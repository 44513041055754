import React from "react";
import Styles from "./Features.module.css";

const Features = () => {
  return (
    <div className={Styles.wrapper}>
      <div className={Styles.container}>
        <p className={Styles.card}>
          <span>Inventory</span>
          <span>View</span>
        </p>
        <p className={Styles.card}>
          <span>Services</span>
          <span>View</span>
        </p>
        <p className={Styles.card}>
          <span>Financing</span>
          <span>View</span>
        </p>
      </div>
    </div>
  );
};

export default Features;

import React, { useEffect, useRef, useState } from "react";
import Styles from "../Styles/Box_B_Non_Edit.module.css";
import {
  addSellPriceLabels,
  deleteSellPriceLabels,
  setDynamicAPILabels,
  updateSellPriceInputs,
  updateSellPriceLabels,
} from "../../../../Redux/slice/taxes&FeesSlice";
import { useDispatch } from "react-redux";
import { MdDelete, MdModeEditOutline } from "react-icons/md";
import { AiTwotoneDelete } from "react-icons/ai";
import { Tooltip } from "react-tooltip";
import { RiFileEditFill } from "react-icons/ri";

const Box_B_Non_Edit = ({ allInputs, editStatus = false }) => {
  // console.log("All inputs => ", allInputs);
  const dispatch = useDispatch();
  const [isLabelEditing, setIsLabelEditing] = useState(false);
  //For tracking which element to edit, because isLabelEditing state is editing globally
  const [editingIndex, setEditingIndex] = useState(null);
  const [labelInputValue, setLabelInputValue] = useState("");
  const inputRef = useRef(null);
  const handlelabelEdit = (index, boolVal, value) => {
    // console.log("Value to be sent in the input field", value);
    setLabelInputValue(value);
    setIsLabelEditing(boolVal);
    setEditingIndex(index);
  };

  const handleLabelInputChange = (index, value) => {
    setLabelInputValue(value);
    dispatch(updateSellPriceLabels({ index, value }));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setIsLabelEditing(false);
        setLabelInputValue("");
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setIsLabelEditing(false);
      setLabelInputValue("");
    }
  };

  const handleDeleteLabel = (index) => {
    dispatch(deleteSellPriceLabels({ index }));
  };

  useEffect(() => {
    if (editStatus && isLabelEditing) {
      inputRef.current.focus();
    }
  }, [editStatus, isLabelEditing, editingIndex]);
  //for tracking the width of the screen
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  // console.log("isSmallScreen  => ", isSmallScreen);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 720 && window.innerWidth <= 1024) {
        setIsSmallScreen(true);
      } else {
        setIsSmallScreen(false);
      }
    };

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //for updating thr index of the element in the allinputs  array

  const [isListOrderUpdating, setIsListOrderUpdating] = useState(false);
  const [orderUpdateIndex, setOrderUpdateIndex] = useState(null);
  const [listingValue, setListingValue] = useState("");
  const labelOrderRef = useRef(null);

  const handleLabelIndexUpdate = (e) => {
    const value = e.target.value;

    // Check if the value is a number and less than or equal to 20
    if (
      /^\d*$/.test(value) &&
      (value === "" || parseInt(value, 10) <= allInputs.length)
    ) {
      setListingValue(value);
    }
  };

  // console.log("allInputs => ", allInputs);
  const handleLabelKeyDown = (e) => {
    if (isListOrderUpdating) {
      if (e.key === "Enter") {
        // listingValue
        const elToUpdated = allInputs.find(
          (_, i) => i === parseInt(listingValue - 1)
        );
        const elWhichisUpdating = allInputs.find(
          (_, i) => i === parseInt(orderUpdateIndex)
        );
        console.log(
          "elToUpdated => ",
          elToUpdated,
          allInputs.indexOf(elToUpdated),

          "elWhichisUpdating => ",
          elWhichisUpdating,
          allInputs.indexOf(elWhichisUpdating)
        );
        const updatedinputs = [...allInputs];
        const currentElIndex = updatedinputs.indexOf(elWhichisUpdating);
        const targetElIndex = updatedinputs.indexOf(elToUpdated);
        [updatedinputs[currentElIndex], updatedinputs[targetElIndex]] = [
          updatedinputs[targetElIndex],
          updatedinputs[currentElIndex],
        ];
        console.log("updatedinputs => ", updatedinputs);
        dispatch(setDynamicAPILabels(updatedinputs));
        setOrderUpdateIndex(null);
      }
    }
  };

  useEffect(() => {
    if (isListOrderUpdating) {
      labelOrderRef?.current?.focus();
      setListingValue(orderUpdateIndex + 1);
    }
  }, [isListOrderUpdating]);

  useEffect(() => {
    const handleClickOutside2 = (event) => {
      if (
        labelOrderRef.current &&
        !labelOrderRef.current.contains(event.target)
      ) {
        setIsListOrderUpdating(false);
        setListingValue("");
      }
    };
    document.addEventListener("mousedown", handleClickOutside2);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside2);
    };
  }, []);

  return (
    <div className={Styles.wrapper}>
      <div className={editStatus ? Styles.modalContainer : Styles.container}>
        {allInputs &&
          allInputs?.map((item, index) => (
            <div
              key={item.id}
              className={`${Styles.inputContainer} ${
                index === allInputs.length - 1 && Styles.pricefeesInputRelative
              }`}
            >
              {editStatus && isLabelEditing && editingIndex === index ? (
                <input
                  type="text"
                  className={Styles.headerInputLabel}
                  ref={inputRef}
                  value={labelInputValue}
                  onKeyDown={handleKeyDown}
                  onChange={(e) => {
                    const { value } = e.target;
                    handleLabelInputChange(index, value);
                  }}
                />
              ) : (
                <p
                  className={
                    editStatus
                      ? Styles.inputLabelContainerPopup
                      : Styles.inputLabelContainer
                  }
                >
                  {/* <span>{item.label}</span>{" "} */}

                  {isSmallScreen ? (
                    <>
                      <span
                        data-tooltip-id={`tooltip-${item.id}`}
                        data-tooltip-content={item.label}
                        data-event="click"
                      >
                        {item.label ? item.label.split(" ")[0] : null}...
                      </span>
                      <Tooltip id={`tooltip-${item.id}`} place="right" />
                    </>
                  ) : (
                    <span className={editStatus && Styles.labelContainer}>
                      {editStatus && (
                        <>
                          {isListOrderUpdating && orderUpdateIndex === index ? (
                            <input
                              type="text"
                              className={Styles.labelOrderIndexInput}
                              value={listingValue}
                              onChange={handleLabelIndexUpdate}
                              onKeyDown={handleLabelKeyDown}
                              ref={labelOrderRef}
                            />
                          ) : (
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setOrderUpdateIndex(index);
                                setIsListOrderUpdating(true);
                              }}
                            >
                              {index + 1}
                            </span>
                          )}
                        </>
                      )}
                      <span>{item.label}</span>
                    </span>
                  )}

                  {editStatus && !isLabelEditing && (
                    <div className={Styles.editDeleteContainer}>
                      <span
                        onClick={() => handleDeleteLabel(index)}
                        style={{ cursor: "pointer" }}
                      >
                        <MdDelete />
                      </span>
                      <span
                        onClick={() => handlelabelEdit(index, true, item.label)}
                      >
                        <RiFileEditFill />
                      </span>
                    </div>
                  )}
                </p>
              )}

              <span className={Styles.box_b_none_edit_input_container}>
                <span className={Styles.box_b_none_edit_dollar_absolute_sign}>
                  $
                </span>
                <input
                  type="text"
                  value={item.InputValue}
                  placeholder="0"
                  className={Styles.inputField}
                  // onChange={(e) => {
                  //   const { value } = e.target;
                  //   dispatch(updateSellPriceInputs({ index, value }));
                  // }}
                  onChange={(e) => {
                    const { value } = e.target;
                    const numericValue = value.replace(/[^\d\.]/g, ""); // remove non-numeric characters
                    dispatch(
                      updateSellPriceInputs({ index, value: numericValue })
                    );
                  }}
                />
              </span>
              {index === allInputs.length - 1 && editStatus &&  (
                <button
                  style={{
                    border: "none",
                    backgroundColor: "#e8a262",
                    color: "white",
                    borderRadius: "5px",
                    padding: "0.35rem 1rem",
                    marginRight: "2rem",
                    fontSize: "medium",
                    marginTop: "0.1rem",
                  }}
                  className={Styles.box_b_none_edit_add_button_big_Screen}
                  onClick={() =>
                    dispatch(
                      addSellPriceLabels({
                        id: allInputs && allInputs.length + 1,
                        label: `Input ${allInputs && allInputs?.length + 1}`,
                      })
                    )
                  }
                  disabled={allInputs && allInputs.length === 20}
                >
                  Add
                </button>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default Box_B_Non_Edit;

import React, { useCallback, useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../home/home.css";
import Image from "../imgData";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";

function Arrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

const VehicleSlider = () => {
  const { id } = useSelector((state) => state.user);
  const { dealerId } = useSelector((state) => state.user);
  const [filters, setFilters] = useState({
    make: [],
    models: [],
    year: [],
    engineType: [],
    fuelType: [],
    vehicleType: [],
    owner: [],
    state: [],
    interiorColor: [],
    exteriorColor: [],
    interiorType: [],
    doorCounts: [],
    driveTrain: [],
    odometer: [],
    price: [],
    exterior_damage: [],
    photos: [],
    seller: [],
    zip_code: [],
  });
  useEffect(() => {
    if (dealerId) {
      setFilters((prevData) => ({
        ...prevData,
        seller: [dealerId && dealerId],
      }));
    }
  }, [dealerId]);

  // Initialize vehicle types with default counts of "0"
  const [vehicleTypes, setVehicleTypes] = useState([
    { image: "image/Pickup.png", type: "TRUCK", count: "0" },
    { image: "image/COUPE.png", type: "Coupe", count: "0" },
    { image: "image/SEDAN.png", type: "Sedan", count: "0" },
    { image: "image/Crossover.png", type: "CROSSOVER", count: "0" },
    { image: "image/SUV.png", type: "SUV", count: "0" },
    { image: "image/Hatchback 1.png", type: "Hatchback", count: "0" },
    { image: "image/CONVERTABLE.png", type: "Convertible", count: "0" },
    {
      image: "image/IncompleteVehicle.png",
      type: "INCOMPLETE VEHICLE",
      count: "0",
    },
    // { image: "image/Passanger.png", type: "PASSENGER CAR", count: "0" },
    { image: "image/Minivan 1.png", type: "MPV", count: "0" },
  ]);

  const [responseFilter, setResponseFilter] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/listing/filters`,
          filters
        );
        setResponseFilter(response.data);
        console.log(
          "response.data.vehicle_type => ",
          response.data.vehicle_type
        );
        updateVehicleCounts(response.data.vehicle_type); // Update counts after fetching data
      } catch (error) {
        console.log("Server side while fetching data during getting filters");
      }
    };
    fetchProducts();
  }, [filters]);

  // Function to update vehicle counts based on API response
  const updateVehicleCounts = (vehicleTypeData) => {
    const updatedVehicleTypes = vehicleTypes.map((vehicle) => {
      const matchingType = vehicleTypeData.find(
        (item) => item.vehicleType === vehicle.type
      );
      return {
        ...vehicle,
        count: matchingType ? matchingType.count : "0", // Update count or set to "0"
      };
    });
    setVehicleTypes(updatedVehicleTypes);
  };

  const handleClick = useCallback((type) => {
    setFilters((prevData) => ({
      ...prevData,
      vehicleType: [type],
    }));
  }, []);

  useEffect(() => {
    if (filters.vehicleType.length > 0) {
      navigate("/product", { state: filters });
    }
  }, [filters, navigate]);

  var settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    nextArrow: <Arrow />,
    prevArrow: <Arrow />,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="p-4" style={{ cursor: "pointer" }}>
      <div className="container-fluid">
        <div className="col-lg-12">
          <h3 style={{ textAlign: "center" }}>
            <span>Shop By Vehicle Type and Body Class</span>
          </h3>
          <div className="row">
            <div
              className="col-md-12
               biggerScreenSlider
               "
            >
              {/* <Slider {...settings}> */}
              {vehicleTypes.map((vehicle) => (
                <div className="owl-item" key={vehicle.type}>
                  <img
                    src={vehicle.image}
                    alt={vehicle.type}
                    style={{ width: "230px", height: "150px" }}
                    onClick={() => handleClick(vehicle.type)}
                    className="vehicleSliderImage mb-2"
                  />
                  <div
                    className="shadow text-center p_U"
                    // style={{ width: %" }}
                  >
                    <p style={{ marginBottom: "0" }}>
                      <b>{vehicle.type}</b>
                      <p>{vehicle.count}</p>
                    </p>
                  </div>
                </div>
              ))}
              {/* </Slider> */}
            </div>
            <div className="smallScreenSlider">
              <Slider {...settings}>
                {vehicleTypes.map((vehicle) => (
                  <div className="owl-item" key={vehicle.type}>
                    <img
                      src={vehicle.image}
                      alt={vehicle.type}
                      style={{ width: "230px", height: "150px" }}
                      onClick={() => handleClick(vehicle.type)}
                      className="vehicleSliderImage mb-2"
                    />
                    <div
                      className="shadow text-center p_U"
                      // style={{ width: %" }}
                    >
                      <p style={{ marginBottom: "0" }}>
                        <b>{vehicle.type}</b>
                        <p>{vehicle.count}</p>
                      </p>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VehicleSlider;

import React from "react";
import Styles from "./DealerWebsite.module.css";
import {
  Outlet,
  Route,
  Routes,
  useNavigate,
  useOutletContext,
} from "react-router-dom";

import Image from "../../imgData/index.js";
import ActivationCards from "./ActivationCards.jsx";
import DNSManagementSystem from "./DNSManagementSystem.jsx";
import DNSsteps from "./DNSsteps.jsx";
const DealerWebsite = () => {
  const isExapanded = useOutletContext();

  return (
    <div
      className={`${Styles.wrapper} ${
        isExapanded ? Styles.content_expanded : Styles.content_collapsed
      }`}
    >
      <Routes>
        <Route path="/" element={<ActivationCards />} />
        <Route path="dns" element={<DNSManagementSystem />} />
        <Route path="dnsSteps" element={<DNSsteps />} />
      </Routes>
    </div>
  );
};

export default DealerWebsite;

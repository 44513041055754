import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import Image from "../../imgData";
import { useSelector } from 'react-redux';
const imgUrl = process.env.REACT_APP_IMG_URL;

const VehicleCard = ({ index, selectedCard, handleSelected, data }) => {
    const { id } = useSelector((state) => state.user);
    const { all_images, exterior_images, misc_images, interior_images, three_sixty_images } = data.multimedia;
    const noImage = Image.noImage;


    const getFirstValidImage = (images) => {
        if (images) {
            const imageList = images.split(",").map((img) => img.trim());
            for (let image of imageList) {
                if (image) {
                    return `${imgUrl}/${id}/${image}`;
                }
            }
        }
        return null;
    };

    const imageUrl =
        (all_images && getFirstValidImage(all_images)) ||
        (exterior_images && getFirstValidImage(exterior_images)) ||
        (misc_images && getFirstValidImage(misc_images)) ||
        (interior_images && getFirstValidImage(interior_images)) ||
        (three_sixty_images && getFirstValidImage(three_sixty_images)) ||
        noImage;

    // console.log('index', index);
    // console.log('selectedCard', selectedCard);
    return (
        <>
            <Col sm={12} className='px-0 mb-4' onClick={() => { handleSelected(index) }} >
                <Link to={`#${data.stock_no}#${data.id}`} >
                    <Container>
                        <Row className='v-card'>
                            <Col md={3} className=' d-flex justify-content-center p-2'>
                                <img src={imageUrl} alt="..." />
                                {/* <img src="https://images.saymedia-content.com/.image/ar_16:9%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:eco%2Cw_1200/MjAxNzE0OTk4NTA5MDUzNzE2/how-to-buy-cars-cheaply-and-sell-them-at-Link-profit.jpg" alt="..." /> */}
                            </Col>
                            <Col md={3} className=' d-flex  align-items-center'>
                                <div className="divide"></div>
                                <div className=' d-flex flex-column justify-content-center w-100'>
                                    <h5>{data.year} {data.make} {data.model}</h5>
                                    {/* <h5>{data.year_Make_Model && data.year_Make_Model.trim().split(/\s+/).slice(0, 3).join(' ')}</h5> */}
                                    {/* <p><span className='orange-color text-nowrap'>VIN: </span>{data.vin}</p> */}
                                </div>

                            </Col>
                            <Col md={3} className=' d-flex  align-items-center'>
                                <div className="divide"></div>
                                <div className=' d-flex flex-column justify-content-center w-100'>
                                    <p className='mb-0'><span className='orange-color text-nowrap'>VIN: </span>{data.vin}</p>
                                    {data.mileage_in ? <p className=' mb-0'>{data.mileage_in}</p> : null}
                                    <p className='mb-0'>{data.engine} {data.transmission}</p>
                                </div>
                            </Col>
                            <Col md={3} className='d-flex align-items-center'>
                                <div className="divide"></div>
                                <div className='d-flex justify-content-evenly w-100'>
                                    <h3 className='m-auto orange-color'>{data.purchase_price}</h3>
                                    {/* <Button className='orange-btn m-auto border-0 px-2 fw-bolder m-3'>{selectedCard === index ? "Selected" : "Select"}</Button> */}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Link>
            </Col>
        </>
    )
}

export default VehicleCard
// Retry Facebook sign-in
// SignInWithFacebook.js
import { auth, FbOAuthProvider } from "./firebaseConfig";
import {
  getRedirectResult,
  signInWithPopup,
  signInWithRedirect,
} from "firebase/auth";
import axios from "axios";
import { saveUser } from "../../../Redux/slice/userSlice"; // Adjust the path as needed

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const getIPAddress = async () => {
  try {
    const res = await axios.get("https://api.ipify.org/?format=json");
    return res.data.ip;
  } catch (error) {
    console.error("Error fetching IP information:", error);
    return "";
  }
};

const SignInWithFacebook = async (
  dispatch,
  navigate,
  userType,
  isScreenSmaller = false
) => {
  try {
    // Fetch IP address
    const ip = await getIPAddress();

    // Proceed with Facebook sign-in
    // const result = await signInWithPopup(auth, FbOAuthProvider);
    // const user = result.user;
    let result;
    if (isScreenSmaller) {
      await signInWithRedirect(auth, FbOAuthProvider);
      result = await getRedirectResult(auth);
    } else {
      result = await signInWithPopup(auth, FbOAuthProvider);
    }
    if (result) {
      const user = result.user;

      console.log("User:", user);
      console.log("User Name:", user.displayName);
      console.log("User Email:", user.email);
      console.log("User Phone Number:", user.phoneNumber);
      console.log("User IP:", ip);

      // Create FormData instance
      const formData = new FormData();
      formData.append(
        "email",
        user.providerData[0].email || user.providerData[0].uid || ""
      );
      formData.append(
        "firstName",
        user.providerData[0].displayName
          ? user.providerData[0].displayName.split(" ")[0]
          : ""
      );
      formData.append("ip", ip);
      formData.append("phoneNumber", user.providerData[0].phoneNumber || "");
      formData.append("user_type", userType);

      // Send the form data to your API
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/save_user`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (res.status === 200) {
        dispatch(
          saveUser({
            email: res.data?.email,
            id: res.data.id,
            firstName: res.data.firstName,
            lastName: res.data.lastName,
            roles: res.data.roles,
            phoneNumber: res.data?.phoneNumber,
          })
        );
        if (res.data.roles === "Dealer") {
          navigate("/dealer/dashboard");
        }
        // if (res.data.roles === "User") {
        //   navigate("/myprofile/dashboard");
        // } else {
        //   navigate("/dealer/dashboard");
        // }
      }
    }
  } catch (error) {
    console.error("Sign in with Facebook failed:", error);

    if (error.response && error.response.status === 400) {
      // throw new Error(error.response.data  + " as " + userType === "Dealer" ? "User" : "Dealer");
      const oppositeUserType = userType === "Dealer" ? "User" : "Dealer";
      throw new Error(`${error.response.data} as ${oppositeUserType}`);
    }
    // else {
    //   throw new Error("An unexpected error occurred. Please try again later.");
    // }
  }
};

export default SignInWithFacebook;

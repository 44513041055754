import React, { useEffect, useRef, useState } from "react";
import Styles from "../../DealerBusinessInformation/Styles/BusinesslocationCard.module.css";
import '../../DealerDashBoardModal/DealersProfile.css';
import { GoPencil } from "react-icons/go";
import { MdMyLocation } from "react-icons/md";
import { MdOutlineMail } from "react-icons/md";
import { TbWorldWww } from "react-icons/tb";
import { MdPhoneInTalk } from "react-icons/md";
import { LiaFaxSolid } from "react-icons/lia";
import { ImWhatsapp } from "react-icons/im";
import { Prev } from "react-bootstrap/esm/PageItem";
// import { fetchGeoMapData } from "../../dashboard/geoAPI";

import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import { saveUser } from "../../../../Redux/slice/userSlice";
import { DealerGeoAPI } from "../../DealerDashBoardModal/DealerGeoAPI";
import { getCountyFromLocation } from "../../CountyDetectAPI";
const libraries = ["places"];
const apiUrl = process.env.REACT_APP_API_URL;

const Buyerdata = ({ show, handleClose,cobuyerData}) => {
  const { id } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  // const [dealerdatastatus,setDealerdatastatus] = useState();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    login_access: 1,
    userId: id,
    firstName: "",
    middleName: "",
    lastName: "",
    idType:"",
    idState:"",
    idNumber:"",
    addressOne: "",
    addressTwo:"",
    county: "",
    phone:"",
    email: "",
  });
  console.log("DealerProfileData:", formData);
// dealer dropdown ---------------------------name data--------------------------
const [apiDealerSuggestion, setAPIDealerSuggestion] = useState([]);
const [apiCalled, setApiCalled] = useState(false);
const handleDealerChange = (e) => {
  const { name, value } = e.target;
  setFormData((prevData) => ({
    ...prevData,
    [name]: value
  }));
};

const handleBlur = () => {
  // Check if either field is non-empty and make the API call
  if (formData.zip || formData.dealerLicense) {
    fetchDealerData(formData.zip, formData.dealerLicense);
  }
};

console.log("cobuyerData",cobuyerData);
useEffect(() => {
    setFormData(prevData => ({
      ...prevData,
      firstName:cobuyerData.firstName || "",
      middleName: cobuyerData.middleName || "",
      lastName:  cobuyerData.lastName || "",
      idType:cobuyerData.idType || "",
      idState:cobuyerData.idState || "",
      idNumber:cobuyerData.idNumber || "",
      addressOne: cobuyerData.address || "",
      addressTwo:cobuyerData.address2 || "",
      county: cobuyerData.county || "",
      phone:cobuyerData.phoneNumber || "",
      email:cobuyerData.email || "",
    }));
}, [cobuyerData]);


  const fetchDealerData = async (zip, pNumber) => {
    // Construct FormData object
    const fd = new FormData();
    if (zip) fd.append('zipCode', zip);
    if (pNumber) fd.append('pNumber', pNumber);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/inventory/get`,
        fd
      );

      setAPIDealerSuggestion(response.data);
    } catch (error) {
      console.log('Error fetching dealer data:', error);
      setAPIDealerSuggestion([]);
    }
  };

console.log("apiDealerSuggestion",apiDealerSuggestion);

// --------------------------------------------------------------------------------------------
  const autocompleteRef = useRef(null);

  const handlePlaceSelect = () => {
    const addressObject = autocompleteRef.current.getPlace();
    if (addressObject.formatted_address) {
      setFormData((prevData) => ({
        ...prevData,

        addressOne: addressObject.formatted_address,
      }));
      console.log("Calling the address object")
      fetchCounty(addressObject.formatted_address)
    }
  };

  const fetchCounty = async (address) => {
    console.log(address)
    try {
      const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
      const res = await getCountyFromLocation(apiKey, address);
      console.log(res)
      setFormData((prevData) => ({
        ...prevData,

        county: res,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const handleLocationInput = (e) => {
    const { name, value } = e.target;

    // Regex patterns
    const zipCodePattern = /^\d{0,5}$/;
    const phoneNumberPattern = /^\d{0,10}$/;

    let updatedValue = value;

    if (name === "zipCode" && !zipCodePattern.test(value)) {
      updatedValue = value.slice(0, -1);
    }

    if (name === "phoneNumber" && !phoneNumberPattern.test(value)) {
      updatedValue = value.slice(0, -1);
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: updatedValue,
    }));
  };
  const handleLocation = async () => {
    try {
      const res = await DealerGeoAPI();
      setFormData((prevData) => ({
        ...prevData,
        addressOne: `${res.formattedAddress}`,
      }));
      fetchCounty(res.formattedAddress);
    } catch (error) {
      console.log("API Side Error");
    }
  };

  const handleLocationZip = async () => {
    try {
      const res = await DealerGeoAPI();
      console.log("res.formattedAddress",res);
      setFormData((prevData) => ({
        ...prevData,
        zip: `${res.zipCode}`,
      }));
      // fetchCounty(res.formattedAddress);
    } catch (error) {
      console.log("API Side Error");
    }
  };

  //fetch county
 

  const formatPhoneNumber = (value) => {
    if (!value) return value;

    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  };

  const handleChangeb = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const cleanedValue = value.replace(/[^\d]/g, "");
    setFormData({ ...formData, [name]: cleanedValue });
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  // api data send -------
  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await delay(3000);
      const response = await axios.post(
        `${apiUrl}/dealer/save_details`,
        formData
      );
      console.log("Response:", response.data);
      dispatch(
            saveUser({
              dealerData:response.data,
            }));
      handleClose(); // Close the modal after submission
    } catch (error) {
      console.error("There was an error!", error);
    }finally {
      setLoading(false); // Set loading to false when the request finishes
    }
  };
// console.log("dealerdatastatus",dealerdatastatus);
// useEffect(()=>{
//   dispatch(
//     saveUser({
//       dealerData:dealerdatastatus,
//     })
//   );
// },[dealerdatastatus]);
  // ----------------------------------
  const [dataGapi, setDataGApi] = useState("");
  const spaceCounty = dataGapi.indexOf(" ");
  const dataCounty = dataGapi.slice(0, spaceCounty);


  console.log("gioapi184", dataGapi);

  return (
    <Modal show={show} size="xl" onHide={handleClose}  dialogClassName="modal-90w">
      <Modal.Header closeButton>
        <div>
          <Modal.Title></Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div
          className={Styles.bussiness_location_card_wrapper}
          style={{ marginTop: "0rem" }}
        >
          <div
            className={Styles.bussiness_location_card_container}
            style={{ borderRadius: "0px", border: "none" }}
          >
            <div className={Styles.location_card_header_wrapper}>
              <div
                className={Styles.location_card_header}
                style={{ justifyContent: "center" }}
              >
                <p>Buyer Information</p>
              </div>
            </div>
            <div
              className={Styles.dealer_contact_information_parent}
              style={{ paddingTop: "0px", paddingBottom: "0px" }}
            >
              <div className={Styles.business_address_1}>
              <p>
                  <label
                    htmlFor="dealer_website"
                    className={Styles.input_label}
                    style={{ fontWeight: "unset" }}
                  >
                    <span
                      style={{
                        color: "orange",
                        fontSize: "large",
                        marginRight: "0.3rem",
                      }}
                    >
                      
                    </span>
                    <span>First Name</span>
                  </label>
                  <input
                    type="text"
                    id="dealer_website"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    className={Styles.input_text_field}
                    style={{ fontWeight: "bolder" }}
                  />
                </p>
                <p>
                  <div>
                    <label
                      htmlFor="dealer_website"
                      className={Styles.input_label}
                      style={{ fontWeight: "unset" }}
                    >
                      <span
                        style={{
                          color: "orange",
                          fontSize: "large",
                          marginRight: "0.3rem",
                        }}
                      >
                       
                      </span>
                      <span>Middle Name</span>
                    </label>
                  </div>
                  <diiv>
                    <input
                      type="text"
                      id="dealer_website"
                      name="middleName"
                      value={formData.middleName}
                      onChange={handleChange}
                      className={Styles.input_text_field}
                      style={{ fontWeight: "bolder" }}
                    />
                  </diiv>
                </p>
                <p>
                  <label
                    htmlFor="dealer_website"
                    className={Styles.input_label}
                    style={{ fontWeight: "unset" }}
                  >
                    <span
                      style={{
                        color: "orange",
                        fontSize: "large",
                        marginRight: "0.3rem",
                      }}
                    >
                     
                    </span>
                    <span>Last Name</span>
                  </label>
                  <input
                    type="text"
                    id="dealer_business_phone_number"
                    value={formData.lastName}
                    onChange={handleChange}
                    name="lastName"
                    pattern="\d*"
                    className={Styles.input_text_field}
                    style={{ fontWeight: "bolder" }}
                  />
                </p>
              </div>
            </div>
            <div
              className={Styles.dealer_contact_information_parent}
              style={{ paddingTop: "0px", paddingBottom: "0px" }}
            >
              <div className={Styles.business_address_1}>
              <p>
                  <label
                    htmlFor="dealer_website"
                    className={Styles.input_label}
                    style={{ fontWeight: "unset" }}
                  >
                    <span
                      style={{
                        color: "orange",
                        fontSize: "large",
                        marginRight: "0.3rem",
                      }}
                    >
                      
                    </span>
                    <span>ID Type</span>
                  </label>
                  <input
                    type="text"
                    id="dealer_website"
                    name="idType"
                    value={formData.idType}
                    onChange={handleChange}
                    className={Styles.input_text_field}
                    style={{ fontWeight: "bolder" }}
                  />
                </p>
                <p>
                  <div>
                    <label
                      htmlFor="dealer_website"
                      className={Styles.input_label}
                      style={{ fontWeight: "unset" }}
                    >
                      <span
                        style={{
                          color: "orange",
                          fontSize: "large",
                          marginRight: "0.3rem",
                        }}
                      >
                       
                      </span>
                      <span>ID State</span>
                    </label>
                  </div>
                  <diiv>
                    <input
                      type="text"
                      id="dealer_website"
                      name="idState"
                      value={formData.idState}
                      onChange={handleChange}
                      className={Styles.input_text_field}
                      style={{ fontWeight: "bolder" }}
                    />
                  </diiv>
                </p>
                <p>
                  <label
                    htmlFor="dealer_website"
                    className={Styles.input_label}
                    style={{ fontWeight: "unset" }}
                  >
                    <span
                      style={{
                        color: "orange",
                        fontSize: "large",
                        marginRight: "0.3rem",
                      }}
                    >
                     
                    </span>
                    <span>ID Number</span>
                  </label>
                  <input
                    type="text"
                    id="dealer_business_phone_number"
                    value={formData.idNumber}
                    onChange={handleChange}
                    name="idNumber"
                    pattern="\d*"
                    className={Styles.input_text_field}
                    style={{ fontWeight: "bolder" }}
                  />
                </p>
              </div>
            </div>
            <div
              className={Styles.location_card_main_2}
              style={{ paddingTop: "0px", paddingBottom: "0px" }}
            >
              <div className={Styles.business_address_1}>
                <p className={Styles.dealer_address_line_1_parent}>
                  <label
                    htmlFor="dealer_address_line_1"
                    className={Styles.input_label}
                    style={{ fontWeight: "unset" }}
                  >
                    Address
                  </label>
                  {/* <input
                                        type="text"
                                        id="dealer_address_line_1"
                                        value={formData.addressOne}
                                        name="addressOne"
                                        onChange={handleLocationInput}
                                        className={Styles.input_text_field}
                                    /> */}
                  <LoadScript
                    googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                    // googleMapsApiKey="AIzaSyAidNgKafDZvB6960WgN84sAV9hHqRYGYY"
                    libraries={libraries}
                  >
                    <Autocomplete
                      onLoad={(autocomplete) =>
                        (autocompleteRef.current = autocomplete)
                      }
                      onPlaceChanged={handlePlaceSelect}
                      style={{ zIndex: "10" }}
                    >
                      <input
                        type="text"
                        id="dealer_address_line_1"
                        className={Styles.input_text_field}
                        // placeholder="Enter your address"
                        style={{ fontWeight: "bolder" }}
                        value={formData.addressOne}
                        onChange={handleLocationInput}
                        name="addressOne"
                      />
                    </Autocomplete>

                    <span onClick={handleLocation}>
                      <MdMyLocation />
                    </span>
                  </LoadScript>
                  {/* <span style={{color:"black",fontSize:"15px",fontWeight:"bold",paddingRight:"13%",paddingTop:"1.5%"}}>Unit:</span> */}
                  <span onClick={handleLocation}>
                    <MdMyLocation />
                  </span>
                </p>
                <p className={Styles.zipCodeLocation}>
                  <label
                    htmlFor="dealer_business_zip_code"
                    className={`${Styles.input_label} `}
                    style={{ fontWeight: "unset" }}
                  >
                    Address Line 2
                  </label>
                  <input
                    type="text"
                    id="dealer_address_line_1"
                    value={formData.addressTwo}
                    name="addressTwo"
                    onChange={handleChange}
                    className={Styles.input_text_field}
                    style={{ fontWeight: "bolder" }}
                  />
                </p>
                <p className={Styles.zipCodeLocation}>
                  <label
                    htmlFor="dealer_business_zip_code"
                    className={`${Styles.input_label} `}
                    style={{ fontWeight: "unset" }}
                  >
                    County
                  </label>
                  <input
                    type="text"
                    id="dealer_business_zip_code"
                    name="county"
                    value={formData.county}
                    className={Styles.input_text_field}
                    style={{ fontWeight: "bolder" }}
                    onChange={handleChange}
                  />
                </p>
              </div>
            </div>
            <div
              className={Styles.location_card_main_2}
              style={{ paddingTop: "0px", paddingBottom: "0px" }}
            >
              <div className={Styles.business_address_2}>
              <p>
                  <label
                    htmlFor="dealer_business_phone_number"
                    className={Styles.input_label}
                    style={{ fontWeight: "unset" }}
                  >
                    <span
                      style={{
                        color: "orange",
                        fontSize: "large",
                        marginRight: "0.3rem",
                      }}
                    >
                      <MdPhoneInTalk />
                    </span>
                    <span>Phone Number</span>
                  </label>
                  <input
                    type="text"
                    id="dealer_business_phone_number"
                    value={formatPhoneNumber(formData.phone)}
                    onChange={handleChangeb}
                    name="phone"
                    pattern="\d*"
                    className={Styles.input_text_field}
                    maxLength={14}
                    style={{ fontWeight: "bolder" }}
                  />
                </p>
                
                <p>
                  <label
                    htmlFor="dealer_email_address"
                    className={Styles.input_label}
                    style={{ fontWeight: "unset" }}
                  >
                    <span
                      style={{
                        color: "orange",
                        fontSize: "large",
                        marginRight: "0.3rem",
                      }}
                    >
                      <MdOutlineMail />
                    </span>
                    <span>Email Address</span>
                  </label>
                  <input
                    type="text"
                    id="dealer_email_address"
                    value={formData.email}
                    name="email"
                    onChange={handleChange}
                    className={Styles.input_text_field}
                    style={{ fontWeight: "bolder" }}
                  />
                </p>
              </div>
            </div>
           
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <button
          className="btn btn-secondary"
          style={{ width: "150px" }}
          onClick={handleSubmit}
          disabled={loading}
        >
         {loading ? <div className="spinner"></div> : 'Submit'}
        </button> */}
      </Modal.Footer>
    </Modal>
  );
};

export default Buyerdata;

import React, { useEffect, useRef, useState } from "react";
import Styles from "./TaxCertificate.module.css";
const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
const TaxCertificate = () => {
  const addressRef = useRef(null);
  const [suggestions, setSuggestions] = useState([]);
  const [formData, setFormData] = useState({
    permitNumber: "",
    phoneNumber: "",
    buyerName: "",
    sellerName: "",
    sellerAddress: "",
    discription: "",
  });
  // ------------------------ handel change----------------------------------------
  const formatPhoneNumber = (phone) => {
    if (!phone) return "";
    // Remove all non-numeric characters
    const cleaned = phone.replace(/\D/g, "");
    // Format it as (XXX) XXX-XXXX
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phone;
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  // ------------------------------------------------------------------------------
  // for address or Seller Address google api------------------------------------------------------------
  useEffect(() => {
    // Load the Google Maps API script
    const loadGoogleScript = () => {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = () => {
        initAutocomplete();
      };
      document.body.appendChild(script);
    };
  
    loadGoogleScript();
  }, [formData.sellerName]);

  const initAutocomplete = () => {
    const input = addressRef.current;
    const options = {
      types: ['establishment'],
      componentRestrictions: { country: 'us' }, // Restrict to US addresses, can be changed to any country code.
    };
    const autocomplete = new window.google.maps.places.Autocomplete(input, options);
  
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
  
      if (place.geometry) {
        const addressComponents = place.address_components;
        const address = place.formatted_address;
  
        // Log the full address (optional)
        console.log('Full Address:', addressComponents);
        
        // Set the state with the selected address and its components
        setFormData((prevState) => ({
          ...prevState,
          sellerName: place.name,  // Assuming `place.name` contains the seller name.
          sellerAddress: address,
        }));
      }
    });
  };
  const handleInputChangeSellerName = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  
    // Filter the suggestions based on the input value
    const filteredSuggestions = suggestions.filter(suggestion =>
      suggestion.toLowerCase().includes(value.toLowerCase())
    );
    setSuggestions(filteredSuggestions);
  };
  // Handle suggestion click
const handleSuggestionClick = (suggestion) => {
  // Find the full address for the selected suggestion
  const selectedPlace = suggestions.find((place) => place.name === suggestion);
  setFormData({
    sellerName: suggestion,
    sellerAddress: selectedPlace?.address || '',
  });
  setSuggestions([]); // Clear suggestions after selection
};
  // ----------------------------------------------------------------------------------------------------
  console.log("formData----TexCertificate", formData);
  return (
    <>
      <div className={Styles.business_address_2}>
        <p>
          <label
            htmlFor="dealer_business_phone_number"
            className={Styles.input_label}
          >
            <span
              style={{
                color: "orange",
                fontSize: "large",
                marginRight: "0.3rem",
              }}
            ></span>
            <span>Taxs Sales And Use Tax Permit Number(11 Digit)</span>
          </label>
          <input
            type="text"
            id="permitNumber"
            name="permitNumber"
            className={Styles.input_text_field}
            maxLength={11}
            value={formData.permitNumber}
            onChange={handleInputChange}
          />
        </p>
        <p>
          <label htmlFor="dealer_business_fax" className={Styles.input_label}>
            <span
              style={{
                color: "orange",
                fontSize: "large",
                marginRight: "0.3rem",
              }}
            ></span>
            <span>Phone Number</span>
          </label>
          <input
            type="text"
            id="phoneNumber"
            name="phoneNumber"
            maxLength={14}
            className={Styles.input_text_field}
            value={formatPhoneNumber(formData.phoneNumber)}
            onChange={handleInputChange}
          />
        </p>
        <p>
          <label
            htmlFor="dealer_business_zip_code"
            className={Styles.input_label}
          >
            <span
              style={{
                color: "orange",
                fontSize: "large",
                marginRight: "0.3rem",
              }}
            ></span>
            <span>Buyer Name</span>
          </label>
          <input
            type="text"
            id="buyerName"
            name="buyerName"
            className={Styles.input_text_field}
            value={formData.buyerName}
            onChange={handleInputChange}
          />
        </p>
      </div>
      <div className={Styles.business_address_2}>
        <p>
          <label
            htmlFor="dealer_business_phone_number"
            className={Styles.input_label}
          >
            <span
              style={{
                color: "orange",
                fontSize: "large",
                marginRight: "0.3rem",
              }}
            ></span>
            <span>Seller Name</span>
          </label>
          <input
            ref={addressRef}
            type="text"
            id="sellerName"
            name="sellerName"
            className={Styles.input_text_field}
            value={formData.sellerName}
            onChange={handleInputChangeSellerName}
          />
          {/* Optionally, show suggestions below */}
          <ul>
            {suggestions.map((suggestion, index) => (
              <li
                key={index}
                onClick={(event) => {
                  event.stopPropagation(); // This stops the event from propagating up the DOM
                  handleSuggestionClick(suggestion);
                }}
              >
                {suggestion}
              </li>
            ))}
          </ul>
        </p>
        <p>
          <label
            htmlFor="dealer_business_zip_code"
            className={Styles.input_label}
          >
            <span
              style={{
                color: "orange",
                fontSize: "large",
                marginRight: "0.3rem",
              }}
            ></span>
            <span>Seller Address</span>
          </label>
          <input
            type="text"
            id="sellerAddress"
            name="sellerAddress"
            className={Styles.input_text_field}
            value={formData.sellerAddress}
            onChange={handleInputChange}
          />
        </p>
      </div>
      <div className={Styles.business_address_2}>
        <p>
          <label
            htmlFor="dealer_business_zip_code"
            className={Styles.input_label}
          >
            <span
              style={{
                color: "orange",
                fontSize: "large",
                marginRight: "0.3rem",
              }}
            ></span>
            <span>Description</span>
          </label>
          <textarea
            type="text"
            id="discription"
            name="discription"
            className={Styles.input_text_field_seller}
            value={formData.discription}
            onChange={handleInputChange}
          />
        </p>
      </div>
      <div className={Styles.generate_btn}>
        <button type="button">Generate</button>
      </div>
    </>
  );
};

export default TaxCertificate;

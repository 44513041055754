import React from "react";
import Styles from "./FeaturedListing.module.css";
import Image from "../../../../imgData";

const FeaturedLisiting = () => {
  return (
    <div className={Styles.wrapper}>
      <div className={Styles.container}>
        <p className={Styles.header}>
          <span>Featured Lisitings</span>
        </p>
        <p className={Styles.headerType}>
          <span style={{ backgroundColor: "black", color: "white" }}>Used</span>
          <span style={{ backgroundColor: "white", color: "black" }}>New</span>
        </p>
        <div className={Styles.cardContainer}>
          <div className={Styles.bigCard}>
            <div className={Styles.card}>
              <img
                src={Image.web3_card_car_image}
                alt="car"
                className={Styles.carImage}
              />
              <p className={Styles.carName}>Audi A8 4-door sedan silver</p>
              <div className={Styles.carDetails}>
                <p className={Styles.carExtraInfo}>
                  <span>2022</span>
                  <span>20,000 mi</span>
                  <span>Automatic</span>
                </p>
                <p className={Styles.car_Prize}>$62,000</p>
              </div>
            </div>
          </div>
          <div className={Styles.smallCardContainer}>
            {[1, 2, 3, 4, 5, 6].map((index) => (
              <div key={index} className={Styles.card}>
                <img
                  src={Image.web3_card_car_image}
                  alt="car"
                  className={Styles.carImage}
                />
                <p className={Styles.carName}>Audi A8 4-door sedan silver</p>
                <div className={Styles.carDetails}>
                  <p className={Styles.carExtraInfo}>
                    <span>2022</span>
                    <span>20,000 mi</span>
                    <span>Automatic</span>
                  </p>
                  <p className={Styles.car_Prize}>$62,000</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <p className={Styles.headerType}>
          <span style={{ border : "1px solid black", padding : "0.4rem 0.9rem", marginTop : "2rem" }}>View All</span>
        </p>
      </div>
    </div>
  );
};

export default FeaturedLisiting;

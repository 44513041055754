import React from 'react'
import './WebFooter.css';
export const WebFooter = () => {
  return (
    <div className='web_footer_main'>
    <div className='web_footer'>
      <div>Logo</div>
      <div>
        <ul>
          <li>Listing</li>
          <li>Blog</li>
          <li>Our Team</li>
        </ul>
      </div>
      <div>
      <ul>
          <li>FAQ</li>
          <li>About Us</li>
          <li>Contact</li>
        </ul>
      </div>
      <div>
      <ul className='web_address'>
          <li style={{fontSize:"20px"}}>(123) 456-789</li>
          <li>Support@info.com</li>
          <li>Example Street, Taxes, USA</li>
        </ul>
      </div>
      </div>
      <div className='web_copyright'>Copyright @ 2023 All rights Reserved. <u>Privacy Policy</u></div>
      </div>
  )
}

export const DefaultDynamicLabels = [
  {
    id: 6465,
    label: "Documentation Fees",
    InputValue: 150,
  },
  {
    id: 6466,
    label: "Wind-Shield Sticker",
    InputValue: 50.75,
  },
  {
    id: 6467,
    label: "TERP Title Fee to DMV",
    InputValue: 20,
  },
  {
    id: 6468,
    label: "Title Application Fee",
    InputValue: 13,
  },
  {
    id: 6469,
    label: "REG. FEE-DPS to Tax Office",
    InputValue: 1,
  },
  {
    id: 6470,
    label: "CNTY ROAD Bridge Addon Fee",
    InputValue: 10,
  },
  {
    id: 6471,
    label: "Child Safety to Tax Office",
    InputValue: 1.5,
  },
  {
    id: 6472,
    label: "Proc. & Handling Fee to Tax Office",
    InputValue: 4.75,
  },
  {
    id: 6473,
    label: "Paper Tag to DMV",
    InputValue: 5,
  },
  {
    id: 6474,
    label: "Metal Tag",
    InputValue: 5,
  },
  {
    id: 6475,
    label: "Inspection Fee To The State",
    InputValue: 8.25,
  },
  {
    id: 6476,
    label: "Inspection Fee To Station",
    InputValue: 25,
  },
  {
    id: 6477,
    label: "Service Contract Fee",
    InputValue: 0,
  },
  {
    id: 6478,
    label: "Card Fee/Technology Fee",
    InputValue: 0,
  },
  {
    id: 6479,
    label: "Others",
    InputValue: 0,
  },
];

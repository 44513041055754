import React, { useState } from 'react';
import './Web4.css';
import Image from '../../../../imgData';
import { IoIosCheckmarkCircle } from "react-icons/io";
import { Web4Footer, WebFooter } from './WebFooter';
// import Web4Footer from './Web4Footer';
const Web4 = () => {
    const [isAllExpanded, setIsAllExpanded] = useState(false);

    const toggleAllSection = () => {
        setIsAllExpanded(!isAllExpanded);
    };

    return (
      <div>
        <div className="website_four_nav">
          <div className="website_four_child" style={{ marginLeft: "10px" }}>
            Website Name
          </div>
          <div className="website_four_child">Home</div>
          <div className="website_four_child">Inventory</div>
          <div className="website_four_child">Listing</div>
          <div className="website_four_child">Pages</div>
          <div className="website_four_child">More</div>
          <div className="website_four_child_login_listing">
            <div className="website_four_child_login">Dealer Login</div>
            <div className="website_four_child_login">+ Add Listing</div>
          </div>
        </div>
        <p
          style={{
            backgroundColor: "red",
            padding: "0.5rem",
            fontSize: "larger",
            color: "white",
            fontWeight: "500",
            textAlign: "center",
          }}
        >
          <span>
            This is a preview only. The site is not functional; please activate
            this design to access the full features.
          </span>
        </p>
        <div className="website_four_seconde_section">
          <div className="website_four_cat_all">
            <span
              style={{ color: "#d1823a", cursor: "pointer" }}
              onClick={toggleAllSection}
            >
              All
            </span>
            <span>Used</span>
            <span>New</span>
          </div>
          {isAllExpanded && (
            <div className="additional_content">
              {/* Add any additional content you want to show here */}
              <p>Additional content visible when 'All' is clicked.</p>
            </div>
          )}
          <div className="website_four_cat_logo">
            <span className="website_four_cat_logo_title">
              <img src="https://p.kindpng.com/picc/s/742-7420504_font-car-comments-car-line-icon-png-transparent.png" />
              <p>Sedan</p>
            </span>
            <span className="website_four_cat_logo_title">
              <img src="https://p.kindpng.com/picc/s/742-7420504_font-car-comments-car-line-icon-png-transparent.png" />
              <p>Coupe</p>
            </span>
            <span className="website_four_cat_logo_title">
              <img src="https://p.kindpng.com/picc/s/742-7420504_font-car-comments-car-line-icon-png-transparent.png" />
              <p>SUV</p>
            </span>
            <span className="website_four_cat_logo_title">
              <img src="https://p.kindpng.com/picc/s/742-7420504_font-car-comments-car-line-icon-png-transparent.png" />
              <p>Hatchback</p>
            </span>
            <span className="website_four_cat_logo_title">
              <img src="https://p.kindpng.com/picc/s/742-7420504_font-car-comments-car-line-icon-png-transparent.png" />
              <p>more</p>
            </span>
            {/* <span>New</span> */}
          </div>
          <div className="website_four_btn_u_n">
            <button id="btn_used">Used</button>
            <button id="btn_new">New</button>
          </div>
          <div
            className="col-12"
            style={{
              marginLeft: "1%",
              marginTop: "5%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div className="col-6">
              <div className="card big_card">
                <img
                  class="card-img-top"
                  src="https://t4.ftcdn.net/jpg/05/37/32/57/360_F_537325726_GtgjRiyc37BLPn9OmisBVVZec9frLaL0.jpg"
                  alt="Card image cap"
                />
                <div className="card-body bg-dark text-light">
                  <p className="card-text">Audi A8 4-door sedan silver</p>
                  <p
                    className="card-text"
                    style={{ borderTop: "2px solid white" }}
                  >
                    Audi A8 4-door sedan silver
                  </p>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div
                className="col-12"
                style={{ display: "flex", flexDirection: "row", gap: "5%" }}
              >
                <div className="col-3">
                  <div className="card smalla_card">
                    <img
                      class="card-img-top"
                      src="https://t4.ftcdn.net/jpg/05/37/32/57/360_F_537325726_GtgjRiyc37BLPn9OmisBVVZec9frLaL0.jpg"
                      alt="Card image cap"
                    />
                    <div className="card-body bg-dark text-light">
                      <p className="card-text">Audi A8 4-door sedan silver</p>
                      <p
                        className="card-text"
                        style={{ borderTop: "2px solid white" }}
                      >
                        Audi A8 4-door sedan silver
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="card smalla_card">
                    <img
                      class="card-img-top"
                      src="https://t4.ftcdn.net/jpg/05/37/32/57/360_F_537325726_GtgjRiyc37BLPn9OmisBVVZec9frLaL0.jpg"
                      alt="Card image cap"
                    />
                    <div className="card-body bg-dark text-light">
                      <p className="card-text">Audi A8 4-door sedan silver</p>
                      <p
                        className="card-text"
                        style={{ borderTop: "2px solid white" }}
                      >
                        Audi A8 4-door sedan silver
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="card smalla_card">
                    <img
                      class="card-img-top"
                      src="https://t4.ftcdn.net/jpg/05/37/32/57/360_F_537325726_GtgjRiyc37BLPn9OmisBVVZec9frLaL0.jpg"
                      alt="Card image cap"
                    />
                    <div className="card-body bg-dark text-light">
                      <p className="card-text">Audi A8 4-door sedan silver</p>
                      <p
                        className="card-text"
                        style={{ borderTop: "2px solid white" }}
                      >
                        Audi A8 4-door sedan silver
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-12"
                style={{ display: "flex", flexDirection: "row", gap: "5%" }}
              >
                <div className="col-3">
                  <div className="card smalla_card">
                    <img
                      class="card-img-top"
                      src="https://t4.ftcdn.net/jpg/05/37/32/57/360_F_537325726_GtgjRiyc37BLPn9OmisBVVZec9frLaL0.jpg"
                      alt="Card image cap"
                    />
                    <div className="card-body bg-dark text-light">
                      <p className="card-text">Audi A8 4-door sedan silver</p>
                      <p
                        className="card-text"
                        style={{ borderTop: "2px solid white" }}
                      >
                        Audi A8 4-door sedan silver
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="card smalla_card">
                    <img
                      class="card-img-top"
                      src="https://t4.ftcdn.net/jpg/05/37/32/57/360_F_537325726_GtgjRiyc37BLPn9OmisBVVZec9frLaL0.jpg"
                      alt="Card image cap"
                    />
                    <div className="card-body bg-dark text-light">
                      <p className="card-text">Audi A8 4-door sedan silver</p>
                      <p
                        className="card-text"
                        style={{ borderTop: "2px solid white" }}
                      >
                        Audi A8 4-door sedan silver
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="card smalla_card">
                    <img
                      class="card-img-top"
                      src="https://t4.ftcdn.net/jpg/05/37/32/57/360_F_537325726_GtgjRiyc37BLPn9OmisBVVZec9frLaL0.jpg"
                      alt="Card image cap"
                    />
                    <div className="card-body bg-dark text-light">
                      <p className="card-text">Audi A8 4-door sedan silver</p>
                      <p
                        className="card-text"
                        style={{ borderTop: "2px solid white" }}
                      >
                        Audi A8 4-door sedan silver
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="website_four_btn_u_n">
            <button id="btn_used">View All</button>
            {/* <button id='btn_new'>New</button> */}
          </div>
          <div
            className="col-12"
            style={{
              backgroundColor: "#f0f5f9",
              paddingTop: "0.1%",
              marginTop: "1%",
            }}
          >
            <div>
              <div className="website_four_btn_u_n">
                <button id="btn_used">Audi</button>
                <button id="btn_new">BMW</button>
                <button id="btn_new">Cadilac</button>
                <button id="btn_new">Mercedes</button>
              </div>
            </div>
            <div
              className="col-12"
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "2%",
                marginLeft: "1%",
              }}
            >
              <div className="col-3">
                <div className="card smallb_card">
                  <img
                    class="card-img-top"
                    src="https://t4.ftcdn.net/jpg/05/37/32/57/360_F_537325726_GtgjRiyc37BLPn9OmisBVVZec9frLaL0.jpg"
                    alt="Card image cap"
                  />
                  <div className="card-body bg-dark text-light">
                    <p className="card-text">Audi A8 4-door sedan silver</p>
                    <p
                      className="card-text"
                      style={{ borderTop: "2px solid white" }}
                    >
                      Audi A8 4-door sedan silver
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="card smallb_card">
                  <img
                    class="card-img-top"
                    src="https://t4.ftcdn.net/jpg/05/37/32/57/360_F_537325726_GtgjRiyc37BLPn9OmisBVVZec9frLaL0.jpg"
                    alt="Card image cap"
                  />
                  <div className="card-body bg-dark text-light">
                    <p className="card-text">Audi A8 4-door sedan silver</p>
                    <p
                      className="card-text"
                      style={{ borderTop: "2px solid white" }}
                    >
                      Audi A8 4-door sedan silver
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="card smallb_card">
                  <img
                    class="card-img-top"
                    src="https://t4.ftcdn.net/jpg/05/37/32/57/360_F_537325726_GtgjRiyc37BLPn9OmisBVVZec9frLaL0.jpg"
                    alt="Card image cap"
                  />
                  <div className="card-body bg-dark text-light">
                    <p className="card-text">Audi A8 4-door sedan silver</p>
                    <p
                      className="card-text"
                      style={{ borderTop: "2px solid white" }}
                    >
                      Audi A8 4-door sedan silver
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="card smallb_card">
                  <img
                    class="card-img-top"
                    src="https://t4.ftcdn.net/jpg/05/37/32/57/360_F_537325726_GtgjRiyc37BLPn9OmisBVVZec9frLaL0.jpg"
                    alt="Card image cap"
                  />
                  <div className="card-body bg-dark text-light">
                    <p className="card-text">Audi A8 4-door sedan silver</p>
                    <p
                      className="card-text"
                      style={{ borderTop: "2px solid white" }}
                    >
                      Audi A8 4-door sedan silver
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="website_four_btn_u_n">
              <button id="btn_used">View All</button>
              {/* <button id='btn_new'>New</button> */}
            </div>
          </div>
          <div
            className="col-12"
            style={{ marginTop: "5%", marginLeft: "10%" }}
          >
            <div className="col-4">
              <p>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less
                normal distribution of letters, as opposed to using 'Content
                here, content here', making it look like readable English. Many
                desktop publishing packages and web page editors now use Lorem
                Ipsum as their default model text, and a search for 'lorem
                ipsum' will uncover many web sites still in their infancy.
                Various versions have evolved over the years, sometimes by
                accident, sometimes on purpose (injected humour and the like).
              </p>
            </div>
          </div>
          <div
            className="col-12"
            style={{
              marginTop: "5%",
              display: "flex",
              flexDirection: "row",
              position: "relative",
            }}
          >
            <div className="col-6 website_four_carduser">
              <p>
                <IoIosCheckmarkCircle style={{ fill: "#d1823a" }} />
              </p>
              <p>
                <IoIosCheckmarkCircle style={{ fill: "#d1823a" }} />
              </p>
              <p>
                <IoIosCheckmarkCircle style={{ fill: "#d1823a" }} />
              </p>
              <p>
                <IoIosCheckmarkCircle style={{ fill: "#d1823a" }} />
              </p>
              <p>
                <IoIosCheckmarkCircle style={{ fill: "#d1823a" }} />
              </p>
              <span>
                <button type="button">Learn More</button>
              </span>
            </div>
            <div
              className="col-6"
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "2%",
                marginLeft: "1%",
                position: "absolute",
                left: "35%",
                top: "5%",
              }}
            >
              <div className="col-3">
                <div className="card smallc_card">
                  <img
                    class="card-img-top"
                    src="https://static.vecteezy.com/system/resources/thumbnails/025/474/309/small/portrait-of-a-professional-woman-in-a-suit-business-woman-standing-in-an-office-ai-generated-photo.jpg"
                    alt="Card image cap"
                  />
                  <div className="card-body bg-dark text-light">
                    <p className="card-text">Emily Rees</p>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="card smallc_card">
                  <img
                    class="card-img-top"
                    src="https://img.freepik.com/free-photo/smiling-young-male-professional-standing-with-arms-crossed-while-making-eye-contact-against-isolated-background_662251-838.jpg"
                    alt="Card image cap"
                  />
                  <div className="card-body bg-dark text-light">
                    <p className="card-text">Jemmie rees</p>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="card smallc_card">
                  <img
                    class="card-img-top"
                    src="https://img.freepik.com/free-photo/smiling-young-male-professional-standing-with-arms-crossed-while-making-eye-contact-against-isolated-background_662251-838.jpg"
                    alt="Card image cap"
                  />
                  <div className="card-body bg-dark text-light">
                    <p className="card-text">Jemmie rees</p>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="card smallc_card">
                  <img
                    class="card-img-top"
                    src="https://img.freepik.com/free-photo/smiling-young-male-professional-standing-with-arms-crossed-while-making-eye-contact-against-isolated-background_662251-838.jpg"
                    alt="Card image cap"
                  />
                  <div className="card-body bg-dark text-light">
                    <p className="card-text">Jemmie rees</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            {/* ----------------------------------------------------------------Trusted Financing Partners Start--------------------------------------------------------- */}
            <section className="p-4">
              <div className="container-fluid text-center">
                <div className="row">
                  <div className="col-md-12 p-4 px-0">
                    <h2>Trusted Financing Partners</h2>
                    <p>
                      Dealers Auto Center is a trusted partner of leading banks
                      across the USA
                    </p>
                    <div className="row pt-4">
                      {/* <div className="customer-logos slider p-0"> */}

                      <div className="auto_slider_containers">
                        <ul className="auto_sliders">
                          <li>
                            <img src={Image.logo1} alt="" />
                          </li>
                          <li>
                            <img src={Image.logo2} alt="" />
                          </li>
                          <li>
                            <img src={Image.logo3} alt="" />
                          </li>
                          <li>
                            <img src={Image.logo4} alt="" />
                          </li>
                          <li>
                            <img src={Image.logo5} alt="" />
                          </li>
                          <li>
                            <img src={Image.logo1} alt="" />
                          </li>
                        </ul>
                        <ul className="auto_sliders">
                          <li>
                            <img src={Image.logo2} alt="" />
                          </li>
                          <li>
                            <img src={Image.logo3} alt="" />
                          </li>
                          <li>
                            <img src={Image.logo4} alt="" />
                          </li>
                          <li>
                            <img src={Image.logo5} alt="" />
                          </li>
                          <li>
                            <img src={Image.logo1} alt="" />
                          </li>
                          <li>
                            <img src={Image.logo2} alt="" />
                          </li>
                        </ul>
                        <ul className="auto_sliders">
                          <li>
                            <img src={Image.logo3} alt="" />
                          </li>
                          <li>
                            <img src={Image.logo4} alt="" />
                          </li>
                          <li>
                            <img src={Image.logo5} alt="" />
                          </li>
                          <li>
                            <img src={Image.logo1} alt="" />
                          </li>
                          <li>
                            <img src={Image.logo2} alt="" />
                          </li>
                          <li>
                            <img src={Image.logo3} alt="" />
                          </li>
                        </ul>
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* ----------------------------------------------------------------Trusted Financing Partners End--------------------------------------------------------- */}
          </div>
          <div
            className="col-12 web_img_container"
            style={{ backgroundImage: `url(${Image.car_website})` }}
          >
            <div className="col-3 web_img_txt">
              <div className="web_img_txt_a">
                <span>App Store</span>
                <span>Google Pay</span>
              </div>
              <div className="web_img_txt_b">
                <span>Download App Now!</span>
              </div>
            </div>
          </div>
          <div className="col-12 web_contact_info">
            <span>Contact Us</span>
            <div class="vl"></div>
            <span>Info@example.com</span>
          </div>
          <div className="col-12">
            <WebFooter />
          </div>
        </div>
      </div>
    );
}

export default Web4;

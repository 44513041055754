export const fetchGeoMapData = async () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          const apiKey = "AIzaSyAidNgKafDZvB6960WgN84sAV9hHqRYGYY";
          const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;

          try {
            const response = await fetch(apiUrl);
            const data = await response.json();
            // console.log("Data from google api", data);
            if (data.status === "OK") {
              const result = data.results[0];
              const addressComponents = result.address_components;
              const zipCode = addressComponents.find((component) =>
                component.types.includes("postal_code")
              );

              resolve({
                formattedAddress: result.formatted_address,
                zipCode: zipCode ? zipCode.long_name : null,
                coordinates: {
                  lat: result.geometry.location.lat,
                  lng: result.geometry.location.lng,
                },
              });
            } else {
              console.error("Geocoding API Error:", data.status);
              reject(new Error(`Geocoding API Error: ${data.status}`));
            }
          } catch (error) {
            console.error("Error fetching data:", error);
            reject(error);
          }
        },
        (error) => {
          console.error("Error getting location:", error);
          reject(error);
        }
      );
    } else {
      console.error("Geolocation not supported");
      reject(new Error("Geolocation not supported"));
    }
  });
};

import React from "react";
import styles from "./BalanceCard.module.css";
import { MdEmail } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const BalanceCard = ({ item }) => {
  console.log("Item to update is  1=>", item);
  const navigate = useNavigate();
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.header}>
          <p className={styles.subHeader}>
            <span>
              Acct No {item.accountNumber ? `${item.accountNumber}` : "NA"}
            </span>

            <span>
              {item?.firstName} {item?.middleName} {item?.lastName}{" "}
              <span style={{ marginLeft: "0.2rem" }}>
                <MdEmail />
              </span>
            </span>
          </p>
          <p className={styles.subHeader}>
            <span>Total Balance</span>

            <span>{item?.sellPriceFees?.balanceDue}</span>
          </p>
        </div>
        <div className={styles.content}>
          <div className={styles.contentLine}>
            <p>
              Vehicle Info :{" "}
              <span>
                {item?.addListing2?.modelYear} {item?.addListing2?.modelMake}{" "}
                {item?.addListing2?.makeModel}
              </span>
            </p>

            <button
              onClick={() =>
                navigate("payment", {
                  state: {
                    item: item,
                    isEditing: false,
                  },
                })
              }
            >
              Payment
            </button>
          </div>
          <div className={styles.contentLine}>
            <p>
              Due Payment : <span>Null</span>
            </p>
            <button onClick={() => navigate("paymentHistory", { state: item })}>
              Payment History
            </button>
          </div>
          <div className={styles.contentLine}>
            <p>
              Due Date : <span>12/12/2024</span>
            </p>
            <button onClick={() => navigate("dealDetails", { state: item })}>
              Details
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BalanceCard;

import React, { useEffect, useState } from "react";
import Styles from "./setup.module.css";
import BasicInfo from "../components/BasicInfo";
import { Link, useLocation, useNavigate, useOutletContext } from "react-router-dom";
import Permissions from "../components/Permissions";
import LeadNotification from "../components/LeadNotification";
import SecuritySettings from "../components/SecuritySettings";
import HistoryTable from "../components/HistoryTable";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Col, Container, Row } from "react-bootstrap";
import {
  Tabs,
  Tab,
} from "react-bootstrap";
import axios from "axios";
import { useSelector } from "react-redux";
import Image from "../../../imgData";
const DealerSetupUser = () => {
  const { id } = useSelector((state) => state.user);
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [formData, setFormData] = useState({
    dealerId: id,
    id: 0,
    firstName: "",
    lastName: "",
    email: "",
    jobTitle: "",
    phoneNumber: "",
    landlineNumber: "",
    password: "",
    confirmpassword: "",
    roles: "dealer",
    sms_notify: false,
    email_notify: false,
  });
  const isExpanded = useOutletContext();

  // for tab----------------------------------------------
  const [activeTab, setActiveTab] = useState("tab1");
  const handleNextButtonClick = () => {
    const tabKeys = [
      "tab1",
      "tab2",
    ];
    const currentIndex = tabKeys.indexOf(activeTab);

    if (currentIndex < tabKeys.length - 1) {
      const nextTab = tabKeys[currentIndex + 1];
      setActiveTab(nextTab);
    }
  };

  const handleBackButtonClick = () => {
    const tabKeys = ["tab1", "tab2"];
    const currentIndex = tabKeys.indexOf(activeTab);

    if (currentIndex > 0) {
      const prevTab = tabKeys[currentIndex - 1];
      setActiveTab(prevTab);
    }
  };
  // ---------------------------------------------------
  console.log("formData57", formData);
  // for images -----------------------------------
  const handleImageUpload = (binaryData) => {
    setImageData(binaryData); // Update state with the binary image data
    console.log('Uploaded Image Data:', binaryData);
  };
  // -----------------------------------------------
  const [userMessageError,setUserMessageError] = useState("");
  const handleUpdateuserInfo = async () => {
    if (!formData.email || !formData.phoneNumber) {
      toast.error("Email & PhoneNumber Required.");
      return;
    }
     // Check if passwords are empty
  if (!formData.password || !formData.confirmpassword) {
    toast.error("Both Password fields are required.");
    return;
  }

  // Check if passwords match
  if (formData.password !== formData.confirmpassword) {
    toast.error("Passwords do not match.");
    return;
  }
    setLoading(true);
    const fd = new FormData();
    fd.append("profile_picture", imageData);
    fd.append("data", JSON.stringify(formData));
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/add_user`,
        fd
      );
      if (res.status === 200) {
        navigate("/dealer/users");
      }
    } catch (error) {
      toast.error(error.response.data);
      setUserMessageError(error.response.data);
      console.log(error.response.data);
    } finally {
      setLoading(false); // Set loading to false when the request finishes
    }
  };
   // -------------------------------------------------------------------------------
   const [userCount, setUserCount] = useState("");
   useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/user/get_dealer_users/${id}`);

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const result = await response.json();
        setUserCount(result);
        console.log("result", result);
      } catch (err) {
        console.log(err.message);
      } finally {
        console.log(false);
      }
    };

    fetchData();
  }, []); // Re-run when the id changes
  // ----------------------------------------------------------------------------------
  const getBackgroundColor = () => {
    if (location.pathname === "/dealer/users") {
      return 'lightblue'; // Active route color
    }
    return ''; // Default color
  };
  const navigateDelete = () =>{
    navigate("/dealer/users/deleted");
  }
  const allUserData = () =>{
    navigate("/dealer/users");
  }
  return (
    <div className={`px-0 mx-auto ${Styles.wrapper} ${isExpanded ? Styles.expanded : Styles.collapsed}`}>
      <Container fluid className={`p-3 ${Styles.container}`}>
        {/* <Row className={`d-flex justify-content-end py-2`}>
         
          <Col sm={2} className={`d-flex justify-content-end`}>
            <Link to='/dealer/users' className={`${Styles.linkOrange} border-0`}>All Users</Link>
          </Col >
          <Col sm={3} className={`d-flex justify-content-center`}>
            <Link to='/dealer/users/deleted' className={`${Styles.linkOrange} border-0 me-3`}>Deleted User</Link>
          </Col >
          <Col sm={2} className={`d-flex justify-content-start`}><Button className={`btn-orange border-0 px-5`} onClick={handleUpdateuserInfo} disabled={loading}>{loading ? <div className={Styles.spinner}></div> : 'Apply'}</Button></Col>
          
        </Row> */}
        <Row className={`d-flex justify-content-evenly my-4`}>
        <Col sm={3} className={`p-3 border rounded text-center shadow d-flex justify-content-between`} onClick={allUserData} style={{ backgroundColor: getBackgroundColor() }}>
          <div className={``}>
            <h1>{userCount?.all_users}</h1>
            <span className='text-warning fw-bolder'> All Users </span>
          </div>
          <div className={`${Styles.profile} border-0`}>
            <img src={Image.user_icon} className={`p-2 rounded`} alt='All Users' width="65px" height='65px' />
          </div>
        </Col>
        <Col sm={3} className={`p-3 border rounded text-center shadow d-flex justify-content-between`}>
          <div className={``}>
            <h1>{userCount?.active_users}</h1>
            <span className='text-success fw-bolder'>Actives Users</span>
          </div>
          <div className={`${Styles.profile} `}>
            <img src={Image.user_icon} className={`p-2 rounded`} alt='Actives Users' width="65px" height='65px' />
          </div>
        </Col>
        <Col sm={3} className={`p-3 border rounded text-center shadow d-flex justify-content-between`} onClick={navigateDelete} >
          <div className={``} style={{cursor:"pointer"}}>
            <h1>{userCount?.deleted_users}</h1>
            <span className='text-danger fw-bolder' >Deleted users</span>
          </div>
          <div className={`${Styles.profile} `}>
            <img src={Image.user_icon} className={`p-2 rounded`} alt='Deleted Users' width="65px" height='65px' />
          </div>
        </Col>
      </Row>
       <Row className={`d-flex justify-content-end py-2`}>
         
          {/* <Col sm={2} className={`d-flex justify-content-end`}>
            <Link to='/dealer/users' className={`${Styles.linkOrange} border-0`}>All Users</Link>
          </Col >
          <Col sm={3} className={`d-flex justify-content-center`}>
            <Link to='/dealer/users/deleted' className={`${Styles.linkOrange} border-0 me-3`}>Deleted User</Link>
          </Col > */}
          <Col sm={2} className={`d-flex justify-content-start`}><Button className={`btn-orange border-0 px-5`} onClick={handleUpdateuserInfo} disabled={loading}>{loading ? <div className={Styles.spinner}></div> : 'Apply'}</Button></Col>
          
        </Row>
        {/* <SecuritySettings /> */}
        {/* <HistoryTable /> */}
        {/* <Tabs 
        activeKey={activeTab}
        onSelect={(k) => setActiveTab(k)}
        id="my-tabs"
    >*/}
        {/* <Tab eventKey="tab1" title="Users"> */}
        <BasicInfo formData={formData} setFormData={setFormData} onImageUpload={handleImageUpload} userMessageError={userMessageError} setUserMessageError={setUserMessageError}/>
        {/* </Tab> */}
        {/* <Tab eventKey="tab2" title="Information"> */}
        <Permissions formData={formData} setFormData={setFormData} />
        <LeadNotification formData={formData} setFormData={setFormData} />
        {/* </Tab> */}
        {/* </Tabs> */}
      </Container>
      <ToastContainer />
    </div>
  );
};

export default DealerSetupUser;

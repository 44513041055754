import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const QuarterRevenueChart5 = () => {
  const labels = ['Q1', 'Q2', 'Q3', 'Q4'];

  const chartData = {
    labels,
    datasets: [
      {
        label: 'Revenue',
        data: [10, 45, 25, 65],
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
        pointBackgroundColor: 'rgba(75, 192, 192, 1)',
        pointBorderColor: 'rgba(75, 192, 192, 1)',
        pointRadius: 5,
        pointHoverRadius: 7,
        fill: true,
      },
    ],
  };

  const chartOptions = {
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false, // Hide horizontal grid lines
        },
        ticks: {
          color: '#D3D3D3',
        },
        border: {
          display: true, // Show y-axis line
        },
      },
      x: {
        grid: {
          display: true, // Show vertical grid lines
          color: 'rgba(255, 255, 255, 0.3)',
          borderDash: [5, 5],
        },
        ticks: {
          color: '#D3D3D3',
        },
        border: {
          display: true, // Show x-axis line
          color: 'rgba(255, 255, 255, 0.3)',
          borderWidth : 1
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: 'white',
          display : false
        },
      },
      title: {
        display: true,
        text: 'Quarterly Revenue',
        color: 'white',
        display : false
      },
      tooltip: {
        enabled: true, // Disable tooltips
      },
    },
  };

  const plugins = [{
    afterDatasetsDraw: (chart) => {
      const ctx = chart.ctx;
      chart.data.datasets.forEach((dataset, i) => {
        const meta = chart.getDatasetMeta(i);
        if (!meta.hidden) {
          meta.data.forEach((element, index) => {
            const data = dataset.data[index];
            ctx.fillStyle = 'white';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';
            ctx.font = '12px Arial';
            ctx.fillText(data, element.x, element.y - 10);
          });
        }
      });
    }
  }];

  return (
    <div style={{
      backgroundColor: '#2a2b4a',
      padding: '10px',
      borderRadius: '10px',
      color: 'white',
      width: '500px',
      height : "100%",
      margin: 'auto',
      display : "flex",
        justifyContent : "center",
        alignItems : "center"
 
    }}>
      <Line data={chartData} options={chartOptions} plugins={plugins} style={{height : "100%"}}/>
    </div>
  );
};

export default QuarterRevenueChart5;
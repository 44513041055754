import Image from "../../imgData";


const AdvertisingProfile = ({ title, logo, statusName, intervalName, status, interval, handleCheckboxChange, handleSelectChange, toggleImage, readonly }) => {
    return (
        <div
            className={`col-md-12 shadow mb-2 ${status ? "custom-bg" : "bg-custom"} rounded border border-secondary`}>
            <div className="row d-flex align-items-center" id="adlst-responsive">
                <div className="col-md-1">
                    <div className="form-check">
                        <label className="containers">
                            {console.log(statusName)}
                            <input type="checkbox"
                                name={statusName}
                                checked={status}
                                disabled={readonly}
                                onChange={(e) => handleCheckboxChange(e, intervalName)} />
                            <span className="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div className="col-md-1">
                    <img src={logo} className="Darkslogo p-0" alt={`${title} Logo`} />
                </div>
                <div className="col-md-6 fw-bold">
                    <div style={{ fontSize: "20px" }}>{title}</div>
                </div>
                <div className="col-md-1 fw-bold" onClick={toggleImage}>
                    <img
                        src={status ? Image.connectedadvmanager : Image.connectadvmanager}
                        style={{ height: "30px", width: "30px" }}
                        alt="Adv Manager" />
                </div>
                <div className="col-md-3">
                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        <div className="input-group mt-2 mb-2">
                            <select
                                className="form-select"
                                name={intervalName}
                                value={interval}
                                disabled={status ? false : true}
                                onChange={handleSelectChange}>
                                <option value="">Select Interval</option>
                                <option value="Every Week">Every Week</option>
                                <option value="Weekly">Weekly</option>
                                <option value="ByWeekly">Bi-Weekly</option>
                                <option value="Monthly">Monthly</option>
                            </select>
                            <label className="input-group-text text-light fw-bold fs-5"
                                style={{ backgroundColor: "#d1823a" }}>
                                Repost
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdvertisingProfile;
import "../Styles/Dealer_Input_Login_feature.css";
import React, { useEffect, useState } from "react";
import "../Styles/Dealer_Input_Login_feature.css";
import axios from "axios";
import Fingerprint2 from 'fingerprintjs2';
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Image from "../../imgData";


const Dealer_ForgetPass_SetPass_Feature = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    console.log("location.state in Dealer_SetPass_ForgetPass_Feature",location.state)
  const [showPass, setShowPass] = useState(false);
  const navigate = useNavigate()

  const [InputPass, setInputPass] = useState("");
  const [InputPassError, setInputPassError] = useState(null);

  const [InputConfirmPass, setInputConfirmPass] = useState("");
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [InputConfirmPassError, setInputConfirmPassError] = useState("");

  const [passwordMatch, setPasswordMatch] = useState(true);
  const [fingerprint, setFingerprint] = useState('');
  console.log(fingerprint)

  // useEffect(() => {
  //   if(!InputPass && !InputConfirmPass){
      
  //   }
  // },[InputPass,InputConfirmPass])
  useEffect(() => {
    const getDeviceFingerprint = () => {
      return new Promise((resolve, reject) => {
        Fingerprint2.get((components) => {
          const values = components.map(component => component.value);
          const fingerprintId = Fingerprint2.x64hash128(values.join(''), 31);
          resolve(fingerprintId);
        });
      });
    };

    const fetchFingerprint = async () => {
      try {
        const fingerprintId = await getDeviceFingerprint();
        setFingerprint(fingerprintId);
      } catch (error) {
        console.error('Error getting fingerprint:', error);
      }
    };

    // Wait for fingerprint to be generated before rendering the component
    const timeout = setTimeout(() => {
      fetchFingerprint();
    }, 1000); // Adjust timeout value as needed

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}$/;

  const handleChangeView = () => {
    setShowPass(!showPass);
  };
  const handleChangeView2 = () => {
    setShowConfirmPass(!showConfirmPass);
  };

  const handleInputChange = (e) => {
    setInputPass(e.target.value);

    if (!passwordRegex.test(e.target.value)) {
      setInputPassError(
        "Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, and one special character."
      );
    } else {
      setInputPassError(null);
    }

    if (InputConfirmPass && e.target.value !== InputConfirmPass) {
      setPasswordMatch(false);
    } else {
      setPasswordMatch(true);
    }
  };

  const handleInputChange2 = (e) => {
    setInputConfirmPass(e.target.value);
    if (!passwordRegex.test(e.target.value)) {
      setInputConfirmPassError(
        "Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, and one special character."
      );
    } else {
      setInputConfirmPassError(null);
    }

    if (InputPass && e.target.value !== InputPass) {
      setPasswordMatch(false);
    } else {
      setPasswordMatch(true);
    }
  };

  useEffect(() => {
    if (
      passwordMatch &&
      !InputPassError &&
      !InputConfirmPassError &&
      InputPass &&
      InputConfirmPass
    ) {
      PassMatchAPI();
    }
  }, [
    passwordMatch,
    InputPass,
    InputConfirmPass,
    InputConfirmPassError,
    InputPassError,
  ]);



  const PassMatchAPI = async () => {
    console.log("Calling the API...");
    const formData = new FormData();
    
    try {
        formData.append("password", InputPass)
        formData.append("email", location.state)
        formData.append("confirmpassword", InputConfirmPass)
        formData.append("fingerprint", fingerprint)
        formData.append("user_type", "Dealer")
        console.log(formData)
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/user/change_password`, formData);
        if(res.status === 200){
          navigate("/dealer/login")
          // dispatch(saveUser())
          console.log("response while creating and confirming new password when forgot password",res)
          // After successfully reset the password then first close the reset password window and then open the login screen again
         
        }
        
    } catch (error) {
        console.log("Error in catch part",error)
    }
  };

  return (
    <div className="login_feature_wrapper">
      <div className="login_feature_container">
        <p className="welcome_message">Dealer Log-in</p>
        {/* <p className="sign_in_message">Please enter and confirm your new password</p> */}
        {/* <label htmlFor="new_password" className="email_username_label">
         New Password *
        </label> */}
       <div className="input_and_icon">
            <input
              type={!showPass ? "password" : "text"}
              onChange={handleInputChange}
              value={InputPass}
              placeholder="Enter Password"
              id="new_password"
            />
            <img
              src={!showPass ? Image.eye_icon : Image.hide_icon}
              alt="hide/unhide_icon"
              height={20}
              onClick={handleChangeView}
            />
          </div>
          <p className="error_pass_set">{InputPassError}</p>
         
          {/* <label htmlFor="confirm_password" className="email_username_label">
         Confirm Password *
        </label> */}
          <div className="input_and_icon" style={{marginTop : "4rem"}}>
            <input
              type={!showConfirmPass ? "password" : "text"}
              onChange={handleInputChange2}
              value={InputConfirmPass}
              placeholder="Confirm Password"
              id="confirm_password"
            />
            <img
              src={!showConfirmPass ? Image.eye_icon : Image.hide_icon}
              alt="hide/unhide_icon"
              height={20}
              onClick={handleChangeView2}
            />
          </div>
          <p className="error_pass_set">{InputConfirmPassError}</p>
          {!passwordMatch && (
            <p style={{ color: "red" }}>Passwords do not match.</p>
          )}
          
     
    </div>
    </div>
  );
};

export default Dealer_ForgetPass_SetPass_Feature;

import { IoIosArrowDown } from "react-icons/io";
import styles from "./Quote.module.css";
import Styles from "./Labels.module.css";
import { useEffect, useRef, useState } from "react";
import QuoteData from "./QuoteData.js";
import { useSelector } from "react-redux";
import axios from "axios";
import Select from "react-select";
import toast, { Toaster } from "react-hot-toast";
import { Button, Container, Dropdown, Modal } from "react-bootstrap";
import Labels from "./Labels.jsx";
import { BiReset, BiSave } from "react-icons/bi";
// for data labels-------------------------------
import retailData from "./retailData";
import wholeSaleData from "./wholeSaleData";
import outOfSaleData from "./outOfSaleData";
import { useDispatch } from "react-redux";
import usaStates from "../../DealerInventory/DealerAddInventory/usaStates.json";
import {
  addSellPriceLabelsFast,
  setDynamicAPILabelsFast,
} from "../../../../Redux/slice/fastSlice.js";
import { DefaultDynamicLabels } from "../../DealerSell/DefaultDynamicLabels.js";

const apiUrl = process.env.REACT_APP_API_URL;
const Quote = () => {
  const { dealerId } = useSelector((state) => state.user);
  const Userdata = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [isAssetOpened, setIsAssetOpened] = useState(false);
  const [checkBoxData,setCheckBoxData]  = useState(null);
  const allInputs = useSelector((state) => state.fast.SellPriceInputs);
  const tottalSumInputs = useSelector((state) => state.fast.totalLabelSum);
  console.log("tottalSumInputs43654", tottalSumInputs);
  console.log("allInputs45765", allInputs);
  const [isDropDownOpened, setIsDropDownOpened] = useState(false);
  const [dropDownOptionSelect, setdropDownOptionSelect] =
    useState("Retail/Cash");
  const [quoteData, setQuoteData] = useState(QuoteData); // Assuming QuoteData is your initial state
  // console.log("retailData",retailData);
  //   form data -------------------------------------------------------************************************---------------------------------------------
  const [formData, setFormData] = useState({
    dealerId: dealerId,
    checkQuote: {
      cash: false,
      card: false,
      wire: false,
      cashier: false,
      cheque: false,
      finance: false,
      trade: false,
      others: false,
    },
    listingId: 0,
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    salesPrice: 0,
    tradeIn: 0,
    ownedTradeIn: 0,
    cashDifference: 0,
    fastQuoteLabels: [],
    // calculation-------------------------------
    saleType: "Retail/Cash",
    salesTaxPerc: 6.25,
    inventoryTaxes: 0.00148,
    salesTaxes: 0,
    inventoryTaxValue: 0,
    totalTaxes: 0,
    totalFees: 0,
    total: 0,
  });
  const [taxesAndFees, setTaxesAndFees] = useState({
    salesTaxPercent: 6.25,
    salestaxvalue: 0,
    inventoryTaxPercent: 0.00148,
    inventoryTaxValue: 0,
  });
  // ---------------calculation----------------------------------------------
  const [saletaxValue, setSaleTaxValue] = useState(0);
  const [inventorytaxValue, setInventoryTaxValue] = useState(0);
  const [totalTaxesValue, setTotalTaxesValue] = useState(0);
  const [sumTotalFees, setSumTotalFees] = useState(0);
  const [totalAllvalue, setTotalAllValue] = useState(0);
  useEffect(() => {
    // Convert salesPrice to a number before calculation
    const price = parseFloat(formData.salesPrice - formData.tradeIn);
    const cashDiffer =
      price + parseFloat(formData.ownedTradeIn ? formData.ownedTradeIn : 0);
    if (!isNaN(price) && price > 0) {
      // If price is valid, calculate the sales tax
      const calculatedTax = (price * 6.25) / 100;
      const calculatedInTax = price * 0.001488;
      const calculatedTotalTax = calculatedTax + calculatedInTax;
      setSaleTaxValue(calculatedTax);
      setInventoryTaxValue(calculatedInTax);
      setTotalTaxesValue(calculatedTotalTax);
      setFormData((prevData) => ({
        ...prevData,
        salesTaxes: parseFloat(calculatedTax.toFixed(2)),
        inventoryTaxValue: parseFloat(calculatedInTax.toFixed(2)),
        totalTaxes: parseFloat(calculatedTotalTax.toFixed(2)),
        cashDifference: parseFloat(
          `${formData.tradeIn === 0 ? 0 : cashDiffer.toFixed(2)}`
        ),
      }));
    } else {
      setSaleTaxValue(0);
      setInventoryTaxValue(0);
    }
  }, [formData.salesPrice, formData.tradeIn, formData.ownedTradeIn, formData.totalFees,
    formData.totalTaxes]);
  // --------------------------------for sum of all form data for total fees--------------------------
  // State to store the sum of numeric values
  useEffect(() => {
    console.log("dsgfdhsum", tottalSumInputs);
    setSumTotalFees(tottalSumInputs); // Update the sum state
    const totalSumAndTotalTax = tottalSumInputs + totalTaxesValue;

    const totalFinal = parseFloat(
      totalSumAndTotalTax +
        (formData.cashDifference === 0
          ? formData.salesPrice
          : formData.cashDifference)
    );
    console.log("dsgfdh", totalFinal);
    setTotalAllValue(totalFinal ? totalFinal.toFixed(2) : 0);
    setFormData((prevData) => ({
      ...prevData,
      totalFees: totalSumAndTotalTax,
      total: Number(totalFinal ? totalFinal.toFixed(2) : 0),
    }));
  }, [
    tottalSumInputs,
    formData.totalFees,
    formData.totalTaxes,
    formData.salesPrice,
    formData.cashDifference,
  ]); // Recalculate sum whenever formData changes
  // ------------------------------------------------------------------------
  //  labels api ---------------------------------------------------------------------
  const fetchDynamicLabels = async (saleTy) => {
    let res; // Declare 'res' here so it's available throughout the function.

    // Use switch statement to assign value to 'res' based on 'saleTy'
    switch (saleTy) {
      case "Retail":
        res = retailData;
        break;
      case "wholeSale":
        res = wholeSaleData;
        break;
      case "outOfSale":
        res = outOfSaleData;
        break;
      default:
        res = retailData; // Default to retailData if no match
        break;
    }
    console.log("res34654765", res);
    // Dispatch the mapped response to the store (assuming `dispatch` and `setDynamicAPILabelsFast` are defined)
    dispatch(setDynamicAPILabelsFast(res));
  };
  //  for set the data to fomrdata------------------------------
  useEffect(() => {
    if (allInputs) {
      setFormData((prevState) => ({
        ...prevState,
        fastQuoteLabels:
          allInputs &&
          allInputs?.map((item) => ({
            itemName: item.label,
            itemValue: Number(item.InputValue),
          })),
      }));
    }
  }, [allInputs]);
  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    // Find the selected asset based on listingId
    if (name === "listingId") {
      const selectedAsset = assets.find(
        (asset) => asset.listingId === Number(value)
      );
      const listingPrice = selectedAsset ? selectedAsset.listingPrice : null;

      setFormData((prevData) => ({
        ...prevData,
        [name]: Number(value), // Update listingId
        salesPrice: Number(listingPrice), // Set listingPrice separately
      }));
    } else {
      const newValue = name === "listingId" ? Number(value) : value;
      setFormData({ ...formData, [name]: newValue });
    }
  };

  const handleChangeSaleType = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    console.log("value24355", value);
    switch (value) {
      case "Retail/Cash":
        fetchDynamicLabels("Retail");
        break;
      case "Whole Sale":
        fetchDynamicLabels("wholeSale");
        break;
      case "Out of state":
        fetchDynamicLabels("outOfSale");
        break;
      default:
        fetchDynamicLabels("Retail");
    }

    setFormData({ ...formData, [name]: value });
  };
  // -------------------------------********************-----Important handle Input Change------------------------------------------------
  // Event handler for input changes
  const formatPhoneNumber = (number) => {
    // Remove non-digit characters
    const digits = number.replace(/\D/g, "");

    // Format the digits into the US phone number format (XXX) XXX-XXXX
    if (digits.length <= 3) {
      return digits;
    } else if (digits.length <= 6) {
      return `(${digits.slice(0, 3)}) ${digits.slice(3)}`;
    } else {
      return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(
        6,
        10
      )}`;
    }
  };

  const handleInputChange = (key, event, isPersonalDetail) => {
    const newValue = event.target.value;

    // Handle phone number formatting
    if (key === "phoneNumber") {
      const formattedPhoneNumber = formatPhoneNumber(newValue);
      setFormData((prevData) => ({
        ...prevData,
        [key]: newValue.replace(/\D/g, ""),
      }));
      event.target.value = formattedPhoneNumber;
    }
    // Handle personal details (non-numeric fields)
    else if (
      isPersonalDetail ||
      key === "firstName" ||
      key === "lastName" ||
      key === "email"
    ) {
      setFormData((prevData) => ({
        ...prevData,
        [key]: newValue,
      }));
    }
    // Handle numeric fields (including decimals)
    else {
      const regex = /^-?\d*\.?\d*$/;

      if (newValue === "" || regex.test(newValue)) {
        // First update with string value to allow typing
        setFormData((prevData) => ({
          ...prevData,
          [key]: newValue,
        }));

        // Then update with number value after a small delay
        if (newValue !== "") {
          setTimeout(() => {
            setFormData((prevData) => ({
              ...prevData,
              [key]: Number(prevData[key]),
            }));
          }, 1000);
        }
      }
    }
  };

  //  for check box----------------------------------------------------
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    setFormData((prevState) => ({
      ...prevState,
      checkQuote: {
        ...prevState.checkQuote,
        [name]: checked, // Dynamically update the key based on checkbox name
      },
    }));
  };

  // ------------------------------------------------end of input changes functionality---------------------------------------------
  const [isTradeInChecked, setIsTradeInChecked] = useState(false);
  const [assets, setAssets] = useState([]);
  const saleTypeOptions = [
    "Retail/Cash",
    "Finance/BHPH",
    "Out of State",
    "Whole Sale",
    "Rent & Lease",
  ];
  // for at the time check for trade-in----------------------------
  const handelCheckBox = () => {
    setIsTradeInChecked(!isTradeInChecked);
    console.log("isTradeInChecked", isTradeInChecked);
  };
  // ---------------------------------------------------------
 
  // ------------------for check save this to backend------------------
  const handleSaveCheckBox = async () => {
    // Assuming formData.checkQuote is the object you want to send
    const checkQuoteValue = formData.checkQuote;
  
    console.log("Call Check Box...", checkQuoteValue);
  
    // Define the API endpoint
    const apiUrlCheck = `${apiUrl}/fastQuote/save/payment_options`;
    const payload = {
      userId: dealerId,  // Add the userId
      ...checkQuoteValue,  // Spread the existing checkQuoteValue object
    };
    try {
      // Make the POST request, sending only the checkQuote object
      const response = await fetch(apiUrlCheck, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload), // Send only the object itself
      });
  
      // Check if the request was successful
      if (response.ok) {
        toast.success("Check-Box Save Successfully...");
      } else {
        console.error("API Error:", response.statusText);
      }
    } catch (error) {
      console.error("Error occurred while calling API:", error);
    }
  };
  //  for get check-box status for show on check box-------------------------------------------
  useEffect(() => {
    const fetchAssets = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/fastQuote/get_options/${dealerId}`
        );
        console.log("response =>checkbox ", response.data.data);
        setCheckBoxData(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchAssets();
  }, []);
  console.log("formdataCheck12345",checkBoxData);
  //  for show the check box on ui------------------------------------------------
  useEffect(()=>{
   if(checkBoxData){
    setFormData((prevState) => ({
      ...prevState,
      checkQuote: {
        ...prevState.checkQuote,
        card: checkBoxData?.card || false,
        cash: checkBoxData?.cash || false,
        cashier : checkBoxData?.cashier || false,
        cheque : checkBoxData?.cheque || false,
        finance : checkBoxData?.finance || false,
        others  : checkBoxData?.others || false,
        trade   : checkBoxData?.trade || false,
        wire   : checkBoxData?.wire || false,
      },
    }));
   }
  },[checkBoxData]);
  // -----------------------------------------------------------------
  useEffect(() => {
    const fetchAssets = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/listing/get_assets/${dealerId}`
        );
        console.log("response => ", response);
        setAssets(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchAssets();
  }, []);
  // for send the data for create pdf for fast quote---------------------------------------
  // for show the pdf______________
  const [quoteForm, setQuoteForm] = useState([]);
  const [showPdf, setShowPdf] = useState(false);
  const [loading, setLoading] = useState(false);
  console.log("quoteForm", quoteForm);
  // _________________________
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const handleGenerate = async (e) => {
    console.log("handleGenerate");
    e.preventDefault();
    if (
      !formData.firstName ||
      !formData.lastName ||
      !formData.phoneNumber ||
      !formData.email
    ) {
      toast.error(
        "First name, Last Name, Mobile Number and Email are required fields."
      );
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(
        `${apiUrl}/fastQuote/get_quote`,
        formData
      );
      setQuoteForm([response.data.file]);
      toast.success("Quote Successfully Save...");
      await delay(3000);
      // Show the modal
      setShowPdf(true);
      console.log("Response:", response.data);
    } catch (error) {
      console.error("There was an error!", error);
      toast.error(error);
      // setError("Detail already exist");
    } finally {
      setLoading(false); // Set loading to false when the request finishes
    }
  };

  //---------------------------------------------------------------------------------------
  console.log("Assets1234", assets);
  console.log("formDat123", formData);
  console.log("quoteData", quoteData);
  const [selectedAsset, setSelectedAsset] = useState(null);

  // This function will be called when an option is selected from the dropdown
  const handleSelectedAsset = (asset) => {
    console.log("Selected asset => ", asset);

    // Update form data with listingId and listingPrice of the selected asset
    setFormData((prevData) => ({
      ...prevData,
      listingId: asset?.value, // listingId
      salesPrice: Number(asset?.listingPrice), // listingPrice
    }));

    // Set the selected asset (full object)
    setSelectedAsset(asset);
  };

  console.log("Selected Asset: ", selectedAsset);

  // Format the assets for the dropdown options
  const formattedAssets = assets.map((asset) => ({
    label: asset.assetName, // Display asset name in the dropdown
    value: asset.listingId, // Use listingId as the unique value
    listingPrice: asset.listingPrice, // Attach listingPrice to each option
  }));
  // ----------------------------------------------------------------
  const [showLabelPopout, setShowLabelPopout] = useState(false);
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [filteredStates, setFilteredStates] = useState([]);
  const searchInputRef = useRef(null);
  const [selectedState, setSelectedState] = useState("TX - Texas");

  useEffect(() => {
    if (search.length > 0) {
      // Filter states based on the search input
      setFilteredStates(
        usaStates.filter((state) =>
          state.name.toLowerCase().includes(search.toLowerCase())
        )
      );
    } else {
      setFilteredStates(usaStates);
    }
  }, [search, usaStates, isOpen]);

  // Handle the search input change
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };
  // Handle state selection
  const handleSelectState = (state) => {
    setSelectedState(state);
  };
  const handleSelect = (eventKey) => {
    setSearch(""); // Clear search input when an item is selected
    setIsOpen(false); // Close the dropdown
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.assetSelectContainer1}>
          {/* <span className={styles.selectItem}>Select Asset</span> */}
          <div className={styles.assetSelector}>
            {/* <div className={styles.assetListContainer}> */}
            <Select
              options={formattedAssets}
              className={styles.assetSelector_field}
              value={selectedAsset} // Pass the whole selected asset object
              onChange={handleSelectedAsset} // Handle selection
              placeholder="Select Assets"
              getOptionLabel={(e) => e.label} // Customize the label shown in the dropdown
              getOptionValue={(e) => e.value} // Use listingId as the value
              isSearchable
              noOptionsMessage={() => "No assets found"} // Custom no options message
              styles={{
                textAlign: "left", // Align text inside the input box
                control: (base) => ({
                  ...base,
                  textAlign: "left", // Align text in control (the box itself)
                  backgroundColor: "#d1823a",
                  color: "white",
                }),
                singleValue: (base) => ({
                  ...base,
                  textAlign: "left", // Align text for selected value
                  color: "white",
                }),
                placeholder: (base) => ({
                  ...base,
                  textAlign: "left", // Align placeholder text to the left
                  color: "white",
                }),
                menu: (base) => ({
                  ...base,
                  textAlign: "left", // Align text in the dropdown menu
                }),
                option: (base) => ({
                  ...base,
                  textAlign: "left", // Align text inside each dropdown option
                  fontSize: "12px",
                  // backgroundColor:"#d1823a",
                  // color:"white"
                }),
              }}
            />
            {/* </div> */}
          </div>
          <div className={styles.buyerSaleTypeContainer}>
            {/* <span>Sale Type</span> */}
            <p
              className={`${styles.saletypeDropDownContinaer} ${styles.ptag_remove_mar}`}
            >
              <select
                id="saleType"
                name="saleType"
                value={formData.saleType}
                // className={Styles.input_text_field}
                style={{
                  fontWeight: "bolder",
                  backgroundColor: "#d1823a",
                  color: "white",
                  padding: "5px",
                }}
                onChange={handleChangeSaleType}
              >
                {/* <option value="">Sale Type</option> */}
                <option value="Retail/Cash">Retail/Cash</option>
                <option value="Finance/BHPS">Finance/BHPS</option>
                <option value="Out of state">Out of state</option>
                <option value="Whole Sale">Whole Sale</option>
                <option value="Rent & Lease">Rent & Lease</option>
              </select>
            </p>
          </div>
        </div>
        <div className={styles.buyerQuoteInfoParent}>
          <div className={styles.buyerQuoteInfo}>
            {QuoteData.slice(0, 4).map((item) => (
              <div key={item.id} className={styles.buyerPersonalInfo}>
                <p
                  className={`${styles.buyerInfoText} ${styles.ptag_remove_mar}`}
                >
                  {item.inputLabel}
                </p>
                <input
                  type="text"
                  value={
                    item.key === "phoneNumber"
                      ? formatPhoneNumber(formData[item.key])
                      : formData[item.key]
                  } // Format phone number for display
                  onChange={(e) =>
                    handleInputChange(item.key, e, item.key !== "phoneNumber")
                  } // Pass the key and handle input change
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.priceFeesWrapper}>
        <div className={styles.priceFeesRightContainer}>
          <div className={styles.percCalWrapper}>
            {QuoteData.slice(4, 6).map((item, index) => (
              <div key={item.id}>
                {/* Render input fields for all except index 1 and 2 (initially) */}
                {index !== 1 && index !== 2 && (
                  <div className={`${styles.priceFeesContainer}`}>
                    <p className={styles.priceFeesText}>
                      {item.id} {item.inputLabel}
                    </p>
                    <div className={styles.inputWithPercentb}>
                      <span className={styles.percentSymbolb}>$</span>
                      <input
                        type="text"
                        value={formData[item.key]} // Bind to formData
                        onChange={(e) => handleInputChange(item.key, e, false)} // Pass key
                      />
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className={styles.percCalWrapper}>
            {QuoteData.slice(4, 8).map((item, index) => (
              <div key={item.id}>
                {/* Render input fields for all except index 1 and 2 (initially) */}
                {/* Render trade-in checkbox only for index 0 */}
                {index === 0 && (
                  <div className={styles.tradeInCheckBox}>
                    <input
                      type="checkbox"
                      checked={isTradeInChecked}
                      id="tradeIn"
                      onChange={() => handelCheckBox()} // Toggle checkbox state
                    />
                    <label htmlFor="tradeIn">I have Trade In</label>
                  </div>
                )}

                {/* Render input fields for index 1 and 2 only if trade-in is checked */}
                {(index === 1 || index === 2 || index === 3) &&
                  isTradeInChecked && (
                    <div
                      className={`${styles.priceFeesContainer}  ${styles.for_width}`}
                      style={{ marginTop: "1rem" }}
                    >
                      <p className={`${styles.priceFeesText} ${styles.for_text_width}`}>
                        {item.id} {item.inputLabel}
                      </p>
                      <div className={styles.inputWithPercentb}>
                        <span className={styles.percentSymbold}>$</span>
                        <input
                          type="text"
                          value={formData[item.key]} // Bind to formData
                          onChange={(e) =>
                            handleInputChange(item.key, e, false)
                          } // Pass key
                        />
                      </div>
                    </div>
                  )}
              </div>
            ))}
          </div>
          <div className={styles.priceFeesleftContainer}>
            <div>
              <Labels
                allInputs={allInputs && allInputs}
                editStatus={false}
                isTradeInChecked={isTradeInChecked}
              />
            </div>
            <p
              style={{
                border: "none",
                marginBottom: "0",
                display: "flex",
                justifyContent: "end",
                paddingRight: "2rem",
                marginBottom: "0.5rem",
              }}
            >
              <button
                // className="btn-orange"
                onClick={() => {
                  dispatch(setDynamicAPILabelsFast(DefaultDynamicLabels));
                }}
                style={{
                  border: "none",
                  // backgroundColor: "#E8A262",
                  color: "#E8A262",
                  fontWeight: "500",
                  borderRadius: "5px",
                  padding: "0.5rem 0.7rem",
                  fontSize: "17px",
                  marginRight: "1rem",
                }}
              >
                <span>
                  <BiReset />
                </span>
              </button>
              <button
                className="btn-orange"
                onClick={() => {
                  setShowLabelPopout(true);
                }}
                style={{
                  border: "none",
                  // backgroundColor: "#E8A262",
                  color: "white",
                  fontWeight: "500",
                  borderRadius: "5px",
                  padding: "0.5rem 0.7rem",
                  width: "auto",
                }}
              >
                Edit{" "}
                <span
                  style={{
                    color: "white",
                    fontSize: "17px",
                    fontWeight: "700",
                    borderRadius: "5px",
                  }}
                >
                  &#8595;
                </span>
              </button>
            </p>
          </div>
        </div>
        <div className={styles.priceFeesRightContainer}>
          <div className={styles.percCalWrapper}>
            <p className={styles.percCalContainer}>
              <span>Sales Tax</span>
              <div className={styles.perCalInput}>
                <div className={styles.inputWithPercent}>
                  <span className={styles.percentSymbol}>%</span>
                  <input
                    type="text"
                    value={taxesAndFees.salesTaxPercent}
                    readOnly
                  />
                </div>
                {/* <input type="text" value={taxesAndFees.salesTaxPercent} /> */}
                <input type="text" value={saletaxValue} />
              </div>
            </p>
            <p className={styles.percCalContainer}>
              <span>Inventory Tax</span>
              <div className={styles.perCalInput}>
                <div className={styles.inputWithPercent}>
                  <span className={styles.percentSymbol}>%</span>
                  <input
                    type="text"
                    value={taxesAndFees.inventoryTaxPercent}
                    readOnly
                  />
                </div>
                <input type="text" value={inventorytaxValue.toFixed(2)} />
              </div>
            </p>
          </div>
          <div className={styles.percCalWrapper}>
            <p className={styles.percCalContainer2}>
              <span>Total Taxes </span>
              <div className={styles.inputWithPercentc}>
                <span className={styles.percentSymbolc}>$</span>
                <input type="text" value={totalTaxesValue} />
              </div>
            </p>
            <p className={styles.percCalContainer2}>
              <span>Total Fees </span>
              <div className={styles.inputWithPercentc}>
                <span className={styles.percentSymbolc}>$</span>
                <input type="text" value={sumTotalFees} />
              </div>
            </p>
          </div>
          <div className={styles.percCalWrapperPayment}>
            <p className={styles.check_payment_head}>Accepts payments</p>
            <div className={styles.check_payment}>
              <span>
                <input
                  type="checkbox"
                  id="cash"
                  name="cash"
                  checked={formData.checkQuote.cash}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="cash">Cash</label>
              </span>
              <span>
                <input
                  type="checkbox"
                  id="card"
                  name="card"
                  checked={formData.checkQuote.card}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="card">Card (Fees Apply)</label>
              </span>
              <span>
                <input
                  type="checkbox"
                  id="wire"
                  name="wire"
                  checked={formData.checkQuote.wire}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="wire">Wire</label>
              </span>
              <span>
                <input
                  type="checkbox"
                  id="cashier"
                  name="cashier"
                  checked={formData.checkQuote.cashier}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="cashier">Cashier Cash</label>
              </span>
              <span>
                <input
                  type="checkbox"
                  id="cheque"
                  name="cheque"
                  checked={formData.checkQuote.cheque}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="cheque">Cheque</label>
              </span>
              <span>
                <input
                  type="checkbox"
                  id="finance"
                  name="finance"
                  checked={formData.checkQuote.finance}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="finance">Finance Source</label>
              </span>
              <span>
                <input
                  type="checkbox"
                  id="trade"
                  name="trade"
                  checked={formData.checkQuote.trade}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="trade">Trade-In</label>
              </span>
              <span>
                <input
                  type="checkbox"
                  id="others"
                  name="others"
                  checked={formData.checkQuote.others}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="others">Others</label>
              </span>
              <p className={styles.check_paymentSave} onClick={handleSaveCheckBox}>
                <BiSave title="Save Payment Method"/>
              </p>
            </div>
          </div>
          <div className={styles.percCalWrapper3}>
            <p className={styles.percCalContainer3}>
              <span>Total</span>
              <span>$ {formData?.total}</span>
            </p>
          </div>

          <p className={styles.generateBtnContainer}>
            <button type="button" onClick={handleGenerate}>
              {loading ? <div className="spinner"></div> : "Generate"}
            </button>
          </p>
        </div>
      </div>
      {/* <div className={styles.exportContainer}>
        <span className={styles.exportIndicator}>Export</span>
        <button>Print</button>
        <button>Email</button>
      </div> */}
      <Modal show={showPdf} onHide={() => setShowPdf(false)} size="xl">
        <Modal.Header closeButton style={{ gap: "10%" }}>
          <Modal.Title id="example-custom-modal-styling-title">
            Qoute Forms
          </Modal.Title>
          <Modal.Title style={{ display: "flex", width: "45%", gap: "5%" }}>
            <Button className="w-100 pe-0 py-2 btn-orange border-0">
              Print
            </Button>
            <Button className="w-100 pe-0 py-2 btn-orange border-0">
              E-Mail
            </Button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "80vh" }}>
          <Container>
            {quoteForm &&
              quoteForm.map((pdf, i) => {
                return (
                  <div key={i} style={{ height: "80vh" }}>
                    <iframe
                      src={`${process.env.REACT_APP_DOCS_URL}/${dealerId}/${pdf}#page=1&zoom=125`}
                      type="application/pdf"
                      frameborder="0"
                      style={{
                        objectFit: "fill",
                        width: "100%",
                        height: "75vh",
                      }}
                    ></iframe>
                  </div>
                );
              })}
          </Container>
        </Modal.Body>
      </Modal>
      {/*----------------------------------- madel for labels--------------------------------------- */}
      <Modal
        // dialogClassName="modal-90w"
        show={showLabelPopout}
        onHide={() => setShowLabelPopout(false)}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title className={Styles.labelPopoutHeaderContainer}>
            <Dropdown
              show={isOpen}
              onSelect={handleSelectState}
              onToggle={(open) => setIsOpen(open)}
              className={Styles.labelPopoutHeaderDropDown}
            >
              <Dropdown.Toggle
                size="sm"
                className="px-3 py-2 bg-orange border-0 fw-bold text-light"
                onClick={() => setIsOpen(!isOpen)}
              >
                {selectedState}
              </Dropdown.Toggle>
              <Dropdown.Menu className="state-drp">
                {/* Hidden input to manage search */}
                <input
                  type="text"
                  value={search}
                  ref={searchInputRef}
                  autofocus
                  onChange={handleSearchChange}
                  onFocus={() => setIsOpen(true)}
                  style={{
                    position: "absolute",
                    top: "-9999px",
                    left: "-9999px",
                  }} // Hide the input field
                />
                {filteredStates.length > 0 ? (
                  filteredStates.map((state) => (
                    <Dropdown.Item
                      key={state.name}
                      eventKey={state.name}
                      onClick={() => handleSelect(state.name)}
                    >
                      {state.name}
                    </Dropdown.Item>
                  ))
                ) : (
                  <Dropdown.Item disabled>No states found</Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>

            <span>Edit</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={Styles.boxContainer}>
            <Dropdown
              show={isOpen}
              onSelect={handleSelectState}
              onToggle={(open) => setIsOpen(open)}
              className={Styles.labelPopoutHeaderDropDown}
            >
              <Dropdown.Toggle
                size="sm"
                className="px-3 py-2 bg-orange border-0 fw-bold text-light"
                onClick={() => setIsOpen(!isOpen)}
              >
                {selectedState}
              </Dropdown.Toggle>
              <Dropdown.Menu className="state-drp">
                {/* Hidden input to manage search */}
                <input
                  type="text"
                  value={search}
                  ref={searchInputRef}
                  autofocus
                  onChange={handleSearchChange}
                  onFocus={() => setIsOpen(true)}
                  style={{
                    position: "absolute",
                    top: "-9999px",
                    left: "-9999px",
                  }} // Hide the input field
                />
                {filteredStates.length > 0 ? (
                  filteredStates.map((state) => (
                    <Dropdown.Item
                      key={state.name}
                      eventKey={state.name}
                      onClick={() => handleSelect(state.name)}
                    >
                      {state.name}
                    </Dropdown.Item>
                  ))
                ) : (
                  <Dropdown.Item disabled>No states found</Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
            <Labels allInputs={allInputs} editStatus={true} />
            <div
              className={
                Styles.box_b_none_edit_add_button_small_screen_container
              }
            >
              <button
                style={{
                  border: "none",
                  backgroundColor: "#e8a262",
                  color: "white",
                  borderRadius: "5px",
                  padding: "0.35rem 1rem",
                  marginRight: "2rem",
                  fontSize: "medium",
                  marginTop: "0.1rem",
                }}
                className={Styles.box_b_none_edit_add_button_small_screen}
                onClick={() =>
                  dispatch(
                    addSellPriceLabelsFast({
                      id: allInputs && allInputs.length + 1,
                      label: `Input ${allInputs && allInputs?.length + 1}`,
                    })
                  )
                }
                disabled={allInputs && allInputs.length === 20}
              >
                Add
              </button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <p
            style={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            {" "}
            <button
              style={{
                border: "none",
                backgroundColor: "#E8A262",
                color: "white",
                fontWeight: "500",
                borderRadius: "5px",
                padding: "0.5rem 0.7rem",
                marginLeft: "0.7rem",
              }}
              onClick={() => setShowLabelPopout(false)}
            >
              Save
            </button>
          </p>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Quote;

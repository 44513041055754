import React from "react";
import Image from "../../../../imgData";

const NextCarProtoType = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        padding: "1rem 3rem",
        // border: "1px solid blue",
        backgroundColor: "#F4F4F4",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          //   border: "1px solid black",
          width: "90%",
        }}
      >
        <p
          style={{
            display: "grid",
            placeContent: "center",
          }}
        >
          <h1 style={{ fontWeight: "600", width: "65%", fontSize: "50px" }}>
            Your next car is waiting for you here...
          </h1>
          <p>
            {" "}
            <button
              style={{
                border: "none",
                backgroundColor: "#e8a262",
                fontWeight: "700",
                padding: "0.4rem 2rem",
                fontWeight: "600",
                fontSize: "1.5rem",
                borderRadius: "20px",
                marginTop: "1rem",
                boxShadow: "4px 4px  4px rgba(0,0,0,0.2)",
              }}
            >
              More
            </button>
          </p>
        </p>
        <p>
          <img
            src={Image.mobileProtoType}
            alt="Next  Car ProtoType"
            height={500}
            width={420}
          />
        </p>
      </div>
    </div>
  );
};

export default NextCarProtoType;

import React, { useEffect, useState } from "react";
import { PDFDocument } from "pdf-lib";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const PDFViewer = ({ pdfArray }) => {
  const [mergedPdfUrl, setMergedPdfUrl] = useState(null);
  const navigate = useNavigate();

  // Convert imported PDFs to Blob
  const convertToBlob = (pdfPath) => {
    return new Promise((resolve, reject) => {
      fetch(pdfPath)
        .then((response) => {
          if (!response.ok) throw new Error("Network response was not ok");
          return response.blob();
        })
        .then((blob) => resolve(blob))
        .catch((error) => reject(error));
    });
  };

  // Method to merge local PDFs
  const mergePDFs = async (files) => {
    try {
      const mergedPdf = await PDFDocument.create();
      for (const file of files) {
        const existingPdfBytes = await file.arrayBuffer(); // Use arrayBuffer for Blob
        const pdf = await PDFDocument.load(existingPdfBytes, { ignoreEncryption: true } );
        const copiedPages = await mergedPdf.copyPages(
          pdf,
          pdf.getPageIndices()
        );
        copiedPages.forEach((page) => mergedPdf.addPage(page));
      }

      const mergedPdfBytes = await mergedPdf.save();
      return URL.createObjectURL(
        new Blob([mergedPdfBytes], { type: "application/pdf" })
      );
    } catch (error) {
      toast.error(error.message);
      console.log(error)
    }
  };

  useEffect(() => {
    const mergeAndSetPdf = async () => {
      // Filter and convert only the PDFs that should be shown
      const files = await Promise.all(
        pdfArray
          .filter((item) => item.isShow)
          .map(async (item) => await convertToBlob(item.pdf))
      );
      const url = await mergePDFs(files);
      setMergedPdfUrl(url);
    };

    mergeAndSetPdf();
  }, []);

  const downloadMergedPDF = () => {
    if (mergedPdfUrl) {
      const link = document.createElement("a");
      link.href = mergedPdfUrl;
      link.download = "merged_document.pdf"; // Name of the downloaded file
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      toast.error("PDF is not ready for download yet.");
    }
    setTimeout(() => {
      navigate("/dealer/inventory/sold", { state: true });
    }, 1500);
  };

  if (!mergedPdfUrl) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ height: "85vh", marginBottom: "1.5rem" }}>
      <iframe
        src={mergedPdfUrl + "#zoom=150"}
        width="100%"
        height="100%"
        type="application/pdf"
        title="Merged PDF File"
      />
    </div>
  );
};

export default PDFViewer;

import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import './RequestDemoModal.css';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Styles from "../DealerBusinessInformation/Styles/BusinesslocationCard.module.css";
import { TbWorldWww } from 'react-icons/tb';
import { MdPhoneInTalk } from 'react-icons/md';
const apiUrl = process.env.REACT_APP_API_URL;


const RequestDemoModal = ({ show, handleClose }) => {
    const { id } = useSelector((state) => state.user);

    const [formData, setFormData] = useState({
        login_access: 0,
        userId: id,
        dealerShipName: "",
        dealerName: "",
        dealershipPhoneNumber: "",
        dealerTelePhone: "",
    });
    console.log("lineForRequestDemo:", formData);

    const formatPhoneNumber = (value) => {
        if (!value) return value;

        const phoneNumber = value.replace(/[^\d]/g, '');
        const phoneNumberLength = phoneNumber.length;

        if (phoneNumberLength < 4) return phoneNumber;
        if (phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
    };

    const handleChangeb = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        const cleanedValue = value.replace(/[^\d]/g, '');
        setFormData({ ...formData, [name]: cleanedValue });
    };


    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    // api data send -------

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${apiUrl}/dealer/save_details`, formData);
            console.log('Response:', response.data);
            handleClose();  // Close the modal after submission
        } catch (error) {
            console.error('There was an error!', error);
        }
    };

    // ----------------------------------


    return (
        <Modal show={show} size="lg" onHide={handleClose} style={{ marginTop: "5%" }}>
            <Modal.Header closeButton>
                {/* <Modal.Title>Request Demo</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
                {/* <div>
                    <p>
                        <label
                        >
                            Dealership Name:
                        </label>
                        <input
                           type="text"
                           value={formData.dealerShipName}
                           onChange={handleChange}
                           name="dealerShipName"
                        />
                    </p>
                   <div style={{display:"flex",justifyContent:"space-between",gap:"10px"}}> <p>
                        <label
                        >
                            Name:
                        </label>
                        <input
                            type="text"
                            value={formData.dealerName}
                            onChange={handleChange}
                            name="dealerName"
                        />
                    </p>
                    <p>
                        <label
                        >
                            Phone Number:
                        </label>
                        <input
                            type="text"
                            value={formatPhoneNumber(formData.dealershipPhoneNumber)}
                            onChange={handleChangeb}
                            name="dealershipPhoneNumber"
                        />
                    </p>
                    <p>
                        <label
                        >
                           Title Phone Number:
                        </label>
                        <input
                            type="text"
                            value={formatPhoneNumber(formData.dealerTelePhone)}
                            onChange={handleChangeb}
                            name="dealerTelePhone"
                        />
                    </p>
                    </div>
                    
                </div> */}
                <div className={Styles.bussiness_location_card_wrapper} style={{ marginTop: "0rem" }}>
                    <div className={Styles.bussiness_location_card_container} style={{ borderRadius: "0px", border: "none" }}>
                        <div className={Styles.location_card_header_wrapper}>
                            <div className={Styles.location_card_header} style={{ justifyContent: "center" }}>
                                <p>Dealer Request Demo Form</p>
                            </div>
                        </div>
                        <div className={Styles.location_card_main_2} style={{ paddingBottom: "0px" }}>
                            <div className={Styles.business_address_1}>
                                <p className={Styles.dealer_address_line_1_parent}>
                                    <label
                                        htmlFor="dealer_address_line_1"
                                        className={Styles.input_label}
                                        style={{fontWeight:"unset"}}
                                    >
                                        Dealership Name:
                                    </label>
                                    <input
                                        type="text"
                                        name="dealerShipName"
                                        id="dealer_address_line_1"
                                        value={formData.dealerShipName}
                                        className={Styles.input_text_field}
                                        onChange={handleChange}
                                        style={{fontWeight:"bolder"}}
                                    />

                                </p>
                            </div>
                        </div>
                        <div className={Styles.dealer_contact_information_parent} style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                            <div className={Styles.business_address_1}>
                            <p>
                                    <div><label htmlFor="dealer_website" className={Styles.input_label}  style={{fontWeight:"unset"}}>
                                        <span>Name:</span>
                                    </label></div>
                                    <diiv><input
                                        type="text"
                                        id="dealer_website"
                                        name="dealerName"
                                        value={formData.dealerName}
                                        onChange={handleChange}
                                        className={Styles.input_text_field}
                                        style={{fontWeight:"bolder"}}
                                    /></diiv>
                                </p>
                                <p>
                                    <label
                                        htmlFor="dealer_business_phone_number"
                                        className={Styles.input_label}
                                        style={{fontWeight:"unset"}}
                                    >
                                        <span
                                            style={{
                                                color: "orange",
                                                fontSize: "large",
                                                marginRight: "0.3rem",
                                            }}
                                        >
                                            <MdPhoneInTalk />
                                        </span>
                                        <span>Phone Number:</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="dealer_business_phone_number"
                                        value={formatPhoneNumber(formData.dealershipPhoneNumber)}
                                        onChange={handleChangeb}
                                        name="dealershipPhoneNumber"
                                        pattern="\d*"
                                        className={Styles.input_text_field}
                                        maxLength={14}
                                        style={{fontWeight:"bolder"}}
                                    />
                                </p>

                               
                                <p>
                                    <label htmlFor="dealer_website" className={Styles.input_label}  style={{fontWeight:"unset"}}>
                                        <span
                                            style={{
                                                color: "orange",
                                                fontSize: "large",
                                                marginRight: "0.3rem",
                                            }}
                                        >
                                             <MdPhoneInTalk />
                                        </span>
                                        <span>Title Phone Number:</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="dealer_business_phone_number"
                                        value={formatPhoneNumber(formData.dealerTelePhone)}
                                        onChange={handleChangeb}
                                        name="dealerTelePhone"
                                        pattern="\d*"
                                        className={Styles.input_text_field}
                                        style={{fontWeight:"bolder"}}
                                    />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <button className="btn btn-secondary" style={{ width: "150px" }} onClick={handleSubmit}>
                    Submit
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default RequestDemoModal;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { PDFDocument } from "pdf-lib";
import toast from "react-hot-toast";

const ReceiptPDFRender = ({ pdfArray }) => {
  const [mergedPdfUrl, setMergedPdfUrl] = useState(null);

  console.log("Merged PDF URL", mergedPdfUrl);

  const userID = useSelector((state) => state.user);
  //method to merge pdfs
  const mergePDFs = async (pdfArray) => {
    try {
      const mergedPdf = await PDFDocument.create();
      for (const item of pdfArray) {
        const existingPdfBytes = await fetch(
          `${process.env.REACT_APP_DOCS_URL}/${userID.dealerId}/${item}`
        ).then((res) => res.arrayBuffer());
        const pdf = await PDFDocument.load(existingPdfBytes);
        const copiedPages = await mergedPdf.copyPages(
          pdf,
          pdf.getPageIndices()
        );
        copiedPages.forEach((page) => mergedPdf.addPage(page));
      }

      const mergedPdfBytes = await mergedPdf.save();
    
      return URL.createObjectURL(
        new Blob([mergedPdfBytes], { type: "application/pdf" })
      );
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    const mergeAndSetPdf = async () => {
      const url = await mergePDFs(pdfArray);
      setMergedPdfUrl(url);
    };

    mergeAndSetPdf();
  }, []);
  const downloadMergedPDF = () => {
    if (mergedPdfUrl) {
      const link = document.createElement("a");
      link.href = mergedPdfUrl;
      link.download = "Payment Receipt";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      toast.error("PDF is not ready for download yet.");
    }
    setTimeout(() => {
    //   navigate("/dealer/inventory/sold", { state: true });
    }, 1500); // Delay of 2000 milliseconds (2 seconds)
    console.log("Download Successfully.........");
  };
 
  return (
    <div style={{ height: "85vh", marginBottom: "1.5rem" }}>
      <iframe
        src={mergedPdfUrl + "#zoom=110"}
        width="100%"
        height="100%"
        type="application/pdf"
        title="Merged PDF File"
      />
    </div>
  );
};

export default ReceiptPDFRender;

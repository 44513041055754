const feesDetail = [
  { id: "documentationFees", label: "Documentation Fees", value: 150.0 },
  { id: "windShieldTracker", label: "Wind-Shield Tracker", value: 0 },
  { id: "terpTitleFeeDmv", label: "TERP Title Fee to DMV", value: 1.0 },
  { id: "titleApplicationFee", label: "Title Application Fee", value: 1.0 },
  {
    id: "regFeeDpsTaxOffice",
    label: "REG. FEE-DPS to TaxOffice",
    value: 1.0,
  },
  {
    id: "cntyRoadBridgeAddonFee",
    label: "CNTY ROAD Bridge Addon Fee",
    value: 13.0,
  },
  {
    id: "childSafetyToTaxOffice",
    label: "Child Safety To Tax Office",
    value: 13.0,
  },
  { id: "metalTag", label: "Metal Tag", value: 5.0 },
  {
    id: "procHandlingFeeTaxOffice",
    label: "Proc. & handling fee to tax office",
    value: 4.75,
  },
  {
    id: "inspectionFeeToState",
    label: "Inspection Fee to the State",
    value: 8.25,
  },
  {
    id: "inspectionFeeToStation",
    label: "Inspection Fee to the Station",
    value: 25.26,
  },
];
export default feesDetail;

import React, { useEffect, useState } from 'react';
import "./savesearch.css"
import { RiEdit2Fill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import { useNavigate } from 'react-router-dom';



const SaveSearch = () => {
    // Session Time out for 15 minutes-------------------------------------
//   const navigate = useNavigate();

   // Session Time out for 15 minutes End-------------------------------------

    return (
        <>
            <section className='container'>
                <div className="row">
                <div className='col-md-12 my-3'>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-end" >
                        <div className='w-'>
                        <select className="form-select " aria-label="Default select example">
                            <option selected>10</option>
                            <option value="1">10</option>
                            <option value="2">20</option>
                            <option value="3">30</option>
                            <option value="3">40</option>
                            <option value="3">50</option>
                        </select>
                        </div>
                        <div>
                        <select className="form-select" aria-label="Default select example">
                            <option selected>Short By</option>
                            <option value="1">Latest</option>
                            <option value="2">Oldest</option>
                        </select>
                        </div>
                    </div>
                </div>

                <div className='row border border-dark my-3'>
                <div className="col-8 mt-3">
                    <h1>2018 Electric and Hybrid</h1>
                    <h5>Relevent (776)</h5>
                </div>
                {/* <div className="col-4">
                    <div className='d-md-flex '>
                    <div className=' me-5' ><h1 className='rounded-circle bg-success text-light text-center ' style={{height:"70px",width:"50%"}}><RiEdit2Fill /></h1></div>
                    <p id="rcorners4"><RiEdit2Fill /></p>
                    <div className=''>
                    <h1 className='rounded-circle bg-danger text-light text-center' style={{height:"70px",width:"50%"}}><MdDelete /></h1>
                    <p id="rcorners5"><MdDelete /></p>
                    </div>
                    </div>
                </div> */}
                <div className="col-4 mt-3" >
                    <div className='d-flex '>
                    <h3 className="rcorners4"><RiEdit2Fill style={{fontSize:"35px"}}/></h3>
                    {/* <div className=''> */}
                    <h3 className="rcorners5"><MdDelete style={{fontSize:"35px"}}/></h3>
                    {/* </div> */}
                    </div>
                </div>
                </div>
                </div>
            </section>
        </>
    )
}

export default SaveSearch;
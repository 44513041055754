import React from "react";
import Styles from "./DealerWebsite.module.css";
import { useNavigate } from "react-router-dom";
import WebsiteCard from "./WebsiteCard.jsx";
import Image from "../../imgData";

const ActivationCards = () => {
  const navigate = useNavigate();
  const handleShowDNS = () => {
    navigate("dns", { relative: "route" });
  };
  const cardsData = [
    { id: 1, img: Image.web_screenshot_1, route: "/dealer/webPreview1" },
    // { id: 2, img: Image.web_screenshot_2, route: "/dealer/webPreview2" },
    { id: 2, img: Image.web_screenshot_4, route: "/dealer/webPreview4" },
    //  add more if needed------------*************-------------------------
  ];

  return (
    <div>
      <div className={Styles.container}>
        {cardsData.map(({ id, img, route }) => (
          <WebsiteCard
            id={id}
            imgScreenShot={img}
            route={route}
            handleShowDNS={handleShowDNS}
          />
        ))}
      </div>
    </div>
  );
};

export default ActivationCards;

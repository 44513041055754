import React, { useEffect, useRef, useState } from "react";
import "./addlist.css";
import { Await, Link, useLocation, useNavigate } from "react-router-dom";
import Tabsp from "./Tabsp";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
//import { setAddListingData } from "../../../Redux/slice/addlistingdata";

import {
  FaAngleDown,
  FaArrowLeft,
  FaFileUpload,
  FaPause,
  FaPlay,
  FaRedo,
  FaTrash,
} from "react-icons/fa";
import Slider from "react-slick";
import { LiaPhotoVideoSolid } from "react-icons/lia";
import { RiVideoUploadFill } from "react-icons/ri";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import imageCompression from "browser-image-compression";
import PanolensViewer from "./PanolensViewer";
import { BsStars } from "react-icons/bs";
import { FaCalculator } from "react-icons/fa6";
// For Tabsection----------------------
// import { Tabs, Tab, Container, Row, Col, Button } from 'react-bootstrap';
import {
  Tabs,
  Tab,
  Container,
  Row,
  Col,
  Button,
  Tooltip,
  OverlayTrigger,
  Table,
  Form,
} from "react-bootstrap";
import "../../../Tabs.css";
import UploadFile from "./UploadFile";
import "./tab.css";
import {
  MdDelete,
  MdOutlineRemoveRedEye,
  MdOutlineVisibilityOff,
  MdMoveDown,
  MdMoveUp,
  MdOutlineMoveUp,
} from "react-icons/md";
import { FaArrowRight } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import { FaSearch } from "react-icons/fa";
//import Button from 'react-bootstrap/Button';
import { ChromePicker } from "react-color";
import usaStates from "./usaStates.json";
import { SlCalender } from "react-icons/sl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Equipments from "./Equipments";
import Preview from "./Preview";
import StateSelector from "./StateSelector";
import PopupData from "./PopupData";
import PopupColorData from "./PopupColorData";
import PopupTyresData from "./PopupTyresData";

import Image from "../../../imgData";
import { fetchGeoMapData } from "../../../dashboard/geoAPI";
import AmountInputWithCalculator from "./AmountInputWithCalculator";
import { connectStorageEmulator } from "firebase/storage";
import { saveUser } from "../../../../Redux/slice/userSlice";
import PopDataTrim from "./PopDataTrim";
// import AmountInputWithCalculator from "./AmountInputWithCalculator";
// For Tabsection end----------------------
const apiUrl = process.env.REACT_APP_API_URL;
const imgUrl = process.env.REACT_APP_IMG_URL;

// console.log("line 80",apiUrl);

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <button
      className="slick-arrow slick-prev"
      style={{
        display: "block",
        position: "absolute",
        left: "20px",
        width: "20px",
        height: "20px",
        zIndex: 1,
      }}
      onClick={onClick}
    >
      Previous
    </button>
  );
};

const NextArrow = (props) => {
  //const { onClick } = props;
  const { className, style, onClick } = props;
  return (
    <button
      className={`${className} slick-arrow slick-next`}
      style={{
        ...style,
        display: "block",
        position: "absolute",
        right: "40px",
        width: "20px",
        height: "20px",
        zIndex: 1,
      }}
      onClick={onClick}
    >
      Next
    </button>
  );
};

const DealerAddInventoryNav = ({ show, handleClose }) => {
  const { id } = useSelector((state) => state.user);
  const { dealerId } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();
  const activeLocation = useLocation();
  // Retrieve original data passed via navigation
  const originalData = location.state?.data || {};

  // console.log("location", location);

  const [inputVin, setInputVin] = useState("");
  const [avtiveReq, setAvtiveReq] = useState(false);
  const [allImages, setAllImages] = useState([]);
  const [videosData, setVideosData] = useState([]);
  const [isPlay, setIsPlay] = useState(true);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [rotationAngles, setRotationAngles] = useState({});
  const [isDragging, setIsDragging] = useState(false);
  const [mediaType, setMediaType] = useState("image");
  const [imageType, setImageType] = useState("EXT");
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [isImagesSaved, setIsImagesSaved] = useState(false);
  const [isImgChecked, setIsImgChecked] = useState(false);

  // Loading stats
  const [loading, setLoading] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [uploadedCount, setUploadedCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [uploadSpeed, setUploadSpeed] = useState(0);
  const [startTime, setStartTime] = useState(null);
  const [uploadedBytes, setUploadedBytes] = useState(0);
  const [uploadSpeedDisplay, setUploadSpeedDisplay] = useState(0);

  useEffect(() => {
    setUploadSpeedDisplay(uploadSpeed);
  }, [uploadSpeed]);

  const progressPercentage = (uploadedCount / totalCount) * 100;

  const [activeTab, setActiveTab] = useState("tab1");
  const [apiData, setApiData] = useState(null);
  const [messageDecode, setMessageDecode] = useState(0);
  const [apiDataEdit, setApiDataEdit] = useState(null);
  const [apiDatab, setApiDatab] = useState(null);
  const [apiDatac, setApiDatac] = useState(null);
  const scrollContainerRef = useRef(null);
  const [emptyFields, setEmptyFields] = useState({});
  //const [decode_error, setErrorMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSeatsPresent, setIsSeatsPresent] = useState(false);
  const [isTransmissionPresent, setIsTransmissionPresent] = useState(false);
  const [year, setYear] = useState(null);
  const [make, setMake] = useState(null);
  const [model, setModel] = useState(null);
  //---------------Editable Dropdown-------------------
  const [inputValue1, setInputValue1] = useState("Used");
  const [inputValue34, setInputValue34] = useState("");
  //for purchased form end----------------------------------
  const [dropdownValuea, setDropdownValuea] = useState("");
  const [dropdownOptionsa, setDropdownOptionsa] = useState([
    "",
    "Option 2",
    "Option 3",
  ]);
  const [newOptiona, setNewOptiona] = useState("");
  // purchased form dropdown end---------------------------------------------------------
  const [exteriorColorShow, setExteriorColorShow] = useState("");
  //console.log("exteriorColorShow => ", exteriorColorShow);
  const [interiorColorShow, setInteriorColorShow] = useState("");
  //console.log("interiorColorShow => ", interiorColorShow);
  //for  Buyer name dropdown---------------------------------------------------
  const [dropdownValueb, setDropdownValueb] = useState("");
  const [dropdownOptionsb, setDropdownOptionsb] = useState([
    "",
    "John",
    "Lusi",
  ]);
  const [newOptionb, setNewOptionb] = useState("");
  //  Buyer name dropdown end---------------------------------------------------
  const [btnTxt, setBtnTxt] = useState("");
  // popup selection option--------------------------------------****************************************************--------------------------

  const [showModalImg, setShowModalImg] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [selectedOptionn, setSelectedOptionn] = useState("");
  const [showModalb, setShowModalb] = useState(false);
  const [selectedOptionb, setSelectedOptionb] = useState("");
  const [showModalc, setShowModalc] = useState(false);
  const [selectedOptionc, setSelectedOptionc] = useState("");
  const [showModald, setShowModald] = useState(false);
  const [selectedOptiond, setSelectedOptiond] = useState("");
  const [showModale, setShowModale] = useState(false);
  const [selectedOptione, setSelectedOptione] = useState("");
  const [showModalf, setShowModalf] = useState(false);
  const [selectedOptionf, setSelectedOptionf] = useState("");
  const [showModalg, setShowModalg] = useState(false);
  const [selectedOptiong, setSelectedOptiong] = useState("");
  const [showModalh, setShowModalh] = useState(false);
  const [selectedOptionh, setSelectedOptionh] = useState("");
  const [showModali, setShowModali] = useState(false);
  const [selectedOptioni, setSelectedOptioni] = useState("");
  const [showModalj, setShowModalj] = useState(false);
  const [selectedOptionj, setSelectedOptionj] = useState("");
  const [showModalk, setShowModalk] = useState(false);
  const [selectedOptionk, setSelectedOptionk] = useState("");
  const [showModall, setShowModall] = useState(false);
  const [selectedOptionl, setSelectedOptionl] = useState("");
  const [showModalm, setShowModalm] = useState(false);
  const [selectedOptionm, setSelectedOptionm] = useState("");
  const [showModalo, setShowModalo] = useState(false);
  const [selectedOptiono, setSelectedOptiono] = useState("");
  const [showModalp, setShowModalp] = useState(false);
  const [selectedOptionp, setSelectedOptionp] = useState("");
  const [showModalq, setShowModalq] = useState(false);
  const [selectedOptionq, setSelectedOptionq] = useState("");
  const [showModalr, setShowModalr] = useState(false);
  const [selectedOptionr, setSelectedOptionr] = useState("");
  const [showModals, setShowModals] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState("");
  const [showModalt, setShowModalt] = useState(false);
  const [selectedOptiont, setSelectedOptiont] = useState("");
  const [showModalu, setShowModalu] = useState(false);
  const [selectedOptionu, setSelectedOptionu] = useState("");
  const [showModalSpare, setShowModalSpare] = useState(false);
  const [selectedOptionSpare, setSelectedOptionSpare] = useState("");
  const [showModalv, setShowModalv] = useState(false);
  const [selectedOptionv, setSelectedOptionv] = useState("");
  const [showModalw, setShowModalw] = useState(false);
  const [selectedOptionw, setSelectedOptionw] = useState("");
  const [showModalx, setShowModalx] = useState(false);
  const [selectedOptionx, setSelectedOptionx] = useState("");
  const [showModaly, setShowModaly] = useState(false);
  const [selectedOptiony, setSelectedOptiony] = useState("");
  const [showModalz, setShowModalz] = useState(false);
  const [selectedOptionz, setSelectedOptionz] = useState("");
  const [showModalza, setShowModalza] = useState(false);
  const [selectedOptionza, setSelectedOptionza] = useState("");
  const [showModalzb, setShowModalzb] = useState(false);
  const [selectedOptionzb, setSelectedOptionzb] = useState("");
  // poup selection option end-------------------------------------------****************************************----------------------------------
  // For Dropdown for tab section end------------------------------------------------------
  // for adv manager --------------
  const [isConnected, setIsConnected] = useState(false);
  const [isConnectedb, setIsConnectedb] = useState(false);
  const [isConnectedc, setIsConnectedc] = useState(false);
  const [isConnectedd, setIsConnectedd] = useState(false);
  const [isConnectede, setIsConnectede] = useState(false);
  const [isConnectedf, setIsConnectedf] = useState(false);
  const [isConnectedg, setIsConnectedg] = useState(false);
  const [showModalPreview, setShowModalPreview] = useState(false);
  const toggleImage = () => {
    setIsConnected(!isConnected);
  };
  const toggleImageb = () => {
    setIsConnectedb(!isConnectedb);
  };
  const toggleImagec = () => {
    setIsConnectedc(!isConnectedc);
  };
  const toggleImaged = () => {
    setIsConnectedd(!isConnectedd);
  };
  const toggleImagee = () => {
    setIsConnectede(!isConnectede);
  };
  const toggleImagef = () => {
    setIsConnectedf(!isConnectedf);
  };
  const toggleImageg = () => {
    setIsConnectedg(!isConnectedg);
  };

  // for three circle------------------------
  const handleLinkClickPreview = () => {
    setShowModalPreview(true);
  };

  const handleModalClosePreview = () => {
    setShowModalPreview(false);
  };
  // floor fees-------------------------------
  const [inputValueInterestRate, setInputValueInterestRate] = useState("");
  const [inputValueLengthFloor, setInputValueLengthFloor] = useState("");
  const [inputValueAdminFees, setInputValueAdminFees] = useState("");
  const [inputValueSetupFees, setInputValueSetupFees] = useState("");
  const [inputValueDeliveryFees, setInputValueDeliveryFees] = useState("");
  const [inputValueAdditionalFees, setInputValueAdditionalFees] = useState("");
  const [inputValueFirstCurtailment, setInputValueFirstCurtailment] =
    useState("");
  const [inputValueSecondCurtailment, setInputValueSecondCurtailment] =
    useState("");
  const [inputValueThirdCurtailment, setInputValueThirdCurtailment] =
    useState("");
  const [inputValueForthCurtailment, setInputValueForthCurtailment] =
    useState("");
  // ----new floor fees------------------
  const [inputValuePurchasePrice, setInputValuePurchasePrice] = useState("");
  const [inputValueVpnInsurance, setInputValueVpnInsurance] = useState("");
  const [inputValueFloorFees, setInputValueFloorFees] = useState("");
  const [inputValueDocumentProcessing, setInputValueDocumentProcessing] =
    useState("");
  const [inputValueDeleveryFees, setInputValueDeleveryFees] = useState("");
  const [inputValueTotalFeesInterest, setInputValueTotalFeesInterest] =
    useState("");
  const [
    inputValueTotalFeesInterestPurchase,
    setInputValueTotalFeesInterestPurchase,
  ] = useState("");
  // ------------------------
  const [objectsFloor, setObjectsFloor] = useState([]);
  const [alertVisible, setAlertVisible] = useState(false);
  // repare cost------------------------------------------------------------
  const [objects, setObjects] = useState([]);
  const [inputValueDate, setInputValueDate] = useState("");
  const [inputValueAmount, setInputValueAmount] = useState("");
  const [inputValueDescription, setInputValueDescription] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [divBackgroundColor, setDivBackgroundColor] = useState("");
  const [highlightedRow, setHighlightedRow] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  // Footer advertising manager---------------------------
  const [isCheckedfooter, setIsCheckedfooter] = useState(true);
  const [isCheckedfooterb, setIsCheckedfooterb] = useState(true);
  const [isCheckedfooterc, setIsCheckedfooterc] = useState(false);
  const [isCheckedfooterd, setIsCheckedfooterd] = useState(false);
  const [isCheckedfootere, setIsCheckedfootere] = useState(false);
  const [isCheckedfooterf, setIsCheckedfooterf] = useState(false);
  const [isCheckedfooterg, setIsCheckedfooterg] = useState(false);
  // Footer advertisi9ng manager end--------------------------
  // for inspection date-----------------------------------------
  // ---------------------new option arrow choose---------
  // Array of radio button options
  //console.log("line120", apiDatab);
  // for calculator -----------------------------------------------------------------------------

  // --------------------------------------------------------------------------------------------------
  let radioOptions;

  if (apiDatab && apiDatab.length > 0) {
    radioOptions = apiDatab.map((model) => ({
      value: model,
      label: model,
    }));
  } else {
    const defaultValue =
      apiDataEdit && apiDataEdit.trim ? apiDataEdit.trim : "";
    radioOptions = [{ value: defaultValue, label: defaultValue }];
  }

  //console.log("line122", radioOptions);

  const radioOptionsb = [
    { value: "4X4", label: "4X4" },
    { value: "AWD", label: "AWD" },
    { value: "FWD", label: "FWD" },
    { value: "RWD", label: "RWD" },
  ];

  let radioOptionsc =
    apiDatac && apiDatac.length > 0
      ? apiDatac.map((model) => ({
          value: model,
          label: model,
        }))
      : [
          { value: "Manual", label: "Manual" },
          // { value: "Semi Automatic", label: "Semi Automatic" },
          { value: "Automatic", label: "Automatic" },
        ];

  const [radioOptionsd, setRadioOptionsd] = useState([
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
  ]);

  const radioOptionse = [
    { value: "Clothes", label: "Clothes" },
    { value: "Leather", label: "Leather" },
    { value: "Alcantara", label: "Alcantara" },
    { value: "Wood Trim", label: "Wood Trim" },
    { value: "Carbon Fiber", label: "Carbon Fiber" },
  ];
  const radioOptionsf = [
    { value: "40 Kwh", label: "40 Kwh" },
    { value: "62 Kwh", label: "62 Kwh" },
    { value: "72 Kwh", label: "72 Kwh" },
  ];
  const radioOptionsg = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
  ];

  const [radioOptionsh, setRadioOptionsh] = useState([
    { value: "Beige", label: "Beige", boxColor: "#F5F5DC" },
    { value: "Black", label: "Black", boxColor: "black" },
    { value: "Blue", label: "Blue", boxColor: "blue" },
    { value: "Brown", label: "Brown", boxColor: "brown" },
    { value: "Burgundy", label: "Burgundy", boxColor: "#800020" },
    { value: "Charcoal", label: "Charcoal", boxColor: "#36454F" },
    { value: "Gold", label: "Gold", boxColor: "#FFD700" },
    { value: "Grey", label: "Grey", boxColor: "#808080" },
    { value: "Green", label: "Green", boxColor: "#008000" },
    { value: "Maroon", label: "Maroon", boxColor: "#800000" },
    { value: "Orange", label: "Orange", boxColor: "orange" },
    { value: "Pink", label: "Pink", boxColor: "pink" },
    { value: "Red", label: "Red", boxColor: "red" },
    { value: "Silver", label: "Silver", boxColor: "#C0C0C0" },
    { value: "Tan", label: "Tan", boxColor: "#D2B48C" },
    { value: "White", label: "White", boxColor: "white" },
    { value: "Yellow", label: "Yellow", boxColor: "yellow" },
  ]);
  const [radioOptionsi, setRadioOptionsi] = useState([
    { value: "Beige", label: "Beige", boxColor: "#F5F5DC" },
    { value: "Black", label: "Black", boxColor: "black" },
    { value: "Blue", label: "Blue", boxColor: "blue" },
    { value: "Brown", label: "Brown", boxColor: "brown" },
    { value: "Burgundy", label: "Burgundy", boxColor: "#800020" },
    { value: "Charcoal", label: "Charcoal", boxColor: "#36454F" },
    { value: "Gold", label: "Gold", boxColor: "#FFD700" },
    { value: "Grey", label: "Grey", boxColor: "#808080" },
    { value: "Green", label: "Green", boxColor: "#008000" },
    { value: "Maroon", label: "Maroon", boxColor: "#800000" },
    { value: "Orange", label: "Orange", boxColor: "orange" },
    { value: "Pink", label: "Pink", boxColor: "pink" },
    { value: "Red", label: "Red", boxColor: "red" },
    { value: "Silver", label: "Silver", boxColor: "#C0C0C0" },
    { value: "Tan", label: "Tan", boxColor: "#D2B48C" },
    { value: "White", label: "White", boxColor: "white" },
    { value: "Yellow", label: "Yellow", boxColor: "yellow" },
  ]);
  // const state = usaStates.map(state => {
  //     return { optionDropdown: state.name };
  // });
  const tyreImage = [
    Image.greenTyre,
    Image.blueTyre,
    Image.yellowTyre,
    Image.redTyre,
  ];
  const defaultTyreImage = Image.tireCondition;

  const radioOptionsj = usaStates.map((state, index) => ({
    value: state.name,
    label: state.name,
  }));

  //console.log("line272",radioOptionsj);
  const radioOptionsk = [
    { value: "Clean", label: "Clean" },
    { value: "Rebuilt", label: "Rebuilt" },
    { value: "Salvage", label: "Slavage" },
    { value: "Structural Damage", label: "Structural Damage" },
    { value: "Total Loss", label: "Total Loss" },
    { value: "Lemon", label: "Lemon" },
    { value: "Bonded", label: "Bonded" },
    { value: "No Title", label: "No Title" },
  ];
  const radioOptionsl = [
    { value: "Actual Miles", label: "Actual Miles" },
    { value: "Unknown Miles", label: "Unknown Miles" },
    { value: "Not Sure", label: "Not Sure" },
  ];
  const radioOptionsm = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
  ];
  const radioOptionso = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];
  const radioOptionsp = [
    { value: "Run", label: "Run" },
    { value: "Starts", label: "Starts" },
    { value: "Won't Starts", label: "Won't Starts" },
  ];
  const radioOptionsq = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
    { value: "Not Sure", label: "Not Sure" },
  ];
  const radioOptionsr = [
    { value: "New 8/32>", label: "New 8/32>", image: tyreImage[0] },
    { value: "Good 6/32-7/32", label: "Good 6/32-7/32", image: tyreImage[1] },
    { value: "Fair <5/32", label: "Fair <5/32", image: tyreImage[2] },
    { value: "Fully-Used NA", label: "Fully Used NA", image: tyreImage[3] },
  ];
  const radioOptionss = [
    { value: "New 8/32>", label: "New 8/32>", image: tyreImage[0] },
    { value: "Good 6/32-7/32", label: "Good 6/32-7/32", image: tyreImage[1] },
    { value: "Fair <5/32", label: "Fair <5/32", image: tyreImage[2] },
    { value: "Fully-Used NA", label: "Fully Used NA", image: tyreImage[3] },
  ];
  const radioOptionst = [
    { value: "New 8/32>", label: "New 8/32>", image: tyreImage[0] },
    { value: "Good 6/32-7/32", label: "Good 6/32-7/32", image: tyreImage[1] },
    { value: "Fair <5/32", label: "Fair <5/32", image: tyreImage[2] },
    { value: "Fully-Used NA", label: "Fully-Used NA", image: tyreImage[3] },
  ];
  const radioOptionsu = [
    { value: "New 8/32>", label: "New 8/32>", image: tyreImage[0] },
    { value: "Good 6/32-7/32", label: "Good 6/32-7/32", image: tyreImage[1] },
    { value: "Fair <5/32", label: "Fair <5/32", image: tyreImage[2] },
    { value: "Fully-Used NA", label: "Fully Used NA", image: tyreImage[3] },
  ];
  const radioOptionsSpare = [
    { value: "New 8/32>", label: "New 8/32>", image: tyreImage[0] },
    { value: "Good 6/32-7/32", label: "Good 6/32-7/32", image: tyreImage[1] },
    { value: "Fair <5/32", label: "Fair <5/32", image: tyreImage[2] },
    { value: "Fully-Used NA", label: "Fully Used NA", image: tyreImage[3] },
  ];
  const [radioOptionsv, setRadioOptionsv] = useState([
    { value: "Bodywork", label: "Bodywork" },
    { value: "Fuel", label: "Fuel" },
    { value: "Labor", label: "Labor" },
    { value: "Mechanic", label: "Mechanic" },
    { value: "Parts", label: "Parts" },
    { value: "Repair", label: "Repair" },
  ]);

  const [radioOptionsw, setRadioOptionsw] = useState([
    { value: "Acdelco", label: "Acdelco" },
    { value: "AutoZone", label: "AutoZone" },
    { value: "Interstate Batteries", label: "Interstate Batteries" },
    { value: "LKQ Corporation", label: "LKQ Corporation" },
    {
      value: "O'Reilly Auto Parts",
      label: "O'Reilly Auto Parts",
    },
    { value: "Pep Boys", label: "Pep Boys" },
    { value: "CarParts.com", label: "CarParts.com" },
  ]);

  const radioOptionsx = [
    { value: "Cash", label: "Cash" },
    { value: "Cheque", label: "Cheque" },
    { value: "Wire", label: "Wire" },
    { value: "Cards", label: "Cards" },
    { value: "Trade", label: "Trade" },
  ];
  const radioOptionsy = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
  ];
  const radioOptionsz = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];

  const radioOptionsza = usaStates.map((state, index) => ({
    value: state.name,
    label: state.name,
  }));

  const radioOptionszb = [
    { value: "SUV", label: "SUV" },
    { value: "MPV", label: "MPV" },
    { value: "Passanger", label: "Passanger" },
    { value: "Truck", label: "Truck" },
  ];
  // ---------------------------------------------------------------------
  const [searchOptionState, setSearchOptionState] = useState("");

  // dropdown--------------------------------------------------------------------------------------------------
  const [show_condition, setShowCondition] = useState(false);
  const stateNames = usaStates.map((state) => state.name);
  // console.log(stateNames);
  const condition = [{ optionDropdown: "New" }, { optionDropdown: "Used" }];

  const [showNewVehicleForm, setShowNewvehicleForm] = useState(false);
  const [newVehicleInput, setNewVehicleInput] = useState("");
  const [newVehicleItem, setNewVehicleItem] = useState([]);
  const [dynamicID, setDynamicID] = useState([]);
  const [liveSeaching, setLiveSearching] = useState(false);
  //console.log(liveSeaching);
  const [VehicleOption, setVehicleOption] = useState([]);
  // ----------------------------------------------------------------------
  const [inspection, setInspection] = useState("");
  const [purchaseDate, setPurchaseDate] = useState("");
  const [selectedImages, setSelectedImages] = useState({
    tyrecondition_fl: "",
    tyrecondition_bl: "",
    tyrecondition_fr: "",
    tyrecondition_br: "",
    tyrecondition_st: "",
  });
  //formdata start ------------------------------------------------------------------

  const [formData, setFormData] = useState({
    id: 0,
    userId: dealerId,
    vin: "",
    lattitude: 0,
    longitude: 0,
    ip: "",
    purchasedDate: "",
    inspection: "",
    purchased_from: "",
    buyer_name: "",
    // stock_no: "",
    condition: "Used",
    year_make_model: "",
    // make: "",
    make: "",
    year: "",
    model: "",
    bodyClass: "",
    wheelbase: "",
    driveType: "",
    doorCount: "",
    testp: "",
    // model: "",
    vehicleType: "",
    engine: "",
    // fuel_type: "",
    // engine_cylinders: "",
    // engine_size: "",
    transmission: "",
    mileage_in: "",
    // mileage_read: "",
    steelAlloyWheel: false,
    aluminiumAlloyWheel: false,
    // engine_type: "",
    horse_power: "",
    trim: "",
    // transmission_speed: "",
    exteriorColor: "",
    interiorColor: "",
    seat_count: "",
    door_count: "",
    drive_train: "",
    interiorType: "",
    frame_damage: "",
    keys_count: "",
    accident: "",
    battery_kwh: "",
    battery_type: "",
    city_mpg: "",
    highway_mpg: "",
    listingStatus: "Available",
    price_fee_date: "",
    // payment_method: "",
    purchase_price: "",
    auction_fees: "",
    market_value_price: "",
    listing_price: "",
    total_repair_cost: "",
    flooring_fee: "",
    commission: "",
    others: "",
    total_cost: "",
    profit_price: "",
    title: "Clean",
    odo: "",
    owner: "",
    exterior_damage: "",
    state: "",
    status: "",
    smoked_in: "",
    tyrecondition_fl: "",
    tyrecondition_fr: "",
    tyrecondition_bl: "",
    tyrecondition_br: "",
    tyrecondition_st: "",
    seller_description: "",
    // Title details section-------------
    title_status: "",
    title_type: "",
    title_number: "",
    title_recieved: "",
    title_location: "",
    other_restraint: "",

    vehicleOptions: {
      safetyFeatures: {
        anti_lock_brake_system: false,
        front_airbag: false,
        side_airbags: false,
        curtain_airbag: false,
        back_up_camera: false,
        blind_spot_monitoring: false,
        parking_sensors: false,
        brake_assist: false,
        collision_mitigation_system: false,
        cross_traffic_alert: false,
        daytime_running_lights: false,
        electronic_stability_control: false,
        forward_collision_warning: false,
        hill_descent_control: false,
        hill_start_assist: false,
        lane_departure_warning: false,
        lane_keeping_assist: false,
        rear_cross_traffic_alert: false,
        tyre_pressure_monitoring: false,
        traction_control: false,
      },
      infotainment: {
        apple_carplay_android_auto: false,
        bluetooth_connectivity: false,
        incar_wifi: false,
        navigation_system: false,
        premium_sound_system: false,
      },
      comfortAndConvenience: {
        adaptive_cruise_control: false,
        auto_dimming_rearview_mirror: false,
        auto_climate_control: false,
        heated_ventilated_seats: false,
        key_less_entry: false,
        key_less_go: false,
        memory_seats: false,
        leather_seats: false,
        multizone_climate_control: false,
        panormic_sunroof: false,
        power_adjustable_seats: false,
        power_liftgate: false,
        power_window_mirrors: false,
        remote_starter: false,
        smart_trunk_opener: false,
        sunshades: false,
        tilt_telescopic_steering_wheel: false,
      },

      exteriorFeatures: {
        alloy_wheels: false,
        automatic_headlights: false,
        fog_lights: false,
        led_headlights: false,
        power_folding_mirrors: false,
      },
      performanceAndHandling: {
        adaptive_suspension: false,
        all_wheels_drive: false,
        drive_mode_selector: false,
        sport_tuned_suspension: false,
        limited_slip_differential: false,
      },
      interiorFeatures: {
        ambient_lighting: false,
        cargo_cover: false,
        cargo_net: false,
        center_console_armrest: false,
        heated_steering_wheels: false,
      },
      driveAssistance: {
        adaptive_headlights: false,
        automatic_high_beams: false,
        driver_drowsiness_monitoring: false,
        head_up_display: false,
        night_vision: false,
      },
      energyAndEquipment: {
        engine_start_stop_system: false,
        hybrid_powertrain: false,
        regenerative_breaking: false,
        solar_roof: false,
      },
      extraVehicleOptions: [],
    },
    // -----------------------------------
    floor_fee: objectsFloor,
    repair_cost: objects,
    addvertising: {
      dacmp_status: true,
      dacmp_interval: "",
      unitedmotors_status: true,
      unitedmotors_interval: "",
      google_status: false,
      google_interval: "",
      insta_status: false,
      insta_interval: "",
      facebook_status: false,
      facebook_interval: "",
      craigslist_status: false,
      craigslist_interval: "",
      twitter_status: false,
      twitter_interval: "",
    },
  });
  const [displayValues, setDisplayValues] = useState({
    mileage_in: "",
    purchase_price: "", // Add other numeric fields as needed
    listing_price: "",
    others: "",
    market_value_price: "",
  });
  // Initialize formData and objects from apiDataEdit
  console.log("messageDecode", messageDecode);
  useEffect(() => {
    if (apiDataEdit) {
      //console.log("apiDataEdit", apiDataEdit);
      const formatDate = (date) => {
        if (!date) return "";
        const [month, day, year] = date.split("/");
        return `${year}-${month}-${day}`;
      };
      // Set formData with specific fields
      setFormData((prevState) => ({
        ...prevState,
        id: messageDecode === 2 ? 0 : apiDataEdit.id || 0,
        // only use for backend-----------
        make: apiDataEdit?.modelMake || "",
        year: apiDataEdit?.modelYear || "",
        model: apiDataEdit?.makeModel || "",
        bodyClass: apiDataEdit?.BodyClass || "",
        wheelbase: apiDataEdit?.WheelBaseShort || "",
        driveType: apiDataEdit?.DriveType || "",
        doorCount: apiDataEdit?.Doors || "",
        // --------------------------
        engine: apiDataEdit.engine || "",
        status: apiDataEdit.status || "",
        inspection: formatDate(apiDataEdit.inspection) || "",
        price_fee_date: apiDataEdit.price_fee_date || "",
        city_mpg: apiDataEdit.city_mpg || "",
        highway_mpg: apiDataEdit.highway_mpg || "",
        year_make_model: apiDataEdit.year_Make_Model || "",
        trim: apiDataEdit.trim || "",
        vehicleType: apiDataEdit.vehicleType || "",
        transmission: apiDataEdit.transmission || "",
        seat_count: apiDataEdit.seat_count || "",
        interiorType: apiDataEdit.interiorType || "",
        keys_count: apiDataEdit.keys_count || "",
        battery_kwh: apiDataEdit.battery_kwh || "",
        battery_type: apiDataEdit.battery_type || "",
        // mileage_in: apiDataEdit.mileage_in || "",
        // ------------------------check box allopy wheel----------------------------------------------
        steelAlloyWheel: apiDataEdit.steelAlloyWheel || false,
        aluminiumAlloyWheel: apiDataEdit.aluminiumAlloyWheel || false,
        // mileage_read: apiDataEdit.mileage_read || "",
        mileage_in: parseFloat(apiDataEdit.mileage_in || 0.0),
        purchase_price: parseFloat(apiDataEdit.purchase_price || 0.0),
        listing_price: parseFloat(apiDataEdit.listing_price || 0.0),
        others: parseFloat(apiDataEdit.others || 0.0),
        market_value_price: parseFloat(apiDataEdit.market_value_price || 0.0),
        purchasedDate: formatDate(apiDataEdit.purchasedDate) || "",
        exteriorColor: apiDataEdit.exteriorColor || "",
        interiorColor: apiDataEdit.interiorColor || "",
        other_restraint: apiDataEdit.other_restraint || "",
        // inspectionDate: apiDataEdit.inspection || "",
        //purchase_price: apiDataEdit.purchase_price || '',
        //listing_price: apiDataEdit.listing_price || "",
        //Equipment and Option----------
        adaptive_cruise_control: apiDataEdit.adaptive_cruise_control || "",
        key_less_entry: apiDataEdit.key_less_entry || "",
        side_airbags: apiDataEdit.side_airbags || "",
        air_conditioning: apiDataEdit.air_conditioning || "",
        key_less_go: apiDataEdit.key_less_go || "",
        side_steps_running_boards: apiDataEdit.side_steps_running_boards || "",
        all_wheels_drive: apiDataEdit.all_wheels_drive || "",
        leather_interior_surface: apiDataEdit.leather_interior_surface || "",
        s_line: apiDataEdit.s_line || "",
        amg_pack: apiDataEdit.amg_pack || "",
        leather_seats: apiDataEdit.leather_seats || "",
        soft_close_doors: apiDataEdit.soft_close_doors || "",
        anti_lock_brake_system: apiDataEdit.anti_lock_brake_system || "",
        leather_steering_wheel: apiDataEdit.leather_steering_wheel || "",
        sport_pack: apiDataEdit.sport_pack || "",
        auto_climate_control: apiDataEdit.auto_climate_control || "",

        // Seller Discription---------------------------------
        state: apiDataEdit.state || "",
        //status:apiDataEdit.status || '',
        odo: apiDataEdit.odo || "",
        owner: apiDataEdit.owner || "",
        exterior_damage: apiDataEdit.exterior_damage || "",
        smoked_in: apiDataEdit.smoked_in || "",
        tyrecondition_fl: apiDataEdit.tyrecondition_fl || "",
        tyrecondition_fr: apiDataEdit.tyrecondition_fr || "",
        tyrecondition_bl: apiDataEdit.tyrecondition_bl || "",
        tyrecondition_br: apiDataEdit.tyrecondition_br || "",
        tyrecondition_st: apiDataEdit.tyrecondition_st || "",
        seller_description: apiDataEdit.seller_description || "",
        // Title info-----------------------------
        title_type: apiDataEdit.title_type || "",
        title_location: apiDataEdit.title_location || "",
        title_number: apiDataEdit.title_number || "",
        title_recieved: apiDataEdit.title_recieved || "",
        title_status: apiDataEdit.title_status || "",
        // finance ------------------------------------------
        total_cost: apiDataEdit.total_cost || "",
        total_repair_cost: apiDataEdit.total_repair_cost || "",
        total_repair_cost: apiDataEdit.total_repair_cost || "",
        //others: apiDataEdit.others || "",
        // others: apiDataEdit.others || "",
        profit_price: apiDataEdit.profit_price || "",
        auction_fees: apiDataEdit.auction_fees || "",
        // advertising manager----------------------------------------------
        addvertising: {
          dacmp_status: apiDataEdit.addvertising?.dacmp_status || false,
          dacmp_interval: apiDataEdit.addvertising?.dacmp_interval || "",
          unitedmotors_status:
            apiDataEdit.addvertising?.unitedmotors_status || false,
          unitedmotors_interval:
            apiDataEdit.addvertising?.unitedmotors_interval || "",
          google_status: apiDataEdit.addvertising?.google_status || false,
          google_interval: apiDataEdit.addvertising?.google_interval || "",
          insta_status: apiDataEdit.addvertising?.insta_status || false,
          insta_interval: apiDataEdit.addvertising?.insta_interval || "",
          facebook_status: apiDataEdit.addvertising?.facebook_status || false,
          facebook_interval: apiDataEdit.addvertising?.facebook_interval || "",
          craigslist_status:
            apiDataEdit.addvertising?.craigslist_status || false,
          craigslist_interval:
            apiDataEdit.addvertising?.craigslist_interval || "",
          twitter_status: apiDataEdit.addvertising?.twitter_status || false,
          twitter_interval: apiDataEdit.addvertising?.twitter_interval || "",
        },
        //repaire cost---------------------------------------------------
        repair_cost: apiDataEdit.repair_cost || [],
      }));

      // Find the boxColor for the exterior color from apiDataEdit
      const exteriorColor = radioOptionsh.find(
        (obj) => obj.label === apiDataEdit.exteriorColor
      )?.boxColor;

      if (exteriorColor) {
        setExteriorColorShow(exteriorColor);
      }
      // Find the boxColor for the exterior color from apiDataEdit
      const interiorColor = radioOptionsi.find(
        (obj) => obj.label === apiDataEdit.interiorColor
      )?.boxColor;

      if (interiorColor) {
        setInteriorColorShow(interiorColor);
      }
      // Update displayValues with formatted data from the API
      setDisplayValues((prevState) => ({
        ...prevState,
        mileage_in: formatValue(apiDataEdit.mileage_in?.toString() || "0.0"),
        purchase_price: formatValue(
          apiDataEdit.purchase_price?.toString() || "0.0"
        ),
        listing_price: formatValue(
          apiDataEdit.listing_price?.toString() || "0.0"
        ),
        others: formatValue(apiDataEdit.others?.toString() || "0.0"),
        market_value_price: formatValue(
          apiDataEdit.market_value_price?.toString() || "0.0"
        ),
      }));
      setIsCheckedfooter(apiDataEdit.addvertising?.dacmp_status || false);
      setIsCheckedfooterb(
        apiDataEdit.addvertising?.unitedmotors_status || false
      );
      setIsCheckedfooterc(apiDataEdit.addvertising?.google_status || false);
      setIsCheckedfooterd(apiDataEdit.addvertising?.insta_status || false);
      setIsCheckedfootere(apiDataEdit.addvertising?.facebook_status || false);
      setIsCheckedfooterf(apiDataEdit.addvertising?.craigslist_status || false);
      setIsCheckedfooterg(apiDataEdit.addvertising?.twitter_status || false);
      // Select the first item in repair_cost array (assuming there is at least one)
      const firstItem =
        apiDataEdit.repair_cost && apiDataEdit.repair_cost.length > 0
          ? apiDataEdit.repair_cost[0]
          : {};

      // // Set fields based on first item
      // setInputValueDate(firstItem.date || ""); // Set date from the first item
      // setSelectedOptionv(firstItem.category || ""); // Set category from the first item
      // setSelectedOptionw(firstItem.payee_vendor || ""); // Set payee_vendor from the first item
      // setSelectedOptionx(firstItem.payment_method || ""); // Set price_payment_method from the first item
      // setInputValueAmount(firstItem.amount ? firstItem.amount.toString() : ""); // Set amount from the first item
      // setInputValueDescription(firstItem.description || ""); // Set description from the first item

      // Set objects with repair_cost array
      // setObjects(apiDataEdit.repair_cost || []);
      setObjects(apiDataEdit.repair_cost || []);

      const newTotalAmount = (apiDataEdit.repair_cost || []).reduce(
        (acc, curr) => acc + curr.amount,
        0
      );
      setTotalAmount(newTotalAmount);
    }
  }, [apiDataEdit]);
  console.log("line366", formData);
  // formdata end---------------------------------------------------------------------
  // new popo input ------------------------------------------------
  const handleArrowClick = () => {
    setShowModal(true);
  };

  useEffect(() => {
    // Access vin from state
    const { vin } = location.state || {};
    //console.log("VIN:", vin);
    if (vin) {
      // setAlertVisible(true);
      setFormData((prevData) => ({
        ...prevData,
        vin: vin,
      }));
      setInputVin(vin); // Initialize input value
    }
    //console.log("8888888888888888888", location.state);
  }, [location.state]);

  // useEffect to call handleRetry when formData.vin is updated and valid
  useEffect(() => {
    if (location.state && formData.vin.length === 17) {
      handleRetry(formData.vin);
    }
  }, [formData.vin]); // Add formData.vin as dependency

  const handleDecodeActive = (vin) => {
    // Check if the input has exactly 17 characters
    if (vin.length === 17) {
      setFormData((prevData) => ({
        ...prevData,
        vin: vin,
      }));
      // handleRetry will be called by the useEffect when formData.vin changes
    }
  };

  const handleOptionSelect = (option) => {
    setSelectedOptionn(option);
    setFormData((prevState) => ({
      ...prevState,
      trim: option,
    }));
    setShowModal(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  // ----
  const handleArrowClickb = () => {
    setShowModalb(true);
  };

  const handleOptionSelectb = (option) => {
    setSelectedOptionb(option);
  };

  const handleCloseModalb = () => {
    setShowModalb(false);
  };

  const handleContinueb = () => {
    setFormData((prevState) => ({
      ...prevState,
      drive_train: selectedOptionb,
    }));
    setShowModalb(false);
  };
  // -------
  // Function to handle clicking the arrow, shows the modal
  const handleArrowClickc = () => {
    setShowModalc(true);
  };

  // Function to handle selecting an option, sets the selected option, updates the form data, and closes the modal
  const handleOptionSelectc = (option) => {
    setSelectedOptionc(option);
    setFormData((prevState) => ({
      ...prevState,
      transmission: option,
    }));
    setShowModalc(false);
  };
  // Effect to call transmissiondecode whenever the transmission in formData changes
  useEffect(() => {
    if (formData.transmission) {
      transmissiondecode();
    }
  }, [formData.transmission]);

  // Function to handle closing the modal
  const handleCloseModalc = () => {
    setShowModalc(false);
  };
  // ---

  const [newOptionseats, setNewOptionseats] = useState("");

  const handleArrowClickd = () => {
    setShowModald(true);
  };

  // Function to handle selecting an option, sets the selected option, updates the form data, and closes the modal
  const handleOptionSelectd = (option) => {
    setSelectedOptiond(option);
    setFormData((prevState) => ({
      ...prevState,
      seat_count: option,
    }));
    setShowModald(false);
  };

  const handleCloseModald = () => {
    setShowModald(false);
  };

  // Handler for adding the new option
  const handleAddOptionseats = () => {
    if (newOptionseats.trim() !== "") {
      const newOptionObj = { value: newOptionseats, label: newOptionseats };
      setRadioOptionsd([...radioOptionsd, newOptionObj]);
      setNewOptionseats("");
      handleOptionSelectd(newOptionseats);
    }
  };

  //----
  const handleArrowClicke = () => {
    setShowModale(true);
  };

  const handleOptionSelecte = (option) => {
    setSelectedOptione(option);
    setFormData((prevState) => ({
      ...prevState,
      interiorType: option,
    }));
    setShowModale(false);
  };

  const handleCloseModale = () => {
    setShowModale(false);
  };

  //----
  const handleArrowClickf = () => {
    setShowModalf(true);
  };

  const handleOptionSelectf = (option) => {
    setSelectedOptionf(option);
  };

  const handleCloseModalf = () => {
    setShowModalf(false);
  };

  const handleContinuef = () => {
    setFormData((prevState) => ({
      ...prevState,
      battery_kwh: selectedOptionf,
    }));
    setShowModalf(false);
  };
  //----
  const handleArrowClickg = () => {
    setShowModalg(true);
  };

  // const handleOptionSelectg = (option) => {
  //   setSelectedOptiong(option);
  // };
  const handleOptionSelectg = (option) => {
    setSelectedOptiong(option);
    setFormData((prevState) => ({
      ...prevState,
      keys_count: option,
    }));
    setShowModalg(false);
  };

  const handleCloseModalg = () => {
    setShowModalg(false);
  };

  const [newOptionExteriorColor, setNewOptionExteriorColor] = useState("");

  const handleArrowClickh = () => {
    setShowModalh(true);
  };

  const handleOptionSelecth = (option, color) => {
    setSelectedOptionh(option);
    setFormData((prevState) => ({
      ...prevState,
      exteriorColor: option,
    }));
    setExteriorColorShow(color);
    setShowModalh(false);
  };

  const handleCloseModalh = () => {
    setShowModalh(false);
  };

  // Handler for adding the new option
  const handleAddOptionExteriorColor = () => {
    if (newOptionExteriorColor.trim() !== "") {
      const newColor = "#CCCCCC"; // Default color for new entries
      const newOptionObj = {
        value: newOptionExteriorColor,
        label: newOptionExteriorColor,
        boxColor: newColor,
      };
      setRadioOptionsh([...radioOptionsh, newOptionObj]);
      setNewOptionExteriorColor("");
      handleOptionSelecth(newOptionExteriorColor, newColor);
    }
  };
  //----
  const [newOptionInteriorColor, setNewOptionInteriorColor] = useState("");

  const handleArrowClicki = () => {
    setShowModali(true);
  };

  const handleOptionSelecti = (option, color) => {
    setSelectedOptioni(option);
    setFormData((prevState) => ({
      ...prevState,
      interiorColor: option,
    }));
    setInteriorColorShow(color);
    setShowModali(false);
  };
  const handleCloseModali = () => {
    setShowModali(false);
  };

  // Handler for adding the new option
  const handleAddOptionInteriorColor = () => {
    if (newOptionInteriorColor.trim() !== "") {
      const newColor = "#CCCCCC"; // Default color for new entries
      const newOptionObj = {
        value: newOptionInteriorColor,
        label: newOptionInteriorColor,
        boxColor: newColor,
      };
      setRadioOptionsi([...radioOptionsi, newOptionObj]);
      setNewOptionInteriorColor("");
      handleOptionSelecti(newOptionInteriorColor, newColor);
    }
  };

  //----
  const [searchTerm, setSearchTerm] = useState("");

  const handleArrowClickj = () => {
    setShowModalj(true);
  };

  // const handleOptionSelectj = (option) => {
  //   setSelectedOptionj(option);
  // };
  const handleOptionSelectj = (option) => {
    setSelectedOptionj(option);
    setFormData((prevState) => ({
      ...prevState,
      state: option,
    }));
    setShowModalj(false);
  };

  const handleCloseModalj = () => {
    setShowModalj(false);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const filteredOptions = radioOptionsj.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  //----
  const handleArrowClickk = () => {
    setShowModalk(true);
  };

  // const handleOptionSelectk = (option) => {
  const handleOptionSelectk = (option) => {
    setSelectedOptionk(option);
    setFormData((prevState) => ({
      ...prevState,
      title_status: option,
    }));
    setShowModalk(false);
  };

  const handleCloseModalk = () => {
    setShowModalk(false);
  };
  //----
  const handleArrowClickl = () => {
    setShowModall(true);
  };

  const handleOptionSelectl = (option) => {
    setSelectedOptionl(option);
    setFormData((prevState) => ({
      ...prevState,
      odo: option,
    }));
    setShowModall(false);
  };
  const handleCloseModall = () => {
    setShowModall(false);
  };
  //----
  const handleArrowClickm = () => {
    setShowModalm(true);
  };

  const handleOptionSelectm = (option) => {
    setSelectedOptionm(option);
    setFormData((prevState) => ({
      ...prevState,
      owner: option,
    }));
    setShowModalm(false);
  };

  const handleCloseModalm = () => {
    setShowModalm(false);
  };
  //----
  const handleArrowClicko = () => {
    setShowModalo(true);
  };

  const handleOptionSelecto = (option) => {
    setSelectedOptiono(option);
    setFormData((prevState) => ({
      ...prevState,
      exterior_damage: option,
    }));
    setShowModalo(false);
  };

  const handleCloseModalo = () => {
    setShowModalo(false);
  };
  //----
  const handleArrowClickp = () => {
    setShowModalp(true);
  };
  const handleOptionSelectp = (option) => {
    setSelectedOptionp(option);
    setFormData((prevState) => ({
      ...prevState,
      status: option,
    }));
    setShowModalp(false);
  };

  const handleCloseModalp = () => {
    setShowModalp(false);
  };
  //----
  const handleArrowClickq = () => {
    setShowModalq(true);
  };
  const handleOptionSelectq = (option) => {
    setSelectedOptionq(option);
    setFormData((prevState) => ({
      ...prevState,
      smoked_in: option,
    }));
    setShowModalq(false);
  };

  const handleCloseModalq = () => {
    setShowModalq(false);
  };
  //----
  const handleArrowClickr = () => {
    setShowModalr(true);
  };

  const handleOptionSelectr = (option, image) => {
    setSelectedOptionr(option);
    setFormData((prevState) => ({
      ...prevState,
      tyrecondition_fl: option,
    }));
    setSelectedImages((prevState) => ({
      ...prevState,
      tyrecondition_fl: image,
    }));
    setShowModalr(false);
  };

  const handleCloseModalr = () => {
    setShowModalr(false);
  };
  //----
  const handleArrowClicks = () => {
    setShowModals(true);
  };

  const handleOptionSelects = (option, image) => {
    setSelectedOptions(option);
    setFormData((prevState) => ({
      ...prevState,
      tyrecondition_bl: option,
    }));
    setSelectedImages((prevState) => ({
      ...prevState,
      tyrecondition_bl: image,
    }));
    setShowModals(false);
  };

  const handleCloseModals = () => {
    setShowModals(false);
  };

  //----
  const handleArrowClickt = () => {
    setShowModalt(true);
  };

  const handleOptionSelectt = (option, image) => {
    setSelectedOptiont(option);
    setFormData((prevState) => ({
      ...prevState,
      tyrecondition_fr: option,
    }));
    setSelectedImages((prevState) => ({
      ...prevState,
      tyrecondition_fr: image,
    }));
    setShowModalt(false);
  };

  const handleCloseModalt = () => {
    setShowModalt(false);
  };
  //----
  const handleArrowClicku = () => {
    setShowModalu(true);
  };
  const handleOptionSelectu = (option, image) => {
    setSelectedOptionu(option);
    setFormData((prevState) => ({
      ...prevState,
      tyrecondition_br: option,
    }));
    setSelectedImages((prevState) => ({
      ...prevState,
      tyrecondition_br: image,
    }));
    setShowModalu(false);
  };

  const handleCloseModalu = () => {
    setShowModalu(false);
  };
  //----
  const handleArrowClickSpare = () => {
    setShowModalSpare(true);
  };
  const handleOptionSelectSpare = (option, image) => {
    setSelectedOptionSpare(option);
    setFormData((prevState) => ({
      ...prevState,
      tyrecondition_st: option,
    }));
    setSelectedImages((prevState) => ({
      ...prevState,
      tyrecondition_st: image,
    }));
    setShowModalSpare(false);
  };

  const handleCloseModalSpare = () => {
    setShowModalSpare(false);
  };
  // New state for storing the new option input
  const [newOption, setNewOption] = useState("");
  //----
  const handleArrowClickv = () => {
    setShowModalv(true);
  };

  const handleOptionSelectv = (option) => {
    setSelectedOptionv(option);
    setFormData((prevState) => ({
      ...prevState,
      category: option,
    }));
    setShowModalv(false);
  };

  const handleCloseModalv = () => {
    setShowModalv(false);
  };
  // Handler for adding the new option
  const handleAddOption = () => {
    if (newOption.trim() !== "") {
      const newOptionObj = { value: newOption, label: newOption };
      setRadioOptionsv([...radioOptionsv, newOptionObj]);
      setNewOption("");
      handleOptionSelectv(newOption);
    }
  };
  const [newOptionsupplier, setNewOptionsupplier] = useState("");
  const handleArrowClickw = () => {
    setShowModalw(true);
  };

  const handleOptionSelectw = (option) => {
    setSelectedOptionw(option);
    setFormData((prevState) => ({
      ...prevState,
      payee_vendor: option,
    }));
    setShowModalw(false);
  };

  const handleCloseModalw = () => {
    setShowModalw(false);
  };

  // Handler for adding the new option
  const handleAddOptionsupplier = () => {
    if (newOptionsupplier.trim() !== "") {
      const newOptionObj = {
        value: newOptionsupplier,
        label: newOptionsupplier,
      };
      setRadioOptionsw([...radioOptionsw, newOptionObj]);
      setNewOptionsupplier("");
      handleOptionSelectw(newOptionsupplier);
    }
  };
  //----
  // for calculator on amount in maintanance section---------------------
  const [showModalCalculator, setShowModalCalculator] = useState(false);
  const [showModalCalculatorListing, setShowModalCalculatorListing] =
    useState(false);
  const [showModalCalculatorPurchase, setShowModalCalculatorPurchase] =
    useState(false);
  const [showModalCalculatorMileage, setShowModalCalculatorMileage] =
    useState(false);
  const handleArrowClickCalculator = () => {
    setShowModalCalculator(true);
  };
  const handleArrowClickCalculatorListing = () => {
    setShowModalCalculatorListing(true);
  };
  const handleArrowClickCalculatorPurchase = () => {
    setShowModalCalculatorPurchase(true);
  };
  const handleArrowClickCalculatorMileage = () => {
    setShowModalCalculatorMileage(true);
  };

  const handleCloseModalCalculator = () => {
    setShowModalCalculator(false);
  };
  const handleCloseModalCalculatorListing = () => {
    setShowModalCalculatorListing(false);
  };
  const handleCloseModalCalculatorPurchase = () => {
    setShowModalCalculatorPurchase(false);
  };
  const handleCloseModalCalculatorMileage = () => {
    setShowModalCalculatorMileage(false);
  };
  // --------------------------------------------------------------------
  const handleArrowClickx = () => {
    setShowModalx(true);
  };
  const handleOptionSelectx = (option) => {
    setSelectedOptionx(option);
    setFormData((prevState) => ({
      ...prevState,
      payment_method: option,
    }));
    setShowModalx(false);
  };

  const handleCloseModalx = () => {
    setShowModalx(false);
  };
  //----
  const handleArrowClicky = () => {
    setShowModaly(true);
  };

  const handleOptionSelecty = (option) => {
    setSelectedOptiony(option);
    setFormData((prevState) => ({
      ...prevState,
      title_type: option,
    }));
    setShowModaly(false);
  };

  const handleCloseModaly = () => {
    setShowModaly(false);
  };

  //----
  const handleArrowClickz = () => {
    setShowModalz(true);
  };

  const handleOptionSelectz = (option) => {
    setSelectedOptionz(option);
    setFormData((prevState) => ({
      ...prevState,
      title_recieved: option,
    }));
    setShowModalz(false);
  };

  const handleCloseModalz = () => {
    setShowModalz(false);
  };

  //----
  const handleArrowClickza = () => {
    setShowModalza(true);
  };

  const handleOptionSelectza = (option) => {
    setSelectedOptionza(option);
    setFormData((prevState) => ({
      ...prevState,
      title_location: option,
    }));
    setShowModalza(false);
  };

  const handleCloseModalza = () => {
    setShowModalza(false);
  };
  //----
  const handleArrowClickzb = () => {
    setShowModalzb(true);
  };

  const handleOptionSelectzb = (option) => {
    setSelectedOptionzb(option);
    setFormData((prevState) => ({
      ...prevState,
      vehicleType: option,
    }));
    setShowModalzb(false);
  };

  const handleCloseModalzb = () => {
    setShowModalzb(false);
  };

  // title info for select image and pdf file-------------------------------------------------------------------------------------

  // for location------------------------------------------and ip-----****************************-----------------------------
  useEffect(() => {
    const fetchUserLocation = async () => {
      try {
        const res = await fetchGeoMapData();

        // Update formData only after geoData has been successfully fetched and set
        setFormData((prevFormData) => ({
          ...prevFormData,
          lattitude: res.coordinates.lat,
          longitude: res.coordinates.lng,
        }));
      } catch (error) {
        console.log("Server side error", error);
      }
    };

    fetchUserLocation();
  }, []);

  // for ip address--------------------------------
  // Function to fetch IP address
  const getUserIPaddress = async () => {
    try {
      const res = await axios.get("https://api.ipify.org/?format=json");
      //console.log("User IP Address", res.data.ip);
      // Update formData with the IP address
      setFormData((prevFormData) => ({
        ...prevFormData,
        ip: res.data.ip,
      }));
    } catch (error) {
      console.error("Error fetching IP address:", error);
    }
  };
  // Fetch IP address on component mount
  useEffect(() => {
    getUserIPaddress();
  }, []);

  // end-------------------------*********************************--------------------------------------------------

  useEffect(() => {
    // Set the default purchase date to the current date
    const today = new Date().toISOString().split("T")[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      purchasedDate: today,
      inspection: today,
      price_fee_date: today,
    }));
  }, []);

  useEffect(() => {
    // Set the default date to today's date in YYYY-MM-DD format
    const today = new Date().toISOString().split("T")[0];
    setInputValueDate(today);
  }, []);

  const chunkSize = 6;
  const objectChunks = [];
  for (let i = 0; i < objects.length || i < chunkSize; i += chunkSize) {
    objectChunks.push(objects.slice(i, i + chunkSize));
  }
  // advertising manager------------------------
  const resetCheckboxes = () => {
    setIsCheckedfooter(true);
    setIsCheckedfooterb(true);
    setIsCheckedfooterc(false);
    setIsCheckedfooterd(false);
    setIsCheckedfootere(false);
    setIsCheckedfooterf(false);
    setIsCheckedfooterg(false);
    setFormData({
      addvertising: {
        dacmp_status: true,
        unitedmotors_status: true,
        google_status: false,
        insta_status: false,
        facebook_status: false,
        craigslist_status: false,
        twitter_status: false,

        // Reset other checkboxes similarly if you have more
      },
    });
  };

  const handleCheckboxChangef = (e) => {
    const { name, checked } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      addvertising: {
        ...prevFormData.addvertising,
        [name]: checked,
      },
    }));
  };

  const handleSelectChangef = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      addvertising: {
        ...prevFormData.addvertising,
        [name]: value,
      },
    }));
  };
  // for scroll bar-----------------------
  const handleKeyDown = (event) => {
    if (scrollContainerRef.current) {
      if (event.key === "ArrowDown") {
        scrollContainerRef.current.scrollTo({
          top: scrollContainerRef.current.scrollHeight,
          behavior: "smooth",
        });
      } else if (event.key === "ArrowUp") {
        scrollContainerRef.current.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  // for tabs-----------------------------

  const handleNextButtonClick = () => {
    const tabKeys = ["tab1", "tab2", "tab3", "tab4", "tab5", "tab6"];
    const currentIndex = tabKeys.indexOf(activeTab);

    if (currentIndex < tabKeys.length - 1) {
      const nextTab = tabKeys[currentIndex + 1];
      setActiveTab(nextTab);
    }
  };

  const handleBackButtonClick = () => {
    const tabKeys = ["tab1", "tab2", "tab3", "tab4", "tab5", "tab6"];
    const currentIndex = tabKeys.indexOf(activeTab);

    if (currentIndex > 0) {
      const prevTab = tabKeys[currentIndex - 1];
      setActiveTab(prevTab);
    }
  };
  // -----------------------------------------------------------------------
  const handleAddMoreVehicleOption = () => {
    //console.log("Show or Hide New Vehicle Form");
    setShowNewvehicleForm(!showNewVehicleForm);
  };

  const handleNewVehicleForm = (e) => {
    //console.log(e.target.value);
    setNewVehicleInput(e.target.value);
  };

  const handleSubmitNewVehicle = (e) => {
    e.preventDefault();
    //console.log("New Vehicle Submitted");
    setNewVehicleItem((prevValues) => [...prevValues, newVehicleInput]);
    const propID = newVehicleInput
      .split(" ")
      .map((item) => item.toLowerCase())
      .join("_");
    setDynamicID((previd) => [...previd, propID]);

    const newOption = {
      option_name: propID,
      option_value: false,
    };

    setFormData((prevFormData) => ({
      ...prevFormData,
      extraVehicleOptions: [
        ...(prevFormData.extraVehicleOptions || []),
        newOption,
      ],
    }));

    //console.log(propID);
    setNewVehicleInput("");
  };

  useEffect(() => {
    const updatedVehicleOptions = Object.entries(formData)
      .filter(([key, value]) => typeof value === "boolean")
      .map(([key, value]) => ({
        option_name: key,
        option_value: value,
      }));

    const combinedVehicleOptions = [
      ...updatedVehicleOptions,
      ...(formData?.extraVehicleOptions || []),
    ];

    setVehicleOption(combinedVehicleOptions);
  }, [formData]);

  const handleCheckBoxChange2 = (e) => {
    const { name, checked } = e.target;

    setFormData((prevFormData) => {
      const { extraVehicleOptions } = prevFormData;
      const updatedExtraVehicleOptions = Array.isArray(extraVehicleOptions)
        ? [...extraVehicleOptions]
        : [];

      const index = updatedExtraVehicleOptions.findIndex(
        (option) => option.option_name === name
      );
      if (index !== -1) {
        updatedExtraVehicleOptions[index] = {
          ...updatedExtraVehicleOptions[index],
          option_value: checked,
        };
      } else {
        updatedExtraVehicleOptions.push({
          option_name: name,
          option_value: checked,
        });
      }

      return {
        ...prevFormData,
        extraVehicleOptions: updatedExtraVehicleOptions,
      };
    });
  };

  const handleDeleteCheckedItems = () => {
    setNewVehicleItem((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems.forEach((item, index) => {
        const id = dynamicID[index];
        const checked = formData.extraVehicleOptions.some(
          (option) => option.option_name === id && option.option_value
        );
        if (checked) {
          updatedItems.splice(index, 1);
          setDynamicID((prevIDs) => prevIDs.filter((id, i) => i !== index));
          setFormData((prevFormData) => {
            const updatedExtraVehicleOptions = [
              ...prevFormData.extraVehicleOptions,
            ];
            const optionIndex = updatedExtraVehicleOptions.findIndex(
              (option) => option.option_name === id
            );
            if (optionIndex !== -1) {
              updatedExtraVehicleOptions.splice(optionIndex, 1);
            }
            return {
              ...prevFormData,
              extraVehicleOptions: updatedExtraVehicleOptions,
            };
          });
        }
      });
      return updatedItems;
    });
  };

  // ----------------------------------------------------***************************--------------------
  const handleSearchOptions = (e) => {
    setSearchOptionState(e.target.value);
    if (e.target.value !== "") {
      setLiveSearching(true);
    } else {
      setLiveSearching(false);
    }
  };

  const filteredFormData = Object.keys(formData).filter((key) =>
    key
      .toLowerCase()
      .includes(searchOptionState.toLowerCase().replace(/ /g, "_"))
  );

  //   console.log(filteredFormData);

  function formatItemText(item) {
    // Split the item string by underscores and capitalize each word
    const words = item
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1));
    // Join the words with spaces to create the formatted text
    return words.join(" ");
  }
  // -----------------------------------------------------------------------**********************************-------------
  // Function to handle inspection date change
  const handleChangeins = (e) => {
    const selectedDate = e.target.value;
    setInspection(selectedDate);
    setFormData((prevFormData) => ({
      ...prevFormData,
      inspection: selectedDate,
    }));
  };
  const handleChangepurchaseDate = (e) => {
    const selectedDate = e.target.value;
    setPurchaseDate(selectedDate);
    setFormData((prevFormData) => ({
      ...prevFormData,
      purchasedDate: selectedDate,
    }));
  };
  // Function to calculate days difference between current date and selected inspection date
  const calculateDaysDifference = () => {
    if (!formData.inspection) return 0; // Ensure formData.inspection is used for calculation
    const currentDate = new Date();
    const selectedDate = new Date(formData.inspection);
    const timeDifference = selectedDate.getTime() - currentDate.getTime();
    const daysDifference = Math.ceil(
      Math.abs(timeDifference) / (1000 * 60 * 60 * 24)
    );
    return daysDifference;
  };

  const calculateDaysDifferencepurchase = () => {
    if (!formData.purchasedDate) return 0; // Ensure formData.inspection is used for calculation
    const currentDate = new Date();
    const selectedDate = new Date(formData.purchasedDate);
    const timeDifference = selectedDate.getTime() - currentDate.getTime();
    const daysDifference = Math.ceil(
      Math.abs(timeDifference) / (1000 * 60 * 60 * 24)
    );
    return daysDifference;
  };
  // Repair and cost(Maintanance)--------------------------------------***************************************------------------------
  const handleSave = () => {
    setDivBackgroundColor("white"); //set color as you want......
    const amount = parseFloat(inputValueAmount);
    if (isNaN(amount)) return; // Exit if amount is not a number

    const newObj = {
      date: inputValueDate,
      category: selectedOptionv,
      payee_vendor: selectedOptionw,
      amount: amount,
      payment_method: selectedOptionx,
      description: inputValueDescription,
    };
    const newObjects = [...objects, newObj];
    setObjects(newObjects);
    const today = new Date().toISOString().split("T")[0];
    setInputValueDate(today);
    setSelectedOptionv("");
    setSelectedOptionw("");
    setSelectedOptionx("");
    setInputValueAmount("");
    setInputValueDescription("");

    const newTotalAmount = newObjects.reduce(
      (acc, curr) => acc + curr.amount,
      0
    );
    setTotalAmount(newTotalAmount);
  };

  const handleRowSelect = (index) => {
    if (selectedRows.includes(index)) {
      setSelectedRows(selectedRows.filter((i) => i !== index));
    } else {
      setSelectedRows([...selectedRows, index]);
    }
  };
  // -------------------------------------------------------------------------------------
  const handleUpdate = () => {
    setDivBackgroundColor("white"); //set color as you want......
    const amount = parseFloat(inputValueAmount);
    if (isNaN(amount)) return; // Exit if amount is not a number

    const updatedObj = {
      id: objects[editIndex].id, // Retain the id of the object
      date: inputValueDate,
      category: selectedOptionv,
      payee_vendor: selectedOptionw,
      amount: amount,
      payment_method: selectedOptionx,
      description: inputValueDescription,
    };

    const newObjects = objects.map((obj, index) =>
      index === editIndex ? updatedObj : obj
    );
    setObjects(newObjects);
    resetForm();

    const newTotalAmount = newObjects.reduce(
      (acc, curr) => acc + curr.amount,
      0
    );
    setTotalAmount(newTotalAmount);
    setIsEditing(false);
    setEditIndex(null);
  };

  const resetForm = () => {
    setInputValueDate("");
    setSelectedOptionv("");
    setSelectedOptionw("");
    setSelectedOptionx("");
    setInputValueAmount("");
    setInputValueDescription("");
  };

  const handleDelete = () => {
    const newObjects = objects.filter(
      (_, index) => !selectedRows.includes(index)
    );
    setObjects(newObjects);
    setSelectedRows([]);
    const newTotalAmount = newObjects.reduce(
      (acc, curr) => acc + curr.amount,
      0
    );
    setTotalAmount(newTotalAmount);
  };

  const handleRowDoubleClick = (index) => {
    const row = objects[index];
    setInputValueDate(row.date);
    setSelectedOptionv(row.category);
    setSelectedOptionw(row.payee_vendor);
    setSelectedOptionx(row.payment_method);
    setInputValueAmount(row.amount.toString());
    setInputValueDescription(row.description);
    setHighlightedRow(index);
    setIsEditing(true);
    setEditIndex(index);
    setDivBackgroundColor("#fce8cd"); //set background color as you want......
  };

  // --------------------------------******************************************************---------------------------------------------------

  // milage in toggle eye-------------------
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isPasswordVisibleb, setIsPasswordVisibleb] = useState(false);
  const [isPasswordVisiblec, setIsPasswordVisiblec] = useState(false);
  const [isPasswordVisibled, setIsPasswordVisibled] = useState(false);
  const [isPasswordVisiblee, setIsPasswordVisiblee] = useState(false);

  const inputRef = useRef(null);
  const inputRefb = useRef(null);
  const inputRefc = useRef(null);
  const inputReff = useRef(null);

  const [masked, setMasked] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [digitCount, setDigitCount] = useState(0);

  useEffect(() => {
    // Reset masking state if formData changes
    if (masked) {
      setMasked(false);
    }
  }, [formData.purchase_price]);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };
  const togglePasswordVisibilityb = () => {
    setIsPasswordVisibleb(!isPasswordVisibleb);
  };
  const togglePasswordVisibilityc = () => {
    setIsPasswordVisiblec(!isPasswordVisiblec);
  };
  const togglePasswordVisibilityd = () => {
    setIsPasswordVisibled(!isPasswordVisibled);
  };

  const handleMouseDown = () => {
    setIsPasswordVisible(true);
  };
  const handleMouseUp = () => {
    setIsPasswordVisible(false);
  };
  const handleMouseDownb = () => {
    setIsPasswordVisibleb(true);
  };
  const handleMouseUpb = () => {
    setIsPasswordVisibleb(false);
  };
  const handleMouseDownc = () => {
    setIsPasswordVisiblec(true);
  };
  const handleMouseUpc = () => {
    setIsPasswordVisiblec(false);
  };
  const handleMouseDownd = () => {
    setIsPasswordVisiblec(true);
  };
  const handleMouseUpd = () => {
    setIsPasswordVisiblec(false);
  };
  const handleMouseDowne = () => {
    setIsPasswordVisiblee(true);
  };
  const handleMouseUpe = () => {
    setIsPasswordVisiblee(false);
  };

  // floor fees---------------------------------------------------
  // total repare cost--------------
  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      total_repair_cost: totalAmount.toFixed(2),
    }));
  }, [totalAmount]);

  // Calculate total
  const calculateTotal = () => {
    const { purchase_price, total_repair_cost, auction_fees, others } =
      formData;
    return (
      parseFloat(purchase_price || 0) +
      parseFloat(total_repair_cost || 0) +
      parseFloat(auction_fees || 0) +
      parseFloat(others || 0)
    ).toFixed(2);
  };

  const calculateProfit = () => {
    const listingPrice = parseFloat(formData.listing_price) || 0;
    const totalCost = parseFloat(formData.total_cost) || 0;
    return listingPrice - totalCost;
  };

  useEffect(() => {
    const newTotalCost = calculateTotal();
    setFormData((prevFormData) => ({
      ...prevFormData,
      total_cost: newTotalCost,
    }));
  }, [
    formData.purchase_price,
    formData.total_repair_cost,
    formData.auction_fees,
    formData.others,
  ]);

  useEffect(() => {
    const newProfitPrice = calculateProfit().toFixed(2);
    setFormData((prevFormData) => ({
      ...prevFormData,
      profit_price: newProfitPrice,
    }));
  }, [formData.listing_price, formData.total_cost]);
  // ---------------------------
  const handleSaveFloor = () => {
    const newObjFloor = {
      floor: inputValue34,
      interest_rate: inputValueInterestRate,
      floor_plan_length: inputValueLengthFloor,
      // new--
      vpn_insurance: inputValueVpnInsurance,
      floor_fees: inputValueFloorFees,
      // ------------
      // admin_fee: inputValueAdminFees,
      purchase_price: inputValuePurchasePrice,
      // setup_fee: inputValueSetupFees,
      // delivery_fee: inputValueDeliveryFees,
      additional_fee: inputValueAdditionalFees,
      document_fees: inputValueDocumentProcessing,
      delivery_fees: inputValueDeleveryFees,
      total_fees_interese: inputValueTotalFeesInterest,
      total_fees_interest_purchase: inputValueTotalFeesInterestPurchase,
    };
    setObjectsFloor([...objectsFloor, newObjFloor]);
    setInputValue34("");
  };

  // Update formData whenever objects changes
  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      repair_cost: objects,
      floor_fee: objectsFloor,
    }));
  }, [objects, objectsFloor]);

  //console.log("line230", objectsFloor);
  // for decode the api data-------------------------------------------------------------------------------------------------------
  const handleChangedecode = async (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Check if the input has exactly 17 characters
    if (name === "vin" && value.length === 17) {
      await handleDecode(value); // Pass value directly to handleDecode
    }
  };

  // New function to handle key press events
  const handleKeyPress = async (event) => {
    const { name, value } = event.target;
    if (event.key === "Enter") {
      // Check if Enter key is pressed
      event.preventDefault(); // Prevent default Enter key behavior
      if (name === "vin" && value.length === 17) {
        await handleDecode(value);
      }
    }
  };

  const handleDecode = async (vinValue) => {
    try {
      const fd = new FormData();
      fd.append("vin", vinValue); // Use the passed vinValue
      fd.append("userId", dealerId);

      const response = await axios.post(`${apiUrl}/listing/get_vin_data`, fd);
      if (response.status === 200) {
        //console.log("API Data:", response.data);
        setApiData(response.data[0]);
        setApiDatab(response.data[1]);
        setApiDatac(response.data[2]);
      }
    } catch (error) {
      if (error.response.status === 409) {
        // Handle retry logic for 409 Conflict status
        console.log(
          "API request failed with status 409. Making a GET request..."
        );
        setMessageDecode(1);
        setAlertVisible(true); // Show the alert message
      } else if (error.response.status === 300) {
        // Handle retry logic for 409 Conflict status
        console.log(
          "API request failed with status 409. Making a GET request..."
        );
        setMessageDecode(2);
        setAlertVisible(true); // Show the alert message
        setFormData((prevState) => ({
          ...prevState,
          id: 0,
        }));
      } else if (error.response.status === 401) {
        console.log("API request failed with status 401");
        setErrorMessage(error.response.data);
      } else if (error.response) {
        console.log("Error:", error.response.data);
        setErrorMessage(error.response.data);
      } else if (error.request) {
        console.log("Error:", error.request);
      } else {
        console.log("Error:", error.message);
      }
    }
  };

  // NM0LS7F79G1269247
  const handleRetry = async () => {
    //alert("Hello", formData.vin);

    setAlertVisible(false); // Hide the alert after confirming

    const retryUrl = `${apiUrl}/listing/get_vin/${formData.vin}/${id}`;
    try {
      const retryResponse = await axios.get(retryUrl);
      if (retryResponse.status === 200) {
        //alert("Hello1");
        //console.log("Retry API Data:", retryResponse.data);
        setApiDataEdit(retryResponse.data[0]);
        if (messageDecode === 2) {
          setBtnTxt("Save");
          setFormData(({ id, ...prevState }) => ({
            ...prevState,
            id: 0,
          }));
        } else if (messageDecode === 1) {
          setBtnTxt("Update");
        }
      } else {
        // alert("Hello3");
        console.log(`Retry request failed with status ${retryResponse.status}`);
        setErrorMessage(retryResponse.data);
      }
    } catch (retryError) {
      console.log("Retry request failed with error:", retryError);
      setErrorMessage(retryError.message);
    }
  };

  console.log("formdata2562", formData);
  const handleCancel = () => {
    setAlertVisible(false); // Hide the alert
    setFormData({ vin: "" }); // Clear the input field
  };
  useEffect(() => {
    //console.log("apiData:", apiData); // Debugging log to check apiData structure
    if (apiData) {
      const newEmptyFields = {
        year_make_model: !(
          apiData?.ModelYear &&
          apiData?.Make &&
          apiData?.Model
        ),
        year: apiData?.ModelYear,
        make: apiData?.Make,
        model: apiData?.Model,
        engine: !apiData?.WheelBaseShort,
        exteriorColor: !apiData?.ExteriorColor,
        interiorColor: !apiData?.InteriorColor,
        odo: !apiData?.ODO,
        owner: !apiData?.Owner,
        mileage_in: !apiData?.Miles,
        title_status: !apiData?.TitleProblem,
        // stock_no: !apiData?.stock_number,
        vehicleType: !apiData?.VehicleType,
        fuel_type: !apiData?.FuelTypePrimary,
        fuel_type_secondary: !apiData?.FuelTypeSecondary,
        engine_cylinders: !apiData?.EngineCylinders,
        transmission: !(
          apiData?.TransmissionStyle && apiData?.TransmissionSpeeds
        ),
        engine_size: !apiData?.DisplacementL,
        engine_type: !apiData?.EngineConfiguration,
        drive_train: !apiData?.DriveType,
        door_count: !apiData?.Doors,
        battery_type: !apiData?.BatteryType,
        battery_kwh: !apiData?.BatteryKWh,
        other_restraint: !(
          apiData?.OtherRestraintSystemInfo && apiData?.AirBagLocFront
        ),
        trim: !apiData?.Trim,
        seat_count: !apiData?.Seats,
        horse_power: !apiData?.DisplacementCI,
        city_mpg: !apiData?.city_mpg,
        highway_mpg: !apiData?.highway_mpg,
        interiorType: !apiData?.interiorType,
      };
      // Set the isSeatsPresent state based on the seat_count field
      setIsSeatsPresent(!!apiData?.Seats);

      setEmptyFields(newEmptyFields);

      const combinedYearMakeModel = `${apiData?.ModelYear || ""} ${
        apiData?.Make || ""
      } ${apiData?.Model || ""} ${
        apiData?.BodyClass === "Cargo Van" || "Van" || "Bus"
          ? apiData?.WheelBaseShort
            ? `WB${apiData?.WheelBaseShort}`
            : ""
          : apiData?.BodyClass || ""
      } ${apiData?.DriveType || ""} ${
        apiData?.Doors ? `${apiData?.Doors}D` : ""
      }`;
      const combinedBodyClassSeriesWheelBase = `${
        apiData?.FuelTypePrimary === "Electric"
          ? `${apiData?.FuelTypePrimary} ${
              apiData?.BatteryKWh ? `${apiData?.BatteryKWh}Kwh` : ""
            } ${apiData?.BatteryType || ""}`
          : `${
              apiData?.EngineCylinders ? `${apiData?.EngineCylinders}CYL` : ""
            } ${apiData?.DisplacementL ? `${apiData?.DisplacementL}L` : ""} ${
              apiData?.EngineConfiguration || ""
            } ${apiData?.FuelTypePrimary || ""} ${
              apiData?.FuelTypeSecondary ? `/${apiData?.FuelTypeSecondary}` : ""
            }`
      }`;
      // const transTransSpeed = `${apiData?.TransmissionStyle || ""} ${

      //   apiData?.TransmissionSpeeds
      //     ? `${apiData?.TransmissionSpeeds} Speed`
      //     : ""
      // }`;
      // Here is the change in the code for transTransSpeed---------------------------*********-----
      // const transTransSpeed = `${
      //   apiData?.city_mpg && apiData?.highway_mpg
      //     ? `${apiDatac || ""} ${
      //         apiData?.TransmissionSpeeds
      //           ? `${apiData?.TransmissionSpeeds} Speed`
      //           : ""
      //       }`
      //     : `${apiData?.TransmissionStyle || ""}   ${
      //         apiData?.TransmissionSpeeds
      //           ? `${apiData?.TransmissionSpeeds} - Speed`
      //           : ""
      //       }`
      // }`;

      const transTransSpeed = `${apiData?.TransmissionStyle || ""} ${
        apiData?.TransmissionSpeeds
          ? `${apiData?.TransmissionSpeeds} - Speed`
          : ""
      }`;

      const otherRestrain = `${apiData?.OtherRestraintSystemInfo || ""} ${
        apiData?.AirBagLocFront || ""
      }`;
      const trimSeries = `${apiData?.Trim || ""}`;
      // Set the isTransTransSpeedPresent state based on the transTransSpeed field
      setIsTransmissionPresent(!!transTransSpeed);
      setFormData((prevFormData) => ({
        ...prevFormData,
        year_make_model: combinedYearMakeModel || "",
        engine: combinedBodyClassSeriesWheelBase || "",
        vehicleType: apiData?.VehicleType || "",
        // stock_no: apiData?.stock_number || "",
        // only send for split purpose--
        make: apiData?.Make || "",
        year: apiData?.ModelYear || "",
        model: apiData?.Model || "",
        bodyClass: apiData?.BodyClass || "",
        wheelbase: apiData?.WheelBaseShort || "",
        driveType: apiData?.DriveType || "",
        doorCount: apiData?.Doors || "",
        // -------------------------
        exteriorColor: apiData?.ExteriorColor || "",
        interiorColor: apiData?.InteriorColor || "",
        odo: apiData?.ODO || "",
        owner: apiData?.Owner || "",
        mileage_in: apiData?.Miles || "",
        title_status: apiData?.TitleProblem || "",
        transmission: transTransSpeed || "",
        drive_train: apiData?.DriveType || "",
        door_count: apiData?.Doors || "",
        battery_type: apiData?.BatteryType || "",
        battery_kwh: apiData?.BatteryKWh || "",
        other_restraint: otherRestrain || "",
        trim: trimSeries || "",
        seat_count: apiData?.Seats || "",
        horse_power: apiData?.DisplacementCI || "",
        city_mpg: apiData?.city_mpg || "",
        highway_mpg: apiData?.highway_mpg || "",
        interiorType: apiData?.interiorType || "",
      }));
      // Set year, make, and model states
      setYear(apiData?.ModelYear);
      setMake(apiData?.Make);
      setModel(apiData?.Model);
    }
  }, [apiData]);
  // -------------------for decode the highway and city mpg-------------*************************-----------------------
  const transmissiondecode = async () => {
    try {
      const fd = new FormData();
      fd.append("year", year); // Assuming 'year' holds the VIN value
      fd.append("make", make);
      fd.append("model", model);
      fd.append("transmission", selectedOptionc);

      const response = await axios.post(`${apiUrl}/listing/get_pri_data`, fd);
      if (response.status === 200) {
        console.log("API Data 1951:", response.data);
        setFormData((prevFormData) => ({
          ...prevFormData,
          highway_mpg: response.data.highway_mpg || "",
          city_mpg: response.data.city_mpg || "",
        }));
      }
    } catch (error) {
      console.log(
        "API request failed with status 409. Making a GET request..."
      );
    }
  };
  // for decode the api data end-------------------------------------------------------------------------------------------------------
  // for images vehivcle all,ext,int,360-----------------------------------------------------------------
  const [selectedCategory, setSelectedCategory] = useState("ALL");
  const [files, setFiles] = useState([]); // For files uploaded via file input
  const [titleOthersFiles, setTitleOthersFiles] = useState([]); // Add this line
  // Highlighted line: new state for bill_sale files
  const [billSaleFiles, setBillSaleFiles] = useState([]);
  const titleFileInputRef = useRef(null);
  const billSaleFileInputRef = useRef(null);
  //const imageButtons = ["ALL", "INT.", "EXT.", "MISC.","360"];
  const [imageData, setImageData] = useState({
    ALL: [],
    INT: Array(1).fill(Image.defaultImg),
    EXT: Array(1).fill(Image.defaultImg),
    MISC: Array(1).fill(Image.defaultImg),
    360: Array(1).fill(Image.defaultImg),
  });
  // // Define default image URLs for each category
  const defaultImages = {
    ALL: `${Image.defaultImage}`, // Example default image URL
    INT: `${Image.defaultImage}`,
    EXT: `${Image.defaultImage}`,
    MISC: `${Image.defaultImage}`,
    360: `${Image.defaultImage}`,
  };
  // -------------------------------------------------------
  useEffect(() => {
    if (apiDataEdit && apiDataEdit.multimedia) {
      const multimedia = apiDataEdit.multimedia;

      const processedData = {
        ALL: [],
        INT: Array(1).fill(Image.defaultImg),
        EXT: Array(1).fill(Image.defaultImg),
        MISC: Array(1).fill(Image.defaultImg),
        360: Array(1).fill(Image.defaultImg),
      };

      if (multimedia.all_images) {
        processedData.ALL = multimedia.all_images
          .split(",")
          .map((image) => `${imgUrl}/${id}/${image.trim()}`);
      }

      if (multimedia.interior_images) {
        processedData.INT = multimedia.interior_images
          .split(",")
          .map((image) => `${imgUrl}/${id}/${image.trim()}`);
      }

      if (multimedia.exterior_images) {
        processedData.EXT = multimedia.exterior_images
          .split(",")
          .map((image) => `${imgUrl}/${id}/${image.trim()}`);
      }
      if (multimedia.misc_images) {
        processedData.MISC = multimedia.misc_images
          .split(",")
          .map((image) => `${imgUrl}/${id}/${image.trim()}`);
      }
      if (multimedia.three_sixty_images) {
        processedData["360"] = multimedia.three_sixty_images
          .split(",")
          .map((image) => `${imgUrl}/${id}/${image.trim()}`);
      }

      const processedVideoData = [];
      if (multimedia.all_videos) {
        processedVideoData.push(
          ...multimedia.all_videos
            .split(",")
            .map((video) => `${imgUrl}/${id}/${video.trim()}`)
        );
      }
      setImageData(processedData);
      setVideosData(processedVideoData);

      // Highlighted block: Process title_others files
      if (multimedia.title_others) {
        setTitleOthersFiles(
          multimedia.title_others.split(",").map((file) => ({
            name: file.trim(),
            url: `${imgUrl}/${id}/${file.trim()}`,
          }))
        );
      } else {
        setTitleOthersFiles([]);
      }

      // Highlighted block: Process bill_sale files
      if (multimedia.bill_sale) {
        setBillSaleFiles(
          multimedia.bill_sale.split(",").map((file) => ({
            name: file.trim(),
            url: `${imgUrl}/${id}/${file.trim()}`,
          }))
        );
      } else {
        setBillSaleFiles([]);
      }
    }
  }, [apiDataEdit]);

  // Determine images to display: uploaded images or default image
  const [imagesToDisplay, setImagesToDisplay] = useState([Image.defaultImage]);

  useEffect(() => {
    let newImagesToDisplay;
    let newCategory;

    if (
      imageData["ALL"].length > 0 &&
      (imageData["ALL"].some((item) => item instanceof File) ||
        imageData["ALL"].some(
          (item) => typeof item === "string" && !item.startsWith("data:image")
        ))
    ) {
      newImagesToDisplay = imageData["ALL"];
      newCategory = "ALL";
    } else if (
      imageData["EXT"].length > 0 &&
      (imageData["EXT"].some((item) => item instanceof File) ||
        imageData["EXT"].some(
          (item) => typeof item === "string" && !item.startsWith("data:image")
        ))
    ) {
      newImagesToDisplay = imageData["EXT"];
      newCategory = "EXT";
    } else if (
      imageData["INT"].length > 0 &&
      (imageData["INT"].some((item) => item instanceof File) ||
        imageData["INT"].some(
          (item) => typeof item === "string" && !item.startsWith("data:image")
        ))
    ) {
      newImagesToDisplay = imageData["INT"];
      newCategory = "INT";
    } else if (
      imageData["MISC"].length > 0 &&
      (imageData["MISC"].some((item) => item instanceof File) ||
        imageData["MISC"].some(
          (item) => typeof item === "string" && !item.startsWith("data:image")
        ))
    ) {
      newImagesToDisplay = imageData["MISC"];
      newCategory = "MISC";
    } else if (
      imageData["360"].length > 0 &&
      (imageData["360"].some((item) => item instanceof File) ||
        imageData["360"].some(
          (item) => typeof item === "string" && !item.startsWith("data:image")
        ))
    ) {
      newImagesToDisplay = imageData["360"];
      newCategory = "360";
    } else {
      newImagesToDisplay = [Image.defaultImage];
      newCategory = "ALL";
    }

    setImagesToDisplay(newImagesToDisplay);
    setSelectedCategory(newCategory);
  }, [imageData]);

  const all_images = imageData.ALL;
  const interior_images = imageData.INT;
  const exterior_images = imageData.EXT;
  const misc_images = imageData.MISC;
  const three_sixty_images = imageData["360"];
  // console.log("line641", imageData);

  let sliderRef = useRef(null);
  const play = () => {
    sliderRef.slickPlay();
    setIsPlay(!isPlay);
  };
  const pause = () => {
    sliderRef.slickPause();
    setIsPlay(!isPlay);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    autoplay: true, // Enable auto sliding
    autoplaySpeed: 3000, // Set interval to 3 seconds (3000 ms)
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const handleImageChange = (e) => {
    if (!formData.vin || !formData.userId || !formData.year_make_model) {
      toast.error("Fill the vin field first, VIN is required.");
      return;
    }

    const files = Array.from(e.target.files);
    setTotalCount(files.length);

    if (files.length > 0) {
      const newImageData = { ...imageData };

      files.forEach((file) => {
        newImageData["ALL"].push(file);
      });
      setImageData(newImageData);
    }
  };

  const uploadImages = async (files) => {
    let uploadedFilesCount = 0;
    let overallProgress = 0; // Track the overall progress

    const uploadFile = async (file, fileIndex) => {
      const vin = formData.vin;
      const frmData = new FormData();
      frmData.append("vin", vin);
      frmData.append("user_id", id);
      frmData.append("all_images", file);
      frmData.append("addListing", JSON.stringify(frmData));

      return new Promise((resolve) => {
        axios
          .post(`${apiUrl}/listing/save_images`, frmData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );

              // Calculate the contribution of the current file's upload to the total percentage
              const currentFileContribution = percentCompleted / totalCount;

              // Calculate the total percentage considering the progress of all uploaded files
              const newPercentage = Math.round(
                (uploadedFilesCount / totalCount + currentFileContribution) *
                  100
              );

              // Update the overall percentage
              setPercentage(newPercentage);
            },
          })
          .then((response) => {
            if (response.status === 200) {
              uploadedFilesCount++;
              setUploadedCount(uploadedFilesCount);
              resolve();
            }
          })
          .catch((error) => {
            console.error(`Error uploading file ${file.name}:`, error);
            resolve();
          });
      });
    };

    const uploadPromises = files.map((file, index) => uploadFile(file, index));
    await Promise.all(uploadPromises);

    // Ensure uploadedCount is set correctly once all uploads are complete
    setUploadedCount(files.length);
    setPercentage(100); // Set to 100% once all uploads are complete
  };

  const handleVideoChange = (e) => {
    if (!formData.vin || !formData.userId || !formData.year_make_model) {
      toast.error("Fill the vin field first, VIN is required.");
      return;
    }

    const files = Array.from(e.target.files);
    if (files.length > 0) {
      const newVideosData = [...videosData, ...files];
      setVideosData(newVideosData);
    }
  };

  const handleDeleteImage = async (category, index, imageName) => {
    console.log("category, index, imageName", category, index, imageName);

    if (formData.id > 0) {
      const deleteUrl = `${apiUrl}/listing/delete_images/${
        formData.id
      }/${id}/ALL/${encodeURIComponent(imageName)}`;

      try {
        const response = await axios.delete(deleteUrl);

        if (response.status === 200) {
          // Handle success case
          const newImageData = { ...imageData };
          newImageData[category].splice(index, 1);
          setImageData(newImageData);
        } else {
          console.log(`Request failed with status ${response.status}`);
        }
      } catch (error) {
        console.log("Request failed with error:", error);
      }
    } else {
      const newImageData = { ...imageData };
      newImageData[category].splice(index, 1);
      setImageData(newImageData);
    }

    if (imageData[imageType] < 1) {
      imageData[imageType].push(Image.defaultImg);
    }

    console.log("imageData", imageData);
  };

  const handleDeleteImgAll = async (category) => {
    if (!imageData || !Array.isArray(imageData[category])) {
      console.error("Invalid imageData or category", imageData, category);
      return;
    }

    const imagesToDelete = imageData[category];

    // Function to extract image name from URL
    const extractImageName = (url) => {
      try {
        const urlObj = new URL(url);
        return urlObj.pathname.split("/").pop();
      } catch (e) {
        console.error("Invalid URL format", url);
        return null; // Return null or any other fallback value
      }
    };

    // Collect promises for all deletions
    const deletePromises = [];

    for (let i = 0; i < imagesToDelete.length; i++) {
      const image = imagesToDelete[i];
      let imageName;
      if (image instanceof File) {
        imageName = image.name; // Use the name property of the File object
      } else {
        // Extract image name from URL or string
        imageName = extractImageName(image);
      }
      const decodedImageName = decodeURIComponent(imageName);
      deletePromises.push(handleDeleteImage(category, i, decodedImageName));
    }

    // Wait for all deletions to complete
    await Promise.all(deletePromises);

    // Clear imageData for the category after all deletions
    setImageData((prev) => {
      const newImageData = { ...prev };

      if (
        category === "EXT" ||
        category === "INT" ||
        category === "MISC" ||
        category === "360"
      ) {
        newImageData[category] = [Image.defaultImg];
      } else {
        newImageData[category] = [];
      } // Assuming you want to clear the images in the category
      return newImageData;
    });

    console.log("All images in category", category, "have been deleted");
  };
  // All Image Modal
  const handleDeleteMedia = async (category, index, mediaName) => {
    const mediaUrl = `${imgUrl}/${id}/${mediaName}`;

    if (formData.id > 0) {
      const deleteUrl =
        //  mediaType === 'image' ? `${apiUrl}/listing/delete_images/${formData.id}/${id}/${encodeURIComponent(mediaName)}` :
        `${apiUrl}/listing/delete_images/${
          formData.id
        }/${id}/ALL/${encodeURIComponent(mediaName)}`;

      try {
        const response = await axios.delete(deleteUrl);

        if (response.status === 200) {
          if (mediaType === "image") {
            setImageData((prevImageData) => {
              const newImageData = { ...prevImageData };
              newImageData[category] = newImageData[category].filter(
                (img) => img !== mediaUrl
              );
              return newImageData;
            });
          } else {
            setVideosData((prevVideosData) => {
              const newVideosData = [...prevVideosData];
              newVideosData.splice(index, 1);
              return newVideosData;
            });
          }
        } else {
          console.log(`Request failed with status ${response.status}`);
        }
      } catch (error) {
        console.log("Request failed with error:", error);
      }
    } else {
      if (mediaType === "image") {
        setImageData((prevImageData) => {
          const newImageData = { ...prevImageData };
          newImageData[category].splice(index, 1);
          return newImageData;
        });
      } else {
        setVideosData((prevVideosData) => {
          const newVideosData = [...prevVideosData];
          newVideosData.splice(index, 1);
          return newVideosData;
        });
      }
    }
  };

  useEffect(() => {
    handleAllImages();
  }, [imageData]);

  const handleAllImages = () => {
    let allImages = [];

    // Iterate through each category in imageData
    Object.keys(imageData).forEach((category) => {
      // Extract paths or names and concatenate to allImages array
      const imagesInCategory = imageData[category].map((image) => {
        if (typeof image === "string") {
          // If image is already a path, push it directly
          return image;
        } else if (image instanceof File) {
          console.log(
            "URL.createObjectURL(image)*************************",
            URL.createObjectURL(image)
          );
          return URL.createObjectURL(image);
        } else {
          // Handle other cases if necessary
          return ""; // Placeholder or handling other types
        }
      });

      // Concatenate all images in each category to allImages array
      allImages = allImages.concat(imagesInCategory);
    });
    setAllImages(allImages);
  };
  const handleImageRotate = (index) => {
    setRotationAngles((prevAngles) => {
      const newAngles = { ...prevAngles };
      newAngles[index] = (newAngles[index] || 0) + 90;
      return newAngles;
    });
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const { source, destination } = result;

    // Get source and destination lists
    const sourceList = Array.from(imageData[source.droppableId]);
    const destinationList = Array.from(imageData[destination.droppableId]);

    // Logic for rearranging the images in the categories
    if (source.droppableId === destination.droppableId) {
      // Remove the item from the source list
      const [removed] = sourceList.splice(source.index, 1);
      // Insert the item into the destination list at the new position
      sourceList.splice(destination.index, 0, removed);
      // Update the state with the new list
      setImageData((prev) => ({
        ...prev,
        [source.droppableId]: sourceList,
      }));
    } else {
      // Handle moving items between different categories
      const [removed] = sourceList.splice(source.index, 1);
      const emptyIndex = destinationList.findIndex(
        (item) => item === Image.defaultImg
      );
      if (emptyIndex !== -1) {
        destinationList[emptyIndex] = removed;
      } else {
        destinationList.push(removed);
      }

      // Ensure source list length does not fall below 12 items by adding default images if necessary
      if (source.droppableId !== "ALL" && sourceList.length < 1) {
        while (sourceList.length < 1) {
          sourceList.push(Image.defaultImg);
        }
      }

      // Update the state
      setImageData((prev) => ({
        ...prev,
        [source.droppableId]: sourceList,
        [destination.droppableId]: destinationList,
      }));
    }

    //console.log("Image Drop Completed");
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDrop = (e, selectedCategory) => {
    if (!formData.vin || !formData.userId || !formData.year_make_model) {
      toast.error("Fill the vin field first, VIN is required.");
      return;
    }

    e.preventDefault();
    setIsDragging(false);

    const files = Array.from(e.dataTransfer.files);
    setImageData((prevImageData) => {
      const newImageData = { ...prevImageData };
      // Start from the length of the current EXT array and try to add new images
      let currentLength = newImageData[selectedCategory].length;

      files.forEach((file) => {
        if (currentLength < 1) {
          const emptyIndex = newImageData[selectedCategory].findIndex(
            (item) => item === null
          );
          if (emptyIndex !== -1) {
            newImageData[selectedCategory][emptyIndex] = file;
            currentLength++;
          }
        } else {
          toast.error("Maximum of 8 images allowed.");
        }
      });

      return newImageData;
    });
  };

  const handleMediaType = (type, imgType) => {
    setMediaType(type);
    setImageType(imgType);
  };

  const handleClickSelection = (image, index, isAll) => {
    if (isAll) {
      // Handle image from imageData.ALL
      setImageData((prev) => {
        // Find the first available index in the target imageType array
        const targetArray = prev[imageType];
        const firstAvailableIndex = targetArray.findIndex(
          (item) => item === Image.defaultImg
        );

        // Create a new array for imageType with the image placed at the first available index
        const newImageTypeArray = [...targetArray];
        if (firstAvailableIndex !== -1) {
          newImageTypeArray[firstAvailableIndex] = image;
        } else {
          newImageTypeArray.push(image);
        }

        return {
          ...prev,
          ALL: prev.ALL.filter((_, i) => i !== index), // Remove image from ALL
          [imageType]: newImageTypeArray, // Update imageType with the new array
        };
      });
    } else if (
      // (image instanceof File &&
      imageType === "EXT" ||
      imageType === "INT" ||
      imageType === "MISC" ||
      imageType === "360"
    ) {
      // )
      // Handle image from imageData[imageType]
      setImageData((prev) => {
        // Find the first available index in the ALL array
        const allArray = prev.ALL;
        const firstAvailableIndexInALL = allArray.findIndex(
          (item) => item === Image.defaultImg
        );

        // Create a new array for ALL with the image placed at the first available index
        const newAllArray = [...allArray];
        if (firstAvailableIndexInALL !== -1) {
          newAllArray[firstAvailableIndexInALL] = image;
        } else {
          newAllArray.push(image);
        }

        // Replace the removed image with Image.defaultImg in the imageType array
        const newImageTypeArray = [...prev[imageType]];
        if (prev[imageType].length <= 1) {
          // If the imageType array has fewer than 1 image, add default image
          newImageTypeArray[index] = Image.defaultImg;
        } else {
          // Just remove the image by setting it to an empty string or another placeholder if needed
          newImageTypeArray.splice(index, 1);
        }

        return {
          ...prev,
          [imageType]: newImageTypeArray, // Update imageType with default image at removed index
          ALL: newAllArray, // Update ALL with the new array
        };
      });
    }

    setCurrentImageIndex(0);
  };

  const handleMoveDownAll = (isAll) => {
    if (imageData && Array.isArray(imageData[imageType])) {
      const items = imageData[imageType];
      // First, move images to the ALL array
      items.forEach((item, index) => handleClickSelection(item, index, isAll));

      // After processing all items, reset imageData[imageType] to only contain Image.defaultImg
      setImageData((prev) => ({
        ...prev,
        [imageType]: [Image.defaultImg], // Ensure only Image.defaultImg remains
      }));
    } else {
      console.error("Invalid imageData or imageType", imageData, imageType);
    }

    console.log("imageData", imageData);
    console.log("imageType", imageType);
  };

  const handleMoveUpAll = (isAll) => {
    setImageData((prev) => {
      // Get current arrays
      const allImages = prev.ALL;
      const imageTypeArray = prev[imageType] || [];

      // Create a new array for imageType
      let newImageTypeArray;

      if (imageTypeArray.includes(Image.defaultImg)) {
        // If imageTypeArray contains Image.defaultImg, replace it with images from ALL
        newImageTypeArray = allImages.slice(); // Copy all images from ALL

        // Remove all instances of Image.defaultImg
        newImageTypeArray = newImageTypeArray.filter(
          (img) => img !== Image.defaultImg
        );
      } else {
        // If imageTypeArray does not contain Image.defaultImg, just append all images from ALL
        newImageTypeArray = [...imageTypeArray, ...allImages];
      }

      // Remove duplicates and keep only Image.defaultImg in imageTypeArray if needed
      newImageTypeArray = Array.from(new Set(newImageTypeArray));

      // Return updated state with `ALL` emptied and `imageType` updated
      return {
        ...prev,
        [imageType]: newImageTypeArray, // Update `imageType` with images from `ALL`
        ALL: [], // Clear `ALL` after moving the images
      };
    });

    setCurrentImageIndex(0); // Reset current image index if necessary
  };

  const handleCheckSelection = (image, index, isAll) => (e) => {
    if (e.target.checked) {
      handleClickSelection(image, index, isAll);
      setIsImgChecked(false);
    }
  };

  const handleSaveImg = async () => {
    setLoading(true);
    setShowModalImg(false);

    const vin = formData.vin;
    const imgData = new FormData();
    imgData.append("vin", vin);
    imgData.append("user_id", id);

    const totalMediaCount =
      imageData.ALL.length +
      imageData.EXT.length +
      imageData.INT.length +
      imageData[360].length +
      imageData.MISC.length +
      videosData.length;
    setTotalCount(totalMediaCount);
    let uploadedMediaCount = 0;

    const uploadQueue = async (files, formDataKey, isImage = false) => {
      for (const file of files) {
        if (file instanceof File) {
          await uploadFile(file, formDataKey);
        } else if (file !== Image.defaultImg) {
          const lastSlashIndex = file.lastIndexOf("/");
          const fileName = file.substring(lastSlashIndex + 1);
          const fileType = isImage ? "image/png" : "video/mp4";
          const fileSize = isImage ? 579500 : 2621440;

          const fileContent = new Uint8Array(fileSize);
          const newFile = new File([fileContent], fileName, {
            type: fileType,
            lastModified: Date.now(),
          });

          await uploadFile(newFile, formDataKey);
        }
      }
    };

    const updateProgress = () => {
      setPercentage(
        Math.min(Math.floor((uploadedMediaCount / totalMediaCount) * 100), 80)
      );
    };

    const uploadFile = async (file, formDataKey) => {
      try {
        imgData.append(formDataKey, file);
        await new Promise((resolve) => setTimeout(resolve, 100));
        uploadedMediaCount++;
        setUploadedCount(uploadedMediaCount);
        updateProgress();
      } catch (error) {
        console.error(`Error uploading file ${file.name}:`, error);
      }
    };
    setTimeout(async () => {
      try {
        // Start upload queue
        await uploadQueue(imageData.ALL, "all_images", true);
        await uploadQueue(imageData.EXT, "exterior_images", true);
        await uploadQueue(imageData.INT, "interior_images", true);
        await uploadQueue(imageData.MISC, "misc_images", true);
        await uploadQueue(imageData[360], "three_sixty_images", true);
        await uploadQueue(videosData, "all_videos");

        // Make the API call
        const response = await axios.post(
          `${apiUrl}/listing/save_images`,
          imgData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.status === 200) {
          setUploadedCount(totalMediaCount);
          setPercentage(100);
          setIsImagesSaved(true);
        }

        console.log("API response:", response.data);
      } catch (error) {
        console.error("Error saving form data:", error);
      } finally {
        setUploadedCount(totalCount);
        setPercentage(100);
        setLoading(false);
      }
    }, 500);
  };
  // Title and Others------------------------------------------
  const [isZoomed, setIsZoomed] = useState(false);
  const [isZoomedb, setIsZoomedb] = useState(false);
  const [carouselKey, setCarouselKey] = useState(0); // Add a carouselKey state
  const [carouselKeyb, setCarouselKeyb] = useState(0); // Add a carouselKey state
  const handleFileChange = (e) => {
    setFiles([...files, ...Array.from(e.target.files)]);
    setCarouselKey((prevKey) => prevKey + 1); // Update the carouselKey
  };

  const handleButtonClick = () => {
    titleFileInputRef.current.click();
  };

  const handleButtonClickb = () => {
    billSaleFileInputRef.current.click();
  };

  const handleDeleteFile = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
    setCarouselKey((prevKey) => prevKey + 1); // Update the carouselKey
  };

  const handleDeleteTitleOthersFile = async (index, fileName) => {
    if (formData.id > 0) {
      const deleteUrl = `${apiUrl}/listing/delete_images/${
        formData.id
      }/title_others/${encodeURIComponent(fileName)}`;

      try {
        const response = await axios.delete(deleteUrl);

        if (response.status === 200) {
          // Remove the file from the state
          const newTitleOthersFiles = titleOthersFiles.filter(
            (file) => file.name !== fileName
          );
          setTitleOthersFiles(newTitleOthersFiles);
        } else {
          console.log(`Request failed with status ${response.status}`);
        }
      } catch (error) {
        console.log("Request failed with error:", error);
      }
    } else {
      // Remove the file from the state
      const newTitleOthersFiles = titleOthersFiles.filter(
        (file, idx) => idx !== index
      );
      setTitleOthersFiles(newTitleOthersFiles);
    }
    setCarouselKey((prevKey) => prevKey + 1); // Update the carouselKey
  };
  // Bill Of Sale---------------------------------
  const [filesb, setFilesb] = useState([]);
  // const fileInputRef = useRef(null);

  const handleFileChangeb = (e) => {
    setFilesb([...filesb, ...Array.from(e.target.files)]);
    setCarouselKeyb((prevKey) => prevKey + 1); // Update the carouselKey
  };

  const handleDeleteFilebb = (index) => {
    const newFilesb = [...filesb];
    newFilesb.splice(index, 1);
    setFilesb(newFilesb);
    setCarouselKeyb((prevKey) => prevKey + 1); // Update the carouselKey
  };
  const handleDeleteFileb = async (index, fileName) => {
    if (formData.id > 0) {
      const deleteUrl = `${apiUrl}/listing/delete_images/${
        formData.id
      }/bill_sale/${encodeURIComponent(fileName)}`;

      try {
        const response = await axios.delete(deleteUrl);

        if (response.status === 200) {
          // Remove the file from the state
          const newFilesb = filesb.filter((file) => file.name !== fileName);
          setFilesb(newFilesb);
        } else {
          console.log(`Request failed with status ${response.status}`);
        }
      } catch (error) {
        console.log("Request failed with error:", error);
      }
    } else {
      // Remove the file from the state
      const newFilesb = [...filesb];
      newFilesb.splice(index, 1);
      setFilesb(newFilesb);
    }
    setCarouselKeyb((prevKey) => prevKey + 1); // Update the carouselKey
  };
  // ------------------------------------------------------------------------------------------------------------------------------

  // images data end---------------------------------------------********************************-------------------------------------------------------
  const [selectedOption, setSelectedOption] = useState(""); // Updated to an empty string

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const maskValue = (value) => {
    return value ? value.replace(/./g, "X") : "";
  };

  const getMaskedValue = (value) => {
    const length = value.length;
    if (length <= 0) return value;

    if (masked) {
      return "X".repeat(length);
    } else {
      return "X".repeat(length - 1) + value[length - 1];
      // return value; // Return the full value if not masked
    }
  };
  const handleChangeb = (e) => {
    const { name, value } = e.target;

    // Remove any non-numeric characters (except for the decimal point)
    const numericValue = value.replace(/[^\d.]/g, "");

    // Update the digit count based on current input
    const currentDigitCount = numericValue.length;

    // If the number of digits entered has changed
    if (currentDigitCount !== digitCount) {
      setDigitCount(currentDigitCount);

      // Clear existing timeout
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      // Set a new timeout to mask the value
      const newTimeoutId = setTimeout(() => {
        setMasked(true);
      }, 500); // Mask after 500ms

      setTimeoutId(newTimeoutId);
    }

    // Check if the value matches the numeric pattern
    const regex = /^[0-9]*\.?[0-9]*$/;
    if (regex.test(numericValue)) {
      // Convert the numeric value to a number
      const numericValueAsNumber =
        numericValue === "" ? 0.0 : parseFloat(numericValue);

      // Update the form data with the numeric value (without commas)
      setFormData((prevData) => ({
        ...prevData,
        [name]: numericValueAsNumber,
      }));

      // Update the display value with commas
      const formattedValue = formatValue(numericValue);
      setDisplayValues((prevDisplayValues) => ({
        ...prevDisplayValues,
        [name]: formattedValue,
      }));
    }
  };

  const formatValue = (value) => {
    // Convert the value to a string if it's not already
    value = value.toString();

    // Split the value into whole and decimal parts
    const [whole, decimal] = value.split(".");

    // Add commas after every 3 digits for display
    let formattedValue = whole.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    if (decimal !== undefined) {
      formattedValue += "." + decimal;
    }

    return formattedValue;
  };

  // new---------------------------------------------
  const handleCheckBoxChange = (e) => {
    const { name, type, checked } = e.target;

    // For checkboxes, handle checked state separately
    if (type === "checkbox") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked, // Update the value based on the checkbox's checked status
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked, // Update the value based on the checkbox's checked status
      }));
    }
  };
  // ---------------------custom dropdown searchsabl ***************************************************************************************************--------------------------

  const handleDropdownCondition = () => {
    setShowCondition(!show_condition);
  };
  const handleSelectCondtion = (selectedItem) => {
    setInputValue1(selectedItem);
    setFormData((prevFormData) => ({
      ...prevFormData,
      condition: selectedItem,
    }));
    setShowCondition(false);
  };
  const filteredConditions = condition.filter((c) =>
    c.optionDropdown.toLowerCase().includes(inputValue1.toLowerCase() || "")
  );

  // -------------------------------------------************************************************************************************-------------------------------------------

  // ----------------------for form data api save----------------------------------------------------------------------------

  const [disabled, setDisabled] = useState(false);
  const compressImage = async (image) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      return await imageCompression(image, options);
    } catch (error) {
      console.error("Image compression error:", error);
    }
  };

  const uploadImage = async (image, onProgress) => {
    return new Promise((resolve, reject) => {
      if (!image || !image.size) {
        reject(new Error("Invalid image"));
      }
      let progress = 0;
      const interval = setInterval(() => {
        if (progress < 100) {
          progress += 10; // Simulate progress increase
          onProgress(progress, image.size * 0.1); // Pass image size portion
        } else {
          clearInterval(interval);
          resolve(); // Simulate successful upload
        }
      }, 1000); // Adjust this interval for simulation
    });
  };
  const uploadVideo = async (video, onProgress) => {
    return new Promise((resolve) => {
      let progress = 0;
      const interval = setInterval(() => {
        if (progress < 100) {
          progress += 10; // Simulate progress increase
          onProgress(progress, video.size * 0.1); // Pass video size portion
        } else {
          clearInterval(interval);
          resolve(); // Simulate successful upload
        }
      }, 1000); // Adjust this interval for simulation
    });
  };
  console.log("formadata4289", formData);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);
    if (!formData.vin || !formData.userId || !formData.year_make_model) {
      toast.error("VIN, User ID, and Year/Make/Model are required fields.");
      return;
    }

    // Prepare form data for listing
    const fnd = new FormData();
    fnd.append("addListing", JSON.stringify(formData));

    // Prepare form data for images
    const imgData = new FormData();
    imgData.append("vin", formData.vin);
    imgData.append("user_id", formData.userId);

    const totalMediaCount =
      imageData.ALL.length +
      imageData.EXT.length +
      imageData.INT.length +
      imageData[360].length +
      imageData.MISC.length +
      videosData.length;
    setTotalCount(totalMediaCount);
    let uploadedMediaCount = 0;
    // Compress images before uploading
    const compressImageOnly = async (image) => {
      try {
        return await compressImage(image);
      } catch (error) {
        console.error(`Error compressing image ${image.name}:`, error);
      }
    };

    // Compress images before uploading
    const compressAndAppendImage = async (image, formDataKey) => {
      try {
        const compressedImage = await compressImage(image);
        fnd.append(formDataKey, compressedImage);
        return compressedImage;
      } catch (error) {
        console.error(`Error compressing image ${image.name}:`, error);
      }
    };

    // Upload a single file
    const uploadFile = async (file, formDataKey) => {
      try {
        // await uploadImage(file, handleUploadProgress);
        fnd.append(formDataKey, file);

        // uploadedMediaCount++;
        // setUploadedCount(uploadedMediaCount);
      } catch (error) {
        console.error(`Error uploading file ${file.name}:`, error);
      }
    };

    // Upload images
    const uploadImage = async (file, formDataKey) => {
      try {
        imgData.append(formDataKey, file);
        uploadedMediaCount++;
        setUploadedCount(uploadedMediaCount);
        setPercentage(
          Math.min(Math.floor((uploadedMediaCount / totalMediaCount) * 100), 80)
        );
        //console.log("uploadedCount", uploadedCount);
        //console.log("percentage", percentage);
      } catch (error) {
        console.error(`Error uploading image ${file.name}:`, error);
      }
    };

    // Process file upload promises
    const processUploadPromises = (files, formDataKey, isImage = false) => {
      return files.map((file) => {
        if (file instanceof File) {
          return isImage
            ? uploadImage(file, formDataKey)
            : uploadFile(file, formDataKey);
        } else if (file !== Image.defaultImg) {
          const lastSlashIndex = file.lastIndexOf("/");
          const fileName = file.substring(lastSlashIndex + 1);
          const fileType = isImage ? "image/png" : "video/mp4"; // Adjust MIME type as needed
          const fileSize = isImage ? 24832 : 34832; // Adjust size as needed
          const fileContent = new Uint8Array(fileSize);
          const newFile = new File([fileContent], fileName, {
            type: fileType,
            lastModifiedDate: Date.now(),
          });
          return isImage
            ? uploadImage(newFile, formDataKey)
            : uploadFile(newFile, formDataKey);
        }
      });
    };

    const uploadMedia = async () => {
      setLoading(true);

      const allImgUploadPromises = processUploadPromises(
        imageData.ALL,
        "all_images",
        true
      );
      const exImgUploadPromises = processUploadPromises(
        imageData.EXT,
        "exterior_images",
        true
      );
      const inImgUploadPromises = processUploadPromises(
        imageData.INT,
        "interior_images",
        true
      );
      const miscImgUploadPromises = processUploadPromises(
        imageData.MISC,
        "misc_images",
        true
      );
      const threeImgUploadPromises = processUploadPromises(
        imageData[360],
        "three_sixty_images",
        true
      );
      const videoUploadPromises = processUploadPromises(
        videosData,
        "all_videos",
        true
      );
      const fileUploadPromises = processUploadPromises(
        files,
        "title_others",
        false
      );
      const filebUploadPromises = processUploadPromises(
        filesb,
        "bill_sale",
        false
      );

      // Upload media and handle API calls based on isImagesSaved
      try {
        if (!isImagesSaved) {
          await Promise.all([
            ...allImgUploadPromises,
            ...exImgUploadPromises,
            ...inImgUploadPromises,
            ...miscImgUploadPromises,
            ...threeImgUploadPromises,
            ...videoUploadPromises,
          ]);

          const response = await axios.post(
            `${apiUrl}/listing/save_images`,
            imgData,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          );

          if (response.status === 200) {
            setIsImagesSaved(true); // Update flag to prevent saving images again
          }
        }

        await Promise.all([...fileUploadPromises, ...filebUploadPromises]);

        const response = await axios.post(
          `${apiUrl}/listing/add_listing`,
          fnd,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        if (response.status === 200) {
          toast.success("Inventory saved successfully");
          handleClose();
          if (location.state) {
            navigate("/dealer/inventory/active");
          } else {
            navigate("/dealer/inventory/active");
          }

          // Reset form data
          const delay = (ms) =>
            new Promise((resolve) => setTimeout(resolve, ms));

          const resetSequence = async () => {
            try {
              await delay(100);
              handleResetVehicleSpecfication();
              await delay(100);
              handleResetMaintanance();
              await delay(100);
              handleResetSeller();
              await delay(100);
              handleResetTitleInfo();
              await delay(100);
              handleResetFinance();
              await delay(100);
              resetCheckboxes();
            } catch (error) {
              console.error("Error in reset functions:", error);
            }
          };

          resetSequence();
        }
      } catch (error) {
        console.error("Error saving form data:", error);
      } finally {
        setLoading(false);
        setDisabled(false);
      }
    };

    await uploadMedia();

    const handleUploadProgress = (progress, bytes) => {};
  };

  // for draft api-------------------------------------------------
  // const [updatedFormData, setUpdatedFormData] = useState(null);

  // const saveData = async (updatedFormData) => {
  //   console.log("Updated formData in saveData:", updatedFormData);
  //   if (!formData.vin || !formData.userId || !formData.year_make_model) {
  //     return;
  //   }
  //   const fnd = new FormData();
  //   fnd.append("addListing", JSON.stringify(updatedFormData)); // Append updated formData to FormData object

  //   try {
  //     const response = await fetch(`${apiUrl}/listing/add_listing`, {
  //       method: "POST",
  //       body: fnd, // Send FormData directly as the body
  //     });

  //     if (response.ok) {
  //       console.log("Data saved successfully");
  //     } else {
  //       console.error("Failed to save data");
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };

  // const handleSavedraft = () => {
  //   setFormData((prevData) => {
  //     const updatedData = {
  //       ...prevData,
  //       listingStatus: "Drafted",
  //     };
  //     console.log("Updated formData in handleSavedraft:", updatedData); // Log updated data
  //     setUpdatedFormData(updatedData); // Store the updated form data
  //     return updatedData;
  //   });
  // };

  // useEffect(() => {
  //   if (updatedFormData) {
  //     saveData(updatedFormData); // Call saveData with the updated form data
  //     setUpdatedFormData(null); // Reset the updatedFormData after saving
  //   }
  // }, [updatedFormData]); // Watch for changes in updatedFormData

  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     console.log("Current formData before unload:", formData); // Log current formData
  //     handleSavedraft();
  //     // event.preventDefault();
  //     // event.returnValue = '';
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, [formData]); // Make sure to include formData in dependencies

  useEffect(() => {
    console.log("Updated formData:", formData);
  }, [formData]); // Log formData changes
  // For Purchaged-----------------------------------------------------********--------------------
  const handleDropdownChangea = (event) => {
    setDropdownValuea(event.target.value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      purchased_from: event.target.value, // Update purchasedFrom field in formData
    }));
  };

  const handleAddOptiona = () => {
    if (newOptiona.trim() !== "") {
      setDropdownOptionsa([...dropdownOptionsa, newOptiona]);
      setDropdownValuea(newOptiona);
      setFormData((prevFormData) => ({
        ...prevFormData,
        purchased_from: newOptiona, // Update purchasedFrom field in formData
      }));
      setNewOptiona("");
    }
  };
  // For Purchaged-----------------------------------------------------********---------------------
  // fOR Buyer------------------------------------------------------------************--------------
  const handleDropdownChangeb = (event) => {
    // setDropdownValueb(event.target.value);
    setDropdownValueb(event.target.value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      buyer_name: event.target.value, // Update purchasedFrom field in formData
    }));
  };

  const handleAddOptionb = () => {
    if (newOptiona.trim() !== "") {
      setDropdownOptionsb([...dropdownOptionsb, newOptionb]);
      setDropdownValueb(newOptionb);
      setFormData((prevFormData) => ({
        ...prevFormData,
        buyer_name: newOptionb, // Update purchasedFrom field in formData
      }));
      setNewOptionb("");
    }
  };
  // ------------------------------------------for reset the data for tab--------------
  const handleResetSeller = () => {
    setFormData({
      state: "",
      title_status: "",
      odo: "",
      owner: "",
      exterior_damage: "",
      status: "",
      keys_count: "",
      smoked_in: "",
      tyrecondition_bl: "",
      tyrecondition_br: "",
      tyrecondition_fl: "",
      tyrecondition_fr: "",
      tyrecondition_st: "",
      seller_description: "",
    });
    setShowModalj(false); // Close state modal if open
    setShowModalk(false); // Close status modal if open
    setShowModall(false);
    setShowModalm(false);
    setShowModalo(false);
    setShowModalp(false);
    setShowModalg(false);
    setShowModalq(false);
    setShowModalr(false);
    setShowModals(false);
    setShowModalt(false);
    setShowModalu(false);
  };
  const handleResetTitleInfo = () => {
    setFormData({
      state: "",
      title_status: "",
      title_number: "",
      title_recieved: "",
      title_location: "",
    });
    setShowModalj(false); // Close state modal if open
    setShowModalk(false); // Close status modal if open
    setShowModalz(false);
    setShowModalza(false);
  };
  const handleResetFinance = () => {
    setSelectedOptionx("");
    setDisplayValues({
      market_value_price: "",
      purchase_price: "",
      market_value_price: "",
      listing_price: "",
      others: "",
      total_cost: "",
    });
    setShowModalx(false);
    calculateTotal();
    calculateProfit();
  };
  const handleResetMaintanance = () => {
    setInputValueDate("");
    setSelectedOptionv("");
    setSelectedOptionw("");
    setInputValueAmount("");
    setSelectedOptionx("");
    setInputValueDescription("");
  };
  const handleResetVehicleSpecfication = () => {
    setFormData({
      year_make_model: "",
      vin: "",
      trim: "",
      engine: "",
      transmission: "",
      vehicleType: "",
      seat_count: "",
      city_mpg: "",
      highway_mpg: "",
      exteriorColor: "",
      interiorColor: "",
      interiorType: "",
      other_restraint: "",
    });

    setImageData({
      ALL: [],
      INT: [],
      EXT: [],
      MISC: [],
      360: [],
    });

    setDisplayValues({
      mileage_in: "",
      purchase_price: "",
      listing_price: "",
    });
    setShowModal(false);
    setShowModalc(false);
    setShowModalzb(false);
    setShowModald(false);
    setShowModalh(false);
    setShowModali(false);
    setShowModale(false);
  };
  // for new dropdown end------------------------***************--------------------------------------------------------------------------------
  // for if vin not present decode on the basic of year make modal-------------------*************--------------
  const [isVisibleYear, setIsVisibleYear] = useState(false);
  const [isVisibleMake, setIsVisibleMake] = useState(false);
  const [isVisibleModel, setIsVisibleModel] = useState(false);
  const [isVisibleTrim, setIsVisibleTrim] = useState(false);
  const toggleDivYear = () => {
    setIsVisibleYear((prevState) => !prevState);
  };
  const toggleDivMake = () => {
    setIsVisibleMake((prevState) => !prevState);
  };
  const toggleDivModel = () => {
    setIsVisibleModel((prevState) => !prevState);
  };
  const toggleDivTrim = () => {
    setIsVisibleTrim((prevState) => !prevState);
  };
  // ------------------
  const yearData = [];
  for (let year = 1971; year <= 2024; year++) {
    yearData.push(year);
  }
  const makeModelData = [
    { make: "Chevrolet", model: "Silverado", trim: "LT" },
    { make: "Ford", model: "F-150", trim: "XLT" },
    { make: "Honda", model: "Civic", trim: "EX" },
    { make: "Toyota", model: "Camry", trim: "LE" },
    { make: "Nissan", model: "Altima", trim: "SR" },
    { make: "BMW", model: "3 Series", trim: "330i" },
    { make: "Audi", model: "A4", trim: "Premium" },
    { make: "Mercedes-Benz", model: "C-Class", trim: "C300" },
    { make: "Hyundai", model: "Elantra", trim: "SEL" },
    { make: "Kia", model: "Optima", trim: "SX" },
    { make: "Volkswagen", model: "Jetta", trim: "GLI" },
    { make: "Subaru", model: "Outback", trim: "Premium" },
    { make: "Chrysler", model: "300", trim: "C" },
    { make: "Dodge", model: "Ram", trim: "Laramie" },
    { make: "Jeep", model: "Wrangler", trim: "Sahara" },
    { make: "Mazda", model: "CX-5", trim: "Touring" },
    { make: "Buick", model: "Enclave", trim: "Avenir" },
    { make: "GMC", model: "Sierra", trim: "SLT" },
    { make: "Porsche", model: "911", trim: "Carrera" },
    { make: "Tesla", model: "Model 3", trim: "Long Range" },
    { make: "Lexus", model: "ES", trim: "300h" },
    { make: "Land Rover", model: "Range Rover", trim: "Autobiography" },
    { make: "Volvo", model: "XC90", trim: "Inscription" },
    { make: "Infiniti", model: "Q50", trim: "Red Sport" },
    { make: "Jaguar", model: "F-PACE", trim: "R-Sport" },
    { make: "Mitsubishi", model: "Outlander", trim: "SE" },
    { make: "Lincoln", model: "Navigator", trim: "Black Label" },
    { make: "Acura", model: "MDX", trim: "Technology" },
    { make: "Alfa Romeo", model: "Giulia", trim: "Ti" },
    { make: "Fiat", model: "500", trim: "Lounge" },
    { make: "Mini", model: "Cooper", trim: "S" },
    { make: "Smart", model: "Fortwo", trim: "Passion" },
    { make: "Maserati", model: "Ghibli", trim: "S Q4" },
    { make: "Bentley", model: "Continental GT", trim: "V8" },
    { make: "Rolls-Royce", model: "Phantom", trim: "Series II" },
    { make: "Aston Martin", model: "DB11", trim: "V8" },
    { make: "Pagani", model: "Huayra", trim: "BC" },
    { make: "Koenigsegg", model: "Agera", trim: "RS" },
    { make: "Ferrari", model: "488", trim: "GTB" },
    { make: "Lamborghini", model: "Huracán", trim: "Evo" },
    { make: "Bugatti", model: "Chiron", trim: "Super Sport" },
    { make: "Peugeot", model: "508", trim: "GT" },
    { make: "Renault", model: "Clio", trim: "RS" },
    { make: "Citroën", model: "C3", trim: "Shine" },
    { make: "Seat", model: "Leon", trim: "FR" },
    { make: "Skoda", model: "Octavia", trim: "RS" },
    { make: "Opel", model: "Astra", trim: "GS Line" },
    { make: "Ford", model: "Escape", trim: "Titanium" },
    { make: "Chevrolet", model: "Equinox", trim: "Premier" },
    { make: "Honda", model: "CR-V", trim: "EX-L" },
    { make: "Toyota", model: "RAV4", trim: "XLE" },
    { make: "Nissan", model: "Rogue", trim: "SV" },
  ];

  const [showModalNoVin, setShowModalNoVin] = useState(false);

  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMake, setSelectedMake] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedTrim, setSelectedTrim] = useState(null);

  const [yearSearch, setYearSearch] = useState("");
  const [makeSearch, setMakeSearch] = useState("");
  const [modelSearch, setModelSearch] = useState("");
  const [trimSearch, setTrimSearch] = useState("");

  const handleClickNoVin = () => {
    setShowModalNoVin(true);
    dispatch(saveUser({ isSideBarOpen: false }));
  };

  const handleCloseModalNoVin = () => {
    setShowModalNoVin(false);
  };
  const haldleYearSet = (year) => {
    setSelectedYear(year);
    setIsVisibleYear(false);
  };
  const haldleMakeSet = (make) => {
    setSelectedMake(make);
    setIsVisibleMake(false);
  };
  const haldleModelSet = (model) => {
    setSelectedModel(model);
    setIsVisibleModel(false);
  };
  const haldleTrimSet = (trim) => {
    setSelectedTrim(trim);
    setIsVisibleTrim(false);
  };
  const handleSubmitNoVin = () => {
    console.log("Selected year make model:", {
      selectedYear,
      selectedMake,
      selectedModel,
      selectedTrim,
    });
    setFormData((prevFormData) => ({
      ...prevFormData,
      year_make_model:
        `${selectedYear} ${selectedMake} ${selectedModel} ${selectedTrim}` ||
        "",
    }));
    handleCloseModalNoVin(); // Close the modal after submitting
  };
  // ---------------------------------------------------------------------------
  return (
    <>
      {/* --------------------------------------------------------------------------- */}
      <Modal
        show={show}
        size="xl"
        onHide={() => handleClose()}
        dialogClassName="modal-90w"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>ADD INVENTORY</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className=""
            style={{
              padding: "0px",
              marginTop: "-23px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div className="container-fluid">
              <form onSubmit={handleSubmit}>
                <div>
                  <div style={{ display: "inline-block", float: "right" }}>
                    <div className="loading me-5">
                      {loading && !isImagesSaved ? (
                        <span className="me-2">
                          Uploaded {uploadedCount} of {totalCount}
                        </span>
                      ) : null}
                      {loading && !isImagesSaved ? `${percentage}%` : null}
                    </div>

                    <button
                      type="submit"
                      id="btn-save"
                      className="btn-change4"
                      disabled={disabled}
                    >
                      {btnTxt ? btnTxt : "Save"}
                      {/* {loading ? `${percentage}%` : null} */}
                      {/* {loading ? <img src={Image.whiteReset} alt="Loader" className={`${loading ? 'loader' : ''}`} /> : null} */}
                      {/* <img src={Image.whiteReset} alt="Loader" className={`px-3 py-1 h-100 w-50 loader`} /> */}
                    </button>
                  </div>
                  <Tabs
                    activeKey={activeTab}
                    onSelect={(k) => setActiveTab(k)}
                    id="my-tabs"
                    className="mt-4 "
                  >
                    <Tab eventKey="tab1" title="Vehicle Specification">
                      <Container
                        className="vehiicle_specification_parent"
                        style={{ width: "70vw" }}
                      >
                        <Row>
                          <Col sm={6} className="vehi-spe-cols">
                            <Container>
                              <Row className="vehicle_specification_left_child">
                                <div className="col-md-12 d-flex">
                                  <div
                                    className="input-group"
                                    id="vinadlst"
                                    style={{ width: "92%" }}
                                  >
                                    <button
                                      className="btn"
                                      type="button"
                                      id="vin-left-btn"
                                    >
                                      VIN
                                    </button>
                                    <input
                                      type="text"
                                      placeholder="Please Enter 17 character.."
                                      className="form-control border-0"
                                      name="vin"
                                      value={formData.vin}
                                      onChange={handleChangedecode}
                                      maxLength={17}
                                      onKeyDown={handleKeyPress} // Add this line
                                      style={{ textTransform: "uppercase" }}
                                    />
                                  </div>
                                  <button
                                    className="btn"
                                    type="button"
                                    id="adlst-no-vin"
                                    onClick={handleClickNoVin}
                                  >
                                    Havn't VIN
                                  </button>
                                  {/* modal for year make modal */}
                                  <Modal
                                    show={showModalNoVin}
                                    onHide={handleCloseModalNoVin}
                                    size="xl"
                                  >
                                    <Modal.Header closeButton>
                                      <Modal.Title>
                                        Select Your Year Make Modal And Trim
                                      </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        height: "88%",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <Button
                                          type="button"
                                          onClick={toggleDivYear}
                                          style={{ width: "15rem" }}
                                        >
                                          {selectedYear ? selectedYear : "Year"}
                                        </Button>
                                        {isVisibleYear && (
                                          <div
                                            style={{
                                              overflowY: "scroll",
                                              height: "400px",
                                              width: "100%",
                                              padding: "0 10px",
                                            }}
                                          >
                                            <Form.Control
                                              type="text"
                                              placeholder="Search Year"
                                              value={yearSearch}
                                              onChange={(e) =>
                                                setYearSearch(e.target.value)
                                              }
                                              style={{ marginBottom: "10px" }}
                                            />
                                            <Table striped bordered hover>
                                              <tbody>
                                                {yearData
                                                  .filter((year) =>
                                                    year
                                                      .toString()
                                                      .includes(yearSearch)
                                                  )
                                                  .map((year) => (
                                                    <tr
                                                      key={year}
                                                      onClick={() =>
                                                        haldleYearSet(year)
                                                      }
                                                    >
                                                      <td>{year}</td>
                                                    </tr>
                                                  ))}
                                              </tbody>
                                            </Table>
                                          </div>
                                        )}
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <Button
                                          type="button"
                                          onClick={toggleDivMake}
                                          style={{ width: "15rem" }}
                                        >
                                          {selectedMake ? selectedMake : "Make"}
                                        </Button>
                                        {isVisibleMake && (
                                          <div
                                            style={{
                                              overflowY: "scroll",
                                              height: "400px",
                                              width: "100%",
                                              padding: "0 10px",
                                            }}
                                          >
                                            <Form.Control
                                              type="text"
                                              placeholder="Search Make"
                                              value={makeSearch}
                                              onChange={(e) =>
                                                setMakeSearch(e.target.value)
                                              }
                                              style={{ marginBottom: "10px" }}
                                            />
                                            <Table striped bordered hover>
                                              <tbody>
                                                {makeModelData
                                                  .filter((data) =>
                                                    data.make
                                                      .toLowerCase()
                                                      .includes(
                                                        makeSearch.toLowerCase()
                                                      )
                                                  )
                                                  .map((data, index) => (
                                                    <tr
                                                      key={index}
                                                      onClick={() => {
                                                        haldleMakeSet(
                                                          data.make
                                                        );
                                                        // setSelectedMake(data.make);
                                                        // setSelectedModel(data.model);
                                                      }}
                                                    >
                                                      <td>{data.make}</td>
                                                    </tr>
                                                  ))}
                                              </tbody>
                                            </Table>
                                          </div>
                                        )}
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <Button
                                          type="button"
                                          onClick={toggleDivModel}
                                          style={{ width: "15rem" }}
                                        >
                                          {selectedModel
                                            ? selectedModel
                                            : "Model"}
                                        </Button>
                                        {isVisibleModel && (
                                          <div
                                            style={{
                                              overflowY: "scroll",
                                              height: "400px",
                                              width: "100%",
                                              padding: "0 10px",
                                            }}
                                          >
                                            <Form.Control
                                              type="text"
                                              placeholder="Search Model"
                                              value={modelSearch}
                                              onChange={(e) =>
                                                setModelSearch(e.target.value)
                                              }
                                              style={{ marginBottom: "10px" }}
                                            />
                                            <Table striped bordered hover>
                                              <tbody>
                                                {makeModelData
                                                  .filter((data) =>
                                                    data.model
                                                      .toLowerCase()
                                                      .includes(
                                                        modelSearch.toLowerCase()
                                                      )
                                                  )
                                                  .map((data, index) => (
                                                    <tr
                                                      key={index}
                                                      onClick={() => {
                                                        haldleModelSet(
                                                          data.model
                                                        );
                                                        // setSelectedMake(data.make);
                                                        // setSelectedModel(data.model);
                                                      }}
                                                    >
                                                      <td>{data.model}</td>
                                                    </tr>
                                                  ))}
                                              </tbody>
                                            </Table>
                                          </div>
                                        )}
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <Button
                                          type="button"
                                          onClick={toggleDivTrim}
                                          style={{ width: "15rem" }}
                                        >
                                          {selectedTrim ? selectedTrim : "Trim"}
                                        </Button>
                                        {isVisibleTrim && (
                                          <div
                                            style={{
                                              overflowY: "scroll",
                                              height: "400px",
                                              width: "100%",
                                              padding: "0 10px",
                                            }}
                                          >
                                            <Form.Control
                                              type="text"
                                              placeholder="Search Model"
                                              value={trimSearch}
                                              onChange={(e) =>
                                                setTrimSearch(e.target.value)
                                              }
                                              style={{ marginBottom: "10px" }}
                                            />
                                            <Table striped bordered hover>
                                              <tbody>
                                                {makeModelData
                                                  .filter((data) =>
                                                    data.trim
                                                      .toLowerCase()
                                                      .includes(
                                                        trimSearch.toLowerCase()
                                                      )
                                                  )
                                                  .map((data, index) => (
                                                    <tr
                                                      key={index}
                                                      onClick={() => {
                                                        haldleTrimSet(
                                                          data.trim
                                                        );
                                                        // setSelectedMake(data.make);
                                                        // setSelectedModel(data.model);
                                                      }}
                                                    >
                                                      <td>{data.trim}</td>
                                                    </tr>
                                                  ))}
                                              </tbody>
                                            </Table>
                                          </div>
                                        )}
                                      </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                      <Button
                                        variant="secondary"
                                        onClick={handleCloseModalNoVin}
                                      >
                                        Close
                                      </Button>
                                      <Button
                                        variant="primary"
                                        onClick={handleSubmitNoVin}
                                        disabled={
                                          !selectedYear ||
                                          !selectedMake ||
                                          !selectedModel
                                        }
                                      >
                                        Submit
                                      </Button>
                                    </Modal.Footer>
                                  </Modal>
                                  {/* modal for year make modal */}
                                  <button
                                    type="button"
                                    id="adlst-reset-btn"
                                    onClick={handleResetVehicleSpecfication}
                                    title="Reset"
                                  >
                                    <img
                                      src={Image.reset_button}
                                      alt="Reset Button"
                                    />
                                  </button>
                                </div>
                                {errorMessage && (
                                  <p style={{ color: "red" }}>{errorMessage}</p>
                                )}
                                {alertVisible && (
                                  <div className="col-md-12 popup-vin mt-2">
                                    {messageDecode === 1 && (
                                      <p style={{ color: "red" }}>
                                        Already exists want to edit?
                                      </p>
                                    )}
                                    {messageDecode === 2 && (
                                      <p style={{ color: "red" }}>
                                        Already sold want to active again.
                                      </p>
                                    )}

                                    <button
                                      className="btn btn-secondary"
                                      onClick={handleCancel}
                                      style={{ marginLeft: "25px" }}
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      className="btn btn-danger mr-2"
                                      onClick={handleRetry}
                                      style={{ marginLeft: "25px" }}
                                    >
                                      Yes
                                    </button>
                                  </div>
                                )}
                                <div
                                  className="col-md-6"
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                ></div>
                                <div className="col-md-12 mt-4">
                                  {/* <label >Year/Make/Model/series/Wheel Base/Body Class</label> */}
                                  <div className="input-field-year-make bg-input">
                                    <input
                                      type="text"
                                      name="year_make_model"
                                      value={formData.year_make_model}
                                      onChange={handleChange}
                                      onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                          e.preventDefault();
                                        }
                                      }}
                                      id="inputadlst"
                                      style={{
                                        fontSize: "15",
                                        width: "98%",
                                        fontWeight: "bolder",
                                        letterSpacing: "2px",
                                        backgroundColor:
                                          emptyFields.year_make_model
                                            ? "#e7bd26"
                                            : "rgb(240 150 31 / 22%)",
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="col-md-12 mt-1">
                                  <lable>Trim</lable>
                                  <div className="input-field-year-make bg-input">
                                    {/* <div className="persistent-placeholder">Trim:</div> */}
                                    <input
                                      type="text"
                                      name="trim"
                                      value={formData.trim}
                                      onChange={handleChange}
                                      onClick={handleArrowClick}
                                      onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                          e.preventDefault();
                                        }
                                      }}
                                      id="inputadlst"
                                      style={{
                                        // paddingLeft: "20%",
                                        fontSize: "15",
                                        width: "98%",
                                        fontWeight: "bolder",
                                        letterSpacing: "2px",
                                        backgroundColor:
                                          "rgb(240 150 31 / 22%)",
                                      }}
                                    />
                                    <div className="arrow-adlst">
                                      <FaArrowRight
                                        onClick={handleArrowClick}
                                      />
                                    </div>
                                  </div>
                                  <Modal
                                    show={showModal}
                                    onHide={handleCloseModal}
                                  >
                                    <Modal.Header closeButton>
                                      <Modal.Title>
                                        Choose a trim package
                                      </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body style={{ height: "88%" }}>
                                      <PopDataTrim
                                        radioOptions={radioOptions}
                                        onStateSelect={handleOptionSelect}
                                      />
                                    </Modal.Body>
                                    <Modal.Footer></Modal.Footer>
                                  </Modal>
                                </div>
                                <div className="col-md-12 mt-1">
                                  <lable>Engine</lable>
                                  <div className="input-field-year-make bg-input">
                                    <input
                                      type="text"
                                      name="engine"
                                      value={formData.engine}
                                      onChange={handleChange}
                                      onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                          e.preventDefault();
                                        }
                                      }}
                                      id="inputadlst"
                                      style={{
                                        // paddingLeft: "20%",
                                        fontSize: "15",
                                        width: "98%",
                                        fontWeight: "bolder",
                                        letterSpacing: "2px",
                                        backgroundColor:
                                          "rgb(240 150 31 / 22%)",
                                      }}
                                    />
                                  </div>
                                </div>
                                <div
                                  className="col-md-12 mt-1"
                                  style={{
                                    padding: "0",
                                    paddingLeft: "1.5%",
                                  }}
                                >
                                  <lable>Transmission</lable>
                                  <div className="input-field-year-make bg-input">
                                    <input
                                      type="text"
                                      name="transmission"
                                      value={formData.transmission}
                                      onChange={handleChange}
                                      onClick={
                                        !apiData?.TransmissionStyle
                                          ? handleArrowClickc
                                          : undefined
                                      }
                                      onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                          e.preventDefault();
                                        }
                                      }}
                                      id="inputadlst"
                                      style={{
                                        // paddingLeft: "20%",
                                        fontSize: "15",
                                        width: isTransmissionPresent
                                          ? "97%"
                                          : "97%",
                                        fontWeight: "bolder",
                                        letterSpacing: "2px",
                                        backgroundColor:
                                          "rgb(240 150 31 / 22%)",
                                        // border : `${!isTransmissionPresent ? "2px solid red" : "none"}`
                                      }}
                                    />
                                    {!apiData?.TransmissionStyle && (
                                      <div
                                        className="arrow-adlst"
                                        style={{
                                          position: "absolute",
                                          right: "3%",
                                        }}
                                      >
                                        <FaArrowRight
                                          onClick={handleArrowClickc}
                                        />
                                      </div>
                                    )}
                                  </div>
                                  <Modal
                                    show={showModalc}
                                    onHide={handleCloseModalc}
                                  >
                                    <Modal.Header closeButton>
                                      <Modal.Title>
                                        Choose a Transmission package
                                      </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body style={{ height: "88%" }}>
                                      <PopupData
                                        radioOptions={radioOptionsc}
                                        onStateSelect={handleOptionSelectc}
                                      />
                                    </Modal.Body>
                                    <Modal.Footer></Modal.Footer>
                                  </Modal>
                                </div>
                                <div
                                  className="col-md-12 mt-1"
                                  style={{
                                    // width: "50%",
                                    paddingLeft: "11px",
                                    paddingRight: "0%",
                                  }}
                                >
                                  <lable> Vehicle Type</lable>
                                  <div className="input-field-year-make bg-input">
                                    {/* <div className="persistent-placeholder">
                                  Vehicle Type
                                </div> */}
                                    <input
                                      type="text"
                                      name="vehicleType"
                                      value={formData.vehicleType}
                                      onChange={handleChange}
                                      onClick={handleArrowClickzb}
                                      onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                          e.preventDefault();
                                        }
                                      }}
                                      id="inputadlst"
                                      style={{
                                        // paddingLeft: "20%",
                                        fontSize: "15",
                                        width: "96%",
                                        fontWeight: "bolder",
                                        letterSpacing: "2px",
                                        backgroundColor:
                                          "rgb(240 150 31 / 22%)",
                                      }}
                                    />
                                    <div
                                      className="arrow-adlst"
                                      style={{
                                        position: "absolute",
                                        right: "3%",
                                      }}
                                    >
                                      <FaArrowRight
                                        onClick={handleArrowClickzb}
                                      />
                                    </div>
                                  </div>
                                  <Modal
                                    show={showModalzb}
                                    onHide={handleCloseModalzb}
                                  >
                                    <Modal.Header closeButton>
                                      <Modal.Title>
                                        Choose a Vehicle Type
                                      </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body style={{ height: "88%" }}>
                                      <PopupData
                                        radioOptions={radioOptionszb}
                                        onStateSelect={handleOptionSelectzb}
                                      />
                                    </Modal.Body>
                                    <Modal.Footer
                                      style={{
                                        justifyContent: "space-between",
                                      }}
                                    ></Modal.Footer>
                                  </Modal>
                                </div>

                                <div className="col-md-4 mt-1">
                                  <lable>Seats</lable>
                                  <div className="input-field-year-make bg-input">
                                    <input
                                      type="text"
                                      name="seat_count"
                                      value={formData.seat_count}
                                      onChange={handleChange}
                                      onClick={handleArrowClickd}
                                      onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                          e.preventDefault();
                                        }
                                      }}
                                      id="inputadlst"
                                      style={{
                                        // paddingLeft: "50%",
                                        fontSize: "15",
                                        width: "90%",
                                        fontWeight: "bolder",
                                        letterSpacing: "2px",
                                        backgroundColor:
                                          "rgb(240 150 31 / 22%)",
                                      }}
                                    />
                                    {!isSeatsPresent && (
                                      <div className="arrow-adlst">
                                        <FaArrowRight
                                          onClick={handleArrowClickd}
                                        />
                                      </div>
                                    )}
                                  </div>
                                  <Modal
                                    show={showModald}
                                    onHide={handleCloseModald}
                                  >
                                    <Modal.Header closeButton>
                                      <Modal.Title>
                                        Choose a seat count package
                                      </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body style={{ height: "88%" }}>
                                      <PopupData
                                        radioOptions={radioOptionsd}
                                        onStateSelect={handleOptionSelectd}
                                      />
                                    </Modal.Body>
                                    <Modal.Footer
                                      style={{
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <input
                                        type="text"
                                        placeholder="Add Your Seats"
                                        value={newOptionseats}
                                        onChange={(e) =>
                                          setNewOptionseats(e.target.value)
                                        }
                                        onKeyPress={(e) => {
                                          if (e.key === "Enter") {
                                            handleAddOptionseats();
                                          }
                                        }}
                                        style={{
                                          marginRight: "1rem",
                                          width: "42%",
                                        }}
                                      />
                                      {/* <button onClick={handleAddOptionsupplier}>Add</button> */}
                                    </Modal.Footer>
                                  </Modal>
                                </div>
                                <div className="col-md-4 mt-1">
                                  <lable>City MPG</lable>
                                  <div
                                    className="input-field-year-make d-flex"
                                    style={{
                                      // border:"2px solid red",
                                      padding: "0",
                                      width: "100%",
                                    }}
                                  >
                                    <div
                                      className="input-fields bg-input"
                                      style={{
                                        width: "91%",
                                        display: "flex",
                                        flex: 1,
                                      }}
                                    >
                                      <input
                                        type="text"
                                        name="city_mpg"
                                        value={formData.city_mpg}
                                        onChange={handleChange}
                                        onKeyPress={(e) => {
                                          if (e.key === "Enter") {
                                            e.preventDefault();
                                          }
                                        }}
                                        id="inputadlst"
                                        style={{
                                          width: "100%",
                                          flex: "1",
                                          // paddingLeft: "45%",
                                          backgroundColor:
                                            "rgb(240 150 31 / 22%)",
                                          fontSize: "15",
                                          fontWeight: "bolder",
                                          letterSpacing: "2px",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 mt-1">
                                  <lable>Highway MPG</lable>
                                  <div
                                    className="px-0 input-field-year-make"
                                    style={{ width: "100%" }}
                                  >
                                    <div
                                      className="input-fields bg-input d-flex"
                                      style={{ width: "100%" }}
                                    >
                                      <input
                                        type="text"
                                        name="highway_mpg"
                                        value={formData.highway_mpg}
                                        onChange={handleChange}
                                        onKeyPress={(e) => {
                                          if (e.key === "Enter") {
                                            e.preventDefault();
                                          }
                                        }}
                                        id="inputadlst"
                                        style={{
                                          width: "100%",
                                          flex: "1",
                                          // paddingLeft: "45%",
                                          fontSize: "15",
                                          fontWeight: "bolder",
                                          letterSpacing: "2px",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex"></div>

                                <div className="col-md-6 mt-1">
                                  <div>
                                    <label>
                                      <img
                                        src={Image.mileage}
                                        style={{
                                          width: "25px",
                                          height: "25px",
                                        }}
                                      />
                                      Mileage
                                    </label>
                                    <div style={{ display: "flex" }}>
                                      <div
                                        className="input-fields border-0 shadow"
                                        style={{
                                          padding: "0px",
                                          width: "100%",
                                          height: "38px",
                                          border: "2px solid red",
                                          backgroundColor: "#eeb989",
                                        }}
                                      >
                                        <input
                                          type="text"
                                          autoComplete="off"
                                          name="mileage_in"
                                          placeholder="0"
                                          value={displayValues.mileage_in}
                                          onChange={handleChangeb}
                                          onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                              e.preventDefault();
                                            }
                                          }}
                                          id="milageinread"
                                        />
                                      </div>
                                      <div className="arrow-adlst-cal">
                                        <FaCalculator
                                          onClick={
                                            handleArrowClickCalculatorMileage
                                          }
                                          style={{ cursor: "pointer" }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <Modal
                                    show={showModalCalculatorMileage}
                                    onHide={handleCloseModalCalculatorMileage}
                                  >
                                    <Modal.Header closeButton style={{backgroundColor:"#d9d9d9"}}>
                                      <Modal.Title>calculator</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body style={{ height: "88%",backgroundColor:"#d9d9d9" }}>
                                      <AmountInputWithCalculator
                                        setShowModalCalculator={
                                          setShowModalCalculatorMileage
                                        }
                                        setDisplayValues={setDisplayValues}
                                        displayValues={displayValues}
                                        setFormData={setFormData}
                                        statusShowCal={4}
                                      />
                                    </Modal.Body>
                                    <Modal.Footer
                                      style={{
                                        justifyContent: "space-between",backgroundColor:"#d9d9d9"
                                      }}
                                    ></Modal.Footer>
                                  </Modal>
                                </div>

                                <Col sm={12} className="mt-1">
                                  <Row className="d-flex justify-content-between">
                                    <Col sm={6}>
                                      <div>Purchased Price</div>
                                      <div style={{ display: "flex" }}>
                                        <div className="inputst input-containerdlr w-100">
                                          <div className="persistent-placeholder">
                                            $
                                          </div>
                                          <div
                                            style={{
                                              position: "relative",
                                              width: "100%",
                                            }}
                                          >
                                            <input
                                              type={
                                                isPasswordVisible
                                                  ? "text"
                                                  : "text"
                                              }
                                              name="purchase_price"
                                              value={
                                                displayValues.purchase_price
                                              }
                                              id="purpriceselprice"
                                              className="placholder_color"
                                              placeholder="0"
                                              onChange={handleChangeb}
                                              onKeyPress={(e) => {
                                                if (e.key === "Enter") {
                                                  e.preventDefault();
                                                }
                                              }}
                                              style={{
                                                fontSize: "15px",
                                                fontWeight: "bolder",
                                                letterSpacing: "2px",
                                                width: "100%",
                                                position: "relative",
                                                zIndex: 2,
                                                backgroundColor: "transparent",
                                                paddingRight: "30px",
                                                marginTop: "0px",
                                                marginBottom: "0px",
                                              }}
                                              ref={inputRef}
                                            />
                                            {!isPasswordVisible && (
                                              <div
                                                style={{
                                                  position: "absolute",
                                                  top: "0px",
                                                  left: "21px",
                                                  maxWidth: "78%",
                                                  height: "100%",
                                                  lineHeight: "38px", // Adjust based on input height
                                                  fontSize: "15px",
                                                  fontWeight: "bolder",
                                                  letterSpacing: "2px",
                                                  color: "#000",
                                                  backgroundColor: "#eeb989",
                                                  zIndex: 2,
                                                  pointerEvents: "none",
                                                  // paddingRight: "30px",
                                                  // marginLeft:"20px",
                                                  borderRadius: "5px",
                                                  overflowX: "hidden",
                                                  // paddingRight: "30px",
                                                }}
                                              >
                                                {/* {getMaskedValue(formData.purchase_price)} */}
                                                {getMaskedValue(
                                                  displayValues.purchase_price
                                                )}
                                              </div>
                                            )}
                                            <span
                                              style={{
                                                position: "absolute",
                                                right: 0,
                                                top: "50%",
                                                transform: "translateY(-50%)",
                                                marginLeft: "-36px",
                                                cursor: "pointer",
                                                zIndex: 3,
                                              }}
                                              onMouseDown={handleMouseDown}
                                              onMouseUp={handleMouseUp}
                                              onMouseLeave={handleMouseUp}
                                            >
                                              {isPasswordVisible ? (
                                                <MdOutlineRemoveRedEye
                                                  style={{
                                                    width: "25px",
                                                    height: "25px",
                                                  }}
                                                />
                                              ) : (
                                                <MdOutlineVisibilityOff
                                                  style={{
                                                    width: "25px",
                                                    height: "25px",
                                                    marginRight: "12px",
                                                  }}
                                                />
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="arrow-adlst-cal">
                                          <FaCalculator
                                            onClick={
                                              handleArrowClickCalculatorPurchase
                                            }
                                            style={{ cursor: "pointer" }}
                                          />
                                        </div>
                                      </div>
                                      <Modal
                                        show={showModalCalculatorPurchase}
                                        onHide={
                                          handleCloseModalCalculatorPurchase
                                        }
                                      >
                                        <Modal.Header closeButton style={{backgroundColor:"#d9d9d9"}}>
                                          <Modal.Title>calculator</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body style={{ height: "88%",backgroundColor:"#d9d9d9" }}>
                                          <AmountInputWithCalculator
                                            setShowModalCalculator={
                                              setShowModalCalculatorPurchase
                                            }
                                            setDisplayValues={setDisplayValues}
                                            displayValues={displayValues}
                                            setFormData={setFormData}
                                            statusShowCal={3}
                                          />
                                        </Modal.Body>
                                        <Modal.Footer
                                          style={{
                                            justifyContent: "space-between",backgroundColor:"#d9d9d9"
                                          }}
                                        ></Modal.Footer>
                                      </Modal>
                                    </Col>

                                    <Col sm={6}>
                                      <div>Listing/Selling Price</div>
                                      <div style={{ display: "flex" }}>
                                        <div className="input-containerdlr inputst input-containerdlr w-100">
                                          <div className="persistent-placeholder">
                                            $
                                          </div>
                                          <input
                                            type="text"
                                            name="listing_price"
                                            value={displayValues.listing_price}
                                            id="purpriceselpriceb"
                                            className="placholder_color"
                                            placeholder="0"
                                            onChange={handleChangeb}
                                            onKeyPress={(e) => {
                                              if (e.key === "Enter") {
                                                e.preventDefault();
                                              }
                                            }}
                                            style={{
                                              fontSize: "15",
                                              fontWeight: "bolder",
                                              letterSpacing: "2px",
                                              marginTop: "0px",
                                              marginBottom: "0px",
                                            }}
                                          />
                                        </div>
                                        <div className="arrow-adlst-cal">
                                          <FaCalculator
                                            onClick={
                                              handleArrowClickCalculatorListing
                                            }
                                            style={{ cursor: "pointer" }}
                                          />
                                        </div>
                                      </div>
                                      <Modal
                                        show={showModalCalculatorListing}
                                        onHide={
                                          handleCloseModalCalculatorListing
                                        }
                                      >
                                        <Modal.Header closeButton style={{backgroundColor:"#d9d9d9"}}>
                                          <Modal.Title>calculator</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body style={{ height: "88%",backgroundColor:"#d9d9d9" }}>
                                          <AmountInputWithCalculator
                                            setShowModalCalculator={
                                              setShowModalCalculatorListing
                                            }
                                            setDisplayValues={setDisplayValues}
                                            displayValues={displayValues}
                                            setFormData={setFormData}
                                            statusShowCal={2}
                                          />
                                        </Modal.Body>
                                        <Modal.Footer
                                          style={{
                                            justifyContent: "space-between",backgroundColor:"#d9d9d9"
                                          }}
                                        ></Modal.Footer>
                                      </Modal>
                                    </Col>
                                  </Row>
                                </Col>

                                <Col sm={12} className="mt-1">
                                  <Row className="d-flex justify-content-between">
                                    <Col
                                      sm={6}
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        padding: "0 1%",
                                      }}
                                    >
                                      <label>Purchased Date</label>
                                      <div
                                        className="input-fieldsins bg-inputdate"
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          width: "98.5%",
                                          height: "38px",
                                        }}
                                      >
                                        <input
                                          type="date"
                                          name="purchasedDate"
                                          value={formData.purchasedDate}
                                          onChange={handleChangepurchaseDate}
                                          style={{
                                            width: "55%",
                                            fontSize: "15",
                                            padding: "11px",
                                            fontWeight: "bolder",
                                            letterSpacing: "2px",
                                            backgroundColor:
                                              "rgb(241 228 211 / 22%)",
                                          }}
                                        />
                                        <div
                                          className="vl bg-inputdate"
                                          style={{ height: "30px" }}
                                        ></div>
                                        <span
                                          id="insdays"
                                          style={{
                                            fontSize: "15",
                                            fontWeight: "bolder",
                                            letterSpacing: "2px",
                                            textAlign: "center",
                                            cursor: "pointer",
                                          }}
                                          title="Days"
                                        >
                                          {calculateDaysDifferencepurchase()}
                                        </span>
                                      </div>
                                    </Col>
                                    <Col
                                      sm={6}
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        padding: "0 1%",
                                      }}
                                    >
                                      <label>Inspection</label>
                                      <div
                                        className="input-fieldsins bg-inputdate"
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          height: "38px",
                                        }}
                                      >
                                        <input
                                          type="date"
                                          name="inspection"
                                          value={formData.inspection}
                                          onChange={handleChangeins}
                                          style={{
                                            width: "55%",
                                            fontSize: "15",
                                            padding: "11px",
                                            fontWeight: "bolder",
                                            letterSpacing: "2px",
                                            backgroundColor:
                                              "rgb(229 214 194 / 22%)",
                                            height: "38px",
                                          }}
                                        />
                                        <div
                                          className="vl bg-inputdate"
                                          style={{ height: "30px" }}
                                        ></div>
                                        <span
                                          id="insdays"
                                          style={{
                                            fontSize: "15",
                                            fontWeight: "bolder",
                                            letterSpacing: "2px",
                                            textAlign: "center",
                                            cursor: "pointer",
                                          }}
                                          title="Days"
                                        >
                                          {calculateDaysDifference()}
                                        </span>
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Container>
                          </Col>
                          {/* -------------------one input filed End------------------------- */}
                          {/* -------------------two input filed Start------------------------- */}
                          <Col sm={6} className="vehi-spe-cols">
                            <Container className="px-0">
                              <Row
                                className={`${
                                  imagesToDisplay.length >= 2
                                    ? "vehicle_specification_right_double px-0"
                                    : "vehicle_specification_right_child px-0"
                                }`}
                              >
                                {/*------------------------------------------------------------------- ----------------------------------------- For Images--------------------------------- */}
                                <div
                                  className={`col-md-12 main-img-con  ${
                                    isDragging ? "dragging" : ""
                                  }`}
                                  style={{
                                    marginTop: "0px",
                                    marginLeft: "0px",
                                  }}
                                  onDragOver={handleDragOver}
                                  onDrop={(e) => handleDrop(e, "ALL")}
                                >
                                  <div
                                    className="selected-image-container mx-auto"
                                    style={{
                                      maxWidth: "100%",
                                      minWidth: "100%",
                                      height: "445px",
                                    }}
                                  >
                                    {imagesToDisplay.length > 0 && (
                                      <div
                                        className="selected-image-container"
                                        style={{
                                          maxWidth: "100%",
                                          minWidth: "100%",
                                          height: "100%",
                                          display: "flex",
                                        }}
                                      >
                                        <div
                                          style={{
                                            maxWidth: "100%",
                                            minWidth: "100%",
                                            height: "100%",
                                          }}
                                        >
                                          <Slider
                                            ref={(slider) =>
                                              (sliderRef = slider)
                                            }
                                            {...settings}
                                          >
                                            {imagesToDisplay.map(
                                              (image, index) => (
                                                <div
                                                  key={index}
                                                  style={{
                                                    position: "relative",
                                                  }}
                                                >
                                                  <div
                                                    className=""
                                                    style={{
                                                      minWidth: "100%",
                                                      maxWidth: "100%",
                                                      height: "445px",
                                                      position: "relative",
                                                    }}
                                                  >
                                                    {/* delete button */}
                                                    {imagesToDisplay.length >
                                                      0 && (
                                                      <OverlayTrigger
                                                        placement="bottom-end"
                                                        overlay={
                                                          <Tooltip
                                                            id={`tooltip-video`}
                                                            className="addlist-tooltip"
                                                          >
                                                            Delete
                                                          </Tooltip>
                                                        }
                                                      >
                                                        <span
                                                          style={{
                                                            position:
                                                              "absolute",
                                                            top: "5px",
                                                            right: ".5rem",
                                                            color: "white",
                                                            fontSize: "x-large",
                                                            backgroundColor:
                                                              "rgba(0, 0, 0, 0.5)",
                                                            borderRadius: "50%",
                                                            padding: "0.2rem",
                                                            display: "grid",
                                                            placeItems:
                                                              "center",
                                                            zIndex: "2",
                                                            cursor: "pointer",
                                                          }}
                                                        >
                                                          <MdDelete
                                                            onClick={() => {
                                                              if (
                                                                typeof image ===
                                                                  "string" &&
                                                                image.includes(
                                                                  "/"
                                                                )
                                                              ) {
                                                                handleDeleteImage(
                                                                  selectedCategory,
                                                                  index,
                                                                  image
                                                                    .split("/")
                                                                    .pop()
                                                                );
                                                              } else if (
                                                                image instanceof
                                                                File
                                                              ) {
                                                                handleDeleteImage(
                                                                  selectedCategory,
                                                                  index,
                                                                  image.name
                                                                );
                                                              } else {
                                                                console.error(
                                                                  "Invalid image:",
                                                                  image
                                                                );
                                                              }
                                                            }}
                                                          />
                                                        </span>
                                                      </OverlayTrigger>
                                                    )}
                                                    {/* {console.log("/\/\/\//\/\/\URL.createObjectURL(image)", URL.createObjectURL(image))} */}
                                                    <img
                                                      className="default-img addlisting-img"
                                                      src={
                                                        typeof image ===
                                                        "string"
                                                          ? image
                                                          : URL.createObjectURL(
                                                              image
                                                            )
                                                      }
                                                      alt="Selected Image"
                                                      style={{
                                                        maxWidth: "100%",
                                                        minWidth: "100%",
                                                        maxHeight: "100%",
                                                        minHeight: "100%",
                                                        padding: "0",
                                                        transform: `rotate(${
                                                          rotationAngles[
                                                            index
                                                          ] || 0
                                                        }deg)`,
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              )
                                            )}
                                          </Slider>
                                        </div>
                                      </div>
                                    )}
                                  </div>

                                  <Container
                                    fluid
                                    className="uploader-container"
                                  >
                                    <Row>
                                      <Col
                                        sm={12}
                                        className="mx-auto d-flex align-items-start justify-content-between"
                                      >
                                        <div className=" d-flex align-items-start justify-content-start">
                                          <OverlayTrigger
                                            placement="top-start"
                                            overlay={
                                              <Tooltip
                                                id={`tooltip-images`}
                                                className="addlist-tooltip"
                                              >
                                                Uploade Images
                                              </Tooltip>
                                            }
                                          >
                                            <div className="image_uploads_section">
                                              <label
                                                htmlFor={`ALL-file-input`}
                                                className="image-upload-fields"
                                                style={{
                                                  top: "-5px",
                                                  left: ".5rem",
                                                  color: "white",
                                                  fontSize: "x-large",
                                                  backgroundColor:
                                                    "rgba(0, 0, 0, 0.5)",
                                                  borderRadius: "50%",
                                                  padding: "0.4rem",
                                                  display: "grid",
                                                  placeItems: "center",
                                                  zIndex: "2",
                                                  cursor: "pointer",
                                                }}
                                              >
                                                <FaFileUpload />
                                                {/* <img  src={Image.uploadImage}  style={{    cursor: "pointer",    height: "30px",    width: "30px",  }}  alt="Upload"/> */}
                                              </label>
                                              {/* <p className=""  onClick={() => handleCategoryClick("ALL")}  style={{    marginBottom: "0rem",    cursor: "pointer",    color: '#b5b5b5'  }}>  ALL</p> */}
                                              <input
                                                id={`ALL-file-input`}
                                                className="d-none"
                                                type="file"
                                                name={`ALL_images`}
                                                multiple
                                                onChange={handleImageChange}
                                              />
                                            </div>
                                          </OverlayTrigger>

                                          <OverlayTrigger
                                            placement="top-start"
                                            overlay={
                                              <Tooltip
                                                id={`tooltip-video`}
                                                className="addlist-tooltip"
                                              >
                                                Uploade Videos
                                              </Tooltip>
                                            }
                                          >
                                            <div className="image_uploads_section mx-1">
                                              <label
                                                htmlFor={`ALL-video-input`}
                                                className="image-upload-fields"
                                                style={{
                                                  // position: "absolute",
                                                  top: "-5px",
                                                  left: "3rem",
                                                  color: "white",
                                                  fontSize: "x-large",
                                                  backgroundColor:
                                                    "rgba(0, 0, 0, 0.5)",
                                                  borderRadius: "50%",
                                                  padding: "0.4rem",
                                                  display: "grid",
                                                  placeItems: "center",
                                                  zIndex: "2",
                                                  cursor: "pointer",
                                                }}
                                              >
                                                <RiVideoUploadFill />
                                              </label>
                                              <input
                                                id={`ALL-video-input`}
                                                className="d-none"
                                                type="file"
                                                name={`ALL_videos`}
                                                multiple
                                                onChange={handleVideoChange}
                                              />
                                            </div>
                                          </OverlayTrigger>
                                        </div>
                                        <OverlayTrigger
                                          placement="top-end"
                                          overlay={
                                            <Tooltip
                                              id={`tooltip-media`}
                                              className="addlist-tooltip"
                                            >
                                              View your media
                                            </Tooltip>
                                          }
                                        >
                                          <div className="image_uploads_section">
                                            {imageData.ALL.length ||
                                            imageData.EXT.length ||
                                            imageData.INT.length ||
                                            imageData["360"].length ? (
                                              <span
                                                className="image-upload-fields"
                                                style={{
                                                  position: "relative",
                                                  // top: "-5px",
                                                  // right: "1rem",
                                                  color: "white",
                                                  fontSize: "x-large",
                                                  backgroundColor:
                                                    "rgba(0, 0, 0, 0.5)",
                                                  borderRadius: "50%",
                                                  padding: "0.4rem",
                                                  display: "grid",
                                                  placeItems: "center",
                                                  zIndex: "2",
                                                  cursor: "pointer",
                                                }}
                                              >
                                                <LiaPhotoVideoSolid
                                                  fill="white"
                                                  onClick={() =>
                                                    setShowModalImg(true)
                                                  }
                                                />
                                              </span>
                                            ) : null}
                                          </div>
                                        </OverlayTrigger>
                                      </Col>
                                      {/* <Col sm={2}<Button className="border-0 text-nowrap btn-orange fw-bold p-1" onClick={() =>setShowModalImg(true)}>View All Image</Button</Col> */}
                                    </Row>
                                  </Container>

                                  {loading && !isImagesSaved ? (
                                    <div className="loader-container">
                                      <div className="loader">
                                        <div
                                          className="loader-progress"
                                          style={{
                                            transform: `rotate(${
                                              (percentage / 100) * 360
                                            }deg)`,
                                          }}
                                        ></div>
                                        <div className="loader-text">
                                          {percentage}%
                                        </div>
                                      </div>
                                      <div className="loader-caption">
                                        Uploaded {uploadedCount}
                                        of
                                        {totalCount}
                                        media
                                      </div>
                                    </div>
                                  ) : null}
                                </div>

                                <Modal
                                  show={showModalImg}
                                  onHide={() => setShowModalImg(false)}
                                  dialogClassName="modal-90w"
                                  id="modal-90w"
                                  aria-labelledby="image-modal-title"
                                >
                                  <Modal.Header
                                    closeButton
                                    className="text-center"
                                  >
                                    <Modal.Title
                                      id="image-modal-title"
                                      className="text-center text-nowrap"
                                    >
                                      Categories
                                    </Modal.Title>

                                    <Container>
                                      <Row className="pt-2">
                                        {/* <Col sm={1}></Col>
                                    <Col sm={2} className="fw-bolder fs-4 text-end">Categories</Col> */}
                                        <Col className="mb-3 px-0 d-flex justify-content-start">
                                          <Button
                                            variant="secondary"
                                            className={`border-0 text-nowrap fw-bold py-1 px-2 mx-2 ${
                                              mediaType == "image" &&
                                              imageType === "EXT"
                                                ? "btn-orange"
                                                : ""
                                            }`}
                                            onClick={() =>
                                              handleMediaType("image", "EXT")
                                            }
                                          >
                                            Exterior Images
                                          </Button>

                                          <Button
                                            variant="secondary"
                                            className={`border-0 text-nowrap fw-bold py-1 px-2 mx-2 ${
                                              mediaType == "image" &&
                                              imageType === "INT"
                                                ? "btn-orange"
                                                : ""
                                            }`}
                                            onClick={() =>
                                              handleMediaType("image", "INT")
                                            }
                                          >
                                            Interior Images
                                          </Button>

                                          <Button
                                            variant="secondary"
                                            className={`border-0 text-nowrap fw-bold py-1 px-2 mx-2 ${
                                              mediaType == "image" &&
                                              imageType === "MISC"
                                                ? "btn-orange"
                                                : ""
                                            }`}
                                            onClick={() =>
                                              handleMediaType("image", "MISC")
                                            }
                                          >
                                            other Images
                                          </Button>

                                          <Button
                                            variant="secondary"
                                            className={`border-0 text-nowrap fw-bold py-1 px-2 mx-2 ${
                                              mediaType == "image" &&
                                              imageType === "360"
                                                ? "btn-orange"
                                                : ""
                                            }`}
                                            onClick={() =>
                                              handleMediaType("image", "360")
                                            }
                                          >
                                            360deg Images
                                          </Button>

                                          <Button
                                            variant="secondary"
                                            className={`border-0 text-nowrap fw-bold py-1 px-2 mx-2 ${
                                              mediaType == "image"
                                                ? ""
                                                : "btn-orange"
                                            }`}
                                            onClick={() =>
                                              handleMediaType("video", "")
                                            }
                                          >
                                            Videos
                                          </Button>
                                        </Col>
                                        <Col className=" mb-3 d-flex justify-content-end">
                                          <Button className="border-0 text-nowrap btn-orange fw-bold py-1 px-2 me-2">
                                            <label
                                              htmlFor={`ALL-file-input`}
                                              className="image-upload-fields"
                                            >
                                              Upload Image
                                            </label>
                                            <input
                                              id={`ALL-file-input`}
                                              className="d-none"
                                              type="file"
                                              name={`ALL_images`}
                                              multiple
                                              onChange={handleImageChange}
                                            />
                                          </Button>
                                          <Button className="border-0 text-nowrap btn-orange fw-bold py-1 px-2 me-2">
                                            <label
                                              htmlFor={`ALL-video-input`}
                                              className="image-upload-fields"
                                            >
                                              Upload Video
                                            </label>
                                            <input
                                              id={`ALL-video-input`}
                                              className="d-none"
                                              type="file"
                                              name={`ALL_videos`}
                                              multiple
                                              onChange={handleVideoChange}
                                            />
                                          </Button>
                                          <Button
                                            className="border-0 text-nowrap btn-orange fw-bold py-1 px-5"
                                            onClick={() => {
                                              setShowModalImg(false);
                                              handleSaveImg();
                                            }}
                                          >
                                            Save
                                          </Button>
                                        </Col>
                                      </Row>
                                    </Container>
                                  </Modal.Header>

                                  <Modal.Body
                                    className="py-0"
                                    style={{ minHeight: "80vh" }}
                                  >
                                    <Container>
                                      {mediaType === "image" ? (
                                        <Row>
                                          <Col className=" d-flex justify-content-center">
                                            <h3>
                                              Select Category then Select Images
                                            </h3>
                                          </Col>
                                        </Row>
                                      ) : null}

                                      {mediaType === "image" ? (
                                        <DragDropContext onDragEnd={onDragEnd}>
                                          <Row>
                                            <Col sm={4}></Col>
                                            <Col sm={3} className="ps-5">
                                              {selectedCategory === imageType
                                                ? "Home Image"
                                                : ""}
                                            </Col>
                                            <Col sm={2}></Col>
                                            <Col
                                              sm={3}
                                              className="d-flex justify-content-end"
                                            >
                                              <Button
                                                className={`border-0 text-nowrap fw-bold py-1 px-2 mx-4 btn-orange `}
                                                onClick={() =>
                                                  handleMoveDownAll(false)
                                                }
                                                style={{
                                                  visibility:
                                                    imageData[imageType]
                                                      .length > 0 &&
                                                    imageData[imageType][0] !==
                                                      Image.defaultImg
                                                      ? "visible"
                                                      : "hidden",
                                                }}
                                              >
                                                Move Down All
                                              </Button>
                                              <Button
                                                onClick={() =>
                                                  handleDeleteImgAll(imageType)
                                                }
                                                className={`border-0 text-nowrap fw-bold py-1 px-2 mx-2 btn-orange`}
                                                style={{
                                                  visibility:
                                                    imageData[imageType]
                                                      .length > 0 &&
                                                    imageData[imageType][0] !==
                                                      Image.defaultImg
                                                      ? "visible"
                                                      : "hidden",
                                                }}
                                              >
                                                {`Delete All ${imageType}`}
                                              </Button>
                                            </Col>
                                          </Row>
                                          <Row className="first-row my-2">
                                            <Col sm={4} className="border px-0">
                                              <div className="zoom-img">
                                                {
                                                  imageType === "360" ? (
                                                    <PanolensViewer
                                                      image={
                                                        // imageData[imageType][currentImageIndex]
                                                        imageData[imageType] &&
                                                        imageData[imageType][
                                                          currentImageIndex
                                                        ]
                                                          ? typeof imageData[
                                                              imageType
                                                            ][
                                                              currentImageIndex
                                                            ] === "string"
                                                            ? imageData[
                                                                imageType
                                                              ][
                                                                currentImageIndex
                                                              ].startsWith(
                                                                "data:image"
                                                              )
                                                              ? Image.selectCat
                                                              : imageData[
                                                                  imageType
                                                                ][
                                                                  currentImageIndex
                                                                ]
                                                            : imageData[
                                                                imageType
                                                              ][
                                                                currentImageIndex
                                                              ] instanceof
                                                                File ||
                                                              imageData[
                                                                imageType
                                                              ][
                                                                currentImageIndex
                                                              ] instanceof Blob
                                                            ? URL.createObjectURL(
                                                                imageData[
                                                                  imageType
                                                                ][
                                                                  currentImageIndex
                                                                ]
                                                              )
                                                            : Image.selectCat
                                                          : Image.selectCat
                                                      }
                                                    />
                                                  ) : (
                                                    <img
                                                      src={
                                                        // imageData[imageType][currentImageIndex]
                                                        imageData[imageType] &&
                                                        imageData[imageType][
                                                          currentImageIndex
                                                        ]
                                                          ? typeof imageData[
                                                              imageType
                                                            ][
                                                              currentImageIndex
                                                            ] === "string"
                                                            ? imageData[
                                                                imageType
                                                              ][
                                                                currentImageIndex
                                                              ].startsWith(
                                                                "data:image"
                                                              )
                                                              ? Image.selectCat
                                                              : imageData[
                                                                  imageType
                                                                ][
                                                                  currentImageIndex
                                                                ]
                                                            : imageData[
                                                                imageType
                                                              ][
                                                                currentImageIndex
                                                              ] instanceof
                                                                File ||
                                                              imageData[
                                                                imageType
                                                              ][
                                                                currentImageIndex
                                                              ] instanceof Blob
                                                            ? URL.createObjectURL(
                                                                imageData[
                                                                  imageType
                                                                ][
                                                                  currentImageIndex
                                                                ]
                                                              )
                                                            : Image.selectCat
                                                          : Image.selectCat
                                                      }
                                                      alt="Main Car"
                                                    />
                                                  )

                                                  // <Viewer src={Image.threeSixty} style={{ width: '100%', height: '100%' }} />
                                                }
                                              </div>
                                            </Col>

                                            <Col sm={8} className="ps-5">
                                              <Container className="px-0">
                                                <Droppable
                                                  droppableId={`${imageType}`}
                                                  direction="horizontal"
                                                  className="cate-drop"
                                                >
                                                  {(provided) => (
                                                    <Row
                                                      className="second-row d-flex justify-content-start"
                                                      {...provided.droppableProps}
                                                      ref={provided.innerRef}
                                                      // onDragOver={handleDragOver}
                                                      onDrop={(e) =>
                                                        handleDrop(e, imageType)
                                                      }
                                                    >
                                                      {imageData[imageType].map(
                                                        (image, index) => (
                                                          <Draggable
                                                            className="border border-2"
                                                            key={`${imageType}-${index}`}
                                                            draggableId={`${imageType}-${index}`}
                                                            index={index}
                                                          >
                                                            {(provided) => (
                                                              <div
                                                                className="p-1 parent-small-img "
                                                                ref={
                                                                  provided.innerRef
                                                                }
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                              >
                                                                <div className="small-img">
                                                                  <img
                                                                    src={
                                                                      typeof image ===
                                                                      "string"
                                                                        ? image
                                                                        : image instanceof
                                                                            File ||
                                                                          image instanceof
                                                                            Blob
                                                                        ? URL.createObjectURL(
                                                                            image
                                                                          )
                                                                        : ""
                                                                    }
                                                                    alt=""
                                                                    className={`thumbnail ${
                                                                      index ===
                                                                      currentImageIndex
                                                                        ? "active"
                                                                        : ""
                                                                    }`}
                                                                    style={{
                                                                      transform: `rotate(${
                                                                        rotationAngles[
                                                                          index
                                                                        ] || 0
                                                                      }deg)`,
                                                                    }}
                                                                    onClick={() =>
                                                                      setCurrentImageIndex(
                                                                        index
                                                                      )
                                                                    }
                                                                    // onDoubleClickCapture={() => handleClickSelection(image, index, false)}
                                                                  />

                                                                  <div className="d-flex justify-content-end check-img">
                                                                    {image !==
                                                                    Image.defaultImg ? (
                                                                      <OverlayTrigger
                                                                        placement="bottom-end"
                                                                        overlay={
                                                                          <Tooltip
                                                                            id={`tooltip-delete`}
                                                                            className="addlist-tooltip"
                                                                          >
                                                                            Rotate
                                                                          </Tooltip>
                                                                        }
                                                                      >
                                                                        <span className="icon">
                                                                          <FaRedo
                                                                            style={{
                                                                              width:
                                                                                ".8rem",
                                                                              height:
                                                                                ".8rem",
                                                                              cursor:
                                                                                "pointer",
                                                                              color:
                                                                                "gray",
                                                                            }}
                                                                            onClick={() =>
                                                                              handleImageRotate(
                                                                                index
                                                                              )
                                                                            }
                                                                          />
                                                                        </span>
                                                                      </OverlayTrigger>
                                                                    ) : null}
                                                                  </div>

                                                                  <div className="icons">
                                                                    <OverlayTrigger
                                                                      placement="top-start"
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-down-${index}`}
                                                                          className="addlist-tooltip"
                                                                        >
                                                                          Move
                                                                          down
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <span className="icon">
                                                                        <MdMoveDown
                                                                          fill="black"
                                                                          onClick={() =>
                                                                            handleClickSelection(
                                                                              image,
                                                                              index,
                                                                              false
                                                                            )
                                                                          }
                                                                        />
                                                                      </span>
                                                                    </OverlayTrigger>

                                                                    <OverlayTrigger
                                                                      placement="top-end"
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-delete-${index}`}
                                                                          className="addlist-tooltip"
                                                                        >
                                                                          Delete
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <span className="icon">
                                                                        <FaTrash
                                                                          fill="black"
                                                                          // className="icon"
                                                                          onClick={() => {
                                                                            if (
                                                                              typeof imageData[
                                                                                imageType
                                                                              ][
                                                                                index
                                                                              ] ===
                                                                                "string" &&
                                                                              imageData[
                                                                                imageType
                                                                              ][
                                                                                index
                                                                              ].includes(
                                                                                "/"
                                                                              )
                                                                            ) {
                                                                              handleDeleteImage(
                                                                                imageType,
                                                                                index,
                                                                                imageData[
                                                                                  imageType
                                                                                ][
                                                                                  index
                                                                                ]
                                                                                  .split(
                                                                                    "/"
                                                                                  )
                                                                                  .pop()
                                                                              );
                                                                            } else if (
                                                                              imageData[
                                                                                imageType
                                                                              ][
                                                                                index
                                                                              ] instanceof
                                                                              File
                                                                            ) {
                                                                              handleDeleteImage(
                                                                                imageType,
                                                                                index,
                                                                                imageData[
                                                                                  imageType
                                                                                ][
                                                                                  index
                                                                                ]
                                                                                  .name
                                                                              );
                                                                            } else {
                                                                              console.error(
                                                                                "Invalid image:",
                                                                                imageData[
                                                                                  imageType
                                                                                ][
                                                                                  index
                                                                                ]
                                                                              );
                                                                            }
                                                                          }}
                                                                        />
                                                                      </span>
                                                                    </OverlayTrigger>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            )}
                                                          </Draggable>
                                                        )
                                                      )}

                                                      {provided.placeholder}
                                                    </Row>
                                                  )}
                                                </Droppable>
                                              </Container>
                                            </Col>
                                          </Row>

                                          <Droppable
                                            droppableId="ALL"
                                            direction="horizontal"
                                            className="border border-danger"
                                          >
                                            {(provided) => (
                                              <Row
                                                className="second-row mt-5 d-flex align-items-center"
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                              >
                                                {imageData.ALL.length > 0 && (
                                                  <>
                                                    <Col
                                                      sm={7}
                                                      className="ps-2"
                                                    >
                                                      Home Image
                                                    </Col>
                                                    <Col sm={5}>
                                                      <Button
                                                        className={`border-0 text-nowrap fw-bold py-1 px-2 mx-2 btn-orange`}
                                                        onClick={() =>
                                                          handleMoveUpAll(true)
                                                        }
                                                      >
                                                        Move Up All
                                                      </Button>

                                                      <Button
                                                        onClick={() =>
                                                          handleDeleteImgAll(
                                                            "ALL"
                                                          )
                                                        }
                                                        className={`border-0 text-nowrap fw-bold py-1 px-2 mx-2 btn-orange`}
                                                      >
                                                        Delete All Image
                                                      </Button>
                                                    </Col>
                                                  </>
                                                )}

                                                {imageData.ALL.map(
                                                  (image, index) => (
                                                    <Draggable
                                                      className="border border-warning"
                                                      key={`All-${index}`}
                                                      draggableId={`ALL-${index}`}
                                                      index={index}
                                                    >
                                                      {(provided) => (
                                                        <div
                                                          className="p-1 parent-small-img"
                                                          ref={
                                                            provided.innerRef
                                                          }
                                                          {...provided.draggableProps}
                                                          {...provided.dragHandleProps}
                                                        >
                                                          <div className="small-img">
                                                            <img
                                                              src={
                                                                typeof image ===
                                                                "string"
                                                                  ? image
                                                                  : URL.createObjectURL(
                                                                      image
                                                                    )
                                                              }
                                                              alt={`ALL-${index}`}
                                                              className={`thumbnail`}
                                                              style={{
                                                                transform: `rotate(${
                                                                  rotationAngles[
                                                                    index
                                                                  ] || 0
                                                                }deg)`,
                                                              }}
                                                              // onDoubleClickCapture={() => handleClickSelection(image, index, true)}
                                                              // onClick={() => setCurrentImageIndex(index)}
                                                            />

                                                            <div className="d-flex justify-content-between check-img">
                                                              <input
                                                                type="checkbox"
                                                                className=""
                                                                checked={
                                                                  isImgChecked
                                                                }
                                                                onChange={handleCheckSelection(
                                                                  image,
                                                                  index,
                                                                  true
                                                                )}
                                                              />
                                                              {image ? (
                                                                <OverlayTrigger
                                                                  placement="bottom-end"
                                                                  overlay={
                                                                    <Tooltip
                                                                      id={`tooltip-redo`}
                                                                      className="addlist-tooltip"
                                                                    >
                                                                      Rotate
                                                                    </Tooltip>
                                                                  }
                                                                >
                                                                  <span className="icon">
                                                                    <FaRedo
                                                                      fill="black"
                                                                      style={{
                                                                        width:
                                                                          ".8rem",
                                                                        height:
                                                                          ".8rem",
                                                                        cursor:
                                                                          "pointer",
                                                                      }}
                                                                      onClick={() =>
                                                                        handleImageRotate(
                                                                          index
                                                                        )
                                                                      }
                                                                    />
                                                                  </span>
                                                                </OverlayTrigger>
                                                              ) : null}
                                                            </div>

                                                            <div className="icons">
                                                              {/* <FaRedo fill="black" className="icon" onClick={() => handleImageRotate(index)} /> */}
                                                              <OverlayTrigger
                                                                placement="top-start"
                                                                overlay={
                                                                  <Tooltip
                                                                    id={`tooltip-up-${index}`}
                                                                    className="addlist-tooltip"
                                                                  >
                                                                    Move UP
                                                                  </Tooltip>
                                                                }
                                                              >
                                                                <span className="icon">
                                                                  <MdOutlineMoveUp
                                                                    fill="black"
                                                                    onClick={() =>
                                                                      handleClickSelection(
                                                                        image,
                                                                        index,
                                                                        true
                                                                      )
                                                                    }
                                                                  />
                                                                </span>
                                                              </OverlayTrigger>

                                                              <OverlayTrigger
                                                                placement="top-end"
                                                                overlay={
                                                                  <Tooltip
                                                                    id={`tooltip-delete-${index}`}
                                                                    className="addlist-tooltip"
                                                                  >
                                                                    Delete
                                                                  </Tooltip>
                                                                }
                                                              >
                                                                <span className="icon">
                                                                  <FaTrash
                                                                    fill="black"
                                                                    // className="icon"
                                                                    onClick={() => {
                                                                      if (
                                                                        typeof imageData
                                                                          .ALL[
                                                                          index
                                                                        ] ===
                                                                          "string" &&
                                                                        imageData.ALL[
                                                                          index
                                                                        ].includes(
                                                                          "/"
                                                                        )
                                                                      ) {
                                                                        handleDeleteImage(
                                                                          "ALL",
                                                                          index,
                                                                          imageData.ALL[
                                                                            index
                                                                          ]
                                                                            .split(
                                                                              "/"
                                                                            )
                                                                            .pop()
                                                                        );
                                                                      } else if (
                                                                        imageData
                                                                          .ALL[
                                                                          index
                                                                        ] instanceof
                                                                        File
                                                                      ) {
                                                                        handleDeleteImage(
                                                                          "ALL",
                                                                          index,
                                                                          imageData
                                                                            .ALL[
                                                                            index
                                                                          ].name
                                                                        );
                                                                      } else {
                                                                        console.error(
                                                                          "Invalid image:",
                                                                          imageData
                                                                            .ALL[
                                                                            index
                                                                          ]
                                                                        );
                                                                      }
                                                                    }}
                                                                  />
                                                                </span>
                                                              </OverlayTrigger>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      )}
                                                    </Draggable>
                                                  )
                                                )}
                                                {provided.placeholder}
                                              </Row>
                                            )}
                                          </Droppable>
                                        </DragDropContext>
                                      ) : (
                                        <Container fluid className="px-0 py-3">
                                          <Row className="first-row">
                                            <Col
                                              sm={12}
                                              className="zoom-video px-0"
                                            >
                                              {videosData[
                                                currentVideoIndex
                                              ] && (
                                                <video
                                                  controls
                                                  style={{
                                                    width: "inherit",
                                                    height: "inherit",
                                                  }}
                                                >
                                                  {typeof videosData[
                                                    currentVideoIndex
                                                  ] === "string" ? (
                                                    <source
                                                      src={
                                                        videosData[
                                                          currentVideoIndex
                                                        ]
                                                      }
                                                      type="video/mp4"
                                                    />
                                                  ) : (
                                                    <source
                                                      src={URL.createObjectURL(
                                                        videosData[
                                                          currentVideoIndex
                                                        ]
                                                      )}
                                                      type="video/mp4"
                                                    />
                                                  )}
                                                  Your browser does not support
                                                  the video tag.
                                                </video>
                                              )}
                                            </Col>
                                          </Row>

                                          <DragDropContext
                                            onDragEnd={onDragEnd}
                                          >
                                            <Droppable
                                              droppableId="videos"
                                              direction="horizontal"
                                            >
                                              {(provided) => (
                                                <Row
                                                  className="second-row d-flex align-items-center"
                                                  {...provided.droppableProps}
                                                  ref={provided.innerRef}
                                                >
                                                  {videosData.map(
                                                    (video, index) => (
                                                      <Draggable
                                                        key={index}
                                                        draggableId={String(
                                                          index
                                                        )}
                                                        index={index}
                                                      >
                                                        {(provided) => (
                                                          <Col
                                                            md={2}
                                                            className="p-1"
                                                            ref={
                                                              provided.innerRef
                                                            }
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                          >
                                                            <div className="small-video">
                                                              <video
                                                                className={`thumbnail ${
                                                                  index ===
                                                                  currentVideoIndex
                                                                    ? "active"
                                                                    : ""
                                                                }`}
                                                                onClick={() =>
                                                                  setCurrentVideoIndex(
                                                                    index
                                                                  )
                                                                }
                                                                style={{
                                                                  width:
                                                                    "100% !important",
                                                                  height:
                                                                    "89% !important",
                                                                }}
                                                              >
                                                                {typeof video ===
                                                                "string" ? (
                                                                  <source
                                                                    src={video}
                                                                    type="video/mp4"
                                                                  />
                                                                ) : (
                                                                  <source
                                                                    src={URL.createObjectURL(
                                                                      video
                                                                    )}
                                                                    type="video/mp4"
                                                                  />
                                                                )}
                                                                Your browser
                                                                does not support
                                                                the video tag.
                                                              </video>
                                                              <div className="d-flex justify-content-center">
                                                                <OverlayTrigger
                                                                  placement="top-end"
                                                                  overlay={
                                                                    <Tooltip
                                                                      id={`tooltip-delete-${index}`}
                                                                      className="addlist-tooltip"
                                                                    >
                                                                      Delete
                                                                    </Tooltip>
                                                                  }
                                                                >
                                                                  <span
                                                                    className=""
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                    }}
                                                                  >
                                                                    <FaTrash
                                                                      fill="black"
                                                                      // className="icon"
                                                                      onClick={() => {
                                                                        if (
                                                                          typeof video ===
                                                                            "string" &&
                                                                          video.includes(
                                                                            "/"
                                                                          )
                                                                        ) {
                                                                          handleDeleteMedia(
                                                                            "",
                                                                            index,
                                                                            video
                                                                              .split(
                                                                                "/"
                                                                              )
                                                                              .pop()
                                                                          );
                                                                        } else if (
                                                                          video instanceof
                                                                          File
                                                                        ) {
                                                                          handleDeleteMedia(
                                                                            "",
                                                                            index,
                                                                            video.name
                                                                          );
                                                                        } else {
                                                                          console.error(
                                                                            "Invalid video:",
                                                                            video
                                                                          );
                                                                        }
                                                                      }}
                                                                    />
                                                                  </span>
                                                                </OverlayTrigger>
                                                              </div>
                                                            </div>
                                                          </Col>
                                                        )}
                                                      </Draggable>
                                                    )
                                                  )}
                                                  {provided.placeholder}
                                                </Row>
                                              )}
                                            </Droppable>
                                          </DragDropContext>
                                        </Container>
                                      )}
                                    </Container>
                                  </Modal.Body>
                                </Modal>

                                {/*---------------------------------------------------------------------------------------------------- For Images end------------------------------ */}
                                <div
                                  className="col-md-4"
                                  style={{ marginTop: "0px" }}
                                >
                                  <div>
                                    <label>Exterior Color</label>
                                  </div>
                                  <div
                                    className="input-field-year-make"
                                    style={{
                                      backgroundColor: "rgb(240 150 31 / 22%)",
                                      padding: "0 5px",
                                    }}
                                  >
                                    <span
                                      style={{
                                        height: "30px",
                                        width: "35px",
                                        backgroundColor: exteriorColorShow,
                                      }}
                                    ></span>
                                    <input
                                      type="text"
                                      name="exteriorColor"
                                      value={formData.exteriorColor}
                                      onChange={handleChange}
                                      onClick={handleArrowClickh}
                                      onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                          e.preventDefault();
                                        }
                                      }}
                                      id="inputadlst"
                                      style={{
                                        fontSize: "15",
                                        // width: "91%",
                                        fontWeight: "bolder",
                                        letterSpacing: "2px",
                                        backgroundColor: "transparent",
                                      }}
                                    />
                                    <div
                                      className="arrow-adlst"
                                      style={{
                                        position: "absolute",
                                        right: "0px",
                                      }}
                                    >
                                      <FaArrowRight
                                        onClick={handleArrowClickh}
                                      />
                                    </div>
                                  </div>
                                  <Modal
                                    show={showModalh}
                                    onHide={handleCloseModalh}
                                  >
                                    <Modal.Header closeButton>
                                      <Modal.Title>
                                        Choose Exterior Color
                                      </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body style={{ height: "88%" }}>
                                      <PopupColorData
                                        radioOptions={radioOptionsh}
                                        onStateSelect={handleOptionSelecth}
                                        boxColor={radioOptionsi.boxColor}
                                      />
                                    </Modal.Body>
                                    <Modal.Footer
                                      style={{ justifyContent: "flex-start" }}
                                    >
                                      <input
                                        type="text"
                                        placeholder="Add Your Exterior Color"
                                        value={newOptionExteriorColor}
                                        onChange={(e) =>
                                          setNewOptionExteriorColor(
                                            e.target.value
                                          )
                                        }
                                        onKeyPress={(e) => {
                                          if (e.key === "Enter") {
                                            handleAddOptionExteriorColor();
                                          }
                                        }}
                                        style={{
                                          marginRight: "1rem",
                                          width: "42%",
                                        }}
                                      />
                                    </Modal.Footer>
                                  </Modal>
                                </div>

                                <div
                                  className="col-md-4"
                                  style={{ marginTop: "0px" }}
                                >
                                  <div>
                                    <label>Interior Color</label>
                                  </div>
                                  <div
                                    className="input-field-year-make"
                                    style={{
                                      backgroundColor: "rgb(240 150 31 / 22%)",
                                      padding: "0 5px",
                                    }}
                                  >
                                    {/* <div className="persistent-placeholder" style={{ fontWeight: "bold" }}>Battery Kwh:</div> */}
                                    <span
                                      style={{
                                        height: "30px",
                                        width: "35px",
                                        backgroundColor: interiorColorShow,
                                      }}
                                    ></span>
                                    <input
                                      type="text"
                                      name="interiorColor"
                                      value={formData.interiorColor}
                                      onChange={handleChange}
                                      onClick={handleArrowClicki}
                                      onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                          e.preventDefault();
                                        }
                                      }}
                                      id="inputadlst"
                                      style={{
                                        paddingLeft: "7%",
                                        fontSize: "15",
                                        width: "91%",
                                        fontWeight: "bolder",
                                        letterSpacing: "2px",
                                        backgroundColor: "transparent",
                                      }}
                                    />
                                    <div
                                      className="arrow-adlst"
                                      style={{
                                        position: "absolute",
                                        right: "0px",
                                      }}
                                    >
                                      <FaArrowRight
                                        onClick={handleArrowClicki}
                                      />
                                    </div>
                                  </div>
                                  <Modal
                                    show={showModali}
                                    onHide={handleCloseModali}
                                  >
                                    <Modal.Header closeButton>
                                      <Modal.Title>
                                        Choose Interior Color
                                      </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body style={{ height: "88%" }}>
                                      <PopupColorData
                                        radioOptions={radioOptionsi}
                                        onStateSelect={handleOptionSelecti}
                                        boxColor={radioOptionsi.boxColor}
                                      />
                                    </Modal.Body>
                                    <Modal.Footer
                                      style={{ justifyContent: "flex-start" }}
                                    >
                                      <input
                                        type="text"
                                        placeholder="Add Your Interior Color"
                                        value={newOptionInteriorColor}
                                        onChange={(e) =>
                                          setNewOptionInteriorColor(
                                            e.target.value
                                          )
                                        }
                                        onKeyPress={(e) => {
                                          if (e.key === "Enter") {
                                            handleAddOptionInteriorColor();
                                          }
                                        }}
                                        style={{
                                          marginRight: "1rem",
                                          width: "42%",
                                        }}
                                      />
                                    </Modal.Footer>
                                  </Modal>
                                </div>

                                <div className="col-md-4">
                                  <div>
                                    <label>Interior Type:</label>
                                  </div>
                                  <div
                                    className="input-field-year-make bg-input"
                                    style={{ backgroundColor: "" }}
                                  >
                                    <input
                                      type="text"
                                      name="interiorType"
                                      value={formData.interiorType}
                                      onChange={handleChange}
                                      onClick={handleArrowClicke}
                                      onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                          e.preventDefault();
                                        }
                                      }}
                                      id="inputadlst"
                                      style={{
                                        paddingLeft: "20%",
                                        fontSize: "15",
                                        width: "70%",
                                        fontWeight: "bolder",
                                        letterSpacing: "2px",
                                        backgroundColor:
                                          "rgb(240 150 31 / 22%)",
                                      }}
                                    />
                                    <div className="arrow-adlst">
                                      <FaArrowRight
                                        onClick={handleArrowClicke}
                                      />
                                    </div>
                                  </div>
                                  <Modal
                                    show={showModale}
                                    onHide={handleCloseModale}
                                  >
                                    <Modal.Header closeButton>
                                      <Modal.Title>
                                        Choose a interior type pakage
                                      </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body style={{ height: "88%" }}>
                                      <PopupData
                                        radioOptions={radioOptionse}
                                        onStateSelect={handleOptionSelecte}
                                      />
                                    </Modal.Body>
                                    <Modal.Footer></Modal.Footer>
                                  </Modal>
                                </div>

                                <div
                                  className="col-12"
                                  style={{ marginTop: "10px", width: "98%" }}
                                >
                                  <label>Other Restraint</label>
                                  <div
                                    className="input-fields-other bg-input"
                                    style={{ width: "100%" }}
                                  >
                                    <textarea
                                      type="text"
                                      name="other_restraint"
                                      value={formData.other_restraint} // ensure `value` is a state variable
                                      onChange={handleChange}
                                      onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                          e.preventDefault();
                                        }
                                      }}
                                      id="inputadlst"
                                      style={{
                                        border: "none",
                                        fontSize: "15px",
                                        fontWeight: "bolder",
                                        letterSpacing: "2px",
                                        color: "black",
                                        borderRadius: "0px",
                                        textAlign: "left",
                                        padding: "10px",
                                        width: "100%", // Ensure it takes full width of the container
                                        height: "104px", // Adjust the height as needed
                                        resize: "none", // Prevent resizing by the user
                                        overflowWrap: "break-word",
                                        boxSizing: "border-box", // Include padding and border in the element's total width and height
                                        fontFamily: "monospace",
                                        backgroundColor: "#fce8cd",
                                        outline: "none",
                                      }}
                                    />
                                  </div>
                                </div>
                              </Row>
                            </Container>
                          </Col>
                          {/* -------------------two input filed End------------------------- */}
                        </Row>
                      </Container>
                    </Tab>
                    <Tab eventKey="tab2" title="Equipments">
                      <Equipments
                        formData={formData}
                        setFormData={setFormData}
                        apiDataEdit={apiDataEdit}
                        setApiDataEdit={setApiDataEdit}
                        apiData={apiData}
                      />
                    </Tab>
                    <Tab eventKey="tab3" title="Seller Description">
                      <div id="refresh-btn-seller">
                        {" "}
                        <button
                          type="button"
                          id="adlst-reset-btn-seller"
                          onClick={handleResetSeller}
                          title="Reset"
                        >
                          <img src={Image.reset_button} alt="Reset Button" />
                        </button>
                      </div>
                      <Container>
                        <section className="row" style={{ marginLeft: "2px" }}>
                          <div
                            className="col-md-6 mt-1 seller1"
                            id="adlsttitledropdown"
                          >
                            <div>
                              <div
                                className="input-containerdrpdn input-fields bg-input"
                                style={{ width: "50%" }}
                              >
                                <span>
                                  <label>
                                    <img
                                      src={Image.title}
                                      style={{ width: "30px", height: "25px" }}
                                    />
                                    Title State
                                  </label>
                                </span>
                                <div
                                  className="col-md-6"
                                  style={{ width: "91%" }}
                                >
                                  <div className="input-field-year-make bg-input">
                                    <input
                                      type="text"
                                      name="state"
                                      placeholder="State"
                                      value={formData.state}
                                      onChange={handleChange}
                                      onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                          e.preventDefault();
                                        }
                                      }}
                                      onClick={handleArrowClickj}
                                      id="inputadlst"
                                      style={{
                                        paddingLeft: "20%",
                                        fontSize: "15",
                                        width: "91%",
                                        fontWeight: "bolder",
                                        letterSpacing: "2px",
                                      }}
                                    />
                                    <div className="arrow-adlst">
                                      <FaArrowRight
                                        onClick={handleArrowClickj}
                                      />
                                    </div>
                                  </div>
                                  <Modal
                                    show={showModalj}
                                    onHide={handleCloseModalj}
                                  >
                                    <Modal.Header closeButton>
                                      <Modal.Title>Choose a state</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body style={{ height: "355px" }}>
                                      <StateSelector
                                        onStateSelect={handleOptionSelectj}
                                      />
                                    </Modal.Body>
                                    <Modal.Footer
                                      style={{
                                        justifyContent: "space-between",
                                      }}
                                    ></Modal.Footer>
                                  </Modal>
                                </div>
                              </div>
                              <div
                                className="input-containerdrpdn input-fields bg-input"
                                style={{ width: "50%" }}
                              >
                                <span>
                                  <label>
                                    <img
                                      src={Image.title}
                                      style={{ width: "30px", height: "25px" }}
                                    />
                                    Title Type
                                  </label>
                                </span>
                                <div
                                  className="col-md-6"
                                  style={{ width: "91%" }}
                                >
                                  <div className="input-field-year-make bg-input">
                                    <input
                                      type="text"
                                      name="title_status"
                                      placeholder="Status"
                                      value={formData.title_status}
                                      onChange={handleChange}
                                      onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                          e.preventDefault();
                                        }
                                      }}
                                      onClick={handleArrowClickk}
                                      id="inputadlst"
                                      style={{
                                        paddingLeft: "20%",
                                        fontSize: "15",
                                        width: "91%",
                                        fontWeight: "bolder",
                                        letterSpacing: "2px",
                                      }}
                                    />
                                    <div className="arrow-adlst">
                                      <FaArrowRight
                                        onClick={handleArrowClickk}
                                      />
                                    </div>
                                  </div>
                                  <Modal
                                    show={showModalk}
                                    onHide={handleCloseModalk}
                                  >
                                    <Modal.Header closeButton>
                                      <Modal.Title>Choose a status</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body style={{ height: "88%" }}>
                                      <PopupData
                                        radioOptions={radioOptionsk}
                                        onStateSelect={handleOptionSelectk}
                                      />
                                    </Modal.Body>
                                    <Modal.Footer
                                      style={{
                                        justifyContent: "space-between",
                                      }}
                                    ></Modal.Footer>
                                  </Modal>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3 mt-2 seller2">
                            <div>
                              <label>
                                <img
                                  src={Image.mileage}
                                  style={{ width: "25px", height: "25px" }}
                                />
                                Mileage
                              </label>
                            </div>
                            <div className="input-containerdrpdn input-fields bg-input">
                              <div
                                className="col-md-6"
                                style={{ width: "91%" }}
                              >
                                <div className="input-field-year-make bg-input">
                                  <input
                                    type="text"
                                    autoComplete="off"
                                    name="mileage_in"
                                    className="placholder_color"
                                    placeholder="0"
                                    value={displayValues.mileage_in}
                                    onChange={handleChangeb}
                                    onKeyPress={(e) => {
                                      if (e.key === "Enter") {
                                        e.preventDefault();
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3 mt-2 seller2">
                            <div>
                              <label>
                                <img
                                  src={Image.owner}
                                  style={{ width: "25px", height: "25px" }}
                                />
                                Owner
                              </label>
                            </div>
                            <div className="input-containerdrpdn input-fields bg-input">
                              <div
                                className="col-md-6"
                                style={{ width: "91%" }}
                              >
                                <div className="input-field-year-make bg-input">
                                  <input
                                    type="text"
                                    name="owner"
                                    value={formData.owner}
                                    onChange={handleChange}
                                    onKeyPress={(e) => {
                                      if (e.key === "Enter") {
                                        e.preventDefault();
                                      }
                                    }}
                                    onClick={handleArrowClickm}
                                    id="inputadlst"
                                    style={{
                                      paddingLeft: "20%",
                                      fontSize: "15",
                                      width: "91%",
                                      fontWeight: "bolder",
                                      letterSpacing: "2px",
                                    }}
                                  />
                                  <div className="arrow-adlst">
                                    <FaArrowRight onClick={handleArrowClickm} />
                                  </div>
                                </div>
                                <Modal
                                  show={showModalm}
                                  onHide={handleCloseModalm}
                                >
                                  <Modal.Header closeButton>
                                    <Modal.Title>Choose a Owner</Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body style={{ height: "88%" }}>
                                    <PopupData
                                      radioOptions={radioOptionsm}
                                      onStateSelect={handleOptionSelectm}
                                    />
                                  </Modal.Body>
                                  <Modal.Footer
                                    style={{ justifyContent: "space-between" }}
                                  ></Modal.Footer>
                                </Modal>
                              </div>
                            </div>
                          </div>
                          <div
                            className="row"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div className="col-md-3 mt-4 seller2">
                              <div>
                                <img
                                  src={Image.suitKeys}
                                  alt="suitcase_keys"
                                  style={{ width: "25px", height: "25px" }}
                                />
                                <label>Keys</label>
                              </div>
                              <div className="input-containerdrpdn input-fields bg-input">
                                <div
                                  className="col-md-6"
                                  style={{ width: "91%" }}
                                >
                                  <div className="input-field-year-make bg-input">
                                    <input
                                      type="text"
                                      name="keys_count"
                                      value={formData.keys_count}
                                      onChange={handleChange}
                                      onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                          e.preventDefault();
                                        }
                                      }}
                                      onClick={handleArrowClickg}
                                      id="inputadlst"
                                      style={{
                                        paddingLeft: "20%",
                                        fontSize: "15",
                                        width: "91%",
                                        fontWeight: "bolder",
                                        letterSpacing: "2px",
                                      }}
                                    />
                                    <div className="arrow-adlst">
                                      <FaArrowRight
                                        onClick={handleArrowClickg}
                                      />
                                    </div>
                                  </div>

                                  <Modal
                                    show={showModalg}
                                    onHide={handleCloseModalg}
                                  >
                                    <Modal.Header closeButton>
                                      <Modal.Title>Choose Keys</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body style={{ height: "88%" }}>
                                      <PopupData
                                        radioOptions={radioOptionsg}
                                        onStateSelect={handleOptionSelectg}
                                      />
                                    </Modal.Body>
                                    <Modal.Footer
                                      style={{
                                        justifyContent: "space-between",
                                      }}
                                    ></Modal.Footer>
                                  </Modal>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3 mt-4 seller2">
                              <div>
                                <img
                                  src={Image.green_smoke_icon}
                                  alt="smokedin_image"
                                  style={{ width: "25px", height: "25px" }}
                                />
                                <label>Odor/Smoked IN</label>
                                <div className="input-containerdrpdn input-fields bg-input">
                                  <div
                                    className="col-md-6"
                                    style={{ width: "91%" }}
                                  >
                                    <div className="input-field-year-make bg-input">
                                      <input
                                        type="text"
                                        name="smoked_in"
                                        value={formData.smoked_in}
                                        onChange={handleChange}
                                        onKeyPress={(e) => {
                                          if (e.key === "Enter") {
                                            e.preventDefault();
                                          }
                                        }}
                                        onClick={handleArrowClickq}
                                        id="inputadlst"
                                        style={{
                                          paddingLeft: "20%",
                                          fontSize: "15",
                                          width: "91%",
                                          fontWeight: "bolder",
                                          letterSpacing: "2px",
                                        }}
                                      />
                                      <div className="arrow-adlst">
                                        <FaArrowRight
                                          onClick={handleArrowClickq}
                                        />
                                      </div>
                                    </div>
                                    <Modal
                                      show={showModalq}
                                      onHide={handleCloseModalq}
                                    >
                                      <Modal.Header closeButton>
                                        <Modal.Title>
                                          Choose a Smoked In Status
                                        </Modal.Title>
                                      </Modal.Header>
                                      <Modal.Body style={{ height: "88%" }}>
                                        <PopupData
                                          radioOptions={radioOptionsq}
                                          onStateSelect={handleOptionSelectq}
                                        />
                                      </Modal.Body>
                                      <Modal.Footer
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      ></Modal.Footer>
                                    </Modal>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3 mt-4 seller2">
                              <div>
                                <img
                                  src={Image.damage}
                                  alt="damage_image"
                                  style={{ width: "25px", height: "25px" }}
                                />
                                <label>
                                  {" "}
                                  <span>Exterior</span> Damage
                                </label>{" "}
                              </div>
                              <div className="input-containerdrpdn input-fields bg-input">
                                <div
                                  className="col-md-6"
                                  style={{ width: "91%" }}
                                >
                                  <div className="input-field-year-make bg-input">
                                    <input
                                      type="text"
                                      name="exterior_damage"
                                      value={formData.exterior_damage}
                                      onChange={handleChange}
                                      onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                          e.preventDefault();
                                        }
                                      }}
                                      onClick={handleArrowClicko}
                                      id="inputadlst"
                                      style={{
                                        paddingLeft: "20%",
                                        fontSize: "15",
                                        width: "91%",
                                        fontWeight: "bolder",
                                        letterSpacing: "2px",
                                      }}
                                    />
                                    <div className="arrow-adlst">
                                      <FaArrowRight
                                        onClick={handleArrowClicko}
                                      />
                                    </div>
                                  </div>
                                  <Modal
                                    show={showModalo}
                                    onHide={handleCloseModalo}
                                  >
                                    <Modal.Header closeButton>
                                      <Modal.Title>
                                        Choose a Exterior Damage
                                      </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body style={{ height: "88%" }}>
                                      <PopupData
                                        radioOptions={radioOptionso}
                                        onStateSelect={handleOptionSelecto}
                                      />
                                    </Modal.Body>
                                    <Modal.Footer
                                      style={{
                                        justifyContent: "space-between",
                                      }}
                                    ></Modal.Footer>
                                  </Modal>
                                </div>
                              </div>
                            </div>
                          </div>
                          <p style={{ marginTop: "2rem" }}>
                            <label
                              htmlFor="steelAlloyWheel"
                              style={{ marginRight: "1rem" }}
                            >
                              <input
                                type="checkbox"
                                name="steelAlloyWheel"
                                id="steelAlloyWheel"
                                value="steelAlloyWheel"
                                onChange={() =>
                                  setFormData((prevData) => ({
                                    ...prevData,
                                    steelAlloyWheel: !formData.steelAlloyWheel,
                                  }))
                                }
                                checked={formData.steelAlloyWheel}
                                style={{ marginRight: "0.5rem" }}
                              />
                              Steel Alloy Wheel
                            </label>
                            <label htmlFor="aluminiumAlloyWheel">
                              <input
                                type="checkbox"
                                name="aluminiumAlloyWheel"
                                id="aluminiumAlloyWheel"
                                value="aluminiumAlloyWheel"
                                onChange={() =>
                                  setFormData((prevData) => ({
                                    ...prevData,
                                    aluminiumAlloyWheel:
                                      !formData.aluminiumAlloyWheel,
                                  }))
                                }
                                checked={formData.aluminiumAlloyWheel}
                                style={{ marginRight: "0.5em" }}
                              />{" "}
                              Aluminium Alloy Wheel
                            </label>
                          </p>
                          <div
                            className="row"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "96%",
                            }}
                          >
                            <div className="col-md-2 mt-4 seller8">
                              <label>
                                <img
                                  src={
                                    defaultTyreImage
                                    // formData.tyrecondition_fl.length > 0
                                    //   ? selectedImages.tyrecondition_fl
                                    //   : defaultTyreImage
                                  }
                                  style={{ width: "25px", height: "25px" }}
                                  className=""
                                />
                                Tire-Condition-FL
                              </label>
                              <div className="" id="trdropdownadlst">
                                <div className="input-containerdrpdn input-fields bg-input">
                                  <div
                                    className="col-md-6"
                                    style={{ width: "124%" }}
                                  >
                                    <div className="input-field-year-make bg-input">
                                      {selectedImages.tyrecondition_fl && (
                                        <img
                                          src={selectedImages.tyrecondition_fl}
                                          alt="selected_tyre"
                                          style={{
                                            width: "25px",
                                            height: "25px",
                                            position: "absolute",
                                            left: "1rem",
                                          }}
                                        />
                                      )}
                                      <input
                                        type="text"
                                        name="tyrecondition_fl"
                                        value={formData.tyrecondition_fl}
                                        onChange={handleChange}
                                        onKeyPress={(e) => {
                                          if (e.key === "Enter") {
                                            e.preventDefault();
                                          }
                                        }}
                                        onClick={handleArrowClickr}
                                        id="inputadlst"
                                        style={{
                                          paddingLeft: "20%",
                                          fontSize: "15",
                                          width: "91%",
                                          fontWeight: "bolder",
                                          letterSpacing: "2px",
                                        }}
                                      />
                                      <div className="arrow-adlst">
                                        <FaArrowRight
                                          onClick={handleArrowClickr}
                                        />
                                      </div>
                                    </div>
                                    <Modal
                                      show={showModalr}
                                      onHide={handleCloseModalr}
                                    >
                                      <Modal.Header closeButton>
                                        <Modal.Title>
                                          Choose Tire-Condition-FL
                                        </Modal.Title>
                                      </Modal.Header>
                                      <Modal.Body>
                                        <PopupTyresData
                                          radioOptions={radioOptionsr}
                                          onStateSelect={handleOptionSelectr}
                                        />
                                      </Modal.Body>
                                      <Modal.Footer
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      ></Modal.Footer>
                                    </Modal>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-2 mt-4 seller9">
                              <label>
                                <img
                                  src={
                                    defaultTyreImage
                                    // formData.tyrecondition_bl.length > 0
                                    //   ? selectedImages.tyrecondition_bl
                                    //   : defaultTyreImage
                                  }
                                  style={{ width: "25px", height: "25px" }}
                                  className=""
                                />
                                Tire-Condition-BL
                              </label>
                              <div className="" id="trdropdownadlst">
                                <div className="input-containerdrpdn input-fields bg-input">
                                  <div
                                    className="col-md-6"
                                    style={{ width: "124%" }}
                                  >
                                    {selectedImages.tyrecondition_bl && (
                                      <img
                                        src={selectedImages.tyrecondition_bl}
                                        alt="selected_tyre"
                                        style={{
                                          width: "25px",
                                          height: "25px",
                                          position: "absolute",
                                          left: "1rem",
                                          top: "0.6rem",
                                        }}
                                      />
                                    )}
                                    <div className="input-field-year-make bg-input">
                                      <input
                                        type="text"
                                        value={formData.tyrecondition_bl}
                                        onChange={handleChange}
                                        onKeyPress={(e) => {
                                          if (e.key === "Enter") {
                                            e.preventDefault();
                                          }
                                        }}
                                        onClick={handleArrowClicks}
                                        id="inputadlst"
                                        style={{
                                          paddingLeft: "20%",
                                          fontSize: "15",
                                          width: "91%",
                                          fontWeight: "bolder",
                                          letterSpacing: "2px",
                                        }}
                                      />
                                      <div className="arrow-adlst">
                                        <FaArrowRight
                                          onClick={handleArrowClicks}
                                        />
                                      </div>
                                    </div>
                                    <Modal
                                      show={showModals}
                                      onHide={handleCloseModals}
                                    >
                                      <Modal.Header closeButton>
                                        <Modal.Title>
                                          Choose Tire-Condition-BL
                                        </Modal.Title>
                                      </Modal.Header>
                                      <Modal.Body>
                                        <PopupTyresData
                                          radioOptions={radioOptionss}
                                          onStateSelect={handleOptionSelects}
                                        />
                                      </Modal.Body>
                                      <Modal.Footer
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      ></Modal.Footer>
                                    </Modal>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-2 mt-4 seller10">
                              <label>
                                <img
                                  src={
                                    defaultTyreImage
                                    // formData.tyrecondition_fr
                                    //   ? selectedImages.tyrecondition_fr
                                    //   : defaultTyreImage
                                  }
                                  style={{ width: "25px", height: "25px" }}
                                  className=""
                                />
                                Tire-Condition-FR
                              </label>
                              <div className="" id="trdropdownadlst">
                                <div className="input-containerdrpdn input-fields bg-input">
                                  <div
                                    className="col-md-6"
                                    style={{ width: "124%" }}
                                  >
                                    <div className="input-field-year-make bg-input">
                                      {selectedImages.tyrecondition_fr && (
                                        <img
                                          src={selectedImages.tyrecondition_fr}
                                          alt="selected_tyre"
                                          style={{
                                            width: "25px",
                                            height: "25px",
                                            position: "absolute",
                                            left: "1rem",
                                            top: "0.6rem",
                                          }}
                                        />
                                      )}
                                      <input
                                        type="text"
                                        name="tyrecondition_fr"
                                        value={formData.tyrecondition_fr}
                                        onChange={handleChange}
                                        onKeyPress={(e) => {
                                          if (e.key === "Enter") {
                                            e.preventDefault();
                                          }
                                        }}
                                        onClick={handleArrowClickt}
                                        id="inputadlst"
                                        style={{
                                          paddingLeft: "20%",
                                          fontSize: "15",
                                          width: "91%",
                                          fontWeight: "bolder",
                                          letterSpacing: "2px",
                                        }}
                                      />
                                      <div className="arrow-adlst">
                                        <FaArrowRight
                                          onClick={handleArrowClickt}
                                        />
                                      </div>
                                    </div>
                                    <Modal
                                      show={showModalt}
                                      onHide={handleCloseModalt}
                                    >
                                      <Modal.Header closeButton>
                                        <Modal.Title>
                                          Choose Tire-Condition-FR
                                        </Modal.Title>
                                      </Modal.Header>
                                      <Modal.Body>
                                        <PopupTyresData
                                          radioOptions={radioOptionst}
                                          onStateSelect={handleOptionSelectt}
                                        />
                                      </Modal.Body>
                                      <Modal.Footer
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      ></Modal.Footer>
                                    </Modal>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-2 mt-4 seller11">
                              <label>
                                <img
                                  src={
                                    defaultTyreImage
                                    // formData.tyrecondition_br.length > 0
                                    //   ? selectedImages.tyrecondition_br
                                    //   : defaultTyreImage
                                  }
                                  style={{ width: "25px", height: "25px" }}
                                  className=""
                                />
                                Tire-Condition-BR
                              </label>
                              <div className="" id="trdropdownadlst">
                                <div className="input-containerdrpdn input-fields bg-input">
                                  <div
                                    className="col-md-6"
                                    style={{ width: "124%" }}
                                  >
                                    <div className="input-field-year-make bg-input">
                                      {selectedImages.tyrecondition_br && (
                                        <img
                                          src={selectedImages.tyrecondition_br}
                                          alt="selected_tyre"
                                          style={{
                                            width: "25px",
                                            height: "25px",
                                            position: "absolute",
                                            left: "1rem",
                                            top: "0.5rem",
                                          }}
                                        />
                                      )}
                                      <input
                                        type="text"
                                        name="tyrecondition_br"
                                        value={formData.tyrecondition_br}
                                        onChange={handleChange}
                                        onKeyPress={(e) => {
                                          if (e.key === "Enter") {
                                            e.preventDefault();
                                          }
                                        }}
                                        onClick={handleArrowClicku}
                                        id="inputadlst"
                                        style={{
                                          paddingLeft: "20%",
                                          fontSize: "15",
                                          width: "91%",
                                          fontWeight: "bolder",
                                          letterSpacing: "2px",
                                        }}
                                      />
                                      <div className="arrow-adlst">
                                        <FaArrowRight
                                          onClick={handleArrowClicku}
                                        />
                                      </div>
                                    </div>
                                    <Modal
                                      show={showModalu}
                                      onHide={handleCloseModalu}
                                    >
                                      <Modal.Header closeButton>
                                        <Modal.Title>
                                          Choose Tire-Condition-BR
                                        </Modal.Title>
                                      </Modal.Header>
                                      <Modal.Body>
                                        <PopupTyresData
                                          radioOptions={radioOptionsu}
                                          onStateSelect={handleOptionSelectu}
                                        />
                                      </Modal.Body>
                                      <Modal.Footer
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      ></Modal.Footer>
                                    </Modal>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2 mt-4 seller11">
                              <label>
                                <img
                                  src={
                                    defaultTyreImage
                                    // formData.tyrecondition_st.length > 0
                                    //   ? selectedImages.tyrecondition_st
                                    //   : defaultTyreImage
                                  }
                                  style={{ width: "25px", height: "25px" }}
                                  className=""
                                />
                                Spare Tire
                              </label>
                              <div className="" id="trdropdownadlst">
                                <div className="input-containerdrpdn input-fields bg-input">
                                  <div
                                    className="col-md-6"
                                    style={{ width: "124%" }}
                                  >
                                    <div className="input-field-year-make bg-input">
                                      {selectedImages.tyrecondition_st && (
                                        <img
                                          src={selectedImages.tyrecondition_st}
                                          alt="selected_tyre"
                                          style={{
                                            width: "25px",
                                            height: "25px",
                                            position: "absolute",
                                            left: "1rem",
                                            top: "0.5rem",
                                          }}
                                        />
                                      )}
                                      <input
                                        type="text"
                                        name="tyrecondition_st"
                                        value={formData.tyrecondition_st}
                                        onChange={handleChange}
                                        onKeyPress={(e) => {
                                          if (e.key === "Enter") {
                                            e.preventDefault();
                                          }
                                        }}
                                        onClick={handleArrowClickSpare}
                                        id="inputadlst"
                                        style={{
                                          paddingLeft: "20%",
                                          fontSize: "15",
                                          width: "91%",
                                          fontWeight: "bolder",
                                          letterSpacing: "2px",
                                        }}
                                      />
                                      <div className="arrow-adlst">
                                        <FaArrowRight
                                          onClick={handleArrowClickSpare}
                                        />
                                      </div>
                                    </div>
                                    <Modal
                                      show={showModalSpare}
                                      onHide={handleCloseModalSpare}
                                    >
                                      <Modal.Header closeButton>
                                        <Modal.Title>
                                          Choose Tire-Condition-Spare-Tire
                                        </Modal.Title>
                                      </Modal.Header>
                                      <Modal.Body>
                                        <PopupTyresData
                                          radioOptions={radioOptionsSpare}
                                          onStateSelect={
                                            handleOptionSelectSpare
                                          }
                                        />
                                      </Modal.Body>
                                      <Modal.Footer
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      ></Modal.Footer>
                                    </Modal>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 ttft ">
                            <label className="yytyr my-3">
                              Seller Description
                            </label>
                            <span
                              type="button"
                              style={{
                                float: "right",
                                color: "blue",
                                marginRight: "2%",
                              }}
                              className="my-3"
                            >
                              <BsStars style={{ outline: "none" }} /> AI
                              Generate
                            </span>
                            <div className="mm bg-input">
                              <textarea
                                type="text"
                                name="seller_description"
                                value={formData.seller_description}
                                onChange={handleChange}
                                onKeyPress={(e) => {
                                  if (e.key === "Enter") {
                                    e.preventDefault();
                                  }
                                }}
                                // style={{border:"none",fontSize: "15", fontWeight: "bolder", letterSpacing: "2px" }}
                                style={{
                                  border: "none",
                                  fontSize: "15px",
                                  fontWeight: "bolder",
                                  letterSpacing: "2px",
                                  textAlign: "left",
                                  color: "black",
                                  padding: "10px",
                                  width: "98%", // Ensure it takes full width of the container
                                  height: "100px", // Adjust the height as needed
                                  resize: "none", // Prevent resizing by the user
                                  overflowWrap: "break-word",
                                  boxSizing: "border-box", // Include padding and border in the element's total width and height
                                  fontFamily: "monospace",
                                  backgroundColor: "#fce8cd",
                                  outline: "none",
                                }}
                              />
                            </div>
                          </div>
                        </section>
                      </Container>
                    </Tab>

                    <Tab eventKey="tab4" title="Add Cost">
                      <div id="refresh-btn-seller">
                        {" "}
                        <button
                          type="button"
                          id="adlst-reset-btn-seller"
                          onClick={handleResetMaintanance}
                          title="Reset"
                        >
                          <img src={Image.reset_button} alt="Reset Button" />
                        </button>
                      </div>
                      <Container>
                        <section className="row">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              backgroundColor: divBackgroundColor,
                            }}
                          >
                            <div
                              className="row"
                              style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                alignItems: "center",
                              }}
                            >
                              <div className="col-md-2">
                                <label className="fw-bold">Date</label>
                                <div
                                  className="inputst mt-2 bg-input"
                                  id="date-r-c"
                                >
                                  <input
                                    type="date"
                                    className="form-control border-0"
                                    name=""
                                    value={inputValueDate}
                                    onChange={(event) =>
                                      setInputValueDate(event.target.value)
                                    }
                                    style={{
                                      height: "42px",
                                      fontSize: "15",
                                      fontWeight: "bolder",
                                      letterSpacing: "2px",
                                    }}
                                  />
                                </div>
                              </div>

                              <div className="col-md-2 mt-2 seller2">
                                <div>
                                  <label className="fw-bold">Category</label>
                                  <div className="input-containerdrpdn input-fields bg-input">
                                    <div
                                      className="col-md-6"
                                      style={{ width: "91%" }}
                                    >
                                      <div className="input-field-year-make bg-input">
                                        <input
                                          type="text"
                                          name="category"
                                          value={selectedOptionv}
                                          onClick={handleArrowClickv}
                                          onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                              e.preventDefault();
                                            }
                                          }}
                                          onChange={(event) =>
                                            setSelectedOptionv(
                                              event.target.value
                                            )
                                          }
                                          id="inputadlst"
                                          style={{
                                            paddingLeft: "8%",
                                            fontSize: "15",
                                            width: "91%",
                                            fontWeight: "bolder",
                                            letterSpacing: "1px",
                                          }}
                                        />
                                        <div className="arrow-adlst">
                                          <FaArrowRight
                                            onClick={handleArrowClickv}
                                          />
                                        </div>
                                      </div>
                                      <Modal
                                        show={showModalv}
                                        onHide={handleCloseModalv}
                                      >
                                        <Modal.Header closeButton>
                                          <Modal.Title>
                                            Choose a Category
                                          </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body style={{ height: "88%" }}>
                                          <PopupData
                                            radioOptions={radioOptionsv}
                                            onStateSelect={handleOptionSelectv}
                                          />
                                        </Modal.Body>
                                        <Modal.Footer
                                          style={{
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          {/* Input field and button for adding a new category */}
                                          <input
                                            type="text"
                                            placeholder="Add Your Category"
                                            value={newOption}
                                            onChange={(e) =>
                                              setNewOption(e.target.value)
                                            }
                                            onKeyPress={(e) => {
                                              if (e.key === "Enter") {
                                                handleAddOption();
                                              }
                                            }}
                                            style={{
                                              marginRight: "1rem",
                                              width: "42%",
                                            }}
                                          />
                                          {/* <button onClick={handleAddOption}>Add</button> */}
                                        </Modal.Footer>
                                      </Modal>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-3 mt-2 seller2">
                                <div>
                                  <label className="fw-bold">Supplier</label>
                                  <div className="input-containerdrpdn input-fields bg-input">
                                    <div
                                      className="col-md-6"
                                      style={{ width: "91%" }}
                                    >
                                      <div className="input-field-year-make bg-input">
                                        <input
                                          type="text"
                                          name="payee_vendor"
                                          value={selectedOptionw}
                                          onClick={handleArrowClickw}
                                          onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                              e.preventDefault();
                                            }
                                          }}
                                          onChange={(event) =>
                                            setSelectedOptionw(
                                              event.target.value
                                            )
                                          }
                                          id="inputadlst"
                                          style={{
                                            paddingLeft: "5%",
                                            fontSize: "15",
                                            width: "91%",
                                            fontWeight: "bolder",
                                            letterSpacing: "2px",
                                          }}
                                        />
                                        <div className="arrow-adlst">
                                          <FaArrowRight
                                            onClick={handleArrowClickw}
                                          />
                                        </div>
                                      </div>
                                      <Modal
                                        show={showModalw}
                                        onHide={handleCloseModalw}
                                      >
                                        <Modal.Header closeButton>
                                          <Modal.Title>
                                            Choose a Supplier
                                          </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body style={{ height: "88%" }}>
                                          <PopupData
                                            radioOptions={radioOptionsw}
                                            onStateSelect={handleOptionSelectw}
                                          />
                                        </Modal.Body>
                                        <Modal.Footer
                                          style={{
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <input
                                            type="text"
                                            placeholder="Add Your Supplier"
                                            value={newOptionsupplier}
                                            onChange={(e) =>
                                              setNewOptionsupplier(
                                                e.target.value
                                              )
                                            }
                                            onKeyPress={(e) => {
                                              if (e.key === "Enter") {
                                                handleAddOptionsupplier();
                                              }
                                            }}
                                            style={{
                                              marginRight: "1rem",
                                              width: "42%",
                                            }}
                                          />
                                          {/* <button onClick={handleAddOptionsupplier}>Add</button> */}
                                        </Modal.Footer>
                                      </Modal>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2 mt-2">
                                <div>
                                  <label className="fw-bold">Amount*</label>
                                </div>
                                <div className="d-flex">
                                  <div className="inputst bg-input input-containerdlr-amount">
                                    <div className="persistent-placeholder">
                                      $
                                    </div>
                                    <input
                                      type="text"
                                      name=""
                                      id="adlstdetialinput"
                                      value={new Intl.NumberFormat().format(
                                        inputValueAmount
                                      )}
                                      onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                          e.preventDefault();
                                        }
                                      }}
                                      onChange={(event) =>
                                        setInputValueAmount(
                                          event.target.value.replace(
                                            /[^0-9.]/g,
                                            ""
                                          )
                                        )
                                      }
                                      style={{
                                        height: "42px",
                                        fontSize: "15",
                                        fontWeight: "bolder",
                                        letterSpacing: "2px",
                                        width: "100%",
                                      }}
                                    />
                                  </div>
                                  <div
                                    className="arrow-adlst"
                                    style={{ top: "8px" }}
                                  >
                                    <FaCalculator
                                      onClick={handleArrowClickCalculator}
                                      style={{ cursor: "pointer" }}
                                    />
                                  </div>
                                </div>
                                <Modal
                                  show={showModalCalculator}
                                  onHide={handleCloseModalCalculator}
                                >
                                  <Modal.Header closeButton style={{backgroundColor:"#d9d9d9"}}>
                                    <Modal.Title>calculator</Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body style={{ height: "88%",backgroundColor:"#d9d9d9" }}>
                                    <AmountInputWithCalculator
                                      setShowModalCalculator={
                                        setShowModalCalculator
                                      }
                                      setInputValueAmount={setInputValueAmount}
                                      statusShowCal={1}
                                    />
                                  </Modal.Body>
                                  <Modal.Footer
                                    style={{ justifyContent: "space-between",backgroundColor:"#d9d9d9" }}
                                  ></Modal.Footer>
                                </Modal>
                              </div>

                              <div className="col-md-2 mt-2 seller2">
                                <div>
                                  {" "}
                                  <label className="fw-bold ">
                                    Payment Method
                                  </label>
                                  <div className="input-containerdrpdn input-fields bg-input">
                                    <div
                                      className="col-md-6"
                                      style={{ width: "91%" }}
                                    >
                                      <div className="input-field-year-make bg-input">
                                        <input
                                          type="text"
                                          name="payment_method"
                                          value={selectedOptionx}
                                          onClick={handleArrowClickx}
                                          onChange={(event) =>
                                            setSelectedOptionx(
                                              event.target.value
                                            )
                                          }
                                          onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                              e.preventDefault();
                                            }
                                          }}
                                          id="inputadlst"
                                          style={{
                                            paddingLeft: "20%",
                                            fontSize: "15",
                                            width: "91%",
                                            fontWeight: "bolder",
                                            letterSpacing: "2px",
                                          }}
                                        />
                                        <div className="arrow-adlst">
                                          <FaArrowRight
                                            onClick={handleArrowClickx}
                                          />
                                        </div>
                                      </div>
                                      <Modal
                                        show={showModalx}
                                        onHide={handleCloseModalx}
                                      >
                                        <Modal.Header closeButton>
                                          <Modal.Title>
                                            Choose a Payment Method
                                          </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body style={{ height: "88%" }}>
                                          <PopupData
                                            radioOptions={radioOptionsx}
                                            onStateSelect={handleOptionSelectx}
                                          />
                                        </Modal.Body>
                                        <Modal.Footer
                                          style={{
                                            justifyContent: "space-between",
                                          }}
                                        ></Modal.Footer>
                                      </Modal>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="col-md-11 mt-4"
                              style={{
                                paddingRight: "0px",
                                marginLeft: "34px",
                                width: "95%",
                              }}
                            >
                              {/* <label className="fw-bold">Description</label> */}
                              <div className="inputst8 mt-2 input-containerdlr-description">
                                <div
                                  className="persistent-placeholder"
                                  style={{
                                    display: inputValueDescription
                                      ? "none"
                                      : "block",
                                  }}
                                >
                                  Description:
                                </div>
                                <input
                                  type="text"
                                  name=""
                                  placeholder=""
                                  value={inputValueDescription}
                                  onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                      e.preventDefault();
                                    }
                                  }}
                                  onChange={(event) =>
                                    setInputValueDescription(event.target.value)
                                  }
                                  style={{
                                    fontSize: "15",
                                    fontWeight: "bolder",
                                    paddingLeft: "8%",
                                    letterSpacing: "2px",
                                    // paddingLeft: "10px",
                                    backgroundColor: "rgba(240, 150, 31, 0.22)",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-8"></div>

                          <div
                            className="d-grid gap-2 d-md-flex justify-content-md-end my-4"
                            id="adlstsaveresetbtn"
                          >
                            {/* <button className="btn btn me-md-2 text-light fw-bold" type="button" id='adlstsaveresetbtnbg' onClick={handleSave}>Save</button> */}
                            {/* <button className="btn btn text-light fw-bold" type="button" style={{ backgroundColor: "#63b82b" }}>Reset</button> */}
                            {isEditing ? (
                              <button
                                className="btn me-md-2 text-light fw-bold btn-change5"
                                type="button"
                                id="adlstsaveresetbtnbg"
                                onClick={handleUpdate}
                              >
                                Update
                              </button>
                            ) : (
                              <button
                                className="btn me-md-2 text-light fw-bold btn-change5"
                                type="button"
                                id="adlstsaveresetbtnbg"
                                onClick={handleSave}
                              >
                                Save
                              </button>
                            )}
                          </div>
                          <hr
                            style={{
                              border: "2px solid black",
                              width: "95%",
                              marginLeft: "3%",
                            }}
                          />
                          {/* ------------------------------table Start------------------------------*/}
                          <div className="col-md-12">
                            <div className="table-responsive">
                              <table className="table table-striped table-hover">
                                <thead>
                                  <tr>
                                    <th
                                      style={{ backgroundColor: "#f5ceb3" }}
                                    ></th>
                                    <th style={{ backgroundColor: "#f5ceb3" }}>
                                      No
                                    </th>
                                    <th style={{ backgroundColor: "#f5ceb3" }}>
                                      Date
                                    </th>
                                    <th style={{ backgroundColor: "#f5ceb3" }}>
                                      Category
                                    </th>
                                    <th style={{ backgroundColor: "#f5ceb3" }}>
                                      Supplier
                                    </th>
                                    <th style={{ backgroundColor: "#f5ceb3" }}>
                                      Payment Method
                                    </th>
                                    <th style={{ backgroundColor: "#f5ceb3" }}>
                                      Description
                                    </th>
                                    <th style={{ backgroundColor: "#f5ceb3" }}>
                                      Amount
                                    </th>
                                  </tr>
                                </thead>
                                {objects.length > 0 ? (
                                  <tbody
                                    style={{
                                      borderBottom: "2px solid #d1823a",
                                    }}
                                  >
                                    {objects.map((row, index) => (
                                      <tr
                                        key={index}
                                        onDoubleClick={() =>
                                          handleRowDoubleClick(index)
                                        }
                                        style={{
                                          backgroundColor:
                                            highlightedRow === index
                                              ? "lightgray"
                                              : "white",
                                        }}
                                      >
                                        <td>
                                          <input
                                            type="checkbox"
                                            checked={selectedRows.includes(
                                              index
                                            )}
                                            onChange={() =>
                                              handleRowSelect(index)
                                            }
                                          />
                                        </td>
                                        <td
                                          style={{
                                            borderRight: "1px solid #d1823a",
                                          }}
                                        >
                                          {index + 1}
                                        </td>
                                        <td
                                          style={{
                                            borderRight: "1px solid #d1823a",
                                          }}
                                        >
                                          {row.date}
                                        </td>
                                        <td
                                          style={{
                                            borderRight: "1px solid #d1823a",
                                          }}
                                        >
                                          {row.category}
                                        </td>
                                        <td
                                          style={{
                                            borderRight: "1px solid #d1823a",
                                          }}
                                        >
                                          {row.payee_vendor}
                                        </td>
                                        <td
                                          style={{
                                            borderRight: "1px solid #d1823a",
                                          }}
                                        >
                                          {row.payment_method}
                                        </td>
                                        <td
                                          style={{
                                            borderRight: "1px solid #d1823a",
                                          }}
                                        >
                                          {row.description}
                                        </td>
                                        <td
                                          style={{
                                            borderRight: "1px solid #d1823a",
                                          }}
                                        >
                                          {row.amount}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                ) : (
                                  <tbody
                                    style={{
                                      borderBottom: "2px solid #d1823a",
                                    }}
                                  >
                                    {Array.from({ length: 6 }, (_, index) => (
                                      <tr
                                        key={index}
                                        style={{ height: "30px" }}
                                      >
                                        <td>
                                          <input type="checkbox" />
                                        </td>
                                        <td
                                          style={{
                                            borderRight: "1px solid #d1823a",
                                          }}
                                        >
                                          {index + 1}
                                        </td>
                                        <td
                                          style={{
                                            borderRight: "1px solid #d1823a",
                                          }}
                                        ></td>
                                        <td
                                          style={{
                                            borderRight: "1px solid #d1823a",
                                          }}
                                        ></td>
                                        <td
                                          style={{
                                            borderRight: "1px solid #d1823a",
                                          }}
                                        ></td>
                                        <td
                                          style={{
                                            borderRight: "1px solid #d1823a",
                                          }}
                                        ></td>
                                        <td
                                          style={{
                                            borderRight: "1px solid #d1823a",
                                          }}
                                        ></td>
                                      </tr>
                                    ))}
                                  </tbody>
                                )}
                              </table>
                              {/* {objectChunks.map((chunk, chunkIndex) => (
                            <table
                              key={chunkIndex}
                              className="table table-striped table-hover"
                              style={{ marginBottom: "20px", fontSize: "20px" }}
                            >
                              <thead>
                                <tr>
                                  <th
                                    style={{ backgroundColor: "#f5ceb3" }}
                                  ></th>
                                  <th
                                    style={{
                                      backgroundColor: "#f5ceb3",
                                      fontSize: "20px",
                                    }}
                                  >
                                    No
                                  </th>
                                  <th
                                    style={{
                                      backgroundColor: "#f5ceb3",
                                      fontSize: "20px",
                                    }}
                                  >
                                    Date
                                  </th>
                                  <th
                                    style={{
                                      backgroundColor: "#f5ceb3",
                                      fontSize: "20px",
                                    }}
                                  >
                                    Category
                                  </th>
                                  <th
                                    style={{
                                      backgroundColor: "#f5ceb3",
                                      fontSize: "20px",
                                    }}
                                  >
                                    Supplier
                                  </th>
                                  <th
                                    style={{
                                      backgroundColor: "#f5ceb3",
                                      fontSize: "20px",
                                    }}
                                  >
                                    Description
                                  </th>
                                  <th
                                    style={{
                                      backgroundColor: "#f5ceb3",
                                      fontSize: "20px",
                                    }}
                                  >
                                    Payment Method
                                  </th>
                                  <th
                                    style={{
                                      backgroundColor: "#f5ceb3",
                                      fontSize: "20px",
                                    }}
                                  >
                                    Amount
                                  </th>
                                </tr>
                              </thead>
                              <tbody
                                style={{ borderBottom: "2px solid #d1823a" }}
                              >
                                {Array.from({ length: chunkSize }).map(
                                  (_, index) => {
                                    const rowIndex =
                                      index + chunkIndex * chunkSize;
                                    const row = objects[rowIndex];
                                    return row ? (
                                      <tr
                                        key={rowIndex}
                                        onDoubleClick={() =>
                                          handleRowDoubleClick(rowIndex)
                                        }
                                        style={{
                                          backgroundColor:
                                            highlightedRow === rowIndex
                                              ? "lightgray"
                                              : "white",
                                          fontSize: "20px",
                                        }}
                                      >
                                        <td
                                          style={{
                                            fontSize: "20px",
                                          }}
                                        >
                                          <input
                                            type="checkbox"
                                            checked={selectedRows.includes(
                                              rowIndex
                                            )}
                                            onChange={() =>
                                              handleRowSelect(rowIndex)
                                            }
                                          />
                                        </td>
                                        <td
                                          style={{
                                            borderRight: "1px solid #d1823a",
                                            fontSize: "20px",
                                          }}
                                        >
                                          {rowIndex + 1}
                                        </td>
                                        <td
                                          style={{
                                            borderRight: "1px solid #d1823a",
                                            fontFamily: "900px",
                                            fontSize: "20px",
                                          }}
                                        >
                                          {row.date}
                                        </td>
                                        <td
                                          style={{
                                            borderRight: "1px solid #d1823a",
                                            fontFamily: "900px",
                                            fontSize: "20px",
                                          }}
                                        >
                                          {row.category}
                                        </td>
                                        <td
                                          style={{
                                            borderRight: "1px solid #d1823a",
                                            fontFamily: "900px",
                                            fontSize: "20px",
                                          }}
                                        >
                                          {row.payee_vendor}
                                        </td>
                                        <td
                                          style={{
                                            borderRight: "1px solid #d1823a",
                                            fontFamily: "900px",
                                            fontSize: "20px",
                                          }}
                                        >
                                          {row.description}
                                        </td>
                                        <td
                                          style={{
                                            borderRight: "1px solid #d1823a",
                                            fontFamily: "900px",
                                            fontSize: "20px",
                                          }}
                                        >
                                          {row.payment_method}
                                        </td>
                                        <td
                                          style={{
                                            borderRight: "1px solid #d1823a",
                                            fontFamily: "900px",
                                            fontSize: "20px",
                                          }}
                                        >
                                          {new Intl.NumberFormat().format(
                                            row.amount
                                          )}
                                        </td>
                                      </tr>
                                    ) : (
                                      <tr
                                        key={rowIndex}
                                        style={{ height: "30px" }}
                                      >
                                        <td>
                                          <input type="checkbox" />
                                        </td>
                                        <td
                                          style={{
                                            borderRight: "1px solid #d1823a",
                                          }}
                                        >
                                          {rowIndex + 1}
                                        </td>
                                        <td
                                          style={{
                                            borderRight: "1px solid #d1823a",
                                          }}
                                        ></td>
                                        <td
                                          style={{
                                            borderRight: "1px solid #d1823a",
                                          }}
                                        ></td>
                                        <td
                                          style={{
                                            borderRight: "1px solid #d1823a",
                                          }}
                                        ></td>
                                        <td
                                          style={{
                                            borderRight: "1px solid #d1823a",
                                          }}
                                        ></td>
                                        <td
                                          style={{
                                            borderRight: "1px solid #d1823a",
                                          }}
                                        ></td>
                                        <td
                                          colSpan="6"
                                          style={{
                                            borderRight: "1px solid #d1823a",
                                          }}
                                        ></td>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          ))} */}
                            </div>
                          </div>
                          <div
                            className="d-flex mt-3"
                            style={{ justifyContent: "space-between" }}
                          >
                            <button
                              className="btn btn-danger me-md-2 btn-change5"
                              type="button"
                              id="adlstdltbtnrpcst"
                              onClick={handleDelete}
                            >
                              Delete
                            </button>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                flexDirection: "row",
                              }}
                            >
                              <div id="total-repare-footer">
                                <h4 className="me-3 ">Total</h4>
                                <span
                                  className="fw-bold  me-3"
                                  style={{ fontSize: "20px" }}
                                >
                                  $
                                  {new Intl.NumberFormat().format(
                                    totalAmount.toFixed(2)
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              backgroundColor: "#fce8cd",
                              width: "50%",
                              marginLeft: "14px",
                              marginTop: "40px",
                            }}
                          ></div>
                        </section>
                        {/* <button type="button" style={{width:"10%"}} onClick={handleResetMaintanance}>Reset</button> */}
                      </Container>
                    </Tab>

                    <Tab eventKey="tab5" title="Title Info">
                      {" "}
                      {/*------------------------Title Details-----------------------------*/}
                      {/* <button type="button" style={{width:"10%"}} onClick={handleResetTitleInfo}>Reset</button> */}
                      <div id="refresh-btn-seller">
                        {" "}
                        <button
                          type="button"
                          id="adlst-reset-btn-seller"
                          onClick={handleResetTitleInfo}
                          title="Reset"
                        >
                          <img src={Image.reset_button} alt="Reset Button" />
                        </button>
                      </div>
                      <div className="container">
                        <div className="row">
                          <div className=" col-md-5 col-lg-6 col-sm-12 mx-auto">
                            <section className="row">
                              <div className="col-sm-10">
                                <div className="mt-3 optSlect">
                                  <div className="input-containerdrpdn input-containerdrpdn-inline bg-input">
                                    <label className="fw-bold label-inline">
                                      Title State
                                    </label>

                                    <div
                                      className="col-md-6"
                                      style={{
                                        width: "71%",
                                        marginTop: "12px",
                                      }}
                                    >
                                      <div className="input-field-year-make bg-input">
                                        <input
                                          type="text"
                                          name="state"
                                          value={formData.state}
                                          onClick={handleArrowClickj}
                                          onChange={handleChange}
                                          onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                              e.preventDefault();
                                            }
                                          }}
                                          id="inputadlst"
                                          style={{
                                            paddingLeft: "20%",
                                            fontSize: "15",
                                            width: "89%",
                                            fontWeight: "bolder",
                                            letterSpacing: "2px",
                                          }}
                                        />
                                        <div className="arrow-adlst">
                                          <FaArrowRight
                                            onClick={handleArrowClickj}
                                          />
                                        </div>
                                      </div>
                                      <Modal
                                        show={showModalj}
                                        onHide={handleCloseModalj}
                                      >
                                        <Modal.Header closeButton>
                                          <Modal.Title>
                                            Choose a state
                                          </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body
                                          style={{
                                            height: "355px",
                                          }}
                                        >
                                          <StateSelector
                                            onStateSelect={handleOptionSelectj}
                                          />
                                        </Modal.Body>
                                        <Modal.Footer
                                          style={{
                                            justifyContent: "space-between",
                                          }}
                                        ></Modal.Footer>
                                      </Modal>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-sm-10">
                                <div className="mt-5 optSlect">
                                  <div className="input-containerdrpdn input-containerdrpdn-inline bg-input">
                                    <label className="fw-bold label-inline">
                                      Title Type
                                    </label>
                                    <div
                                      className="col-md-6"
                                      style={{
                                        width: "71%",
                                        marginTop: "12px",
                                      }}
                                    >
                                      <div className="input-field-year-make bg-input">
                                        <input
                                          type="text"
                                          name="title_type"
                                          value={formData.title_status}
                                          onChange={handleChange}
                                          onClick={handleArrowClickk}
                                          onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                              e.preventDefault();
                                            }
                                          }}
                                          id="inputadlst"
                                          style={{
                                            paddingLeft: "20%",
                                            fontSize: "15",
                                            width: "89%",
                                            fontWeight: "bolder",
                                            letterSpacing: "2px",
                                          }}
                                        />
                                        <div className="arrow-adlst">
                                          <FaArrowRight
                                            onClick={handleArrowClickk}
                                          />
                                        </div>
                                      </div>
                                      <Modal
                                        show={showModaly}
                                        onHide={handleCloseModalk}
                                      >
                                        <Modal.Header closeButton>
                                          <Modal.Title>
                                            Choose a Title Type
                                          </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body style={{ height: "88%" }}>
                                          <PopupData
                                            radioOptions={radioOptionsk}
                                            onStateSelect={handleOptionSelectk}
                                          />
                                        </Modal.Body>
                                        <Modal.Footer
                                          style={{
                                            justifyContent: "space-between",
                                          }}
                                        ></Modal.Footer>
                                      </Modal>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-sm-10">
                                <div className="mt-5 optSlect">
                                  <div className="input-containerdrpdn input-containerdrpdn-inline bg-input">
                                    <label
                                      className="fw-bold label-inline"
                                      style={{ marginRight: "2px" }}
                                    >
                                      Title Number
                                    </label>
                                    <div
                                      style={{
                                        width: "100%",
                                        marginTop: "0px",
                                      }}
                                    >
                                      <input
                                        type="text"
                                        name="title_number"
                                        value={formData.title_number}
                                        placeholder=""
                                        onChange={handleChange}
                                        onKeyPress={(e) => {
                                          if (e.key === "Enter") {
                                            e.preventDefault();
                                          }
                                        }}
                                        className=""
                                        style={{
                                          width: "98%",
                                          paddingLeft: "20%",
                                          borderBottom: "none",
                                          height: "41px",
                                          border: "none",
                                          fontSize: "15",
                                          fontWeight: "bolder",
                                          letterSpacing: "2px",
                                          outline: "none",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-sm-10">
                                <div className=" mt-5 optSlect">
                                  <div className="input-containerdrpdn input-containerdrpdn-inline bg-input">
                                    <label className="fw-bold label-inline">
                                      Title Received
                                    </label>
                                    <div
                                      className="col-md-6"
                                      style={{
                                        width: "71%",
                                        marginTop: "12px",
                                      }}
                                    >
                                      <div className="input-field-year-make bg-input">
                                        <input
                                          type="text"
                                          name="title_recieved"
                                          value={formData.title_recieved}
                                          onChange={handleChange}
                                          onClick={handleArrowClickz}
                                          onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                              e.preventDefault();
                                            }
                                          }}
                                          id="inputadlst"
                                          style={{
                                            paddingLeft: "20%",
                                            fontSize: "15",
                                            width: "89%",
                                            fontWeight: "bolder",
                                            letterSpacing: "2px",
                                          }}
                                        />
                                        <div className="arrow-adlst">
                                          <FaArrowRight
                                            onClick={handleArrowClickz}
                                          />
                                        </div>
                                      </div>
                                      <Modal
                                        show={showModalz}
                                        onHide={handleCloseModalz}
                                      >
                                        <Modal.Header closeButton>
                                          <Modal.Title>
                                            Choose a Title Received
                                          </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body style={{ height: "88%" }}>
                                          <PopupData
                                            radioOptions={radioOptionsz}
                                            onStateSelect={handleOptionSelectz}
                                          />
                                        </Modal.Body>
                                        <Modal.Footer
                                          style={{
                                            justifyContent: "space-between",
                                          }}
                                        ></Modal.Footer>
                                      </Modal>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-sm-10">
                                <div className="mb-5 mt-5 optSlect">
                                  <div className="input-containerdrpdn input-containerdrpdn-inline bg-input">
                                    <label className="fw-bold label-inline">
                                      Title Location
                                    </label>

                                    <div
                                      className="col-md-6"
                                      style={{
                                        width: "71%",
                                        marginTop: "12px",
                                      }}
                                    >
                                      <div className="input-field-year-make bg-input">
                                        <input
                                          type="text"
                                          name="title_location"
                                          value={formData.title_location}
                                          onChange={handleChange}
                                          onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                              e.preventDefault();
                                            }
                                          }}
                                          id="inputadlst"
                                          style={{
                                            paddingLeft: "20%",
                                            fontSize: "15",
                                            width: "98%",
                                            fontWeight: "bolder",
                                            letterSpacing: "2px",
                                          }}
                                        />
                                      </div>
                                      <Modal
                                        show={showModalza}
                                        onHide={handleCloseModalza}
                                      >
                                        <Modal.Header closeButton>
                                          <Modal.Title>
                                            Choose a Title Location
                                          </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body
                                          style={{
                                            maxHeight: "50vh",
                                            overflowY: "auto",
                                          }}
                                        >
                                          {radioOptionsza.map(
                                            (option, index) => (
                                              <div key={index}>
                                                <label>
                                                  <input
                                                    type="radio"
                                                    name="title_location_option"
                                                    value={option.value}
                                                    style={{
                                                      marginRight: "1rem",
                                                    }}
                                                    onChange={() =>
                                                      handleOptionSelectza(
                                                        option.value
                                                      )
                                                    }
                                                  />
                                                  {option.label}
                                                </label>
                                              </div>
                                            )
                                          )}
                                        </Modal.Body>
                                        <Modal.Footer
                                          style={{
                                            justifyContent: "space-between",
                                          }}
                                        ></Modal.Footer>
                                      </Modal>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>
                          <div className=" col-md-5 col-lg-6 col-sm-12 mx-auto">
                            <div
                              className="d-flex justify-content-around"
                              id="flex-container"
                              style={{ height: "500px" }}
                            >
                              <div
                                className="vr border-0"
                                style={{
                                  backgroundColor: "#ff9204",
                                  width: "2%",
                                  height: "90%",
                                }}
                              ></div>
                              <div className="ms-3 mt-5">
                                <input
                                  type="file"
                                  id="titleFileInput"
                                  style={{ display: "none" }}
                                  multiple
                                  onChange={handleFileChange}
                                  ref={titleFileInputRef}
                                  accept=".jpg,.jpeg,.png,.pdf,.docx"
                                />

                                <div
                                  className="file-upload-content"
                                  onClick={handleButtonClick}
                                  style={{
                                    border: "2px dotted #d1823a",
                                    width: "227px",
                                    height: "292px",
                                  }}
                                >
                                  {files.length === 0 &&
                                    titleOthersFiles.length === 0 && (
                                      <div className="file-description">
                                        <i className="fas fa-cloud-upload-alt"></i>
                                        <h5>Title & Others</h5>
                                      </div>
                                    )}
                                  {(files.length > 0 ||
                                    titleOthersFiles.length > 0) && (
                                    <div style={{ position: "relative" }}>
                                      <Carousel
                                        key={carouselKey} // Add the carouselKey to force re-render
                                        showThumbs={false}
                                        showStatus={false}
                                        showArrows={false}
                                        infiniteLoop={true}
                                        autoPlay={true} // Enable auto-play
                                        interval={3000} // Set interval to 3 seconds
                                        stopOnHover={true} // Optional: Stop auto-play on hover
                                        swipeable={true} // Optional: Allow swiping to navigate slides
                                        dynamicHeight={false} //
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        {/* Highlighted block: Render title_others files */}
                                        {titleOthersFiles.map((file, index) => (
                                          <div key={index}>
                                            <button
                                              type="button"
                                              className="btn btn-sm"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleDeleteTitleOthersFile(
                                                  index,
                                                  file.name
                                                ); // Highlighted change
                                              }}
                                              style={{
                                                position: "absolute",
                                                top: "5px",
                                                right: "0",
                                              }}
                                            >
                                              <i className="fas fa-trash-alt"></i>
                                            </button>
                                            {file.name.endsWith(".pdf") ? (
                                              <embed
                                                src={file.url}
                                                type="application/pdf"
                                                width="200"
                                                height="287"
                                              />
                                            ) : (
                                              <img
                                                src={file.url}
                                                alt="uploaded"
                                                style={{
                                                  width: "200px",
                                                  height: "287px",
                                                  zIndex: "9999",
                                                }}
                                                onClick={handleButtonClick}
                                              />
                                            )}
                                          </div>
                                        ))}
                                        {/* Highlighted block: Render newly uploaded files */}
                                        {files.map((file, index) => (
                                          <div key={index}>
                                            <button
                                              type="button"
                                              className="btn btn-sm"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleDeleteFile(index); // Highlighted change
                                              }}
                                              style={{
                                                position: "absolute",
                                                top: "5px",
                                                right: "0",
                                              }}
                                            >
                                              <i className="fas fa-trash-alt"></i>
                                            </button>
                                            {file.type === "application/pdf" ? (
                                              <embed
                                                src={URL.createObjectURL(file)}
                                                type="application/pdf"
                                                width="200"
                                                height="287"
                                              />
                                            ) : file.type ===
                                              "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                                              <embed
                                                src={URL.createObjectURL(file)}
                                                type="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                width="200"
                                                height="287"
                                              />
                                            ) : (
                                              <img
                                                src={URL.createObjectURL(file)}
                                                alt="uploaded"
                                                style={{
                                                  width: "200px",
                                                  height: "287px",
                                                  zIndex: "9999",
                                                }}
                                                onClick={handleButtonClick}
                                              />
                                            )}
                                          </div>
                                        ))}
                                      </Carousel>
                                    </div>
                                  )}
                                </div>
                                <div
                                  className="text-center mt-5 pl-3"
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    backgroundColor: "#d1823a",
                                    width: "100%",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <button
                                    type="button"
                                    className="btn d-block text-light btns-orange"
                                    style={{ width: "100%" }}
                                    id="adlsttitlebtn"
                                  >
                                    Title & Others (
                                    {files.length + titleOthersFiles.length})
                                  </button>
                                </div>
                              </div>
                              <div className="ms-3 mt-5">
                                <input
                                  type="file"
                                  id="billSaleFileInput"
                                  style={{ display: "none" }}
                                  multiple
                                  onChange={handleFileChangeb}
                                  accept=".jpg,.jpeg,.png,.pdf,.docx"
                                  ref={billSaleFileInputRef} // Use bill sale ref
                                />

                                <div
                                  className="file-upload-content"
                                  onClick={handleButtonClickb}
                                  style={{
                                    border: "2px dotted #d1823a",
                                    width: "227px",
                                    height: "292px",
                                  }}
                                >
                                  {filesb.length === 0 &&
                                    billSaleFiles.length === 0 && (
                                      <div className="file-description">
                                        <i className="fas fa-cloud-upload-alt"></i>
                                        <h5>Bill Of Sale</h5>
                                      </div>
                                    )}
                                  {(filesb.length > 0 ||
                                    billSaleFiles.length > 0) && (
                                    <div style={{ position: "relative" }}>
                                      <Carousel
                                        key={carouselKeyb} // Add the carouselKey to force re-render
                                        showThumbs={false}
                                        showStatus={false}
                                        showArrows={false}
                                        infiniteLoop={true}
                                        autoPlay={true} // Enable auto-play
                                        interval={3000} // Set interval to 3 seconds
                                        stopOnHover={true} // Optional: Stop auto-play on hover
                                        swipeable={true} // Optional: Allow swiping to navigate slides
                                        dynamicHeight={false} //
                                      >
                                        {/* Highlighted block: Render bill_sale files */}
                                        {billSaleFiles.map((file, index) => (
                                          <div key={index}>
                                            <button
                                              type="button"
                                              className="btn btn-sm"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleDeleteFileb(
                                                  index,
                                                  file.name
                                                ); // Highlighted change
                                              }}
                                              style={{
                                                position: "absolute",
                                                top: "5px",
                                                right: "0",
                                              }}
                                            >
                                              <i className="fas fa-trash-alt"></i>
                                            </button>
                                            {file.name.endsWith(".pdf") ? (
                                              <embed
                                                src={file.url}
                                                type="application/pdf"
                                                width="200"
                                                height="287"
                                              />
                                            ) : (
                                              <img
                                                src={file.url}
                                                alt="uploaded"
                                                style={{
                                                  width: "200px",
                                                  height: "287px",
                                                }}
                                              />
                                            )}
                                          </div>
                                        ))}
                                        {/* Highlighted block: Render newly uploaded files */}
                                        {filesb.map((file, index) => (
                                          <div key={index}>
                                            <button
                                              type="button"
                                              className="btn btn-sm"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleDeleteFilebb(index);
                                              }}
                                              style={{
                                                position: "absolute",
                                                top: "5px",
                                                right: "0",
                                              }}
                                            >
                                              <i className="fas fa-trash-alt"></i>
                                            </button>
                                            {file.type === "application/pdf" ? (
                                              <embed
                                                src={URL.createObjectURL(file)}
                                                type="application/pdf"
                                                width="200"
                                                height="287"
                                              />
                                            ) : file.type ===
                                              "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                                              <embed
                                                src={URL.createObjectURL(file)}
                                                type="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                width="200"
                                                height="287"
                                              />
                                            ) : (
                                              <img
                                                src={URL.createObjectURL(file)}
                                                alt="uploaded"
                                                style={{
                                                  width: "200px",
                                                  height: "287px",
                                                }}
                                              />
                                            )}
                                          </div>
                                        ))}
                                      </Carousel>
                                    </div>
                                  )}
                                </div>
                                <div
                                  className="text-center mt-5 pl-3"
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    backgroundColor: "#d1823a",
                                    width: "100%",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <button
                                    type="button"
                                    className="btn d-block text-light btns-orange"
                                    style={{ width: "100%" }}
                                    id="adlsttitlebtn"
                                  >
                                    Bill Of Sale (
                                    {filesb.length + billSaleFiles.length})
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>

                    {/* -----------------------------Prices & Fees Start-------------------------------- */}

                    <Tab eventKey="tab6" title="Finance">
                      <div id="refresh-btn-seller">
                        {" "}
                        <button
                          type="button"
                          id="adlst-reset-btn-seller"
                          onClick={handleResetFinance}
                          title="Reset"
                        >
                          <img src={Image.reset_button} alt="Reset Button" />
                        </button>
                      </div>
                      <Container>
                        <section className="row">
                          <div className="col-md-6 d-flex align-items-center bg-input">
                            <label className="fw-bold label-inline">
                              Payment Date
                            </label>
                            <div
                              className="inputst mt-2 "
                              style={{ width: "34%" }}
                            >
                              <input
                                type="date"
                                name="price_fee_date"
                                value={formData.price_fee_date}
                                id="adlstdetialinput"
                                onChange={handleChange}
                                className="border-0"
                                style={{
                                  width: "100%",
                                  height: "42px",
                                  fontSize: "15",
                                  fontWeight: "bolder",
                                  letterSpacing: "2px",
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-6 d-flex align-items-center bg-input">
                            <label className="fw-bold label-inline">
                              Market Value Price
                            </label>
                            <div className="inputst input-containerdlr">
                              <div className="persistent-placeholder">$</div>
                              <input
                                type="text"
                                name="market_value_price"
                                value={displayValues.market_value_price}
                                id="adlstdetialinput"
                                onChange={handleChangeb}
                                onKeyPress={(e) => {
                                  if (e.key === "Enter") {
                                    e.preventDefault();
                                  }
                                }}
                                style={{
                                  height: "42px",
                                  fontSize: "15",
                                  fontWeight: "bolder",
                                  letterSpacing: "2px",
                                  width: "98%",
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-6 ">
                            <label className="fw-bold label-inline">
                              Payment Method
                            </label>
                            {/* </div> */}
                            <div className="inputst input-containerdlr input-containerdrpdn input-fields bg-input">
                              <div
                                className="col-md-6"
                                style={{ width: "98%" }}
                              >
                                <div className="input-field-year-make bg-input">
                                  <input
                                    type="text"
                                    name="payment_method"
                                    value={selectedOptionx}
                                    onClick={handleArrowClickx}
                                    onChange={(event) =>
                                      setSelectedOptionx(event.target.value)
                                    }
                                    onKeyPress={(e) => {
                                      if (e.key === "Enter") {
                                        e.preventDefault();
                                      }
                                    }}
                                    id="inputadlst"
                                    style={{
                                      paddingLeft: "20%",
                                      fontSize: "15",
                                      width: "91%",
                                      fontWeight: "bolder",
                                      letterSpacing: "2px",
                                    }}
                                  />

                                  <div className="arrow-adlst">
                                    <FaArrowRight onClick={handleArrowClickx} />
                                  </div>
                                </div>
                                <Modal
                                  show={showModalx}
                                  onHide={handleCloseModalx}
                                >
                                  <Modal.Header closeButton>
                                    <Modal.Title>
                                      Choose a Payment Method
                                    </Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body style={{ height: "88%" }}>
                                    <PopupData
                                      radioOptions={radioOptionsx}
                                      onStateSelect={handleOptionSelectx}
                                    />
                                  </Modal.Body>
                                  <Modal.Footer
                                    style={{ justifyContent: "space-between" }}
                                  ></Modal.Footer>
                                </Modal>
                              </div>
                              {/* </div> */}
                            </div>
                          </div>

                          <div className="col-md-6 d-flex align-items-center bg-input">
                            <label className="fw-bold label-inline">
                              Listing Price
                            </label>
                            <div className="inputst input-containerdlr">
                              <div className="persistent-placeholder">$</div>
                              <input
                                type="text"
                                name="listing_price"
                                value={displayValues.listing_price}
                                id="adlstdetialinput"
                                onChange={handleChangeb}
                                onKeyPress={(e) => {
                                  if (e.key === "Enter") {
                                    e.preventDefault();
                                  }
                                }}
                                className="bg-orange"
                                style={{
                                  backgroundColor: "#fce8cd",
                                  height: "42px",
                                  fontSize: "15",
                                  fontWeight: "bolder",
                                  letterSpacing: "2px",
                                  width: "98%",
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-md-6 d-flex align-items-center bg-input">
                            <label className="fw-bold label-inline">
                              {" "}
                              Purchase Price
                            </label>

                            <div className="inputst input-containerdlr">
                              <div className="persistent-placeholder">$</div>
                              <input
                                type="text"
                                name="purchase_price"
                                value={displayValues.purchase_price}
                                id="adlstdetialinput"
                                placeholder="0"
                                onChange={handleChangeb}
                                onKeyPress={(e) => {
                                  if (e.key === "Enter") {
                                    e.preventDefault();
                                  }
                                }}
                                style={{
                                  height: "42px",
                                  width: "98%",
                                  fontSize: "15",
                                  fontWeight: "bolder",
                                  letterSpacing: "2px",
                                  paddingRight: "35px",
                                }}
                                ref={inputRef}
                              />
                              {!isPasswordVisible && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "8px",
                                    left: "21px",
                                    width: "70%",
                                    height: "70%",
                                    lineHeight: "41px",
                                    fontSize: "15px",
                                    fontWeight: "bolder",
                                    letterSpacing: "2px",
                                    color: "#000",
                                    backgroundColor: "#fce8cd",
                                    zIndex: 2,
                                    pointerEvents: "none",
                                    // paddingRight: "30px",
                                    // marginLeft:"20px",
                                    borderRadius: "5px",
                                    overflowX: "hidden",
                                  }}
                                >
                                  {getMaskedValue(displayValues.purchase_price)}
                                </div>
                              )}
                              <span
                                style={{
                                  marginLeft: "-30px",
                                  cursor: "pointer",
                                }}
                                onMouseDown={handleMouseDown} // Show password on mouse down
                                onMouseUp={handleMouseUp} // Hide password on mouse up
                                onMouseLeave={handleMouseUp} // Hide password if mouse leaves the icon
                              >
                                {isPasswordVisible ? (
                                  <MdOutlineRemoveRedEye
                                    style={{ width: "25px", height: "25px" }}
                                  />
                                ) : (
                                  <MdOutlineVisibilityOff
                                    style={{ width: "25px", height: "25px" }}
                                  />
                                )}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-6 d-flex align-items-center bg-input">
                            <label className="fw-bold label-inline">
                              Est. Profit
                            </label>
                            <div className="inputst input-containerdlr">
                              <div className="persistent-placeholder">$</div>
                              <input
                                type="text"
                                name="profit_price"
                                value={calculateProfit().toFixed(2)}
                                id="adlstdetialinput"
                                onChange={handleChangeb}
                                onKeyPress={(e) => {
                                  if (e.key === "Enter") {
                                    e.preventDefault();
                                  }
                                }}
                                style={{
                                  height: "42px",
                                  width: "98%",
                                  fontSize: "15",
                                  fontWeight: "bolder",
                                  letterSpacing: "2px",
                                  paddingRight: "35px",
                                }}
                                ref={inputRefb}
                              />
                              {!isPasswordVisibleb && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "8px",
                                    left: "21px",
                                    width: "70%",
                                    height: "70%",
                                    lineHeight: "41px",
                                    fontSize: "15px",
                                    fontWeight: "bolder",
                                    letterSpacing: "2px",
                                    color: "#000",
                                    backgroundColor: "#fce8cd",
                                    zIndex: 2,
                                    pointerEvents: "none",
                                    paddingRight: "30px",
                                    // marginLeft:"20px",
                                    borderRadius: "5px",
                                    overflowX: "hidden",
                                  }}
                                >
                                  {getMaskedValue(calculateProfit().toFixed(2))}
                                </div>
                              )}
                              <span
                                style={{
                                  marginLeft: "-30px",
                                  cursor: "pointer",
                                }}
                                onMouseDown={handleMouseDownb} // Show password on mouse down
                                onMouseUp={handleMouseUpb} // Hide password on mouse up
                                onMouseLeave={handleMouseUpb} // Hide password if mouse leaves the icon
                              >
                                {isPasswordVisibleb ? (
                                  <MdOutlineRemoveRedEye
                                    style={{ width: "25px", height: "25px" }}
                                  />
                                ) : (
                                  <MdOutlineVisibilityOff
                                    style={{ width: "25px", height: "25px" }}
                                  />
                                )}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-6 d-flex align-items-center bg-input">
                            <label className="fw-bold label-inline">
                              Total Repair
                            </label>
                            <div className="inputst input-containerdlr">
                              <div className="persistent-placeholder">$</div>
                              <input
                                type="text"
                                name="total_repair_cost"
                                value={totalAmount.toFixed(2)}
                                id="adlstdetialinput"
                                onChange={handleChangeb}
                                onKeyPress={(e) => {
                                  if (e.key === "Enter") {
                                    e.preventDefault();
                                  }
                                }}
                                style={{
                                  height: "42px",
                                  fontSize: "15",
                                  fontWeight: "bolder",
                                  letterSpacing: "2px",
                                  width: "98%",
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-6 d-flex align-items-center bg-input"></div>
                          <div className="col-md-6 d-flex align-items-center bg-input">
                            <label className="fw-bold label-inline">
                              Others
                            </label>
                            <div className="inputst input-containerdlr">
                              <div className="persistent-placeholder">$</div>
                              <input
                                type="text"
                                name="others"
                                value={displayValues.others}
                                id="adlstdetialinput"
                                onChange={handleChangeb}
                                onKeyPress={(e) => {
                                  if (e.key === "Enter") {
                                    e.preventDefault();
                                  }
                                }}
                                style={{
                                  height: "42px",
                                  fontSize: "15",
                                  fontWeight: "bolder",
                                  letterSpacing: "2px",
                                  width: "98%",
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-md-6 d-flex align-items-center bg-input"></div>

                          <div className="col-md-6 d-flex align-items-center bg-input">
                            <label className="fw-bold label-inline">
                              Total Cost
                            </label>
                            <div className="inputst input-containerdlr">
                              <div className="persistent-placeholder">$</div>
                              <input
                                type="text"
                                name="total_cost"
                                value={calculateTotal()}
                                id="adlstdetialinput"
                                onChange={handleChangeb}
                                onKeyPress={(e) => {
                                  if (e.key === "Enter") {
                                    e.preventDefault();
                                  }
                                }}
                                style={{
                                  height: "42px",
                                  width: "98%",
                                  fontSize: "15",
                                  fontWeight: "bolder",
                                  letterSpacing: "2px",
                                }}
                                ref={inputRefc}
                              />
                              {!isPasswordVisiblec && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "15px",
                                    left: "21px",
                                    maxWidth: "78%",
                                    fontSize: "15px",
                                    fontWeight: "bolder",
                                    letterSpacing: "2px",
                                    color: "#000",
                                    backgroundColor: "#fce8cd",
                                    zIndex: 2,
                                    pointerEvents: "none",
                                    // paddingRight: "30px",
                                    // marginLeft:"20px",
                                    borderRadius: "5px",
                                    overflowX: "hidden",
                                  }}
                                >
                                  {getMaskedValue(calculateTotal())}
                                </div>
                              )}
                              <span
                                style={{
                                  marginLeft: "-36px",
                                  cursor: "pointer",
                                }}
                                onMouseDown={handleMouseDownc} // Show password on mouse down
                                onMouseUp={handleMouseUpc} // Hide password on mouse up
                                onMouseLeave={handleMouseUpc} // Hide password if mouse leaves the icon
                              >
                                {isPasswordVisiblec ? (
                                  <MdOutlineRemoveRedEye
                                    style={{ width: "25px", height: "25px" }}
                                  />
                                ) : (
                                  <MdOutlineVisibilityOff
                                    style={{ width: "25px", height: "25px" }}
                                  />
                                )}
                              </span>
                            </div>
                          </div>
                        </section>
                        {/* <button type="button" style={{width:"10%",marginTop:"5%"}} onClick={handleResetFinance}>Reset</button> */}
                      </Container>
                    </Tab>
                  </Tabs>
                </div>
                {/*-------------------one input filed Start-------------------------*/}

                {/*---------------------full page end-----------------------------------*/}
                {/* 3circle Tool manager import inventry autocheck */}
                {/* <div className="circle-tm-ii-ac">
              <div className="circle-tia">
                Tool<p>Manager</p>{" "}
              </div>
              <span
                style={{
                  fontWeight: "bolder",
                  fontSize: "41px",
                  height: "80px",
                }}
              >
                .
              </span>
              <span
                style={{
                  fontWeight: "bolder",
                  fontSize: "41px",
                  height: "80px",
                }}
              >
                .
              </span>
              <div className="circle-tia">Import Inventry</div>
              <span
                style={{
                  fontWeight: "bolder",
                  fontSize: "41px",
                  height: "80px",
                }}
              >
                .
              </span>
              <span
                style={{
                  fontWeight: "bolder",
                  fontSize: "41px",
                  height: "80px",
                }}
              >
                .
              </span>
              <div className="circle-tia">
                <div onClick={handleLinkClickPreview}>Preview</div>
                <Preview
                  show={showModalPreview}
                  handleClose={handleModalClosePreview}
                  formData={formData}
                />
              </div>
            </div> */}
                {/* 3circle Tool manager import inventry autocheck end*/}
              </form>
              {/* <Tabsp /> */}
            </div>
            <ToastContainer />
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default DealerAddInventoryNav;

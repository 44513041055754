import React, { useState } from "react";
import PaymentStage1 from "./components/PaymentStage1";
import PaymentStage2 from "./components/PaymentStage2";
import PaymentStage3 from "./components/PaymentStage3";
import { useLocation } from "react-router-dom";

const DealerBalancePayment = () => {
  const [paymentStages, setPaymentStages] = useState("stage1");

  const dataFromLocation = useLocation();

  return (
    <>
      {paymentStages === "stage1" && (
        <PaymentStage1
          setPaymentStages={setPaymentStages}
          dataFromLocation={dataFromLocation}
        />
      )}
      {paymentStages === "stage2" && (
        <PaymentStage2
          setPaymentStages={setPaymentStages}
          dataFromLocation={dataFromLocation}
        />
      )}
      {paymentStages === "stage3" && (
        <PaymentStage3
          setPaymentStages={setPaymentStages}
          dataFromLocation={dataFromLocation}
        />
      )}
    </>
  );
};

export default DealerBalancePayment;

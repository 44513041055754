import { React } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import './App.css';
import MyProfile from "./components/dashboard/MyProfile";
// import Navbar from './components/navbars/Navbar';

import Home from "./components/home/Home";
import ProductListing from "./components/productsListing/ProductListing";
import CarDetails from "./components/cardetails/CarDetails";
// import AddListing from './components/addlisting/AddListing';
import Bought from "./components/dashboard/boughtSold/Bought";
import ActiveInventory from "./components/dashboard/activeInventory/ActiveInventory";
import ViewHistory from "./components/dashboard/viewHistory/ViewHistory";
import SaveSearch from "./components/dashboard/saveSearch/SaveSearch";
import CustomerDetails from "./components/dashboard/CustomerDetails";
import AddList from "./components/dashboard/addListing/AddList";
import AccountVerification from "./components/AccountVerification/AccountVerification";
import RecycleBin from "./components/dashboard/Recycle bin/RecycleBin";
import CompareVehicle from "./components/dashboard/compareVehicle/CompareVehicle";
import Draft from "./components/dashboard/DraftPage/Draft";
import Button from "./components/dashboard/dashboardButton/Button";
import FavCartPage from "./components/dashboard/myfavourites/FavCartPage";
import Sell from "./components/dashboard/sell/Sell";
import UseCustomInActivity from "./components/dashboard/useCustomInActivity";
import TermsCondition from "./components/TermsCondition";
import PrivacyPolicy from "./components/PrivacyPolicy";
// import VehicleList from './VehicleList';
//import Test from './components/Test';
import DealerDashBoard from "./components/DealerSection/DealerDashBoard/DealerDashBoard.jsx";
import DealerLayout from "./components/DealerSection/DealerLayout/DealerLayout.jsx";
import UserProtectedRoutes from "./components/UserAuthDialogue/Pages/UserProtectedRoutes.jsx";
import DealerProtectedRoutes from "./components/DealerAuthSection/Pages/DealerProtectedRoutes.jsx";
import Dealer_Auth_Layout from "./components/DealerAuthSection/Pages/Dealer_Auth_Layout.jsx";
import Dealer_Login_feature from "./components/DealerAuthSection/Pages/Dealer_Login_feature.jsx";
import Dealer_Input_Login_feature from "./components/DealerAuthSection/Pages/Dealer_Input_Login_feature.jsx";
import Dealer_Device_Verify_feature from "./components/DealerAuthSection/Pages/Dealer_Device_Verify_feature.jsx";
import Dealer_OTP_Feature from "./components/DealerAuthSection/Pages/Dealer_OTP_Feature.jsx";
import Dealer_ForgetPass_OTP_feature from "./components/DealerAuthSection/Pages/Dealer_ForgetPass_OTP_feature.jsx";
import Dealer_ForgetPass_SetPass_Feature from "./components/DealerAuthSection/Pages/Dealer_ForgetPass_SetPass_Feature.jsx";
import Dealer_signup_setPass_feature from "./components/DealerAuthSection/Pages/Dealer_signup_setPass_feature.jsx";
import DealerMail from "./components/DealerSection/DealerMail/DealerMail.jsx";
import DealerInformation from "./components/DealerSection/DealerMaster/DealerInformation/DealerInformation.jsx";
import DealerTaxesAndFees from "./components/DealerSection/DealerMaster/DealerTaxesAndFees/DealerTaxesAndFees.jsx";
import DealerRetail from "./components/DealerSection/DealerMaster/DealerTaxesAndFees/DealerRetail/DealerRetail.jsx";
import DealerFinance_BHPH from "./components/DealerSection/DealerMaster/DealerTaxesAndFees/DealerFinance_BHPH/DealerFinance_BHPH.jsx";
import DealerOutOfState from "./components/DealerSection/DealerMaster/DealerTaxesAndFees/DealerOutOfState/DealerOutOfState.jsx";
import DealerWholeSale from "./components/DealerSection/DealerMaster/DealerTaxesAndFees/DealerWholeSale/DealerWholeSale.jsx";
import DealerExport from "./components/DealerSection/DealerMaster/DealerTaxesAndFees/DealerExport/DealerExport.jsx";
import DealerRent_Lease from "./components/DealerSection/DealerMaster/DealerTaxesAndFees/DealerRent_Lease/DealerRent_Lease.jsx";
import DealerForms from "./components/DealerSection/DealerForms/DealerForms.jsx";
import DealerDeletedUser from "./components/DealerSection/DealerUsers/DealerDeletedUser/DealerDeletedUser.jsx";
import DealerSetupUser from "./components/DealerSection/DealerUsers/DealerSetupUser/DealerSetupUser.jsx";
import DealerLienHolders from "./components/DealerSection/DealerLienHolders/DealerLienHolders.jsx";
import DealerWarrantyCompany from "./components/DealerSection/DealerWarrantyCompany/DealerWarrantyCompany.jsx";
import DealerFloorCompany from "./components/DealerSection/DealerFloorCompany/DealerFloorCompany.jsx";
import DealerGapInsurance from "./components/DealerSection/DealerGapInsurance/DealerGapInsurance.jsx";
import DealerInsuranceComnpany from "./components/DealerSection/DealerInsuranceCompany/DealerInsuranceComnpany.jsx";
import DealerAddInventory from "./components/DealerSection/DealerInventory/DealerAddInventory/DealerAddInventory.jsx";
import DealerSoldInventory from "./components/DealerSection/DealerInventory/DealerSoldInventory/DealerSoldInventory.jsx";
import DealerDraft from "./components/DealerSection/DealerInventory/DealerDraft/DealerDraft.jsx";
import DealerImportAndExport from "./components/DealerSection/DealerInventory/DealerImportAndExport/DealerImportAndExport.jsx";
import DealerInventoryTaxMaker from "./components/DealerSection/DealerInventory/DealerInventoryTaxMaker/DealerInventoryTaxMaker.jsx";
import DealerSell from "./components/DealerSection/DealerSell/DealerSell.jsx";
import DealerHRManager from "./components/DealerSection/DealerHRManager/DealerHRManager.jsx";
import DealerRecycleBin from "./components/DealerSection/DealerRecycleBin/DealerRecycleBin.jsx";
import DealerBought_Sold from "./components/DealerSection/DealerBought&Sold/DealerBought_Sold.jsx";

import Homelayout from "./Homelayout.jsx";
import DealerFacilitiesHome from "./components/DealerFacilities/DealerFacilitiesHome.jsx";
import DealerBusinessInformation from "./components/DealerSection/DealerBusinessInformation/DealerBusinessInformation.jsx";
import StateSelector from "./components/dashboard/addListing/StateSelector.jsx";
import DealerActiveInventory from "./components/DealerSection/DealerInventory/activeInventory/DealerActiveInventory.jsx";
import DealerAddManager from "./components/DealerSection/DealerInventory/DealerAddManager/DealerAddManager.jsx";
import DealerUsers from "./components/DealerSection/DealerUsers/DealerUsers.jsx";
import DealerMessages from "./components/DealerSection/DealerMessages/DealerMessages.jsx";

import DealerWebsite from "./components/DealerSection/DealerWebsite/DealerWebsite.jsx";

import Web1 from "./components/home/WebsitePreviews/Pages/Web1/Web1.jsx";
import Web2 from "./components/home/WebsitePreviews/Pages/Web2/Web1.jsx";
import Web3 from "./components/home/WebsitePreviews/Pages/Web3/Web3.jsx";
import Web4 from "./components/home/WebsitePreviews/Pages/Web4/Web4.jsx";
import ChatComponent from "./ChatComponent.jsx";
import DealerHelpDesk from "./components/DealerSection/DealerHelpDesk/DealerHelpDesk.jsx";
import ProductListing1 from "./components/home/WebsitePreviews/components/productListing1.jsx";
import DealerBalanceInventory from "./components/DealerSection/DealerInventory/DealerBalanceInventory/DealerBalanceInventory.jsx";
import CarDetails1 from "./components/home/WebsitePreviews/components/CarDetails1.jsx";
import ScrollToTop from "./ScrollToTop.jsx";
import Dealer_User_SetPass from "./components/DealerAuthSection/Pages/Dealer_User_SetPass.jsx";

import { Toaster } from "react-hot-toast";
import Offers from "./components/dashboard/offers/Offers.jsx";
import UserMessages from "./components/dashboard/Messages/UserMessages.jsx";
import TestSocket from "./components/dashboard/Messages/TestSocket.jsx";
import DealerOffers from "./components/DealerSection/DealerOffers/DealerOffers.jsx";
import Web44 from "./components/home/WebsitePreviews/Pages/Web44/Web4.jsx";
import DealerFastQuote from "./components/DealerSection/DealerFast/DealerFastQuote/DealerFastQuote.jsx";
import DealerCRMLeeds from "./components/DealerSection/DealerCRMLeeds/DealerCRMLeeds.jsx";
import BalanceHome from "./components/DealerSection/DealerInventory/PaymentDueData/BalanceHome.jsx";

function App() {
  return (
    <>
      <BrowserRouter>
        <Toaster
          position="top-right" // Where the notification appears
          reverseOrder={false}
          toastOptions={{
            duration: 5000, // How long the notification stays visible
          }}
        />
        <UseCustomInActivity>
          <ScrollToTop />
          <Routes>
            <Route element={<Homelayout />}>
              <Route path="/" element={<Home />} />
              <Route path="/cardetail/:productId" element={<CarDetails />} />
              <Route path="/product" element={<ProductListing />} />
              <Route path="/term-condition" element={<TermsCondition />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/chatbot" element={<ChatComponent />} />
              <Route
                path="/accountverification"
                element={<AccountVerification />}
              />
              <Route
                path="/dealer_facilities_home"
                element={<DealerFacilitiesHome />}
              />
            </Route>
            <Route
              path="/myprofile"
              element={
                <UserProtectedRoutes>
                  <MyProfile />
                </UserProtectedRoutes>
              }
            >
              <Route path="bought" element={<Bought />} />

              <Route path="addlist" element={<AddList />} />
              <Route path="active" element={<ActiveInventory />} />
              <Route path="view" element={<ViewHistory />} />
              <Route path="save" element={<SaveSearch />} />
              <Route path="customerdetails" element={<CustomerDetails />} />
              <Route path="recyclebin" element={<RecycleBin />} />
              <Route path="comparivehicle" element={<CompareVehicle />} />
              <Route path="draft" element={<Draft />} />
              <Route path="dashboard" element={<Button />} />
              <Route path="fav" element={<FavCartPage />} />
              <Route path="offers" element={<Offers />} />
              <Route path="sell" element={<Sell />} />
              <Route path="message" element={<UserMessages />} />
              {/* <Route path="message" element={<TestSocket />} /> */}
            </Route>

            <Route
              path="/dealer/login"
              element={
                <Dealer_Auth_Layout header="Sign in">
                  <Dealer_Login_feature />
                </Dealer_Auth_Layout>
              }
            />
            <Route
              path="/dealer/inputlogin"
              element={
                <Dealer_Auth_Layout header="Password">
                  <Dealer_Input_Login_feature />
                </Dealer_Auth_Layout>
              }
            />
            <Route
              path="/dealer/verifyDeviceOTP"
              element={
                <Dealer_Auth_Layout header="Device Verify">
                  <Dealer_Device_Verify_feature />
                </Dealer_Auth_Layout>
              }
            />
            <Route
              path="/dealer/signupOTP"
              element={
                <Dealer_Auth_Layout header="Verify OTP">
                  <Dealer_OTP_Feature />
                </Dealer_Auth_Layout>
              }
            />
            <Route
              path="/dealer/forgetPassOTP"
              element={
                <Dealer_Auth_Layout header="Verify OTP">
                  <Dealer_ForgetPass_OTP_feature />
                </Dealer_Auth_Layout>
              }
            />
            <Route
              path="/dealer/forgetPassSetPass"
              element={
                <Dealer_Auth_Layout header="Set Password">
                  <Dealer_ForgetPass_SetPass_Feature />
                </Dealer_Auth_Layout>
              }
            />
            <Route
              path="/dealer/signupSetPass"
              element={
                <Dealer_Auth_Layout header="Set Password">
                  <Dealer_signup_setPass_feature />
                </Dealer_Auth_Layout>
              }
            />
            {/* Dealer User Login  Feature starts here*/}
            <Route
              path="/dealer/auth/user/set-password"
              element={
                <Dealer_Auth_Layout header="Set Password">
                  <Dealer_User_SetPass />
                </Dealer_Auth_Layout>
              }
            />

            <Route
              path="/dealer"
              element={
                <DealerProtectedRoutes>
                  <DealerLayout />
                </DealerProtectedRoutes>
              }
            >
              <Route path="dashboard" element={<DealerDashBoard />} />
              <Route path="mail" element={<DealerMail />} />
              <Route
                path="fast/quote/fast-quote"
                element={<DealerFastQuote />}
              />
              <Route
                path="fast/quote/tax-certificate"
                element={<DealerFastQuote />}
              />
              <Route path="fast/quote/draft" element={<DealerFastQuote />} />
              <Route path="fast/quote/forms" element={<DealerFastQuote />} />
              <Route path="crmLeeds/all" element={<DealerCRMLeeds />} />
              <Route path="crmLeeds/search" element={<DealerCRMLeeds />} />
              <Route path="crmLeeds/loanApp" element={<DealerCRMLeeds />} />
              <Route path="crmLeeds/emailLeeds" element={<DealerCRMLeeds />} />
              <Route path="crmLeeds/messages" element={<DealerCRMLeeds />} />
              <Route path="crmLeeds/calls" element={<DealerCRMLeeds />} />
              <Route path="crmLeeds/offers" element={<DealerCRMLeeds />} />
              <Route path="crmLeeds/usersReport" element={<DealerCRMLeeds />} />
              <Route path="crmLeeds/services" element={<DealerCRMLeeds />} />

              <Route
                path="dealer-master/dealer-information"
                element={<DealerInformation />}
              />
              <Route
                path="dealer-master/taxes-fees"
                element={<DealerTaxesAndFees />}
              />
              <Route
                path="dealer-master/bussiness-information"
                element={<DealerBusinessInformation />}
              />
              <Route
                path="dealer-master/taxes-fees/retail"
                element={<DealerRetail />}
              />
              <Route
                path="dealer-master/taxes-fees/finance-bhph"
                element={<DealerFinance_BHPH />}
              />
              <Route
                path="dealer-master/taxes-fees/out-of-state"
                element={<DealerOutOfState />}
              />
              <Route
                path="dealer-master/taxes-fees/whole-sale"
                element={<DealerWholeSale />}
              />
              <Route
                path="dealer-master/taxes-fees/export"
                element={<DealerExport />}
              />
              <Route
                path="dealer-master/taxes-fees/rent-lease"
                element={<DealerRent_Lease />}
              />
              <Route path="forms" element={<DealerForms />} />
              <Route path="users" element={<DealerUsers />} />

              <Route path="users/setup" element={<DealerSetupUser />} />
              <Route path="users/deleted" element={<DealerDeletedUser />} />
              <Route path="lien-holders" element={<DealerLienHolders />} />
              <Route
                path="warranty-company"
                element={<DealerWarrantyCompany />}
              />
              <Route path="floor-company" element={<DealerFloorCompany />} />
              <Route path="gap-insurance" element={<DealerGapInsurance />} />
              <Route
                path="insurance-company"
                element={<DealerInsuranceComnpany />}
              />
              <Route path="inventory/add" element={<DealerAddInventory />} />
              {/* <Route
                  path="vehicleSpecification"
                  element={<DealerAddInventory />}
                />
                <Route path="equipments" element={<DealerAddInventory />} />
                <Route
                  path="sellerDescription"
                  element={<DealerAddInventory />}
                />
                <Route path="addCost" element={<DealerAddInventory />} />
                <Route path="titleInfo" element={<DealerAddInventory />} />
                <Route path="finance" element={<DealerAddInventory />} /> */}
              {/* </Route> */}
              <Route
                path="inventory/active"
                element={<DealerActiveInventory />}
              />
              <Route path="inventory/sold" element={<DealerSoldInventory />} />
              <Route
                path="inventory/balance/*"
                // element={<DealerBalanceInventory />}
                element={<BalanceHome />}
              />
              <Route path="inventory/draft" element={<DealerDraft />} />
              <Route
                path="inventory/import-export"
                element={<DealerImportAndExport />}
              />
              <Route
                path="inventory/tax-maker"
                element={<DealerInventoryTaxMaker />}
              />
              <Route path="sell" element={<DealerSell />} />
              <Route path="messages" element={<DealerMessages />} />
              <Route path="offers" element={<DealerOffers />} />
              <Route path="bought_sold" element={<DealerBought_Sold />} />
              <Route path="dealer_add_manager" element={<DealerAddManager />} />
              <Route path="website/*" element={<DealerWebsite />} />
              <Route path="help_desk" element={<DealerHelpDesk />} />
              <Route path="recycle-bin" element={<DealerRecycleBin />} />
            </Route>
            <Route
              path="/dealer/webPreview1"
              element={
                <DealerProtectedRoutes>
                  <Web1 />
                </DealerProtectedRoutes>
              }
            />
            <Route
              path="/dealer/product1"
              element={
                <DealerProtectedRoutes>
                  <ProductListing1 />
                </DealerProtectedRoutes>
              }
            />
            <Route
              path="/dealer/carDetails1"
              element={
                <DealerProtectedRoutes>
                  <CarDetails1 />
                </DealerProtectedRoutes>
              }
            />
            <Route
              path="/dealer/webPreview2"
              element={
                <DealerProtectedRoutes>
                  <Web2 />
                </DealerProtectedRoutes>
              }
            />
            <Route
              path="/dealer/webPreview3"
              element={
                <DealerProtectedRoutes>
                  <Web3 />
                </DealerProtectedRoutes>
              }
            />
            <Route
              path="/dealer/webPreview4"
              element={
                <DealerProtectedRoutes>
                  <Web4 />
                </DealerProtectedRoutes>
              }
            />
            <Route
              path="/dealer/webPreview5"
              element={
                <DealerProtectedRoutes>
                  <Web44 />
                </DealerProtectedRoutes>
              }
            />
          </Routes>
        </UseCustomInActivity>
      </BrowserRouter>
      {/* <Test/> */}
      {/* <StateSelector/> */}
    </>
  );
}

export default App;

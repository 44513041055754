import axios from "axios";

export async function UserCity() {
  const getPosition = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
  };

  try {
    if ("geolocation" in navigator) {
      const position = await getPosition();
      const { latitude, longitude } = position.coords;

      const response = await axios.get(
        `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
      );
      console.log(response);

      return response.data;
    } else {
      throw new Error("Geolocation is not available");
    }
  } catch (error) {
    console.error("Error getting user city:", error.message);
    return null;
  }
}

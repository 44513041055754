const ProductData1 = {
  accident: "",
  account_number: null,
  addvertising: {
    id: 3,
    dacmp_status: true,
    dacmp_interval: "",
    unitedmotors_status: true,
    unitedmotors_interval: "",
    craigslist_status: false,
    craigslist_interval: "",
    facebook_status: false,
    facebook_interval: "",
    google_status: false,
    google_interval: "",
    insta_status: false,
    insta_interval: "",
    twitter_status: false,
    twitter_interval: "",
    listing_id: null,
  },
  auction_fees: 0,
  battery_kwh: "324",
  battery_type: "Li-ion",
  bodyClass: "SUV",
  buyer_name: "",
  city_mpg: "",
  commission: 0,
  condition: "Used",
  coordinates: {
    latitude: 19.0748,
    longitude: 72.8856,
  },
  created_at: "2024-09-09",
  doorCount: "4D",
  doorCounts: 4,
  driveTrain: "FWD",
  driveType: "FWD",
  engine: "6CYL 4L V-Shaped Gasoline ",
  engineType: "6CYL",
  estimated_cost: 0,
  exteriorColor: "",
  exterior_damage: "",
  floor_fee: [],
  flooring_fee: 0,
  frame_damage: "",
  fuelType: "Gasoline",
  highway_mpg: "",
  horse_power: 324,
  id: 3,
  inspection: "2024-09-09",
  interiorColor: "",
  interiorType: "",
  ip: null,
  keys_count: 3,
  lattitude: 0,
  listingStatus: "Available",
  listing_price: 23467,
  location: null,
  longitude: 0,
  make: "FORD",
  makeModel: "Explorer",
  market_value_price: 0,
  mileage_in: 12343,
  mileage_read: null,
  model: "Explorer",
  modelMake: "FORD",
  modelYear: 2010,
  multimedia: {
    id: 3,
    all_images:
      "https://www.hyundai.com/content/dam/hyundai/in/en/data/find-a-car/i20/Highlights/pc/i20_Modelpc.png",
    all_videos: "",
    bill_sale: "",
    exterior_images:
      "https://www.hyundai.com/content/dam/hyundai/in/en/data/find-a-car/i20/Highlights/pc/i20_Modelpc.png, https://imgd-ct.aeplcdn.com/370x231/n/cw/ec/150749/i20-facelift-right-front-three-quarter.jpeg,https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSwetUmiWXZpOpqA_2ZlesVw3eVsrxYkRY2Lg&s ",
    interior_images:
      "https://www.hyundai.com/content/dam/hyundai/in/en/data/find-a-car/i20/Interior/pc/big-image1.jpg, https://cdni.autocarindia.com/Utils/ImageResizer.ashx?n=https://cdni.autocarindia.com/ExtraImages/20200226110417_2020-Hyundai-i20-interior.jpg",
    misc_images:
      "https://stimg.cardekho.com/images/carexteriorimages/630x420/Hyundai/i20/9471/1697696007962/front-left-side-47.jpg?imwidth=420&impolicy=resize",
    three_sixty_images:
      "https://imgd-ct.aeplcdn.com/664x415/n/cw/ec/150749/i20-facelift-right-front-three-quarter.jpeg?isig=0&q=80",
    title_others: "",
  },
  odo: "34554",
  other_restraint: "DisplacementCI (HP):244,  1st Row (Driver and Passenger)",
  others: 0,
  owner: "",
  price_fee_date: "2024-09-09",
  price_payment_method: null,
  profit_price: 0,
  purchase_price: 12312,
  purchasedDate: "2024-09-09",
  purchased_from: "Emran Rababah",
  repair_cost: [],
  seat_count: 4,
  sellListing: null,
  seller_description: "",
  series_body_wheel: null,
  smoked_in: "Yes",
  state: "TX-Texas",
  status: "",
  stock_no: "B02192",
  title: "Clean",
  title_location: "",
  title_number: "",
  title_recieved: "",
  title_status: "",
  title_type: "",
  total_cost: 0,
  total_repair_cost: 0,
  transmission: "transmission",
  transmission_speed: null,
  trim: "XLT ",
  trims: "XLT",
  tyrecondition_bl: "",
  tyrecondition_br: "",
  tyrecondition_fl: "",
  tyrecondition_fr: "",
  userId: 12,
  user_name: "Afjal Khan",
  vehicleOptions: {
    id: 3,
    comfortAndConvenience: {
      id: 3,
      adaptive_cruise_control: false,
      auto_climate_control: false,
      auto_dimming_rearview_mirror: false,
      heated_ventilated_seats: false,
      key_less_entry: false,
      key_less_go: false,
      leather_seats: false,
      memory_seats: false,
      multizone_climate_control: false,
      panormic_sunroof: false,
      power_adjustable_seats: false,
      power_liftgate: false,
      power_window_mirrors: false,
      remote_starter: false,
      smart_trunk_opener: false,
      sunshades: false,
      tilt_telescopic_steering_wheel: false,
    },
    driveAssistance: {
      id: 3,
      adaptive_headlights: false,
      automatic_high_beams: false,
      driver_drowsiness_monitoring: false,
      head_up_display: false,
      night_vision: false,
      parking_assistance_self: null,
      sorrund_view_camera: null,
      traffic_sign_recognition: null,
    },
    energyAndEquipment: {
      id: 3,
      engine_start_stop_system: false,
      hybrid_powertrain: false,
      regenerative_breaking: false,
      solar_roof: false,
    },
    exteriorFeatures: {
      id: 3,
      alloy_wheels: false,
      automatic_headlights: false,
      fog_lights: false,
      led_headlights: false,
      power_folding_mirrors: false,
      roof_rails: null,
      running_boards: null,
      spoiler: null,
      tow_hitch: null,
      windshield_wiper_de_icer: null,
    },
    extraVehicleOptions: [],
    infotainment: {
      id: 3,
      apple_carplay_android_auto: false,
      bluetooth_connectivity: false,
      incar_wifi: false,
      navigation_system: false,
      premium_sound_system: false,
      satellite_radio: null,
      touchscreen_display: null,
      usb_ports: null,
      voice_recognition: null,
      wireless_charging_pad: null,
    },
    interiorFeatures: {
      id: 3,
      ambient_lighting: false,
      cargo_cover: false,
      cargo_net: false,
      center_console_armrest: false,
      heated_steering_wheels: false,
      leather_wrapped_steering_wheel: null,
      rear_seat_entertainment_system: null,
      split_folding_rear_seat: null,
    },
    performanceAndHandling: {
      id: 3,
      adaptive_suspension: false,
      all_wheels_drive: false,
      drive_mode_selector: false,
      limited_slip_differential: false,
      sport_tuned_suspension: false,
      variable_steering_assist: null,
    },
    safetyFeatures: {
      id: 3,
      anti_lock_brake_system: false,
      back_up_camera: false,
      blind_spot_monitoring: false,
      brake_assist: false,
      collision_mitigation_system: false,
      cross_traffic_alert: false,
      curtain_airbag: false,
      daytime_running_lights: false,
      electronic_stability_control: false,
      forward_collision_warning: false,
      front_airbag: false,
      hill_descent_control: false,
      hill_start_assist: false,
      lane_departure_warning: false,
      lane_keeping_assist: false,
      parking_sensors: false,
      rear_cross_traffic_alert: false,
      side_airbags: false,
      traction_control: false,
      tyre_pressure_monitoring: false,
    },
  },
  vehicleType: "MPV",
  vin: "1FMEU6DE5AUB02192",
  wheelBase: null,
  year: "2010",
  year_Make_Model: "2010 FORD Explorer SUV FWD 4D",
  year_make_model: "2010/#/FORD/#/Explorer/#/SUV/#/FWD/#/4D",
};

export default ProductData1;

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaMicrophone } from "react-icons/fa";

const HomeFilters1 = ({ route }) => {
  const navigate = useNavigate();
  const naviGate = () => {
    navigate("/dealer/product1", { state: route });
  };
  return (
    <section className="sectionMain ">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-md-10 form-col">
            {" "}
            {/* col-md-8  col-12*/}
            <div className="form_5" style={{ background: "rgb(107, 99, 99)" }}>
              <form className="row login-form">
                <div className="row text-light align-items-center text-center pb-4">
                  <div
                    className="col-lg-9  d-flex justify-content-end pe-10"
                    style={{ paddingRight: "10rem" }}
                  >
                    {/* <Link className="buttonSell2">Auto Loan Calculator</Link> */}
                    <div className=" col-lg-8  m-0">
                      {/* input field */}
                      <p
                        className="input_search_field"
                        style={{ marginBottom: "0" }}
                      >
                        <input
                          type="text"
                          placeholder="Search by Vehicle"
                          style={{ padding: "10px 5px" }}
                        />
                        {/* <span className="search_icon_container">
                    <FaSearch />
                  </span> */}
                        {/* Microphone icon*/}
                        <span className="microphone_container">
                          <FaMicrophone />
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <Link className="buttonSell2 col-lg-10">
                      Auto Loan Calculator
                    </Link>
                  </div>
                </div>

                <div
                  className="accordion col-lg-3 col-md-4 col-sm-12"
                  id="accordionExample"
                >
                  <div className="accordion-item" style={{ width: "85%" }}>
                    <div
                      id="collapseOne"
                      className={`accordion-collapse `}
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                      // style={{width :"85%"}}
                    >
                      <div className="accordion-body p-0 d-flex w-80">
                        <select className="p-0 py- px-1"></select>
                        <select
                          className="p-0 py-2 px-1"
                          name="yearTo"
                        ></select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-4 col-lg-2">
                  <select
                    className="form-select"
                    name="make"
                    style={{ width: "85%" }}
                  ></select>
                </div>
                <div className="col-sm-12 col-md-4 col-lg-2">
                  <select
                    className="form-select"
                    name="models"
                    style={{ width: "85%" }}
                  ></select>
                </div>
                <div className="col-sm-12 col-md-4 col-lg-2">
                  <select
                    className="form-select"
                    name="odometer"
                    style={{ width: "85%" }}
                  ></select>
                </div>
                {/* <div className="col-sm-12 col-md-4 col-lg-2 zipCode_container">
                  <input
                    type="text"
                    placeholder="Enter ZipCode"
                    style={{ padding: "7px", width: "85%" }}
                  />
                  <span>
                    <MdMyLocation />
                  </span>
                </div> */}
                <div className="col-sm-12 col-md-4 col-lg-3">
                  <button
                    type="button"
                    className=" search_btn"
                    style={{ width: "85%" }}
                    onClick={naviGate}
                  >
                    Search Inventory
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeFilters1;

import React from 'react'

const Payment = () => {
    return (
        <div className="payment-info">
            <div className="input-user-container form-row">
                <div className="form-group col-md-4">
                    <label htmlFor="paymentMethod">Payment Method</label>
                    <select className="form-select" id="paymentMethod">
                        <option value="creditCard">Credit Card</option>
                        <option value="debitCard">Debit Card</option>
                        <option value="paypal">PayPal</option>

                    </select>
                </div>
                <div className="form-group col-md-5">
                    <label htmlFor="cardHolderName">CC Holder Name</label>
                    <input type="text" className="form-control" id="cardHolderName" />
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="cardNo">Credit Card No.</label>
                    <input type="text" className="form-control" id="cardNo" />
                </div>

                <div className="form-group col-md-3">
                    <label htmlFor="expiryYear">Expiry Year</label>
                    <select className="form-select" id="expiryYear">
                        <option>2021</option>
                        <option>2022</option>
                        <option>2023</option>
                        <option>2024</option>
                    </select>
                </div>
                <div className="form-group col-md-3">
                    <label htmlFor="expiryMonth">Expiry Month</label>
                    <select className="form-select" id="expiryMonth">
                        {Array.from({ length: 12 }, (_, index) => {
                            const month = index + 1;
                            return (
                                <option key={month} value={month}>
                                    {new Date(0, month - 1).toLocaleString('default', { month: 'long' })}
                                </option>
                            );
                        })}
                    </select>
                </div>

                <div className="form-group col-md-2">
                    <label htmlFor="cvv">CVV</label>
                    <input type="text" className="form-control" id="cvv" />
                </div>
                <div className="form-group col-md-6">
                    <label htmlFor="billingAddress">Billing Address</label>
                    <input type="text" className="form-control" id="billingAddress" />
                </div>

            </div>
        </div>
    )
}

export default Payment
import React, { useEffect, useState } from 'react'
import "./bought.css"
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import MyProfile from '../MyProfile';

const Bought = () => {
    // Session Time out for 15 minutes-------------------------------------
//   const navigate = useNavigate();

   // Session Time out for 15 minutes End-------------------------------------

    const z = useSelector((state) => state.addlistingdata.z);
    console.log("line8",z);

    return (
        <>
        {/* <MyProfile/> */}
            <section className='container'>+
                <div className='mm'>
                    <div className='row'>
                        <div className="col-12">
                            <header className="d-sm-flex align-items-center mt-5  ">
                                <div className="ms-auto">
                                    <select className="form-select d-inline-block w-auto border pt-1 me-2">
                                        <option value="0">Select</option>
                                        <option value="0">10</option>
                                        <option value="1">20</option>
                                        <option value="2">30</option>
                                        <option value="3">50</option>
                                    </select>

                                    <select className="form-select d-inline-block w-auto border pt-1 me-2 ">
                                        <option value="0 " className='text-center'>Sort By</option>
                                        <option value="0">Bought (Low to High)</option>
                                        <option value="1">Bought (High to Low)</option>
                                        <option value="2">Sold (Low to High)</option>
                                        <option value="3">Sold (High to Low)</option>
                                    </select>
                                </div>

                            </header>
                        </div>
                    </div>


                    <div className='row'>
                        <div className="row shadow-sm p-3  bg-body rounded mt-4 border border-dark">
                            <div className="col-lg-4 col-md-5 ">
                                <div className="product-image-box">
                                    <img src="https://hips.hearstapps.com/hmg-prod/images/lincoln-navigator-1542226524.jpg" className="w-100" alt="..." />
                                    <div className="product-image-content mt-2">
                                        <h4 className="text-center">Sold On: 22/2/2024</h4>
                                    </div>
                                </div>
                            </div>
                            {/*----------------------------------------side Image End--------------------------------------------------*/}
                            <div className="col-lg-6 col-md-7">
                                {/*----------------------content deatils Start---------------------*/}
                                <div className="row">
                                    <div className="col-md-8 p-md-0 p-3">
                                        <h4><b>2024 Lincoln Navigator SUV</b> </h4>
                                        <h6><span className="theme-color" style={{ color: '#D1823A' }}> VIN :</span>1DDFDHLKJM854D45F</h6>
                                        <h6><span className='fw-bold fs-5'>14.5352MI</span> &nbsp;&nbsp;<span>2WD</span> &nbsp;&nbsp;<span>2.6L</span>&nbsp;&nbsp; <span>4CYL</span> &nbsp;&nbsp;<span>GASOLINE</span> &nbsp;&nbsp;<span>AUTO</span> </h6>
                                        <h6><span className="theme-color" style={{ color: '#D1823A' }}>Location :</span>Dallas TX</h6>
                                        <div className="d-flex ">
                                            <h6><span className="theme-color" style={{ color: '#D1823A' }}> Bought From :</span>Jessica</h6>
                                            <label className="form-check-label ms-2" htmlFor="flexCheckDefault" >
                                                <i className="fas fa-star text-warning" ></i>
                                                <i className="fas fa-star text-warning" ></i>
                                                <i className="fas fa-star text-warning" ></i>
                                                <i className="fas fa-star text-warning" ></i>
                                                <i className="fas fa-star text-light"></i>
                                            </label>
                                        </div>
                                        <div className="d-flex ">
                                            <h6><span className="theme-color" style={{ color: '#D1823A' }}> Sold to :</span>David Auto Center</h6>
                                            <label className="form-check-label ms-2" htmlFor="flexCheckDefault" >
                                                <i className="fas fa-star text-warning" ></i>
                                                <i className="fas fa-star text-warning"></i>
                                                <i className="fas fa-star text-warning"></i>
                                                <i className="fas fa-star text-warning"></i>
                                                <i className="fas fa-star "></i>
                                            </label>
                                        </div>
                                        <h6><span className="theme-color" style={{ color: '#D1823A' }}>Payment Method :</span>Card</h6>
                                    </div>
                                    <div className="col-md-4 p-md-0 p-3">
                                    </div>
                                </div>
                                {/*----------------------content deatils End---------------------*/}
                            </div>
                            {/* -------------------left side Icon and button Start--------------------- */}
                            <div className='col-lg-2 col-md-12 '>
                                <div className='row'>
                                    <div className='col-lg-12 col-md-4 col-12'>
                                        <h4 > <span className='fw-bold fs-5'>Sold Price :</span> <span className='fw-bold fs-5' style={{ color: '#D1823A' }}>$15262 </span> </h4>
                                    </div>
                                    <div className='col-lg-12 col-md-4 col-12 my-1'>
                                        <div className='product-S-btnss'>
                                            <span><i className="fa-solid fa-down"></i></span> Dowanload
                                        </div>
                                    </div>
                                    <div className='col-lg-12 col-md-4 col-12 my-1'>
                                        <div className='product-S-btns'>
                                            <span></span> Email
                                        </div>
                                    </div>

                                </div>
                            </div>
                            {/* -------------------left side Icon and button End--------------------- */}
                        </div>
                    </div>
                </div>
                {/* ===============================================================================================================================*/}
                <div className='mm mb-4'>
                    <div className='row'>
                        <div className="row shadow-sm p-3  bg-body rounded mt-4 border border-dark">
                            <div className="col-lg-4 col-md-5 ">
                                <div className="product-image-box">
                                    <img src="https://hips.hearstapps.com/hmg-prod/images/lincoln-navigator-1542226524.jpg" className="w-100" alt="..." />
                                    <div className="product-image-content mt-2">
                                        <h4 className="text-center">Sold On: 22/2/2024</h4>
                                    </div>
                                </div>
                            </div>
                            {/*----------------------------------------side Image End--------------------------------------------------*/}
                            <div className="col-lg-6 col-md-7">
                                {/*----------------------content deatils Start---------------------*/}
                                <div className="row">
                                    <div className="col-md-8 p-md-0 p-3">
                                        <h4><b>2024 Lincoln Navigator SUV</b> </h4>
                                        <h6><span className="theme-color" style={{ color: '#D1823A' }}> VIN :</span>1DDFDHLKJM854D45F</h6>
                                        <h6><span className='fw-bold fs-5'>14.5352MI</span> &nbsp;&nbsp;<span>2WD</span> &nbsp;&nbsp;<span>2.6L</span>&nbsp;&nbsp; <span>4CYL</span> &nbsp;&nbsp;<span>GASOLINE</span> &nbsp;&nbsp;<span>AUTO</span> </h6>
                                        <h6><span className="theme-color" style={{ color: '#D1823A' }}>Location :</span>Dallas TX</h6>
                                        <div className="d-flex ">
                                            <h6><span className="theme-color" style={{ color: '#D1823A' }}> Bought From :</span>Jessica</h6>
                                            <label className="form-check-label ms-2" htmlFor="flexCheckDefault" >
                                                <i className="fas fa-star text-warning" ></i>
                                                <i className="fas fa-star text-warning" ></i>
                                                <i className="fas fa-star text-warning" ></i>
                                                <i className="fas fa-star text-warning" ></i>
                                                <i className="fas fa-star text-light"></i>
                                            </label>
                                        </div>
                                        <div className="d-flex ">
                                            <h6><span className="theme-color" style={{ color: '#D1823A' }}> Sold to :</span>David Auto Center</h6>
                                            <label className="form-check-label ms-2" htmlFor="flexCheckDefault" >
                                                <i className="fas fa-star text-warning" ></i>
                                                <i className="fas fa-star text-warning"></i>
                                                <i className="fas fa-star text-warning"></i>
                                                <i className="fas fa-star text-warning"></i>
                                                <i className="fas fa-star "></i>
                                            </label>
                                        </div>
                                        <h6><span className="theme-color" style={{ color: '#D1823A' }}>Payment Method :</span>Card</h6>
                                    </div>
                                    <div className="col-md-4 p-md-0 p-3">
                                    </div>
                                </div>
                                {/*----------------------content deatils End---------------------*/}
                            </div>
                            {/* -------------------left side Icon and button Start--------------------- */}
                            <div className='col-lg-2 col-md-12 '>
                                <div className='row'>
                                    <div className='col-lg-12 col-md-4 col-12'>
                                        <p > <span className='fw-bold fs-6'>Bought Price :</span> <span className='fw-bold fs-6' style={{ color: '#D1823A' }}>$15262 </span> </p>
                                    </div>
                                    <div className='col-lg-12 col-md-4 col-12 my-1'>
                                        <div className='product-S-btnss'>
                                            <span><i className="fa-solid fa-down"></i></span> Dowanload
                                        </div>
                                    </div>
                                    <div className='col-lg-12 col-md-4 col-12 my-1'>
                                        <div className='product-S-btns'>
                                            <span></span> Email
                                        </div>
                                    </div>

                                </div>
                            </div>
                            {/* -------------------left side Icon and button End--------------------- */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Bought;


import React, { useState, useEffect, useRef } from "react";
import Styles from "../Styles/ChatContainer.module.css";
import "./ChatContainer.css";
import { IoLocationSharp, IoSend } from "react-icons/io5";
import { CgAttachment } from "react-icons/cg";
import EmojiPicker from "emoji-picker-react";
import { BsCurrencyDollar } from "react-icons/bs";
import { FaRegFaceSmile } from "react-icons/fa6";

import { useDispatch, useSelector } from "react-redux";
const ChatContainer = ({
  input,
  handleInputChange,
  messages,
  sendMessage,
  handleInputFocus,
  handleInputBlur,
}) => {
  console.log("Received message chatcontainer:", messages);
  const { firstName, id } = useSelector((state) => state.user);
  const [stompClient, setStompClient] = useState(null);
  // const [messages, setMessages] = useState([]);
  const [messageInput, setMessageInput] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const chatBoxRef = useRef(null);

  useEffect(() => {
    // Scroll to bottom of chat box
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages]);

  const handleMessageSubmit = (e) => {
    e.preventDefault();
    if (messageInput && stompClient) {
      const chatMessage = {
        sender: "neeraj", // Replace with actual sender if needed
        content: messageInput,
        receiver: "Sumit", // Replace with actual receiver if needed
      };
      setMessageInput("");
    }
  };

  const handleEmojiClick = (emojiObject) => {
    setMessageInput((prevInput) => prevInput + emojiObject.emoji);
    setShowEmojiPicker(false);
  };

  return (
    <div className={` border`}>
      <h1>User Chat</h1>
      <div className={Styles.chatBox} ref={chatBoxRef}>
        {/* <div className={Styles.receivedMessage}>
          <div>user name</div>
          <div className={Styles.msgContent}>
            kityb uykdyi fgriuetg 458tg 4r85gc 3583gc r8gc48f c486rtgfc 48g
            eruxfg78e rg78 g8guki,kmghvtm tvgbftdx ucvnbnhg jt7u65u7fd rhd7uhjur
          </div>
        </div> */}

        {/* <div className={Styles.sentMessage}>
          <div>{firstName}</div>
          <div className={Styles.msgContent}>
            jyge5tk rtguy n458t4 5t84 t76c5gt 7g jg4kdyi fgriuetg 458tg 4r85gc
            3583gc r8gc48f c486rtgfc 48g eruxfg78e rg78 g8 nn5t8 irdi8 gn5i4u
            yg4576 tg5u775 t4578t5h8 giu8t4e 8947t3 8r497 t609d 64876tyf
          </div>
        </div> */}

        {messages
          .filter(
            (mes) =>
              mes.content &&
              mes.content.trim().length > 0 &&
              mes.content !== "is typing..."
          )

          .map((msg, index) => (
            <div
              key={index}
              className={
                msg.sender === id ? Styles.sentMessage : Styles.receivedMessage
              }
            >
              <div>{Number(msg.sender) === id ? firstName : "Receiver"}</div>
              <div className={Styles.msgContent}>
                {msg.content.length > 0 &&
                  msg.content !== "is typing..." &&
                  msg.content}
              </div>
            </div>
          ))}
      </div>
      {/* Message type container  */}
      <form onSubmit={handleMessageSubmit} className={Styles.messageForm}>
        <div className="input-field-message mx-auto my-3">
          <div className={`me-2 ${Styles.inputContainer}`}>
            <button type="button" className={Styles.iconButton}>
              <IoLocationSharp style={{ fill: "#4184cb" }} />
              <span style={{ fontSize: "12px", fontWeight: "unset" }}>
                Location
              </span>
            </button>
            <button type="button" className={Styles.iconButton}>
              <CgAttachment />
              <span style={{ fontSize: "12px", fontWeight: "unset" }}>
                Attachment
              </span>
            </button>
            <button type="button" className={Styles.iconButton}>
              <BsCurrencyDollar />
              <span style={{ fontSize: "12px", fontWeight: "unset" }}>
                Loan App
              </span>
            </button>
            <input
              type="text"
              value={input}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              placeholder="Type a message..."
              className={Styles.messageInput}
              style={{ letterSpacing: "2px", fontWeight: "bold" }}
            />
            <button
              type="button"
              onClick={() => setShowEmojiPicker(!showEmojiPicker)}
              className={Styles.emojiButton}
            >
              <FaRegFaceSmile style={{ backgroundColor: "#ffc800" }} />
            </button>
          </div>
          <button
            type="submit"
            className={` border-0 ${Styles.sendMessageButton}`}
            onClick={sendMessage}
          >
            <IoSend style={{ border: "none" }} id="send" />
          </button>
        </div>
        {showEmojiPicker && (
          <div className={Styles.emojiPickerContainer}>
            <EmojiPicker onEmojiClick={handleEmojiClick} />
          </div>
        )}
      </form>
    </div>
  );
};

export default ChatContainer;

import React, { useState, useEffect } from 'react';
import './footer.css';

const ScrollToTopButton = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (document.body.scrollTop > 480 || document.documentElement.scrollTop > 480) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

//   const topFunction = () => {
//     document.body.scrollTop = 0;
//     document.documentElement.scrollTop = 0;
//   };

const topFunction = () => {
    window.scroll({
        top: 0, 
        left: 0, 
        behavior: "smooth"
      })
  };

  return (
    <button className='myBtn' onClick={topFunction} style={{ display: showButton ? 'block' : 'none' }} title="Go to top">
      <i className="fa-solid fa-arrow-up-long" style={{ color: '#ffffff' }}></i>
    </button>
  );
};

export default ScrollToTopButton;
import React from "react";
import { MdOutlineForwardToInbox } from "react-icons/md";
import { FiPhoneCall } from "react-icons/fi";
import { HiDotsHorizontal } from "react-icons/hi";
import { VscSend } from "react-icons/vsc";
import "./Comparecard.css";
import Image from "../../imgData";
import { GrCube } from "react-icons/gr";
import { CiDollar } from "react-icons/ci";

const Comparecard = () => {
  return (
    <div>
      <div className="compare-vehcile-main-container">
        <div className="c-v-image-card">
          {/* for dropdown header */}
          <select value="Select Vehicle" className="dropdown_car_select"
          >
            <option disabled hidden value="">Select Vehicle</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
          {/* for dropdown header end*/}
          <div>
            <img
              src="https://car-images.bauersecure.com/wp-images/2697/bmwi4_029.jpg"
              style={{ width: "200px", height: "110px" }}
              alt=".."
            />
          </div>
          <h5 style={{ marginTop: "15px" , width : "90%", textAlign:"center"}}>2019 NISSAN SEDDAN</h5>
        </div>
        <div className="c-v-specification">
          <span>
            <h5
              style={{
                borderBottom: "1px solid black",
                height: "36px",
                width: "133px",
              }}
            >
              Specification
            </h5>
          </span>
          <div className="data_content_compare_card_1">
            {" "}
            <span>4X2 300hp 3.5L</span>
            <span>Gas M/T Hard</span>
            <span>Top Cloth</span>
          </div>
        </div>
        <div className="c-v-price">
          <span>
            <h5
              style={{
                borderBottom: "1px solid black",
                height: "36px",
                width: "80px",
              }}
            >
              Price
            </h5>
          </span>
          <div className="data_content_compare_card_2">
            <h5 style={{ fontWeight: "800" }}>$35,603</h5>

            <h5
              style={{ color: "#d1823a", fontWeight: "800" }}
              className="child_container_comapre_card_2"
            >
              <span>$24,603</span>
              <span>DACV</span>
            </h5>
          </div>
        </div>
        <div className="c-v-odometer">
          <span>
            <h5
              style={{
                borderBottom: "1px solid black",
                height: "36px",
                width: "115px",
              }}
            >
              Odometer
            </h5>
          </span>
          <h5 className="child_container_comapre_card_3">16,232 mi</h5>
        </div>
        <div className="c-v-estmonth">
          <h5
            style={{
              borderBottom: "1px solid black",
              height: "36px",
              width: "100px",
            }}
          >
            <span>Est.</span>
            <span>Mo*</span>
          </h5>

          <p className="child_container_comapre_card_4">$743/mo</p>
        </div>
        <div className="c-v-listed">
          <span>
            <h5
              style={{
                borderBottom: "1px solid black",
                height: "36px",
                width: "120px",
              }}
            >
              Listed
            </h5>
          </span>
          <div className="child_container_comapre_card_5">
            <h5>84 days</h5>
            <h5>in market</h5>
          </div>
        </div>
        <div className="c-v-cont-direction">
          <span>
            <h5 style={{ borderBottom: "1px solid black", height: "36px" }}>
              <span>Contact</span> <span>&</span> <span> Direction</span>
            </h5>
          </span>
          <div className="child_container_comapre_card_6">
            <div className="child_container_header">
              <span>
                <button id="c-v-call">
                  <span>
                    <FiPhoneCall />
                  </span>
                  <span>(892) 545-5467</span>
                </button>
              </span>
              <span>
                <MdOutlineForwardToInbox
                  style={{
                    fill: "#d1823a",
                    fontSize: "25px",
                    marginRight: "10px",
                  }}
                />
              </span>
            </div>
            <div className="child_address">
              <h6>1764,MC Donald, TX USA</h6>
            </div>
            <div className="messenger_contact">
              <img
                src={Image.messanger}
                alt="messenger icon"
                height={35}
                width={35}
              />
              <span>Contact the seller now</span>
            </div>
            <div className="messenge_input_send">
              <input
                type="text"
                placeholder="Hi Kumar, Is this still available?"
              />
              <button
                style={{
                  backgroundColor: "#d1823a",
                  border: "none",
                  borderRadius: "5px",
                }}
              >
                <VscSend style={{ fill: "white", fontSize: "25px" }} />
              </button>
            </div>
            <div className="finance_section">
              <p>
                <span>
                  <GrCube />
                </span>
                <span>Send Offer</span>
              </p>
              <p>
                <span>
                  <CiDollar />
                </span>
                <span>Loan App</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Comparecard;

import React from "react";
import styles from "./Feedback.module.css";
import { BsChatQuote } from "react-icons/bs";
import { IoMdStar } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";

const Feedbacks = () => {
  const feedbackArray = [
    {
      textContent:
        "Outstanding experience from the welcoming staff to the seamless buying process, I found my perfect car with ease. Highly recommended for excellent service!",
      user: "Sara J Alkh",
    },
    {
      textContent:
        "Exceptional service -  friendly, knowledgeable, staff, a hassle-free buying process, and transparent pricing, highly recommended for a positive car-buying process!",
      user: "Aya M Ahmed",
    },
    {
      textContent:
        "Satisfied customer - the team's professionalism, transparent dealings, and efficient process made my car purchase enjoyable. Highly recommeded for a smooth and positive experience",
      user: "Jemi J Ada",
    },
  ];
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.headerContainer}>
          <span>
            <BsChatQuote />
          </span>
          <span>Feedback</span>
        </div>

        <div className={styles.cardContainer}>
          <button className={styles.leftArrowbtn}>
            <IoIosArrowBack />
          </button>
          <button className={styles.rightArrowbtn}>
            <IoIosArrowForward />
          </button>
          {feedbackArray.map((item, index) => (
            <div key={index} className={styles.card}>
              <p className={styles.starContainer}>
                {[1, 2, 3, 4, 5].map((_, i) => (
                  <span key={i}>
                    <IoMdStar />
                  </span>
                ))}
              </p>
              <p>{item.textContent}</p>
              <p>
                <span>{item.user}</span>
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Feedbacks;

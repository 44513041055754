import React, { useState } from "react";
import Styles from "./Styles/SavedDealerLocations.module.css";
import Image from "../../imgData";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { MdOutlineModeEdit } from "react-icons/md";
import axios from "axios";
import { useSelector } from 'react-redux';
const imgUrl = process.env.REACT_APP_IMG_URL;

const SavedDealerLocations = ({ location, fetchLocation, onEdit, handleAdd, index, editLocation, setShowForm }) => {
  const { id } = useSelector((state) => state.user);
  const { dealerId } = useSelector((state) => state.user);
  const [isHovered, setIsHovered] = useState(false);
  const handleDeleteLocation = async () => {
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_API_URL}/dealer/delete/${location.userId}/${location.id}`
      );
      console.log(res);
      fetchLocation();
    } catch (error) {
      console.log(error);
    }
  };
  const locationId = index + 1
  return (
    <div
      className={Styles.SavedDealerLocationWrapper}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{ display: editLocation && editLocation?.id === location.id ? "none" : "" }}
    >
      <div className={Styles.SavedDealerLocationContainer}>
        <p
          className={`${Styles.SavedDealerLocationheader} ${isHovered ? Styles.hovered : ""
            }`}
        >
          <span>Location-{index + 1}</span>
        </p>

        <div className={Styles.SavedDealerLocationCard_auth}>
          <div
            className={`${Styles.SavedDealerLocationCard} ${isHovered ? Styles.hovered : ""
              }`}
          >
            {locationId === 1 && (
              <p className={Styles.PrimaryLocation}>Primary Location</p>
            )}

            <div className={Styles.SavedDealerLocationCardText}>
              <p className={Styles.SavedDealerLocationCardheaderText}>
                {location.businessName || "Business Name"}
              </p>
              <p className={Styles.SavedDealerLocationCardWebsite}>
                {location.website || "Business website"}
              </p>
              <p
                className={Styles.SavedDealerLocationCardStreetNo}
                style={{ fontWeight: "700" }}
              >
                {location.addressOne || "Address One"}
              </p>
              <p className={Styles.SavedDealerLocationCardAddress}>
                {location.addressTwo}
              </p>
            </div>
            <div className={Styles.SavedDealerLocationCardImage}>
              <img src={location.profilePicture ? `${imgUrl}/${dealerId}/${location.profilePicture}` : Image.car_image} alt="car" />
            </div>
          </div>
          <div className={Styles.auth_icons}>
            {locationId !== 1 ? (
              <p
                className={Styles.deleteIcon}
                onClick={handleDeleteLocation}
                style={{ cursor: "pointer" }}
              >
                <RiDeleteBin6Fill />
              </p>
            ) : null}
            <p className={Styles.Icon}>
              <MdOutlineModeEdit
                style={{ cursor: "pointer" }}

                onClick={() => {
                  handleAdd(true);
                  onEdit(location);
                  // setIsEditing()
                  setShowForm(true);
                }}
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SavedDealerLocations;

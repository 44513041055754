import React from "react";
import Styles from "./DealerFinance.module.css";

const DealerRetailInputs = ({head,type,name,InputValue,handleChange}) => {
  const handleInputChange = (e) => {
    handleChange(name,e.target.value)
  }
  return (
      <div className={Styles.input_wrapper}>
        <p className={Styles.input_header}>{head}</p>
        <div className={Styles.input_container}>
          <input type="text" value={InputValue} onChange={handleInputChange} style={{fontWeight : "600", fontSize : "x-large"}}/>
          <span>{type}</span>
        </div>
      </div>
  );
};

export default DealerRetailInputs;

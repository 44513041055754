import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logoutUser } from '../../Redux/slice/userSlice';

const UseCustomInActivity = ({ children }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const timeoutInMinutes = 15; // Default timeout value

  useEffect(() => {
    let timer;

    const resetTimer = () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        console.log("Session expired due to inactivity");
        // navigate("/");
        // dispatch(logoutUser())
      }, timeoutInMinutes * 60 * 1000); // Convert minutes to milliseconds
    };

    const clearTimer = () => {
      clearTimeout(timer);
    };

    resetTimer();

    const events = ['mousemove', 'keydown', 'scroll', 'resize', 'touchstart', 'touchend'];

    events.forEach(event => {
      window.addEventListener(event, resetTimer);
    });

    return () => {
      events.forEach(event => {
        window.removeEventListener(event, resetTimer);
      });
      clearTimer();
    };
  }, [navigate, timeoutInMinutes]);

  return <>{children}</>;
};

export default UseCustomInActivity;

import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styles from "../Styles/mailAddAcc.module.css";

const DealerAddAccount = () => {

    const [formData, setFormData] = useState({
        radioOption: '',
        selectOption: '',
        pop: {
            email: '',
            mailServer: '',
            incomingPort: '',
        },
        smtp: {
            email: '',
            mailServer: '',
            incomingPort: '',
        },
        password: '',
        confirmPassword: '',
    });


    // Handle change for radio buttons
    const handleRadioChange = (event) => {
        setFormData({
            ...formData,
            radioOption: event.target.value
        });
    };

    // Handle change for text inputs
    const handleTextChange = (e, section) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [section]: {
                ...prevState[section],
                [name]: value
            }
        }));
    };

    // Handle change for select dropdown
    const handleSelectChange = (event) => {
        setFormData({
            ...formData,
            selectOption: event.target.value
        });
    };

    const handlePasswordChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    // Handle form submission
    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('Form submitted:', formData);
    };

    return (
        <Container className={`p-4`}>
            <Row>
                <Col sm={12}>
                    <h5 className={` ${styles.settingHead}`}>Additional Mail Accounts</h5>
                    <p className={` ${styles.subHead}`}>Here you can add your other mail account like gmail, Yahoo etc.</p>
                </Col>
                <Col sm={8}>
                    <form onSubmit={handleSubmit} className={` ${styles.form}`}>
                        <div className={`py-3`}>
                            <label className={`me-3 `} >Choos connection type: </label>
                            <label className={`me-2 `}>
                                <input
                                    type="radio"
                                    value="NON SSL"
                                    checked={formData.radioOption === 'NON SSL'}
                                    onChange={handleRadioChange}
                                />
                                NON SSL
                            </label>
                            <label className={`me-2 `}>
                                <input
                                    type="radio"
                                    value="SSL"
                                    checked={formData.radioOption === 'SSL'}
                                    onChange={handleRadioChange}
                                />
                                SSL
                            </label>
                            <label className={`me-2 `}>
                                <input
                                    type="radio"
                                    value="TLS"
                                    checked={formData.radioOption === 'TLS'}
                                    onChange={handleRadioChange}
                                />
                                <span>TLS</span>
                            </label>
                            <label className={` `}>
                                <input
                                    type="radio"
                                    value="AUTO"
                                    checked={formData.radioOption === 'AUTO'}
                                    onChange={handleRadioChange}
                                />
                                <span>AUTO</span>
                            </label>
                        </div>

                        <div>
                            <label className={` d-flex justify-content-between`}>
                                Choose Provider:
                                <select
                                    value={formData.selectOption}
                                    onChange={handleSelectChange}
                                    style={{
                                        width: '60%',
                                        background: "white",
                                        padding: '2px 10px',
                                        border: '1px solid '
                                    }}
                                >
                                    <option value="">Select a Provider</option>
                                    <option value="Gmail">Gmail</option>
                                    <option value="Yahoo">Yahoo</option>
                                    <option value="AOL">AOL</option>
                                    <option value="Outlook">Outlook</option>
                                    <option value="ICloud">ICloud</option>
                                    <option value="Other">Other</option>
                                </select>
                            </label>
                        </div>

                        <div className={` ${styles.pop}`}>
                            <p>POP</p>
                            <label>
                                User/Email ID:
                                <input
                                    type="text"
                                    name="email"
                                    value={formData.pop.email}
                                    onChange={e => handleTextChange(e, 'pop')}
                                />
                            </label>
                            <label>
                                Mail Server:
                                <input
                                    type="text"
                                    name="mailServer"
                                    value={formData.pop.mailServer}
                                    onChange={e => handleTextChange(e, 'pop')}
                                />
                            </label>
                            <label>
                                Incoming Port:
                                <input
                                    type="text"
                                    name="incomingPort"
                                    value={formData.pop.incomingPort}
                                    onChange={e => handleTextChange(e, 'pop')}
                                />
                            </label>
                        </div>

                        <div className={` ${styles.smtp}`}>
                            <p>SMTP</p>
                            <label>
                                User/Email ID:
                                <input
                                    type="text"
                                    name="email"
                                    value={formData.smtp.email}
                                    onChange={e => handleTextChange(e, 'smtp')}
                                />
                            </label>
                            <label>
                                Mail Server:
                                <input
                                    type="text"
                                    name="mailServer"
                                    value={formData.smtp.mailServer}
                                    onChange={e => handleTextChange(e, 'smtp')}
                                />
                            </label>
                            <label>
                                Incoming Port:
                                <input
                                    type="text"
                                    name="incomingPort"
                                    value={formData.smtp.incomingPort}
                                    onChange={e => handleTextChange(e, 'smtp')}
                                />
                            </label>
                        </div>

                        <div className={` ${styles.password} ${styles.smtp}`}>
                            <p>Password</p>
                            <label>
                                Password:
                                <input
                                    type="password"
                                    name="password"
                                    placeholder='Password'
                                    value={formData.password}
                                    onChange={handlePasswordChange}

                                />
                            </label>

                            <label>
                                Incoming Port:
                                <input
                                    type="password"
                                    name="confirmPassword"
                                    placeholder='Confirm Password'
                                    value={formData.confirmPassword}
                                    onChange={handlePasswordChange}

                                />
                            </label>
                        </div>

                        {/* <button type="submit">Submit</button> */}
                    </form>
                </Col>
                <Col sm={4} className={` d-flex justify-content-center align-items-center`}> <p className={` ${styles.connecting}`}>Connecting</p> </Col>
            </Row>
        </Container>
    )
}

export default DealerAddAccount
import React, { useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import styles from "../Styles/mailAddAcc.module.css";
import comStyles from "../Styles/common.module.css";
import { FaPlus } from "react-icons/fa";

const DealerEmailSign = () => {
  return (
    <Container className={`p-4`}>
      <Row>
        <Col sm={12}>
          <h5 className={` ${styles.settingHead}`}>
            Signatures</h5>
          <p className={`m-0 ${styles.subHead}`}>Signatures can be configured here and associated with an Identity later.</p>
        </Col>
        <Col sm={10}>
          <form className={` ${styles.form}`}>
            <div className={`m-0 ${styles.pop}`}>

              <div className={` ${comStyles.tableCon}`} style={{ minHeight: '25vh' }}>
                <div className={` ${comStyles.tableHead}`}>Signatures</div>
                <table>
                  <thead>
                    <tr>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </div>

            <Button className={`p-1 ${comStyles.saveBtn}`}> <FaPlus />New Signatures</Button>
          </form>
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <h5 className={` ${styles.settingHead}`}>Identity</h5>
          <p className={`m-0 ${styles.subHead}`}>
            Identities are used when composing an email. It allows the sender to associate a set of sender properties with the email. Properties such as Email Address, Reply-to Address, and Signature are stored within an Identity.</p>
        </Col>
        <Col sm={10}>
          <form className={` ${styles.form}`}>
            <div className={`m-0 ${styles.pop}`}>

              <div className={` ${comStyles.tableCon}`} style={{ minHeight: '25vh' }}>
                <div className={` ${comStyles.tableHead}`}>Identity</div>
                <table>
                  <thead>
                    <tr>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </div>

            <Button className={`p-1 ${comStyles.saveBtn}`}> <FaPlus />New Identity</Button>
          </form>
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <h5 className={` ${styles.settingHead}`}>Identity Properties</h5>
        </Col>
        <Col sm={10}>
          <form className={` ${styles.form}`}>
            <div className={`m-0 ${styles.pop}`}>
              <label>
                Name:
                <input
                  type="text"
                  name="name"
                />
              </label>
              <label>
                Email Address:
                <input
                  type="email"
                  name="EmailAddress"
                />
              </label>
              <label>
                Reply-to Address:
                <input
                  type="text"
                  name="ReplyToAddress"
                />
              </label>
              <label className={` d-flex justify-content-between`}>
                Signature:
                <select
                  style={{
                    width: '60%',
                    background: "white",
                    padding: '2px 10px',
                    border: '1px solid '
                  }}
                >
                  <option value="">Select a Provider</option>
                  <option value="Gmail">Gmail</option>
                  <option value="Yahoo">Yahoo</option>
                </select>
              </label>
              <label>
                Signature Preview:
                <textarea
                  name="signaturePreview:"
                  rows="4"  // Adjust rows to control the height of the textarea
                  cols="50" // Adjust cols to control the width of the textarea
                />
              </label>
            </div>
            <div className={` d-flex justify-content-end`}>
              <button className={`me-2 ${comStyles.saveBtn}`} type="submit">Save</button>
              <button className={` ${comStyles.saveBtn}`} type="button">Cancel</button>
            </div>
          </form>
        </Col>
      </Row>
    </Container>
  )
}

export default DealerEmailSign
import React from "react";
import Styles from "./Financing.module.css";

const Financing = () => {
  const arr = [
    {
      head: "New Specials",
      text: "Stay tuned for exciting conclusion of the adventure. Auoda, though silent, could'nt hide ger anxiety.",
    },
    {
      head: "Schedule Service",
      text: "The resolution of the adventure will be apparent soon.Aouda, will keep mum, harbored a hint of anxiety.",
    },
    {
      head: "Used Specials",
      text: "The unfolding of the adventure's finale is imminent. Aouda say nothing, bore a sense of unease.",
    },
    {
      head: "Value Trade",
      text: "The unfolding of the adventure's finale is imminent. Aouda say nothing, bore a sense of unease.",
    },
  ];
  return (
    <div className={Styles.wrapper}>
      <div className={Styles.container}>
        <p className={Styles.header}>
          <span>Shop, Finance and Buy Your Car</span>
        </p>
        <div className={Styles.DataContainer}>
          {arr.map((item, i) => (
            <div key={i} className={Styles.textContainer}>
              <span>{item.head}</span>
              <p>{item.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Financing;

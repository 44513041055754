import React, { useEffect, useRef, useState } from "react";
import CardView from "./CradView";
import "./active.css";
import Image from "../../../imgData";
import { FaEye } from "react-icons/fa";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import {
  Button,
  Col,
  Container,
  Row,
  Table,
  OverlayTrigger,
  Tooltip,
  Modal,
} from "react-bootstrap";
import Pagination from "../../../Pagination/Pagination";
import * as XLSX from "xlsx";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { saveView } from "../../../../Redux/slice/activeSlice";
import { BiReset } from "react-icons/bi";
import AdvertisingProfile from "./AdvertisingProfile";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
// import CustomPrompt from './CustomPrompt';

const apiUrl = process.env.REACT_APP_API_URL;

const DealerAddManager = () => {
  const location = useLocation();
  const data = location.state || {};
  const dispatch = useDispatch();
  const view = useSelector((state) => state.active.view);
  const { id } = useSelector((state) => state.user);
  // For all dropdown button in this page
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [openDropdown, setOpenDropdown] = useState(null);
  const dropdownRef = useRef();

  const [tableData, setTableData] = useState([]);
  const [initialFilters, setInitialFilters] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  // const [showPrompt, setShowPrompt] = useState(false);
  // const [showRowCheck, setShowRowCheck] = useState(false)

  const [email, setEmail] = useState("");
  const handleViewToggle = (newView) => {
    dispatch(saveView(newView));
  };

  // const [sortedData, setSortedData] = useState([]);
  // console.log("line51", sortedData);
  // useEffect(() => {
  //   const data = location.state || {};
  //   console.log("filterData234:", data);
  //   if (Object.keys(data).length > 0) {
  //     setTableData(data);
  //     setSortedData(data);
  //     setIsLoading(false);
  //   } else {
  //     const fetchData = async () => {
  //       try {
  //         const response = await fetch(`${apiUrl}/listing/get_user_listing/${id}`);
  //         if (!response.ok) {
  //           throw new Error("Failed to fetch data");
  //         }
  //         const data = await response.json();
  //         setTableData(data);
  //         setSortedData(data);
  //       } catch (error) {
  //         setError(error);
  //       } finally {
  //         setIsLoading(false);
  //       }
  //     };

  //     fetchData();
  //   }
  // }, [location.state, apiUrl, id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${apiUrl}/listing/get_user_listing/${id}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setTableData(data);
        setInitialFilters(data);
        // setTableData(Array.isArray(data) ? data : []);

        if (response.status === 200) {
          console.log("before handle sort tableData", tableData);
        }
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    const data = location.state || {};
    if (Object.keys(data).length > 0) {
      setTableData(Array.isArray(data) ? data : []);
      setIsLoading(false);
    } else {
      fetchData();
    }

    // Add a cleanup function
    return () => {
      // Cancel any ongoing fetch if component unmounts
    };
  }, []);

  // useEffect(() => {
  //   setSortedData(tableData);
  //   console.log("tableData updated:", tableData);
  // }, [tableData]);
  const dropdownClickedRef = useRef(false);

  const toggleDropdown = (dropdownName) => {
    setOpenDropdown((prevDropdown) => {
      // If the clicked dropdown is already open, close it
      if (prevDropdown === dropdownName) {
        return null;
      } else {
        // Open the clicked dropdown and close any other open dropdown
        return dropdownName;
      }
    });
  };

  const handleOptionClick = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const handleEmailClick = () => {
    // Handle email button click
  };

  const handleDownloadClick = () => {
    // Handle download button click
  };

  // For Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // For calculation of DaysIn collumn
  const [daysDifference, setDaysDifference] = useState(0);
  // Calculate the number of days difference
  const calculateDaysDifference = (inspectionDate) => {
    if (!inspectionDate) return 0;
    const currentDate = new Date();
    const inspectionDateObj = new Date(inspectionDate);
    const timeDifference = inspectionDateObj - currentDate;
    return Math.ceil(Math.abs(timeDifference) / (1000 * 60 * 60 * 24));
  };

  // For pdf geration
  const handlePrintClick = async () => {
    // const selected = selectedRows.map(index => tableData[index].vin);

    try {
      // Generate the XLSX file data
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(tableData);
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      const wbout = XLSX.write(wb, { type: "binary", bookType: "xlsx" });
      const blob = new Blob([s2ab(wbout)], {
        type: "application/octet-stream",
      });

      // Create a URL for the Blob
      const url = URL.createObjectURL(blob);

      // Open the XLSX file in a new window/tab
      const printWindow = window.open(url);

      // Wait for a short delay to ensure the XLSX file is loaded
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Print the content in the new window/tab
      if (printWindow) {
        printWindow.print();
      } else {
        throw new Error("Failed to open print window.");
      }
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
      alert("Failed to print. Please try again.");
    }
  };

  // For selection of cards
  const [selectedCards, setselectedCards] = useState([]);

  const handleSelected = (index) => {
    setselectedCards((prevselectedCards) => {
      if (prevselectedCards.includes(index)) {
        // If the card is already selected, remove it
        return prevselectedCards.filter((i) => i !== index);
      } else {
        // If the card is not selected, add it
        return [...prevselectedCards, index];
      }
    });
  };

  const handleRowClick = (index) => {
    // If the clicked row is already selected, deselect it
    if (selectedRows.includes(index)) {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((selectedIndex) => selectedIndex !== index)
      );
    } else {
      // If the clicked row is not selected, select it
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, index]);
    }
  };

  // Function to handle export button click
  const tableRef = useRef(null);

  const handleExportClick = () => {
    // Show popup/modal/dialog to input email address
    // console.log("tableData", tableData)

    // setShowRowCheck(true);

    const email = prompt("Please enter your email address:");
    // setShowPrompt(true);

    if (email) {
      // Get the data of selected rows
      const selectedVin = selectedRows.map((index) => tableData[index].vin);

      // If user selected rows and entered an email address
      if (selectedVin.length > 0) {
        fetch(`${apiUrl}/active_inventory/sendExcelEmail`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: email, selectedVin: selectedVin }),
        })
          .then((response) => {
            if (response.ok) {
              // If request is successful, display success message
              alert("Email has been sent successfully!");
            } else {
              // If request fails, display error message
              alert("Failed to send email. Please try again.");
            }
          })
          .catch((error) => {
            // If fetch fails, display error message
            console.error("Error:", error);
            alert("An error occurred. Please try again later.");
          });
      } else {
        // If no rows are selected, prompt the user to select rows
        alert("Please select rows to export.");
      }

      console.log("selectedData", selectedVin);
    }
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  const handleSort = (order) => {
    // const sorted = [...tableData].sort((a, b) => {
    //   const dateA = new Date(a.created_at);
    //   const dateB = new Date(b.created_at);
    //   return order === "oldest" ? dateA - dateB : dateB - dateA;
    // });
    console.log("__________order", order);
    if (order) {
      const sorted = [...tableData].sort((a, b) => a.id - b.id);
      console.log("__________sorted newest", sorted);
      setTableData(sorted);
    } else {
      const sorted = [...tableData].sort((a, b) => b.id - a.id);
      console.log("__________sorted oldest", sorted);
      setTableData(sorted);
    }
  };

  const handleWebsite = (event) => {
    event.stopPropagation();
    alert("Added");
  };

  const handleEdit = (event) => {
    event.stopPropagation();
    alert("Edited");
  };

  const handleDelete = async (event, cardId, index) => {
    event.stopPropagation();

    // Display a confirmation dialog
    const userConfirmed = window.confirm(
      "Are you sure you want to delete this inventory item?"
    );

    if (!userConfirmed) {
      // If the user clicks "Cancel", exit the function
      return;
    }

    try {
      const listing_id = "listing";
      const response = await fetch(
        `${apiUrl}/listing/delete/${listing_id}/${id}/${cardId}`,
        {
          method: "DELETE",
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete the card");
      }

      // Update the state to reflect the deletion
      const newTableData = tableData.filter((_, i) => i !== index);
      setTableData(newTableData);
      // setSortedData(newTableData);
    } catch (error) {
      console.error("Error deleting card:", error);
    }
  };

  const handleUpdate = (id, updatedData) => {
    console.log("updatedData>>>>>>>>>>>>>>>>>>>>>>>", updatedData);
    setTableData((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, addvertising: updatedData } : item
      )
    );
    // setTableData((prevData) =>
    //   prevData.map((item) => (item.id === id ? { ...item, addvertising: updatedData } : item))
    // );
  };

  const [filters, setFilters] = useState({
    make: [],
    models: [],
    year: [],
    bodyClass: [],
    engineType: [],
    fuelType: [],
    vehicleType: [],
    owner: [],
    state: [],
    interiorColor: [],
    exteriorColor: [],
    interiorType: [],
    doorCounts: [],
    driveTrain: [],
    odometer: [],
    price: [],
    exterior_damage: [],
    photos: [],
    seller: [id && id],
    zip_code: [],
  });
  // console.log("Filter selected ", filters);

  const [selectedFilter, setSelectedFilter] = useState({
    make: "Make",
    models: "Model",
    year: "Year",
    bodyClass: "Body Type",
  });

  const handleFilterDDClick = (PropToUpdate, ValueForUpdate) => {
    //If ValueForUpdate is null then update the view and api with the value
    if (!ValueForUpdate) {
      setSelectedFilter((prevData) => ({
        ...prevData,
        [PropToUpdate]:
          PropToUpdate === "make"
            ? "Make"
            : PropToUpdate === "models"
              ? "Model"
              : PropToUpdate === "year"
                ? "Year"
                : "Body Type",
      }));
      setFilters((prevData) => ({
        ...prevData,
        [PropToUpdate]: [],
      }));
      return;
    }
    //If ValueForUpdate is not null then update the view and api with the value
    //This is for showing which one is selected in the dropdown
    // if (ValueForUpdate !== null) {
    //   setSelectedFilter((prevData) => ({
    //     ...prevData,
    //     [PropToUpdate]: ValueForUpdate,
    //   }));
    //   //this is for sending in the API which one is updated
    //   setFilters((prevData) => ({
    //     ...prevData,
    //     [PropToUpdate]: [ValueForUpdate],
    //   }));
    // }

    setSelectedFilter((prevData) => ({
      ...prevData,
      [PropToUpdate]: ValueForUpdate,
    }));

    // Set filters: if year, send as array of strings
    const valueToUpdate =
      PropToUpdate === "year"
        ? [String(ValueForUpdate), String(ValueForUpdate)]
        : [ValueForUpdate];

    setFilters((prevData) => ({
      ...prevData,
      [PropToUpdate]: valueToUpdate,
    }));
  };

  const handleResetFilter = () => {
    setFilters({
      make: [],
      models: [],
      year: [],
      bodyClass: [],
      engineType: [],
      fuelType: [],
      vehicleType: [],
      owner: [],
      state: [],
      interiorColor: [],
      exteriorColor: [],
      interiorType: [],
      doorCounts: [],
      driveTrain: [],
      odometer: [],
      price: [],
      exterior_damage: [],
      photos: [],
      seller: [id && id],
      zip_code: [],
    });

    // Reset selected filters to initial state
    setSelectedFilter({
      make: "Make",
      models: "Model",
      year: "Year",
      bodyClass: "Body Type",
    });

    setSelectedSort(null);
    // Use this function wherever you need to reset the filters
  };
  //Here after changing the filter fetch the products again
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/listing/filter_listing`,
          filters
        );
        console.log("response filters from the API", response.data);
        setTableData(Array.isArray(response.data) ? response.data : []);
      } catch (error) {
        console.log("Server side while fetching data during getting filters");
      }
    };
    fetchProducts();
  }, [filters]);
  // ----------for search bar filter -----------------------------------------------------------
  const [inputValue, setInputValue] = useState("");
  const [selectedSort, setSelectedSort] = useState(null);

  const handleSelect = async (selectedStatus) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/listing/get_user_listing/${id}?searchTerm=${selectedStatus}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setTableData(data);
      setSelectedSort(selectedStatus);
      console.log(data);
      // Handle the response data as needed
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSelect(inputValue); // Call the function with the input value
      setInputValue(""); // Clear the input field if desired
    }
  };
  const handleButtonClick = () => {
    handleSelect(inputValue); // Call the function with the input value
    setInputValue(""); // Clear the input field if desired
  };

  const handleSortByColumn = async (sort, column) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/listing/get_user_listing/${id}?searchTerm=${sort}&extraParam=${column}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setTableData(data);
      console.log(data);
      // Handle the response data as needed
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // -------------------------------------------------------------------------------------------

  return (
    <>
      <Container
        fluid
        className="px-5 "
        style={{ paddingLeft: "3rem", marginTop: "-1rem" }}
      >
        <Row
          className=" py-2 d-flex flex-nowrap justify-content-evenly "
          style={{ paddingLeft: "10rem" }}
        >
          <Col sm={1} className=" ">
            {/* Icon in the first column */}
            <div className="toggle-view">
              <img
                src={view == "c" ? Image.cardViewS : Image.cardView}
                alt="card-view"
                width="50%"
                height="100%"
                className="card-view"
                onClick={() => handleViewToggle("c")}
              />

              <img
                src={view == "l" ? Image.listViewS : Image.listView}
                alt="list-view"
                width="50%"
                height="100%"
                className="list-view"
                onClick={() => handleViewToggle("l")}
              />
            </div>
          </Col>

          {/* <Col sm={7} className="ms-4 px-0 d-flex justify-content-center" style={{ gap: "5px" }}>
            <div className="inventory-info_adv">
              <span className="inventory">Total Published</span>
              <span className="inventory-count orange-color">{tableData.length}</span>
            </div>

            <div className="inventory-info_adv">
              <span className="inventory">Not Published</span>
              <span className="inventory-count orange-color">27</span>
            </div>

            <div className="inventory-info_adv">
              <span className="inventory">Most Published</span>
              <span className="inventory-count orange-color">34</span>
            </div>

            <div className="inventory-info_adv">
              <span className="inventory">Most Published</span>
              <span className="inventory-count orange-color">17</span>
            </div>
            <div className="inventory-info_adv">
              <span className="inventory">Most Published</span>
              <span className="inventory-count orange-color">17</span>
            </div>
          </Col> */}
          <Col sm={8} className="px-0 d-flex justify-content-center">
            <div className="inventory-info">
              <span className="inventory">All Inventory:</span>
              <span className="inventory-count orange-color">
                {tableData.length}
              </span>
            </div>

            <div className="inventory-info">
              <span className="inventory">Published:</span>
              <span className="inventory-count orange-color">27</span>
            </div>

            <div className="inventory-info">
              <span className="inventory">Not Published:</span>
              <span className="inventory-count orange-color">34</span>
            </div>

            <div className="inventory-info">
              <span className="inventory">Total Leads:</span>
              <span className="inventory-count orange-color">17</span>
            </div>
          </Col>

          <Col sm={2} className="d-flex justify-content-end align-items-start px-0">
            <Button
              className="btnmediamanager-sec border-0 fw-bold p-2 me-2 mb-0 me-3"
            // onClick={() => handleAdvertisedOnClick()}
            >
              Advertised On
            </Button>

            {view === "l" ? (
              <>
                {/* <Button
                  className="m-2 bg-btn rounded-3 fw-bold export"
                  onClick={handleExportClick}
                >
                  Export
                </Button>
                <Button
                  className="m-2 bg-btn rounded-3 fw-bold print"
                  onClick={handlePrintClick}
                >
                  Print
                </Button> */}
              </>
            ) : null}

            {/* First dropdown */}
            {/* <div className="">
              <button
                className="btn dropdown-toggle me-2 drp-btn page-count"
                type="button"
                onClick={() => toggleDropdown("page-count")}
              >
                10
              </button>
              {openDropdown === "page-count" && (
                <ul
                  className="page-count-list p-0"
                  ref={dropdownRef}
                  style={{ display: openDropdown ? "block" : "none" }}
                >
                  <li>
                    <Button className="dropdown-item">1</Button>
                  </li>
                  <li>
                    <Button className="dropdown-item">2</Button>
                  </li>
                </ul>
              )}
            </div> */}

            {/* Second dropdown */}
            {/* {view === "c" ? ( */}
            {/* <div className="">
              <button
                className="btn dropdown-toggle drp-btn sort-by"
                type="button"
                onClick={() => toggleDropdown("sort-by")}
              >
                Sort by
              </button>
              {openDropdown === "sort-by" && (
                <ul className="sort-by-list p-0"
                  ref={dropdownRef}
                  aria-labelledby="dropdownMenuButton2"
                  style={{ display: openDropdown ? "block" : "none" }}
                >
                  <li
                    className="list-style-none dropdown-item"
                    onClick={() => handleSort("oldest")}
                  >
                    Oldest
                  </li>
                  <li
                    className=" list-style-none dropdown-item"
                    href="#"
                    onClick={() => handleSort("")}
                  >
                    Newest
                  </li>
                </ul>
              )}
            </div> */}
            {/* ) : null} */}
          </Col>
        </Row>

        {view == "l" ? (
          <>
            <Row>
              <Col sm={12} className="px-0" style={{ position: "relative" }}>
                <Container fluid className="px-3">
                  <Row>
                    {/* input field start */}
                    <Col
                      sm={11}
                      className="input-group mt-3 mb-4"
                      id="act-search"
                    >
                      <Button
                        className="btn border-0 dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{ backgroundColor: "#e8a262" }}
                      >
                        All
                      </Button>
                      <ul className="dropdown-menu">
                        <li onClick={() => handleSelect("Price10k")}>
                          <Link className="dropdown-item" to="#">
                            Price &lt; 10k
                          </Link>
                        </li>
                        <li onClick={() => handleSelect("Mileage50k")}>
                          <Link className="dropdown-item" to="#">
                            Miles &lt; 50k
                          </Link>
                        </li>
                        <li onClick={() => handleSelect("Mileage100k")}>
                          <Link className="dropdown-item" to="#">
                            Miles &lt; 100k
                          </Link>
                        </li>
                      </ul>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search Inventory"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)} // Update state on input change
                        onKeyPress={handleKeyPress} // Call handleKeyPress on key press
                      />
                      <Button
                        className="btn border-0"
                        type="button"
                        id="button-addon1"
                        style={{ backgroundColor: "#e8a262" }}
                        onClick={handleButtonClick}
                      >
                        <i className="fas fa-search"></i>
                      </Button>

                      <Button
                        className={`btn border-0 dropdown-toggle btn-eq-wd ${selectedFilter.year === "Year" || selectedFilter.year === null ? "" : 'bg-secondary'}`}
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        id="dropdownYear" // Added ID for accessibility
                        style={{
                          backgroundColor: "#e8a262",
                          margin: "0 0.3rem",
                        }}
                      >
                        {selectedFilter.year}
                      </Button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownYear"
                        style={{ maxHeight: "300px", overflowY: "scroll" }}
                      >
                        <li onClick={() => handleFilterDDClick("year", null)}>
                          <Link className={`dropdown-item ${selectedFilter.year === null ? "selected-item" : ""}`} to="#">
                            Select None
                          </Link>
                        </li>
                        {initialFilters &&
                          [...new Set(initialFilters.map((car) => car.year))]
                            .sort((a, b) => a - b)
                            .map((year, index) => (
                              <li
                                key={index}
                                onClick={() =>
                                  handleFilterDDClick("year", year)
                                }
                              >
                                <Link className={`dropdown-item ${selectedFilter.year === year ? "selected-item" : ""}`} to="#">
                                  {year}
                                </Link>
                              </li>
                            ))}
                      </ul>

                      <Button
                        className={`btn border-0 dropdown-toggle btn-eq-wd ${selectedFilter.make === "Make" || selectedFilter.make === null ? "" : 'bg-secondary'}`}
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        id="dropdownMake" // Added ID for accessibility
                        style={{ backgroundColor: "#e8a262" }}
                      >
                        {selectedFilter.make}
                      </Button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMake"
                        style={{ maxHeight: "300px", overflowY: "scroll" }}
                      >
                        <li onClick={() => handleFilterDDClick("make", null)}>
                          <Link className={`dropdown-item ${selectedFilter.make === null ? "selected-item" : ""
                            }`} to="#">
                            Select None
                          </Link>
                        </li>
                        {initialFilters &&
                          [...new Set(initialFilters.map((car) => car.make))]
                            .sort()
                            .map((make, index) => (
                              <li
                                key={index}
                                onClick={() =>
                                  handleFilterDDClick("make", make)
                                }
                              >
                                <Link className={`dropdown-item ${selectedFilter.make === make ? "selected-item" : ""
                                  }`} to="#">
                                  {make}
                                </Link>
                              </li>
                            ))}
                      </ul>

                      <Button
                        className={`btn border-0 dropdown-toggle btn-eq-wd ${selectedFilter.models === "Model" || selectedFilter.models === null ? "" : 'bg-secondary'}`}
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        id="dropdownModel" // Added ID for accessibility
                        style={{
                          backgroundColor: "#e8a262",
                          margin: "0 0.3rem",
                        }}
                      >
                        {selectedFilter.models}
                      </Button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownModel"
                        style={{ maxHeight: "300px", overflowY: "scroll" }}
                      >
                        <li onClick={() => handleFilterDDClick("models", null)}>
                          <Link className={`dropdown-item ${selectedFilter.models === null ? "selected-item" : ""
                            }`} to="#">
                            Select None
                          </Link>
                        </li>
                        {initialFilters &&
                          [...new Set(initialFilters.map((car) => car.model))]
                            .sort()
                            .map((model, index) => (
                              <li
                                key={index}
                                onClick={() =>
                                  handleFilterDDClick("models", model)
                                }
                              >
                                <Link className={`dropdown-item ${selectedFilter.models === model ? "selected-item" : ""
                                  }`} to="#">
                                  {model}
                                </Link>
                              </li>
                            ))}
                      </ul>

                      <Button
                        className={`btn border-0 dropdown-toggle btn-eq-wd ${selectedFilter.bodyClass === "Body Type" || selectedFilter.bodyClass === null ? "" : 'bg-secondary'}`}
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        id="bodyClass" // Added ID for accessibility
                        style={{
                          backgroundColor: "#e8a262",
                          margin: "0 0.3rem",
                        }}
                      >
                        {selectedFilter.bodyClass}
                      </Button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="bodyClass"
                        style={{ maxHeight: "300px", overflowY: "scroll" }}
                      >
                        <li
                          onClick={() => handleFilterDDClick("bodyClass", null)}
                        >
                          <Link className={`dropdown-item ${selectedFilter.bodyClass === null ? "selected-item" : ""
                            }`} to="#">
                            Select None
                          </Link>
                        </li>
                        {initialFilters &&
                          [
                            ...new Set(
                              initialFilters.map((car) => car.bodyClass)
                            ),
                          ]
                            .sort()
                            .map((bodyClass, index) => (
                              <li
                                key={index}
                                onClick={() =>
                                  handleFilterDDClick("bodyClass", bodyClass)
                                }
                              >
                                <Link className={`dropdown-item ${selectedFilter.bodyClass === bodyClass ? "selected-item" : ""
                                  }`} to="#">
                                  {bodyClass}
                                </Link>
                              </li>
                            ))}
                      </ul>

                      <Button
                        className={`btn border-0 dropdown-toggle btn-eq-wd ${selectedSort ? 'bg-secondary' : ""}`}
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        id="sortBy" // Added ID for accessibility
                        style={{
                          backgroundColor: "#e8a262",
                          margin: "0 0.3rem",
                        }}
                      >
                        Sort BY
                      </Button>
                      <ul className="dropdown-menu"
                        aria-labelledby="sortBy">
                        <li onClick={() => handleSelect("Desc")}>
                          <Link className={`dropdown-item ${selectedSort === "Desc" ? 'selected-item' : ''}`} to="#">
                            Newest
                          </Link>
                        </li>
                        <li onClick={() => handleSelect("Asc")}>
                          <Link className={`dropdown-item ${selectedSort === "Asc" ? 'selected-item' : ''}`} to="#">
                            Oldest
                          </Link>
                        </li>
                      </ul>

                      <Button
                        className="btn border-0 btn-eq-wd"
                        type="button"
                        id="button-addon1"
                        style={{ backgroundColor: "#e8a262" }}
                        onClick={handleResetFilter}
                      >
                        <BiReset className="icons-r-flt" />
                      </Button>
                    </Col>
                    {/* input field end */}
                  </Row>
                </Container>
              </Col>
            </Row>

            <Row
              className="mx-0 rounded  bg-body border-2"
              style={{
                position: "relative",
                top: "",
                zIndex: "5 ",
                // minWidth: "1000px", overflowX: "scroll"
              }}
            >
              {/* <BasicDocument /> */}
              <ListView
                tableData={tableData}
                currentPage={currentPage}
                tableRef={tableRef}
                selectedRows={selectedRows}
                handleRowClick={handleRowClick}
                onUpdate={handleUpdate}
                handleSortByColumn={handleSortByColumn}
              />

              {/* <Col sm={12}>
                <Pagination
                  onPageChange={handlePageChange}
                  currentPage={currentPage}
                />
              </Col> */}
            </Row>
          </>
        ) : (
          <>
            <Row>
              <Col sm={1} className="h-100">
                {/* <div className="select-btn"></div> */}
              </Col>
              <Col sm={11} className="px-0" style={{ position: "relative" }}>
                <Container fluid className="px-3">
                  <Row>
                    {/* input field start */}
                    <Col
                      sm={12}
                      className="input-group mt-3 mb-4"
                      id="act-search"
                    >
                      <Button
                        className="btn border-0 dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{ backgroundColor: "#e8a262" }}
                      >
                        All
                      </Button>
                      <ul className="dropdown-menu">
                        <li onClick={() => handleSelect("Price10k")}>
                          <Link className="dropdown-item" to="#">
                            Price &lt; 10k
                          </Link>
                        </li>
                        <li onClick={() => handleSelect("Mileage50k")}>
                          <Link className="dropdown-item" to="#">
                            Miles &lt; 50k
                          </Link>
                        </li>
                        <li onClick={() => handleSelect("Mileage100k")}>
                          <Link className="dropdown-item" to="#">
                            Miles &lt; 100k
                          </Link>
                        </li>
                      </ul>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search Inventory"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)} // Update state on input change
                        onKeyPress={handleKeyPress} // Call handleKeyPress on key press
                      />
                      <Button
                        className="btn border-0"
                        type="button"
                        id="button-addon1"
                        style={{ backgroundColor: "#e8a262" }}
                        onClick={handleButtonClick}
                      >
                        <i className="fas fa-search"></i>
                      </Button>

                      <Button
                        className={`btn border-0 dropdown-toggle btn-eq-wd ${selectedFilter.year === "Year" || selectedFilter.year === null ? "" : 'bg-secondary'}`}
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        id="dropdownYear" // Added ID for accessibility
                        style={{
                          backgroundColor: "#e8a262",
                          margin: "0 0.3rem",
                        }}
                      >
                        {selectedFilter.year}
                      </Button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownYear"
                        style={{ maxHeight: "300px", overflowY: "scroll" }}
                      >
                        <li onClick={() => handleFilterDDClick("year", null)}>
                          <Link className={`dropdown-item ${selectedFilter.year === null ? "selected-item" : ""
                            }`} to="#">
                            Select None
                          </Link>
                        </li>
                        {initialFilters &&
                          [...new Set(initialFilters.map((car) => car.year))]
                            .sort((a, b) => a - b)
                            .map((year, index) => (
                              <li
                                key={index}
                                onClick={() =>
                                  handleFilterDDClick("year", year)
                                }
                              >
                                <Link className={`dropdown-item ${selectedFilter.year === year ? "selected-item" : ""
                                  }`} to="#">
                                  {year}
                                </Link>
                              </li>
                            ))}
                      </ul>

                      <Button
                        className={`btn border-0 dropdown-toggle btn-eq-wd ${selectedFilter.make === "Make" || selectedFilter.make === null ? "" : 'bg-secondary'}`}
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        id="dropdownMake" // Added ID for accessibility
                        style={{ backgroundColor: "#e8a262" }}
                      >
                        {selectedFilter.make}
                      </Button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMake"
                        style={{ maxHeight: "300px", overflowY: "scroll" }}
                      >
                        <li onClick={() => handleFilterDDClick("make", null)}>
                          <Link className={`dropdown-item ${selectedFilter.make === '' ? "selected-item" : ""
                            }`} to="#">
                            Select None
                          </Link>
                        </li>
                        {initialFilters &&
                          [...new Set(initialFilters.map((car) => car.make))]
                            .sort()
                            .map((make, index) => (
                              <li
                                key={index}
                                onClick={() =>
                                  handleFilterDDClick("make", make)
                                }
                              >
                                <Link className={`dropdown-item ${selectedFilter.make === make ? "selected-item" : ""
                                  }`} to="#">
                                  {make}
                                </Link>
                              </li>
                            ))}
                      </ul>

                      <Button
                        className={`btn border-0 dropdown-toggle btn-eq-wd ${selectedFilter.models === "Model" || selectedFilter.models === null ? "" : 'bg-secondary'}`}
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        id="dropdownModel" // Added ID for accessibility
                        style={{
                          backgroundColor: "#e8a262",
                          margin: "0 0.3rem",
                        }}
                      >
                        {selectedFilter.models}
                      </Button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownModel"
                        style={{ maxHeight: "300px", overflowY: "scroll" }}
                      >
                        <li onClick={() => handleFilterDDClick("models", null)}>
                          <Link className={`dropdown-item ${selectedFilter.models === null ? "selected-item" : ""
                            }`} to="#">
                            Select None
                          </Link>
                        </li>
                        {initialFilters &&
                          [...new Set(initialFilters.map((car) => car.model))]
                            .sort()
                            .map((model, index) => (
                              <li
                                key={index}
                                onClick={() =>
                                  handleFilterDDClick("models", model)
                                }
                              >
                                <Link className={`dropdown-item ${selectedFilter.models === model ? "selected-item" : ""
                                  }`} to="#">
                                  {model}
                                </Link>
                              </li>
                            ))}
                      </ul>

                      <Button
                        className={`btn border-0 dropdown-toggle btn-eq-wd ${selectedFilter.bodyClass === "Body Type" || selectedFilter.bodyClass === null ? "" : 'bg-secondary'}`}
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        id="bodyClass" // Added ID for accessibility
                        style={{
                          backgroundColor: "#e8a262",
                          margin: "0 0.3rem",
                        }}
                      >
                        {selectedFilter.bodyClass}
                      </Button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="bodyClass"
                        style={{ maxHeight: "300px", overflowY: "scroll" }}
                      >
                        <li
                          onClick={() => handleFilterDDClick("bodyClass", null)}
                        >
                          <Link className={`dropdown-item ${selectedFilter.bodyClass === null ? "selected-item" : ""
                            }`} to="#">
                            Select None
                          </Link>
                        </li>
                        {initialFilters &&
                          [
                            ...new Set(
                              initialFilters.map((car) => car.bodyClass)
                            ),
                          ]
                            .sort()
                            .map((bodyClass, index) => (
                              <li
                                key={index}
                                onClick={() =>
                                  handleFilterDDClick("bodyClass", bodyClass)
                                }
                              >
                                <Link className={`dropdown-item ${selectedFilter.bodyClass === bodyClass ? "selected-item" : ""
                                  }`} to="#">
                                  {bodyClass}
                                </Link>
                              </li>
                            ))}
                      </ul>

                      <Button
                        className={`btn border-0 dropdown-toggle btn-eq-wd ${selectedSort ? 'bg-secondary' : ""}`}
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        id="sortBy" // Added ID for accessibility
                        style={{
                          backgroundColor: "#e8a262",
                          margin: "0 0.3rem",
                        }}
                      >
                        Sort BY
                      </Button>
                      <ul className="dropdown-menu" aria-labelledby="sortBy">
                        <li onClick={() => handleSelect("Desc")}>
                          <Link className={`dropdown-item ${selectedSort === "Desc" ? 'selected-item' : ''}`} to="#">
                            Newest
                          </Link>
                        </li>
                        <li onClick={() => handleSelect("Asc")}>
                          <Link className={`dropdown-item ${selectedSort === "Asc" ? 'selected-item' : ''}`} to="#">
                            Oldest
                          </Link>
                        </li>
                      </ul>

                      <Button
                        className="btn border-0 btn-eq-wd"
                        type="button"
                        id="button-addon1"
                        style={{ backgroundColor: "#e8a262" }}
                        onClick={handleResetFilter}
                      >
                        <BiReset className="icons-r-flt" />
                      </Button>
                    </Col>
                    {/* input field end */}
                  </Row>
                </Container>
              </Col>
            </Row>

            <Row className="mx-0 rounded bg-body border-2 bg-tr">
              {Array.isArray(tableData) ? (
                tableData.map((data, index) => {
                  return (
                    <CardView
                      data={{
                        ...data,
                        daysDifference: calculateDaysDifference(
                          data.inspection
                        ),
                      }}
                      index={index}
                      key={index}
                      selectedCards={selectedCards}
                      handleSelected={handleSelected}
                      handleWebsite={handleWebsite}
                      handleEdit={handleEdit}
                      handleDelete={handleDelete}
                      onUpdate={handleUpdate}
                    />
                  );
                })
              ) : (
                <p>No data available</p>
              )}
            </Row>
          </>
        )}
      </Container>
    </>
  );
};

// Define the calculateDaysDifference function outside of ListView
const calculateDaysDifference = (inspectionDate) => {
  if (!inspectionDate) return 0;
  const currentDate = new Date();
  const inspectionDateObj = new Date(inspectionDate);
  const timeDifference = inspectionDateObj - currentDate;
  return Math.ceil(Math.abs(timeDifference) / (1000 * 60 * 60 * 24));
};

const ListView = ({
  tableData,
  currentPage,
  tableRef,
  selectedRows,
  handleRowClick,
  onUpdate,
  handleSortByColumn
}) => {
  const Navigate = useNavigate();

  const location = useLocation();
  // Extract data and originalData from location state
  const originalData = location.state?.originalData || {};
  const updatedData = location.state?.updatedData || {};

  const isFieldUpdated = (data, fieldName) => {
    if (data.vin === updatedData.vin) {
      return originalData[fieldName] !== updatedData[fieldName];
    }
  };

  const handlePublish = (id) => {
    // Handle publish event
    console.log(`Publishing item with id: ${id}`);
  };

  const handleLinkClick = (vin, data) => {
    // Navigate to AddList component programmatically with state
    Navigate("/dealer/inventory/add", { state: { vin, data, active: true } });
    // Navigate('/myprofile/addlist', { state: vin, });
  };

  const itemsPerPage = 20; // Change this value according to your preference
  const totalItems = tableData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  // Calculate current page items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = tableData.slice(indexOfFirstItem, indexOfLastItem);

  // Add empty rows if necessary
  const emptyRowsCount = Math.max(itemsPerPage - currentItems.length, 0);
  const emptyRows = Array.from({ length: emptyRowsCount }).map((_, index) => (
    <tr key={`empty-${index}`}>
      {[...Array(13)].map((_, i) => (
        <td key={i}>&nbsp;</td>
      ))}
    </tr>
  ));

  const [show, setShow] = useState(false);
  const [socialIcons, setSocialIcons] = useState([]);
  const [inventoryId, setInventoryId] = useState(0);
  const [advertisingPro, setAdvertisingPro] = useState({
    listing_id: 0,
    dacmp_status: true,
    dacmp_interval: "",
    unitedmotors_status: true,
    unitedmotors_interval: "",
    google_status: false,
    google_interval: "",
    insta_status: false,
    insta_interval: "",
    facebook_status: false,
    facebook_interval: "",
    facebook_marketplace_status: false,
    facebook_groups_status: false,
    craigslist_status: false,
    craigslist_interval: "",
    twitter_status: false,
    twitter_interval: "",
  });

  const handleAddsPro = (e, data) => {
    e.stopPropagation();
    setInventoryId(data.id);
    setShow(true);
    console.log("seting Profils");
    if (data && data.addvertising) {
      setAdvertisingPro((prev) => ({
        ...prev,
        listing_id: data.id,
        dacmp_interval: data.addvertising.dacmp_interval ?? prev.dacmp_interval,
        unitedmotors_interval:
          data.addvertising.unitedmotors_interval ?? prev.unitedmotors_interval,
        google_status: data.addvertising.google_status,
        google_interval:
          data.addvertising.google_interval ?? prev.google_interval,
        insta_status: data.addvertising.insta_status,
        insta_interval: data.addvertising.insta_interval ?? prev.insta_interval,
        facebook_status: data.addvertising.facebook_status,
        facebook_interval:
          data.addvertising.facebook_interval ?? prev.facebook_interval,
        craigslist_status: data.addvertising.craigslist_status,
        craigslist_interval:
          data.addvertising.craigslist_interval ?? prev.craigslist_interval,
        twitter_status: data.addvertising.twitter_status,
        twitter_interval:
          data.addvertising.twitter_interval ?? prev.twitter_interval,
      }));
    }
  };

  const handleCheckboxChange = (e, intervalName) => {
    console.log("handleCheckboxChange", e);
    const { name, checked } = e.target;
    // setAdvertisingPro((prev) => ({ ...prev, [name]: checked }));

    if (name === "facebook_status" && checked === false) {
      setAdvertisingPro((prev) => ({
        ...prev,
        facebook_marketplace_status: false,
        facebook_groups_status: false,
      }));
    }

    // Reset the interval if the checkbox is unchecked
    const resetInterval = !checked ? "" : advertisingPro[intervalName];

    setAdvertisingPro((prev) => ({
      ...prev,
      [name]: checked,
      [intervalName]: resetInterval,
    }));
  };

  const handleSelectChange = (e) => {
    console.log("handleSelectChange");
    e.stopPropagation();
    // e.preventDefault();
    const { name, value } = e.target;
    setAdvertisingPro((prev) => ({ ...prev, [name]: value }));
  };

  const resetCheckboxes = () => {
    setAdvertisingPro({
      listing_id: null,
      dacmp_status: true,
      // dacmp_interval: "",
      unitedmotors_status: true,
      // unitedmotors_interval: "",
      google_status: false,
      google_interval: "",
      insta_status: false,
      insta_interval: "",
      facebook_status: false,
      facebook_interval: "",
      craigslist_status: false,
      craigslist_interval: "",
      twitter_status: false,
      twitter_interval: "",
    });
  };

  // Handle image toggle state for all profiles
  const [connectionStatus, setConnectionStatus] = useState({
    dacmp_connected: false,
    unitedmotors_connected: false,
    google_connected: false,
    insta_connected: false,
    facebook_connected: false,
    craigslist_connected: false,
    twitter_connected: false,
  });

  const toggleConnectionStatus = (profileName) => {
    setConnectionStatus((prevStatus) => ({
      ...prevStatus,
      [profileName]: !prevStatus[profileName],
    }));
  };

  const setAllIntervals = (interval) => {
    console.log("interval in setAllIntervals", interval);
    setAdvertisingPro((prev) => ({
      ...prev,
      dacmp_interval: prev.dacmp_status ? interval : prev.dacmp_interval,
      unitedmotors_interval: prev.unitedmotors_status
        ? interval
        : prev.unitedmotors_interval,
      google_interval: prev.google_status ? interval : prev.google_interval,
      insta_interval: prev.insta_status ? interval : prev.insta_interval,
      facebook_interval: prev.facebook_status
        ? interval
        : prev.facebook_interval,
      craigslist_interval: prev.craigslist_status
        ? interval
        : prev.craigslist_interval,
      twitter_interval: prev.twitter_status ? interval : prev.twitter_interval,
    }));
  };

  // Function to post advertisingPro data
  const updateAdvertisement = async () => {
    try {
      console.log(advertisingPro);
      // console.log('Payload being sent:', JSON.stringify(advertisingPro, null, 2));

      const response = await fetch(`${apiUrl}/listing/update_advertisement`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(advertisingPro),
      });

      if (response.ok) {
        const updatedData = await response.json();
        console.log("Advertisement updated successfully:", updatedData);

        // Notify parent component of the update
        // if (onUpdate) onUpdate(data.id, advertisingPro);
        if (onUpdate) onUpdate(inventoryId, advertisingPro);

        // Update local state with the new data
        // setAdvertisingPro((prev) => ({
        //     ...prev,
        //     ...updatedData, // Assume the API response contains the updated fields
        // }));
      } else {
        console.error("Failed to update advertisement:", response.statusText);
        // Handle the error response as needed
      }
    } catch (error) {
      console.error("Error updating advertisement:", error);
      // Handle any errors that occur during the fetch
    } finally {
      setShow(false);
    }
  };

  const [facebookMarketplaceStatus, setFacebookMarketplaceStatus] =
    useState(false);
  const [facebookGroupsStatus, setFacebookGroupsStatus] = useState(false);

  const handleFacebookCheckboxChange = (checked, name) => {
    console.log("______checked", name, checked, facebookMarketplaceStatus);
    setAdvertisingPro((prev) => ({ ...prev, [name]: checked }));
    setFacebookMarketplaceStatus(checked);
    // console.log("advertisingPro", advertisingPro);
  };

  useEffect(() => {
    setTimeout(() => {
      console.log("advertisingPro_______________", advertisingPro);
    }, 200);
  }, [facebookMarketplaceStatus, advertisingPro]);

  const renderTableRow = (item, index) => {
    if (!item.year_make_model) {
      return null;
    }
    const year_make_model = item.year_make_model;
    const [year, make, model, series, bodyClass] = year_make_model.split(" ");

    // Calculate days difference for the inspection date
    const daysDifference = calculateDaysDifference(item.inspection);
    const daysDifferencePurchase = calculateDaysDifference(item.purchasedDate);

    return (
      <tr
        key={index}
        className={selectedRows.includes(index) ? "selected-rows" : ""}
        onClick={() => item && handleRowClick(index)}
      >
        {item ? (
          <>
            <td>{index + 1}</td>
            <td
              className="ac-col fw-bold"
              onClick={() => handleLinkClick(item.vin, item)}
            >
              {item.vin.slice(-8)}
            </td>
            <td>{item.year}</td>
            <td>{item.make}</td>
            <td>{item.model}</td>
            <td
              style={{
                textShadow: isFieldUpdated(item, "exteriorColor")
                  ? "#ff8c21 1px 0 10px"
                  : "black",
              }}
            >
              {item.exteriorColor}
            </td>
            <td>{item.bodyClass}</td>
            {/* <td
              className="vin-column fw-bold"
              onClick={() => handleLinkClick(item.vin, item)}
            >
              {item.vin}
            </td> */}
            <td
              style={{
                textShadow: isFieldUpdated(item, "mileage_in")
                  ? "#ff8c21 1px 0 10px"
                  : "black",
                fontWeight: "700",
              }}
            >
              {item.mileage_in}
            </td>

            <OverlayTrigger
              placement="bottom-start"
              overlay={
                <Tooltip id={`tooltip-${index}`} className="custom-tooltip">
                  Purchased Date: <br />
                  {item.purchasedDate}
                </Tooltip>
              }
            >
              <td
                style={{
                  textShadow: isFieldUpdated(item, "inspection")
                    ? "#ff8c21 1px 0 10px"
                    : "black",
                }}
              >
                {daysDifferencePurchase}
              </td>
            </OverlayTrigger>

            {/* <td>{daysDifference}</td>  */}
            <td
              style={{
                textShadow: isFieldUpdated(item, "listing_price")
                  ? "#ff8c21 1px 0 10px"
                  : "black",
              }}
            >
              $ {item.listing_price}
            </td>

            <OverlayTrigger
              placement="bottom-start"
              overlay={
                <Tooltip id={`tooltip-${index}`} className="custom-tooltip">
                  Inspection Date: <br />
                  {item.inspection}
                </Tooltip>
              }
            >
              <td
                className="border-0 mb-0"
                style={{
                  textShadow: isFieldUpdated(item, "inspection")
                    ? "#ff8c21 1px 0 10px"
                    : "black",
                }}
              >
                {/* {item.inspection}{" "} */}
                <span className="border-0 px-1"> {daysDifference}</span>
              </td>
            </OverlayTrigger>
            <td>{item.allLeads}</td>
            <td className="text-center">
              <Button
                // onClick={() => handlePublish(item.id)}
                onClick={(e) => handleAddsPro(e, item)}
                className="publish-btn border-0 py-1"
              >
                Live
              </Button>
            </td>
          </>
        ) : (
          Array.from({ length: 13 }).map((_, i) => <td key={i}>&nbsp;</td>)
        )}
      </tr>
    );
  };

  // Calculate total mileage
  const totalMileage = tableData.reduce(
    (total, item) => total + item.mileage_in,
    0
  );

  // Calculate total mileage
  const listingPrice = tableData.reduce(
    (total, price) => total + price.listing_price,
    0
  );

  return (
    <>
      <div className="table-wrapper rounded px-0 table-scrollbar">
        <Table
          striped
          bordered
          hover
          ref={tableRef}
          className="shadow mb-0"
          id="table"
        >
          <thead>
            <tr>
              <th>No#</th>
              <th className="ac-col">Stock. No#
                <span className="filter-icons">
                  <OverlayTrigger
                    key="bottom-start"
                    placement="bottom-start"
                    overlay={<Tooltip id={`tooltip-bottom-start`} className="table-tooltip" >Descending</Tooltip>}
                  >
                    <span onClick={() => handleSortByColumn('Desc', "Stock")}><TiArrowSortedUp /></span>
                  </OverlayTrigger>

                  <OverlayTrigger
                    key="bottom-start"
                    placement="bottom-start"
                    overlay={<Tooltip id={`tooltip-bottom-start`} className="table-tooltip" >Ascending</Tooltip>}
                  >
                    <span onClick={() => handleSortByColumn('Asc', "Stock")}><TiArrowSortedDown /></span>
                  </OverlayTrigger>
                </span>
              </th>

              <th>Year
                <span className="filter-icons">
                  <OverlayTrigger
                    key="bottom-start"
                    placement="bottom-start"
                    overlay={<Tooltip id={`tooltip-bottom-start`} className="table-tooltip" >Descending</Tooltip>}
                  >
                    <span onClick={() => handleSortByColumn('Desc', "Vehicle")}><TiArrowSortedUp /></span>
                  </OverlayTrigger>

                  <OverlayTrigger
                    key="bottom-start"
                    placement="bottom-start"
                    overlay={<Tooltip id={`tooltip-bottom-start`} className="table-tooltip" >Ascending</Tooltip>}
                  >
                    <span onClick={() => handleSortByColumn('Asc', "Vehicle")}><TiArrowSortedDown /></span>
                  </OverlayTrigger>
                </span>
              </th>

              <th>Make</th>

              <th>Model</th>

              <th>Color
                <span className="filter-icons">
                  <OverlayTrigger
                    key="bottom-start"
                    placement="bottom-start"
                    overlay={<Tooltip id={`tooltip-bottom-start`} className="table-tooltip" >Descending</Tooltip>}
                  >
                    <span onClick={() => handleSortByColumn('Desc', "Color")}><TiArrowSortedUp /></span>
                  </OverlayTrigger>

                  <OverlayTrigger
                    key="bottom-start"
                    placement="bottom-start"
                    overlay={<Tooltip id={`tooltip-bottom-start`} className="table-tooltip" >Ascending</Tooltip>}
                  >
                    <span onClick={() => handleSortByColumn('Asc', "Color")}><TiArrowSortedDown /></span>
                  </OverlayTrigger>
                </span>
              </th>

              <th>Body Class
                <span className="filter-icons">
                  <OverlayTrigger
                    key="bottom-start"
                    placement="bottom-start"
                    overlay={<Tooltip id={`tooltip-bottom-start`} className="table-tooltip" >Descending</Tooltip>}
                  >
                    <span onClick={() => handleSortByColumn('Desc', "Body Style")}><TiArrowSortedUp /></span>
                  </OverlayTrigger>

                  <OverlayTrigger
                    key="bottom-start"
                    placement="bottom-start"
                    overlay={<Tooltip id={`tooltip-bottom-start`} className="table-tooltip" >Ascending</Tooltip>}
                  >
                    <span onClick={() => handleSortByColumn('Asc', "Body Style")}><TiArrowSortedDown /></span>
                  </OverlayTrigger>
                </span>
              </th>

              {/* <th className="vin-column">VIN</th> */}
              <th>Miles
                <span className="filter-icons">
                  <OverlayTrigger
                    key="bottom-start"
                    placement="bottom-start"
                    overlay={<Tooltip id={`tooltip-bottom-start`} className="table-tooltip" >Descending</Tooltip>}
                  >
                    <span onClick={() => handleSortByColumn('Desc', "Mileage")}><TiArrowSortedUp /></span>
                  </OverlayTrigger>

                  <OverlayTrigger
                    key="bottom-start"
                    placement="bottom-start"
                    overlay={<Tooltip id={`tooltip-bottom-start`} className="table-tooltip" >Ascending</Tooltip>}
                  >
                    <span onClick={() => handleSortByColumn('Asc', "Mileage")}><TiArrowSortedDown /></span>
                  </OverlayTrigger>
                </span>
              </th>

              <th>Days P
                <span className="filter-icons">
                  <OverlayTrigger
                    key="bottom-start"
                    placement="bottom-start"
                    overlay={<Tooltip id={`tooltip-bottom-start`} className="table-tooltip" >Descending</Tooltip>}
                  >
                    <span onClick={() => handleSortByColumn('Desc', "Purchase Date")}><TiArrowSortedUp /></span>
                  </OverlayTrigger>

                  <OverlayTrigger
                    key="bottom-start"
                    placement="bottom-start"
                    overlay={<Tooltip id={`tooltip-bottom-start`} className="table-tooltip" >Ascending</Tooltip>}
                  >
                    <span onClick={() => handleSortByColumn('Asc', "Purchase Date")}><TiArrowSortedDown /></span>
                  </OverlayTrigger>
                </span>
              </th>

              <th>Listing Price
                <span className="filter-icons">
                  <OverlayTrigger
                    key="bottom-start"
                    placement="bottom-start"
                    overlay={<Tooltip id={`tooltip-bottom-start`} className="table-tooltip" >Descending</Tooltip>}
                  >
                    <span onClick={() => handleSortByColumn('Desc', "Listing Price")}><TiArrowSortedUp /></span>
                  </OverlayTrigger>

                  <OverlayTrigger
                    key="bottom-start"
                    placement="bottom-start"
                    overlay={<Tooltip id={`tooltip-bottom-start`} className="table-tooltip" >Ascending</Tooltip>}
                  >
                    <span onClick={() => handleSortByColumn('Asc', "Listing Price")}><TiArrowSortedDown /></span>
                  </OverlayTrigger>
                </span>
              </th>

              <th>Days In
                <span className="filter-icons">
                  <OverlayTrigger
                    key="bottom-start"
                    placement="bottom-start"
                    overlay={<Tooltip id={`tooltip-bottom-start`} className="table-tooltip" >Descending</Tooltip>}
                  >
                    <span onClick={() => handleSortByColumn('Desc', "Inspection Date")}><TiArrowSortedUp /></span>
                  </OverlayTrigger>

                  <OverlayTrigger
                    key="bottom-start"
                    placement="bottom-start"
                    overlay={<Tooltip id={`tooltip-bottom-start`} className="table-tooltip" >Ascending</Tooltip>}
                  >
                    <span onClick={() => handleSortByColumn('Asc', "Inspection Date")}><TiArrowSortedDown /></span>
                  </OverlayTrigger>
                </span>
              </th>

              <th>All Leads</th>

              <th>Website</th>

            </tr>
          </thead>
          <tbody>
            {currentItems.map(renderTableRow)}
            {emptyRows}
          </tbody>
          <tfoot>
            <tr style={{ backgroundColor: "orange" }}>
              <td colSpan={8}>All Inventory: {tableData.length}</td>
              <td>{totalMileage}</td>
              <td></td>
              <td>$ {listingPrice}</td>
              <td></td>
              <td>6575</td>
              {/* <td></td> */}
            </tr>
          </tfoot>
        </Table>
      </div>

      <Modal
        show={show}
        size="xl"
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Advertising Manager
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div id="refresh-btn-seller">
              <button
                type="button"
                id="adlst-reset-btn-seller"
                onClick={resetCheckboxes}
                title="Reset"
              >
                <img src={Image.reset_button} alt="Reset Button" />
              </button>
            </div>
            <div className="tab-buttons">
              <section className="container">
                <div className="row">
                  {/* Card for Dealers Auto Center Market Place */}
                  <AdvertisingProfile
                    title="Dealers Auto Center Market Place"
                    logo={Image.darkLogo}
                    statusName="dacmp_status"
                    intervalName="dacmp_interval"
                    status={advertisingPro.dacmp_status}
                    interval={advertisingPro.dacmp_interval}
                    handleCheckboxChange={handleCheckboxChange}
                    handleSelectChange={handleSelectChange}
                    toggleImage={() =>
                      toggleConnectionStatus("dacmp_connected")
                    }
                    isConnected={connectionStatus.dacmp_connected}
                    isAllInterval={true}
                    setAllIntervals={setAllIntervals}
                    readonly={true}
                  />

                  {/* Card for United Motors */}
                  <AdvertisingProfile
                    title="Website (United Motors)"
                    logo={Image.darkLogo}
                    statusName="unitedmotors_status"
                    intervalName="unitedmotors_interval"
                    status={advertisingPro.unitedmotors_status}
                    interval={advertisingPro.unitedmotors_interval}
                    handleCheckboxChange={handleCheckboxChange}
                    handleSelectChange={handleSelectChange}
                    toggleImage={() =>
                      toggleConnectionStatus("unitedmotors_connected")
                    }
                    isConnected={connectionStatus.unitedmotors_connected}
                    readonly={true}
                  />

                  {/* Card for Google Market Place */}
                  <AdvertisingProfile
                    title="Google Market Place"
                    logo={Image.google}
                    statusName="google_status"
                    intervalName="google_interval"
                    status={advertisingPro.google_status}
                    interval={advertisingPro.google_interval}
                    handleCheckboxChange={handleCheckboxChange}
                    handleSelectChange={handleSelectChange}
                    toggleImage={() =>
                      toggleConnectionStatus("google_connected")
                    }
                    isConnected={connectionStatus.google_connected}
                  />

                  {/* Card for Instagram */}
                  <AdvertisingProfile
                    title="Instagram"
                    logo="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/2048px-Instagram_logo_2016.svg.png"
                    statusName="insta_status"
                    intervalName="insta_interval"
                    status={advertisingPro.insta_status}
                    interval={advertisingPro.insta_interval}
                    handleCheckboxChange={handleCheckboxChange}
                    handleSelectChange={handleSelectChange}
                    toggleImage={() =>
                      toggleConnectionStatus("insta_connected")
                    }
                    isConnected={connectionStatus.insta_connected}
                  />

                  {/* Card for Facebook */}
                  <AdvertisingProfile
                    title="Facebook"
                    logo={Image.facebook}
                    statusName="facebook_status"
                    intervalName="facebook_interval"
                    status={advertisingPro.facebook_status}
                    interval={advertisingPro.facebook_interval}
                    handleCheckboxChange={handleCheckboxChange}
                    handleSelectChange={handleSelectChange}
                    toggleImage={() =>
                      toggleConnectionStatus("facebook_connected")
                    }
                    isConnected={connectionStatus.facebook_connected}
                    isFacebook={true}
                    facebookMarketplaceStatus={
                      advertisingPro.facebook_marketplace_status
                    }
                    facebookGroupsStatus={advertisingPro.facebook_groups_status}
                    handleFacebookCheckboxChange={handleFacebookCheckboxChange}
                  />

                  {/* Card for Craigslist */}
                  <AdvertisingProfile
                    title="Craigslist"
                    logo={Image.craigslist}
                    statusName="craigslist_status"
                    intervalName="craigslist_interval"
                    status={advertisingPro.craigslist_status}
                    interval={advertisingPro.craigslist_interval}
                    handleCheckboxChange={handleCheckboxChange}
                    handleSelectChange={handleSelectChange}
                    toggleImage={() =>
                      toggleConnectionStatus("craigslist_connected")
                    }
                    isConnected={connectionStatus.craigslist_connected}
                  />

                  {/* Card for Twitter */}
                  <AdvertisingProfile
                    title="Twitter"
                    logo={Image.twitter_x_logo}
                    statusName="twitter_status"
                    intervalName="twitter_interval"
                    status={advertisingPro.twitter_status}
                    interval={advertisingPro.twitter_interval}
                    handleCheckboxChange={handleCheckboxChange}
                    handleSelectChange={handleSelectChange}
                    toggleImage={() =>
                      toggleConnectionStatus("twitter_connected")
                    }
                    isConnected={connectionStatus.twitter_connected}
                  />
                </div>
              </section>
            </div>
            <div className="d-flex justify-content-center ">
              <Button
                className="btn-orange border-0 py-2 px-3"
                onClick={updateAdvertisement}
              >
                Publish
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>


    </>
  );
};

export default DealerAddManager;

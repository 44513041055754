import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = () => {
  const data = {
    labels: ["Organic", "Search", "TV Ads", "Social", "Video"],
    datasets: [
      {
        label: "Marketing Channels",
        data: [2000, 3000, 4000, 5000, 6000],
        backgroundColor: "#36a2eb",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    indexAxis: "y",
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Marketing Channels Performance",
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        grid: {
        //   color: 'rgba(255, 255, 255, 0.3)',  // White with transparency
        //   borderDash: [5, 5],  // This creates the dotted line effect
        //   drawBorder: false,  // This removes the solid axis line
        display : false
        },
        border: {
          display: true,  // This removes the solid axis line
          color: 'rgba(255, 255, 255, 0.3)',
        },
        ticks: {
          color: '#D3D3D3',
        },
      },
      y: {
        barThickness: 60,
        categoryPercentage: 0.8,
        barPercentage: 0.9,
        grid: {
          color: 'rgba(255, 255, 255, 0.3)',  // White with transparency
          borderDash: [5, 5],  // This creates the dotted line effect
          drawBorder: false,  // This removes the solid axis line
        },
        border: {
            display: true,
            color: 'rgba(255, 255, 255, 0.3)',
            borderDash: [5, 5],
        },
        ticks: {
          color: '#D3D3D3',
          borderDash: [5, 5],
        },
      },
    },
  };

  return (
    <div style={{
      backgroundColor: '#2a2b4a',
      padding: '10px',
      borderRadius: '10px',
      color: "white",
      display : "flex",
        justifyContent : "center",
        alignItems : "center"
    }}>
      <Bar
        data={data}
        options={options}
        style={{ height: "350px", width: "500px" }}
      />
    </div>
  );
};

export default BarChart;
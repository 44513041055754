import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../../NearByCars.css";
import { SlSpeedometer } from "react-icons/sl";
import { IoLocationSharp } from "react-icons/io5";
import Image from "../../../../imgData";

const NearByCars44 = () => {
  const carsListing = [
    {
      year_make_model: "2014 CHEVROLET Cruze Sedan 4D 1LS",
      image: "path/to/chevrolet_cruze.jpg", // Replace with actual image path or URL
      mileage_in: 30000,
      fuel: "(123) 123-1234",
      listing_price: 3234,
      color: "red", // Example color
      address: "Springfield, IL", // Example address
      distance: 120, // Example distance in miles
    },
    {
      year_make_model: "2016 TOYOTA Corolla Sedan 4D LE",
      image: "path/to/toyota_corolla.jpg", // Replace with actual image path or URL
      mileage_in: 45000,
      fuel: "(123) 123-1234",
      listing_price: 13500,
      color: "blue", // Example color
      address: "Madison, WI", // Example address
      distance: 200, // Example distance in miles
    },
    {
      year_make_model: "2018 HONDA Civic Sedan 4D LX",
      image: "path/to/honda_civic.jpg", // Replace with actual image path or URL
      mileage_in: 22000,
      fuel: "(123) 123-1234",
      listing_price: 18000,
      color: "black", // Example color
      address: "Austin, TX", // Example address
      distance: 90, // Example distance in miles
    },
    {
      year_make_model: "2017 FORD Focus Sedan 4D SE",
      image: "path/to/ford_focus.jpg", // Replace with actual image path or URL
      mileage_in: 35000,
      fuel: "(123) 123-1234",
      listing_price: 16000,
      color: "white", // Example color
      address: "Seattle, WA", // Example address
      distance: 150, // Example distance in miles
    },
    {
      year_make_model: "2015 NISSAN Sentra Sedan 4D SV",
      image: "path/to/nissan_sentra.jpg", // Replace with actual image path or URL
      mileage_in: 50000,
      fuel: "(123) 123-1234",
      listing_price: 14000,
      color: "green", // Example color
      address: "Denver, CO", // Example address
      distance: 80, // Example distance in miles
    },
    {
      year_make_model: "2019 KIA Forte Sedan 4D EX",
      image: "path/to/kia_forte.jpg", // Replace with actual image path or URL
      mileage_in: 15000,
      fuel: "(123) 123-1234",
      listing_price: 19000,
      color: "silver", // Example color
      address: "Miami, FL", // Example address
      distance: 60, // Example distance in miles
    },
  ];

  const navigate = useNavigate();
  //   const handleClick = (product) => {
  //     navigate(`/cardetail/${product.id}_${product.year}_${product.make}`, {
  //       state: { product },
  //     });
  //   };

  console.log("carsListing", carsListing);
  return (
    <section style={{ cursor: "pointer" }}>
      <div className="container-fluid p-4">
        <div className="row">
          <h3 className="text-center p-4">Featured Dealers Near Me</h3>
          {carsListing.length > 0 && (
            <>
              <div className="col-12">
                <div className="col-lg-12">
                  <div className="row">
                    {carsListing.slice(0, 6).map((car, index) => (
                      <div
                        key={index}
                        className="col-lg-4 nearByCarcardWrapper"
                        // onClick={() => handleClick(car)}
                        style={{ borderRadius: "25px" }}
                      >
                        <div
                          className="card mt-3 card-product"
                          style={{ borderRadius: "25px" }}
                        >
                          {index === 5 ? (
                            <div
                              style={{
                                backgroundColor: " white",
                                position: "relative",
                              }}
                            >
                              <p
                                style={{
                                  position: "absolute",
                                  right: "30%",
                                  top: "10%",
                                  fontSize: "xx-large",
                                  fontWeight: "600",
                                }}
                              >
                                <span
                                  style={{
                                    color: "#d66b00",
                                    marginRight: "0.7rem",
                                  }}
                                >
                                  Sell
                                </span>
                                <span
                                  style={{
                                    color: "black",
                                    marginRight: "0.7rem",
                                  }}
                                >
                                  Your
                                </span>
                                <span
                                  style={{
                                    color: "#d66b00",
                                  }}
                                >
                                  Cars
                                </span>
                              </p>
                              <div
                                style={{ paddingTop: "2rem", padding: "2rem" }}
                              >
                                <img
                                  src={Image.laptopProtoType}
                                  alt="laptop image"
                                  style={{ width: "100%", height: "100%" }}
                                />
                              </div>
                            </div>
                          ) : (
                            <div
                              className="product-image-box-c"
                              style={{ position: "relative" }}
                            >
                              <img
                                src={Image.car_image}
                                className="card-img-top card-product-img"
                                alt="..."
                                style={{
                                  backgroundSize: "cover",
                                  backgroundPosition: "center",
                                  width: "100%",
                                  height: "19.6rem",
                                  borderRadius: "25px 25px 0 0",
                                }}
                              />
                              <span className="grid-int-color-b">
                                Int. Color{" "}
                                <span
                                  style={{
                                    display: "inline-block", // Make the span an inline-block element
                                    width: "20px", // Set the width for the circle
                                    height: "20px", // Set the height for the circle
                                    backgroundColor: car.color,
                                    borderRadius: "50%", // Make it circular
                                    // marginLeft: "1.3rem",
                                    border: "1px solid white",
                                  }}
                                  title={car.color ? car.color : ""}
                                />
                              </span>
                              <div
                                className="overlay-text top-text"
                                //   onClick={() => handleClick(car)}
                                style={{ cursor: "pointer" }}
                              >
                                <div
                                  style={{
                                    padding: "1rem",
                                    fontSize: "medium",
                                  }}
                                >
                                  {car.year_make_model || "Default Title"}
                                </div>
                                <span className="grid-card-mi">
                                  <SlSpeedometer style={{ fill: "#d1823a" }} />{" "}
                                  <span>
                                    {new Intl.NumberFormat().format(
                                      car.mileage_in
                                    )}{" "}
                                    mi
                                  </span>
                                </span>
                              </div>

                              <div
                                className="overlay-text bottom-text"
                                style={{ position: "relative" }}
                              >
                                <div className="gird-card-bottom-txt">
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      padding: "1rem",
                                      width: "100%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        marginTop: "1.5rem",
                                      }}
                                    >
                                      <p
                                        style={{
                                          display: "flex",
                                          gap: "5px",
                                          display: "none",
                                        }}
                                      >
                                        {/* <span> {car?.driveType || 0}</span>
                                      <span> {car?.engine.split(" ")[1]} </span>
                                      <span> {car?.engine.split(" ")[0]} </span>
                                      <span> {car?.engine.split(" ")[3]} </span> */}
                                        <span
                                          style={{ marginBottom: "0.5rem" }}
                                        >
                                          {" "}
                                          {car.transmission ||
                                            "Transmission NA"}{" "}
                                        </span>{" "}
                                      </p>
                                      <p style={{ marginBottom: "0.5rem" }}>
                                        Seller :{" "}
                                        {car.user_name || "Seller Name NA"}
                                      </p>
                                      <p>
                                        <span style={{ fontSize: "large" }}>
                                          <IoLocationSharp
                                            style={{ fill: "#d1823a" }}
                                          />{" "}
                                        </span>
                                        {car?.location || "Seller Location NA"}{" "}
                                        (45 mi away)
                                      </p>
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <p
                                        id="grid-card-price"
                                        style={{ textAlign: "center" }}
                                      >
                                        $
                                        {new Intl.NumberFormat().format(
                                          car.listing_price
                                        )}{" "}
                                      </p>
                                      <p style={{ textAlign: "center" }}>
                                        <span>
                                          Est $
                                          {new Intl.NumberFormat().format(
                                            car?.estimated_cost
                                              ? Math.ceil(car?.estimated_cost)
                                              : 0
                                          )}{" "}
                                          mo
                                        </span>
                                      </p>
                                      <p id="grid-card-check-avlt">
                                        Check Availability
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="product_share_like-c">
                                {car.id !== 0 ? (
                                  <span
                                  //   onClick={(e) => {
                                  //     e.stopPropagation();
                                  //     handleLikeTheProduct(car.id);
                                  //   }}
                                  >
                                    {/* <FaHeart
                                  // fill={`${isFav ? "red" : "white"}`}
                                  /> */}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default NearByCars44;

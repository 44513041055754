import React from 'react'
import { Col, Container, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'

import { MdReplyAll, MdDelete, } from "react-icons/md";
import { PiArrowFatRightFill } from "react-icons/pi";
import { RiReplyFill } from "react-icons/ri";
import { ImAttachment } from "react-icons/im";
import { FaStar } from "react-icons/fa6";

import styles from '../mail.module.css'
import Image from '../../../imgData';

const DealerMailTemplate = () => {

    const renderFile = ({ fileUrl, fileType }) => {
        console.log("fileUrl", fileUrl);
        switch (fileType) {
            case 'image':
                return <img src={fileUrl} alt="File" style={{ maxWidth: '100%', maxHeight: '100%' }} />;
            case 'application/pdf':
                return <embed src={fileUrl} type="application/pdf" width="100%" height="600px" />;
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                return (
                    <iframe
                        src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(fileUrl)}`}
                        width="100%"
                        height="600px"
                        title="Word Document Viewer"
                    ></iframe>
                );
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                return (
                    <iframe
                        src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(fileUrl)}`}
                        width="100%"
                        height="600px"
                        title="Excel Spreadsheet Viewer"
                    ></iframe>
                );
            default:
                return <p>Unsupported file type</p>;
        }
    };

    return (
        <Container>
            <Row className={` py-5 d-flex justify-content-between`}>
                <Col sm={2}>
                    <div className={` ${styles.name}`}>Priyanka</div>
                    <span className={` ${styles.smallFont}`}>priyanka12@gmail.com</span>
                </Col>
                <Col sm={3} className={` d-flex justify-content-end`}>
                    <span className={` ${styles.smallFont}`}>02.12.2024 - 10:25AM</span>
                </Col>
            </Row>
            <Row className={`d-flex justify-content-between mb-5`}>
                <Col sm={9}>
                    <div className={` ${styles.subject}`}>Good Tool!!</div>
                    {/* <span className={` ${styles.smallFont}`}>priyanka12@gmail.com</span> */}
                </Col>
                <Col sm={3}>
                    <div className={`w-100 ${styles.icons}`}>
                        <OverlayTrigger
                            placement="bottom-start"
                            overlay={
                                <Tooltip id={`tooltip-attachment`} className="custom-tooltip">
                                    Attachment
                                </Tooltip>
                            }>
                            <span className={` ${styles.icon}`}><ImAttachment /></span>
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement="bottom-start"
                            overlay={
                                <Tooltip id={`tooltip-star`} className="custom-tooltip">
                                    Star
                                </Tooltip>
                            }>
                            <span className={` ${styles.icon}`}><FaStar /></span>
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement="bottom-start"
                            overlay={
                                <Tooltip id={`tooltip-reply`} className="custom-tooltip">
                                    Reply
                                </Tooltip>
                            }>
                            <span className={` ${styles.icon}`}><RiReplyFill /></span>
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement="bottom-start"
                            overlay={
                                <Tooltip id={`tooltip-replyAll`} className="custom-tooltip">
                                    Reply All
                                </Tooltip>
                            }>
                            <span className={` ${styles.icon}`}><MdReplyAll /></span>
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement="bottom-start"
                            overlay={
                                <Tooltip id={`tooltip-forword`} className="custom-tooltip">
                                    Forword
                                </Tooltip>
                            }>
                            <span className={` ${styles.icon}`}><PiArrowFatRightFill /></span>
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement="bottom-start"
                            overlay={
                                <Tooltip id={`tooltip-delete`} className="custom-tooltip">
                                    Delete
                                </Tooltip>
                            }>
                            <span className={` ${styles.icon}`}><MdDelete /></span>
                        </OverlayTrigger>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <pre>ghth gvtht jhyf gyugv yfcv yucyuc yutc ythty rvyh</pre>
                </Col>
            </Row>

            <Row className={` ${styles.docxSec}`}>
                <div className={`px-0 ${styles.document}`}>
                    <embed
                        src={Image.pdf}
                        type="application/pdf"
                        width='100%'
                        height='100%'
                    />
                    {/* {renderFile('https://www.rd.usda.gov/sites/default/files/pdf-sample_0.pdf', 'application/pdf')} */}
                </div>
                <div className={`px-0 ${styles.document}`}>
                    <img src={Image.noImage} alt="document2" />
                    {/* {renderFile(Image.noImage, 'image')}ttps://www.google.com/imgres?q=i */}
                </div>
                <div className={`px-0 ${styles.document}`}>
                    <img src={Image.noImage} alt="document3" />
                </div>
            </Row>
        </Container>
    )
}

export default DealerMailTemplate
import React, { useEffect, useState } from "react";
import "./CompareVehicle.css";
import { MdOutlineForwardToInbox } from "react-icons/md";
import { FiPhoneCall } from "react-icons/fi";
import { HiDotsHorizontal } from "react-icons/hi";
import { VscSend } from "react-icons/vsc";
import { MdMoveToInbox } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Comparecard from "./Comparecard";

const CompareVehicle = () => {
  // Session Time out for 15 minutes-------------------------------------
  const navigate = useNavigate();
  const cardArr = Array(10).fill(5)
  // Session Time out for 15 minutes End-------------------------------------
  return (
    <div className="compare-vehicle-main">
      <div className="compare_vehicle_header">
      <span id="c-v-add-form-btn">
        <button>+ Add Form</button>
      </span>
      <span id="c-v-form-fav-btn">
        <button>
          <MdMoveToInbox style={{ fontSize: "25px" }} /> Form My Favroute
        </button>
      </span>
      </div>
     
      {/* card item for compare-vehicle */}
      <div className="compare_vehilce_card_container">
        {cardArr && cardArr.map((_,i) => (
          <div key={i}><Comparecard/></div>
        ))}
      </div>
    
      {/* card item for compare-vehicle end */}
    </div>
  );
};

export default CompareVehicle;

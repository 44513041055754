import React, { useEffect, useState } from "react";
import Styles from "../Styles/Box_D.module.css";
import InputFieldComp from "./InputFieldComp";
import { useDispatch, useSelector } from "react-redux";
import { setRetailData } from "../../../../Redux/slice/taxes&FeesSlice";
import { connectStorageEmulator } from "firebase/storage";

const Box_H = ({ retailFromRedux, onSumChange }) => {
  const totalFees = useSelector(
    (state) => state.taxesAndFees.dealerRetail.totalFees
  );
  // const totalFeesn = totalFees.toFixed(2)
  const userListingPrice = useSelector(
    (state) => state.taxesAndFees.dealerRetail.userListingPrice
  );
  const mileageIn = useSelector(
    (state) => state.taxesAndFees.dealerRetail.mileageIn
  );
  const totalFeesAndothers = useSelector((state) =>
    parseFloat(state.taxesAndFees.dealerRetail.totalFeesAndothers)
  );
  const isCarSelected = useSelector((state) => state.sell.selectedVehicle);
  // Provide a fallback in case `isCarSelected` is undefined
  const listingPrice = isCarSelected?.listing_price || 0;
  const mileage_in = isCarSelected?.mileage_in || 0;
  //const totalFeesTax = totalFees.toFixed(0);
  // Update state whenever `totalFees` changes
  const [inputValues, setInputValues] = useState([
    // { header: "Mileage On Sale:", InputValue: `${mileageIn}`, name: "mileageIn" },
    // { header: "Sale Price:", InputValue: `${userListingPrice}`, name: "userListingPrice" },
    { header: "Total Taxes", InputValue: totalFees, name: "totalFees" },
    {
      header: "Total Fees And Others",
      InputValue: `${totalFeesAndothers}`,
      name: "totalFeesAndothers",
    },
  ]);
  const formatNumberWithCommas = (num) => {
    return Number(num).toLocaleString();
  };

  const removeCommas = (str) => {
    return str.replace(/,/g, "");
  };

  useEffect(() => {
    if (listingPrice) {
      dispatch(
        setRetailData({
          userListingPrice: listingPrice,
        })
      );
    }
  }, [listingPrice]);

  useEffect(() => {
    if (mileage_in) {
      dispatch(
        setRetailData({
          mileageIn: mileage_in,
        })
      );
    }
  }, [mileage_in]);

  // useEffect(() => {
  //   if (totalFeesAndOthers) {
  //   dispatch(setRetailData({
  //     totalFeesAndothers:totalFeesAndOthers,
  //   }));
  // }
  // },[totalFeesAndothers]);

  const dispatch = useDispatch();
  useEffect(() => {
    setInputValues((prevInputValues) =>
      prevInputValues.map((item) =>
        item.name === "mileageIn" ? { ...item, InputValue: mileageIn } : item
      )
    );
  }, [mileageIn]);
  useEffect(() => {
    setInputValues((prevInputValues) =>
      prevInputValues.map((item) =>
        item.name === "totalFeesAndothers"
          ? { ...item, InputValue: totalFeesAndothers }
          : item
      )
    );
  }, [totalFeesAndothers]);
  useEffect(() => {
    setInputValues((prevInputValues) =>
      prevInputValues.map((item) =>
        item.name === "totalFees" ? { ...item, InputValue: totalFees } : item
      )
    );
  }, [totalFees]);
  useEffect(() => {
    setInputValues((prevInputValues) =>
      prevInputValues.map((item) =>
        item.name === "userListingPrice"
          ? { ...item, InputValue: userListingPrice }
          : item
      )
    );
  }, [userListingPrice]);

  useEffect(() => {
    setInputValues((prevInputValues) =>
      prevInputValues.map((item) =>
        item.name === "userListingPrice"
          ? { ...item, InputValue: userListingPrice }
          : item
      )
    );
  }, [userListingPrice]);

  useEffect(() => {
    setInputValues((prevInputValues) =>
      prevInputValues.map((item) =>
        item.name === "totalFeesAndothers"
          ? { ...item, InputValue: totalFeesAndothers }
          : item
      )
    );
  }, [totalFeesAndothers]);
  // sum all three------------------
  useEffect(() => {
    const sum = inputValues.reduce(
      (acc, item) => acc + parseFloat(item.InputValue)
    );
    onSumChange(sum);
  }, [inputValues, onSumChange]);
  // --------------------------
  const handleInputChange = (name, value) => {
    const updatedFields = inputValues.map((field) =>
      field.name === name ? { ...field, InputValue: value } : field
    );
    setInputValues(updatedFields);

    // Dispatch the updated value to the Redux store
    dispatch(setRetailData({ ...retailFromRedux, [name]: value }));
  };

  return (
    <div
      className={Styles.wrapper}
      style={{
        borderBottom: "0px",
        borderRight: "0px",
        borderLeft: "0px",
        padding: "0.47rem 1rem",
      }}
    >
      <div
        className={Styles.container}
        style={{ paddingLeft: "0px", paddingRight: "0px" }}
      >
        {inputValues.map((item, index) => (
          <InputFieldComp
            key={index}
            header={item.header}
            InputValue={item.InputValue}
            name={item.name}
            handleInputChange={handleInputChange}
          />
        ))}
      </div>
    </div>
  );
};

export default Box_H;

import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styles from "../Styles/mailAddAcc.module.css";
import Image from '../../../imgData'
import moment from 'moment-timezone';
import fetchLanguages from './fetchLanguages.js'


const DealerSetting = () => {

  // Get the list of time zones
  const timeZones = moment.tz.names();

  const [languages, setLanguages] = useState([]);
  const [formData, setFormData] = useState({
    displayName: '',
    defaultAdd: '',
    startupPage: '',
    timeZone: '',
    language: '',
  });

  useEffect(() => {
    async function getLanguages() {
      try {
        const languages = await fetchLanguages();
        setLanguages(languages);
      } catch (error) {
        console.error('Error getting languages:', error);
      }
    }
    getLanguages();
  }, []);

  // Handle change events for input and select fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Create a FormData instance
    const data = new FormData();
    // Append form data
    for (const [key, value] of Object.entries(formData)) {
      data.append(key, value);
    }

    // Here you can handle form data as needed
    // For example, you can send it to a server
    console.log('Form Data:', formData);

    // Optionally, you can use the FormData object to post data
    // fetch('/your-endpoint', {
    //   method: 'POST',
    //   body: data,
    // })
    // .then(response => response.json())
    // .then(result => console.log('Success:', result))
    // .catch(error => console.error('Error:', error));
  };

  console.log("timeZones", timeZones);


  return (
    <Container className={`p-4`}>
      <Row>
        <form onSubmit={handleSubmit} className={` ${styles.form}`}>
          <h5 className={` ${styles.settingHead}`}>Accounts Settings</h5>
          <Col sm={8}>
            {/* <h5 className={` ${styles.settingHead}`}>Accounts Settings</h5> */}
            <div className={` ${styles.pop}`}>
              <label htmlFor="displayName">
                Display Name:
                <input
                  type="text"
                  id="displayName"
                  name="displayName"
                  value={formData.displayName}
                  onChange={handleChange}
                />
              </label>
            </div>
            <div className={` ${styles.pop}`}>
              <label htmlFor="defaultAdd">
                Default Address:
                <select
                  id="defaultAdd"
                  name="defaultAdd"
                  value={formData.defaultAdd}
                  onChange={handleChange}
                >
                  <option value="">Select an option</option>
                  <option value="option1">Option 1</option>
                  <option value="option2">Option 2</option>
                </select>
              </label>
            </div>
            <div className={` ${styles.pop}`}>
              <label htmlFor="startupPage">
                Startup Page:
                <select
                  id="startupPage"
                  name="startupPage"
                  value={formData.startupPage}
                  onChange={handleChange}
                >
                  <option value="">Select an option</option>
                  <option value="inbox">Inbox</option>
                  <option value="calendar">Calendar</option>
                  <option value="contect">Contect</option>
                  <option value="task">Task</option>
                </select>
              </label>
            </div>
          </Col>

          <h5 className={`mt-3 ${styles.settingHead}`}>Regional Settings</h5>
          <Col sm={8}>
            <div className={` ${styles.pop}`}>
              <label htmlFor="timeZone">
                Time Zone:
                <select
                  id="timeZone"
                  name="timeZone"
                  value={formData.timeZone}
                  onChange={handleChange}
                >
                  <option value="">Select an option</option>
                  {timeZones.map((zone) => (
                    <option key={zone} value={zone}>
                      {zone}
                    </option>
                  ))}
                  {/* <option value="option1">Option 1</option>
                  <option value="option2">Option 2</option> */}
                </select>
              </label>
            </div>

            <div className={` ${styles.pop}`}>
              <label htmlFor="language">
                Language:
                <select
                  id="language"
                  name="language"
                  value={formData.language}
                  onChange={handleChange}
                >
                  <option value="">Select an option</option>
                  {languages && languages.map(lang => (
                    <option key={lang.code} value={lang.code}>
                      {lang.name}
                    </option>
                  ))}
                  <option value="hindi">Hindi</option>
                  <option value="english">English</option>
                </select>
              </label>
            </div>
          </Col>

          <Col sm={12}>
            <h5 className={`mt-3  ${styles.settingHead}`}>Client Settings</h5>
            <Row className={`d-flex justify-content-between`}>
              <Col sm={5} className={` d-flex flex-column justify-content-center`}>
                <img src={Image.mailSetting} alt="Mail Icon" width='50px' height='50px' />
                <p>Mail enable allows desktop e-mail and mobile devices to access messages and folders using standard protocols like IMAP, SMTP and POP3.</p>
              </Col>
              <Col sm={7} className={`${styles.details}`}>
                <h6 className={`fw-bold`}> Details</h6>
                <Row className={`border p-2`}>
                  <Col sm={6} className={` d-flex flex-column`} >
                    <p>IMAP(No SSL)</p>
                    <span>Server: mail.5starsmotors.com</span>
                    <span>Incoming Port: 143</span>
                    <span>Outgoing Port: No</span>
                  </Col>
                  <Col sm={6} className={` d-flex flex-column`}>
                    <p>IMAP(SSL)</p>
                    <span>Server: mail.5starsmotors.com</span>
                    <span>Incoming Port: 143</span>
                    <span>Outgoing Port: No</span>
                  </Col>
                  <Col sm={6} className={` d-flex flex-column`}>
                    <p>POP(No SSL)</p>
                    <span>Server: mail.5starsmotors.com</span>
                    <span>Incoming Port: 110</span>
                    <span>Outgoing Port: No</span>
                  </Col>
                  <Col sm={6} className={` d-flex flex-column`}>
                    <p>POP(SSL)</p>
                    <span>Server: mail.5starsmotors.com</span>
                    <span>Incoming Port: 143</span>
                    <span>Outgoing Port: No</span>
                  </Col>
                  <Col sm={6} className={` d-flex flex-column`}>
                    <p>SMTP(No SSL)</p>
                    <span>Server: mail.5starsmotors.com</span>
                    <span>Incoming Port: 25</span>
                    <span>Outgoing Port: No</span>
                  </Col>
                  <Col sm={6} className={` d-flex flex-column`}>
                    <p>SMTP(SSL)</p>
                    <span>Server: mail.5starsmotors.com</span>
                    <span>Incoming Port: 143</span>
                    <span>Outgoing Port: No</span>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          {/* <button type="submit">Submit</button> */}
        </form>
      </Row>
    </Container>
  )
}

export default DealerSetting
import React, { forwardRef, useEffect, useState } from "react";
import "../../../Calculator.css";
import styles from "../../../calculator.module.css";
import { withExtraArgument } from "redux-thunk";
import { Col, Container, Row } from "react-bootstrap";
import { IoMdInformationCircleOutline } from "react-icons/io";
import circleImage from "./CalculatorCircle.png";

const MonthlyLoanCalculator4 = forwardRef((props, ref) => {
  const [vehiclePrice, setVehiclePrice] = useState("15000");
  const [tradeInValue, setTradeInValue] = useState(0);
  const [ttlPrice, setTtlPrice] = useState(0);
  const [interestRate, setInterestRate] = useState("5.85");
  const [downPayment, setDownPayment] = useState(0);
  const [loanTermInMonth, setLoanTermInMonth] = useState("48");
  const [activeButton, setActiveButton] = useState("48");
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const [loanAountData, setLoanAmountData] = useState(0);
  const [activeRate, setActiveRate] = useState(5.85); //for button bg color only----------
  const [showInput, setShowInput] = useState(false);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (vehiclePrice) {
      // Calculate 10% of vehiclePrice
      const calculatedDownPayment = parseFloat(vehiclePrice) * 0.1;
      setDownPayment(calculatedDownPayment); // Update downPayment with two decimal places
    } else {
      setDownPayment(""); // Clear downPayment if vehiclePrice is empty
    }
  }, [vehiclePrice]);

  useEffect(() => {
    if (vehiclePrice) {
      // Calculate 65% of vehiclePrice
      const calculatedttlPrice = parseFloat(vehiclePrice) * 0.65 + 150;
      const calculatedTtlPrice = Math.ceil(calculatedttlPrice);
      setTtlPrice(calculatedTtlPrice); // Update downPayment with two decimal places
    } else {
      setTtlPrice(""); // Clear downPayment if vehiclePrice is empty
    }
  }, [vehiclePrice]);

  useEffect(() => {
    calculateMonthlyPayment();
  }, [
    vehiclePrice,
    tradeInValue,
    interestRate,
    downPayment,
    loanTermInMonth,
    ttlPrice,
  ]);

  const calculateMonthlyPayment = () => {
    const P = parseFloat(vehiclePrice) + parseFloat(ttlPrice);
    const TIV = parseFloat(tradeInValue);
    const r = parseFloat(interestRate) / 100 / 12;
    const DP = parseFloat(downPayment);
    const n = parseInt(loanTermInMonth, 10);

    if (isNaN(P) || isNaN(TIV) || isNaN(r) || isNaN(DP) || isNaN(n)) {
      return;
    }

    const loanAmount = P - DP - TIV;
    setLoanAmountData(loanAmount);
    const M =
      (loanAmount * (r * Math.pow(1 + r, n))) / (Math.pow(1 + r, n) - 1);
    //const roundedValue = M > 0 ? Math.round(M) : Math.ceil(M);
    setMonthlyPayment(Math.ceil(M));
  };
  // bg color-----------------------------------
  const handleButtonClickb = (rate) => {
    setInterestRate(rate);
    setActiveRate(rate); // Set the active button based on the rate
  };

  // Function to get button styles based on whether it's active
  const getButtonStyles = (rate) => ({
    backgroundColor: activeRate === rate ? "black" : "white", // Active button color
    color: activeRate === rate ? "white" : "black", // Active text color
  });
  // btn bg color---------------------------------------
  // console.log("vehiclePrice:", vehiclePrice);
  // console.log("tradeInValue:", tradeInValue);
  // console.log("interestRate:", interestRate);
  // console.log("downPayment:", downPayment);
  // console.log("loanTermInMonth:", loanTermInMonth);

  // const handleButtonClick = (value) => {
  //   if (activeButton === value) {
  //     setActiveButton(null);
  //     setLoanTermInMonth('');
  //   } else {
  //     setActiveButton(value);
  //     setLoanTermInMonth(value);
  //   }
  // };

  const handleButtonClick = (value) => {
    if (activeButton === value) {
      setActiveButton(null);
      setLoanTermInMonth("");
    } else {
      setActiveButton(value);
      setLoanTermInMonth(value);
    }
  };

  const getButtonStyle = (value) => {
    return activeButton === value
      ? { backgroundColor: "black", color: "white" }
      : {};
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyDown = (event) => {
    if (event.key === "Enter") {
      handleButtonClick(inputValue);
      setShowInput(false); // Hide input field after pressing Enter
    }
  };

  const handleInputBlur = () => {
    // Hide input field if it loses focus
    setShowInput(false);
  };
  // ------------------------------------------------------------------------------------

  // ------------------------------------------------------------------------------------
  return (
    <>
      <section className={` ${styles.bgGradient}`} ref={ref}>
        <h1 className="text-center text-dark fw-bold text-white">
          Auto Loan Calculator
        </h1>
        <div className={` ${styles.cylenderContainer}`}>
          <div className={` ${styles.cylender} ${styles.cylender1}`}></div>
          <div className={` ${styles.cylender} ${styles.cylender2}`}></div>
          <div className={` ${styles.cylender} ${styles.cylender3}`}></div>
        </div>
        <Container
          fluid
          className="h-100"
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            top: "60px",
          }}
        >
          <Row className="h-100">
            <Col sm={6} style={{ paddingLeft: "11rem" }}>
              <form action="" method="post">
                <div
                  className="mt-2"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    color: "black",
                  }}
                >
                  <span>
                    <p className={styles.label} style={{ marginBottom: "0px" }}>
                      Vehicle Amount
                    </p>
                  </span>
                  <div className={styles.inputConPercent}>
                    <span
                      className={styles.percentSymbol}
                      style={{ fontWeight: "bolder", fontSize: "20px" }}
                    >
                      $
                    </span>
                    <input
                      type="text"
                      className={styles.formControl}
                      value={vehiclePrice}
                      onChange={(e) => setVehiclePrice(e.target.value)}
                    />
                  </div>
                </div>

                <div
                  className="mt-2"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    color: "black",
                  }}
                >
                  <span>
                    <p className={styles.label} style={{ marginBottom: "0px" }}>
                      Down Payment
                    </p>
                  </span>
                  <div className={styles.inputConPercent}>
                    <span
                      className={styles.percentSymbol}
                      style={{ fontWeight: "bolder", fontSize: "20px" }}
                    >
                      $
                    </span>
                    <input
                      type="text"
                      className={styles.formControl}
                      value={downPayment}
                      onChange={(e) => setDownPayment(e.target.value)}
                    />
                  </div>
                </div>

                <div
                  className="mt-2"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    color: "black",
                  }}
                >
                  <span>
                    <p className={styles.label} style={{ marginBottom: "0px" }}>
                      Trade-in-Value
                    </p>
                  </span>
                  <div className={styles.inputConPercent}>
                    <span
                      className={styles.percentSymbol}
                      style={{ fontWeight: "bolder", fontSize: "20px" }}
                    >
                      $
                    </span>
                    <input
                      type="text"
                      className={styles.formControl}
                      value={tradeInValue}
                      onChange={(e) => setTradeInValue(e.target.value)}
                    />
                  </div>
                </div>

                <div
                  className="mt-2"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    color: "black",
                  }}
                >
                  <span>
                    <p className={styles.label} style={{ marginBottom: "0px" }}>
                      TTL(Title,Taxes and Licences Plates) Est.
                    </p>
                  </span>
                  <div className={styles.inputConPercent}>
                    <span
                      className={styles.percentSymbol}
                      style={{ fontWeight: "bolder", fontSize: "20px" }}
                    >
                      $
                    </span>
                    <input
                      type="text"
                      className={styles.formControl}
                      value={ttlPrice}
                      onChange={(e) => setTtlPrice(e.target.value)}
                    />
                  </div>
                </div>
                <div
                  className="mt-2"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    color: "black",
                  }}
                >
                  <span>
                    <p className={styles.label} style={{ marginBottom: "0px" }}>
                      Interest Rate
                    </p>
                  </span>
                  <div className={styles.inputConPercent}>
                    <input
                      type="text"
                      className={styles.formControl}
                      value={interestRate}
                      onChange={(e) => setInterestRate(e.target.value)}
                    />
                    <span
                      className={styles.percentSymbol}
                      style={{ fontWeight: "bolder", fontSize: "20px" }}
                    >
                      %
                    </span>
                  </div>
                  {/* <div style={{ display: "flex", justifyContent: "space-between", }}>
                    <span className={` ${styles.minMax}`}>Min. 1</span>
                    <span className={` ${styles.minMax}`}>Max. 72</span>
                  </div>
                  <input type="range" className={styles.inputRange} min="0" max="20000" /> */}
                </div>
                {/* <div
                  className="mt-2"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    color: "black",
                  }}
                >
                  <span>
                    <p className={styles.label} style={{ marginBottom: "0px" }}>
                      Loan Terms (Monthly)
                    </p>
                  </span>
                  <div className={styles.inputConPercent}>
                    <span
                      className={styles.percentSymbol}
                      style={{ fontWeight: "bolder", fontSize: "20px" }}
                    >
                      $
                    </span>
                    <input
                      type="text"
                      className={styles.formControl}
                      value={loanTermInMonth}
                      onChange={(e) => setLoanTermInMonth(e.target.value)}
                    />
                  </div>
                </div> */}
                <div
                  className="mt-2"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    color: "black",
                  }}
                >
                  <span>
                    <p className={styles.label} style={{ marginBottom: "0px" }}>
                      Loan Terms (Monthly)
                    </p>
                  </span>
                  <div className={styles.inputConPercent}>
                    <div
                      className="mt-2"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <span className={` ${styles.txtBtnCarHome}`}>
                        <button
                          type="button"
                          onClick={() => handleButtonClick("36")}
                          style={getButtonStyle("36")}
                        >
                          36
                        </button>
                      </span>
                      <span className={` ${styles.txtBtnCarHome}`}>
                        <button
                          type="button"
                          onClick={() => handleButtonClick("48")}
                          style={getButtonStyle("48")}
                        >
                          48
                        </button>
                      </span>
                      <span className={` ${styles.txtBtnCarHome}`}>
                        <button
                          type="button"
                          onClick={() => handleButtonClick("60")}
                          style={getButtonStyle("60")}
                        >
                          60
                        </button>
                      </span>
                      <span className={` ${styles.txtBtnCarHome}`}>
                        <button
                          type="button"
                          onClick={() => handleButtonClick("72")}
                          style={getButtonStyle("72")}
                        >
                          72
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className="mt-2"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    color: "black",
                  }}
                >
                  <span>
                    <p className={styles.label} style={{ marginBottom: "0px" }}>
                      Approx. Credit Score
                    </p>
                  </span>
                  <div className={styles.inputConPercent}>
                    {/* <input
                      type="text"
                      className={styles.formControl}
                      value={vehiclePrice}
                      onChange={(e) => setVehiclePrice(e.target.value)}
                    />
                    <span className={styles.percentSymbol} style={{ fontWeight: "bolder", fontSize: "20px" }}>%</span> */}

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <span className={` ${styles.txtBtnCarHome}`}>
                        <button
                          type="button"
                          style={getButtonStyles(11)}
                          onClick={() => handleButtonClickb(11)}
                        >
                          <p style={{ fontWeight: "bold" }}>Poor</p>
                          <p>630 or less</p>
                        </button>
                      </span>
                      <span className={` ${styles.txtBtnCarHome}`}>
                        <button
                          type="button"
                          style={getButtonStyles(6.85)}
                          onClick={() => handleButtonClickb(6.85)}
                        >
                          <p style={{ fontWeight: "bold" }}>Fair</p>
                          <p>640 or 699</p>
                        </button>
                      </span>
                      <span className={` ${styles.txtBtnCarHome}`}>
                        <button
                          type="button"
                          style={getButtonStyles(5.85)}
                          onClick={() => handleButtonClickb(5.85)}
                        >
                          <p style={{ fontWeight: "bold" }}>Good</p>
                          <p>700 or 749</p>
                        </button>
                      </span>
                      <span className={` ${styles.txtBtnCarHome}`}>
                        <button
                          type="button"
                          style={getButtonStyles(4)}
                          onClick={() => handleButtonClickb(4)}
                        >
                          <p style={{ fontWeight: "bold" }}>Excellent</p>
                          <p>750 or 850</p>
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </form>

              <div
                className={`mx-auto animated-box ${styles.estMonthlyHeadingHome}`}
                style={{ height: "10%", marginTop: "20px" }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <h4
                    style={{
                      marginBottom: "0px",
                      marginTop: "9px",
                      fontWeight: "900",
                      color: "#006099",
                    }}
                  >
                    {/* ${new Intl.NumberFormat().format(monthlyPayment)} */}
                  </h4>
                  <span className={styles.tooltip_container_b}>
                    <IoMdInformationCircleOutline className="info-icon" />
                    <div className={styles.tooltip_text_b}>
                      This is an Estimated Monthly payment ONLY. It is NOT a
                      loan.
                    </div>
                  </span>
                </div>
                <div>
                  <p style={{ marginBottom: "0px" }}>Est. Monthly Payment</p>
                </div>
              </div>
              {/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "10%", marginLeft: "4%" }}>
                  <div className='text-box-cal'>Apply For Loan</div>
                  <div className='text-box-cal'><p>Total loan amount <span style={{ fontWeight: "bold" }}>${new Intl.NumberFormat().format(loanAountData)}</span></p></div>
                </div> */}
            </Col>

            <Col
              sm={6}
              className="d-flex flex-column justify-content-around gap-4 align-items-center pb-5"
            >
              <div className={` ${styles.loadValContainer}`}>
                <img
                  src={circleImage}
                  alt=""
                  srcset=""
                  width="100%"
                  height="100%"
                />
                <span className={` ${styles.loanEmi}`}>
                  $
                  <span style={{ color: "#d1823a" }}>
                    {new Intl.NumberFormat().format(monthlyPayment)}
                  </span>
                  <span
                    className={styles.tooltip_container_b}
                    style={{ top: "-4px" }}
                  >
                    <IoMdInformationCircleOutline className="info-icon" />
                    <div className={styles.tooltip_text_b}>
                      This is an Estimated Monthly payment ONLY. It is NOT a
                      loan.
                    </div>
                  </span>
                  <p style={{ marginTop: "-1rem" }}>month</p>
                </span>
              </div>
              <div className="w-100 d-flex flex-column justify-content-around align-items-center">
                <h3 style={{ color: "white" }}>Get Pre Approve</h3>
                <p className={`${styles.apply_btn_cal}`}>Apply</p>
                {/* <div
                  className=" d-flex justify-content-around align-items-center"
                  style={{ gap: "5.5rem" }}
                >
                  
                  <img
                    src="image/emailLoan.png"
                    alt="Email"
                    height="60px"
                    width="60px"
                  />
                  <img
                    src="https://cdn.pixabay.com/photo/2016/12/18/13/45/download-1915753_1280.png"
                    alt="Download"
                    height="60px"
                    width="60px"
                  />
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
});

export default MonthlyLoanCalculator4;

import React, { useEffect, useState, useRef } from "react";
import { Col, Container, Row, Button, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  setSellData,
  saveSellData,
  setCoBuyerSellData,
} from "../../../Redux/slice/sellSlice";
import usaStates from "../addListing/usaStates.json";
import styles from "./buyerInfo.module.css";
import Autocomplete from "react-google-autocomplete";

const BuyerInfo = ({ selectedVehicle }) => {
  const sellData = useSelector((state) => state.sell.buyer) || {};
  const sellCoBuyerData = useSelector((state) => state.sell.sellCoBuyer) || {};
  const phoneNumbers = useSelector(
    (state) => state.sell.buyer.phoneNumbers || []
  );
  const CoBuyerphoneNumbers = useSelector(
    (state) => state.sell.sellCoBuyer.phoneNumbers || []
  );

  const dispatch = useDispatch();

  const [showComBuyerTab, setShowComBuyerTab] = useState(false);
  const [localPhoneNo, setLocalPhoneNo] = useState([]);
  const [CoBuyerlocalPhoneNo, setCoBuyerLocalPhoneNo] = useState([]);
  const [modalState, setModalState] = useState({ visible: false, type: "" });
  const [idType, setIdType] = useState("");
  const [idState, setIdState] = useState("");

  const handleModalClose = () => setModalState({ visible: false, type: "" });
  const handleModalShow = (type) => setModalState({ visible: true, type });

  const handleSelectType = (type) => {
    setIdType(type);
    handleModalClose();
  };

  const handleSelectState = (state) => {
    setIdState(state);
    handleModalClose();
  };

  useEffect(() => {
    sessionStorage.setItem("sellData", JSON.stringify(sellData));
  }, [sellData]);

  useEffect(() => {
    setLocalPhoneNo(phoneNumbers.map((num) => formatPhoneNumber(num)));
  }, [phoneNumbers]);

  const toggleComBuyerTab = () => {
    setShowComBuyerTab(!showComBuyerTab);
  };

  const handleInputChange = (field, value) => {
    dispatch(setSellData({ buyer: { ...sellData, [field]: value } }));
  };

  const handleCoBuyerInputChange = (field, value) => {
    dispatch(
      setCoBuyerSellData({
        sellCoBuyer: { ...sellCoBuyerData, [field]: value },
      })
    );
  };

  const formatPhoneNumber = (phoneNumber) => {
    const unformattedPhoneNumber = phoneNumber.replace(/\D/g, "");
    if (/^\d{10}$/.test(unformattedPhoneNumber)) {
      return `(${unformattedPhoneNumber.slice(
        0,
        3
      )}) ${unformattedPhoneNumber.slice(3, 6)}-${unformattedPhoneNumber.slice(
        6
      )}`;
    }
    return phoneNumber;
  };

  const handlePhoneNumberChange = async (index, value) => {
    const unformattedPhoneNumber = value.replace(/\D/g, "");
    let formattedPhoneNumber = value;
    if (/^\d{10}$/.test(unformattedPhoneNumber)) {
      formattedPhoneNumber = `(${unformattedPhoneNumber.slice(
        0,
        3
      )}) ${unformattedPhoneNumber.slice(3, 6)}-${unformattedPhoneNumber.slice(
        6
      )}`;
    }

    const updatedPhoneNumbers = [...phoneNumbers];
    updatedPhoneNumbers[index] = unformattedPhoneNumber;

    setLocalPhoneNo([
      ...localPhoneNo.slice(0, index),
      formattedPhoneNumber,
      ...localPhoneNo.slice(index + 1),
    ]);

    dispatch(
      setSellData({ buyer: { ...sellData, phoneNumbers: updatedPhoneNumbers } })
    );
  };

  const handleCoBuyerPhoneNumberChange = async (index, value) => {
    const unformattedPhoneNumber = value.replace(/\D/g, "");
    let formattedPhoneNumber = value;
    if (/^\d{10}$/.test(unformattedPhoneNumber)) {
      formattedPhoneNumber = `(${unformattedPhoneNumber.slice(
        0,
        3
      )}) ${unformattedPhoneNumber.slice(3, 6)}-${unformattedPhoneNumber.slice(
        6
      )}`;
    }

    const updatedPhoneNumbers = [...CoBuyerphoneNumbers];
    updatedPhoneNumbers[index] = unformattedPhoneNumber;

    setCoBuyerLocalPhoneNo([
      ...CoBuyerlocalPhoneNo.slice(0, index),
      formattedPhoneNumber,
      ...CoBuyerlocalPhoneNo.slice(index + 1),
    ]);

    dispatch(
      setCoBuyerSellData({
        sellCoBuyer: { ...sellCoBuyerData, phoneNumbers: updatedPhoneNumbers },
      })
    );
  };

  const addPhoneNumber = () => {
    const updatedPhoneNumbers = [...phoneNumbers];
    if (updatedPhoneNumbers.length < 2) {
      updatedPhoneNumbers.push("");
      setLocalPhoneNo([...localPhoneNo, ""]);
      dispatch(
        setSellData({
          buyer: { ...sellData, phoneNumbers: updatedPhoneNumbers },
        })
      );
    }
  };

  const deletePhoneNumber = (index) => {
    const updatedPhoneNumbers = phoneNumbers.filter((_, i) => i !== index);
    const updatedLocalPhoneNo = localPhoneNo.filter((_, i) => i !== index);
    setLocalPhoneNo(updatedLocalPhoneNo);
    dispatch(
      setSellData({ buyer: { ...sellData, phoneNumbers: updatedPhoneNumbers } })
    );
  };

  const handleSave = async () => {
    try {
      dispatch(saveSellData());
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const autocompleteRef = useRef(null);

  const handlePlaceSelected = (place) => {
    const addressComponents = place.address_components;
    const formattedAddress = place.formatted_address;
    let city = "";
    let state = "";
    let zipCode = "";
    let country = "";

    addressComponents.forEach((component) => {
      const types = component.types;
      if (types.includes("locality")) {
        city = component.long_name;
      }
      if (types.includes("administrative_area_level_1")) {
        state = component.short_name;
      }
      if (types.includes("postal_code")) {
        zipCode = component.long_name;
      }
      if (types.includes("country")) {
        country = component.long_name;
      }
    });

    // Update the buyer state with the new address details
    dispatch(
      setSellData({
        buyer: {
          ...sellData,
          address: formattedAddress,
          city,
          state,
          zipCode,
          country,
        },
      })
    );
  };

  return (
    <>
      <Container className="px-0">
        <Form>
          <Row className=" d-flex justify-content-end mb-4">
            <Col>
              {selectedVehicle ? selectedVehicle.year_Make_Model : null}&emsp;
              &emsp;
              {selectedVehicle ? `Vin:${selectedVehicle.vin}` : null}&emsp;
              &emsp;
              {selectedVehicle ? `Mileage:${selectedVehicle.mileage_in}` : null}
              &emsp; &emsp;
              {selectedVehicle
                ? `Listing Price:${selectedVehicle.listing_price}`
                : null}
              &emsp; &emsp;
            </Col>

            <Col sm={2}>
              <label
                className="checkbox-container text-nowrap"
                style={{ float: "right", color: "#e8a262" }}
              >
                <input type="checkbox" onChange={toggleComBuyerTab} />
                Co-Buyer
              </label>
            </Col>
          </Row>

          <Row className=" d-flex justify-content-center">
            <Col sm={6}>
              <Form.Group className={styles.formGroup}>
                <Form.Label style={{ fontWeight: "400" }}>
                  Account No:
                </Form.Label>
                <Form.Control
                  type="text"
                  style={{ fontWeight: "700" }}
                  value={selectedVehicle?.stockNo || ""}
                />
              </Form.Group>
              <Form.Group className={`${styles.formGroup}`}>
                <Form.Label style={{ fontWeight: "400" }}>Name:</Form.Label>
                <div className={styles.inlineFields}>
                  <Form.Control
                    type="text"
                    placeholder="First Name         M         Last Name"
                    style={{ fontWeight: "700" }}
                    onChange={(e) =>
                      handleInputChange("firstName", e.target.value)
                    }
                  />
                  {/* <Form.Control type="text" placeholder="M" />
                  <Form.Control type="text" placeholder="Last Name" /> */}
                </div>
              </Form.Group>
              <Form.Group
                className={`${styles.formGroup} d-flex justify-content-between`}
              >
                <Form.Label style={{ fontWeight: "400" }}>ID Type:</Form.Label>
                <div className={styles.idType}>
                  <Form.Control
                    type="text"
                    value={idType}
                    style={{ fontWeight: "700" }}
                  />
                  <Button
                    variant="secondary"
                    onClick={() => handleModalShow("type")}
                  >
                    &#10140;
                  </Button>
                </div>
              </Form.Group>
              <Form.Group className={styles.formGroup}>
                <Form.Label style={{ fontWeight: "400" }}>
                  ID Number:
                </Form.Label>
                <Form.Control
                  style={{ fontWeight: "700" }}
                  type="text"
                  onChange={(e) =>
                    handleInputChange("idNumber", e.target.value)
                  }
                />
              </Form.Group>
              <Form.Group
                className={`${styles.formGroup} d-flex justify-content-between`}
              >
                <Form.Label
                  style={{ fontWeight: "400" }}
                  className="text-nowrap"
                >
                  ID State:
                </Form.Label>
                <div className={styles.idType}>
                  <Form.Control
                    type="text"
                    value={idState}
                    style={{ fontWeight: "700" }}
                  />
                  <Button
                    variant="secondary"
                    onClick={() => handleModalShow("state")}
                  >
                    &#10140;
                  </Button>
                </div>
              </Form.Group>
              <Form.Group className={styles.formGroup}>
                <Form.Label style={{ fontWeight: "400" }}>Address:</Form.Label>
                <Autocomplete
                  apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                  onChange={(e) => handleInputChange("address", e.target.value)}
                  onPlaceSelected={handlePlaceSelected}
                  types={["address"]}
                  ref={autocompleteRef}
                  options={{
                    componentRestrictions: { country: "us" },
                  }}
                  value={sellData.address}
                  defaultValue={sellData.address || ""}
                  placeholder="Enter address"
                  className="form-control-plaintext fw-bold"
                />
              </Form.Group>
              <Form.Group className={styles.formGroup}>
                <Form.Label style={{ fontWeight: "400" }}>Phone:</Form.Label>
                <Form.Control
                  type="text"
                  style={{ fontWeight: "700" }}
                  defaultValue="(469) - 456 6560"
                />
              </Form.Group>
              <Form.Group className={styles.formGroup}>
                <Form.Label style={{ fontWeight: "400" }}>Email:</Form.Label>
                <Form.Control
                  type="text"
                  style={{ fontWeight: "700" }}
                  onChange={(e) => handleInputChange("email", e.target.value)}
                />
              </Form.Group>
            </Col>

            {showComBuyerTab ? (
              <Col>
                <Form.Group className={styles.formGroup}>
                  <Form.Label style={{ fontWeight: "400" }}>
                    Account No:
                  </Form.Label>
                  <Form.Control
                    type="text"
                    style={{ fontWeight: "700" }}
                    defaultValue="1"
                  />
                </Form.Group>
                <Form.Group className={`${styles.formGroup}`}>
                  <Form.Label style={{ fontWeight: "400" }}>Name:</Form.Label>
                  <div className={styles.inlineFields}>
                    <Form.Control
                      type="text"
                      placeholder="First Name         M         Last Name"
                      defaultValue="Priyanka"
                    />
                    {/* <Form.Control type="text" placeholder="M" />
                <Form.Control type="text" placeho..lder="Last Name" /> */}
                  </div>
                </Form.Group>
                <Form.Group
                  className={`${styles.formGroup} d-flex justify-content-between`}
                >
                  <Form.Label style={{ fontWeight: "400" }}>
                    ID Type:
                  </Form.Label>
                  <div className={styles.idType}>
                    <Form.Control
                      type="text"
                      value={idType}
                      style={{ fontWeight: "700" }}
                    />
                    <Button
                      variant="secondary"
                      onClick={() => handleModalShow("type")}
                    >
                      &#10140;
                    </Button>
                  </div>
                </Form.Group>
                <Form.Group className={styles.formGroup}>
                  <Form.Label style={{ fontWeight: "400" }}>
                    ID Number:
                  </Form.Label>
                  <Form.Control style={{ fontWeight: "700" }} type="text" />
                </Form.Group>
                <Form.Group
                  className={`${styles.formGroup} d-flex justify-content-between`}
                >
                  <Form.Label
                    style={{ fontWeight: "400" }}
                    className="text-nowrap"
                  >
                    ID State:
                  </Form.Label>
                  <div className={styles.idType}>
                    <Form.Control
                      type="text"
                      value={idState}
                      style={{ fontWeight: "700" }}
                    />
                    <Button
                      variant="secondary"
                      onClick={() => handleModalShow("state")}
                    >
                      &#10140;
                    </Button>
                  </div>
                </Form.Group>
                <Form.Group className={styles.formGroup}>
                  <Form.Label style={{ fontWeight: "400" }}>
                    Address:
                  </Form.Label>
                  <Form.Control
                    type="text"
                    style={{ fontWeight: "700" }}
                    defaultValue="1503 S McDonald St, McKinney, TX 75069"
                  />
                </Form.Group>
                <Form.Group className={styles.formGroup}>
                  <Form.Label style={{ fontWeight: "400" }}>Phone:</Form.Label>
                  <Form.Control
                    type="text"
                    style={{ fontWeight: "700" }}
                    defaultValue="(469) - 456 6560"
                  />
                </Form.Group>
                <Form.Group className={styles.formGroup}>
                  <Form.Label style={{ fontWeight: "400" }}>Email:</Form.Label>
                  <Form.Control
                    type="text"
                    style={{ fontWeight: "700" }}
                    defaultValue="Dallas.Dallas@gmail.com"
                  />
                </Form.Group>
              </Col>
            ) : null}
          </Row>
        </Form>

        <Modal show={modalState.visible} onHide={handleModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              {modalState.type === "type"
                ? "Select ID Type"
                : "Select ID State"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="h-50 ">
            {modalState.type === "type" && (
              <>
                <Form.Check
                  type="radio"
                  label="U.S Driver License"
                  name="documentType"
                  id="driverLicense"
                  onChange={() => handleSelectType("U.S Driver License")}
                />
                <Form.Check
                  type="radio"
                  label="U.S. State ID"
                  name="documentType"
                  id="stateID"
                  onChange={() => handleSelectType("U.S. State ID")}
                />
                <Form.Check
                  type="radio"
                  label="U.S. Citizenship &Immigration Services/DOJ ID"
                  name="documentType"
                  id="Citizenship"
                  onChange={() =>
                    handleSelectType(
                      "U.S. Citizenship &Immigration Services/DOJ ID"
                    )
                  }
                />
                <Form.Check
                  type="radio"
                  label="Passport"
                  name="documentType"
                  id="passport"
                  onChange={() => handleSelectType("Passport")}
                />
                <Form.Check
                  type="radio"
                  label="NATO ID"
                  name="documentType"
                  id="natoId"
                  onChange={() => handleSelectType("NATO ID")}
                />
                <Form.Check
                  type="radio"
                  label="U.S. Military ID"
                  name="documentType"
                  id="militaryId"
                  onChange={() => handleSelectType("U.S. Military ID")}
                />
                <Form.Check
                  type="radio"
                  label="U.S. Dept. of State ID"
                  name="documentType"
                  id="deptStateId"
                  onChange={() => handleSelectType("U.S. Dept. of State ID")}
                />
                <Form.Check
                  type="radio"
                  label="U.S. Dept. of Homeland Security ID"
                  name="documentType"
                  id="deptHomelandSecurityId"
                  onChange={() =>
                    handleSelectType("U.S. Dept. of Homeland Security ID")
                  }
                />
                <Form.Check
                  type="radio"
                  label="Other Military of Forces Photo ID"
                  name="documentType"
                  id="otherMilitaryForcesPhotoId"
                  onChange={() =>
                    handleSelectType("Other Military of Forces Photo ID")
                  }
                />
              </>
            )}
            {modalState.type === "state" && (
              <>
                {usaStates.map((state) => (
                  <Form.Check
                    key={state.abbreviation}
                    type="radio"
                    label={state.name}
                    name="documentState"
                    id={state.abbreviation}
                    onChange={() => handleSelectState(state.name)}
                  />
                ))}
              </>
            )}
          </Modal.Body>
        </Modal>
      </Container>
    </>
  );
};

export default BuyerInfo;

// <div className="buyer-info mb-5">
//   <Container fluid>
//     <Row className="w-100 d-flex justify-content-end">
//       <Col sm={6}>{/* Vehicle Info */}</Col>
//       <Col sm={6}>
//         <label
//           className="checkbox-container text-nowrap"
//           style={{ float: "right", color: "#e8a262" }}
//         >
//           <input type="checkbox" onChange={toggleComBuyerTab} />
//           Company Buyer
//         </label>
//       </Col>
//     </Row>
//     <Col sm={1} style={{ marginTop: "-30px" }}>
//       <label htmlFor="inputAccount" className="form-label text-nowrap">
//         Ac. No.
//       </label>
//       <input
//         type="text"
//         value={selectedVehicle?.stockNo || ""}
//         className="form-control-plaintext form-border"
//       />
//     </Col>
//     <Row className="py-3">
//       <Col sm={2}>
//         <label htmlFor="firstName" className="form-label">
//            First Name
//         </label>
//         <input
//           type="text"
//           className="form-control-plaintext form-border"
//           onChange={(e) => handleInputChange("firstName", e.target.value)}
//         />
//       </Col>

//       <>
//         <Col sm={1}>
//           <label htmlFor="middleName" className="form-label">
//             Mid. Name
//           </label>
//           <input
//             type="text"
//             className="form-control-plaintext form-border"
//             onChange={(e) =>
//               handleInputChange("middleName", e.target.value)
//             }
//           />
//         </Col>
//         <Col sm={2}>
//           <label htmlFor="lastName" className="form-label">
//             Last Name
//           </label>
//           <input
//             type="text"
//             className="form-control-plaintext form-border"
//             onChange={(e) => handleInputChange("lastName", e.target.value)}
//           />
//         </Col>
//       </>

//       <Col sm={3}>
//         <label htmlFor="inputState" className="form-label">
//           ID.Type
//         </label>
//         <select
//           className="form-select"
//           onChange={(e) => handleInputChange("idType", e.target.value)}
//         >
//           <option selected>U.S Driver License</option>
//           <option>U.S. State ID</option>
//           <option>U.S. Citizenship &Immigration Services/DOJ ID</option>
//           <option>Passport </option>
//           <option>NATO ID</option>
//           <option>U.S. Military ID</option>
//           <option>U.S. Dept. of State ID</option>
//           <option>U.S. Dept. of Homeland Security ID</option>
//           <option>Other Military of Forces Photo ID</option>
//         </select>
//       </Col>

//       <Col sm={2}>
//         {/* <div className="num-state"> */}
//         {/* <div style={{ width: "50%" }}> */}
//         <label htmlFor="idNumber" className="form-label">
//           ID Number
//         </label>
//         <input
//           type="text"
//           className="form-control-plaintext form-border"
//           onChange={(e) => handleInputChange("idNumber", e.target.value)}
//         />
//         {/* </div> */}
//         {/* </div> */}
//       </Col>
//       <Col sm={2}>
//         <label htmlFor="state" className="form-label">
//           State
//         </label>
//         <input
//           type="text"
//           className="form-control-plaintext form-border px-3"
//          onChange={(e) => handleInputChange("idNumber", e.target.value)}
//         />
//       </Col>
//     </Row>
//     <Row className="input-user-container py-5">
//       <Col sm={2}>
//         <label htmlFor="Address" className="form-label">
//           Address
//         </label>
//         <Autocomplete
//           apiKey={process.env.REACT_APP_GOOGLE_API_KEY}

//           onPlaceSelected={handlePlaceSelected}
//           options={{
//             types: ["address"],
//           }}
//           defaultValue={address}
//           className="form-control-plaintext form-border"
//         />
//       </Col>
//       <Col sm={1}>
//         <label htmlFor="Apt" className="form-label">
//           Apt#
//         </label>
//         <input
//           type="text"
//           className="form-control-plaintext form-border"
//           onChange={(e) => handleInputChange("apt", e.target.value)}
//         />
//       </Col>
//       <Col sm={2}>
//         <label htmlFor="City" className="form-label">
//           City
//         </label>
//         <input
//           type="text"
//           className="form-control-plaintext form-border"
//           value={sellData.city}
//           onChange={(e) => handleInputChange("city", e.target.value)}
//         />
//       </Col>
//       <Col sm={1}>
//         <label htmlFor="State" className="form-label">
//           State
//         </label>
//         <input
//           type="text"
//           className="form-control-plaintext form-border"
//           value={sellData.state}
//           onChange={(e) => handleInputChange("state", e.target.value)}
//         />
//       </Col>
//       <Col sm={1}>
//         <label htmlFor="zipCode" className="form-label">
//           Zip Code
//         </label>
//         <input
//           type="text"
//           className="form-control-plaintext form-border"
//           value={sellData.zipCode}
//           onChange={(e) => handleInputChange("zipCode", e.target.value)}
//         />
//       </Col>

//       <Col sm={1}>
//         <label htmlFor="inputState" className="form-label">
//           Country
//         </label>
//         <input
//           type="text"
//
//           className="form-control-plaintext form-border"
//           value="USA"
//         />
//       </Col>
//       <Col sm={2}>
//         <label htmlFor="Phone1" className="form-label text-nowrap">
//           <FaPhoneVolume style={{ fill: "#d1823a" }} /> Phone #1
//         </label>
//         <input
//           type="text"
//           placeholder="(xxx) xxx xxxx"
//           className="form-control-plaintext form-border"
//           value={localPhoneNo[0] || ""}
//           // onChange={(e) => handlePhoneNumberChange(0, e.target.value, e.target.selectionStart)}
//           // onKeyDown={(e) => handlePhoneNumberKeyDown(e, 0)}
//           onChange={(e) => handlePhoneNumberChange(0, e.target.value)}
//         />
//       </Col>
//       <Col sm={2}>
//         <label htmlFor="Email" className="form-label">
//           <AiOutlineMail style={{ fill: "#d1823a" }} /> Email
//         </label>
//         <input
//           type="text"
//           placeholder="example@example.com"
//           className="form-control-plaintext form-border"
//           onChange={(e) => handleInputChange("email", e.target.value)}
//         />
//       </Col>

//       {phoneNumbers.length < 2 && (
//         <Col sm={1}>
//           <button
//             style={{
//               border: "none",
//               backgroundColor: "#e8a262",
//               borderRadius: "10px",
//               color: "white",
//               marginTop: "30px",
//             }}
//             onClick={addPhoneNumber}
//           >
//             + <FaPhoneVolume style={{ fill: "white" }} />
//           </button>
//         </Col>
//       )}
//     </Row>
//     {showComBuyerTab ? (
//       <>
//         <Row className="py-3  " style={{marginTop : "3rem"}}>
//           <Col sm={2}>
//             <label htmlFor="firstName" className="form-label">
//               First Name
//             </label>
//             <input
//               type="text"
//               className="form-control-plaintext form-border"
//               onChange={(e) =>
//                 handleCoBuyerInputChange("firstName", e.target.value)
//               }
//             />
//           </Col>

//           <Col sm={1}>
//             <label htmlFor="middleName" className="form-label">
//               Mid. Name
//             </label>
//             <input
//               type="text"
//               className="form-control-plaintext form-border"
//               onChange={(e) =>
//                 handleCoBuyerInputChange("middleName", e.target.value)
//               }
//             />
//           </Col>
//           <Col sm={2}>
//             <label htmlFor="lastName" className="form-label">
//               Last Name
//             </label>
//             <input
//               type="text"
//               className="form-control-plaintext form-border"
//               onChange={(e) =>
//                 handleCoBuyerInputChange("lastName", e.target.value)
//               }
//             />
//           </Col>

//           <Col sm={3}>
//             <label htmlFor="inputState" className="form-label">
//               ID.Type
//             </label>
//             <select
//               className="form-select"
//               onChange={(e) => handleCoBuyerInputChange("idType", e.target.value)}
//             >
//               <option selected>U.S Driver License</option>
//               <option>U.S. State ID</option>
//               <option>U.S. Citizenship &Immigration Services/DOJ ID</option>
//               <option>Passport </option>
//               <option>NATO ID</option>
//               <option>U.S. Military ID</option>
//               <option>U.S. Dept. of State ID</option>
//               <option>U.S. Dept. of Homeland Security ID</option>
//               <option>Other Military of Forces Photo ID</option>
//             </select>
//           </Col>

//           <Col sm={2}>
//             {/* <div className="num-state"> */}
//             {/* <div style={{ width: "50%" }}> */}
//             <label htmlFor="idNumber" className="form-label">
//               ID Number
//             </label>
//             <input
//               type="text"
//               className="form-control-plaintext form-border"
//               onChange={(e) =>
//                 handleCoBuyerInputChange("idNumber", e.target.value)
//               }
//             />
//             {/* </div> */}
//             {/* </div> */}
//           </Col>
//           <Col sm={2}>
//             <label htmlFor="state" className="form-label">
//               State
//             </label>
//             <input
//               type="text"
//               className="form-control-plaintext form-border px-3"
//               onChange={(e) => handleCoBuyerInputChange("idState", e.target.value)}
//             />
//           </Col>
//         </Row>
//         <Row className="input-user-container py-5">
//           <Col sm={2}>
//             <label htmlFor="Address" className="form-label">
//               Address
//             </label>
//             <Autocomplete
//               apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
//               onPlaceSelected={handlePlaceSelected}
//               options={{
//                 types: ["address"],
//               }}
//               defaultValue={address}
//               className="form-control-plaintext form-border"
//             />
//           </Col>
//           <Col sm={1}>
//             <label htmlFor="Apt" className="form-label">
//               Apt#
//             </label>
//             <input
//               type="text"
//               className="form-control-plaintext form-border"
//               onChange={(e) => handleCoBuyerInputChange("apt", e.target.value)}
//             />
//           </Col>
//           <Col sm={2}>
//             <label htmlFor="City" className="form-label">
//               City
//             </label>
//             <input
//               type="text"
//               className="form-control-plaintext form-border"
//               value={sellCoBuyerData.city}
//               onChange={(e) => handleCoBuyerInputChange("city", e.target.value)}
//             />
//           </Col>
//           <Col sm={1}>
//             <label htmlFor="State" className="form-label">
//               State
//             </label>
//             <input
//               type="text"
//               className="form-control-plaintext form-border"
//               value={sellCoBuyerData.state}
//               onChange={(e) => handleCoBuyerInputChange("state", e.target.value)}
//             />
//           </Col>
//           <Col sm={1}>
//             <label htmlFor="zipCode" className="form-label">
//               Zip Code
//             </label>
//             <input
//               type="text"
//               className="form-control-plaintext form-border"
//               value={sellCoBuyerData.zipCode}
//               onChange={(e) => handleCoBuyerInputChange("zipCode", e.target.value)}
//             />
//           </Col>

//           <Col sm={1}>
//             <label htmlFor="inputState" className="form-label">
//               Country
//             </label>
//             <input
//               type="text"
//
//               className="form-control-plaintext form-border"
//               value="USA"
//             />
//           </Col>
//           <Col sm={2}>
//             <label htmlFor="Phone1" className="form-label text-nowrap">
//               <FaPhoneVolume style={{ fill: "#d1823a" }} /> Phone #1
//             </label>
//             <input
//               type="text"
//               placeholder="(xxx) xxx xxxx"
//               className="form-control-plaintext form-border"
//               value={localPhoneNo[0] || ""}
//               // onChange={(e) => handleCoBuyerPhoneNumberChange(0, e.target.value, e.target.selectionStart)}
//               // onKeyDown={(e) => handlePhoneNumberKeyDown(e, 0)}
//               onChange={(e) => handleCoBuyerPhoneNumberChange(0, e.target.value)}
//             />
//           </Col>
//           <Col sm={2}>
//             <label htmlFor="Email" className="form-label">
//               <AiOutlineMail style={{ fill: "#d1823a" }} /> Email
//             </label>
//             <input
//               type="text"
//               placeholder="example@example.com"
//               className="form-control-plaintext form-border"
//               onChange={(e) => handleCoBuyerInputChange("email", e.target.value)}
//             />
//           </Col>

//         </Row>
//       </>
//     ) : null}
//   </Container>
// </div>

import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { saveUser } from "../../../Redux/slice/userSlice";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

import toast from "react-hot-toast";

const GoogleOneTaplogin = () => {
  const { email, id } = useSelector((state) => state.user);
  const [showAskWhichUserModal, setShowAskWhichUserModal] = useState(false);
  const [responseFromGoogleOneTapLogin, setResponseFromGoogleOneTapLogin] =
    useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (!email && !id) {
      const script = document.createElement("script");
      script.src = "https://accounts.google.com/gsi/client";
      script.async = true;
      script.defer = true;
      script.onload = (data) => {
        function decodeJwtResponse(token) {
          let base64Url = token.split(".")[1];
          let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
          let jsonPayload = decodeURIComponent(
            atob(base64)
              .split("")
              .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
              })
              .join("")
          );
          return JSON.parse(jsonPayload);
        }
        let responsePayload;
        function handleCredentialResponse(response) {
          // decodeJwtResponse() is a custom function defined by you
          // to decode the credential response.
          responsePayload = decodeJwtResponse(response.credential);
          console.log(" Response from google  => ", responsePayload);
          console.log("ID: " + responsePayload.sub);
          console.log("Full Name: " + responsePayload.name);
          console.log("Given Name: " + responsePayload.given_name);
          console.log("Family Name: " + responsePayload.family_name);
          console.log("Image URL: " + responsePayload.picture);
          console.log("Email: " + responsePayload.email);
          if (responsePayload && responsePayload.email) {
            setResponseFromGoogleOneTapLogin(responsePayload);
            setShowAskWhichUserModal(true);
          }
        }

        window.google.accounts.id.initialize({
          client_id:
            "413776152809-kefhp5sb06c160jr9h3ndb9t0svtkpke.apps.googleusercontent.com",
          // "854910170922-t0t33tjvq2dubabr5a54j9o89knqvarv.apps.googleusercontent.com", //Google Client Id
          //Google Client Id
          callback: handleCredentialResponse,
        });
        try {
          window.google.accounts.id.prompt();
        } catch (error) {
          if (error instanceof window.NetworkError) {
            console.error("Error retrieving token:", error);
            // Handle the error, e.g., display an error message to the user
          } else {
            throw error;
          }
        }
      };
      document.body.appendChild(script);
    }
  }, []);

  const getIPAddress = async () => {
    try {
      const res = await axios.get("https://api.ipify.org/?format=json");
      return res.data.ip;
    } catch (error) {
      console.error("Error fetching IP information:", error);
      return "";
    }
  };

  const [userSelected, setUserSelected] = useState({
    user: false,
    dealer: false,
  });
  const [loading, setloading] = useState(false);
  const loginGoogleOneTap = async (userType) => {
    setloading(true);
    const ip = await getIPAddress();
    const formData = new FormData();
    formData.append("email", responseFromGoogleOneTapLogin.email || "");
    formData.append(
      "firstName",
      responseFromGoogleOneTapLogin.given_name
        ? responseFromGoogleOneTapLogin.given_name
        : ""
    );
    formData.append(
      "lastName",
      responseFromGoogleOneTapLogin.family_name
        ? responseFromGoogleOneTapLogin.family_name
        : ""
    );

    formData.append("ip", ip);
    formData.append("user_type", userType);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/save_user`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (res.status === 200) {
        toast.success("Signed in successfully");
        dispatch(saveUser(res.data));
        console.log("Response666", res.data); // Log response for both screens
        if (res.data.roles === "Dealer") {
          navigate("/dealer/dashboard");
        }
      }
    } catch (error) {
      toast.error("Server side error while logging in");
      console.error("Error saving user data:", error);
    } finally {
      setloading(false);
      setShowAskWhichUserModal(false);
      setUserSelected((prevData) => ({
        ...prevData,
        user: false,
        dealer: false,
      }));
    }
  };
  return (
    <>
      <Modal
        show={showAskWhichUserModal}
        onHide={() => setShowAskWhichUserModal(false)}
      >
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p
            style={{
              fontWeight: "600",
              fontSize: "larger",
              textAlign: "center",
            }}
          >
            How would you like to log in?
          </p>
          <p style={{ display: "flex", justifyContent: "space-evenly" }}>
            <button
              style={{
                border: "none",
                color: "#d1823a",
                fontWeight: "500",
                width: "30%",
                padding: "0.3rem 0.6rem",
                borderRadius: "4px",

                fontSize: "large",
              }}
              onClick={() => {
                setUserSelected((prevData) => ({
                  ...prevData,
                  user: true,
                  dealer: false,
                }));
                loginGoogleOneTap("User");
              }}
            >
              {loading && userSelected.user ? "Signing in" : "As a Customer"}
            </button>
            <button
              style={{
                border: "none",
                color: "white",
                backgroundColor: "#d1823a",
                fontWeight: "500",
                width: "30%",
                padding: "0.3rem 0.6rem",
                borderRadius: "4px",
                fontSize: "large",
              }}
              onClick={() => {
                setUserSelected((prevData) => ({
                  ...prevData,
                  user: false,
                  dealer: true,
                }));
                loginGoogleOneTap("Dealer");
              }}
            >
              {loading && userSelected.dealer ? "Signing in" : "As a Dealer"}
            </button>
          </p>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default GoogleOneTaplogin;

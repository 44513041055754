import { createSlice } from '@reduxjs/toolkit';

const addlistingdataSlice = createSlice({
  name: 'addlistingdata',
  initialState: {
    z: null,
    selectedGroup: 'Safety Features',
    selectedEquipments: [''],
    groupsAndEquipments: {
      'Safety Features': [
        'ABS (Anti-lock Braking System)',
        'Airbags (Front, Side, Curtain)',
        'Backup Camera',
        'Blind Spot Monitoring',
        'Brake Assist',
        'Collision Mitigation System',
        'Cross Traffic Alert',
        'Daytime Running Lights',
        'Electronic Stability Control',
        'Forward Collision Warning',
        'Hill Descent Control',
        'Hill Start Assist',
        'Lane Departure Warning',
        'Lane Keeping Assist',
        'Parking Sensors (Front and Rear)',
        'Rear Cross Traffic Alert',
        'Tire Pressure Monitoring System',
        'Traction Control'
      ],
      'Comfort and Convenience': [
        'Adaptive Cruise Control',
        'Auto-dimming Rearview Mirror',
        'Automatic Climate Control',
        'Heated and Ventilated Seats',
        'Keyless Entry',
        'Keyless Start (Push Button Start)',
        'Leather Seats',
        'Memory Seats',
        'Multi-zone Climate Control',
        'Panoramic Sunroof/Moonroof',
        'Power Adjustable Seats',
        'Power Liftgate',
        'Power Windows and Mirrors',
        'Remote Start',
        'Smart Trunk Opener',
        'Sunshades (Rear and Side Windows)',
        'Tilt and Telescopic Steering Wheel'
      ],
      'Infotainment': [
        'Apple CarPlay and Android Auto',
        'Bluetooth Connectivity',
        'In-car Wi-Fi',
        'Navigation System',
        'Premium Sound System',
        'Satellite Radio',
        'Touchscreen Display',
        'USB Ports',
        'Voice Recognition',
        'Wireless Charging Pad'
      ],
      'Exterior Features': [
        'Alloy Wheels',
        'Automatic Headlights',
        'Fog Lights',
        'LED Headlights',
        'Power-folding Mirrors',
        'Roof Rails',
        'Running Boards',
        'Spoiler',
        'Tow Hitch',
        'Windshield Wiper De-icer'
      ],
      'Performance and Handling': [
        'Adaptive Suspension',
        'All-wheel Drive',
        'Drive Mode Selector',
        'Limited Slip Differential',
        'Sport-tuned Suspension',
        'Variable Steering Assist'
      ],
      'Interior Features': [
        'Ambient Lighting',
        'Cargo Cover',
        'Cargo Net',
        'Center Console with Armrest',
        'Heated Steering Wheel',
        'Leather-wrapped Steering Wheel',
        'Rear Seat Entertainment System',
        'Split-folding Rear Seats'
      ],
      'Driver Assistance': [
        'Adaptive Headlights',
        'Automatic High Beams',
        'Driver Drowsiness Monitoring',
        'Head-up Display',
        'Night Vision Assist',
        'Parking Assistance (Self-parking)',
        'Surround View Camera System',
        'Traffic Sign Recognition'
      ],
      'Energy and Environment': [
        'Engine Start-Stop System',
        'Hybrid Powertrain',
        'Regenerative Braking',
        'Solar Roof'
      ],
      'Additional Equipments': [] // Added a default empty array for additional equipments
    }
  },
  reducers: {
    setSelectedGroup(state, action) {
      state.selectedGroup = action.payload;
    },

    addEquipment(state, action) {
      const { equipment, groupName } = action.payload;
      if (groupName === 'Additional Equipments') {
        if (!state.z[groupName].includes(equipment)) {
          state.z[groupName].push(equipment);
        }
      }
      // You can add additional conditions or actions for other groups if needed
    },

    removeEquipment(state, action) {
      const { equipment, groupName } = action.payload;
      if (groupName === 'Additional Equipments') {
        state.z[groupName] = state.z[groupName].filter(item => item !== equipment);
        state.groupsAndEquipments[groupName] = state.groupsAndEquipments[groupName].filter(item => item !== equipment);
      }
      state.selectedEquipments = state.selectedEquipments.filter(item => item !== equipment);
    },

    addSelectedEquipment(state, action) {
      const { equipment } = action.payload;
      // if (!state.selectedEquipments.includes(equipment)) {
      state.selectedEquipments.push(equipment);
      // }
    },


    removeSelectedEquipment(state, action) {
      const { equipment } = action.payload;
      state.selectedEquipments = state.selectedEquipments.filter(item => item !== equipment);
    },
  },
});

export const { 
  setSelectedGroup,
  selectedEquipments,
  addEquipment, removeEquipment,
  addSelectedEquipment,
  removeSelectedEquipment,
} = addlistingdataSlice.actions;

export default addlistingdataSlice.reducer;



// import { createSlice } from '@reduxjs/toolkit';

// const dataSlice = createSlice({
//   name: 'data',
//   initialState: {
//     x: null, // Initial value of x
//   },
//   reducers: {
//     setData(state, action) {
//       state.x = action.payload;
//     },
//   },
// });

// export const { setData } = dataSlice.actions;
// export default dataSlice.reducer;
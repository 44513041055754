import React, { useEffect, useState } from 'react'
import { useOutletContext } from "react-router-dom";
import { Button, Col, Container, Row } from 'react-bootstrap'
import Dropdown from 'react-bootstrap/Dropdown';
import { MdEmail, MdDelete, MdOutbox, MdMarkEmailRead, MdOutlineContactMail, MdOutlineSpeakerNotes, MdArrowLeft, MdOutlineArrowRight, MdArrowRight } from "react-icons/md";
import { PiBookOpenTextDuotone } from "react-icons/pi";
import { FaRegCalendarAlt, FaSearch } from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import { BsBellFill, BsBugFill } from "react-icons/bs";
import { IoSearch } from "react-icons/io5";

import { IoMdMailOpen, IoMdMailUnread } from "react-icons/io";
import { RiFoldersFill, } from "react-icons/ri";
import { FaStar } from "react-icons/fa6";
import styles from './mail.module.css'
import './Styles/datePicker.css'

import Image from '../../imgData';


import DealerInbox from './Pages/DealerInbox';
import DealerJunk from './Pages/DealerJunk';
import DealerStars from './Pages/DealerStars';
import DealerSend from './Pages/DealerSend';
import DealerMailDraft from './Pages/DealerMailDraft';
import DealerDelete from './Pages/DealerDelete';
import DealerFolders from './Pages/DealerFolders';
import DealerOutbox from './Pages/DealerOutbox';
import DealerMails from './Pages/DealerMails';
import DealerContacts from './Pages/DealerContacts';
import DealerNotes from './Pages/DealerNotes';
import DealerCalendar from './Pages/DealerCalendar';
import DealerSetting from './Pages/DealerSetting';
import DealerNotification from './Pages/DealerNotification';
import DealerComposeMail from './Pages/DealerComposeMail';
import DealerMailTemplate from './Pages/DealerMailTemplate';
import DealerRegionalSetting from './Pages/DealerRegionalSetting';
import DealerChangePassword from './Pages/DealerChangePassword';
import DealerAutoResponse from './Pages/DealerAutoResponse';
import DealerEmailSign from './Pages/DealerEmailSign';
import DealerContactsForm from './Pages/DealerContactsForm';
import DealerAddAccount from './Pages/DealerAddAccount';
import DealerUnreadMsg from './Pages/DealerUnreadMsg';
import DealerDeleteFolder from './Pages/DealerDeleteFolder';
import DealerBlockedEmail from './Pages/DealerBlockedEmail';
import DealerSpamRules from './Pages/DealerSpamRules';
import DealerMailboxFil from './Pages/DealerMailboxFil';
import DealerMailBlacklist from './Pages/DealerMailBlacklist';

const DealerMail = () => {
  const isExpanded = useOutletContext();

  const [activeSideItem, setActiveSideItem] = useState('Account Settings')
  const [activeSetting, setActiveSetting] = useState('Filtering')
  const [startDate, setStartDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);
  const [isSpamOpen, setIsSpamOpen] = useState(false);


  useEffect(() => {
    setActiveSetting('')
  }, [activeSideItem])


  const handleActivePage = (item, e) => {
    e.preventDefault();
    e.stopPropagation();

    console.log("Active Page: ", item);
    setActiveSideItem(item)
  }

  const handleActiveSettings = (item) => {
    setActiveSetting(item)
  }

  const handleToggle = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      setActiveSideItem('Folders')
    }
  };

  const handleToggleSpam = () => {
    setIsSpamOpen(!isSpamOpen);
    // if (!isSpamOpen) {
    //   setActiveSetting('Spam');
    // }
  };

  // console.log("activeSetting", activeSetting);

  return (
    <Container fluid className={`${styles.wrapper} ${isExpanded ? styles.content_expanded : styles.content_collapsed
      }  `}>
      <Row className={` ${styles.mailNavbar}`}>
        <Col sm={12} className='px-3'>
          <Row className='px-3 d-flex align-items-center justify-content-evenly'>
            <Col sm={5} className={` d-flex justify-content-evenly py-4`}>
              <div className={`${styles.navIcon} ${activeSideItem === 'Inbox' || activeSideItem === 'Mail templete' ? styles.active : ''} `} onClick={(e) => handleActivePage('Inbox', e)} >
                <MdOutlineContactMail />
                <span className={` text-nowrap text-center`}>Mails</span>
              </div>
              <div className={`${styles.navIcon} ${activeSideItem === 'Contacts' || activeSideItem === 'Contact Form' ? styles.active : ''} `} onClick={(e) => handleActivePage('Contacts', e)}>
                <PiBookOpenTextDuotone />
                <span className={` text-nowrap text-center`}>Contacts</span>
              </div>
              <div className={`${styles.navIcon} ${activeSideItem === 'Notes' ? styles.active : ''} `} onClick={(e) => handleActivePage('Notes', e)}>
                <MdOutlineSpeakerNotes />
                <span className={` text-nowrap text-center`}>Notes</span>
              </div>
              <div className={`${styles.navIcon} ${activeSideItem === 'Calendar' ? styles.active : ''} `} onClick={(e) => handleActivePage('Calendar', e)}>
                <FaRegCalendarAlt />
                <span className={` text-nowrap text-center`}>Calendar</span>
              </div>
              <div className={`${styles.navIcon} ${activeSideItem === 'Account Settings' ? styles.active : ''}  mx-3`} onClick={(e) => handleActivePage('Account Settings', e)}>
                <FiSettings />
                <span className={` text-nowrap text-center`}>Account Settings</span>
              </div>
            </Col>
            <Col className='d-flex justify-content-center'>
              <div className={`${styles.searchBar}`}>
                <div className={`${styles.navIcon}`} onClick={(e) => handleActivePage('Inbox', e)}><IoSearch /></div>
                <input type="search" placeholder='Search...' name="" id="" className={`${styles.search} `} />
              </div>
            </Col>
            <Col className={` d-flex justify-content-evenly align-items-center py-4`}>
              <div className={`${styles.navIcon} ${activeSideItem === 'Unread' ? styles.active : ''}`} onClick={(e) => handleActivePage('Unread', e)}><IoMdMailUnread /></div>
              <div className={`${styles.navIcon} ${activeSideItem === 'Notification' ? styles.active : ''}`} onClick={(e) => handleActivePage('Notification', e)}><BsBellFill /></div>
              <div className={` ${styles.pro}`}>
                <div style={{ width: '3px', border: '3px solid #7ad90e', borderRadius: '50%', position: 'absolute', left: '23px' }}></div>
                <img src={Image.user_icon} alt="" />
                <span className={` px-2`}>Priyanka</span>
              </div>
            </Col>
          </Row>
        </Col>
      </Row >

      <Row style={{ position: 'relative', border: '1px solid lightgray' }}>
        {activeSideItem !== 'Calendar' &&
          activeSideItem !== 'Notes' &&
          activeSideItem !== 'Contacts' &&
          activeSideItem !== 'Contact Form' &&
          (<>
            <Col sm={3} className={`border-end ${styles.sidebar}`}>
              <div className={` ${styles.emailPro}`}>
                <select>
                  <option>priyanka12@gmail.com</option>
                </select>
              </div>

              {activeSideItem !== 'Calendar' && activeSideItem !== 'Account Settings' ?
                <>
                  <div className={` ${styles.compose}`} onClick={(e) => handleActivePage('Compose', e)}>
                    <Button  >Compose</Button></div>
                  <ul className={` ${styles.mailCategory}`}>

                    <li className={` ${activeSideItem === 'Inbox' || activeSideItem === 'Mail templete' ? styles.active : ''}`}
                      onClick={(e) => handleActivePage('Inbox', e)} >
                      <span className={` ${styles.catIcon}`}><MdEmail /></span> Inbox
                      <span className={` ${styles.unread}`}>6</span>
                    </li>
                    <li className={` ${activeSideItem === 'Junk E-Mail' ? styles.active : ''}`}
                      onClick={(e) => handleActivePage('Junk E-Mail', e)}>
                      <span className={` ${styles.catIcon}`}><BsBugFill /></span> Junk E-Mail
                    </li>
                    <li className={` ${activeSideItem === 'Outbox' ? styles.active : ''}`}
                      onClick={(e) => handleActivePage('Outbox', e)}>
                      <span className={` ${styles.catIcon}`}><MdOutbox /></span> Outbox
                    </li>
                    <li className={` ${activeSideItem === 'Send Items' ? styles.active : ''}`}
                      onClick={(e) => handleActivePage('Send Items', e)}>
                      <span className={` ${styles.catIcon}`}><MdMarkEmailRead /></span> Send Items
                    </li>
                    <li className={` ${activeSideItem === 'Draft' ? styles.active : ''}`}
                      onClick={(e) => handleActivePage('Draft', e)}>
                      <span className={` ${styles.catIcon}`}><IoMdMailOpen /></span> Draft
                    </li>
                    <li className={` ${activeSideItem === 'Deleted Items' ? styles.active : ''}`}
                      onClick={(e) => handleActivePage('Deleted Items', e)}>
                      <span className={` ${styles.catIcon}`}><MdDelete /></span> Deleted Items
                    </li>

                    <li className={`${styles.folder} ${isOpen ? styles.open : ''} ${activeSideItem === 'Folders' ? styles.active : ''} `}
                      onClick={() => handleToggle()}>
                      <span className={` ${styles.catIcon}`}><RiFoldersFill /> Folders</span>
                      <ul className={` ${styles.innerList}`}>
                        <li onClick={(e) => handleActivePage('Create Folders', e)} className={`ps-3 ${activeSideItem === 'Create Folders' ? styles.active : ''}`}>Create Folders</li>
                        <li onClick={(e) => handleActivePage('Delete Folders', e)} className={`ps-3 ${activeSideItem === 'Delete Folders' ? styles.active : ''}`}>Delete Folders</li>
                      </ul>
                    </li>
                    <li className={` ${activeSideItem === 'Stars' ? styles.active : ''}`}
                      onClick={(e) => handleActivePage('Stars', e)}>
                      <span className={` ${styles.catIcon}`}><FaStar /></span> Stars
                    </li>

                  </ul>
                </>
                :
                <>
                  {activeSideItem !== 'Calendar' ?
                    <ul className={` ${styles.settingsCat}`}>

                      <li className={` ${activeSetting === 'General' ? styles.active : ''}`}
                        onClick={() => handleActiveSettings('General')} > General Settings
                      </li>

                      <li className={` ${activeSetting === 'Add' ? styles.active : ''}`}
                        onClick={() => handleActiveSettings('Add')} > Add Account
                      </li>

                      <li className={` ${activeSetting === 'Regional' ? styles.active : ''}`}
                        onClick={() => handleActiveSettings('Regional')} > Regional Settings
                      </li>

                      <li className={` ${activeSetting === 'Password' ? styles.active : ''}`}
                        onClick={() => handleActiveSettings('Password')} > Change Password
                      </li>

                      <li className={` ${activeSetting === 'Response' ? styles.active : ''}`}
                        onClick={() => handleActiveSettings('Response')} > Auto Response
                      </li>

                      <li className={` ${activeSetting === 'Signature' ? styles.active : ''}`}
                        onClick={() => handleActiveSettings('Signature')} > Email Signature
                      </li>

                      <li className={` ${activeSetting === 'Blocked' ? styles.active : ''}`}
                        onClick={() => handleActiveSettings('Blocked')} >Blocked Email
                      </li>

                      <li className={`${styles.folder} ${isSpamOpen ? styles.spamOpen : ''} ${activeSetting === 'Spam' ? styles.active : ''} `} onClick={() => handleToggleSpam()}>
                        <span>Spam</span>
                        <ul className={` ${styles.innerList}`}>
                          <li onClick={(e) => handleActiveSettings('Rules', e)} className={` ${activeSetting === 'Rules' ? styles.active : ''}`}>Spam Rules</li>

                          <li onClick={(e) => handleActiveSettings('Filtering', e)} className={` ${activeSetting === 'Filtering' ? styles.active : ''}`}>Mailbox Filtering</li>

                          <li onClick={(e) => handleActiveSettings('Blacklist', e)} className={` ${activeSetting === 'Blacklist' ? styles.active : ''}`}>Blacklist</li>
                        </ul>
                      </li>

                    </ul>
                    : <div></div>
                  }
                </>
              }

              {/* {activeSideItem === 'Calendar' &&
                (<>
                  <div className="custom-calendar">
                    <Calendar
                      localizer={localizer}
                      // events={events}
                      defaultView="month"
                      views={['month', 'week', 'day']}
                      components={{
                        toolbar: CustomToolbar,
                        day: CustomDayView,
                      }}
                      style={{ height: 600 }}
                    />
                  </div>

                  <div className={` ${styCal.eventCon}`}>
                    <div className={` ${styCal.eventHead}`}><FaSearch /> <span>All Calendar Items</span></div>
                    <ul className={` ${styCal.eventList}`}>
                      <li className={` ${styCal.event}`}>Meeting with Mr. Emran</li>
                      <li className={` ${styCal.event}`}>Appointment Remainder</li>
                      <li className={` ${styCal.event}`}>Meeting with Mr. Emran</li>
                    </ul>
                  </div>
                </>)
              } */}
            </Col>
          </>)}
        <Col sm={9} className='px-0'
        // style={{ position: 'relative' }}
        >
          {activeSideItem && activeSideItem === 'Inbox' ? <DealerInbox handleActivePage={handleActivePage} /> : null}
          {activeSideItem && activeSideItem === 'Junk E-Mail' ? <DealerJunk handleActivePage={handleActivePage} /> : null}
          {activeSideItem && activeSideItem === 'Outbox' ? <DealerOutbox handleActivePage={handleActivePage} /> : null}
          {activeSideItem && activeSideItem === 'Send Items' ? <DealerSend handleActivePage={handleActivePage} /> : null}
          {activeSideItem && activeSideItem === 'Draft' ? <DealerMailDraft handleActivePage={handleActivePage} /> : null}
          {activeSideItem && activeSideItem === 'Deleted Items' ? <DealerDelete handleActivePage={handleActivePage} /> : null}
          {activeSideItem && activeSideItem === 'Create Folders' ? <DealerFolders handleActivePage={handleActivePage} /> : null}
          {activeSideItem && activeSideItem === 'Delete Folders' ? <DealerDeleteFolder handleActivePage={handleActivePage} /> : null}
          {activeSideItem && activeSideItem === 'Stars' ? <DealerStars handleActivePage={handleActivePage} /> : null}

          {activeSideItem && activeSideItem === 'Mails' ? <DealerMails /> : null}

          {activeSideItem && activeSideItem === 'Notification' ? <DealerNotification /> : null}
          {activeSideItem && activeSideItem === 'Unread' ? <DealerUnreadMsg /> : null}

          {activeSideItem && activeSideItem === 'Compose' ? <DealerComposeMail /> : null}
          {activeSideItem && activeSideItem === 'Mail templete' ? <DealerMailTemplate /> : null}

          {activeSetting && activeSetting === 'General' ? <DealerSetting /> : null}
          {activeSetting && activeSetting === 'Add' ? <DealerAddAccount /> : null}
          {activeSetting && activeSetting === 'Regional' ? <DealerRegionalSetting /> : null}
          {activeSetting && activeSetting === 'Password' ? <DealerChangePassword /> : null}
          {activeSetting && activeSetting === 'Response' ? <DealerAutoResponse /> : null}
          {activeSetting && activeSetting === 'Signature' ? <DealerEmailSign /> : null}
          {activeSetting && activeSetting === 'Blocked' ? <DealerBlockedEmail /> : null}
          {activeSetting && activeSetting === 'Rules' ? <DealerSpamRules /> : null}
          {activeSetting && activeSetting === 'Filtering' ? <DealerMailboxFil /> : null}
          {activeSetting && activeSetting === 'Blacklist' ? <DealerMailBlacklist /> : null}
        </Col>

        {activeSideItem && activeSideItem === 'Contacts' ? <DealerContacts handleActivePage={handleActivePage} /> : null}
        {activeSideItem && activeSideItem === 'Contact Form' ? <DealerContactsForm /> : null}
        {activeSideItem && activeSideItem === 'Notes' ? <DealerNotes /> : null}
        {activeSideItem && activeSideItem === 'Calendar' ? <DealerCalendar /> : null}

      </Row>
    </Container >
  )
}

export default DealerMail
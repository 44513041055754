import React, { useState, useEffect, useRef, useCallback } from "react";
import styled from "styled-components";
import { filteredImage } from "./FilteredImage";
import { useNavigate } from "react-router-dom";

const SliderContainer = styled.div`
  width: 100vw;
  height: 50vh;
  position: relative;
  // background-color: #f0f0f0;
  overflow: hidden;
  padding: 5rem;
`;

const Logo = styled.div`
  width: 80px;
  height: 80px;
  position: absolute;
  transition: transform 0.3s ease;
  cursor: pointer;
  left: ${(props) => props.x}px;
  top: ${(props) => props.y}px;

  &:hover {
    transform: scale(1.5);
    z-index: 10;
  }
`;

const LogoContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap : 0.5rem;
  // border : 2px solid red;

  img {
    width: 100%;
    height: 90%;
    // object-fit: contain;
    pointer-events: none;
  }

  span {
    position: absolute;
    bottom: -20%;
    left: 50%;
    transform: translateX(-50%);
    // background-color: rgba(0, 0, 0, 0.5);
    color: black;
    padding: 2px 5px;
    border-radius: 5px;
    font-size: 15px;
    font-weight  : 600;
  }
`;

const MainMarquee1 = () => {
  const navigate = useNavigate();
  const [logos, setLogos] = useState([]);
  const [carCounts, setCarCounts] = useState({});
  const [isDragging, setIsDragging] = useState(false);
  const sliderRef = useRef(null);
  const dragItem = useRef(null);
  const dragStart = useRef(null);
  const [mouseDownTime, setMouseDownTime] = useState(0);
  const [mouseUpTime, setMouseUpTime] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const formData = new FormData();
        formData.append("user_id", 0);
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/listing/get_makes_count`,
          {
            method: "POST",
            body: formData,
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();
        const counts = data.reduce((acc, item) => {
          const makeKey = item.make.toLowerCase().replace(/-/g, "");
          if (filteredImage.hasOwnProperty(makeKey)) {
            acc[makeKey] = item.count;
          }
          return acc;
        }, {});
        setCarCounts(counts);
      } catch (error) {
        console.log("Fail", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const containerWidth = sliderRef.current.clientWidth;
    const containerHeight = sliderRef.current.clientHeight;
    const logoWidth = 100;
    const logoHeight = 100;
    const logos = Object.keys(filteredImage);
    const totalLogos = logos.length;
  
    // Set the number of rows to 3
    const rows = 3;
    // Calculate the number of columns for the first row
    const firstRowColumns = Math.floor((containerWidth + logoWidth / 2) / (logoWidth * 1.5));
    
    // Calculate the horizontal gap for even distribution
    const horizontalGap = (containerWidth - firstRowColumns * logoWidth) / (firstRowColumns + 1);
    
    // Set a fixed vertical gap
    const verticalGap = 30; // Adjust this value as needed
  
    // Calculate the starting Y position to position rows in the upper part
    const startY = containerHeight * 0.1; // 10% from the top
  
    const newLogos = [];
    let currentIndex = 0;
  
    for (let row = 0; row < rows; row++) {
      const columnsInThisRow = row % 2 === 0 ? firstRowColumns : firstRowColumns - 1;
      const xOffset = row % 2 === 1 ? horizontalGap + logoWidth / 2 : 0;
  
      for (let col = 0; col < columnsInThisRow && currentIndex < totalLogos; col++) {
        const x = xOffset + (col + 1) * horizontalGap + col * logoWidth;
        const y = startY + row * (logoHeight + verticalGap);
  
        newLogos.push({
          id: currentIndex,
          url: filteredImage[logos[currentIndex]],
          x,
          y,
          count: carCounts[logos[currentIndex]] || 0,
        });
  
        currentIndex++;
      }
    }
  
    setLogos(newLogos);
  }, [carCounts]);
  const onMouseDown = (e, id) => {
    if (e.button !== 0) return;
    setMouseDownTime(Date.now());
    dragItem.current = id;
    dragStart.current = { x: e.clientX, y: e.clientY };
    setIsDragging(true);
    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onMouseUp);
  };

  const onMouseMove = (e) => {
    if (dragItem.current !== null) {
      const dx = e.clientX - dragStart.current.x;
      const dy = e.clientY - dragStart.current.y;

      setLogos((prevLogos) =>
        prevLogos.map((logo) => {
          if (logo.id === dragItem.current) {
            let newX = logo.x + dx;
            let newY = logo.y + dy;

            newX = Math.max(
              0,
              Math.min(newX, sliderRef.current.clientWidth - 80)
            );
            newY = Math.max(
              0,
              Math.min(newY, sliderRef.current.clientHeight - 80)
            );

            return { ...logo, x: newX, y: newY };
          }
          return logo;
        })
      );

      dragStart.current = { x: e.clientX, y: e.clientY };
    }
  };

  const onMouseUp = () => {
    setMouseUpTime(Date.now());
    dragItem.current = null;
    setIsDragging(false);
    window.removeEventListener("mousemove", onMouseMove);
    window.removeEventListener("mouseup", onMouseUp);
  };



  return (
    <SliderContainer ref={sliderRef}>
      {logos.map((logo) => (
        <Logo
          key={logo.id}
          x={logo.x}
          y={logo.y}
          onMouseDown={(e) => onMouseDown(e, logo.id)}
        >
          <LogoContent
            
          >
            <img src={logo.url} alt={`Car logo ${logo.id + 1}`} />
            <span style={{marginTop : "0.4rem"}}>{logo.count}</span>
          </LogoContent>
        </Logo>
      ))}
    </SliderContainer>
  );
};

export default MainMarquee1;
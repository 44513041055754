import React, { useState } from "react";
import Styles from "../Styles/NotesForm.module.css";

const NotesForm = () => {
  const [inputValue, setInputValue] = useState("");

  
  return (
    <div className={Styles.FormWrapper}>
      <div className={Styles.FormContainer}>
        <span className={Styles.head}>Notes</span>
        <div className={Styles.noteInfos}>
          <p className={Styles.infoTag}>Tag</p>
          <input type="text" />
          <div className={Styles.readers}>
            <span>Emran</span>
            <span>Niraj</span>
            <span>Sumit</span>
            <span>Afjal</span>
          </div>
          <div className={Styles.colors}>
            <p style={{ backgroundColor: "red" }}></p>
            <p style={{ backgroundColor: "green" }}></p>
            <p style={{ backgroundColor: "blue" }}></p>
          </div>
        </div>
        {/* Subject line */}
        <div className={Styles.subjectInfo}>
          <p className={Styles.subjectTag}>Subject</p>
          <input type="text" />
        </div>
        <div className={Styles.textareaContainer}>
          <div className={Styles.input_field_container}>
            <textarea
              className={Styles.input_field}
              value={inputValue}
              onChange={(event) => setInputValue(event.target.value)}
              placeholder="Enter your text here..."
              rows={6}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotesForm;

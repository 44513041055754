import React, { useEffect, useState } from "react";
import styles from "./MobileHeroSection.module.css";
import { TbFilterSearch } from "react-icons/tb";
import { IoSearchSharp } from "react-icons/io5";
import { useSelector } from "react-redux";
import axios from "axios";
import { MdMyLocation } from "react-icons/md";
import { DealerGeoAPI } from "../DealerSection/DealerBusinessInformation/DealerGeoAPI";
import { useNavigate } from "react-router-dom";
import Image from "../imgData";

const MobileHeroSection = () => {
  const { roles, dealerId } = useSelector((state) => state.user);
  const [filters, setFilters] = useState({
    make: [],
    models: [],
    year: [],
    engineType: [],
    fuelType: [],
    vehicleType: [],
    owner: [],
    state: [],
    interiorColor: [],
    exteriorColor: [],
    interiorType: [],
    doorCounts: [],
    driveTrain: [],
    odometer: [],
    price: [],
    exterior_damage: [],
    photos: [],
    seller: roles === "Dealer" ? [dealerId] : [],

    zip_code: [],
  });
  const [responseFilter, setResponseFilter] = useState({});
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/listing/filters`,
          filters
        );
        // console.log("response filters from the API", response.data.data);
        setResponseFilter(response.data);
      } catch (error) {
        // console.log("Server side while fetching data during getting filters");
      }
    };
    fetchProducts();
  }, []);
  const handleFiltersChange = (e) => {
    const { name, value } = e.target;

    setFilters((prevFilters) => {
      if (name === "yearFrom" || name === "yearTo") {
        const yearIndex = name === "yearFrom" ? 0 : 1;
        const newYear = [...prevFilters.year];
        newYear[yearIndex] = value;

        // Remove empty values and sort
        const sortedYear = newYear.filter(Boolean).sort((a, b) => a - b);

        return {
          ...prevFilters,
          year: sortedYear,
        };
      } else if (name === "odometer") {
        return {
          ...prevFilters,
          odometer: ["0", value], // Set 0 as lower bound and selected value as upper bound
        };
      } else {
        return {
          ...prevFilters,
          [name]: [value],
        };
      }
    });
  };
  const handleChangeZipCode = (e) => {
    setFilters((prevData) => ({
      ...prevData,
      zip_code: [e.target.value],
    }));
  };
  const handleLocation = async () => {
    try {
      const res = await DealerGeoAPI();
      console.log(res);
      setFilters((prevData) => ({
        ...prevData,
        zip_code: [res.zipCode], // Updating zip_code in state
      }));
    } catch (error) {
      console.log("API Side Error");
    }
  };
  const userData = useSelector((state) => state.user);
  const navigate = useNavigate();
  const handleNavigate = () => {
    if (
      userData &&
      userData.roles === "Dealer" &&
      userData.firstName &&
      userData.lastName
    ) {
      // Update filters state with seller info
      setFilters((prevData) => {
        const updatedFilters = {
          ...prevData,
          seller: [userData.id],
        };

        // Navigate after the state update
        navigate("/product", { state: updatedFilters });
        return updatedFilters;
      });
    } else {
      // Navigate with current filters if user is not a dealer or other conditions are not met
      navigate("/product", { state: filters });
    }
  };
  const [showAllFilters, setShowAllFilters] = useState(false);
  const toggleFilterVisibility = () => {
    setShowAllFilters((prev) => !prev);
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.filterContainer}>
        <p className={styles.filterText}>
          <span>
            <IoSearchSharp />
          </span>
          <input type="text" placeholder="Search Your Inventory" />
        </p>
        <p className={styles.filterBtn} onClick={toggleFilterVisibility}>
          <TbFilterSearch />
        </p>
      </div>
      {/* Filter Items */}
      <div
        // className={`${styles.filter_items} ${showAllFilters ? "d-block" : "d-none"}`}
        style={{
          display: showAllFilters ? "block" : "none",
          position: "absolute",
          top: "120px",
          left: "0px",
          zIndex: "199",
          backgroundColor: "white",
          width: "100%",
          padding: "10px",
        }}
      >
        <div className="d-flex flex-column gap-3 justify-content-center ">
          <div className="col-sm-12 col-md-4 col-lg-2 d-flex flex-column  justify-content-center align-items-center">
            <select
              className="form-select select-filter box_shadow_filter_btn select_parent_tag"
              name="make"
              value={filters.make}
              onChange={handleFiltersChange}
              style={{
                paddingTop: "9px",
                paddingBottom: "9px",
                width: "82%",
                fontWeight: "700",
                fontSize: "larger",
              }}
            >
              <option value="" hidden>
                Make
              </option>
              {responseFilter &&
                responseFilter?.makes?.map((makeItem, i) => (
                  <option value={makeItem.make} key={i}>
                    {makeItem.make}
                  </option>
                ))}
            </select>
          </div>
          <div className="col-sm-12 col-md-4 col-lg-2 d-flex flex-column  justify-content-center align-items-center">
            <select
              className="form-select select-filter box_shadow_filter_btn"
              name="models"
              value={filters.models}
              onChange={handleFiltersChange}
              style={{
                paddingTop: "9px",
                paddingBottom: "9px",
                width: "82%",
                fontWeight: "700",
                fontSize: "larger",
              }}
            >
              <option value="" hidden>
                Model
              </option>
              {responseFilter &&
                responseFilter?.models?.map((model, i) => (
                  <option value={model} key={i}>
                    {model}
                  </option>
                ))}
            </select>
          </div>
          <div className="col-sm-12 col-md-4 col-lg-2 d-flex flex-column  justify-content-center align-items-center">
            <select
              className="form-select select-filter box_shadow_filter_btn"
              name="odometer"
              value={filters.odometer[1] || ""}
              onChange={handleFiltersChange}
              style={{
                paddingTop: "9px",
                paddingBottom: "9px",
                width: "82%",
                fontWeight: "700",
                fontSize: "larger",
              }}
            >
              <option value="" hidden>
                Odometer
              </option>
              <option value="500">500 miles</option>
              <option value="10000">10k miles</option>
              <option value="50000">50k miles</option>
              <option value="100000">100k miles</option>
              <option value="150000">150k miles</option>
              <option value="200000">200k miles</option>
              <option value="1000000">200k + miles</option>
            </select>
          </div>
          <div className="col-sm-12 col-md-4 col-lg-3 zipCode_container d-flex flex-column  justify-content-center align-items-center">
            <input
              type="text"
              placeholder="Enter Zip Code"
              style={{
                padding: "9px",
                width: "82%",
                fontWeight: "700",
                fontSize: "larger",
              }}
              value={filters.zip_code}
              onChange={handleChangeZipCode}
              className="form-select select-filter box_shadow_filter_btn"
            />
            <span onClick={handleLocation}>
              <MdMyLocation />
            </span>
          </div>
          <div
            className={`col-sm-12 col-md-4 col-lg-3 zipCode_container d-flex flex-column  justify-content-center align-items-center ${styles.searchBtn}`}
          >
            <button
              type="button"
              onClick={handleNavigate}
              // style={{ width: "80%" }}
            >
              Search Inventory
            </button>
          </div>
        </div>
      </div>
      {/* Hero Image Section */}
      <div className=" position-relative" style={{ paddingTop: "2rem" }}>
        <p className={styles.heroPartText}>
          {" "}
          Get Your First <span style={{ color: "#e8a262" }}>Deal</span>.
        </p>
        <p>
          <img
            src={Image.mobileHeroSectionImage}
            alt="hero car image"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </p>
      </div>
    </div>
  );
};

export default MobileHeroSection;

import React, { useEffect, useState } from "react";
import Styles from "../Styles/Box_D.module.css";
import InputFieldComp from "./InputFieldComp";
import { useDispatch, useSelector } from "react-redux";
import { setRetailData } from "../../../../Redux/slice/taxes&FeesSlice";
// Function to format numbers with commas
const formatNumberWithCommas = (number) => {
  return new Intl.NumberFormat().format(number);
};
const Box_E = ({ retailFromRedux }) => {
  const cashDifference = useSelector(
    (state) => state.taxesAndFees.dealerRetail.cashDifference
  );
  const userListingPrice = useSelector((state) =>
    parseFloat(state.taxesAndFees.dealerRetail.userListingPrice)
  );
  const totalFees = useSelector((state) =>
    parseFloat(state.taxesAndFees.dealerRetail.totalFees)
  );
  const totalFeesAndothers = useSelector((state) =>
    parseFloat(state.taxesAndFees.dealerRetail.totalFeesAndothers)
  );
  const totalDue = useSelector(
    (state) => parseFloat(state.taxesAndFees.dealerRetail.totalDue) || 0
  );
  const paymentPriceFees = useSelector(
    (state) => parseFloat(state.taxesAndFees.dealerRetail.paymentPriceFees) || 0
  );
  const balanceDue = totalDue - paymentPriceFees;
  const totalDueSum = cashDifference + totalFees + totalFeesAndothers;
  const dispatch = useDispatch();
  const initialValues = [
    // { header: "Total Due", InputValue: cashDifference, name: "cashDifference" },
    {
      header: "Payment",
      InputValue: paymentPriceFees,
      name: "paymentPriceFees",
    },
  ];

  useEffect(() => {
    setInputValues((prevInputValues) =>
      prevInputValues.map((item) =>
        item.name === "paymentPriceFees"
          ? { ...item, InputValue: paymentPriceFees }
          : item
      )
    );
  }, [paymentPriceFees]);
  useEffect(() => {
    setInputValues((prevInputValues) =>
      prevInputValues.map((item) =>
        item.name === "cashDifference"
          ? { ...item, InputValue: cashDifference }
          : item
      )
    );
  }, [cashDifference]);
  const [inputValues, setInputValues] = useState(initialValues);
  const handleInputChange = (name, value) => {
    const updatedFields = inputValues.map((field) =>
      field.name === name ? { ...field, InputValue: value } : field
    );
    setInputValues(updatedFields);

    // Dispatch the updated value to the Redux store
    dispatch(setRetailData({ ...retailFromRedux, [name]: value }));
  };
  const [paymentMethod, setPaymentMethod] = useState("");

  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };
  console.log("line53", paymentMethod);
  useEffect(() => {
    dispatch(
      setRetailData({
        priceFeesPaymentMethod: paymentMethod,
      })
    );
  }, [paymentMethod]);
  useEffect(() => {
    dispatch(
      setRetailData({
        balanceDue: balanceDue ? balanceDue.toFixed(2) : 0,
      })
    );
  }, [balanceDue]);
  useEffect(() => {
    dispatch(
      setRetailData({
        totalDue: totalDueSum,
      })
    );
  }, [totalDueSum]);
  return (
    <div
      className={Styles.wrapper}
      style={{ borderRight: "0px", borderLeft: "0px" }}
    >
      <div className={Styles.container}>
        <div className={Styles.inputFieldContainer}>
          <span
            style={{ fontWeight: "600", fontSize: "x-large", flex: "0.93" }}
          >
            TOTAL DUE :{" "}
          </span>
          <p className={Styles.dueData} style={{ marginBottom: "4px" }}>
            {formatNumberWithCommas(totalDue)}
          </p>
        </div>
        <div className={Styles.inputFieldContainer_payment}>
          <span
            style={{
              fontWeight: "500",
              fontSize: "large",
              paddingTop: "0.5rem",
            
            }}
            className={Styles.paymentMethodHeader}
          >
            Payment Method :{" "}
          </span>
          <select
            className={Styles.payment_method}
            value={paymentMethod}
            onChange={handlePaymentMethodChange}
          >
            <option value="">Payment Method</option>
            <option value="Cash">Cash</option>
            <option value="Cheque">Cheque</option>
            <option value="Wire">Wire</option>
            <option value="Card">Card</option>
            <option value="Trade">Trade</option>
          </select>
        </div>
        {inputValues.map((item) => (
          <InputFieldComp
            item={item}
            key={item.header}
            header={item.header}
            InputValue={item.InputValue}
            name={item.name}
            handleInputChange={handleInputChange}
          />
        ))}
        <div
          className={Styles.inputFieldContainer}
          style={{ marginTop: "0px", height: "40px" }}
        >
          <span style={{ fontWeight: "600", fontSize: "x-large"  , flex : "0.94"}}>
            Balance Due:{" "}
          </span>
          <p className={Styles.dueData} style={{ backgroundColor: "pink" }}>
            {formatNumberWithCommas(balanceDue.toFixed(2))}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Box_E;

import React, { useState } from 'react'
import {
  Tabs,
  Tab,
  Container,
  Row,
  Col,
  Button,
  Form,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import "../../Tabs.css";
const DealerLienHolders = () => {
  // for tabs-----------------------------
  const [activeTab, setActiveTab] = useState("tab1");
  const handleNextButtonClick = () => {
    const tabKeys = [
      "tab1",
      "tab2",
      "tab3",
      "tab4",
      "tab5",
      "tab6",
      "tab7",
    ];
    const currentIndex = tabKeys.indexOf(activeTab);

    if (currentIndex < tabKeys.length - 1) {
      const nextTab = tabKeys[currentIndex + 1];
      setActiveTab(nextTab);
    }
  };

  const handleBackButtonClick = () => {
    const tabKeys = ["tab1", "tab2", "tab3", "tab4", "tab5", "tab6", "tab7"];
    const currentIndex = tabKeys.indexOf(activeTab);

    if (currentIndex > 0) {
      const prevTab = tabKeys[currentIndex - 1];
      setActiveTab(prevTab);
    }
  };
  return (
    <div><Tabs
      activeKey={activeTab}
      onSelect={(k) => setActiveTab(k)}
      id="my-tabs"
    >
      <Tab eventKey="tab1" title="Service Contract">
        Service Contract Section
      </Tab>
      <Tab eventKey="tab2" title="Gap Insurance">
        Gap Insurance Section
      </Tab>
      <Tab eventKey="tab3" title="Floor Company">
        Floor Company Section
      </Tab>
      <Tab eventKey="tab4" title="Lien Holders">
        Lien Holders Section
      </Tab>
      <Tab eventKey="tab5" title="Insurance Company">
        Insurance Company Section
      </Tab>
      <Tab eventKey="tab6" title="GPS">
        GPS Section
      </Tab>
      <Tab eventKey="tab7" title="Surety Bond">
      Surety Bond Section
      </Tab>
    </Tabs>
    </div>
  )
}

export default DealerLienHolders
import { IoIosArrowDown } from "react-icons/io";
import styles from "./Drafting.module.css";
import { useEffect, useState } from "react";
import DraftData from "./DraftData.js";
import { useSelector } from "react-redux";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { Button, Container, Modal } from "react-bootstrap";
const apiUrl = process.env.REACT_APP_API_URL;
const Drafting = () => {
  const { dealerId } = useSelector((state) => state.user);
  const [isAssetOpened, setIsAssetOpened] = useState(false);
  const [isDropDownOpened, setIsDropDownOpened] = useState(false);
  const [dropDownOptionSelect, setdropDownOptionSelect] =
    useState("Retail/Cash");
  const [draftData, setDraftData] = useState(DraftData); // Assuming QuoteData is your initial state
  //   form data -------------------------------------------------------************************************---------------------------------------------
  const [formData, setFormData] = useState({
    dealerId: dealerId,
    listingId: 0,
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    salesPrice: 0,
    tradeIn: 0,
    cashDifference: 0,
    documentationFees: 0,
    windShieldSticker: 0,
    terpTitleFeetoDMV: 0,
    titleAppFee: 0,
    regFeesDPStoTaxOffice: 0,
    cntyRoadBridgeAddonFee: 0,
    childSafetyToTaxOffice: 0,
    procHandlingFee: 0,
    metalTag: 0,
    inspectionFeeFeeToState: 0,
    inspectionFeeFeeToStation: 0,
    // calculation-------------------------------
    saleType: "Retail/Cash",
    salesTaxPerc: 6.25,
    inventoryTaxes: 0.00148,
    salesTaxes: 0,
    inventoryTaxValue: 0,
    totalTaxes: 0,
    totalFees: 0,
    total: 0,
  });
  const [taxesAndFees, setTaxesAndFees] = useState({
    salesTaxPercent: 6.25,
    salestaxvalue: 0,
    inventoryTaxPercent: 0.00148,
    inventoryTaxValue: 0,
  });
  // ---------------calculation----------------------------------------------
  const [saletaxValue, setSaleTaxValue] = useState(0);
  const [inventorytaxValue, setInventoryTaxValue] = useState(0);
  const [totalTaxesValue, setTotalTaxesValue] = useState(0);
  const [sumTotalFees, setSumTotalFees] = useState(0);
  const [totalAllvalue, setTotalAllValue] = useState(0);
  useEffect(() => {
    // Convert salesPrice to a number before calculation
    const price = parseFloat(formData.salesPrice - formData.tradeIn);

    if (!isNaN(price) && price > 0) {
      // If price is valid, calculate the sales tax
      const calculatedTax = (price * 6.25) / 100;
      const calculatedInTax = price * 0.001488;
      const calculatedTotalTax = calculatedTax + calculatedInTax;
      setSaleTaxValue(calculatedTax);
      setInventoryTaxValue(calculatedInTax);
      setTotalTaxesValue(calculatedTotalTax);
      setFormData((prevData) => ({
        ...prevData,
        salesTaxes: parseFloat(calculatedTax.toFixed(2)),
        inventoryTaxValue: parseFloat(calculatedInTax.toFixed(2)),
        totalTaxes: parseFloat(calculatedTotalTax.toFixed(2)),
        cashDifference: price.toFixed(2),
      }));
    } else {
      setSaleTaxValue(0);
      setInventoryTaxValue(0);
    }
  }, [formData.salesPrice,formData.tradeIn]);
  // --------------------------------for sum of all form data for total fees--------------------------
  // State to store the sum of numeric values
  useEffect(() => {
    // Exclude the specified keys and calculate the sum
    const excludeKeys = [
      "listingId",
      "firstName",
      "lastName",
      "phoneNumber",
      "email",
      "tradeIn",
      "cashDifference",
      "salesTaxes",
      "inventoryTaxValue",
      "salesPrice",
      "salesTaxPerc",
      "inventoryTaxes",
      "totalTaxes",
      "totalFees",
      "total",
      "saleType",
      "dealerId",
    ];

    const totalSum = Object.keys(formData)
      .filter((key) => !excludeKeys.includes(key)) // Filter out excluded keys
      .reduce((acc, key) => {
        const value = parseFloat(formData[key]); // Ensure it's a number
        return isNaN(value) ? acc : acc + value; // Add the value to the sum if it's a valid number
      }, 0);

    setSumTotalFees(totalSum); // Update the sum state
    const totalSumAndTotalTax = totalSum + totalTaxesValue;
    const totalFinal = (totalSumAndTotalTax + formData.salesPrice) - formData.tradeIn;
    setTotalAllValue(totalFinal);
    setFormData((prevData) => ({
      ...prevData,
      totalFees: parseFloat(totalSumAndTotalTax.toFixed(2)),
      total: parseFloat(totalFinal.toFixed(2)),
    }));
  }, [
    formData.documentationFees,
    formData.windShieldSticker,
    formData.terpTitleFeetoDMV,
    formData.titleAppFee,
    formData.regFeesDPStoTaxOffice,
    formData.cntyRoadBridgeAddonFee,
    formData.childSafetyToTaxOffice,
    formData.procHandlingFee,
    formData.metalTag,
    formData.inspectionFeeFeeToState,
    formData.inspectionFeeFeeToStation,
    formData.tradeIn,
    totalTaxesValue,
  ]); // Recalculate sum whenever formData changes
  // ------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    // Find the selected asset based on listingId
    if (name === "listingId") {
      const selectedAsset = assets.find(
        (asset) => asset.listingId === Number(value)
      );
      const listingPrice = selectedAsset ? selectedAsset.listingPrice : null;

      setFormData((prevData) => ({
        ...prevData,
        [name]: Number(value), // Update listingId
        salesPrice: listingPrice, // Set listingPrice separately
      }));
    } else {
      const newValue = name === "listingId" ? Number(value) : value;
      setFormData({ ...formData, [name]: newValue });
    }
  };

  const handleChangeSaleType = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  // -------------------------------********************-----Important handle Input Change------------------------------------------------
  // Event handler for input changes
  const formatPhoneNumber = (number) => {
    // Remove non-digit characters
    const digits = number.replace(/\D/g, "");

    // Format the digits into the US phone number format (XXX) XXX-XXXX
    if (digits.length <= 3) {
      return digits;
    } else if (digits.length <= 6) {
      return `(${digits.slice(0, 3)}) ${digits.slice(3)}`;
    } else {
      return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(
        6,
        10
      )}`;
    }
  };

  const handleInputChange = (key, event, isPersonalDetail) => {
    console.log("key", key);
    const newValue = event.target.value;

    // If it's the phoneNumber field, store only digits in formData
    if (key === "phoneNumber") {
      const formattedPhoneNumber = formatPhoneNumber(newValue);
      setFormData((prevData) => ({
        ...prevData,
        [key]: newValue.replace(/\D/g, ""), // Store the raw digits
      }));
      // Update the displayed value with the formatted phone number
      event.target.value = formattedPhoneNumber;
    } else {
      // Handle other input fields (strings or numbers) as per your previous logic
      if (
        isPersonalDetail ||
        key === "firstName" ||
        key === "lastName" ||
        key === "phoneNumber" ||
        key === "email"
      ) {
        if (!/^\d+$/.test(newValue)) {
          // Reject numbers for personal details
          setFormData((prevData) => ({
            ...prevData,
            [key]: newValue,
          }));
        }
      } else {
        // Allow numbers for other fields
        if (/^\d*\.?\d*$/.test(newValue)) {
          setFormData((prevData) => ({
            ...prevData,
            [key]: Number(newValue),
          }));
        }
      }
    }
  };

  // ------------------------------------------------end of input changes functionality---------------------------------------------
  const [isTradeInChecked, setIsTradeInChecked] = useState(false);
  const [assets, setAssets] = useState([]);
  const saleTypeOptions = [
    "Retail/Cash",
    "Finance/BHPH",
    "Out of State",
    "Whole Sale",
    "Rent & Lease",
  ];
  // for at the time check for trade-in----------------------------
  const handelCheckBox = () =>{
    setIsTradeInChecked(!isTradeInChecked)
    console.log("isTradeInChecked",isTradeInChecked);
  }
  // ---------------------------------------------------------
  useEffect(() => {
    const fetchAssets = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/listing/get_assets/${dealerId}`
        );
        console.log("response => ", response);
        setAssets(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchAssets();
  }, []);
  // for send the data for create pdf for fast quote---------------------------------------
  // for show the pdf______________
  const [quoteForm, setQuoteForm] = useState([]);
  const [showPdf, setShowPdf] = useState(false);
  const [loading, setLoading] = useState(false);
  console.log("quoteForm", quoteForm);
  // _________________________
  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
  const handleGenerate = async (e) => {
    console.log("handleGenerate");
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        `${apiUrl}/fastQuote/get_quote`,
        formData
      );
      setQuoteForm([response.data.file]);
      toast.success("Quote Successfully Save...");
      await delay(3000);
      // Show the modal
      setShowPdf(true);
      console.log("Response:", response.data);
    } catch (error) {
      console.error("There was an error!", error);
      toast.error(error);
      // setError("Detail already exist");
    } finally {
      setLoading(false); // Set loading to false when the request finishes
    }
  };

  //---------------------------------------------------------------------------------------
  console.log("Assets1234", assets);
  console.log("formDat123", formData);
  console.log("draftData", draftData);
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.assetSelectContainer1}>
          <span className={styles.selectItem}>Select Asset</span>
          <p className={styles.assetSelector}>
            <select
              id="listingId"
              name="listingId"
              value={formData.listingId}
              // className={Styles.input_text_field}
              style={{ fontWeight: "bolder", backgroundColor: "#FBEADF" }}
              onChange={handleChange}
            >
              <option value="">Select an Asset</option>
              {assets.map((asset) => (
                <option key={asset.listingId} value={asset.listingId}>
                  {asset.assetName}
                </option>
              ))}
            </select>
            {/* <span>Selected item here</span> */}
            {/* <span onClick={() => setIsAssetOpened(!isAssetOpened)}>
              {" "}
              <IoIosArrowDown
                style={{
                  transform: isAssetOpened ? "rotate(180deg)" : "rotate(0deg)",
                  transition: "transform 250ms ease-in",
                }}
              />
            </span> */}
          </p>
        </div>
        <div className={styles.buyerQuoteInfoParent}>
          <div className={styles.buyerQuoteInfo}>
            {DraftData.slice(0, 3).map((item) => (
              <div key={item.id} className={styles.buyerPersonalInfo}>
                <p className={styles.buyerInfoText} style={{marginBottom:"0px"}}>{item.inputLabel}</p>
                <input
                  type="text"
                  value={
                    item.key === "phoneNumber"
                      ? formatPhoneNumber(formData[item.key])
                      : formData[item.key]
                  } // Format phone number for display
                  onChange={(e) =>
                    handleInputChange(item.key, e, item.key !== "phoneNumber")
                  } // Pass the key and handle input change
                />
              </div>
            ))}
          </div>
        </div>
        <div className={styles.buyerQuoteInfoParent}>
          <div className={styles.buyerQuoteInfo}>
              <div className={styles.buyerPersonalInfo}>
                <p className={styles.buyerInfoText} style={{marginBottom:"0px"}}>Address1</p>
                <input
                  type="text"
                />
              </div>
              <div className={styles.buyerPersonalInfo}>
                <p className={styles.buyerInfoText} style={{marginBottom:"0px"}}>Zip</p>
                <input
                  type="text"
                />
              </div>
              <div className={styles.buyerPersonalInfo}>
                <p className={styles.buyerInfoText} style={{marginBottom:"0px"}}>County</p>
                <input
                  type="text"
                />
              </div>
          </div> 
        </div>
        <div className={styles.buyerQuoteInfoParent} style={{justifyContent:"center"}}>
          <div className={styles.buyerQuoteInfo} style={{justifyContent:"center",marginLeft:"25%"}}>
          {DraftData.filter(item => item.key === "phoneNumber" || item.key === "email").map((item) => (
              <div key={item.id} className={styles.buyerPersonalInfo}>
                <p className={styles.buyerInfoText} style={{marginBottom:"0px"}}>{item.inputLabel}</p>
                <input
                  type="text"
                  value={
                    item.key === "phoneNumber"
                      ? formatPhoneNumber(formData[item.key])
                      : formData[item.key]
                  } // Format phone number for display
                  onChange={(e) =>
                    handleInputChange(item.key, e, item.key !== "phoneNumber")
                  } // Pass the key and handle input change
                />
              </div>
            ))}
          </div>
        </div>
        <div className={styles.buyerQuoteInfoParent}>
          <div className={styles.buyerQuoteInfo}>
              <div className={styles.buyerPersonalInfo} style={{display:"flex",flexDirection:"row",alignItems:"center"}}>
                <p className={styles.buyerInfoText} style={{marginBottom:"0px"}}>Price :</p>
                <input
                  type="text"
                  style={{width:"75%"}}
                />
              </div>
              <div className={styles.buyer_btn_ls}>
                <button>Liean Holder</button>
                <button>Services Contract</button>
              </div>
          </div> 
        </div>
      </div>
      <div className={styles.priceFeesWrapper}>
        <div className={styles.priceFeesleftContainer}>
          {DraftData.slice(5).map((item, index) => (
            <div key={item.id}>
              {/* Render input fields for all except index 1 and 2 (initially) */}
              {index !== 1 && index !== 2 && (
                <div className={styles.priceFeesContainer}>
                  <p className={styles.priceFeesText}>{item.id}. {item.inputLabel}</p>
                  <div className={styles.inputWithPercentb}>
                  <span className={styles.percentSymbolb}>$</span>
                  <input
                    type="text"
                    value={formData[item.key]} // Bind to formData
                    onChange={(e) => handleInputChange(item.key, e, false)} // Pass key
                  />
                  </div>
                </div>
              )}

              {/* Render trade-in checkbox only for index 0 */}
              {index === 0 && (
                <div className={styles.tradeInCheckBox}>
                  <input
                    type="checkbox"
                    checked={isTradeInChecked}
                    id="tradeIn"
                    onChange={() => handelCheckBox()} // Toggle checkbox state
                  />
                  <label htmlFor="tradeIn">I have Trade In</label>
                </div>
              )}

              {/* Render input fields for index 1 and 2 only if trade-in is checked */}
              {(index === 1 || index === 2) && isTradeInChecked && (
                <div className={styles.priceFeesContainer}>
                  <p className={styles.priceFeesText}>{item.inputLabel}</p>
                  <div className={styles.inputWithPercentb}>
                  <span className={styles.percentSymbolb}>$</span>
                  <input
                    type="text"
                    value={formData[item.key]} // Bind to formData
                    onChange={(e) => handleInputChange(item.key, e, false)} // Pass key
                  />
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className={styles.priceFeesRightContainer}>
          <div className={styles.percCalWrapper}>
            <p className={styles.percCalContainer}>
              <span>13. Sales Tax</span>
              <div className={styles.perCalInput}>
                <div className={styles.inputWithPercent}>
                  <span className={styles.percentSymbol}>%</span>
                  <input
                    type="text"
                    value={taxesAndFees.salesTaxPercent}
                    readOnly
                  />
                </div>
                {/* <input type="text" value={taxesAndFees.salesTaxPercent} /> */}
                <input type="text" value={saletaxValue.toFixed(2)} />
              </div>
            </p>
            <p className={styles.percCalContainer}>
              <span>14. Inventory Tax</span>
              <div className={styles.perCalInput}>
                <div className={styles.inputWithPercent}>
                  <span className={styles.percentSymbol}>%</span>
                  <input
                    type="text"
                    value={taxesAndFees.inventoryTaxPercent}
                    readOnly
                  />
                </div>
                <input type="text" value={inventorytaxValue.toFixed(2)} />
              </div>
            </p>
          </div>
          <div className={styles.percCalWrapper}>
            <p className={styles.percCalContainer2}>
              <span>15. Total Taxes </span>
              <div className={styles.inputWithPercentc}>
                  <span className={styles.percentSymbolc}>$</span>
              <input type="text" value={totalTaxesValue.toFixed(2)} />
              </div>
            </p>
            <p className={styles.percCalContainer2}>
              <span>16. Total Fees </span>
              <div className={styles.inputWithPercentc}>
                  <span className={styles.percentSymbolc}>$</span>
              <input type="text" value={sumTotalFees.toFixed(2)} />
              </div>
            </p>
          </div>
          <div className={styles.percCalWrapper3}>
            <p className={styles.percCalContainer3}>
              <span>17. Total</span>
              <span>$ {totalAllvalue.toFixed(2)}</span>
            </p>
          </div>

          <p className={styles.generateBtnContainer}>
            {/* <button type="button" onClick={handleGenerate}>
              {loading ? <div className="spinner"></div> : "Generate"}
            </button> */}
            {/* <button type="button">
              {loading ? <div className="spinner"></div> : "Generate"}
            </button> */}
          </p>
        </div>
      </div>
      {/* <div className={styles.exportContainer}>
        <span className={styles.exportIndicator}>Export</span>
        <button>Print</button>
        <button>Email</button>
      </div> */}
      <Modal show={showPdf} onHide={() => setShowPdf(false)} size="xl">
        <Modal.Header closeButton style={{ gap: "10%" }}>
          <Modal.Title id="example-custom-modal-styling-title">
            Qoute Forms
          </Modal.Title>
          <Modal.Title style={{ display: "flex", width: "45%", gap: "5%" }}>
            <Button className="w-100 pe-0 py-2 btn-orange border-0">
              Print
            </Button>
            <Button className="w-100 pe-0 py-2 btn-orange border-0">
              E-Mail
            </Button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "80vh" }}>
          <Container>
            {quoteForm &&
              quoteForm.map((pdf, i) => {
                return (
                  <div key={i} style={{ height: "80vh" }}>
                    <iframe
                      src={`${process.env.REACT_APP_DOCS_URL}/${dealerId}/${pdf}#page=1&zoom=125`}
                      type="application/pdf"
                      frameborder="0"
                      style={{
                        objectFit: "fill",
                        width: "100%",
                        height: "75vh",
                      }}
                    ></iframe>
                  </div>
                );
              })}
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Drafting;

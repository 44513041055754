import React, { useEffect, useRef, useState } from 'react';

const Image360Viewer = ({images}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const viewerRef = useRef(null);
  // const images = [
  //   'https://l13.alamy.com/360/R5GE45/360-degree-panorama-of-a-suburb-of-a-german-industrial-city-with-detached-houses-and-skyscrapers-on-the-edge-of-the-motorway-and-with-sports-faciliti-R5GE45.jpg',
  //   'https://t3.ftcdn.net/jpg/05/21/73/82/360_F_521738291_rHNxuCh0Pxh1t6i0tY1ijuspy71qtgei.jpg',
  // ];
  
  useEffect(() => {
    if (viewerRef.current && window.pannellum) {
      window.pannellum.viewer('panorama', {
        type: 'equirectangular',
        panorama: images[currentIndex],
        autoLoad: true,
        compass: true,
        autoRotate: -2,
        crossOrigin: true,
      });
    }
  }, [currentIndex, images]);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  if (!images || images.length === 0) {
    return <div>No images available</div>;
  }

  return (
    <div className="image-360-container">
      <button className="left-arrow-large" onClick={handlePrev}>
        &#8249;
      </button>
      <div
        id="panorama"
        style={{
          width: '100%',
          height: '600px',
        }}
        ref={viewerRef}
      ></div>
      <button className="right-arrow-large" onClick={handleNext}>
        &#8250;
      </button>
    </div>
  );
};

export default Image360Viewer;

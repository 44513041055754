import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { IoIosCall, IoIosText, IoMdMail } from "react-icons/io";
import { BsFillSendCheckFill } from "react-icons/bs";
import { FaSquareShareNodes } from "react-icons/fa6";
import { IoHeartCircle } from "react-icons/io5";
import Image from '../../imgData';


const ViewHistory = () => {
    // Session Time out for 15 minutes-------------------------------------
  const navigate = useNavigate();

   // Session Time out for 15 minutes End-------------------------------------
    console.log(Image.key)
    return (
        <>
            <section className="container">
                <div className='row'>
                    <div className="col-12">
                        <header className="d-sm-flex align-items-center my-4">
                            <div className="ms-auto">
                                <select className="form-select d-inline-block w-auto border pt-1 me-2">
                                    <option value="0">Select</option>
                                    <option value="0">10</option>
                                    <option value="1">20</option>
                                    <option value="2">30</option>
                                    <option value="3">50</option>
                                </select>

                                <select className="form-select d-inline-block w-auto border pt-1 me-2 ">
                                    <option value="0 " className='text-center'>Sort By</option>
                                    <option value="0">Bought (Low to High)</option>
                                    <option value="1">Bought (High to Low)</option>
                                    <option value="2">Sold (Low to High)</option>
                                    <option value="3">Sold (High to Low)</option>
                                </select>
                            </div>

                        </header>
                    </div>
                </div>
                <div className="row shadow-sm p-3  bg-body rounded border border-dark">
                    <div className="col-lg-4 col-md-5 ">
                        <div className="product-image-box">
                            <div style={{ position: "relative", textAlign: "center" }}>
                                <img src="https://images.saymedia-content.com/.image/ar_16:9%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:eco%2Cw_1200/MjAxNzE0OTk4NTA5MDUzNzE2/how-to-buy-cars-cheaply-and-sell-them-at-Link-profit.jpg" className="w-100" alt="..." />
                                <div className="top-right" style={{ position: "absolute", top: "8px", right: "16px", display: "flex", flexDirection: "column" }}>
                                    <FaSquareShareNodes style={{ fontSize: "30px", fill: "white" }} />
                                    <IoHeartCircle style={{ fontSize: "30px", fill: "white" }} />
                                </div>
                            </div>
                            <div className="product-image-content">
                                <div className="d-flex justify-content-center mt-3">
                                    <p className='me-2'>Exterior</p>
                                    <p >Interior</p>
                                </div>
                                <div className="color-variant mx-auto ">
                                    {/* <p><button data-tooltip="I’m the tooltip text.">I’m a button with a tooltip</button></p> */}
                                    <Link
                                        to="#" data-tooltip="Exterior"
                                        style={{
                                            // color: 'red',
                                            padding: '10px',
                                            backgroundColor: 'yellow',
                                            borderRadius: '50%',
                                            height: 'auto',
                                            display: 'block',
                                            // width: '1%',
                                        }}
                                    ></Link>
                                    <Link
                                        to="#" data-tooltip="Interior"
                                        style={{
                                            // color: 'blue',
                                            padding: '10px',
                                            backgroundColor: 'red',
                                            borderRadius: '50%',
                                            height: 'auto',
                                            display: 'block',
                                            // width: '1%',
                                        }}
                                    ></Link>
                                </div>
                                <p className="text-center">Contact the seller Now</p>
                                <div className="product-message">Hi Kumar, is this still available ? ....</div>
                                <button className="product-send-btn me-5">Send</button>
                            </div>
                        </div>
                    </div>
                    {/*----------------------------------------side Image End--------------------------------------------------*/}
                    <div className="col-lg-6 col-md-7">
                        {/*----------------------content deatils Start---------------------*/}
                        <div className="row">
                            <div className="col-md-8 p-md-0 p-3">
                                <h4><b>2019 Yellow Ford Mustang</b> </h4>
                                <h6><span className="theme-color"> VIN :</span>1DDFDHLKJM854D45F</h6>
                                <h6>14.5352MI | 2WD | 2.6L | 4CYL | GASOLINE | AUTO </h6>
                                <h6><span className="theme-color">Location :</span>Sector 62, Noida <sub>132 miles</sub></h6>
                                <div className="d-flex ">
                                    <h6><span className="theme-color"> Seller :</span>Tanish</h6>
                                    <label className="form-check-label ms-2" htmlFor="flexCheckDefault" >
                                        <i className="fas fa-star " style={{ color: '#D1823A' }}></i>
                                        <i className="fas fa-star " style={{ color: '#D1823A' }}></i>
                                        <i className="fas fa-star " style={{ color: '#D1823A' }}></i>
                                        <i className="fas fa-star " style={{ color: '#D1823A' }}></i>
                                        <i className="fas fa-star "></i>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-4 p-md-0 p-3">
                                <h2> <b>Price :</b> <span className="theme-color"> <b>$15262</b> </span> </h2>
                                <h3> <b>Est :</b> <span className="theme-color"> <b>$257/mon</b> </span> </h3>
                            </div>
                        </div>
                        {/*----------------------content deatils End---------------------*/}


                        {/* ------------------------------Title Details Start------------*/}

                        <div className="row">
                            <hr className="my-1" width="95%" />
                            <h5><b>Seller Report</b> </h5>
                            <p>Frame Damage ..... <Link to="#" className="tooltip-bottom" data-tooltips="Welcome to Dac, Sector 62,Noida D-Block">More</Link> </p>

                            <hr className="my-1" width="100%" />
                            <div className="row mt-4">
                                <div className="col-md-3 col-6 imhs" >
                                    {/* <img src={Data[0].img2} className="w-50 d-block m-auto " alt='..' style={{ cursor: 'pointer' }} /> */}
                                    <img src={Image.title} className="w-50 d-block m-auto " alt='..' style={{ cursor: 'pointer' }} />
                                    {/* <p>{Data[0].Lname}</p> */}
                                    <p className="text-center mt-2 " style={{ fontSize: '14px' }}>Clean Title - TX</p>
                                </div>
                                <div className="col-md-3 col-6">
                                    <img src={Image.odo} className="w-50 d-block m-auto" alt='..' style={{ cursor: 'pointer' }} />
                                    <p className="text-center mt-2" style={{ fontSize: '14px' }}>Actual Mileage</p>
                                </div>
                                <div className="col-md-3 col-6">
                                    <img src={Image.owr} className="w-50 d-block m-auto" alt='..' style={{ cursor: 'pointer' }} />
                                    <p className="text-center mt-2" style={{ fontSize: '14px' }}>5<sup>th<i>Owner</i></sup></p>
                                </div>
                                <div className="col-md-3 col-6">
                                    <img src={Image.sprkBlur} className="w-50 d-block m-auto" alt='..' style={{ cursor: 'pointer' }} />
                                    <p className="text-center mt-2" style={{ fontSize: '14px' }}>No</p>
                                </div>
                                <div className="col-md-3 col-6">
                                    <img src={Image.carBlur} className="w-50 h-50 d-block m-auto" alt='..' style={{ cursor: 'pointer' }} />
                                    <p className="text-center mt-2 " style={{ fontSize: '14px' }}>Run & Drive</p>
                                </div>
                                <div className="col-md-3 col-6">
                                    <img src={Image.key} className="w-50 d-block m-auto text-info" alt='..' style={{ height: '70px', cursor: 'pointer' }} />
                                    <p className="text-center mt-2" style={{ fontSize: '14px' }}>Key</p>
                                </div>
                                <div className="col-md-3 col-6 fffr" >
                                    <img src={Image.tire} className="w-50 h-50 d-block m-auto bnnnnm" alt='..' style={{ cursor: 'pointer' }} />
                                    <p className="text-center mt-2" style={{ fontSize: '14px' }}>Wheels</p>
                                </div>
                                <div className="col-md-3 col-6">
                                    <img src={Image.smoke} className="w-50 h-50 d-block m-auto" alt='..' style={{ cursor: 'pointer' }} />
                                    <p className="text-center mt-2" style={{ fontSize: '14px' }}>No</p>
                                </div>
                            </div>
                        </div>
                        {/* ------------------------------Title Details End------------*/}
                    </div>
                    {/* -------------------left side Icon and button Start--------------------- */}
                    <div className='col-lg-2 col-md-12 '>
                        <div className='row'>
                            <div className='col-lg-12 col-md-4 col-12'>
                                <div className='product-g-btn'>
                                    <span><IoIosCall /></span> Calls
                                </div>
                            </div>
                            <div className='col-lg-12 col-md-4 col-12 my-1'>
                                <div className='product-T-btn'>
                                    <span><IoIosText /></span> Text
                                </div>
                            </div>
                            <div className='col-lg-12 col-md-4 col-12 my-1'>
                                <div className='product-E-btn'>
                                    <span><IoMdMail /></span> Email
                                </div>
                            </div>
                            <div className='col-lg-12 col-md-4 col-12 my-1'>
                                <div className='product-S-btn'>
                                    <span><BsFillSendCheckFill /></span> Send
                                </div>
                            </div>
                            <div className='col-lg-12 col-md-4 col-12 my-1'>
                                <div className='product-S-btn'>
                                    <span><i className="fa-solid fa-landmark"></i></span> Loan
                                </div>
                            </div>
                            <div className='col-lg-12 col-md-4 col-12 my-1'>
                                <div className='product-S-btn'>
                                    <span><i className="fa-solid fa-code-pull-request"></i></span> Request Quote
                                </div>
                            </div>
                            <div className='col-lg-12 col-md-4 col-12 my-1'>
                                <img src={Image.icon1} className="w-75 d-block m-auto my-2" alt="...." />
                            </div>
                            <div className='col-lg-12 col-md-4 col-12 my-1'>
                                <img src={Image.icon2} className="w-75 d-block m-auto my-2" alt="...." />
                            </div>
                        </div>
                    </div>
                    {/* -----left side Icon and button End----- */}
                </div>
                {/* --------------------------------------------------------------- */}
                <div className="row shadow-sm p-3  bg-body rounded border border-dark my-4">
                    <div className="col-lg-4 col-md-5 ">
                        <div className="product-image-box">
                        <div style={{ position: "relative", textAlign: "center" }}>
                            <img src="https://images.unsplash.com/photo-1494976388531-d1058494cdd8?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" className="w-100" alt="..." />
                            <div className="top-right" style={{ position: "absolute", top: "8px", right: "16px", display: "flex", flexDirection: "column" }}>
                                    <FaSquareShareNodes style={{ fontSize: "30px", fill: "white" }} />
                                    <IoHeartCircle style={{ fontSize: "30px", fill: "white" }} />
                                </div>
                                </div>
                            <div className="product-image-content">
                                <div className="d-flex justify-content-center mt-3">
                                    <p className='me-2'>Exterior</p>
                                    <p >Interior</p>
                                </div>
                                <div className="color-variant mx-auto ">
                                    {/* <p><button data-tooltip="I’m the tooltip text.">I’m a button with a tooltip</button></p> */}
                                    <Link
                                        to="#" data-tooltip="Exterior"
                                        style={{
                                            // color: 'red',
                                            padding: '10px',
                                            backgroundColor: 'yellow',
                                            borderRadius: '50%',
                                            height: 'auto',
                                            display: 'block',
                                            // width: '1%',
                                        }}
                                    ></Link>
                                    <Link
                                        to="#" data-tooltip="Interior"
                                        style={{
                                            // color: 'blue',
                                            padding: '10px',
                                            backgroundColor: 'red',
                                            borderRadius: '50%',
                                            height: 'auto',
                                            display: 'block',
                                            // width: '1%',
                                        }}
                                    ></Link>
                                </div>
                                <p className="text-center">Contact the seller Now</p>
                                <div className="product-message">Hi Kumar, is this still available ? ....</div>
                                <button className="product-send-btn me-5">Send</button>
                            </div>
                        </div>
                    </div>
                    {/*----------------------------------------side Image End--------------------------------------------------*/}
                    <div className="col-lg-6 col-md-7">
                        {/*----------------------content deatils Start---------------------*/}
                        <div className="row">
                            <div className="col-md-8 p-md-0 p-3">
                                <h4><b>2019 Yellow Ford Mustang</b> </h4>
                                <h6><span className="theme-color"> VIN :</span>1DDFDHLKJM854D45F</h6>
                                <h6>14.5352MI | 2WD | 2.6L | 4CYL | GASOLINE | AUTO </h6>
                                <h6><span className="theme-color">Location :</span>Sector 62, Noida <sub>132 miles</sub></h6>
                                <div className="d-flex ">
                                    <h6><span className="theme-color"> Seller :</span>Tanish</h6>
                                    <label className="form-check-label ms-2" htmlFor="flexCheckDefault" >
                                        <i className="fas fa-star " style={{ color: '#D1823A' }}></i>
                                        <i className="fas fa-star " style={{ color: '#D1823A' }}></i>
                                        <i className="fas fa-star " style={{ color: '#D1823A' }}></i>
                                        <i className="fas fa-star " style={{ color: '#D1823A' }}></i>
                                        <i className="fas fa-star "></i>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-4 p-md-0 p-3">
                                <h2> <b>Price :</b> <span className="theme-color"> <b>$15262</b> </span> </h2>
                                <h3> <b>Est :</b> <span className="theme-color"> <b>$257/mon</b> </span> </h3>
                            </div>
                        </div>
                        {/*----------------------content deatils End---------------------*/}


                        {/* ------------------------------Title Details Start------------*/}

                        <div className="row">
                            <hr className="my-1" width="95%" />
                            <h5><b>Seller Report</b> </h5>
                            <p>Frame Damage ..... <Link to="#" className="tooltip-bottom" data-tooltips="Welcome to Dac, Sector 62,Noida D-Block">More</Link> </p>

                            <hr className="my-1" width="100%" />
                            <div className="row mt-4">
                                <div className="col-md-3 col-6 imhs" >
                                    <img src="category/tittle-G.png " className="w-50 d-block m-auto " alt='..' style={{ cursor: 'pointer' }} />
                                    <p className="text-center mt-2 " style={{ fontSize: '14px' }}>Clean Title - TX</p>
                                </div>
                                <div className="col-md-3 col-6">
                                    <img src="category/odo-blue.png" className="w-50 d-block m-auto" alt='..' style={{ cursor: 'pointer' }} />
                                    <p className="text-center mt-2" style={{ fontSize: '14px' }}>Actual Mileage</p>
                                </div>
                                <div className="col-md-3 col-6">
                                    <img src="category/owr.png" className="w-50 d-block m-auto" alt='..' style={{ cursor: 'pointer' }} />
                                    <p className="text-center mt-2" style={{ fontSize: '14px' }}>5<sup>th<i>Owner</i></sup></p>
                                </div>
                                <div className="col-md-3 col-6">
                                    <img src="category/sprk-blur.png" className="w-50 d-block m-auto" alt='..' style={{ cursor: 'pointer' }} />
                                    <p className="text-center mt-2" style={{ fontSize: '14px' }}>No</p>
                                </div>
                                <div className="col-md-3 col-6">
                                    <img src="category/car-blue.png" className="w-50 h-50 d-block m-auto" alt='..' style={{ cursor: 'pointer' }} />
                                    <p className="text-center mt-2" style={{ fontSize: '14px' }}>Run & Drive</p>
                                </div>
                                <div className="col-md-3 col-6">
                                    <img src="category/key.png" className="w-50 d-block m-auto text-info" alt='..' style={{ height: '70px', cursor: 'pointer' }} />
                                    <p className="text-center mt-2" style={{ fontSize: '14px' }}>Key</p>
                                </div>
                                <div className="col-md-3 col-6 fffr" >
                                    <img src="category/tir-g.png" className="w-50 h-50 d-block m-auto bnnnnm" alt='..' style={{ cursor: 'pointer' }} />
                                    <p className="text-center mt-2" style={{ fontSize: '14px' }}>Wheels</p>
                                </div>
                                <div className="col-md-3 col-6">
                                    <img src="category/smok.png" className="w-50 h-50 d-block m-auto" alt='..' style={{ cursor: 'pointer' }} />
                                    <p className="text-center mt-2" style={{ fontSize: '14px' }}>No</p>
                                </div>
                            </div>
                        </div>
                        {/* ------------------------------Title Details End------------*/}
                    </div>
                    {/* -------------------left side Icon and button Start--------------------- */}
                    <div className='col-lg-2 col-md-12 '>
                        <div className='row'>
                            <div className='col-lg-12 col-md-4 col-12'>
                                <div className='product-g-btn'>
                                    <span><IoIosCall /></span> Calls
                                </div>
                            </div>
                            <div className='col-lg-12 col-md-4 col-12 my-1'>
                                <div className='product-T-btn'>
                                    <span><IoIosText /></span> Text
                                </div>
                            </div>
                            <div className='col-lg-12 col-md-4 col-12 my-1'>
                                <div className='product-E-btn'>
                                    <span><IoMdMail /></span> Email
                                </div>
                            </div>
                            <div className='col-lg-12 col-md-4 col-12 my-1'>
                                <div className='product-S-btn'>
                                    <span><BsFillSendCheckFill /></span> Send
                                </div>
                            </div>
                            <div className='col-lg-12 col-md-4 col-12 my-1'>
                                <div className='product-S-btn'>
                                    <span><i className="fa-solid fa-landmark"></i></span> Loan
                                </div>
                            </div>
                            <div className='col-lg-12 col-md-4 col-12 my-1'>
                                <div className='product-S-btn'>
                                    <span><i className="fa-solid fa-code-pull-request"></i></span> Request Quote
                                </div>
                            </div>
                            <div className='col-lg-12 col-md-4 col-12 my-1'>
                                <img src="category/icon1.jpeg" className="w-75 d-block m-auto my-2" alt="...." />
                            </div>
                            <div className='col-lg-12 col-md-4 col-12 my-1'>
                                <img src="category/icon2.jpeg" className="w-75 d-block m-auto my-2" alt="...." />
                            </div>
                        </div>
                    </div>
                    {/* -----left side Icon and button End----- */}
                </div>
                {/* --------------------------------------------------------------- */}
            </section>
        </>
    )
}

export default ViewHistory;

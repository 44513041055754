import React, { useEffect, useState } from "react";
import styles from "../DealDetails.module.css";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";
import { HiOutlineInformationCircle } from "react-icons/hi";
import feesDetail from "./DealDetailFeeStructure";
import axios from "axios";

const DealDetails = () => {
  const location = useLocation();
  const carData = location.state;
  console.log("carData => ", carData);
  const [priceFeesArray, setPriceFeesArray] = useState(feesDetail);
  const [apiData, setApiData] = useState({});
  const [totalFees, setTotalFees] = useState(0);
  const [totalTaxes, setotalTaxes] = useState(0);
  console.log("apiData => ", apiData);
  const handleGetDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/sell/get_dealdetails/${carData.userId}/${carData.id}`
      );
      console.log("Response from the data => ", response);
      if (response.status === 200) {
        setApiData(response.data);
        const taxes =
          response.data.saleTaxValue + response.data.mInventoryValue;
        setotalTaxes(taxes);
        const fees = response.data.sellPriceFeeItems.reduce(
          (acc, item) => acc + item.itemValue,
          0
        );
        console.log("Total fees => ", fees);
        setTotalFees(fees);
      }
    } catch (error) {
      console.log("Error while response from API => ", error);
    }
  };
  useEffect(() => {
    handleGetDetails();
  }, []);
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.header}>
          <Link to="/dealer/inventory/balance">
            <IoMdArrowRoundBack />
          </Link>
          <span>Deal Details</span>
        </div>
        <div className={styles.dataContainer}>
          <div className={styles.dataHeader}>
            <div className={styles.subHeader1}>
              <p>
                <span>Asset Info</span>
                <span>
                  <HiOutlineInformationCircle />{" "}
                </span>
              </p>
              <p>
                {carData?.addListing2?.modelYear}{" "}
                {carData?.addListing2?.modelMake}{" "}
                {carData?.addListing2?.makeModel}
              </p>
            </div>
            <div className={styles.subHeader2}>
              <span>Buyer Name</span>
              <span>
                {" "}
                {carData?.firstName}
                {carData?.middleName}
                {carData?.lastName}
              </span>
            </div>
          </div>
          <div className={styles.salePriceContainer}>
            <span>1. Sales Price</span>
            <span>$ {apiData.paymentPriceFees}</span>
          </div>
          <div className={styles.tradeInDifferenceContainer}>
            <div>
              <span>2. Trade In</span>
              <input
                type="text"
                value={`$ ${carData?.addListing?.listing_price || 0}`}
              />
            </div>
            <div>
              <span>3. Owned on Trade</span>
              <input type="text" name="" id="" />
            </div>
            <div>
              <span>4. Cash Difference</span>
              <input
                type="text"
                name=""
                id=""
                value={`$ ${apiData.cashDifference}`}
              />
            </div>
          </div>
          <div className={styles.pricTaxContainer}>
            <div>
              <span>5. Sales Tax</span>
              <input type="text" value={`${apiData.salesTaxes} %`} />
              <input type="text" value={`$ ${apiData.saleTaxValue}`} />
            </div>
            <div>
              <span>6. Inventory Tax</span>
              <input type="text" value={`${apiData.mInventoryTax} %`} />
              <input type="text" value={`$ ${apiData.mInventoryValue}`} />
            </div>
          </div>

          <div className={styles.pricFeesContainer}>
            {apiData &&
              apiData?.sellPriceFeeItems?.map((fee, index) => (
                <div key={fee.id}>
                  <span>
                    {index + 7 + " "}. {fee.itemName}
                  </span>
                  <input type="text" value={fee.itemValue} />
                </div>
              ))}
          </div>
          <div className={styles.tradeInDifferenceContainer}>
            <div>
              <span>18. Total Taxes</span>
              <input type="text" value={`$ ${totalTaxes}`} />
            </div>
            <div>
              <span>19. Total Fees</span>
              <input type="text" value={`$ ${totalFees}`} />
            </div>
          </div>
          <div className={styles.tradeInDifferenceContainer}>
            <div>
              <span>Total</span>
              <input type="text" value={`$ ${(totalTaxes + totalFees).toFixed(2)}`} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealDetails;

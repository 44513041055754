import React, { useState } from "react";
import Styles from "./DealerRetail.module.css";
import DealerRetailInputs from "./DealerRetailInputs";
import { useOutletContext } from "react-router-dom";

const DealerRent_Lease = () => {
  const isExpanded = useOutletContext();
  
  const [formData, setFormData] = useState({
    salesTaxes: "",
    motorInventory: "",
    documentationFees: "",
    drivesOutTag: "",
    cardProcessingFees: "",
    technologyFees: "",
    mailDeliveryFees: "",
    shippingFees: "",
    other1: "",
    other2: "",
    other3: "",
    other4: "",
    other5: "",
    other6: "",
  });
  const handleInput = (name,InputValue) => {
   setFormData((prevData) => ({
    ...prevData, [name] : InputValue
   }))
  }
  console.log(formData)
  return (
    <div className={Styles.wrapper}>
      <div className={Styles.container}>
        <p className={Styles.reset_button_container}>
          <span>Reset</span>
        </p>
        <div
          className={`${Styles.cardContainer} ${
            isExpanded ? Styles.expanded : Styles.collapsed
          } `}
        >
          <p className={Styles.card_header}>
            <span>Rent and Lease</span>
          </p>
          {/* Line 1 */}
          <div className={Styles.line_1}>
            <DealerRetailInputs head="Sales Taxes" name="salesTaxes" type="%" InputValue={formData.salesTaxes} handleChange={handleInput}/>
            <DealerRetailInputs head="Motor Inventory Tax" type="%"  name="motorInventory" InputValue={formData.motorInventory} handleChange={handleInput}/>
            <DealerRetailInputs head="Documentation Fees" type="$" name="documentationFees" InputValue={formData.documentationFees} handleChange={handleInput}/>
            <DealerRetailInputs head="Drives Out Tag" type="$" name="drivesOutTag" InputValue={formData.drivesOutTag} handleChange={handleInput}/>
          </div>
          {/* Line 2 */}
          <div className={Styles.line_2}>
            <DealerRetailInputs head="Card Processing Fees" type="%" name="cardProcessingFees" InputValue={formData.cardProcessingFees} handleChange={handleInput}/>
            <DealerRetailInputs head="Technology Fees" type="%" name="technologyFees"  InputValue={formData.technologyFees} handleChange={handleInput}/>
            <DealerRetailInputs head="Mail Delivery Fees (Fedex)" type="$" name="mailDeliveryFees" InputValue={formData.mailDeliveryFees} handleChange={handleInput}/>
            <DealerRetailInputs head="Shipping Fees" type="$" name="shippingFees" InputValue={formData.shippingFees} handleChange={handleInput}/>
          </div>

          {/* Line 3 */}
          <div className={Styles.line_3}>
            <DealerRetailInputs head="Other" type="$" name="other1" InputValue={formData.other1} handleChange={handleInput}/>
            <DealerRetailInputs head="Other" type="$" name="other2" InputValue={formData.other2} handleChange={handleInput}/>
            <DealerRetailInputs head="Other" type="$" name="other3" InputValue={formData.other3} handleChange={handleInput}/>
            <DealerRetailInputs head="Other" type="$" name="other4" InputValue={formData.other4} handleChange={handleInput}/>
          </div>

          {/* Line 4 */}
          <div className={Styles.line_4}>
            <DealerRetailInputs head="Other" type="$" name="other5" InputValue={formData.other5} handleChange={handleInput}/>
            <DealerRetailInputs head="Other" type="$" name="other6" InputValue={formData.other6} handleChange={handleInput}/>
            <div className={Styles.ApplyButtonWrapper}>
              <div className={Styles.ApplyButtonContainer}>
                <button>Apply</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealerRent_Lease;

import React from 'react'
import DealerfacilitiesHeader from './Components/DealerfacilitiesHeader';
import './Styles/DealerFacilitiesHome.css'
import DealerCredibility from './Components/DealerCredibility';
import DealerFacilitiesProducts from './Components/DealerFacilitiesProducts';
import DealerNextStep from './Components/DealerNextStep';

const DealerFacilitiesHome = () => {
  return (
    <div>
        <div>
            <DealerfacilitiesHeader/>
            <DealerCredibility/>
            <DealerFacilitiesProducts/>
            <DealerNextStep/>
        </div>
    </div>
  )
}

export default DealerFacilitiesHome
import React, { useEffect, useState } from 'react'
import './Button.css';
import { Link, useNavigate } from 'react-router-dom';
import Image from '../../imgData';
import MainMarquee from '../../home/MainMarquee';

const Button = () => {
    // Session Time out for 15 minutes-------------------------------------
    // const navigate = useNavigate();
    // const timeoutInMinutes = 1; // Default timeout value
    // useEffect(() => {
    //     let timer;

    //     const resetTimer = () => {
    //         clearTimeout(timer);
    //         timer = setTimeout(() => {
    //             //setIsActive(false); // Timeout occurred, log out or show session expired message
    //             console.log("session Expire......line15");
    //             navigate("/login");

    //         }, timeoutInMinutes * 60 * 1000); // Convert minutes to milliseconds
    //     };

    //     const clearTimer = () => {
    //         clearTimeout(timer);
    //     };

    //     resetTimer();

    //     window.addEventListener('mousemove', resetTimer); // Reset timer on mouse movement
    //     window.addEventListener('keydown', resetTimer); // Reset timer on key press
    //     window.addEventListener('scroll', resetTimer); // Reset timer on scroll
    //     window.addEventListener('resize', resetTimer); // Reset timer on window resize
    //     window.addEventListener('touchstart', resetTimer); // Reset timer on touch start
    //     window.addEventListener('touchend', resetTimer); // Reset timer on touch end

    //     return () => {
    //         window.removeEventListener('mousemove', resetTimer);
    //         window.removeEventListener('keydown', resetTimer);
    //         window.removeEventListener('scroll', resetTimer);
    //         window.removeEventListener('resize', resetTimer);
    //         window.removeEventListener('touchstart', resetTimer);
    //         window.removeEventListener('touchend', resetTimer);
    //         clearTimer();
    //     };
    // }, []);

    // Session Time out for 15 minutes End-------------------------------------
   
    return (
        <>
            <div className='buttoncontainer'>
                {/*--------------------------------------slider-------------------- */}
                <section>
                  <MainMarquee/>
                </section>
                {/*--------------------------------------slider End-------------------- */}
                <div className='button-main-container-dashboard'>
                {/* <div className='button1'>
                    <button style={{ backgroundColor: "rgb(44,60,79,0.54)", color: "white", border: "none" }}>
                        <p>All</p>
                        <p>Vehicles</p>
                        <p>(7)</p>
                    </button>
                    <button style={{ backgroundColor: "rgb(232,162,98,0.47)", color: "white", border: "none" }}>
                        <p>All</p>
                        <p>Bought</p>
                        <p>(4)</p>
                    </button>
                    <button style={{ backgroundColor: "rgb(44,60,79,0.54)", color: "white", border: "none" }}>
                        <p>All</p>
                        <p>SOLD</p>
                        <p>(3)</p>
                    </button>
                    <button style={{ backgroundColor: "rgb(232,162,98,0.47)", color: "white", border: "none" }}>
                        <p>All</p>
                        <p>Receivable</p>
                        <p>($42,752)</p>
                    </button>
                    <button style={{ backgroundColor: "rgb(44,60,79,0.54)", color: "white", border: "none" }}>
                        <p>All</p>
                        <p>Purchases</p>
                        <p>($36,452)</p>
                    </button>
                    <button style={{ backgroundColor: "rgb(232,162,98,0.47)", color: "white", border: "none" }}>
                        <p>All</p>
                        <p>Messages</p>
                        <p>(984)</p>
                    </button>
                    <button style={{ backgroundColor: "rgb(44,60,79,0.54)", color: "white", border: "none" }}>
                        <p>All</p>
                        <p>Calls</p>
                        <p>(543)</p>
                    </button>
                    <button style={{ backgroundColor: "rgb(232,162,98,0.47)", color: "white", border: "none" }}>
                        <p>All</p>
                        <p>Offers</p>
                        <p>(734)</p>
                    </button>
                </div> */}
                <div className='button2'>
                <Link to="/myprofile/sell"><button>
                        <span id='btn21'>
                    <img src={Image.Sell} style={{width:"50px",height:"50px"}}/>
                        <p>Sell</p></span>
                    </button></Link>

                    <Link to="/myprofile/addlist"><button>
                        <span id='btn22'>
                    <img src={Image.sidebarAddListing} style={{width:"50px",height:"50px"}}/>
                        <p>Add Listing</p></span>
                    </button></Link>
                   
                    <Link to="/myprofile/active"><button>
                        <span id='btn23'>
                    <img src={Image.activeListing} style={{width:"50px",height:"50px"}}/>
                        <p>Active Listing</p></span>
                    </button></Link>
                   
                    <Link  to="/myprofile/bought"><button>
                        <span id='btn24'>
                    <img src={Image.boughtSold} style={{width:"50px",height:"50px"}}/>
                        <p>Bought & Sold</p></span>
                    </button></Link>
                    
                    <Link to="/myprofile/draft"><button>
                        <span id='btn25'>
                    <img src={Image.sidebarDraft} style={{width:"50px",height:"50px"}}/>
                        <p>Draft</p></span>
                    </button></Link>
                   
                </div>
                <div className='button3'>
                <Link to="/myprofile/fav"><button>
                        <span id='btn31'>
                    <img src={Image.sidebarMyFavrites} style={{width:"50px",height:"50px"}}/>
                        <p>My Favorities</p></span>
                    </button></Link>

                    <Link to="/myprofile/save"><button>
                        <span id='btn32'>
                    <img src={Image.sidebarSavedSearch} style={{width:"50px",height:"50px"}}/>
                        <p>Saved Search</p></span>
                    </button></Link>
                   
                    <Link to="#"><button>
                        <span id='btn33'>
                    <img src={Image.sidebarMessages} style={{width:"50px",height:"50px"}}/>
                        <p>Messages</p></span>
                    </button></Link>
                   
                    <Link to="#"><button>
                        <span id='btn34'>
                    <img src={Image.sidebarOffers} style={{width:"50px",height:"50px"}}/>
                        <p>Offers</p></span>
                    </button></Link>
                   
                    <Link to="/myprofile/view"><button>
                        <span id='btn35'>
                    <img src={Image.sidebarViewhistory} style={{width:"50px",height:"50px"}}/>
                        <p>View History</p></span>
                    </button></Link>
                    
                </div>
                <div className='button4'>
                <Link to="/myprofile/comparivehicle"><button>
                        <span id='btn41'>
                    <img src={Image.sidebarCompareVehicles} style={{width:"50px",height:"50px"}}/>
                        <p>Comp. Vehicles</p></span>
                    </button></Link>
                   
                    <Link to="/myprofile/customerdetails"><button>
                        <span id='btn42'>
                    <img src={Image.sidebarMyProfile} style={{width:"50px",height:"50px"}}/>
                        <p>My Profile</p></span>
                    </button></Link>
                   
                    <Link to="/myprofile/recyclebin"><button>
                        <span id='btn43'>
                    <img src={Image.sidebarRecycleBin} style={{width:"50px",height:"50px"}}/>
                        <p>Recycle Bin</p></span>
                    </button></Link>
                   
                    <Link to="/login"><button>
                        <span id='btn44'>
                    <img src={Image.sidebarLogout} style={{width:"50px",height:"50px"}}/>
                        <p>Logout</p></span>
                    </button></Link>
                </div>
                </div>
            </div>
        </>
    )
}
export default Button;
import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Row, FormControl } from 'react-bootstrap';
import Image from "../../../imgData";
import "./equipments.css";
import { MdAirlineSeatReclineNormal, MdCameraswitch, MdMediaBluetoothOn, MdMonitor, MdOutlineElectricCar, MdOutlineSurroundSound } from "react-icons/md";
import { FaArrowDownUpLock, FaRoadBarrier } from 'react-icons/fa6';
import { FaBandcamp, FaRegLightbulb, FaTrafficLight, } from 'react-icons/fa';
import { TbCarCrash, TbDeviceRemote, TbShoppingBagHeart } from 'react-icons/tb';
import { GiAudioCassette, GiCarDoor, GiCarKey, GiCarWheel, GiFlatTire, GiHills, GiMirrorMirror, GiRoad, GiShieldReflect, GiSteeringWheel, GiSunglasses, GiTrafficCone, GiTrafficLightsRed, GiWindow } from 'react-icons/gi';
import { LuParkingCircle } from 'react-icons/lu';
import { BsSpeedometer } from "react-icons/bs";
import { TiWeatherStormy } from "react-icons/ti";
import { PiHeadlightsBold, PiSolarRoof } from "react-icons/pi";
import { RiTaxiWifiLine } from "react-icons/ri";

const Equipments = ({ formData, setFormData, apiDataEdit, setApiDataEdit ,apiData}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [recommendedEquipments, setRecommendedEquipments] = useState([]);
  const [filteredEquipments, setFilteredEquipments] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('safetyFeatures');
  const [selectedEquipments, setSelectedEquipments] = useState([]);
  const [selectedEquipmentsByGroup, setSelectedEquipmentsByGroup] = useState({});
  const [allSelectedEquipments, setAllSelectedEquipments] = useState([]);
  const [showInput, setShowInput] = useState(false);
  const [extraEquipmentName, setExtraEquipmentName] = useState('');
  const [extraVehicleOptions, setExtraVehicleOptions] = useState(formData.extraVehicleOptions || []);
  const [isDropdownVisible, setIsDropdownVisible] = useState(true);
  const groupsAndEquipments = {
    'safetyFeatures': [
      { name: 'ABS (Anti-lock Braking System)', key: 'anti_lock_brake_system', icon: <FaArrowDownUpLock style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Back Up Camera', key: 'back_up_camera', icon: <MdCameraswitch style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Blind spot Monitoring', key: 'blind_spot_monitoring', icon: <MdMonitor style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Break Assist', key: 'brake_assist', icon: <GiShieldReflect style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Collision Mitigation system', key: 'collision_mitigation_system', icon: <MdOutlineElectricCar style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Cross Traffic Alert', key: 'cross_traffic_alert', icon: <GiTrafficLightsRed style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Curtain Airbag', key: 'curtain_airbag', icon: <TbShoppingBagHeart style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'daytime Running Lights', key: 'daytime_running_lights', icon: <FaRegLightbulb style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Electronic Stability Control', key: 'electronic_stability_control', icon: <MdOutlineElectricCar style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Forward Collision warning', key: 'forward_collision_warning', icon: <TbCarCrash style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Front Airbag', key: 'front_airbag', icon: <TbShoppingBagHeart style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Hill Descent Control', key: 'hill_descent_control', icon: <GiHills style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Hill Start assist', key: 'hill_start_assist', icon: <GiHills style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Lane departure warning', key: 'lane_departure_warning', icon: <GiRoad style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Lane Keeping assist', key: 'lane_keeping_assist', icon: <FaRoadBarrier style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Parking Sensors', key: 'parking_sensors', icon: <LuParkingCircle style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Repare cross traffic alert', key: 'rear_cross_traffic_alert', icon: <FaTrafficLight style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Side Airbags', key: 'side_airbags', icon: <TbShoppingBagHeart style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Trafic Control', key: 'traction_control', icon: <GiTrafficCone style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Tyre Pressure monitoring', key: 'tyre_pressure_monitoring', icon: <GiFlatTire style={{ float: "right", fontSize: "25px" }} /> }
    ],
    'comfortAndConvenience': [
      { name: 'Adaptive Cruise Control', key: 'adaptive_cruise_control', icon: <BsSpeedometer style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Auto Climate Control', key: 'auto_climate_control', icon: <TiWeatherStormy style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Auto Dimming Rearview Mirror', key: 'auto_dimming_rearview_mirror', icon: <GiMirrorMirror style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Heated Ventilated Seats', key: 'heated_ventilated_seats', icon: <MdAirlineSeatReclineNormal style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Key Less Entry', key: 'key_less_entry', icon: <GiCarKey style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Key Less go', key: 'key_less_go', icon: <GiCarKey style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Leather seats', key: 'leather_seats', icon: <MdAirlineSeatReclineNormal style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Memory Seats', key: 'memory_seats', icon: <MdAirlineSeatReclineNormal style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Multizone climate control', key: 'multizone_climate_control', icon: <TiWeatherStormy style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'panormic sunroof', key: 'panormic_sunroof', icon: <PiSolarRoof style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'power adjustable seats', key: 'power_adjustable_seats', icon: <MdAirlineSeatReclineNormal style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Power listgate', key: 'power_liftgate', icon: <GiCarDoor style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Power window mirrors', key: 'power_window_mirrors', icon: <GiWindow style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Remote starter', key: 'remote_starter', icon: <TbDeviceRemote style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Smart trunk opener', key: 'smart_trunk_opener', icon: <TbDeviceRemote style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Sunshades', key: 'sunshades', icon: <GiSunglasses style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Title telescopic steering wheel', key: 'tilt_telescopic_steering_wheel', icon: <GiSteeringWheel style={{ float: "right", fontSize: "25px" }} /> }
    ],
    'infotainment': [
      { name: 'Apple Carplay Android Auto', key: 'apple_carplay_android_auto', icon: <GiAudioCassette style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Blutooth Connectivity', key: 'bluetooth_connectivity', icon: <MdMediaBluetoothOn style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Incar wifi', key: 'incar_wifi', icon: <RiTaxiWifiLine style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Navigation System', key: 'navigation_system', icon: <FaBandcamp style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Premium Sound System', key: 'premium_sound_system', icon: <MdOutlineSurroundSound style={{ float: "right", fontSize: "25px" }} /> }
    ],
    'exteriorFeatures': [
      { name: 'Alloy wheels', key: 'alloy_wheels', icon: <GiCarWheel style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Automatic Headlight', key: 'automatic_headlights', icon: <PiHeadlightsBold style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Fog Lights', key: 'fog_lights', icon: <PiHeadlightsBold style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Led Headlight', key: 'led_headlights', icon: <PiHeadlightsBold style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Power Folding Mirrors', key: 'power_folding_mirrors', icon: <GiMirrorMirror style={{ float: "right", fontSize: "25px" }} /> }
    ],
    'performanceAndHandling': [
      { name: 'Adaptive suspension', key: 'adaptive_suspension', icon: <GiFlatTire style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'All Wheel Drive', key: 'all_wheels_drive', icon: <GiFlatTire style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Drive Mode Selector', key: 'drive_mode_selector', icon: <GiFlatTire style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Limited Slip Differential', key: 'limited_slip_differential', icon: <GiFlatTire style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Sports Tuned Suspension', key: 'sport_tuned_suspension', icon: <GiFlatTire style={{ float: "right", fontSize: "25px" }} /> }
    ],
    'interiorFeatures': [
      { name: 'Ambient Listing', key: 'ambient_lighting', icon: <GiFlatTire style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Cargo Cover', key: 'cargo_cover', icon: <GiFlatTire style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'cargo Net', key: 'cargo_net', icon: <GiFlatTire style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Center Console Armrest', key: 'center_console_armrest', icon: <GiFlatTire style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Heated steering Wheels', key: 'heated_steering_wheels', icon: <GiFlatTire style={{ float: "right", fontSize: "25px" }} /> }
    ],
    'driveAssistance': [
      { name: 'Adaptive Headlights', key: 'adaptive_headlights', icon: <GiFlatTire style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Automatic High Beams', key: 'automatic_high_beams', icon: <GiFlatTire style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Driver Drowsiness Monitoring', key: 'driver_drowsiness_monitoring', icon: <GiFlatTire style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Head Up Display', key: 'head_up_display', icon: <GiFlatTire style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Night Version', key: 'night_vision', icon: <GiFlatTire style={{ float: "right", fontSize: "25px" }} /> },
    ],
    'energyAndEquipment': [
      { name: 'Engine Start Stop System', key: 'engine_start_stop_system', icon: <GiFlatTire style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Hybrid Powertrain', key: 'hybrid_powertrain', icon: <GiFlatTire style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Regenerative Breaking', key: 'regenerative_breaking', icon: <GiFlatTire style={{ float: "right", fontSize: "25px" }} /> },
      { name: 'Solar Roof', key: 'solar_roof', icon: <GiFlatTire style={{ float: "right", fontSize: "25px" }} /> },
    ],
    'recommendedEquipments': recommendedEquipments,
    'extraVehicleOptions': extraVehicleOptions?.map(option => ({
      name: option.option_name,
      key: option.option_name.toLowerCase().replace(/\s+/g, '_'),
    })),
  };

  // Debounce hook to prevent rapid input changes
  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);

    return debouncedValue;
  }

  const debouncedSearchQuery = useDebounce(searchQuery, 500);


  useEffect(() => {
    if (debouncedSearchQuery) {
      const filtered = [];
      for (const group in groupsAndEquipments) {
        if (groupsAndEquipments.hasOwnProperty(group)) {
          filtered.push(
            ...groupsAndEquipments[group]
              .filter(equipment =>
                equipment.name.toLowerCase().includes(debouncedSearchQuery.toLowerCase())
              )
              .map(equipment => ({ group, equipment }))
          );
        }
      }
      setFilteredEquipments(filtered);
    } else {
      setFilteredEquipments([]);
    }
  }, [debouncedSearchQuery, extraVehicleOptions]); // Included extraVehicleOptions as a dependency
 console.log("apiData155***",apiData);
//  const cleanRecomded = apiData?.RecommendedEquipments?.replace(/\\/g, '');
//  const recomded = JSON.parse(cleanRecomded);
//  console.log("recomded********",recomded);
useEffect(() => {
  if (apiData?.RecommendedEquipments) {
    const cleanedData = apiData.RecommendedEquipments.replace(/\\/g, '');
    const recommendedEquipmentsData = JSON.parse(cleanedData);
    
    const recommendedEquipmentsList = [];
    for (const category in recommendedEquipmentsData) {
      for (const key in recommendedEquipmentsData[category]) {
        if (recommendedEquipmentsData[category][key]) {
          recommendedEquipmentsList.push({
            name: key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
            key: key,
            category: category,
            icon: <GiFlatTire style={{ float: "right", fontSize: "25px" }} /> // You might want to use different icons based on the category
          });
        }
      }
    }
    setRecommendedEquipments(recommendedEquipmentsList);

    // setFormData(prevFormData => ({
    //   ...prevFormData,
    //   recommendedEquipments: recommendedEquipmentsData,
    // }));
  }
}, [apiData]);
 useEffect(() => {
  if (apiData?.Equipments) {
    const cleanedData = apiData?.Equipments?.replace(/\\/g, '');
    const vehicleOptions = JSON.parse(cleanedData);
    if (vehicleOptions) {
      const updatedExtraVehicleOptions = vehicleOptions?.extraVehicleOptions?.map(option => ({
        ...option,
        id: option.id,
      }));

      setExtraVehicleOptions(updatedExtraVehicleOptions);

      setFormData(prevFormData => ({
        ...prevFormData,
        vehicleOptions: {
          ...prevFormData.vehicleOptions,
          ...vehicleOptions,
          extraVehicleOptions: updatedExtraVehicleOptions,
        },
      }));

      const selected = [];
      for (const group in vehicleOptions) {
        if (group === 'extraVehicleOptions') {
          const extraOptions = vehicleOptions[group];
          extraOptions.forEach(option => {
            if (option.option_value) {
              selected.push({
                name: option.option_name,
                key: option.option_name.toLowerCase().replace(/\s+/g, '_'),
                option_value: option.option_value,
              });
            }
          });
        } else {
          const options = vehicleOptions[group];
          for (const key in options) {
            if (options[key]) {
              const equipment = groupsAndEquipments[group]?.find(eq => eq.key === key);
              if (equipment) selected.push({ ...equipment, option_value: true });
            }
          }
        }
      }
      setAllSelectedEquipments(selected);
    }
  }
}, [apiData]);
  useEffect(() => {
    if (apiDataEdit) {
      const { vehicleOptions } = apiDataEdit;
      if (vehicleOptions) {
        const updatedExtraVehicleOptions = vehicleOptions.extraVehicleOptions.map(option => ({
          ...option,
          id: option.id,
        }));

        setExtraVehicleOptions(updatedExtraVehicleOptions);

        setFormData(prevFormData => ({
          ...prevFormData,
          vehicleOptions: {
            ...prevFormData.vehicleOptions,
            ...vehicleOptions,
            extraVehicleOptions: updatedExtraVehicleOptions,
          },
        }));

        const selected = [];
        for (const group in vehicleOptions) {
          if (group === 'extraVehicleOptions') {
            const extraOptions = vehicleOptions[group];
            extraOptions.forEach(option => {
              if (option.option_value) {
                selected.push({
                  name: option.option_name,
                  key: option.option_name.toLowerCase().replace(/\s+/g, '_'),
                  option_value: option.option_value,
                });
              }
            });
          } else {
            const options = vehicleOptions[group];
            for (const key in options) {
              if (options[key]) {
                const equipment = groupsAndEquipments[group]?.find(eq => eq.key === key);
                if (equipment) selected.push({ ...equipment, option_value: true });
              }
            }
          }
        }
        setAllSelectedEquipments(selected);
      }
    }
  }, [apiDataEdit]);


  const toggleOptionValue = (name) => {
    setAllSelectedEquipments(prevSelected =>
      prevSelected.filter(equipment => equipment.name !== name)
    );

    // Update formData
    setFormData(prevFormData => {
      const updatedVehicleOptions = { ...prevFormData.vehicleOptions };
      let updated = false;

      // First, check in extraVehicleOptions
      if (updatedVehicleOptions.extraVehicleOptions) {
        updatedVehicleOptions.extraVehicleOptions = updatedVehicleOptions.extraVehicleOptions.map(option => {
          if (option.option_name === name) {
            updated = true;
            return { ...option, option_value: false };
          }
          return option;
        });
      }

      // If not found in extraVehicleOptions, check in other groups
      if (!updated) {
        for (const group in updatedVehicleOptions) {
          if (typeof updatedVehicleOptions[group] === 'object' && updatedVehicleOptions[group] !== null) {
            const key = name.toLowerCase().replace(/\s+/g, '_');
            if (key in updatedVehicleOptions[group]) {
              updatedVehicleOptions[group][key] = false;
              updated = true;
              break;
            }
          }
        }
      }

      return { ...prevFormData, vehicleOptions: updatedVehicleOptions };
    });
  };

  // ------------------------------------------------------------
  const handleGroupSelect = (group) => {
    setSelectedGroup(group);
    setSearchQuery("");
    setFilteredEquipments([]);
  };

  // Update selected equipments for the group when it changes
  useEffect(() => {
    setSelectedEquipmentsByGroup((prevSelectedEquipmentsByGroup) => ({
      ...prevSelectedEquipmentsByGroup,
      [selectedGroup]: selectedEquipments,
    }));
  }, [selectedGroup, selectedEquipments]);

  const handleEquipmentSelect = (equipment) => {
    if (selectedGroup === 'recommendedEquipments') {
      // Toggle the value in the main category
      setFormData(prevFormData => {
        const updatedVehicleOptions = { ...prevFormData.vehicleOptions };
        if (!updatedVehicleOptions[equipment.category]) {
          updatedVehicleOptions[equipment.category] = {};
        }
        updatedVehicleOptions[equipment.category][equipment.key] = 
          !updatedVehicleOptions[equipment.category][equipment.key];
  
        return {
          ...prevFormData,
          vehicleOptions: updatedVehicleOptions,
        };
      });
  
      // Update allSelectedEquipments
      setAllSelectedEquipments(prevSelected => {
        const index = prevSelected.findIndex(item => item.name === equipment.name);
        if (index === -1) {
          return [...prevSelected, { ...equipment, option_value: true }];
        } else {
          return prevSelected.filter(item => item.name !== equipment.name);
        }
      });
    } else {
      // Existing logic for other categories
      setAllSelectedEquipments(prevSelected => {
        const index = prevSelected.findIndex(item => item.name === equipment.name);
        if (index === -1) {
          updateVehicleOptionState(equipment.key, true);
          return [...prevSelected, { ...equipment, option_value: true }];
        } else {
          return prevSelected;
        }
      });
    }
  
    // Update selectedEquipments for the current group
    setSelectedEquipments(prevSelected => {
      const index = prevSelected.findIndex(item => item.name === equipment.name);
      if (index === -1) {
        return [...prevSelected, equipment];
      } else {
        return prevSelected.filter(item => item.name !== equipment.name);
      }
    });
    // Remove the equipment from the current group's list
    if (selectedGroup === 'recommendedEquipments') {
      setRecommendedEquipments(prevEquipments => 
        prevEquipments.filter(item => item.name !== equipment.name)
      );
    } else {
      setSelectedEquipments(prevSelected => 
        prevSelected.filter(item => item.name !== equipment.name)
      );
    }
    setIsDropdownVisible(false);
  };
  // New function added
  // const handleEquipmentSelect = (equipment) => {
  //   // Toggle the value in the main category
  //   setFormData(prevFormData => {
  //     const updatedVehicleOptions = { ...prevFormData.vehicleOptions };
  //     const category = selectedGroup === 'recommendedEquipments' ? equipment.category : selectedGroup;
      
  //     if (!updatedVehicleOptions[category]) {
  //       updatedVehicleOptions[category] = {};
  //     }
  //     updatedVehicleOptions[category][equipment.key] = true;
  
  //     return {
  //       ...prevFormData,
  //       vehicleOptions: updatedVehicleOptions,
  //     };
  //   });
  
  //   // Update allSelectedEquipments
  //   setAllSelectedEquipments(prevSelected => {
  //     const index = prevSelected.findIndex(item => item.name === equipment.name);
  //     if (index === -1) {
  //       return [...prevSelected, { ...equipment, option_value: true }];
  //     } else {
  //       return prevSelected;
  //     }
  //   });
  
  //   // Remove the equipment from the current group's list
  //   if (selectedGroup === 'recommendedEquipments') {
  //     setRecommendedEquipments(prevEquipments => 
  //       prevEquipments.filter(item => item.name !== equipment.name)
  //     );
  //   } else {
  //     setSelectedEquipments(prevSelected => 
  //       prevSelected.filter(item => item.name !== equipment.name)
  //     );
  //   }
  
  //   setIsDropdownVisible(false);
  // };
  
  const handleSelectedEquipmentDoubleClick = (equipment) => {
    toggleOptionValue(equipment.name);
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setIsDropdownVisible(true); // Add this line
  };
  useEffect(() => {
    if (!searchQuery) {
      setIsDropdownVisible(true);
    }
  }, [searchQuery]);

  const updateVehicleOptionState = (key, value) => {
    if (selectedGroup === 'extraVehicleOptions') {
      setExtraVehicleOptions(prevOptions =>
        prevOptions.map(option =>
          option.option_name.toLowerCase().replace(/\s+/g, '_') === key
            ? { ...option, option_value: value }
            : option
        )
      );

      setFormData(prevFormData => ({
        ...prevFormData,
        vehicleOptions: {
          ...prevFormData.vehicleOptions,
          extraVehicleOptions: prevFormData.vehicleOptions.extraVehicleOptions.map(option =>
            option.option_name.toLowerCase().replace(/\s+/g, '_') === key
              ? { ...option, option_value: value }
              : option
          )
        }
      }));
    } else {
      setFormData(prevFormData => ({
        ...prevFormData,
        vehicleOptions: {
          ...prevFormData.vehicleOptions,
          [selectedGroup]: {
            ...prevFormData.vehicleOptions[selectedGroup],
            [key]: value,
          },
        },
      }));
    }
  };

  // const handleSearchChange = (e) => {
  //   setSearchQuery(e.target.value);
  // };

  const handleAddEquipmentClick = () => {
    if (showInput && extraEquipmentName.trim() !== '') {
      const newOption = {
        id: 0, // Always set id to 0 for new extra vehicle options
        option_name: extraEquipmentName.trim(),
        option_value: false,
      };

      setExtraVehicleOptions(prevOptions => {
        // Ensure prevOptions is an array
        const currentOptions = Array.isArray(prevOptions) ? prevOptions : [];
        const updatedOptions = [...currentOptions, newOption];
      
        // Update formData vehicleOptions
        setFormData(prevFormData => ({
          ...prevFormData,
          vehicleOptions: {
            ...prevFormData.vehicleOptions,
            extraVehicleOptions: updatedOptions,
          },
        }));
      
        return updatedOptions;
      });

      setExtraEquipmentName('');
    }
    setShowInput(!showInput);
  };

  const handleInputChange = (e) => {
    setExtraEquipmentName(e.target.value);
  };
  const groupNameMapping = {
    safetyFeatures: "Safety Features",
    comfortAndConvenience: "Comfort and Convenience",
    infotainment: "Infotainment",
    exteriorFeatures: "Exterior Features",
    performanceAndHandling: "Performance and Handling",
    interiorFeatures: "Interior Features",
    driveAssistance: "Drive Assistance",
    energyAndEquipment: "Energy and Equipment",
    recommendedEquipments:"Recommended Equipments",
    extraVehicleOptions: "Extra Vehicle Options",
  };


  return (
    <Container className="px-0">
      <Row className="searchParent">
        <Col className="searchOptionParent">
          <div className="searchFormContainer">
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              className="place-equi-a border-0"
              placeholder="Equipments..."
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }}
            />
            <img
              src={Image.search_orange_icon}
              alt="Search Icon"
              style={{ height: "30px", width: "30px" }}
            />
          </div>
          {filteredEquipments.length > 0 && isDropdownVisible && (
            <div className="dropdown-menu show position-absolute dropdown-menub">
              {filteredEquipments.map(({ group, equipment }) => (
                <div
                  key={equipment.name}
                  className="dropdown-item"
                  onClick={() => handleEquipmentSelect(equipment)}
                >
                  {`${equipment.name} (${group})`}
                </div>
              ))}
            </div>
          )}
        </Col>
      </Row>

      <Row className="groups-container">
        <Col sm={7} className="px-0">
          <Container>
            <Row>
              <Col className="groups">
                <div className='groups-head'>Please Select The Group</div>
                {Object.keys(groupsAndEquipments).map(group => (
                  <div
                    key={group}
                    className={`group ${selectedGroup === group ? 'selected' : ''}`}
                    onClick={() => handleGroupSelect(group)}
                    style={{
                      backgroundColor: group === "recommendedEquipments" ? '#F6D9BF' : ''
                    }}
                  >
                    {/* {group} */}
                    {groupNameMapping[group] || group}
                    {selectedGroup === group && <span className="arrow"></span>}
                  </div>
                ))}
              </Col>
              <Col className="equipments">
  <div className='equipments-head'>Please Select The Equipments</div>
  <div className="equipments-scroll">
    {selectedGroup === 'recommendedEquipments' ? (
      recommendedEquipments.length > 0 ? (
        recommendedEquipments.map(equipment => (
          <div
            key={equipment.key}
            className="equipment"
            onClick={() => handleEquipmentSelect(equipment)}
          >
            {equipment.name} <span className='equipment-icon'>{equipment.icon}</span>
          </div>
        ))
      ) : (
        <p>No recommended equipments available.</p>
      )
    ) : (
      selectedGroup && groupsAndEquipments[selectedGroup] ? (
        groupsAndEquipments[selectedGroup]
          .filter(equipment => !allSelectedEquipments.some(selected => selected.name === equipment.name))
          .map(equipment => (
            <div
              key={equipment.name}
              className={`equipment ${selectedEquipments.find(item => item.name === equipment.name) ? 'selected' : ''}`}
              onClick={() => handleEquipmentSelect(equipment)}>
              {equipment.name} <span className='equipment-icon'>{equipment.icon}</span>
            </div>
          ))
      ) : (
        <p>No equipments available for this group.</p>
      )
    )}
  </div>
</Col>
            </Row>
          </Container>
        </Col>
        <Col className="selected-equipments px-0">
          <Container>
            <Row>
               <span className='selected-equipment-head'>Selected Equipment</span>
              {allSelectedEquipments.map(equipment => (
                <Col sm={6} key={equipment.name} className="px-2">
                  <div
                    className="selected-equipment"
                    onClick={() => toggleOptionValue(equipment.name)}
                    onDoubleClick={() => handleSelectedEquipmentDoubleClick(equipment)} // <-- Added this line
                  >
                    {equipment.name}
                  </div>
                </Col>
              ))}
            </Row>


          </Container>
        </Col>
      </Row>
      <Row className='justify-content-end'>
        {showInput && (
          <Col sm={4} className='d-flex justify-content-end'>
            <FormControl
              type="text"
              value={extraEquipmentName}
              onChange={handleInputChange}
              placeholder="Enter new equipment"
              className="me-2"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault(); // Prevents the default Enter key action
                }
              }}
            />
          </Col>
        )}
        <Col sm={2} className='d-flex justify-content-end'>
          <Button className='border-0 btns-orange p-1 btn-change5' onClick={handleAddEquipmentClick}>
            {showInput ? 'Add Equipment' : '+ Add Equipment'}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

// Debounce hook to prevent rapid input changes
function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export default Equipments;
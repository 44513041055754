import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Image from "../../imgData";
import { setRetailData } from "../../../Redux/slice/taxes&FeesSlice";
import { setActiveTab } from "../../../Redux/slice/sellSlice";
import "./components/SaleType.css";
import { Col } from "react-bootstrap";

const SaleType = () => {
  const dispatch = useDispatch();
  const [selectedType, setSelectedType] = useState(null);
  const retailFromRedux = useSelector(
    (state) => state.taxesAndFees.dealerRetail
  );
  const activeTabFromRedux = useSelector((state) => state.sell.activeTab);
  const stateData = retailFromRedux.txtTitleType;
  console.log("txtTitleType", stateData);
  const handleBtnTxt = (txt) => {
    dispatch(setRetailData({ txtTitleType: txt }));
    dispatch(setActiveTab("Buyer"));
  };

  const saleTypes = [
    { id: "Retail", label: "Retail/Cash", image: Image.dealer_retail },
    { id: "Finance/BHPH", label: "Finance/BHPH", image: Image.dealer_finance },
    { id: "Out of State", label: "Out of State", image: Image.dealer_oos },
    { id: "Whole Sale", label: "Whole Sale", image: Image.sidebarHome },
    {
      id: "Rent & Lease",
      label: "Rent & Lease",
      image: Image.dealer_rent_lease,
    },
  ];
  useEffect(() => {
    console.log("stateData => ", stateData);
    if (activeTabFromRedux === "Sale Type" && stateData) {
      setSelectedType(stateData);
    }
  }, [activeTabFromRedux, stateData]);

  const selectedVehicle = useSelector(state => state.sell.selectedVehicle)
  return (
    <div className="saleTypeWrapper">
      <Col sm={12} className="text-center BuyingCarDetails">
        <span>{selectedVehicle ? selectedVehicle.year_Make_Model : null}</span>
        {/* &emsp; &emsp; */}
        <span>
          {selectedVehicle ? (
            <>
              <span style={{ color: "#d1823a" }}>VIN : </span>
              <span>{selectedVehicle.vin}</span>
            </>
          ) : null}
        </span>
        {/* &emsp;&emsp; */}
        <span className="BuyingCarNumbers">
          <span>
            {selectedVehicle ? (
              <>
                <span style={{ color: "#d1823a" }}>Mileage : </span>
                <span>
                  {new Intl.NumberFormat().format(selectedVehicle.mileage_in)}
                </span>
              </>
            ) : null}
          </span>
          {/* &emsp; &emsp; */}
          <span>
            {" "}
            {selectedVehicle ? (
              <>
                <span style={{ color: "#d1823a" }}>Listing Price : </span>
                <span>
                  $
                  {new Intl.NumberFormat().format(
                    selectedVehicle.listing_price
                  )}
                </span>
              </>
            ) : null}
          </span>
        </span>
        {/* &emsp; &emsp; */}
      </Col>
      <div className="saleTypeContainer">
        {saleTypes.map((type) => (
          <div
            key={type.id}
            className="sale_type_data"
            onClick={() => {
              // setSelectedType(type.label);
              handleBtnTxt(type.label);
            }}
            style={{
              backgroundColor: stateData === type.label ? "#e8a262" : "",
            }}
          >
            <input
              type="radio"
              value={type.label}
              id={type.id}
              checked={stateData === type.label}
              style={{ width: "20px", height: "20px", borderRadius: "50%" }}
              className="saleTypeItemInput"
            />

            <label htmlFor={type.id} className="saleTypeItemLabel">
              {type.label}
            </label>
            {/* </div> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SaleType;

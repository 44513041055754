import { createSlice } from '@reduxjs/toolkit';

const profileimgSlice = createSlice({
  name: 'profileimg',
  initialState: {
    y: null, // Initial value of x
  },
  reducers: {
    setProfileimg(state, action) {
      state.y = action.payload;
    },
  },
});

export const { setProfileimg } = profileimgSlice.actions;
export default profileimgSlice.reducer;




// const dataSlice = createSlice({
//     name: 'data',
//     initialState: {
//       x: null, // Initial value of x
//     },
//     reducers: {
//       setData(state, action) {
//         state.x = action.payload;
//       },
//     },
//   });
  
//   export const { setData } = dataSlice.actions;
//   export default dataSlice.reducer;
import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styles from "../Styles/mailAddAcc.module.css";
import spamStyles from "../Styles/common.module.css";

const DealerSpamRules = () => {
    return (
        <Container className={`p-4`}>
            <Row>
                <Col sm={12}>
                    <h5 className={` ${styles.settingHead}`}>Spam Filtering</h5>
                </Col>
                <Col sm={9}>
                    <form className={` ${styles.form}`}>
                        <div className={` ${styles.pop}`}>
                            <p>
                                <input
                                    type='checkbox'
                                    name='msgEnable'
                                // checked={responseMsg.msgEnable}
                                // onChange={handleMsgChange}
                                />
                                Enable mailbox spam filetering</p>
                            <Row>
                                <Col className={` ${spamStyles.spamStage}`}>
                                    <div className={` ${spamStyles.riscLevel}`}>
                                        <img src="" alt="low-risc" />
                                        <h6>'Low Risk' Spam Messages</h6>
                                    </div>
                                    <p>Messages that have some characteristics of spam.</p>
                                    <p>Typically, these are Messages that fail at least one spam test.</p>
                                    <div>
                                        <label className={` d-flex flex-column`}>
                                            Choose Provider:
                                            <select
                                                style={{
                                                    width: '100%',
                                                    background: "white",
                                                    padding: '2px 10px',
                                                    border: '1px solid '
                                                }}
                                            >
                                                <option value="">Select a Provider</option>
                                                <option value="Gmail">No Action</option>
                                                <option value="Yahoo">Delete the message</option>
                                                <option value="AOL">Move message to folder</option>
                                                <option value="Outlook">Copy message to junk mail folder</option>
                                                <option value="ICloud">add prefix to subject</option>
                                            </select>
                                        </label>
                                    </div>
                                </Col>
                                <Col className={` ${spamStyles.spamStage}`}>
                                    <div className={` ${spamStyles.riscLevel}`}>
                                        <img src="" alt="mid-risc" />
                                        <h6>'Moderate Risk' Spam Messages</h6>
                                    </div>
                                    <p>Messages that are likely to be spam.</p>
                                    <p>Typically, these are messages that significantly fail spam tests.</p>
                                    <div>
                                        <label className={` d-flex flex-column`}>
                                            Choose Provider:
                                            <select
                                                style={{
                                                    width: '100%',
                                                    background: "white",
                                                    padding: '2px 10px',
                                                    border: '1px solid '
                                                }}
                                            >
                                                <option value="">Select a Provider</option>
                                                <option value="Gmail">No Action</option>
                                                <option value="Yahoo">Delete the message</option>
                                                <option value="AOL">Move message to folder</option>
                                                <option value="Outlook">Copy message to junk mail folder</option>
                                                <option value="ICloud">add prefix to subject</option>
                                            </select>
                                        </label>
                                    </div>
                                </Col>
                                <Col className={` ${spamStyles.spamStage}`}>
                                    <div className={` ${spamStyles.riscLevel}`}>
                                        <img src="" alt="high-risc" />
                                        <h6>'High Risk' Spam Messages</h6>
                                    </div>
                                    <p>Messages that are very likely to be spam.</p>
                                    <p>Typically, these are messages that severely fail spam tests.</p>
                                    <div>
                                        <label className={` d-flex flex-column`}>
                                            Choose Provider:
                                            <select
                                                style={{
                                                    width: '100%',
                                                    background: "white",
                                                    padding: '2px 10px',
                                                    border: '1px solid '
                                                }}
                                            >
                                                <option value="">Select a Provider</option>
                                                <option value="Gmail">No Action</option>
                                                <option value="Yahoo">Delete the message</option>
                                                <option value="AOL">Move message to folder</option>
                                                <option value="Outlook">Copy message to junk mail folder</option>
                                                <option value="ICloud">add prefix to subject</option>
                                            </select>
                                        </label>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </form>
                </Col>
            </Row>
        </Container>
    )
}

export default DealerSpamRules
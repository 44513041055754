import React, { useCallback, useEffect, useState } from "react";

import "./DealerOffers.css";
import { IoShareSocial } from "react-icons/io5";
import { FaHeart } from "react-icons/fa6";
import { useSelector } from "react-redux";
import {
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookShareButton,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { MdPhoneInTalk } from "react-icons/md";
import { VscTag } from "react-icons/vsc";
const DealerOffers = () => {
  const [showSocialMedia, setShowSocialMedia] = useState(false);
  const { id } = useSelector((state) => state.user);
  const [isFav, setIsFav] = useState(false);
  const handleShareLink = () => {
    console.log("Sharing link with the id");
    setShowSocialMedia(!showSocialMedia);
  };
  const profileData = [
    {
      imgage:
        "https://static.vecteezy.com/system/resources/previews/038/974/578/non_2x/ai-generated-professional-portrait-of-a-competent-woman-free-photo.jpg",
      name: "Jesica",
    },
    {
      imgage:
        "https://st4.depositphotos.com/5260901/31593/i/450/depositphotos_315935436-stock-photo-beautiful-female-african-american-business.jpg",
      name: "Juli",
    },
    {
      imgage:
        "https://media.istockphoto.com/id/627909282/photo/trust-in-our-business.jpg?s=612x612&w=0&k=20&c=q-hm6in8qxi4wcJoicAlmusSlut7CIjKuOkn2F0BFh0=",
      name: "julia",
    },
    {
      imgage:
        "https://thumbs.dreamstime.com/b/portrait-successful-business-woman-white-background-professional-109290811.jpg",
      name: "Stralet",
    },
    {
      imgage:
        "https://t4.ftcdn.net/jpg/01/42/20/17/360_F_142201762_qMCuIAolgpz4NbF5T5m66KQJzYzrEbUv.jpg",
      name: "Rehan",
    },
    {
      imgage:
        "https://www.shutterstock.com/image-photo/smiling-young-middle-eastern-man-260nw-2063524544.jpg",
      name: "John",
    },
    {
      imgage:
        "https://cdn.pixabay.com/photo/2020/09/18/22/05/man-5583034_1280.jpg",
      name: "Sakir",
    },
    {
      imgage:
        "https://thumbs.dreamstime.com/b/handsome-stylish-modern-african-american-business-man-entrepreneur-executive-sitting-outside-office-cheerful-smile-155856257.jpg",
      name: "Honki",
    },
    // {
    //   imgage:
    //     "https://static.vecteezy.com/system/resources/previews/038/974/578/non_2x/ai-generated-professional-portrait-of-a-competent-woman-free-photo.jpg",
    //   name: "Sanjay",
    // },
    // {
    //   imgage:
    //     "https://static.vecteezy.com/system/resources/previews/038/974/578/non_2x/ai-generated-professional-portrait-of-a-competent-woman-free-photo.jpg",
    //   name: "Niraj",
    // },
    // {
    //   imgage:
    //     "https://static.vecteezy.com/system/resources/previews/038/974/578/non_2x/ai-generated-professional-portrait-of-a-competent-woman-free-photo.jpg",
    //   name: "Niraj",
    // },
    // {
    //   imgage:
    //     "https://static.vecteezy.com/system/resources/previews/038/974/578/non_2x/ai-generated-professional-portrait-of-a-competent-woman-free-photo.jpg",
    //   name: "Niraj",
    // },
    // {
    //   imgage:
    //     "https://static.vecteezy.com/system/resources/previews/038/974/578/non_2x/ai-generated-professional-portrait-of-a-competent-woman-free-photo.jpg",
    //   name: "Niraj",
    // },
  ];
  const [visibleProfiles, setVisibleProfiles] = useState(8);
  const [expandedProfile, setExpandedProfile] = useState(null);
  const [showCounterDetail, setShowCounterDetail] = useState(null);

  const handleLoadMore = () => {
    setVisibleProfiles((prev) => Math.min(prev + 3, profileData.length));
  };

  const handleProfileClick = (profileName) => {
    if (expandedProfile === profileName) {
      setExpandedProfile(null);
    } else {
      setExpandedProfile(profileName);
    }
  };

  const handleCounterClick = (profileName) => {
    setShowCounterDetail(
      showCounterDetail === profileName ? null : profileName
    );
  };

  const handleCloseCounterDetail = () => {
    setShowCounterDetail(null);
  };
  return (
    <div>
      <div className="head-offers">
        <div></div>
      <div className="head-btn-offers">
        <button type="button">Received Offers</button>
        <button type="button">Sent Offers</button>
      </div>
      <div className="head-btn-offers-filter">
        <span>10</span>
        <span>Sort By</span>
      </div>
      </div>
      <div className="card wrapper_card_product-b">
        {/* Top Items */}
        <div className="row pb-0 rounded card_container">
          <div className="col-lg-4 col-md-5 card_left-b">
            <div className="product-image-box">
              <img
                src="https://idrive.sg/uploads/0000/7/2024/09/19/blog-10.jpg"
                alt="product_car_image"
                className="product_car_image"
              />
              <div className="product_share_like">
                <span onClick={handleShareLink}>
                  <IoShareSocial />
                </span>
                {id !== 0 ? (
                  <span>
                    <FaHeart fill={`${isFav ? "red" : "white"}`} />
                  </span>
                ) : null}
              </div>
              {/* Share buttons */}
              {showSocialMedia ? (
                <div className="social_media_container">
                  <FacebookShareButton>
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>
                  <TwitterShareButton>
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>
                  <WhatsappShareButton>
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
                  <TelegramShareButton>
                    <FacebookMessengerIcon size={32} round />
                  </TelegramShareButton>
                </div>
              ) : null}
            </div>
          </div>
          <div className="ps-0 card_center">
            {/*----------------------content deatils Start---------------------*/}
            {/* <div className="row"> */}
            <div className="center_primary-b">
              <h4 style={{ fontWeight: 800, cursor: "pointer" }}>
                2019 NISSAN SEDAN
              </h4>
              <div className="vin_extras_container">
                <h6 style={{ fontSize: "18px" }}>
                  <span
                    className="theme-color "
                    style={{
                      color: "#D1823A",
                      fontWeight: "bold",
                      margin: "10px 3px",
                    }}
                  >
                    Mileage :
                  </span>
                  <span style={{ cursor: "pointer" }}>
                    {/* {product.mileage_in} */}
                    {/* {new Intl.NumberFormat().format(product.mileage_in)} */}
                    1,234 MI
                  </span>
                </h6>
                {/* <h6 style={{ fontSize: "18px", display: "flex", gap: "0.2rem" }}>
                <span> {product?.driveType || 0} </span>{" "}
                <span> {product?.engine.split(" ")[1]} </span>{" "}
                <span> {product?.engine.split(" ")[0]} </span>{" "}
                <span> {product?.engine.split(" ")[3]} </span>{" "}
                <span> {product.transmission || "Transmission NA"} </span>
                <span>{product.id}</span>
              </h6> */}
              </div>

              <h6 style={{ fontSize: "18px", margin: "01.3rem 0" }}>
                <span
                  className="theme-color"
                  style={{ color: "#D1823A", fontWeight: "bold" }}
                >
                  Location :{" "}
                </span>
                Sector 62, Noida
                <b>
                  <sub>132 miles</sub>
                </b>
              </h6>
              <div className="d-flex seller_section_product_listing">
                <h6 style={{ fontSize: "18px" }}>
                  <span
                    className="theme-color"
                    style={{ color: "#D1823A", fontWeight: "bold" }}
                  >
                    Seller :{" "}
                  </span>
                  <span>NIRAJ</span>
                </h6>
                <label
                  className="form-check-label ms-2"
                  htmlFor="flexCheckDefault"
                >
                  <i className="fas fa-star " style={{ color: "#D1823A" }}></i>
                  <i className="fas fa-star " style={{ color: "#D1823A" }}></i>
                  <i className="fas fa-star " style={{ color: "#D1823A" }}></i>
                  <i className="fas fa-star " style={{ color: "#D1823A" }}></i>
                  <i className="fas fa-star "></i>
                </label>
              </div>
            </div>
            <div className="center_secondary-b">
              <div className="profile-container">
                {profileData.slice(0, visibleProfiles).map((profile, index) => (
                  <div className="profile" key={index}>
                    <div
                      onClick={() => handleProfileClick(profile.name)}
                      style={{ position: "relative", textAlign: "center" }}
                    >
                      <img
                        className="profile-image"
                        src={profile.imgage}
                        alt={profile.name}
                      />
                      <p>{profile.name}</p>
                      {expandedProfile === profile.name && (
                        <div className="profile-detail">
                          <div className="main-detail-a">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                textAlign: "center",
                              }}
                            >
                              <img
                                className="detail-image"
                                src={profile.imgage}
                                alt={profile.name}
                              />
                              <p style={{ marginBottom: "0px" }}>
                                {profile.name}
                              </p>
                            </div>
                            <div>01:12:00 PM</div>
                            <div className="main-detail-rupees">$ 32,450</div>
                          </div>
                          <div className="main-detail-a mt-1">
                            <div className="main-detail-rupees-a">Accept</div>
                            <div className="main-detail-rupees-b">Decline</div>
                            <div
                              className="main-detail-rupees-c"
                              onClick={() => handleCounterClick(profile.name)}
                            >
                              Counter back
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>

              {/* Counter Detail Section */}
              {showCounterDetail && (
                <div className="counter-detail">
                  <div style={{ position: "relative",width:"100%" }}>
                    <button
                      onClick={handleCloseCounterDetail}
                      style={{
                        position: "absolute",
                        top: "1px",
                        right: "0px",
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                        fontSize: "16px",
                      }}
                    >
                      ✖
                    </button>
                    <h4 style={{textAlign:"center",backgroundColor:"#d1823a",borderRadius:"3px"}}>Listing Price : <span style={{color:"white"}}>$23,456</span></h4>
                    <div className="main-detail-b">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          textAlign: "center",
                          marginTop: "10px",
                        }}
                      >
                        <img
                          className="detail-image"
                          src={
                            profileData.find(
                              (p) => p.name === showCounterDetail
                            )?.imgage
                          }
                          alt={showCounterDetail}
                        />
                        <p style={{ marginBottom: "0px" }}>
                          {showCounterDetail}
                        </p>
                      </div>
                      <div>01:12:00 PM</div>
                      <div className="main-detail-rupees">$ 32,450</div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* </div> */}
          </div>
        </div>
        {/* Bottom items */}
      </div>
      {/* <div
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "grey",
          width: "70%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <h4 style={{ textAlign: "center", backgroundColor: "#d1823a" }}>
          Listin Price: $35,234
        </h4>
        <div className="main-detail-a">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <img
              className="detail-image"
              src="https://img.freepik.com/free-photo/pretty-smiling-joyfully-female-with-fair-hair-dressed-casually-looking-with-satisfaction_176420-15187.jpg"
              alt="alt"
            />
            <p style={{ marginBottom: "0px" }}>Jesica</p>{" "}
            
          </div>
          <div>01:12:00 PM</div>
          <div className="main-detail-rupees">$ 32,450</div>
        </div>
      </div> */}
    </div>
  );
};

export default DealerOffers;

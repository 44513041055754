import React, { useEffect, useState } from "react";
import Styles from "../Styles/SelectedSellCar.module.css";
import Image from "../../../imgData";
import { useOutletContext } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Button } from "react-bootstrap";

import { setRetailData } from "../../../../Redux/slice/taxes&FeesSlice";
import TradeInComponent from "./TradeInComponent";
import { setActiveTab } from "../../../../Redux/slice/sellSlice";
const apiUrl = process.env.REACT_APP_API_URL;
const EmptySellCar = ({ onApiDataUpdate, apiData }) => {
  const isExpanded = useOutletContext();
  const [show, setShow] = useState(false);
  const selectedReduxCar = useSelector((state) => state.sell.selectedVehicle);
  const retailFromRedux = useSelector(
    (state) => state.taxesAndFees.dealerRetail
  );
  console.log("line 18", retailFromRedux);
  //const [txtBtn, setTxtBtn] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showModal, setShowModal] = useState(false);
  console.log("Selected car in redux in selectedsell car", selectedReduxCar);
  const handleChangeDate = (e) => {
    const selectedDate = e.target.value;
    setDate(selectedDate);

    const dateObj = new Date(selectedDate);
    const date = dateObj.getDate();
    const month = dateObj.getMonth() + 1;
    const year = dateObj.getFullYear();
    console.log("Selected Date:", selectedDate);
    console.log("Day:", date);
    console.log("Month:", month);
    console.log("Year:", year);
  };

  // -----------------------------------------------------------------------------------

  // for get data-----
  const { id } = useSelector((state) => state.user);

  const dealerId = id;
  console.log("16", retailFromRedux);
  const stateData = retailFromRedux.txtState;
  const txtBtn = retailFromRedux.txtTitleType;
  console.log("43", txtBtn);
  const [formData, setFormData] = useState({
    dealerId: id,
    taxState: stateData,
    taxType: txtBtn,
  });

  useEffect(() => {
    setFormData((prevFormData) => ({ ...prevFormData, taxType: txtBtn }));
  }, [txtBtn]);
  // const handleBtnTxt = (txt) => {
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     taxType: txt,
  //   }));
  //   handleClose(); // Close the modal after selection
  // };
  //const [dataApi, setDataApi] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      if (formData.dealerId && formData.taxState && formData.taxType) {
        try {
          const response = await axios.post(`${apiUrl}/taxes/get`, formData);
          console.log("Fetched data:", response.data);
          onApiDataUpdate(response.data);
          // setDataApi(response.data);
        } catch (err) {
          console.error("API call error:", err);
        }
      }
    };

    // Fetch data when `formData.taxType` is updated
    if (formData.taxType) {
      fetchData();
    }
  }, [formData]);
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const [date, setDate] = useState(getCurrentDate());
  console.log("line94TradeIndate", date);
  useEffect(() => {
    dispatch(
      setRetailData({
        date: date,
      })
    );
  }, [date]);

  // for tade in popup----
  const handleLinkClick = () => {
    setShowModal(true);
  };
  const handleModalClose = () => {
    setShowModal(false);
  };
  // Lien Holder

  const [apiNameSuggestion, setApinameSuggestion] = useState([]);
  const [showLienHolderModal, setShowLienHoderModal] = useState(false);

  const [showSuggestions, setShowSuggestions] = useState(false);
  const [lienHolderData, setLienHolderData] = useState({
    name: "",
    holderId: "",
    address: "",
    address2: "",
  });

  // console.log(lienHolderData)
  const dispatch = useDispatch();
  const handleLienHolderChange = (e, changeValue) => {
    const { name, value } = e.target;
    setLienHolderData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    dispatch(setRetailData({ [changeValue]: value }));
    if (
      value.trim().length === 0 ||
      apiNameSuggestion.some(
        (suggestion) => suggestion.leinHolderName === value
      )
    ) {
      setApinameSuggestion([]);
      setShowSuggestions(false);
    } else {
      setShowSuggestions(true);
    }
  };
  const handleSuggestionClick = (suggestion) => {
    setApinameSuggestion([]);
    setShowSuggestions(false);
    setLienHolderData((prevData) => ({
      ...prevData,
      name: suggestion.leinHolderName,
      holderId: suggestion.id,
      address:
        suggestion.address1 +
        " , " +
        suggestion.city +
        " , " +
        suggestion.state +
        " , " +
        suggestion.zipCode,
      address2: suggestion.address2,
    }));

    dispatch(
      setRetailData({
        lienHolderName: suggestion?.leinHolderName,
        lienHolderID: suggestion?.id,
        lienHolderAddress:
          suggestion.address1 +
          " , " +
          suggestion.city +
          " , " +
          suggestion.state +
          " , " +
          suggestion.zipCode,
        lienHolderAddress2: suggestion?.address2,
      })
    );
  };

  useEffect(() => {
    const fetchlienHolderData = async () => {
      if (
        !lienHolderData.name ||
        apiNameSuggestion.some(
          (suggestion) => suggestion.leinHolderName === lienHolderData.name
        )
      ) {
        setApinameSuggestion([]);
        setShowSuggestions(false);
        return;
      }
      try {
        // Using FormData if necessary, otherwise use plain JSON
        const fd = new FormData();
        fd.append("name", lienHolderData.name);

        // API call to fetch lien holder data
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/data/get_lein_holder`,
          fd
        );

        // Logging the response
        console.log(response.data);
        setApinameSuggestion(response.data);
        setShowSuggestions(true);
      } catch (error) {
        // More descriptive error handling
        console.error("Error fetching lien holder data:", error);
        setShowSuggestions(false);
        setApinameSuggestion([]);
      }
    };
    fetchlienHolderData();
  }, [lienHolderData.name]);

  const [providerData, setProviderData] = useState({
    name: "",
    providerId: "",
    address: "",
    address2: "",
  });

  const [apiProviderSuggestion, setProviderSuggestion] = useState([]);
  const [showProviderModal, setShowProviderModal] = useState(false);
  const handleProviderChange = (e, changeValue) => {
    const { name, value } = e.target;
    setProviderData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    dispatch(setRetailData({ [changeValue]: value }));
    if (
      value.trim().length === 0 ||
      apiProviderSuggestion.some(
        (suggestion) => suggestion.leinHolderName === value
      )
    ) {
      setProviderSuggestion([]);
    }
  };
  const handleProviderClick = (suggestion) => {
    setProviderSuggestion([]);
    setProviderSuggestion((prevData) => ({
      ...prevData,
      name: suggestion.leinHolderName,
      providerId: suggestion.id,
      address:
        suggestion.address1 +
        " , " +
        suggestion.city +
        " , " +
        suggestion.state +
        " , " +
        suggestion.zipCode,
      address2: suggestion.address2,
    }));

    dispatch(
      setRetailData({
        serviceContractName: suggestion?.leinHolderName,
        serviceContractId: suggestion?.id,
        serviceContractAddress:
          suggestion.address1 +
          " , " +
          suggestion.city +
          " , " +
          suggestion.state +
          " , " +
          suggestion.zipCode,
        serviceContractAddress2: suggestion?.address2,
      })
    );
  };

  useEffect(() => {
    const fetchProviderData = async () => {
      if (
        !providerData.name ||
        apiProviderSuggestion.some(
          (suggestion) => suggestion.leinHolderName === providerData.name
        )
      ) {
        setProviderSuggestion([]);
        return;
      }
      try {
        // Using FormData if necessary, otherwise use plain JSON
        const fd = new FormData();
        fd.append("name", providerData.name);

        // API call to fetch lien holder data
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/data/get_service_contract`,
          fd
        );

        // Logging the response
        console.log(response.data);
        setProviderSuggestion(response.data);
      } catch (error) {
        // More descriptive error handling
        console.error("Error fetching lien holder data:", error);
        setProviderSuggestion([]);
      }
    };
    fetchProviderData();
  }, [providerData.name]);

  // useEffect(() => {
  //   dispatch(
  //     setRetailData({
  //       PriceFeeDate: date,
  //     })
  //   );
  // }, [date]);
  // ----------------------------
  return (
    <>
      <div className={Styles.txtbutton_saleprice} style={{ justifyContent: "flex-end" }}>
        {/* <span style={{ marginRight: "34%" }}>{txtBtn}</span> */}
        <span style={{ height: "30px" }}>
          {" "}
          <div
            className={Styles.inputFieldContainer}
            style={{ padding: "0px", visibility: "hidden" }}
          >
            <input
              type="date"
              value={date}
              onChange={handleChangeDate}
              className={Styles.inputField}
              style={{ border: "none", marginBottom: "5px", backgroundColor: "#d1823a", borderRadius: "5px", color: "white" }}
            />
          </div>
        </span>
      </div>
      <div className={Styles.wrapper}>
        <div className={Styles.container}>
          <img src={Image.inventory_not_selected} alt="car" />
          <div className={Styles.vin_name_wrapper}>
            <div
              className={`${Styles.vin_name_container} ${isExpanded ? Styles.font_smaller : ""
                }`}
            >
              <span
                onClick={() => dispatch(setActiveTab("Vehicle List"))}
                style={{ cursor: "pointer", fontWeight: "600" }}
              >
                Please click here to select inventory
              </span>
              <span className={Styles.vin_Container} style={{ display: "none" }}>
                <span style={{ color: "#d1823a" }}>VIN : </span>
                <span>5NPET46CX8H372949</span>
              </span>
              <span className={Styles.vin_Container} style={{ display: "none" }}>
                <span style={{ color: "#d1823a" }}>Mileage : </span>
                <span>5NPET46CX8H372949</span>
              </span>
            </div>
            {/* Date Contianer */}

            {/*  */}
            <div
              className={`${Styles.price_type_container}`}
              style={{ flex: "0.2rem" }}
            >
              {/*<div className={Styles.price_container} style={{width :"80%"}}>
              <span style={{height:"30px"}}>
                {" "}
                <div
                  className={Styles.inputFieldContainer}
                  style={{ padding: "0px" }}
                >
                  <input
                    type="date"
                    value={date}
                    onChange={handleChangeDate}
                    className={Styles.inputField}
                    style={{backgroundColor:"grey",color:"white",border:"none",marginBottom:"5px"}}
                  />
                </div>
              </span> */}
              {/* <span className={isExpanded ? Styles.hide_header : ""}>
                {txtBtn}
              </span>
            </div> */}
              <div className={`gap-1 d-flex flex-column `}>
                <span className={Styles.clickedSpanb} style={{ width: "100%" }}>Trade-In</span>
                <TradeInComponent
                  show={showModal}
                  handleClose={handleModalClose}
                />
                <span
                  className={Styles.clickedSpanb}
                >
                  Service Contract
                </span>
              </div>
              <div className={`gap-1 d-flex flex-column `}>
                <span className={Styles.clickedSpanb}>
                  Lien Holder
                </span>
                <span className={Styles.clickedSpanb}>
                  Gap Insurance
                </span>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={showLienHolderModal}
          onHide={() => setShowLienHoderModal(false)}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Lien Holder</Modal.Title>
          </Modal.Header>
          <Modal.Body
            className="h-50 "
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2,1fr",
              gap: "2rem",
              rowGap: "2rem",
            }}
          >
            <div style={{ position: "relative" }}>
              <p style={{ marginBottom: "0" }}>Lien Holder Name</p>
              <input
                type="text"
                value={lienHolderData.name}
                name="name"
                style={{
                  backgroundColor: "#E8A262",
                  border: "none",
                  padding: "0.3rem",
                  color: "white",
                  fontWeight: "500",
                  zIndex: "0",
                }}
                onChange={(e) => handleLienHolderChange(e, "lienHolderName")}
              />
              {showSuggestions && (
                <ul
                  className="dropdown-menu show"
                  style={{ position: "absolute", top: "10rem", zIndex: "1" }}
                >
                  {apiNameSuggestion?.map((suggestion) => (
                    <li key={suggestion.id}>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => handleSuggestionClick(suggestion)}
                      >
                        {suggestion.leinHolderName}
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div>
              <p style={{ marginBottom: "0" }}>Lien Holder ID</p>
              <input
                type="text"
                value={lienHolderData.holderId}
                name="holderId"
                style={{
                  backgroundColor: "#E8A262",
                  border: "none",
                  padding: "0.3rem",
                  color: "white",
                  fontWeight: "500",
                  zIndex: "0",
                }}
                onChange={(e) => handleLienHolderChange(e, "lienHolderID")}
              />
            </div>
            <div>
              <p style={{ marginBottom: "0" }}>Address : </p>
              <input
                type="text"
                value={lienHolderData.address}
                name="address"
                style={{
                  backgroundColor: "#E8A262",
                  border: "none",
                  padding: "0.3rem",
                  color: "white",
                  fontWeight: "500",
                  zIndex: "0",
                }}
                onChange={(e) => handleLienHolderChange(e, "lienHolderAddress")}
              />
            </div>
            <div>
              <p style={{ marginBottom: "0" }}>Address 2 :</p>
              <input
                type="text"
                value={lienHolderData.address2}
                name="address2"
                style={{
                  backgroundColor: "#E8A262",
                  border: "none",
                  padding: "0.3rem",
                  color: "white",
                  fontWeight: "500",
                  zIndex: "0",
                }}
                onChange={(e) => handleLienHolderChange(e, "lienHolderAddress2")}
              />
            </div>
          </Modal.Body>
          {/* <div style={{display:"flex",justifyContent:"flex-end",marginRight:"15px",marginBottom:"15px"}}><button type="button" style={{border:"none",backgroundColor:"#d1823a",color:"white",borderRadius:"5px",width:"80px",height:"30px"}}>Save</button></div> */}
          <Modal.Footer>
            <p
              style={{
                display: "flex",
                justifyContent: "end",
                fontSize: "large",
                width: "100%",
              }}
            >
              <span
                style={{
                  backgroundColor: "#E8A262",
                  color: "white",
                  padding: "0.3rem 0.5rem",
                }}
                onClick={() => setShowLienHoderModal(false)}
              >
                Submit
              </span>
            </p>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showProviderModal}
          onHide={() => setShowProviderModal(false)}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Service Contract Provider</Modal.Title>
          </Modal.Header>
          <Modal.Body
            className="h-50 "
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2,1fr",
              gap: "2rem",
              rowGap: "2rem",
            }}
          >
            <div style={{ position: "relative" }}>
              <p style={{ marginBottom: "0" }}>Provider Name</p>
              <input
                type="text"
                value={providerData.name}
                name="name"
                style={{
                  backgroundColor: "#E8A262",
                  border: "none",
                  padding: "0.3rem",
                  color: "white",
                  fontWeight: "500",
                  zIndex: "0",
                }}
                onChange={(e) => handleProviderChange(e, "serviceContractName")}
              />
              {apiProviderSuggestion.length > 0 && (
                <ul
                  className="dropdown-menu show"
                  style={{ position: "absolute", top: "10rem", zIndex: "1" }}
                >
                  {apiProviderSuggestion?.map((suggestion) => (
                    <li key={suggestion.id}>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => handleProviderClick(suggestion)}
                      >
                        {suggestion.leinHolderName}
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div>
              <p style={{ marginBottom: "0" }}>Service Provider ID</p>
              <input
                type="text"
                value={providerData.providerId}
                name="providerId"
                style={{
                  backgroundColor: "#E8A262",
                  border: "none",
                  padding: "0.3rem",
                  color: "white",
                  fontWeight: "500",
                  zIndex: "0",
                }}
                onChange={(e) => handleProviderChange(e, "serviceContractId")}
              />
            </div>
            <div>
              <p style={{ marginBottom: "0" }}>Address : </p>
              <input
                type="text"
                value={providerData.address}
                name="address"
                style={{
                  backgroundColor: "#E8A262",
                  border: "none",
                  padding: "0.3rem",
                  color: "white",
                  fontWeight: "500",
                  zIndex: "0",
                }}
                onChange={(e) =>
                  handleProviderChange(e, "serviceContractAddress")
                }
              />
            </div>
            <div>
              <p style={{ marginBottom: "0" }}>Address 2 :</p>
              <input
                type="text"
                value={providerData.address2}
                name="address2"
                style={{
                  backgroundColor: "#E8A262",
                  border: "none",
                  padding: "0.3rem",
                  color: "white",
                  fontWeight: "500",
                  zIndex: "0",
                }}
                onChange={(e) =>
                  handleProviderChange(e, "serviceContractAddress2")
                }
              />
            </div>
          </Modal.Body>
          {/* <div style={{display:"flex",justifyContent:"flex-end",marginRight:"15px",marginBottom:"15px"}}><button type="button" style={{border:"none",backgroundColor:"#d1823a",color:"white",borderRadius:"5px",width:"80px",height:"30px"}}>Save</button></div> */}
          <Modal.Footer>
            <p
              style={{
                display: "flex",
                justifyContent: "end",
                fontSize: "large",
                width: "100%",
              }}
            >
              <span
                style={{
                  backgroundColor: "#E8A262",
                  color: "white",
                  padding: "0.3rem 0.5rem",
                }}
                onClick={() => setShowProviderModal(false)}
              >
                Submit
              </span>
            </p>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default EmptySellCar;

import React, { useState } from "react";
import Styles from "../DealerRetail/DealerRetail.module.css";
import DealerRetailInputs from "./DealerRetailInputs";
import { useOutletContext } from "react-router-dom";

const DealerOutOfState = () => {
  const isExpanded = useOutletContext();

  const [formData, setFormData] = useState({
    salesTaxes: "0",
    inventoryTax: "0.001488",
    titleApplicationFee: "0",
    terpTitlefee: "0",
    drivesOutTag: "5",
    windshieldTrackerSticker: "0",
    regFeeDps: "0",
    cntyRoadBridgeFee: "0",
    childSafetyFund: "0",
    InspectionFeeOBDNL: "0",
    processingHandlingFee: "0",
    documentationFee: "150",

    technologyFees: "0",
   
    other1: "",
    other2: "",
    
  });
  const handleInput = (name, InputValue) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: InputValue,
    }));
  };
  console.log(formData);
  return (
    <div className={Styles.wrapper}>
      <div className={Styles.container}>
        <p className={Styles.reset_button_container}>
          <span>Reset</span>
        </p>
        <div
          className={`${Styles.cardContainer} ${
            isExpanded ? Styles.expanded : Styles.collapsed
          } `}
        >
          <p className={Styles.card_header}>
            <span>Out of State Service</span>
          </p>
          {/* Line 1 */}
          <div className={Styles.columnContainer}>
            <div className={Styles.col}>
              {" "}
              <div
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  marginBottom: "10px",
                  fontSize: "24px",
                }}
              >
                Taxes
              </div>
              <DealerRetailInputs
                head="Sales Taxes"
                name="salesTaxes"
                type="%"
                InputValue={formData.salesTaxes}
                handleChange={handleInput}
              />
              <DealerRetailInputs
                head="Motor Inventory Tax"
                type="%"
                name="inventoryTax"
                InputValue={formData.inventoryTax}
                handleChange={handleInput}
              />
            </div>
            <div className={Styles.col}>
              <div
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  marginBottom: "10px",
                  fontSize: "24px",
                }}
              >
                Licence & Registration
              </div>
              <DealerRetailInputs
                head="Title Application Fee"
                type="%"
                name="titleApplicationFee"
                InputValue={formData.titleApplicationFee}
                handleChange={handleInput}
              />
              <DealerRetailInputs
                head="TERP Title Fee"
                type="$"
                name="terpTitlefee"
                InputValue={formData.terpTitlefee}
                handleChange={handleInput}
              />
              <DealerRetailInputs
                head="Drives Out Tag"
                type="$"
                name="drivesOutTag"
                InputValue={formData.drivesOutTag}
                handleChange={handleInput}
              />
              <DealerRetailInputs
                head="Windshield Sticker"
                type="%"
                name="windshieldTrackerSticker"
                InputValue={formData.windshieldTrackerSticker}
                handleChange={handleInput}
              />
              <DealerRetailInputs
                head="REG FEE-DPS "
                type="$"
                name="regFeeDps"
                InputValue={formData.regFeeDps}
                handleChange={handleInput}
              />
              <DealerRetailInputs
                head="CNTY Road Bridge Add-on Fee"
                type="$"
                name="cntyRoadBridgeFee"
                InputValue={formData.cntyRoadBridgeFee}
                handleChange={handleInput}
              />
              <DealerRetailInputs
                head="Child Safety Fund "
                type="$"
                name="childSafetyFund"
                InputValue={formData.childSafetyFund}
                handleChange={handleInput}
              />
              <DealerRetailInputs
                head="Inspection Fee - OBDNL"
                type="$"
                name="InspectionFeeOBDNL"
                InputValue={formData.InspectionFeeOBDNL}
                handleChange={handleInput}
              />
              <DealerRetailInputs
                head="Processing and Handling Fee"
                type="$"
                name="processingHandlingFee"
                InputValue={formData.processingHandlingFee}
                handleChange={handleInput}
              />
            </div>
            <div className={Styles.col}>
              <div
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  marginBottom: "10px",
                  fontSize: "24px",
                }}
              >
                Fee & Others
              </div>
              <DealerRetailInputs
                head="Documentation Fees"
                type="$"
                name="documentationFee"
                InputValue={formData.documentationFee}
                handleChange={handleInput}
              />
              <DealerRetailInputs
                head="Technology Fees"
                type="%"
                name="technologyFees"
                InputValue={formData.technologyFees}
                handleChange={handleInput}
              />
              <DealerRetailInputs
                head="Other"
                type="$"
                name="other1"
                InputValue={formData.other1}
                handleChange={handleInput}
              />
              <DealerRetailInputs
                head="Other"
                type="$"
                name="other2"
                InputValue={formData.other2}
                handleChange={handleInput}
              />
            </div>
          </div>

          {/* Line 3 */}
          {/* <div className={Styles.line_3}></div> */}

          {/* Line 4 */}
          <div className={Styles.line_4}>
            <div className={Styles.ApplyButtonWrapper}>
              <div className={Styles.ApplyButtonContainer}>
                <button>Apply</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealerOutOfState;

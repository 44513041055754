import React, { useEffect, useRef, useState } from "react";
import "../../DealerInventory/DealerAddInventory/addlist.css";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import imageCompression from "browser-image-compression";
import { GiClick } from "react-icons/gi";

// For Tabsection----------------------
// import { Tabs, Tab, Container, Row, Col, Button } from 'react-bootstrap';
import {
  Tabs,
  Tab,
  Container,
  Row,
  Col,
  Button,
  Form,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";

import {
  MdDelete,
  MdOutlineRemoveRedEye,
  MdOutlineVisibilityOff,
  MdMoveDown,
  MdMoveUp,
  MdOutlineMoveUp,
} from "react-icons/md";
import { FaArrowRight, FaCalculator } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import { FaSearch } from "react-icons/fa";
//import Button from 'react-bootstrap/Button';
import { ChromePicker } from "react-color";
import usaStates from "../../DealerInventory/DealerAddInventory/usaStates.json";
import { SlCalender } from "react-icons/sl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import Image from "../../../imgData";
import { fetchGeoMapData } from "../../../dashboard/geoAPI";
import PopupData from "../../DealerInventory/DealerAddInventory/PopupData";
import StateSelector from "../../DealerInventory/DealerAddInventory/StateSelector";
import Preview from "../../DealerInventory/DealerAddInventory/Preview";
import Equipments from "../../DealerInventory/DealerAddInventory/Equipments";
import PopupColorData from "../../DealerInventory/DealerAddInventory/PopupColorData";
import PanolensViewer from "../../DealerInventory/DealerAddInventory/PanolensViewer";
import PopupTyresData from "../../DealerInventory/DealerAddInventory/PopupTyresData";
import { setRetailData } from "../../../../Redux/slice/taxes&FeesSlice";
import AmountInputWithCalculator from "../../DealerInventory/DealerAddInventory/AmountInputWithCalculator";
// For Tabsection end----------------------
const apiUrl = process.env.REACT_APP_API_URL;
// console.log("line 80",apiUrl);

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <button
      className="slick-arrow slick-prev"
      style={{
        display: "block",
        position: "absolute",
        left: "20px",
        width: "20px",
        height: "20px",
        zIndex: 1,
      }}
      onClick={onClick}
    >
      Previous
    </button>
  );
};

const NextArrow = (props) => {
  //const { onClick } = props;
  const { className, style, onClick } = props;
  return (
    <button
      className={`${className} slick-arrow slick-next`}
      style={{
        ...style,
        display: "block",
        position: "absolute",
        right: "40px",
        width: "20px",
        height: "20px",
        zIndex: 1,
      }}
      onClick={onClick}
    >
      Next
    </button>
  );
};

const TradeInComponent = ({
  show,
  handleClose,
  onButtonClick,
  setIsSpanVisibleTradeIn,
  onReset,
  setIsClicked,
  counter,
  isClicked,
  status
}) => {
  const { id } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { dealerId } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();
  const activeLocation = useLocation();
  // Retrieve original data passed via navigation
  const originalData = location.state?.data || {};
  const TaxFeesPopData = useSelector(
    (state) => state.taxesAndFees.dealerRetail || 0
  );
  console.log("TaxFeesPopData*******24*******TradeIn", TaxFeesPopData);
  console.log("location", location);

  const [inputVin, setInputVin] = useState("");
  const dealerRetailDtata = useSelector(
    (state) => state.taxesAndFees.dealerRetail || ""
  );
  console.log("dealerRetailDtataTradeIn",dealerRetailDtata);
  console.log("statusTradeIn",status);
  // Loading stats
  const [loading, setLoading] = useState(false);
  const [uploadedCount, setUploadedCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [uploadSpeed, setUploadSpeed] = useState(0);
  const [uploadSpeedDisplay, setUploadSpeedDisplay] = useState(0);

  useEffect(() => {
    setUploadSpeedDisplay(uploadSpeed);
  }, [uploadSpeed]);

  const progressPercentage = (uploadedCount / totalCount) * 100;

  const [activeTab, setActiveTab] = useState("tab1");
  const [apiData, setApiData] = useState(null);
  const [apiDataEdit, setApiDataEdit] = useState(null);
  const [apiDatab, setApiDatab] = useState(null);
  const [apiDatac, setApiDatac] = useState(null);
  const scrollContainerRef = useRef(null);
  const [emptyFields, setEmptyFields] = useState({});
  //const [decode_error, setErrorMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSeatsPresent, setIsSeatsPresent] = useState(false);
  const [isTransmissionPresent, setIsTransmissionPresent] = useState(false);
  const [year, setYear] = useState(null);
  const [make, setMake] = useState(null);
  const [model, setModel] = useState(null);
  //---------------Editable Dropdown-------------------
  const [inputValue1, setInputValue1] = useState("Used");
  const [inputValue34, setInputValue34] = useState("");
  //for purchased form end----------------------------------
  const [dropdownValuea, setDropdownValuea] = useState("");
  const [dropdownOptionsa, setDropdownOptionsa] = useState([
    "",
    "Option 2",
    "Option 3",
  ]);
  const [newOptiona, setNewOptiona] = useState("");
  // purchased form dropdown end---------------------------------------------------------
  const [exteriorColorShow, setExteriorColorShow] = useState("");
  console.log("exteriorColorShow => ", exteriorColorShow);
  const [interiorColorShow, setInteriorColorShow] = useState("");
  console.log("interiorColorShow => ", interiorColorShow);
  //for  Buyer name dropdown---------------------------------------------------
  const [dropdownValueb, setDropdownValueb] = useState("");
  const [dropdownOptionsb, setDropdownOptionsb] = useState([
    "",
    "John",
    "Lusi",
  ]);
  const [newOptionb, setNewOptionb] = useState("");
  //  Buyer name dropdown end---------------------------------------------------
  const [btnTxt, setBtnTxt] = useState("");
  // popup selection option--------------------------------------****************************************************--------------------------

  const [showModalImg, setShowModalImg] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [selectedOptionn, setSelectedOptionn] = useState("");
  const [showModalb, setShowModalb] = useState(false);
  const [selectedOptionb, setSelectedOptionb] = useState("");
  const [showModalc, setShowModalc] = useState(false);
  const [selectedOptionc, setSelectedOptionc] = useState("");
  const [showModald, setShowModald] = useState(false);
  const [selectedOptiond, setSelectedOptiond] = useState("");
  const [showModale, setShowModale] = useState(false);
  const [selectedOptione, setSelectedOptione] = useState("");
  const [showModalf, setShowModalf] = useState(false);
  const [selectedOptionf, setSelectedOptionf] = useState("");
  const [showModalg, setShowModalg] = useState(false);
  const [selectedOptiong, setSelectedOptiong] = useState("");
  const [showModalh, setShowModalh] = useState(false);
  const [selectedOptionh, setSelectedOptionh] = useState("");
  const [showModali, setShowModali] = useState(false);
  const [selectedOptioni, setSelectedOptioni] = useState("");
  const [showModalj, setShowModalj] = useState(false);
  const [selectedOptionj, setSelectedOptionj] = useState("");
  const [showModalk, setShowModalk] = useState(false);
  const [selectedOptionk, setSelectedOptionk] = useState("");
  const [showModall, setShowModall] = useState(false);
  const [selectedOptionl, setSelectedOptionl] = useState("");
  const [showModalm, setShowModalm] = useState(false);
  const [selectedOptionm, setSelectedOptionm] = useState("");
  const [showModalo, setShowModalo] = useState(false);
  const [selectedOptiono, setSelectedOptiono] = useState("");
  const [showModalp, setShowModalp] = useState(false);
  const [selectedOptionp, setSelectedOptionp] = useState("");
  const [showModalq, setShowModalq] = useState(false);
  const [selectedOptionq, setSelectedOptionq] = useState("");
  const [showModalr, setShowModalr] = useState(false);
  const [selectedOptionr, setSelectedOptionr] = useState("");
  const [showModals, setShowModals] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState("");
  const [showModalt, setShowModalt] = useState(false);
  const [selectedOptiont, setSelectedOptiont] = useState("");
  const [showModalu, setShowModalu] = useState(false);
  const [selectedOptionu, setSelectedOptionu] = useState("");
  const [showModalv, setShowModalv] = useState(false);
  const [selectedOptionv, setSelectedOptionv] = useState("");
  const [showModalw, setShowModalw] = useState(false);
  const [selectedOptionw, setSelectedOptionw] = useState("");
  const [showModalx, setShowModalx] = useState(false);
  const [selectedOptionx, setSelectedOptionx] = useState("");
  const [showModaly, setShowModaly] = useState(false);
  const [selectedOptiony, setSelectedOptiony] = useState("");
  const [showModalz, setShowModalz] = useState(false);
  const [selectedOptionz, setSelectedOptionz] = useState("");
  const [showModalza, setShowModalza] = useState(false);
  const [selectedOptionza, setSelectedOptionza] = useState("");
  const [showModalzb, setShowModalzb] = useState(false);
  const [selectedOptionzb, setSelectedOptionzb] = useState("");
  // poup selection option end-------------------------------------------****************************************----------------------------------
  // For Dropdown for tab section end------------------------------------------------------
  // for adv manager --------------
  const [isConnected, setIsConnected] = useState(false);
  const [isConnectedb, setIsConnectedb] = useState(false);
  const [isConnectedc, setIsConnectedc] = useState(false);
  const [isConnectedd, setIsConnectedd] = useState(false);
  const [isConnectede, setIsConnectede] = useState(false);
  const [isConnectedf, setIsConnectedf] = useState(false);
  const [isConnectedg, setIsConnectedg] = useState(false);
  const [showModalPreview, setShowModalPreview] = useState(false);
  const toggleImage = () => {
    setIsConnected(!isConnected);
  };
  const toggleImageb = () => {
    setIsConnectedb(!isConnectedb);
  };
  const toggleImagec = () => {
    setIsConnectedc(!isConnectedc);
  };
  const toggleImaged = () => {
    setIsConnectedd(!isConnectedd);
  };
  const toggleImagee = () => {
    setIsConnectede(!isConnectede);
  };
  const toggleImagef = () => {
    setIsConnectedf(!isConnectedf);
  };
  const toggleImageg = () => {
    setIsConnectedg(!isConnectedg);
  };

  // for three circle------------------------
  const handleLinkClickPreview = () => {
    setShowModalPreview(true);
  };

  const handleModalClosePreview = () => {
    setShowModalPreview(false);
  };
  // floor fees-------------------------------
  const [inputValueInterestRate, setInputValueInterestRate] = useState("");
  const [inputValueLengthFloor, setInputValueLengthFloor] = useState("");
  const [inputValueAdminFees, setInputValueAdminFees] = useState("");
  const [inputValueSetupFees, setInputValueSetupFees] = useState("");
  const [inputValueDeliveryFees, setInputValueDeliveryFees] = useState("");
  const [inputValueAdditionalFees, setInputValueAdditionalFees] = useState("");
  const [inputValueFirstCurtailment, setInputValueFirstCurtailment] =
    useState("");
  const [inputValueSecondCurtailment, setInputValueSecondCurtailment] =
    useState("");
  const [inputValueThirdCurtailment, setInputValueThirdCurtailment] =
    useState("");
  const [inputValueForthCurtailment, setInputValueForthCurtailment] =
    useState("");
  // ----new floor fees------------------
  const [inputValuePurchasePrice, setInputValuePurchasePrice] = useState("");
  const [inputValueVpnInsurance, setInputValueVpnInsurance] = useState("");
  const [inputValueFloorFees, setInputValueFloorFees] = useState("");
  const [inputValueDocumentProcessing, setInputValueDocumentProcessing] =
    useState("");
  const [inputValueDeleveryFees, setInputValueDeleveryFees] = useState("");
  const [inputValueTotalFeesInterest, setInputValueTotalFeesInterest] =
    useState("");
  const [
    inputValueTotalFeesInterestPurchase,
    setInputValueTotalFeesInterestPurchase,
  ] = useState("");
  // ------------------------
  const [objectsFloor, setObjectsFloor] = useState([]);
  const [alertVisible, setAlertVisible] = useState(false);
  // repare cost------------------------------------------------------------
  const [objects, setObjects] = useState([]);
  const [inputValueDate, setInputValueDate] = useState("");
  const [inputValueAmount, setInputValueAmount] = useState("");
  const [inputValueDescription, setInputValueDescription] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [divBackgroundColor, setDivBackgroundColor] = useState("");
  const [highlightedRow, setHighlightedRow] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  // Footer advertising manager---------------------------
  const [isCheckedfooter, setIsCheckedfooter] = useState(true);
  const [isCheckedfooterb, setIsCheckedfooterb] = useState(true);
  const [isCheckedfooterc, setIsCheckedfooterc] = useState(false);
  const [isCheckedfooterd, setIsCheckedfooterd] = useState(false);
  const [isCheckedfootere, setIsCheckedfootere] = useState(false);
  const [isCheckedfooterf, setIsCheckedfooterf] = useState(false);
  const [isCheckedfooterg, setIsCheckedfooterg] = useState(false);
  // Footer advertisi9ng manager end--------------------------
  // for inspection date-----------------------------------------
  // ---------------------new option arrow choose---------
  // Array of radio button options
  console.log("line120", apiDatab);

  let radioOptions;

  if (apiDatab && apiDatab.length > 0) {
    radioOptions = apiDatab.map((model) => ({
      value: model,
      label: model,
    }));
  } else {
    const defaultValue =
      apiDataEdit && apiDataEdit.trim ? apiDataEdit.trim : "";
    radioOptions = [{ value: defaultValue, label: defaultValue }];
  }

  console.log("line122", radioOptions);

  const radioOptionsb = [
    { value: "4X4", label: "4X4" },
    { value: "AWD", label: "AWD" },
    { value: "FWD", label: "FWD" },
    { value: "RWD", label: "RWD" },
  ];

  let radioOptionsc =
    apiDatac && apiDatac.length > 0
      ? apiDatac.map((model) => ({
          value: model,
          label: model,
        }))
      : [
          { value: "Manual", label: "Manual" },
          // { value: "Semi Automatic", label: "Semi Automatic" },
          { value: "Automatic", label: "Automatic" },
        ];

  const [radioOptionsd, setRadioOptionsd] = useState([
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
  ]);

  const radioOptionse = [
    { value: "Clothes", label: "Clothes" },
    { value: "Leather", label: "Leather" },
    { value: "Alcantara", label: "Alcantara" },
    { value: "Wood Trim", label: "Wood Trim" },
    { value: "Carbon Fiber", label: "Carbon Fiber" },
  ];
  const radioOptionsf = [
    { value: "40 Kwh", label: "40 Kwh" },
    { value: "62 Kwh", label: "62 Kwh" },
    { value: "72 Kwh", label: "72 Kwh" },
  ];
  const radioOptionsg = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
  ];

  const [radioOptionsh, setRadioOptionsh] = useState([
    { value: "Beige", label: "Beige", boxColor: "#F5F5DC" },
    { value: "Black", label: "Black", boxColor: "black" },
    { value: "Blue", label: "Blue", boxColor: "blue" },
    { value: "Brown", label: "Brown", boxColor: "brown" },
    { value: "Burgundy", label: "Burgundy", boxColor: "#800020" },
    { value: "Charcoal", label: "Charcoal", boxColor: "#36454F" },
    { value: "Gold", label: "Gold", boxColor: "#FFD700" },
    { value: "Grey", label: "Grey", boxColor: "#808080" },
    { value: "Green", label: "Green", boxColor: "#008000" },
    { value: "Maroon", label: "Maroon", boxColor: "#800000" },
    { value: "Orange", label: "Orange", boxColor: "orange" },
    { value: "Pink", label: "Pink", boxColor: "pink" },
    { value: "Red", label: "Red", boxColor: "red" },
    { value: "Silver", label: "Silver", boxColor: "#C0C0C0" },
    { value: "Tan", label: "Tan", boxColor: "#D2B48C" },
    { value: "White", label: "White", boxColor: "white" },
    { value: "Yellow", label: "Yellow", boxColor: "yellow" },
  ]);
  const [radioOptionsi, setRadioOptionsi] = useState([
    { value: "Beige", label: "Beige", boxColor: "#F5F5DC" },
    { value: "Black", label: "Black", boxColor: "black" },
    { value: "Blue", label: "Blue", boxColor: "blue" },
    { value: "Brown", label: "Brown", boxColor: "brown" },
    { value: "Burgundy", label: "Burgundy", boxColor: "#800020" },
    { value: "Charcoal", label: "Charcoal", boxColor: "#36454F" },
    { value: "Gold", label: "Gold", boxColor: "#FFD700" },
    { value: "Grey", label: "Grey", boxColor: "#808080" },
    { value: "Green", label: "Green", boxColor: "#008000" },
    { value: "Maroon", label: "Maroon", boxColor: "#800000" },
    { value: "Orange", label: "Orange", boxColor: "orange" },
    { value: "Pink", label: "Pink", boxColor: "pink" },
    { value: "Red", label: "Red", boxColor: "red" },
    { value: "Silver", label: "Silver", boxColor: "#C0C0C0" },
    { value: "Tan", label: "Tan", boxColor: "#D2B48C" },
    { value: "White", label: "White", boxColor: "white" },
    { value: "Yellow", label: "Yellow", boxColor: "yellow" },
  ]);
  // const state = usaStates.map(state => {
  //     return { optionDropdown: state.name };
  // });
  const tyreImage = [
    Image.greenTyre,
    Image.blueTyre,
    Image.yellowTyre,
    Image.redTyre,
  ];
  const defaultTyreImage = Image.tireCondition;

  const radioOptionsj = usaStates.map((state, index) => ({
    value: state.name,
    label: state.name,
  }));

  //console.log("line272",radioOptionsj);
  const radioOptionsk = [
    { value: "Clean", label: "Clean" },
    { value: "Rebuilt", label: "Rebuilt" },
    { value: "Salvage", label: "Slavage" },
    { value: "Structural Damage", label: "Structural Damage" },
    { value: "Total Loss", label: "Total Loss" },
    { value: "Lemon", label: "Lemon" },
    { value: "Bonded", label: "Bonded" },
    { value: "No Title", label: "No Title" },
  ];
  const radioOptionsl = [
    { value: "Actual Miles", label: "Actual Miles" },
    { value: "Unknown Miles", label: "Unknown Miles" },
    { value: "Not Sure", label: "Not Sure" },
  ];
  const radioOptionsm = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
  ];
  const radioOptionso = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];
  const radioOptionsp = [
    { value: "Run", label: "Run" },
    { value: "Starts", label: "Starts" },
    { value: "Won't Starts", label: "Won't Starts" },
  ];
  const radioOptionsq = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
    { value: "Not Sure", label: "Not Sure" },
  ];
  const radioOptionsr = [
    { value: "New 8/32>", label: "New 8/32>", image: tyreImage[0] },
    { value: "Good 6/32-7/32", label: "Good 6/32-7/32", image: tyreImage[1] },
    { value: "Fair <5/32", label: "Fair <5/32", image: tyreImage[2] },
    { value: "Fully-Used NA", label: "Fully Used NA", image: tyreImage[3] },
  ];
  const radioOptionss = [
    { value: "New 8/32>", label: "New 8/32>", image: tyreImage[0] },
    { value: "Good 6/32-7/32", label: "Good 6/32-7/32", image: tyreImage[1] },
    { value: "Fair <5/32", label: "Fair <5/32", image: tyreImage[2] },
    { value: "Fully-Used NA", label: "Fully Used NA", image: tyreImage[3] },
  ];
  const radioOptionst = [
    { value: "New 8/32>", label: "New 8/32>", image: tyreImage[0] },
    { value: "Good 6/32-7/32", label: "Good 6/32-7/32", image: tyreImage[1] },
    { value: "Fair <5/32", label: "Fair <5/32", image: tyreImage[2] },
    { value: "Fully-Used NA", label: "Fully-Used NA", image: tyreImage[3] },
  ];
  const radioOptionsu = [
    { value: "New 8/32>", label: "New 8/32>", image: tyreImage[0] },
    { value: "Good 6/32-7/32", label: "Good 6/32-7/32", image: tyreImage[1] },
    { value: "Fair <5/32", label: "Fair <5/32", image: tyreImage[2] },
    { value: "Fully-Used NA", label: "Fully Used NA", image: tyreImage[3] },
  ];
  const [radioOptionsv, setRadioOptionsv] = useState([
    { value: "Bodywork", label: "Bodywork" },
    { value: "Fuel", label: "Fuel" },
    { value: "Labor", label: "Labor" },
    { value: "Mechanic", label: "Mechanic" },
    { value: "Parts", label: "Parts" },
    { value: "Repair", label: "Repair" },
  ]);

  const [radioOptionsw, setRadioOptionsw] = useState([
    { value: "4 Wheel Parts", label: "4 Wheel Parts" },
    { value: "Acdelco", label: "Acdelco" },
    { value: "Advance Auto Parts", label: "Advance Auto Parts" },
    {
      value: "AutoZone",
      label: "AutoZone",
    },
    { value: "Carquest Auto Parts", label: "Carquest Auto Parts" },
    { value: "Dorman Products", label: "Dorman Products" },
    { value: "FleetPride", label: "FleetPride" },
    { value: "Interstate Batteries", label: "Interstate Batteries" },
    { value: "JC Whitney", label: "JC Whitney" },
    {
      value: "Keystone Automotive Industries",
      label: "Keystone Automotive Industries",
    },
    { value: "LKQ Corporation", label: "LKQ Corporation" },
    {
      value: "Motorcar Parts of America (MPA)",
      label: "Motorcar Parts of America (MPA)",
    },
    {
      value: "NAPA Auto Parts (Genuine Parts Company)",
      label: "NAPA Auto Parts (Genuine Parts Company)",
    },
    {
      value: "O'Reilly Auto Parts",
      label: "O'Reilly Auto Parts",
    },
    { value: "Pep Boys", label: "Pep Boys" },
    { value: "RockAuto", label: "RockAuto" },
    {
      value: "Standard Motor Products (SMP)",
      label: "Standard Motor Products (SMP)",
    },
    // {
    //   value: "Summit Racing Equipment",
    //   label: "Summit Racing Equipment",
    // },
    {
      value: "U.S. Auto Parts Network (CarParts.com)",
      label: "U.S. Auto Parts Network (CarParts.com)",
    },
    {
      value: "Worldpac",
      label: "Worldpac",
    },
  ]);

  const radioOptionsx = [
    { value: "Cash", label: "Cash" },
    { value: "Cheque", label: "Cheque" },
    { value: "Wire", label: "Wire" },
    { value: "Cards", label: "Cards" },
    { value: "Trade", label: "Trade" },
  ];
  const radioOptionsy = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
  ];
  const radioOptionsz = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];

  const radioOptionsza = usaStates.map((state, index) => ({
    value: state.name,
    label: state.name,
  }));
  // const radioOptionsza = [
  //   { value: "1", label: "1" },
  //   { value: "2", label: "2" },
  //   { value: "3", label: "3" },
  //   { value: "4", label: "4" },
  // ];
  const radioOptionszb = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
  ];
  // ---------------------------------------------------------------------
  const [searchOptionState, setSearchOptionState] = useState("");

  // dropdown--------------------------------------------------------------------------------------------------
  const [show_condition, setShowCondition] = useState(false);
  const stateNames = usaStates.map((state) => state.name);
  // console.log(stateNames);
  const condition = [{ optionDropdown: "New" }, { optionDropdown: "Used" }];

  const [showNewVehicleForm, setShowNewvehicleForm] = useState(false);
  const [newVehicleInput, setNewVehicleInput] = useState("");
  const [newVehicleItem, setNewVehicleItem] = useState([]);
  const [dynamicID, setDynamicID] = useState([]);
  const [liveSeaching, setLiveSearching] = useState(false);
  console.log(liveSeaching);
  const [VehicleOption, setVehicleOption] = useState([]);
  // ----------------------------------------------------------------------
  const [inspection, setInspection] = useState("");
  const [purchaseDate, setPurchaseDate] = useState("");
  const [selectedImages, setSelectedImages] = useState({
    tyrecondition_fl: "",
    tyrecondition_bl: "",
    tyrecondition_fr: "",
    tyrecondition_br: "",
  });
  //formdata start ------------------------------------------------------------------

  const [formData, setFormData] = useState({
    id: 0,
    userId: dealerId,
    vin: "",
    year_make_model: "",
    trim: "",
    engine: "",
    transmission: "",
    vehicleType: "",
    // make year modal only check purpose----
    make: "",
    year: "",
    model: "",
    // --------------------------------
    seat_count: "",
    city_mpg: "",
    highway_mpg: "",
    inspection: "",
    exteriorColor: "",
    interiorColor: "",
    interiorType: "",
    mileage_in: "",
    //tradeInValue: formData.tradeInValue,
    purchase_price: "",
    listing_price: "",
    // actualValue: "",
  });

  const [displayValues, setDisplayValues] = useState({
    mileage_in: "",
    purchase_price: "", // Add other numeric fields as needed
    listing_price: "",
    others: "",
    market_value_price: "",
  });
  // Initialize formData and objects from apiDataEdit
  useEffect(() => {
    if (apiDataEdit) {
      console.log("apiDataEdit", apiDataEdit);
      const formatDate = (date) => {
        if (!date) return "";
        const [month, day, year] = date.split("/");
        return `${year}-${month}-${day}`;
      };
      // Set formData with specific fields
      setFormData((prevState) => ({
        ...prevState,
        id: apiDataEdit.id || "",
        // only use for backend-----------
        make: apiDataEdit?.Make || "",
        year: apiDataEdit?.ModelYear || "",
        model: apiDataEdit?.Model || "",
        bodyClass: apiDataEdit?.BodyClass || "",
        wheelbase: apiDataEdit?.WheelBaseShort || "",
        driveType: apiDataEdit?.DriveType || "",
        doorCount: apiDataEdit?.Doors || "",
        // --------------------------
        engine: apiDataEdit.engine || "",
        status: apiDataEdit.status || "",
        inspection: formatDate(apiDataEdit.inspection) || "",
        price_fee_date: apiDataEdit.price_fee_date || "",
        city_mpg: apiDataEdit.city_mpg || "",
        highway_mpg: apiDataEdit.highway_mpg || "",
        year_make_model: apiDataEdit.year_Make_Model || "",
        trim: apiDataEdit.trim || "",
        vehicleType: apiDataEdit.vehicleType || "",
        transmission: apiDataEdit.transmission || "",
        seat_count: apiDataEdit.seat_count || "",
        interiorType: apiDataEdit.interiorType || "",
        keys_count: apiDataEdit.keys_count || "",
        battery_kwh: apiDataEdit.battery_kwh || "",
        battery_type: apiDataEdit.battery_type || "",
        // mileage_in: apiDataEdit.mileage_in || "",
        // mileage_read: apiDataEdit.mileage_read || "",
        mileage_in: parseFloat(apiDataEdit.mileage_in || 0.0),
        purchase_price: parseFloat(apiDataEdit.purchase_price || 0.0),
        listing_price: parseFloat(apiDataEdit.listing_price || 0.0),
        others: parseFloat(apiDataEdit.others || 0.0),
        market_value_price: parseFloat(apiDataEdit.market_value_price || 0.0),
        purchasedDate: apiDataEdit.purchasedDate || "",
        exteriorColor: apiDataEdit.exteriorColor || "",
        interiorColor: apiDataEdit.interiorColor || "",
        other_restraint: apiDataEdit.other_restraint || "",
        // inspectionDate: apiDataEdit.inspection || "",
        //purchase_price: apiDataEdit.purchase_price || '',
        //listing_price: apiDataEdit.listing_price || "",
        //Equipment and Option----------
        adaptive_cruise_control: apiDataEdit.adaptive_cruise_control || "",
        key_less_entry: apiDataEdit.key_less_entry || "",
        side_airbags: apiDataEdit.side_airbags || "",
        air_conditioning: apiDataEdit.air_conditioning || "",
        key_less_go: apiDataEdit.key_less_go || "",
        side_steps_running_boards: apiDataEdit.side_steps_running_boards || "",
        all_wheels_drive: apiDataEdit.all_wheels_drive || "",
        leather_interior_surface: apiDataEdit.leather_interior_surface || "",
        s_line: apiDataEdit.s_line || "",
        amg_pack: apiDataEdit.amg_pack || "",
        leather_seats: apiDataEdit.leather_seats || "",
        soft_close_doors: apiDataEdit.soft_close_doors || "",
        anti_lock_brake_system: apiDataEdit.anti_lock_brake_system || "",
        leather_steering_wheel: apiDataEdit.leather_steering_wheel || "",
        sport_pack: apiDataEdit.sport_pack || "",
        auto_climate_control: apiDataEdit.auto_climate_control || "",

        // Seller Discription---------------------------------
        state: apiDataEdit.state || "",
        //status:apiDataEdit.status || '',
        odo: apiDataEdit.odo || "",
        owner: apiDataEdit.owner || "",
        exterior_damage: apiDataEdit.exterior_damage || "",
        smoked_in: apiDataEdit.smoked_in || "",
        tyrecondition_fl: apiDataEdit.tyrecondition_fl || "",
        tyrecondition_fr: apiDataEdit.tyrecondition_fr || "",
        tyrecondition_bl: apiDataEdit.tyrecondition_bl || "",
        tyrecondition_br: apiDataEdit.tyrecondition_br || "",
        seller_description: apiDataEdit.seller_description || "",
        // Title info-----------------------------
        title_type: apiDataEdit.title_type || "",
        title_location: apiDataEdit.title_location || "",
        title_number: apiDataEdit.title_number || "",
        title_recieved: apiDataEdit.title_recieved || "",
        title_status: apiDataEdit.title_status || "",
        // finance ------------------------------------------
        total_cost: apiDataEdit.total_cost || "",
        total_repair_cost: apiDataEdit.total_repair_cost || "",
        total_repair_cost: apiDataEdit.total_repair_cost || "",
        //others: apiDataEdit.others || "",
        // others: apiDataEdit.others || "",
        profit_price: apiDataEdit.profit_price || "",
        auction_fees: apiDataEdit.auction_fees || "",
        // advertising manager----------------------------------------------
        addvertising: {
          dacmp_status: apiDataEdit.addvertising.dacmp_status || false,
          dacmp_interval: apiDataEdit.addvertising.dacmp_interval || "",
          unitedmotors_status:
            apiDataEdit.addvertising.unitedmotors_status || false,
          unitedmotors_interval:
            apiDataEdit.addvertising.unitedmotors_interval || "",
          google_status: apiDataEdit.addvertising.google_status || false,
          google_interval: apiDataEdit.addvertising.google_interval || "",
          insta_status: apiDataEdit.addvertising.insta_status || false,
          insta_interval: apiDataEdit.addvertising.insta_interval || "",
          facebook_status: apiDataEdit.addvertising.facebook_status || false,
          facebook_interval: apiDataEdit.addvertising.facebook_interval || "",
          craigslist_status:
            apiDataEdit.addvertising.craigslist_status || false,
          craigslist_interval:
            apiDataEdit.addvertising.craigslist_interval || "",
          twitter_status: apiDataEdit.addvertising.twitter_status || false,
          twitter_interval: apiDataEdit.addvertising.twitter_interval || "",
        },
        //repaire cost---------------------------------------------------
        repair_cost: apiDataEdit.repair_cost || [],
      }));

      // Find the boxColor for the exterior color from apiDataEdit
      const exteriorColor = radioOptionsh.find(
        (obj) => obj.label === apiDataEdit.exteriorColor
      )?.boxColor;

      if (exteriorColor) {
        setExteriorColorShow(exteriorColor);
      }
      // Find the boxColor for the exterior color from apiDataEdit
      const interiorColor = radioOptionsi.find(
        (obj) => obj.label === apiDataEdit.interiorColor
      )?.boxColor;

      if (interiorColor) {
        setInteriorColorShow(interiorColor);
      }
      // Update displayValues with formatted data from the API
      setDisplayValues((prevState) => ({
        ...prevState,
        mileage_in: formatValue(apiDataEdit.mileage_in?.toString() || "0.0"),
        purchase_price: formatValue(
          apiDataEdit.purchase_price?.toString() || "0.0"
        ),
        listing_price: formatValue(
          apiDataEdit.listing_price?.toString() || "0.0"
        ),
        others: formatValue(apiDataEdit.others?.toString() || "0.0"),
        market_value_price: formatValue(
          apiDataEdit.market_value_price?.toString() || "0.0"
        ),
      }));
      setIsCheckedfooter(apiDataEdit.addvertising.dacmp_status || false);
      setIsCheckedfooterb(
        apiDataEdit.addvertising.unitedmotors_status || false
      );
      setIsCheckedfooterc(apiDataEdit.addvertising.google_status || false);
      setIsCheckedfooterd(apiDataEdit.addvertising.insta_status || false);
      setIsCheckedfootere(apiDataEdit.addvertising.facebook_status || false);
      setIsCheckedfooterf(apiDataEdit.addvertising.craigslist_status || false);
      setIsCheckedfooterg(apiDataEdit.addvertising.twitter_status || false);
      // Select the first item in repair_cost array (assuming there is at least one)
      const firstItem =
        apiDataEdit.repair_cost && apiDataEdit.repair_cost.length > 0
          ? apiDataEdit.repair_cost[0]
          : {};

      // Set fields based on first item
      setInputValueDate(firstItem.date || ""); // Set date from the first item
      setSelectedOptionv(firstItem.category || ""); // Set category from the first item
      setSelectedOptionw(firstItem.payee_vendor || ""); // Set payee_vendor from the first item
      setSelectedOptionx(firstItem.payment_method || ""); // Set price_payment_method from the first item
      setInputValueAmount(firstItem.amount ? firstItem.amount.toString() : ""); // Set amount from the first item
      setInputValueDescription(firstItem.description || ""); // Set description from the first item

      // Set objects with repair_cost array
      // setObjects(apiDataEdit.repair_cost || []);
      setObjects(apiDataEdit.repair_cost || []);

      const newTotalAmount = (apiDataEdit.repair_cost || []).reduce(
        (acc, curr) => acc + curr.amount,
        0
      );
      setTotalAmount(newTotalAmount);
    }
  }, [apiDataEdit]);
  console.log("TradeInComponent Data 850", formData);
  const SavedataRedux = () => {
    dispatch(
      setRetailData({
        id: 0,
        dealerId: formData.id,
        vin: formData.vin,
        year_make_model: formData.year_make_model,
        trim: formData.trim,
        // maek year modal for check purpose only------
        make: formData.make,
        year: formData.year,
        model: formData.model,
        bodyClass: formData.bodyClass,
        wheelbase: formData.wheelbase,
        driveType: formData.driveType,
        doorCount: formData.doorCount,
        purchase_price: formData.purchase_price,
        // ----------------------------------------
        engine: formData.engine,
        transmission: formData.transmission,
        vehicleType: formData.vehicleType,
        seat_count: formData.seat_count,
        city_mpg: formData.city_mpg,
        highway_mpg: formData.highway_mpg,
        inspection: formData.inspection,
        exteriorColor: formData.exteriorColor,
        interiorColor: formData.interiorColor,
        interiorType: formData.interiorType,
        mileage_in: formData.mileage_in,
        //tradeInValue: formData.tradeInValue,
        listing_price: formData.listing_price,
      })
    );
    handleClose();
    onButtonClick();
    // handleResetVehicleSpecfication();
    setIsSpanVisibleTradeIn(true);
  };
  //
  // formdata end---------------------------------------------------------------------
  useEffect(() => {
    console.log("typeof setIsClicked:", typeof setIsClicked); // Check if it's a function
    if (TaxFeesPopData) {
      console.log("purchase_price value:", TaxFeesPopData?.purchase_price); // Log the value

      setFormData((prevData) => ({
        ...prevData,
        vin: TaxFeesPopData.vin || "",
        year_make_model: TaxFeesPopData.year_make_model || "",
        trim: TaxFeesPopData.trim || "",
        make: TaxFeesPopData.make || "",
        year: TaxFeesPopData.year || "",
        model: TaxFeesPopData.model || "",
        bodyClass: TaxFeesPopData.bodyClass || "",
        wheelbase: TaxFeesPopData.wheelbase || "",
        driveType: TaxFeesPopData.driveType || "",
        doorCount: TaxFeesPopData.doorCount || "",
        engine: TaxFeesPopData.engine || "",
        transmission: TaxFeesPopData.transmission || "",
        vehicleType: TaxFeesPopData.vehicleType || "",
        seat_count: TaxFeesPopData.seat_count || "",
        city_mpg: TaxFeesPopData.city_mpg || "",
        highway_mpg: TaxFeesPopData.highway_mpg || "",
        inspection: TaxFeesPopData.inspection || "",
        exteriorColor: TaxFeesPopData.exteriorColor || "",
        interiorColor: TaxFeesPopData.interiorColor || "",
        interiorType: TaxFeesPopData.interiorType || "",
      }));

      setDisplayValues((prevState) => ({
        ...prevState,
        mileage_in: formatValue(TaxFeesPopData?.mileage_in?.toString() ?? ''),
        purchase_price: formatValue(
          TaxFeesPopData?.purchase_price?.toString() ?? ''
        ),
        listing_price: formatValue(
          TaxFeesPopData?.listing_price?.toString() ?? ''
        ),
      }));

      // if (TaxFeesPopData.purchase_price) {
      //   setIsClicked(true);
      //   setIsSpanVisibleTradeIn(true);
      // } else if (TaxFeesPopData.purchase_price === "") {
      //   setIsClicked(false);
      //   setIsSpanVisibleTradeIn(false);
      // }
    }
  }, [TaxFeesPopData]);
//   for sold update data-------------------------------------------------------------------------
useEffect(() => {
  if (dealerRetailDtata && status) {
     const yeraMakeModel = dealerRetailDtata?.year_make_model?.replace(/\/#/g, '')
    .replace(/\//g, ' ')
    .replace(/#/g, ' ');
    setFormData((prevData) => ({
      ...prevData,
      vin: dealerRetailDtata.vin || "",
      year_make_model: yeraMakeModel || "",
      trim: dealerRetailDtata.trim || "",
      make: dealerRetailDtata.make || "",
      year: dealerRetailDtata.year || "",
      model: dealerRetailDtata.model || "",
      bodyClass: dealerRetailDtata.bodyClass || "",
      wheelbase: dealerRetailDtata.wheelbase || "",
      driveType: dealerRetailDtata.driveType || "",
      doorCount: dealerRetailDtata.doorCount || "",
      engine: dealerRetailDtata.engine || "",
      transmission: dealerRetailDtata.transmission || "",
      vehicleType: dealerRetailDtata.vehicleType || "",
      seat_count: dealerRetailDtata.seat_count || "",
      city_mpg: dealerRetailDtata.city_mpg || "",
      highway_mpg: dealerRetailDtata.highway_mpg || "",
      inspection: dealerRetailDtata.inspection || "",
      exteriorColor: dealerRetailDtata.exteriorColor || "",
      interiorColor: dealerRetailDtata.interiorColor || "",
      interiorType: dealerRetailDtata.interiorType || "",
    }));

    setDisplayValues((prevState) => ({
      ...prevState,
      mileage_in: formatValue(dealerRetailDtata?.mileage_in?.toString() ?? ''),
      purchase_price: formatValue(
        dealerRetailDtata?.purchase_price?.toString() ?? ''
      ),
      listing_price: formatValue(
        dealerRetailDtata?.listing_price?.toString() ?? ''
      ),
    }));
  }
}, [dealerRetailDtata]);
  // new popo input ------------------------------------------------
  const handleArrowClick = () => {
    setShowModal(true);
  };

  useEffect(() => {
    // Access vin from state
    const { vin } = location.state || {};
    console.log("VIN:", vin);
    if (vin) {
      // setAlertVisible(true);
      setFormData((prevData) => ({
        ...prevData,
        vin: vin,
      }));
      setInputVin(vin); // Initialize input value
    }
    console.log("8888888888888888888", location.state);
  }, [location.state]);

  // useEffect to call handleRetry when formData.vin is updated and valid
  // useEffect(() => {
  //   if (location.state && formData.vin.length === 17) {
  //     handleRetry(formData.vin);
  //   }
  // }, [formData.vin]); // Add formData.vin as dependency

  const handleDecodeActive = (vin) => {
    // Check if the input has exactly 17 characters
    if (vin.length === 17) {
      setFormData((prevData) => ({
        ...prevData,
        vin: vin,
      }));
      // handleRetry will be called by the useEffect when formData.vin changes
    }
  };

  const handleOptionSelect = (option) => {
    setSelectedOptionn(option);
    setFormData((prevState) => ({
      ...prevState,
      trim: option,
    }));
    setShowModal(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  // ----
  const handleArrowClickb = () => {
    setShowModalb(true);
  };

  const handleOptionSelectb = (option) => {
    setSelectedOptionb(option);
  };

  const handleCloseModalb = () => {
    setShowModalb(false);
  };

  const handleContinueb = () => {
    setFormData((prevState) => ({
      ...prevState,
      drive_train: selectedOptionb,
    }));
    setShowModalb(false);
  };
  // -------
  // Function to handle clicking the arrow, shows the modal
  const handleArrowClickc = () => {
    setShowModalc(true);
  };

  // Function to handle selecting an option, sets the selected option, updates the form data, and closes the modal
  const handleOptionSelectc = (option) => {
    setSelectedOptionc(option);
    setFormData((prevState) => ({
      ...prevState,
      transmission: option,
    }));
    setShowModalc(false);
  };
  // Effect to call transmissiondecode whenever the transmission in formData changes
  // useEffect(() => {
  //   if (formData.transmission) {
  //     transmissiondecode();
  //   }
  // }, [formData.transmission]);

  // Function to handle closing the modal
  const handleCloseModalc = () => {
    setShowModalc(false);
  };
  // ---

  const [newOptionseats, setNewOptionseats] = useState("");

  const handleArrowClickd = () => {
    setShowModald(true);
  };

  // Function to handle selecting an option, sets the selected option, updates the form data, and closes the modal
  const handleOptionSelectd = (option) => {
    setSelectedOptiond(option);
    setFormData((prevState) => ({
      ...prevState,
      seat_count: option,
    }));
    setShowModald(false);
  };

  const handleCloseModald = () => {
    setShowModald(false);
  };

  // Handler for adding the new option
  const handleAddOptionseats = () => {
    if (newOptionseats.trim() !== "") {
      const newOptionObj = { value: newOptionseats, label: newOptionseats };
      setRadioOptionsd([...radioOptionsd, newOptionObj]);
      setNewOptionseats("");
      handleOptionSelectd(newOptionseats);
    }
  };

  //----
  const handleArrowClicke = () => {
    setShowModale(true);
  };

  const handleOptionSelecte = (option) => {
    setSelectedOptione(option);
    setFormData((prevState) => ({
      ...prevState,
      interiorType: option,
    }));
    setShowModale(false);
  };

  const handleCloseModale = () => {
    setShowModale(false);
  };

  const [newOptionExteriorColor, setNewOptionExteriorColor] = useState("");

  const handleArrowClickh = () => {
    setShowModalh(true);
  };

  // Function to handle selecting an option, sets the selected option, updates the form data, and closes the modal
  const handleOptionSelecth = (option, color) => {
    setSelectedOptionh(option);
    setFormData((prevState) => ({
      ...prevState,
      exteriorColor: option,
    }));
    setExteriorColorShow(color);
    setShowModalh(false);
  };

  const handleCloseModalh = () => {
    setShowModalh(false);
  };

  // Handler for adding the new option
  const handleAddOptionExteriorColor = () => {
    if (newOptionExteriorColor.trim() !== "") {
      const newColor = "#CCCCCC"; // Default color for new entries
      const newOptionObj = {
        value: newOptionExteriorColor,
        label: newOptionExteriorColor,
        boxColor: newColor,
      };
      setRadioOptionsh([...radioOptionsh, newOptionObj]);
      setNewOptionExteriorColor("");
      handleOptionSelecth(newOptionExteriorColor, newColor);
    }
  };
  //----

  const [newOptionInteriorColor, setNewOptionInteriorColor] = useState("");

  const handleArrowClicki = () => {
    setShowModali(true);
  };

  // Function to handle selecting an option, sets the selected option, updates the form data, and closes the modal
  const handleOptionSelecti = (option, color) => {
    setSelectedOptioni(option);
    setFormData((prevState) => ({
      ...prevState,
      interiorColor: option,
    }));
    setInteriorColorShow(color);
    setShowModali(false);
  };
  const handleCloseModali = () => {
    setShowModali(false);
  };
  // // Handler for adding the new option
  // const handleAddOptionInteriorColor = () => {
  //   if (newOptionInteriorColor.trim() !== "") {
  //     const newOptionObj = { value: newOptionInteriorColor, label: newOptionInteriorColor };
  //     setRadioOptionsi([...radioOptionsi, newOptionObj]);
  //     setNewOptionInteriorColor("");
  //     handleOptionSelecti(newOptionInteriorColor);
  //   }
  // };

  // Handler for adding the new option
  const handleAddOptionInteriorColor = () => {
    if (newOptionInteriorColor.trim() !== "") {
      const newColor = "#CCCCCC"; // Default color for new entries
      const newOptionObj = {
        value: newOptionInteriorColor,
        label: newOptionInteriorColor,
        boxColor: newColor,
      };
      setRadioOptionsi([...radioOptionsi, newOptionObj]);
      setNewOptionInteriorColor("");
      handleOptionSelecti(newOptionInteriorColor, newColor);
    }
  };
  //----
  const [searchTerm, setSearchTerm] = useState("");
  const [newOptionsupplier, setNewOptionsupplier] = useState("");
  const handleArrowClickw = () => {
    setShowModalw(true);
  };

  const handleOptionSelectw = (option) => {
    setSelectedOptionw(option);
    setFormData((prevState) => ({
      ...prevState,
      payee_vendor: option,
    }));
    setShowModalw(false);
  };

  //----
  const handleArrowClickzb = () => {
    setShowModalzb(true);
  };
// for calculator on amount in maintanance section---------------------
const [showModalCalculator, setShowModalCalculator] = useState(false);
const [showModalCalculatorListing, setShowModalCalculatorListing] =
  useState(false);
const [showModalCalculatorPurchase, setShowModalCalculatorPurchase] =
  useState(false);
const [showModalCalculatorMileage, setShowModalCalculatorMileage] =
  useState(false);
const handleArrowClickCalculator = () => {
  setShowModalCalculator(true);
};
const handleArrowClickCalculatorListing = () => {
  setShowModalCalculatorListing(true);
};
const handleArrowClickCalculatorPurchase = () => {
  setShowModalCalculatorPurchase(true);
};
const handleArrowClickCalculatorMileage = () => {
  setShowModalCalculatorMileage(true);
};

const handleCloseModalCalculator = () => {
  setShowModalCalculator(false);
};
const handleCloseModalCalculatorListing = () => {
  setShowModalCalculatorListing(false);
};
const handleCloseModalCalculatorPurchase = () => {
  setShowModalCalculatorPurchase(false);
};
const handleCloseModalCalculatorMileage = () => {
  setShowModalCalculatorMileage(false);
};
// --------------------------------------------------------------------
  // const handleOptionSelectzb = (option) => {
  //   setSelectedOptionzb(option);
  // };
  const handleOptionSelectzb = (option) => {
    setSelectedOptionzb(option);
    setFormData((prevState) => ({
      ...prevState,
      vehicleType: option,
    }));
    setShowModalzb(false);
  };

  const handleCloseModalzb = () => {
    setShowModalzb(false);
  };

  // const handleContinuezb = () => {
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     vehicle_type: selectedOptionzb,
  //   }));
  //   setShowModalzb(false);
  // };
  // title info for select image and pdf file-------------------------------------------------------------------------------------

  // for location------------------------------------------and ip-----****************************-----------------------------
  useEffect(() => {
    const fetchUserLocation = async () => {
      try {
        const res = await fetchGeoMapData();

        // Update formData only after geoData has been successfully fetched and set
        setFormData((prevFormData) => ({
          ...prevFormData,
          lattitude: res.coordinates.lat,
          longitude: res.coordinates.lng,
        }));
      } catch (error) {
        console.log("Server side error", error);
      }
    };

    fetchUserLocation();
  }, []);

  // for ip address--------------------------------
  // Function to fetch IP address
  const getUserIPaddress = async () => {
    try {
      const res = await axios.get("https://api.ipify.org/?format=json");
      console.log("User IP Address", res.data.ip);
      // Update formData with the IP address
      setFormData((prevFormData) => ({
        ...prevFormData,
        ip: res.data.ip,
      }));
    } catch (error) {
      console.error("Error fetching IP address:", error);
    }
  };
  // Fetch IP address on component mount
  useEffect(() => {
    getUserIPaddress();
  }, []);

  // end-------------------------*********************************--------------------------------------------------

  useEffect(() => {
    // Set the default purchase date to the current date
    const today = new Date().toISOString().split("T")[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      purchasedDate: today,
      inspection: today,
      price_fee_date: today,
    }));
  }, [counter]);

  useEffect(() => {
    // Set the default date to today's date in YYYY-MM-DD format
    const today = new Date().toISOString().split("T")[0];
    setInputValueDate(today);
  }, [counter]);

  const chunkSize = 6;
  const objectChunks = [];
  for (let i = 0; i < objects.length || i < chunkSize; i += chunkSize) {
    objectChunks.push(objects.slice(i, i + chunkSize));
  }

  // for scroll bar-----------------------
  const handleKeyDown = (event) => {
    if (scrollContainerRef.current) {
      if (event.key === "ArrowDown") {
        scrollContainerRef.current.scrollTo({
          top: scrollContainerRef.current.scrollHeight,
          behavior: "smooth",
        });
      } else if (event.key === "ArrowUp") {
        scrollContainerRef.current.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  // for tabs-----------------------------

  const handleNextButtonClick = () => {
    const tabKeys = [
      "tab1",
      "tab2",
      "tab3",
      "tab4",
      "tab5",
      "tab6",
      "tab7",
      "tab8",
    ];
    const currentIndex = tabKeys.indexOf(activeTab);

    if (currentIndex < tabKeys.length - 1) {
      const nextTab = tabKeys[currentIndex + 1];
      setActiveTab(nextTab);
    }
  };

  const handleBackButtonClick = () => {
    const tabKeys = ["tab1", "tab2", "tab3", "tab4", "tab5", "tab6", "tab7"];
    const currentIndex = tabKeys.indexOf(activeTab);

    if (currentIndex > 0) {
      const prevTab = tabKeys[currentIndex - 1];
      setActiveTab(prevTab);
    }
  };

  // -----------------------------------------------------------------------**********************************-------------
  // inspection data--------------------------------------------------
  // Function to handle inspection date change
  const handleChangeins = (e) => {
    const selectedDate = e.target.value;
    setInspection(selectedDate);
    setFormData((prevFormData) => ({
      ...prevFormData,
      inspection: selectedDate,
    }));
  };
  const handleChangepurchaseDate = (e) => {
    const selectedDate = e.target.value;
    setPurchaseDate(selectedDate);
    setFormData((prevFormData) => ({
      ...prevFormData,
      purchasedDate: selectedDate,
    }));
  };
  // Function to calculate days difference between current date and selected inspection date
  const calculateDaysDifference = () => {
    if (!formData.inspection) return 0; // Ensure formData.inspection is used for calculation
    const currentDate = new Date();
    const selectedDate = new Date(formData.inspection);
    const timeDifference = selectedDate.getTime() - currentDate.getTime();
    const daysDifference = Math.ceil(
      Math.abs(timeDifference) / (1000 * 60 * 60 * 24)
    );
    return daysDifference;
  };

  const calculateDaysDifferencepurchase = () => {
    if (!formData.purchasedDate) return 0; // Ensure formData.inspection is used for calculation
    const currentDate = new Date();
    const selectedDate = new Date(formData.purchasedDate);
    const timeDifference = selectedDate.getTime() - currentDate.getTime();
    const daysDifference = Math.ceil(
      Math.abs(timeDifference) / (1000 * 60 * 60 * 24)
    );
    return daysDifference;
  };
  // Repair and cost(Maintanance)--------------------------------------***************************************------------------------
  const handleSave = () => {
    setDivBackgroundColor("white"); //set color as you want......
    const amount = parseFloat(inputValueAmount);
    if (isNaN(amount)) return; // Exit if amount is not a number

    const newObj = {
      date: inputValueDate,
      category: selectedOptionv,
      payee_vendor: selectedOptionw,
      amount: amount,
      payment_method: selectedOptionx,
      description: inputValueDescription,
    };
    const newObjects = [...objects, newObj];
    setObjects(newObjects);
    setInputValueDate("");
    setSelectedOptionv("");
    setSelectedOptionw("");
    setSelectedOptionx("");
    setInputValueAmount("");
    setInputValueDescription("");

    const newTotalAmount = newObjects.reduce(
      (acc, curr) => acc + curr.amount,
      0
    );
    setTotalAmount(newTotalAmount);
  };

  const handleRowSelect = (index) => {
    if (selectedRows.includes(index)) {
      setSelectedRows(selectedRows.filter((i) => i !== index));
    } else {
      setSelectedRows([...selectedRows, index]);
    }
  };
  // -------------------------------------------------------------------------------------
  const handleUpdate = () => {
    setDivBackgroundColor("white"); //set color as you want......
    const amount = parseFloat(inputValueAmount);
    if (isNaN(amount)) return; // Exit if amount is not a number

    const updatedObj = {
      id: objects[editIndex].id, // Retain the id of the object
      date: inputValueDate,
      category: selectedOptionv,
      payee_vendor: selectedOptionw,
      amount: amount,
      payment_method: selectedOptionx,
      description: inputValueDescription,
    };

    const newObjects = objects.map((obj, index) =>
      index === editIndex ? updatedObj : obj
    );
    setObjects(newObjects);
    resetForm();

    const newTotalAmount = newObjects.reduce(
      (acc, curr) => acc + curr.amount,
      0
    );
    setTotalAmount(newTotalAmount);
    setIsEditing(false);
    setEditIndex(null);
  };

  const resetForm = () => {
    setInputValueDate("");
    setSelectedOptionv("");
    setSelectedOptionw("");
    setSelectedOptionx("");
    setInputValueAmount("");
    setInputValueDescription("");
  };

  const handleDelete = () => {
    const newObjects = objects.filter(
      (_, index) => !selectedRows.includes(index)
    );
    setObjects(newObjects);
    setSelectedRows([]);
    // const newTotalAmount = newObjects.reduce(
    //   (acc, curr) => acc + curr.amount,
    //   0
    // );
    const newTotalAmount = newObjects.reduce(
      (acc, curr) => acc + curr.amount,
      0
    );
    setTotalAmount(newTotalAmount);
  };

  const handleRowDoubleClick = (index) => {
    const row = objects[index];
    setInputValueDate(row.date);
    setSelectedOptionv(row.category);
    setSelectedOptionw(row.payee_vendor);
    setSelectedOptionx(row.payment_method);
    setInputValueAmount(row.amount.toString());
    setInputValueDescription(row.description);
    setHighlightedRow(index);
    setIsEditing(true);
    setEditIndex(index);
    setDivBackgroundColor("#fce8cd"); //set background color as you want......
  };

  // --------------------------------******************************************************---------------------------------------------------
  const [masked, setMasked] = useState(false);
  const inputRef = useRef(null);
  useEffect(() => {
    // Reset masking state if formData changes
    if (masked) {
      setMasked(false);
    }
  }, [formData.purchase_price]);
  const getMaskedValue = (value) => {
    const length = value.length;
    if (length <= 0) return value;

    if (masked) {
      return "X".repeat(length);
    } else {
      return "X".repeat(length - 1) + value[length - 1];
      // return value; // Return the full value if not masked
    }
  };
  // milage in toggle eye-------------------
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isPasswordVisibleb, setIsPasswordVisibleb] = useState(false);
  const [isPasswordVisiblec, setIsPasswordVisiblec] = useState(false);
  const [isPasswordVisibled, setIsPasswordVisibled] = useState(false);
  const [isPasswordVisiblee, setIsPasswordVisiblee] = useState(false);
  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };
  const togglePasswordVisibilityb = () => {
    setIsPasswordVisibleb(!isPasswordVisibleb);
  };
  const togglePasswordVisibilityc = () => {
    setIsPasswordVisiblec(!isPasswordVisiblec);
  };
  const togglePasswordVisibilityd = () => {
    setIsPasswordVisibled(!isPasswordVisibled);
  };

  const handleMouseDown = () => {
    setIsPasswordVisiblee(true);
  };
  const handleMouseUp = () => {
    setIsPasswordVisiblee(false);
  };

  const handleMouseDownb = () => {
    setIsPasswordVisible(true);
  };
  const handleMouseUpb = () => {
    setIsPasswordVisible(false);
  };

  const handleMouseDownc = () => {
    setIsPasswordVisibleb(true);
  };
  const handleMouseUpc = () => {
    setIsPasswordVisibleb(false);
  };

  const handleMouseDownd = () => {
    setIsPasswordVisiblec(true);
  };
  const handleMouseUpd = () => {
    setIsPasswordVisiblec(false);
  };

  // floor fees---------------------------------------------------
  // total repare cost--------------
  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      total_repair_cost: totalAmount.toFixed(2),
    }));
  }, [totalAmount]);

  // Calculate total
  const calculateTotal = () => {
    const { purchase_price, total_repair_cost, auction_fees, others } =
      formData;
    return (
      parseFloat(purchase_price || 0) +
      parseFloat(total_repair_cost || 0) +
      parseFloat(auction_fees || 0) +
      parseFloat(others || 0)
    ).toFixed(2);
  };

  const calculateProfit = () => {
    const listingPrice = parseFloat(formData.listing_price) || 0;
    const totalCost = parseFloat(formData.total_cost) || 0;
    return listingPrice - totalCost;
  };

  useEffect(() => {
    const newTotalCost = calculateTotal();
    setFormData((prevFormData) => ({
      ...prevFormData,
      total_cost: newTotalCost,
    }));
  }, [
    formData.purchase_price,
    formData.total_repair_cost,
    formData.auction_fees,
    formData.others,
  ]);

  useEffect(() => {
    const newProfitPrice = calculateProfit().toFixed(2);
    setFormData((prevFormData) => ({
      ...prevFormData,
      profit_price: newProfitPrice,
    }));
  }, [formData.listing_price, formData.total_cost]);
  // ---------------------------
  const handleSaveFloor = () => {
    const newObjFloor = {
      floor: inputValue34,
      interest_rate: inputValueInterestRate,
      floor_plan_length: inputValueLengthFloor,
      // new--
      vpn_insurance: inputValueVpnInsurance,
      floor_fees: inputValueFloorFees,
      // ------------
      // admin_fee: inputValueAdminFees,
      purchase_price: inputValuePurchasePrice,
      // setup_fee: inputValueSetupFees,
      // delivery_fee: inputValueDeliveryFees,
      additional_fee: inputValueAdditionalFees,
      document_fees: inputValueDocumentProcessing,
      delivery_fees: inputValueDeleveryFees,
      total_fees_interese: inputValueTotalFeesInterest,
      total_fees_interest_purchase: inputValueTotalFeesInterestPurchase,
    };
    setObjectsFloor([...objectsFloor, newObjFloor]);
    setInputValue34("");
  };

  // Update formData whenever objects changes
  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      repair_cost: objects,
      floor_fee: objectsFloor,
    }));
  }, [objects, objectsFloor]);

  console.log("line230", objectsFloor);
  // for decode the api data-------------------------------------------------------------------------------------------------------
  const handleChangedecode = async (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Check if the input has exactly 17 characters
    if (name === "vin" && value.length === 17) {
      await handleDecode(value); // Pass value directly to handleDecode
    } else if (value.length < 17) {
      setAlertVisible(false); // Set alertVisible to false if VIN is less than 17 characters
    }
  };

  // New function to handle key press events
  const handleKeyPress = async (event) => {
    const { name, value } = event.target;
    if (event.key === "Enter") {
      // Check if Enter key is pressed
      event.preventDefault(); // Prevent default Enter key behavior
      if (name === "vin" && value.length === 17) {
        await handleDecode(value);
      }
    }
  };

  const handleDecode = async (vinValue) => {
    try {
      const fd = new FormData();
      fd.append("vin", vinValue); // Use the passed vinValue
      fd.append("userId", dealerId);

      const response = await axios.post(`${apiUrl}/listing/get_vin_data`, fd);
      if (response.status === 200) {
        console.log("API Data:", response.data);
        setApiData(response.data[0]);
        setApiDatab(response.data[1]);
        setApiDatac(response.data[2]);
        setAlertVisible(false);
      }
    } catch (error) {
      if (error.response.status === 409) {
        // Handle retry logic for 409 Conflict status
        console.log(
          "API request failed with status 409. Making a GET request..."
        );
        setAlertVisible(true); // Show the alert message
      } else if (error.response.status === 401) {
        console.log("API request failed with status 401");
        setErrorMessage(error.response.data);
      } else if (error.response) {
        console.log("Error:", error.response.data);
        setErrorMessage(error.response.data);
      } else if (error.request) {
        console.log("Error:", error.request);
      } else {
        console.log("Error:", error.message);
      }
    }
  };

  // NM0LS7F79G1269247
  const handleRetry = async () => {
    //alert("Hello", formData.vin);

    setAlertVisible(false); // Hide the alert after confirming

    const retryUrl = `${apiUrl}/listing/get_vin/${formData.vin}/${id}`;
    try {
      const retryResponse = await axios.get(retryUrl);
      if (retryResponse.status === 200) {
        //alert("Hello1");
        console.log("Retry API Data:", retryResponse.data);
        setApiDataEdit(retryResponse.data[0]);
        setBtnTxt("Update");
      } else {
        // alert("Hello3");
        console.log(`Retry request failed with status ${retryResponse.status}`);
        setErrorMessage(retryResponse.data);
      }
    } catch (retryError) {
      console.log("Retry request failed with error:", retryError);
      setErrorMessage(retryError.message);
    }
  };

  const handleCancel = () => {
    setAlertVisible(false); // Hide the alert
    setFormData({ vin: "" }); // Clear the input field
  };
  useEffect(() => {
    console.log("apiData:", apiData); // Debugging log to check apiData structure
    if (apiData) {
      const newEmptyFields = {
        year_make_model: !(
          apiData?.ModelYear &&
          apiData?.Make &&
          apiData?.Model
        ),
        year: apiData?.ModelYear,
        make: apiData?.Make,
        model: apiData?.Model,
        engine: !apiData?.WheelBaseShort,
        exteriorColor: !apiData?.ExteriorColor,
        interiorColor: !apiData?.InteriorColor,
        odo: !apiData?.ODO,
        owner: !apiData?.Owner,
        mileage_in: !apiData?.Miles,
        title_status: !apiData?.TitleProblem,
        // stock_no: !apiData?.stock_number,
        vehicleType: !apiData?.VehicleType,
        interiorType:!apiData?.interiorType,
        fuel_type: !apiData?.FuelTypePrimary,
        fuel_type_secondary: !apiData?.FuelTypeSecondary,
        engine_cylinders: !apiData?.EngineCylinders,
        transmission: !(
          apiData?.TransmissionStyle && apiData?.TransmissionSpeeds
        ),
        engine_size: !apiData?.DisplacementL,
        engine_type: !apiData?.EngineConfiguration,
        drive_train: !apiData?.DriveType,
        door_count: !apiData?.Doors,
        battery_type: !apiData?.BatteryType,
        battery_kwh: !apiData?.BatteryKWh,
        other_restraint: !(
          apiData?.OtherRestraintSystemInfo && apiData?.AirBagLocFront
        ),
        trim: !(apiData?.Trim && apiData?.Series),
        seat_count: !apiData?.Seats,
        horse_power: !apiData?.DisplacementCI,
        city_mpg: !apiData?.city_mpg,
        highway_mpg: !apiData?.highway_mpg,
      };
      // Set the isSeatsPresent state based on the seat_count field
      setIsSeatsPresent(!!apiData?.Seats);

      setEmptyFields(newEmptyFields);

      const combinedYearMakeModel = `${apiData?.ModelYear || ""} ${
        apiData?.Make || ""
      } ${apiData?.Model || ""} ${
        apiData?.BodyClass === "Cargo Van" || "Van" || "Bus"
          ? apiData?.WheelBaseShort
            ? `WB${apiData?.WheelBaseShort}`
            : ""
          : apiData?.BodyClass || ""
      } ${apiData?.DriveType || ""} ${
        apiData?.Doors ? `${apiData?.Doors}D` : ""
      }`;
      const combinedBodyClassSeriesWheelBase = `${
        apiData?.FuelTypePrimary === "Electric"
          ? `${apiData?.FuelTypePrimary} ${
              apiData?.BatteryKWh ? `${apiData?.BatteryKWh}Kwh` : ""
            } ${apiData?.BatteryType || ""}`
          : `${
              apiData?.EngineCylinders ? `${apiData?.EngineCylinders}CYL` : ""
            } ${apiData?.DisplacementL ? `${apiData?.DisplacementL}L` : ""} ${
              apiData?.EngineConfiguration || ""
            } ${apiData?.FuelTypePrimary || ""} ${
              apiData?.FuelTypeSecondary ? `/${apiData?.FuelTypeSecondary}` : ""
            }`
      }`;
      // const transTransSpeed = `${apiData?.TransmissionStyle || ""} ${

      //   apiData?.TransmissionSpeeds
      //     ? `${apiData?.TransmissionSpeeds} Speed`
      //     : ""
      // }`;
      // Here is the change in the code for transTransSpeed
      const transTransSpeed = `${
        apiData?.city_mpg && apiData?.highway_mpg
          ? `${apiDatac || ""} ${
              apiData?.TransmissionSpeeds
                ? `${apiData?.TransmissionSpeeds} Speed`
                : ""
            }`
          : `${apiData?.TransmissionStyle || ""}   ${
              apiData?.TransmissionSpeeds
                ? `${apiData?.TransmissionSpeeds} - Speed`
                : ""
            }`
      }`;

      const otherRestrain = `${apiData?.OtherRestraintSystemInfo || ""} ${
        apiData?.AirBagLocFront || ""
      }`;
      const trimSeries = `${apiData?.Trim || ""} ${apiData?.Series || ""}`;
      // Set the isTransTransSpeedPresent state based on the transTransSpeed field
      setIsTransmissionPresent(!!transTransSpeed);
      setFormData((prevFormData) => ({
        ...prevFormData,
        year_make_model: combinedYearMakeModel || "",
        engine: combinedBodyClassSeriesWheelBase || "",
        vehicleType: apiData?.VehicleType || "",
        interiorType: apiData?.interiorType || "",
        // stock_no: apiData?.stock_number || "",
        // only send for split purpose--
        make: apiData?.Make || "",
        year: apiData?.ModelYear || "",
        model: apiData?.Model || "",
        bodyClass: apiData?.BodyClass || "",
        wheelbase: apiData?.WheelBaseShort || "",
        driveType: apiData?.DriveType || "",
        doorCount: apiData?.Doors || "",
        // -------------------------
        exteriorColor: apiData?.ExteriorColor || "",
        interiorColor: apiData?.InteriorColor || "",
        odo: apiData?.ODO || "",
        owner: apiData?.Owner || "",
        mileage_in: apiData?.Miles || "",
        title_status: apiData?.TitleProblem || "",
        transmission: transTransSpeed || "",
        drive_train: apiData?.DriveType || "",
        door_count: apiData?.Doors || "",
        battery_type: apiData?.BatteryType || "",
        battery_kwh: apiData?.BatteryKWh || "",
        other_restraint: otherRestrain || "",
        trim: trimSeries || "",
        seat_count: apiData?.Seats || "",
        horse_power: apiData?.DisplacementCI || "",
        city_mpg: apiData?.city_mpg || "",
        highway_mpg: apiData?.highway_mpg || "",
      }));
      // Set year, make, and model states
      setYear(apiData?.ModelYear);
      setMake(apiData?.Make);
      setModel(apiData?.Model);
    }
  }, [apiData]);
  // -------------------for decode the highway and city mpg-------------*************************-----------------------
  // console.log('Year:', year);
  // console.log('Make:', make);
  // console.log('Model:', model);
  // console.log('option transmission:', selectedOptionc);

  const transmissiondecode = async () => {
    try {
      const fd = new FormData();
      fd.append("year", year); // Assuming 'year' holds the VIN value
      fd.append("make", make);
      fd.append("model", model);
      fd.append("transmission", selectedOptionc);

      const response = await axios.post(`${apiUrl}/listing/get_pri_data`, fd);
      if (response.status === 200) {
        console.log("API Data 1951:", response.data);
        setFormData((prevFormData) => ({
          ...prevFormData,
          highway_mpg: response.data.highway_mpg || "",
          city_mpg: response.data.city_mpg || "",
        }));
      }
    } catch (error) {
      console.log(
        "API request failed with status 409. Making a GET request..."
      );
    }
  };
  // for decode the api data end-------------------------------------------------------------------------------------------------------

  // ------------------------------------------------------------------------------------------------------------------------------

  // images data end---------------------------------------------********************************-------------------------------------------------------
  const [selectedOption, setSelectedOption] = useState(""); // Updated to an empty string

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const maskValue = (value) => {
    return value ? value.replace(/./g, "X") : "";
  };
  const handleChangeb = (e) => {
    const { name, value } = e.target;

    // Remove any non-numeric characters (except for the decimal point)
    const numericValue = value.replace(/[^\d.]/g, "");

    // Check if the value matches the numeric pattern
    const regex = /^[0-9]*\.?[0-9]*$/;
    if (regex.test(numericValue)) {
      // Convert the numeric value to a number
      const numericValueAsNumber =
        numericValue === "" ? 0.0 : parseFloat(numericValue);

      // Update the form data with the numeric value (without commas)
      setFormData((prevData) => ({
        ...prevData,
        [name]: numericValueAsNumber,
      }));

      // Update the display value with commas
      const formattedValue = formatValue(numericValue);
      setDisplayValues((prevDisplayValues) => ({
        ...prevDisplayValues,
        [name]: formattedValue,
      }));
    }
  };

  const formatValue = (value) => {
    // Convert the value to a string if it's not already
    value = value.toString();

    // Split the value into whole and decimal parts
    const [whole, decimal] = value.split(".");

    // Add commas after every 3 digits for display
    let formattedValue = whole.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    if (decimal !== undefined) {
      formattedValue += "." + decimal;
    }

    return formattedValue;
  };

  // ----------------------for form data api save----------------------------------------------------------------------------

  const [disabled, setDisabled] = useState(false);

  // ------------------------------------------for reset the data for tab--------------

  const handleResetVehicleSpecfication = () => {
    setFormData({
      year_make_model: "",
      vin: "",
      trim: "",
      engine: "",
      transmission: "",
      vehicleType: "",
      seat_count: "",
      city_mpg: "",
      highway_mpg: "",
      exteriorColor: "",
      interiorColor: "",
      interiorType: "",
      other_restraint: "",
    });

    setDisplayValues({
      mileage_in: "",
      purchase_price: "",
      listing_price: "",
    });
    setShowModal(false);
    setShowModalc(false);
    setShowModalzb(false);
    setShowModald(false);
    setShowModalh(false);
    setShowModali(false);
    setShowModale(false);
  };
  // Call the parent's onReset function when needed
  React.useEffect(() => {
    if (onReset) onReset(handleResetVehicleSpecfication);
  }, [onReset]);
  // for new dropdown end------------------------***************--------------------------------------------------------------------------------

  return (
    <>
      {/* --------------------------------------------------------------------------- */}
      <Modal show={show} size="xl" onHide={handleClose}>
        <Modal.Header
          closeButton
          style={{
            padding: "0px",
            border: "none",
            paddingRight: "20px",
            paddingTop: "15px",
          }}
        >
          {/* <Modal.Title>Choose a trim package</Modal.Title> */}
        </Modal.Header>
        <div
          className="p-0"
          style={{
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold",
            fontSize: "24px",
            color: "#d1823a",
          }}
        >
          {" "}
          Trade-In Details
        </div>
        <div className="p-3">
          <div className="container-fluid">
            <form>
              <div className="border-0" style={{ border: "none" }}>
                <Container fluid className="vehiicle_specification_parent">
                  <Row>
                    <Col sm={6} className="vehi-spe-cols">
                      <Container>
                        <Row className="vehicle_specification_left_child">
                          <div className="col-md-12 d-flex">
                            <div
                              className="input-group"
                              id="vinadlst"
                              style={{ width: "92%" }}
                            >
                              <button
                                className="btn"
                                type="button"
                                id="vin-left-btn"
                              >
                                VIN
                              </button>
                              <input
                                type="text"
                                placeholder="Please Enter 17 character.."
                                className="form-control border-0"
                                name="vin"
                                value={formData.vin}
                                maxLength={17}
                                onChange={handleChangedecode}
                                onKeyDown={handleKeyPress} // Add this line
                                style={{ textTransform: "uppercase" }}
                              />
                            </div>
                            <button
                              type="button"
                              id="adlst-reset-btn"
                              onClick={handleResetVehicleSpecfication}
                              title="Reset"
                            >
                              <img
                                src={Image.reset_button}
                                alt="Reset Button"
                              />
                            </button>
                          </div>
                          {errorMessage && (
                            <p style={{ color: "red" }}>{errorMessage}</p>
                          )}
                          {alertVisible && (
                            <div className="col-md-12 popup-vin mt-2">
                              <p
                                style={{ color: "red", cursor: "pointer" }}
                                // onClick={handleCancel}
                              >
                               Already exists want to edit?
                                {/* <GiClick /> */}
                              </p>
                              <button
                                className="btn btn-secondary"
                                onClick={handleCancel}
                                style={{ marginLeft: "25px" }}
                              >
                                Cancel
                              </button>
                              <button
                                className="btn btn-danger mr-2"
                                onClick={handleRetry}
                                style={{ marginLeft: "25px" }}
                              >
                                Yes
                              </button>
                            </div>
                          )}
                          <div
                            className="col-md-6"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          ></div>

                          <div className="col-md-12 mt-4">
                            <div>
                              <label>Trim</label>
                            </div>
                            <div className="input-field-year-make bg-input">
                              <input
                                type="text"
                                name="trim"
                                value={formData.trim}
                                onChange={handleChange}
                                onKeyPress={(e) => {
                                  if (e.key === "Enter") {
                                    e.preventDefault();
                                  }
                                }}
                                id="inputadlst"
                                style={{
                                  paddingLeft: "20%",
                                  fontSize: "15",
                                  width: "98%",
                                  fontWeight: "bolder",
                                  letterSpacing: "2px",
                                  backgroundColor: "rgb(240 150 31 / 22%)",
                                }}
                              />
                              <div className="arrow-adlst">
                                <FaArrowRight onClick={handleArrowClick} />
                              </div>
                            </div>
                            <Modal show={showModal} onHide={handleCloseModal}>
                              <Modal.Header closeButton>
                                <Modal.Title>Choose a trim package</Modal.Title>
                              </Modal.Header>
                              <Modal.Body style={{ height: "88%" }}>
                                <PopupData
                                  radioOptions={radioOptions}
                                  onStateSelect={handleOptionSelect}
                                />
                              </Modal.Body>
                              <Modal.Footer></Modal.Footer>
                            </Modal>
                          </div>

                          <div
                            className="col-md-12 mt-4"
                            style={{
                              padding: "0",
                              paddingLeft: "1.5%",
                            }}
                          >
                            <div>Transmission:</div>
                            <div className="input-field-year-make bg-input">
                              <input
                                type="text"
                                name="transmission"
                                value={formData.transmission}
                                onChange={handleChange}
                                onKeyPress={(e) => {
                                  if (e.key === "Enter") {
                                    e.preventDefault();
                                  }
                                }}
                                id="inputadlst"
                                style={{
                                  paddingLeft: "20%",
                                  fontSize: "15",
                                  width: isTransmissionPresent ? "97%" : "97%",
                                  fontWeight: "bolder",
                                  letterSpacing: "2px",
                                  backgroundColor: "rgb(240 150 31 / 22%)",
                                }}
                              />
                              {!isTransmissionPresent && (
                                <div
                                  className="arrow-adlst"
                                  style={{ position: "absolute", right: "3%" }}
                                >
                                  <FaArrowRight onClick={handleArrowClickc} />
                                </div>
                              )}
                            </div>
                            <Modal show={showModalc} onHide={handleCloseModalc}>
                              <Modal.Header closeButton>
                                <Modal.Title>
                                  Choose a Transmission package
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body style={{ height: "88%" }}>
                                <PopupData
                                  radioOptions={radioOptionsc}
                                  onStateSelect={handleOptionSelectc}
                                />
                              </Modal.Body>
                              <Modal.Footer></Modal.Footer>
                            </Modal>
                          </div>

                          <div className="col-md-6 mt-4">
                            <div>Seats</div>
                            <div className="input-field-year-make bg-input">
                              <input
                                type="text"
                                name="seat_count"
                                value={formData.seat_count}
                                onChange={handleChange}
                                onClick={handleArrowClickd}
                                onKeyPress={(e) => {
                                  if (e.key === "Enter") {
                                    e.preventDefault();
                                  }
                                }}
                                id="inputadlst"
                                style={{
                                  paddingLeft: "50%",
                                  fontSize: "15",
                                  width: "90%",
                                  fontWeight: "bolder",
                                  letterSpacing: "2px",
                                  backgroundColor: "rgb(240 150 31 / 22%)",
                                }}
                              />
                              {!isSeatsPresent && (
                                <div className="arrow-adlst">
                                  <FaArrowRight onClick={handleArrowClickd} />
                                </div>
                              )}
                            </div>
                            <Modal show={showModald} onHide={handleCloseModald}>
                              <Modal.Header closeButton>
                                <Modal.Title>
                                  Choose a seat count package
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body style={{ height: "88%" }}>
                                <PopupData
                                  radioOptions={radioOptionsd}
                                  onStateSelect={handleOptionSelectd}
                                />
                              </Modal.Body>
                              <Modal.Footer
                                style={{ justifyContent: "space-between" }}
                              >
                                <input
                                  type="text"
                                  placeholder="Add Your Seats"
                                  value={newOptionseats}
                                  onChange={(e) =>
                                    setNewOptionseats(e.target.value)
                                  }
                                  onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                      handleAddOptionseats();
                                    }
                                  }}
                                  style={{ marginRight: "1rem", width: "42%" }}
                                />
                                {/* <button onClick={handleAddOptionsupplier}>Add</button> */}
                              </Modal.Footer>
                            </Modal>
                          </div>
                          <div className="col-md-6 mt-4">
                            {" "}
                            <div>City MPG</div>
                            <div
                              className="input-fields bg-input"
                              style={{
                                width: "100%",
                                display: "flex",
                                flex: 1,
                              }}
                            >
                              <input
                                type="text"
                                name="city_mpg"
                                value={formData.city_mpg}
                                onChange={handleChange}
                                onKeyPress={(e) => {
                                  if (e.key === "Enter") {
                                    e.preventDefault();
                                  }
                                }}
                                id="inputadlst"
                                style={{
                                  width: "100%",
                                  flex: "1",
                                  paddingLeft: "45%",
                                  backgroundColor: "rgb(240 150 31 / 22%)",
                                  fontSize: "15",
                                  fontWeight: "bolder",
                                  letterSpacing: "2px",
                                }}
                              />
                            </div>
                          </div>

                          <div className="d-flex"></div>
                          <div
                            className="col-md-6 mt-4"
                            style={{ marginTop: "0px" }}
                          >
                            <div>
                              <label>Exterior Color</label>
                            </div>
                            <div
                              className="input-field-year-make"
                              style={{
                                backgroundColor: "rgb(240 150 31 / 22%)",
                                padding: "0 5px",
                              }}
                            >
                              <span
                                style={{
                                  height: "30px",
                                  width: "35px",
                                  backgroundColor: exteriorColorShow,
                                }}
                              ></span>
                              <input
                                type="text"
                                name="exteriorColor"
                                value={formData.exteriorColor}
                                onChange={handleChange}
                                onClick={handleArrowClickh}
                                onKeyPress={(e) => {
                                  if (e.key === "Enter") {
                                    e.preventDefault();
                                  }
                                }}
                                id="inputadlst"
                                style={{
                                  fontSize: "15",
                                  // width: "91%",
                                  fontWeight: "bolder",
                                  letterSpacing: "2px",
                                  backgroundColor: "transparent",
                                }}
                              />
                              <div
                                className="arrow-adlst"
                                style={{ position: "absolute", right: "0px" }}
                              >
                                <FaArrowRight onClick={handleArrowClickh} />
                              </div>
                            </div>
                            <Modal show={showModalh} onHide={handleCloseModalh}>
                              <Modal.Header closeButton>
                                <Modal.Title>Choose Exterior Color</Modal.Title>
                              </Modal.Header>
                              <Modal.Body style={{ height: "88%" }}>
                                <PopupColorData
                                  radioOptions={radioOptionsh}
                                  onStateSelect={handleOptionSelecth}
                                  boxColor={radioOptionsi.boxColor}
                                />
                              </Modal.Body>
                              <Modal.Footer
                                style={{ justifyContent: "flex-start" }}
                              >
                                <input
                                  type="text"
                                  placeholder="Add Your Exterior Color"
                                  value={newOptionExteriorColor}
                                  onChange={(e) =>
                                    setNewOptionExteriorColor(e.target.value)
                                  }
                                  onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                      handleAddOptionExteriorColor();
                                    }
                                  }}
                                  style={{ marginRight: "1rem", width: "42%" }}
                                />
                              </Modal.Footer>
                            </Modal>
                          </div>

                          <div
                            className="col-md-6 mt-4"
                            style={{ marginTop: "0px" }}
                          >
                            <div>
                              <label>Interior Color</label>
                            </div>
                            <div
                              className="input-field-year-make"
                              style={{
                                backgroundColor: "rgb(240 150 31 / 22%)",
                                padding: "0 5px",
                              }}
                            >
                              {/* <div className="persistent-placeholder" style={{ fontWeight: "bold" }}>Battery Kwh:</div> */}
                              <span
                                style={{
                                  height: "30px",
                                  width: "35px",
                                  backgroundColor: interiorColorShow,
                                }}
                              ></span>
                              <input
                                type="text"
                                name="interiorColor"
                                value={formData.interiorColor}
                                onChange={handleChange}
                                onClick={handleArrowClicki}
                                onKeyPress={(e) => {
                                  if (e.key === "Enter") {
                                    e.preventDefault();
                                  }
                                }}
                                id="inputadlst"
                                style={{
                                  paddingLeft: "7%",
                                  fontSize: "15",
                                  width: "91%",
                                  fontWeight: "bolder",
                                  letterSpacing: "2px",
                                  backgroundColor: "transparent",
                                }}
                              />
                              <div
                                className="arrow-adlst"
                                style={{ position: "absolute", right: "0px" }}
                              >
                                <FaArrowRight onClick={handleArrowClicki} />
                              </div>
                            </div>
                            <Modal show={showModali} onHide={handleCloseModali}>
                              <Modal.Header closeButton>
                                <Modal.Title>Choose Interior Color</Modal.Title>
                              </Modal.Header>
                              <Modal.Body style={{ height: "88%" }}>
                                <PopupColorData
                                  radioOptions={radioOptionsi}
                                  onStateSelect={handleOptionSelecti}
                                  boxColor={radioOptionsi.boxColor}
                                />
                              </Modal.Body>
                              <Modal.Footer
                                style={{ justifyContent: "flex-start" }}
                              >
                                <input
                                  type="text"
                                  placeholder="Add Your Interior Color"
                                  value={newOptionInteriorColor}
                                  onChange={(e) =>
                                    setNewOptionInteriorColor(e.target.value)
                                  }
                                  onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                      handleAddOptionInteriorColor();
                                    }
                                  }}
                                  style={{ marginRight: "1rem", width: "42%" }}
                                />
                              </Modal.Footer>
                            </Modal>
                          </div>
                          <div className="col-md-6 mt-4"></div>
                          {/* <div className="col-md-6 mt-4">
                            <div>
                              <label>*Trade-In Value</label>
                              <div
                                className="input-fields border-0 btns-orange shadow"
                                style={{
                                  padding: "0px",
                                  width: "100%",
                                  height: "38px",
                                  border: "2px solid red",
                                }}
                              >
                                <input
                                  type="text"
                                  autoComplete="off"
                                  name="purchase_price"
                                  value={displayValues.purchase_price}
                                  onChange={handleChangeb}
                                  onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                      e.preventDefault();
                                    }
                                  }}
                                  id="milageinread"
                                />
                              </div>
                            </div>
                          </div> */}
                          <Col  className="col-md-6 mt-4">
                            <div>*Trade-In Value</div>
                            <div style={{ display: "flex" }}>
                              <div className="inputst input-containerdlr w-100">
                                <div className="persistent-placeholder">$</div>
                                <div
                                  style={{
                                    position: "relative",
                                    width: "100%",
                                  }}
                                >
                                  <input
                                    type="text"
                                    name="purchase_price"
                                    value={displayValues.purchase_price}
                                    id="purpriceselprice"
                                    className="placholder_color"
                                    placeholder="0"
                                    onChange={handleChangeb}
                                    onKeyPress={(e) => {
                                      if (e.key === "Enter") {
                                        e.preventDefault();
                                      }
                                    }}
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: "bolder",
                                      letterSpacing: "2px",
                                      width: "100%",
                                      position: "relative",
                                      zIndex: 2,
                                      backgroundColor: "transparent",
                                      paddingRight: "30px",
                                      marginTop: "0px",
                                      marginBottom: "0px",
                                    }} 
                                  />
                                </div>
                              </div>
                              <div className="arrow-adlst-cal">
                                <FaCalculator
                                  onClick={handleArrowClickCalculatorPurchase}
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                            </div>
                            <Modal
                              show={showModalCalculatorPurchase}
                              onHide={handleCloseModalCalculatorPurchase}
                            >
                              <Modal.Header closeButton>
                                <Modal.Title>calculator</Modal.Title>
                              </Modal.Header>
                              <Modal.Body style={{ height: "88%" }}>
                                <AmountInputWithCalculator
                                  setShowModalCalculator={
                                    setShowModalCalculatorPurchase
                                  }
                                  setDisplayValues={setDisplayValues}
                                  displayValues={displayValues}
                                  setFormData={setFormData}
                                  statusShowCal={3}
                                />
                              </Modal.Body>
                              <Modal.Footer
                                style={{
                                  justifyContent: "space-between",
                                }}
                              ></Modal.Footer>
                            </Modal>
                          </Col>
                        </Row>
                      </Container>
                    </Col>
                    {/* -------------------one input filed End------------------------- */}
                    {/* -------------------two input filed Start------------------------- */}
                    <Col
                      sm={6}
                      className="vehi-spe-cols"
                      style={{ marginTop: "8px" }}
                    >
                      <Container className="px-0">
                        <Row className="">
                          <div className="col-md-12 mt-1">
                            {/* <label >Year/Make/Model/series/Wheel Base/Body Class</label> */}
                            <div className="input-field-year-make bg-input">
                              <input
                                type="text"
                                name="year_make_model"
                                value={formData.year_make_model}
                                onChange={handleChange}
                                onKeyPress={(e) => {
                                  if (e.key === "Enter") {
                                    e.preventDefault();
                                  }
                                }}
                                id="inputadlst"
                                style={{
                                  fontSize: "15",
                                  width: "98%",
                                  fontWeight: "bolder",
                                  letterSpacing: "2px",
                                  backgroundColor: emptyFields.year_make_model
                                    ? "#e7bd26"
                                    : "rgb(240 150 31 / 22%)",
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-12 mt-4">
                            <div>Engine:</div>
                            <div className="input-field-year-make bg-input">
                              <input
                                type="text"
                                name="engine"
                                value={formData.engine}
                                onChange={handleChange}
                                onKeyPress={(e) => {
                                  if (e.key === "Enter") {
                                    e.preventDefault();
                                  }
                                }}
                                id="inputadlst"
                                style={{
                                  paddingLeft: "20%",
                                  fontSize: "15",
                                  width: "98%",
                                  fontWeight: "bolder",
                                  letterSpacing: "2px",
                                  backgroundColor: "rgb(240 150 31 / 22%)",
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-12 mt-4">
                            <div>Vehicle Type</div>
                            <div className="input-field-year-make bg-input">
                              <input
                                type="text"
                                name="vehicleType"
                                value={formData.vehicleType}
                                onChange={handleChange}
                                onKeyPress={(e) => {
                                  if (e.key === "Enter") {
                                    e.preventDefault();
                                  }
                                }}
                                id="inputadlst"
                                style={{
                                  paddingLeft: "20%",
                                  fontSize: "15",
                                  width: "96%",
                                  fontWeight: "bolder",
                                  letterSpacing: "2px",
                                  backgroundColor: "rgb(240 150 31 / 22%)",
                                }}
                              />
                              <div
                                className="arrow-adlst"
                                style={{ position: "absolute", right: "3%" }}
                              >
                                <FaArrowRight onClick={handleArrowClickzb} />
                              </div>
                            </div>
                            <Modal
                              show={showModalzb}
                              onHide={handleCloseModalzb}
                            >
                              <Modal.Header closeButton>
                                <Modal.Title>Choose a Vehicle Type</Modal.Title>
                              </Modal.Header>
                              <Modal.Body style={{ height: "88%" }}>
                                <PopupData
                                  radioOptions={radioOptionszb}
                                  onStateSelect={handleOptionSelectzb}
                                />
                              </Modal.Body>
                              <Modal.Footer
                                style={{ justifyContent: "space-between" }}
                              >
                                {/* <Button
                                variant="primary"
                                onClick={handleCloseModalzb}
                              >
                                Cancel
                              </Button>
                              <Button
                                variant="primary"
                                onClick={handleContinuezb}
                              >
                                Continue
                              </Button> */}
                              </Modal.Footer>
                            </Modal>
                          </div>
                          <div
                            className="col-md-12 mt-4"
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <div className="col-md-6 px-0">
                              {/* <label >Highway MPG</label> */}
                              <div>Hwy MPG:</div>
                              <div
                                className="input-fields bg-input d-flex"
                                style={{ width: "94%" }}
                              >
                                <input
                                  type="text"
                                  name="highway_mpg"
                                  value={formData.highway_mpg}
                                  onChange={handleChange}
                                  onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                      e.preventDefault();
                                    }
                                  }}
                                  id="inputadlst"
                                  style={{
                                    width: "100%",
                                    flex: "1",
                                    paddingLeft: "45%",
                                    fontSize: "15",
                                    fontWeight: "bolder",
                                    letterSpacing: "2px",
                                  }}
                                />
                              </div>
                            </div>
                            <Col
                              md={6}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                padding: "0 1%",
                              }}
                            >
                              <label>Inspection</label>
                              <div
                                className="input-fieldsins bg-inputdate"
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  height: "39px",
                                }}
                              >
                                <input
                                  type="date"
                                  name="inspection"
                                  value={formData.inspection}
                                  onChange={handleChangeins}
                                  style={{
                                    width: "64%",
                                    fontSize: "15",
                                    padding: "11px",
                                    fontWeight: "bolder",
                                    letterSpacing: "2px",
                                    backgroundColor: "rgb(229 214 194 / 22%)",
                                  }}
                                />
                                <div className="vl bg-inputdate"></div>
                                <span
                                  id="insdays"
                                  style={{
                                    fontSize: "15",
                                    fontWeight: "bolder",
                                    letterSpacing: "2px",
                                    textAlign: "center",
                                    cursor: "pointer",
                                  }}
                                  title="Days"
                                >
                                  {calculateDaysDifference()}
                                </span>
                              </div>
                            </Col>
                          </div>
                          <div className="col-md-6 mt-4">
                            <div>
                              <label>Interior Type:</label>
                            </div>
                            <div
                              className="input-field-year-make bg-input"
                              style={{ backgroundColor: "" }}
                            >
                              <input
                                type="text"
                                name="interiorType"
                                value={formData.interiorType}
                                onChange={handleChange}
                                onClick={handleArrowClicke}
                                onKeyPress={(e) => {
                                  if (e.key === "Enter") {
                                    e.preventDefault();
                                  }
                                }}
                                id="inputadlst"
                                style={{
                                  paddingLeft: "20%",
                                  fontSize: "15",
                                  width: "100%",
                                  fontWeight: "bolder",
                                  letterSpacing: "2px",
                                  backgroundColor: "rgb(240 150 31 / 22%)",
                                }}
                              />
                              <div className="arrow-adlst">
                                <FaArrowRight onClick={handleArrowClicke} />
                              </div>
                            </div>
                            <Modal show={showModale} onHide={handleCloseModale}>
                              <Modal.Header closeButton>
                                <Modal.Title>
                                  Choose a interior type pakage
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body style={{ height: "88%" }}>
                                <PopupData
                                  radioOptions={radioOptionse}
                                  onStateSelect={handleOptionSelecte}
                                />
                              </Modal.Body>
                              <Modal.Footer></Modal.Footer>
                            </Modal>
                          </div>
                          <div className="col-md-6 mt-4">
                              <div>
                                <label>
                                  <img
                                    src={Image.mileage}
                                    style={{ width: "25px", height: "25px" }}
                                  />
                                  Mileage
                                </label>
                                <div style={{ display: "flex" }}>
                                  <div
                                    className="input-fields border-0 shadow"
                                    style={{
                                      padding: "0px",
                                      width: "100%",
                                      height: "38px",
                                      border: "2px solid red",
                                      backgroundColor: '#eeb989',
                                    }}
                                  >
                                    <input
                                      type="text"
                                      autoComplete="off"
                                      name="mileage_in"
                                      placeholder="0"
                                      value={displayValues.mileage_in}
                                      onChange={handleChangeb}
                                      onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                          e.preventDefault();
                                        }
                                      }}
                                      id="milageinread"
                                    />
                                  </div>
                                  <div className="arrow-adlst-cal">
                                    <FaCalculator
                                      onClick={
                                        handleArrowClickCalculatorMileage
                                      }
                                      style={{ cursor: "pointer" }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <Modal
                                show={showModalCalculatorMileage}
                                onHide={handleCloseModalCalculatorMileage}
                              >
                                <Modal.Header closeButton>
                                  <Modal.Title>calculator</Modal.Title>
                                </Modal.Header>
                                <Modal.Body style={{ height: "88%" }}>
                                  <AmountInputWithCalculator
                                    setShowModalCalculator={
                                      setShowModalCalculatorMileage
                                    }
                                    setDisplayValues={setDisplayValues}
                                    displayValues={displayValues}
                                    setFormData={setFormData}
                                    statusShowCal={4}
                                  />
                                </Modal.Body>
                                <Modal.Footer
                                  style={{
                                    justifyContent: "space-between",
                                  }}
                                ></Modal.Footer>
                              </Modal>
                            </div>
                          {/* <div className="col-md-6 mt-4">
                            <div>
                              <label>Actual Value</label>
                              <div
                                className="input-fields border-0 btns-orange shadow"
                                style={{
                                  padding: "0px",
                                  width: "100%",
                                  height: "38px",
                                  border: "2px solid red",
                                }}
                              >
                                <input
                                  type="text"
                                  autoComplete="off"
                                  name="listing_price"
                                  value={displayValues.listing_price}
                                  onChange={handleChangeb}
                                  onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                      e.preventDefault();
                                    }
                                  }}
                                  id="milageinread"
                                />
                              </div>
                            </div>
                          </div> */}
                          <Col className="col-md-6 mt-4">
                                  <div>Actual Value</div>
                                  <div style={{ display: "flex" }}>
                                    <div className="input-containerdlr inputst input-containerdlr w-100">
                                      <div className="persistent-placeholder">
                                        $
                                      </div>
                                      <input
                                        type="text"
                                        name="listing_price"
                                        value={displayValues.listing_price}
                                        id="purpriceselpriceb"
                                        className="placholder_color"
                                        placeholder="0"
                                        onChange={handleChangeb}
                                        onKeyPress={(e) => {
                                          if (e.key === "Enter") {
                                            e.preventDefault();
                                          }
                                        }}
                                        style={{
                                          fontSize: "15",
                                          fontWeight: "bolder",
                                          letterSpacing: "2px",
                                          marginTop: "0px",
                                          marginBottom: "0px",
                                        }}
                                      />
                                    </div>
                                    <div className="arrow-adlst-cal">
                                      <FaCalculator
                                        onClick={
                                          handleArrowClickCalculatorListing
                                        }
                                        style={{ cursor: "pointer" }}
                                      />
                                    </div>
                                  </div>
                                  <Modal
                                    show={showModalCalculatorListing}
                                    onHide={handleCloseModalCalculatorListing}
                                  >
                                    <Modal.Header closeButton>
                                      <Modal.Title>calculator</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body style={{ height: "88%" }}>
                                      <AmountInputWithCalculator
                                        setShowModalCalculator={
                                          setShowModalCalculatorListing
                                        }
                                        setDisplayValues={setDisplayValues}
                                        displayValues={displayValues}
                                        setFormData={setFormData}
                                        statusShowCal={2}
                                      />
                                    </Modal.Body>
                                    <Modal.Footer
                                      style={{
                                        justifyContent: "space-between",
                                      }}
                                    ></Modal.Footer>
                                  </Modal>
                                </Col>
                        </Row>
                      </Container>
                    </Col>
                    {/* -------------------two input filed End------------------------- */}
                  </Row>
                  <div
                    style={{
                      display: "inline-block",
                      float: "right",
                      marginTop: "4%",
                    }}
                  >
                    <div className="loading">
                      {loading ? (
                        <span>
                          {uploadedCount} of {totalCount}
                        </span>
                      ) : null}
                    </div>
                    <button
                      type="button"
                      id="btn-save"
                      className="btn-change4"
                      onClick={SavedataRedux}
                    >
                      Save
                    </button>
                  </div>
                </Container>
              </div>
            </form>
            {/* <Tabsp /> */}
          </div>
          <ToastContainer />
        </div>
      </Modal>
    </>
  );
};

export default TradeInComponent;

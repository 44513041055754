import React, { useState, useEffect } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import Image from "../../imgData";
import { saveUser } from "../../../Redux/slice/userSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

function GoogleConsoleLogin({ userType }) {
  const [user, setUser] = useState([]);
  const [profile, setProfile] = useState([]);
  const [isBtnClicked, setIsBtnClicked] = useState(false);
  console.log("User => ", user);
  console.log("Profile => ", profile);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  useEffect(() => {
    if (user && isBtnClicked) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setProfile(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [user]);

  const getIPAddress = async () => {
    try {
      const res = await axios.get("https://api.ipify.org/?format=json");
      return res.data.ip;
    } catch (error) {
      console.error("Error fetching IP information:", error);
      return "";
    }
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (profile && isBtnClicked) {
      const SaveUser = async () => {
        const ip = await getIPAddress();
        try {
          const formData = new FormData();
          formData.append("email", profile.email || "");
          formData.append(
            "firstName",
            profile.given_name ? profile.given_name : ""
          );
          formData.append(
            "lastName",
            profile.family_name ? profile.family_name : ""
          );

          formData.append("ip", ip);
          formData.append("user_type", userType);

          const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/user/save_user`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (res.status === 200) {
            dispatch(
              saveUser({
                email: res.data.email,
                id: res.data.id,
                dealerId: res.data.dealerId,
                firstName: res.data.firstName,
                lastName: res.data.lastName,
                roles: res.data.roles,
                loginAccess: res.data.loginAccess,
                dealerApproval: res.data.dealerApproval,
                dealerData: res.data.dealerData,
              })
            );

            if (res.data.roles === "Dealer") {
              navigate("/dealer/dashboard");
            }
            toast.success("Signed in successfully");
          }
        } catch (error) {
          toast.error(error.response.data);
        }
      };
      SaveUser();
    }
  }, [profile]);

  return (
    <>
      <div
        onClick={() => {
          login();
          setIsBtnClicked(true);
        }}
        className="provider_div"
      >
        <span>
          <img src={Image.google_logo} alt="google logo" height={25} />
        </span>{" "}
        Signin with Google
      </div>
    </>
  );
}
export default GoogleConsoleLogin;

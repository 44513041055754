import React, { useState } from 'react'

const SellYourCar = () => {
    const [tab, setTab] = useState('license');
  
    const handleTabChange = (selectedTab) => {
      setTab(selectedTab);
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      // Add your form submission logic here
    };
  return (
    <section style={{ backgroundImage: `url('image/car-bg.jpg')`, backgroundPosition: 'center' }} className="p-lg-5">
      <div className="container-fluid p-lg-5 py-3">
        <div className="row">
          <div className="col-lg-12">
            <div className="col-lg-4 ">
              <div className="p-4" style={{ backgroundColor: '#c57a40' }}>
                <div className="card p-3 shadow">
                  <h2>Sell Your Car</h2>
                  <nav>
                    <div className="nav nav-tabs mb-3 nav-justified" id="nav-tab" role="tablist">
                      <button
                        className={`nav-link ${tab === 'license' ? 'active' : ''}`}
                        onClick={() => handleTabChange('license')}
                        type="button"
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected={tab === 'license'}
                      >
                        License
                      </button>
                      <button
                        className={`nav-link ${tab === 'vin' ? '' : ''}`}
                        onClick={() => handleTabChange('vin')}
                        type="button"
                        role="tab"
                        aria-controls="nav-profile"
                        aria-selected={tab === 'vin'}
                      >
                        VIN
                      </button>
                    </div>
                  </nav>
                  <div className="tab-content p-3 border" id="nav-tabContent" style={{minWidth : "auto"}}>
                    <div className={`tab-pane fade ${tab === 'license' ? 'active show' : ''}`} id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                      <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                          <input
                            type="text"
                            className="form-control"
                            id="licensePlate"
                            placeholder="License Plate number"
                          />
                        </div>
                        <div className="mb-3">
                          <input
                            type="text"
                            className="form-control"
                            id="registeredLocation"
                            placeholder="Where's your car registered?"
                          />
                        </div>
                        <div className="mb-3">
                          <input
                            type="text"
                            className="form-control"
                            id="zipCode"
                            placeholder="What's your zip code?"
                          />
                        </div>
                        <button
                          type="submit"
                          className="btn btn-primary"
                          style={{
                            backgroundColor: '#f5f5f5',
                            border: '1px solid',
                            width: '100%',
                            color: 'white',
                          }}
                        >
                          Get Started
                        </button>
                      </form>
                    </div>
                    <div className={`tab-pane fade ${tab === 'vin' ? 'active show' : ''}`} id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                      <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                          <input
                            type="text"
                            className="form-control"
                            id="vinNumber"
                            placeholder="VIN number"
                          />
                        </div>
                        <div className="mb-3">
                          <input
                            type="text"
                            className="form-control"
                            id="registeredLocation"
                            placeholder="Where's your car registered?"
                          />
                        </div>
                        <div className="mb-3">
                          <input
                            type="text"
                            className="form-control"
                            id="zipCode"
                            placeholder="What's your zip code?"
                          />
                        </div>
                        <button
                          type="submit"
                          className="btn btn-primary"
                          style={{
                            backgroundColor: '#f5f5f5',
                            border: '1px solid',
                            width: '100%',
                            color: 'white',
                          }}
                        >
                          Get Started
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SellYourCar;
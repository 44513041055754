import React from "react";
import "../Styles/DealerNextStep.css";

const DealerNextStep = () => {
  return (
    <div className="dealer_next_step_wrapper">
      <div className="dealer_next_step_container">
        <p className="dealer_next_step_header">TAKE THE NEXT STEP</p>
        <p className="request_register_container">
          <span>REQUEST FOR A DEMO</span>
          <span>Register Now</span>
        </p>
        <div className="dealer_inquiry_input_container">
          <p>
            <span>Dealership Name</span>
            <input type="text" />
          </p>
          <p>
            <span>Dealer License Number / P Number</span>
            <input type="text" />
          </p>
          <p>
            <span>Office</span>
            <input type="text" />
          </p>
          <p>
            <span>Fax</span>
            <input type="text" />
          </p>
        </div>
          <button>Submit</button>
      </div>
    </div>
  );
};

export default DealerNextStep;

import React, { useState } from "react";
import { Modal } from "react-bootstrap";
const RollsDeatail = ({ show, handleClose }) => {
  //Preview Modal

  return (
    <Modal
      show={show}
      size="xl"
      onHide={handleClose}
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          Rollls details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body></Modal.Body>
    </Modal>
  );
};

export default RollsDeatail;

import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Styles from "./PaymentDue.module.css";
import "./PaymentDue.css";

import ActiveBalanceCalculator from "./ActiveBalanceCalculator";
import { FaCalculator } from "react-icons/fa";
import { FaCircleArrowRight, FaDivide } from "react-icons/fa6";
import DealDetailDesktop from "./components/DealDetailDesktop";
import PaymentHistoryDesktop from "./components/PaymentHistoryDesktop";
import axios from "axios";
const PaymentDueData = ({
  show,
  handleClose,
  PaymentData,
  setRefreshApiData,
}) => {
  console.log("Payment Data => ", PaymentData);
  const currentBalance =
    PaymentData &&
    PaymentData?.sellPriceFees &&
    PaymentData?.sellPriceFees?.balanceDue;

  console.log("currentBalance => ", currentBalance);
  // form data---------------------------------------------------
  const [formData, setFormData] = useState({
    dueBalance: currentBalance,
    latePaymentFees: "",
    payment: "",
    paymentMethod: "",
    nextPaymentDate: "",
    balanceDue: "",
    notes: "",
    mscOthers: "",
    reminder: false,
    sendReceiptEmail: false,
    sellId: "",
    userId: "",
    id: 0,
  });
  const [isEditing, setIsEditing] = useState(false);
  console.log("is Editing => ", isEditing);
  console.log(
    "currentBalance => ",
    currentBalance,
    " formdata => 35 ",
    formData
  );
  useEffect(() => {
    if (PaymentData) {
      setFormData({
        ...formData,
        dueBalance: currentBalance,
        latePaymentFees: "",
        payment: "",
        paymentMethod: "",
        nextPaymentDate: "",
        balanceDue: "",
      });
    }
    return () => {
      setIsEditing(false);
      setFormData({
        dueBalance: currentBalance,
        latePaymentFees: "",
        payment: "",
        paymentMethod: "",
        nextPaymentDate: "",
        balanceDue: "",
        notes: "",
        mscOthers: "",
        reminder: false,
        sendReceiptEmail: false,
        sellId: "",
        userId: "",
        id: 0,
      });
    };
  }, [currentBalance]);
  useEffect(() => {
    return () => {
      setIsEditing(false);
    };
  }, []);

  useEffect(() => {
    console.log(
      "isEditing false currentBalance => ",
      currentBalance,
      " formdata => 35 ",
      formData
    );
  }, []);

  useEffect(() => {
    let newBalanceDue;
    if (formData.payment) {
      newBalanceDue = formData.dueBalance - formData.payment;
    } else {
      newBalanceDue = formData.dueBalance - 0;
    }
    setFormData((prevData) => ({
      ...prevData,
      balanceDue: parseInt(newBalanceDue)?.toFixed(2) || 0,
    }));
  }, [formData.payment]);

  // for tabs-----------------------------
  // const [activeTab, setActiveTab] = useState("tab1");

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  console.log("fomrData23453", formData);
  // for calculator----------------------------------------------------
  const [showCalculatorDropdown, setShowCalculatorDropdown] = useState(false);
  const [showPaymentCalculator, setshowPaymentCalculator] = useState(false);

  const [showLatePaymentCalculator, setShowLatePaymentCalculator] =
    useState(false);
  const handleArrowClickCalculator = () => {
    setShowCalculatorDropdown(!showCalculatorDropdown); // Toggle the visibility of the dropdown
  };
  const paymentTabArray = ["Payment", "Payment History", "Deal Details"];
  const [paymentActiveTab, setPaymentActiveTab] = useState("Payment");

  const handleSaveData = async () => {
    const dateParts = formData?.nextPaymentDate?.split("-"); // Assuming the input is in yyyy-mm-dd format
    let formattedDate =
      dateParts.length === 3
        ? `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`
        : "";

    const dataToSend = {
      ...formData,
      sellId: parseInt(PaymentData.id),
      userId: parseInt(PaymentData.userId),
      balanceDue: parseFloat(formData.balanceDue) || 0, // Ensuring it's a number
      dueBalance: parseFloat(currentBalance) || 0, // Ensuring it's a number
      payment: parseFloat(formData.payment) || 0, // Similarly for payment field, if needed
      latePaymentFees: parseFloat(formData.latePaymentFees) || 0, // Likewise for latePaymentFees
      mscOthers: parseFloat(formData.mscOthers) || 0, // Similarly for other fields
      nextPaymentDate: formattedDate,
    };
    console.log("Data to be sent  to server 74", dataToSend);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/sell/update_payment`,
        dataToSend
      );
      if (response.status === 200) {
        setPaymentActiveTab("Payment History");
        setFormData((prevData) => ({
          ...prevData,
          dueBalance: currentBalance,
          latePaymentFees: "",
          payment: "",
          paymentMethod: "",
          nextPaymentDate: "",
          balanceDue: "",
          notes: "",
          mscOthers: "",
          reminder: false,
          sendReceiptEmail: false,
          sellId: "",
          userId: "",
          id: 0,
        }));
        setIsEditing(false);
        setRefreshApiData(true);
      }
      console.log("response from server", response);
    } catch (error) {
      console.error("Error while sending data to backend", error);
    }
  };
  const handleCloseModel = () => {
    handleClose();
    setIsEditing(false);
    setFormData({
      dueBalance: currentBalance,
      latePaymentFees: "",
      payment: "",
      paymentMethod: "",
      nextPaymentDate: "",
      balanceDue: "",
      notes: "",
      mscOthers: "",
      reminder: false,
      sendReceiptEmail: false,
      sellId: "",
      userId: "",
      id: 0,
    });
  };
  return (
    <Modal
      show={show}
      size="xl"
      onHide={handleCloseModel}
      dialogClassName="modal-90w"
    >
      <Modal.Header closeButton>
        <div>
          <Modal.Title>Payment</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="paymentBodyWrapper">
          <div className="paymentBodyTabsContainer">
            {paymentTabArray.map((tab, index) => (
              <span
                key={index}
                onClick={() => setPaymentActiveTab(tab)}
                className={paymentActiveTab === tab ? "activeTab" : ""}
              >
                {tab}
              </span>
            ))}
          </div>

          {paymentActiveTab === "Payment" && (
            <>
              <div className={Styles.dataMessangerWrapper}>
                <div className={Styles.currentBalance}>
                  <span className={Styles.balanceHeader}>Current Balance</span>
                  <input
                    type="text"
                    value={new Intl.NumberFormat().format(formData.dueBalance)}
                  />
                </div>
                {/* Data + Messanger */}
                <div className={Styles.dataMessangerContainer}>
                  {/* Data part container */}
                  <div className={`${Styles.dataPartContainer}`}>
                    <div className={`${Styles.mainContainer}`}>
                      <label
                        className={` ${Styles.label_header} ${Styles.label_header2}`}
                      >
                        Payment:
                      </label>
                      <div className={Styles.calculatorContainer}>
                        <input
                          type="text"
                          id="payment"
                          name="payment"
                          className={`form-control ${Styles.input}`}
                          onChange={handleChange}
                          value={formData.payment}
                        />
                        <div className={Styles.arrow_amout}>
                          <FaCalculator
                            onClick={() =>
                              setshowPaymentCalculator(!showPaymentCalculator)
                            }
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                        {showPaymentCalculator && (
                          <div className={Styles.calculator_dropdown}>
                            <ActiveBalanceCalculator
                              setShowModalCalculator={setshowPaymentCalculator}
                              setFormData={setFormData}
                              statusShowCal={4}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={` ${Styles.mainContainer}`}>
                      <label
                        className={` ${Styles.label_header} ${Styles.label_header2}`}
                      >
                        Late Payment Fees:
                      </label>
                      <div className={Styles.calculatorContainer}>
                        <input
                          type="text"
                          id="latePaymentFees"
                          name="latePaymentFees"
                          className={`form-control  ${Styles.input}`}
                          onChange={handleChange}
                          value={formData.latePaymentFees}
                        />
                        <div className={Styles.arrow_amout}>
                          <FaCalculator
                            onClick={() =>
                              setShowLatePaymentCalculator(
                                !showLatePaymentCalculator
                              )
                            }
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                        {showLatePaymentCalculator && (
                          <div className={Styles.calculator_dropdown}>
                            <ActiveBalanceCalculator
                              setShowModalCalculator={
                                setShowLatePaymentCalculator
                              }
                              setFormData={setFormData}
                              statusShowCal={4}
                            />
                          </div>
                        )}
                      </div>
                    </div>

                    <div className={` ${Styles.mainContainer} `}>
                      <label
                        className={` ${Styles.label_header} ${Styles.label_header2}`}
                      >
                        MSC & Others
                      </label>
                      <div className={Styles.calculatorContainer}>
                        <input
                          type="text"
                          id="mscOthers"
                          name="mscOthers"
                          className={`form-control ${Styles.input}`}
                          onChange={handleChange}
                          value={formData.mscOthers}
                        />
                        <div className={Styles.arrow_amout}>
                          <FaCalculator
                            onClick={handleArrowClickCalculator}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                        {showCalculatorDropdown && (
                          <div className={Styles.calculator_dropdown}>
                            <ActiveBalanceCalculator
                              setShowModalCalculator={setShowCalculatorDropdown}
                              setFormData={setFormData}
                              statusShowCal={4}
                            />
                          </div>
                        )}
                      </div>
                    </div>

                    <div className={` ${Styles.mainContainer}`}>
                      <label className={` ${Styles.label_header}`}>
                        Payment Method:
                      </label>
                      <select
                        id="paymentMethod"
                        name="paymentMethod"
                        className={`form-control  ${Styles.input}`}
                        onChange={handleChange}
                        value={formData.paymentMethod}
                      >
                        <option value="">Select a Payment Method</option>
                        <option value="cash">Cash</option>
                        <option value="cheque">Cheque</option>
                        <option value="wire">Wire</option>
                        <option value="card">Card</option>
                        <option value="trade">Traded</option>
                        <option value="other">Other</option>
                        <option value="cashier_check">Cashier Check</option>
                        <option value="finance">Finance Source</option>
                        {/* Add more payment options as needed */}
                      </select>
                    </div>
                    <div className={` ${Styles.mainContainer}`}>
                      <label className={` ${Styles.label_header}`}>
                        Total Payment:
                      </label>
                      <input
                        type="text"
                        className={`form-control  ${Styles.input} ${Styles.darkOrangeInput}`}
                        value={parseInt(
                          parseInt(formData.latePaymentFees || 0) +
                            parseInt(formData.mscOthers || 0) +  parseInt(formData.payment || 0)

                        ).toFixed(2)}
                      />
                    </div>
                    <div className={` ${Styles.mainContainer}`}>
                      <label className={` ${Styles.label_header}`}>
                        Next Payment Due:
                      </label>
                      <input
                        type="date"
                        id="nextPaymentDate"
                        name="nextPaymentDate"
                        className={`form-control  ${Styles.input}`}
                        onChange={handleChange}
                        value={formData.nextPaymentDate}
                      />
                    </div>
                    <div className={` ${Styles.mainContainer}`}>
                      <label className={` ${Styles.label_header}`}>
                        Balance Due:
                      </label>
                      <input
                        type="text"
                        id="balanceDue"
                        name="balanceDue"
                        className={`form-control  ${Styles.input}`}
                        onChange={handleChange}
                        value={
                          formData?.balanceDue === "NaN"
                            ? 0
                            : formData?.balanceDue
                        }
                      />
                    </div>
                    <div className={` ${Styles.mainContainer}`}>
                      <label className={Styles.label_header}>Notes :</label>
                      <textarea
                        id="notes"
                        name="notes"
                        className={`form-control ${Styles.input}`}
                        onChange={handleChange}
                        value={formData.notes}
                      />
                    </div>
                  </div>
                  {/* Messanger part container */}
                  <div className={Styles.MessangerPartWrapper}>
                    <div className={Styles.MessangerPartContainer}>
                      <div className={Styles.MessangerHeader}>
                        <span>Message</span>
                        <span>1</span>
                      </div>
                      <div className={Styles.MessangerBody}>
                        <div className={Styles.LeftBody}>
                          <div className={Styles.message}>
                            <span>Ghada</span>
                            <span>I am interested to drive this car.</span>
                          </div>
                        </div>
                        <div className={Styles.RightBody}>
                          <div className={Styles.message}>
                            <span>United</span>
                            <span>
                              Before we proceed, can you please provide your ID
                              and address? Also I need to know if you have
                              driving license with all other documents.
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className={Styles.MessangerInput}>
                        <input
                          type="text"
                          name=""
                          id=""
                          placeholder="Enter Your Message"
                        />
                        <span>
                          <FaCircleArrowRight />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Reminder checkbox */}
                <div
                  className={`d-flex w-100 align-items-center ${Styles.dataPaymentContainer}`}
                >
                  <div className="d-flex flex-column w-50">
                    <label
                      className={` ${Styles.label_header}`}
                      htmlFor="reminder"
                    >
                      <input
                        type="checkbox"
                        id="reminder"
                        className={Styles.checkbox}
                        value={formData.reminder}
                        checked={formData.reminder}
                        onChange={() =>
                          setFormData({
                            ...formData,
                            reminder: !formData.reminder,
                          })
                        }
                      />
                      Send Reminder for the payment by email.
                    </label>
                    <label
                      className={` ${Styles.label_header}`}
                      htmlFor="sendReceiptEmail"
                    >
                      <input
                        type="checkbox"
                        id="sendReceiptEmail"
                        className={Styles.checkbox}
                        value={formData.sendReceiptEmail}
                        checked={formData.sendReceiptEmail}
                        onChange={() =>
                          setFormData({
                            ...formData,
                            sendReceiptEmail: !formData.sendReceiptEmail,
                          })
                        }
                      />
                      Send receipt via email.
                    </label>
                  </div>
                  <div className={`w-50`}>
                    <button
                      type="button"
                      className={Styles.save_btn}
                      onClick={handleSaveData}
                      disabled={!formData.payment && !formData.dueBalance}
                    >
                     {isEditing ? "Update" : "Save"} 
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
          {paymentActiveTab === "Payment History" && (
            <>
              <PaymentHistoryDesktop
                PaymentData={PaymentData}
                setPaymentActiveTab={setPaymentActiveTab}
                setFormData={setFormData}
                setIsEditing={setIsEditing}
              />
            </>
          )}
          {paymentActiveTab === "Deal Details" && (
            <>
              <DealDetailDesktop PaymentData={PaymentData} />
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PaymentDueData;

import React, { useEffect, useState } from 'react'
// import './Draft.css';
import { FaSquareShareNodes } from "react-icons/fa6";
import { IoHeartCircle } from "react-icons/io5";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Image from "../../imgData";
const apiUrl = process.env.REACT_APP_API_URL;
const imgUrl = process.env.REACT_APP_IMG_URL;

const DealerRecycleBin = () => {
  const [recycleData, setRecycleData] = useState([]);
  const dispatch = useDispatch(); // Using useDispatch to get the dispatch function
  const { id } = useSelector((state) => state.user);
  const [currentPage, setCurrentPage] = useState(1); // State for current page
  const [itemsPerPage] = useState(5); // Number of items per page, adjust as needed
  // Session Time out for 15 minutes-------------------------------------
  // Define fetchData function within the component scope
  const fetchData = async () => {
    try {
      const response = await fetch(`${apiUrl}/listing/get_user_recycle/${id}`);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      setRecycleData(data); // Store fetched data in state
    } catch (error) {
      console.error('Error fetching draft data:', error);
    }
  };

  useEffect(() => {
    fetchData(); // Initial data fetch when component mounts
  }, [id]); // Fetch data whenever id changes

  const handleDeleteRecycle = async (Id, userId) => {
    try {
      const response = await fetch(`${apiUrl}/listing/permanent_delete/${userId}/${Id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Failed to delete draft');
      }
      fetchData(); // Refetch data after successful deletion
    } catch (error) {
      console.error('Error deleting draft:', error);
      // Handle error as needed, show error message, etc.
    }
  };

  const handleRestoreData = async (Id, userId) => {
    try {
      const response = await fetch(`${apiUrl}/listing/restore_listing/${userId}/${Id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Failed to delete Recyle');
      }
      fetchData(); // Refetch data after successful deletion
    } catch (error) {
      console.error('Error deleting Reccycle:', error);
      // Handle error as needed, show error message, etc.
    }
  };

  console.log("line38", recycleData);

  // Calculate the index range of items for the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = recycleData.slice(indexOfFirstItem, indexOfLastItem); // Slice data for current page

  const handleNext = () => {
    setCurrentPage((prevPage) => prevPage + 1); // Increment page number
  };

  const handlePrevious = () => {
    setCurrentPage((prevPage) => prevPage - 1); // Decrement page number
  };

  const noImage = Image.noImage;
  const getImageUrl = (draftItem) => {
    const { all_images, exterior_images, misc_images, interior_images, three_sixty_images } = draftItem.multimedia;

    // Helper function to check if a string has valid images
    const hasValidImages = (imageString) => {
      const imagesArray = imageString.split(",");
      return imagesArray.length > 0 && imagesArray[0];
    };

    // Check if all_images has at least one image
    if (hasValidImages(all_images)) {
      return `${imgUrl}/${id}/${all_images.split(",")[0]}`;
    }

    // Check if exterior_images has at least one image
    if (hasValidImages(exterior_images)) {
      return `${imgUrl}/${id}/${exterior_images.split(",")[0]}`;
    }

    // Check if misc_images has at least one image
    if (hasValidImages(misc_images)) {
      return `${imgUrl}/${id}/${misc_images.split(",")[0]}`;
    }

    // Check if interior_images has at least one image
    if (hasValidImages(interior_images)) {
      return `${imgUrl}/${id}/${interior_images.split(",")[0]}`;
    }

    // Check if three_sixty_images has at least one image
    if (hasValidImages(three_sixty_images)) {
      return `${imgUrl}/${id}/${three_sixty_images.split(",")[0]}`;
    }

    // If none of the above, return the no image placeholder
    return noImage;
  };

  // Session Time out for 15 minutes End-------------------------------------
  return (
    <div style={{ overflowY: "hidden", width: "100%", paddingBottom: "20px" }}>
      {recycleData.map((draftItem, index) => (
        <div className='draft-cart-main-cointer'>
          <div className='draft-cart'>
            <div className="card" style={{ width: " 18rem", height: "160px", marginTop: "10px" }}>
              <div style={{ position: "relative", textAlign: "center" }}>
                <img src={getImageUrl(draftItem)} className="card-img-top" alt="..." />
                <div className="top-right" style={{ position: "absolute", top: "8px", right: "16px", display: "flex", flexDirection: "column" }}>
                  <FaSquareShareNodes style={{ fontSize: "20px", fill: "white" }} />
                  <IoHeartCircle style={{ fontSize: "20px", fill: "white" }} />
                </div>
              </div>
              <div className="card-body" style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", height: "70px" }}>
                <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                  {/* <p><button data-tooltip="I’m the tooltip text.">I’m a button with a tooltip</button></p> */}
                  <div><Link
                    to="#" data-tooltip="Exterior"
                    style={{
                      // color: 'red',
                      padding: '10px',
                      backgroundColor: 'darkblue',
                      borderRadius: '50%',
                      height: 'auto',
                      display: 'block',
                      marginRight: "39px"
                      // width: '1%',
                    }}
                  ></Link></div>
                  <div> <Link
                    to="#" data-tooltip="Interior"
                    style={{
                      // color: 'blue',
                      padding: '10px',
                      backgroundColor: 'red',
                      borderRadius: '50%',
                      height: 'auto',
                      display: 'block',
                      // width: '1%',
                    }}
                  ></Link></div>
                </div>
              </div>

            </div>
          </div>
          <div className='draft-heading' style={{ marginLeft: "30px" }}>
            <h5 style={{ marginTop: "20px", float: "left" }}>{draftItem.year_Make_Model}</h5>
            <p style={{ fontSize: "12px", float: "left" }}><span style={{ color: "#d1823a" }}>VIN :</span>{draftItem.vin}</p>
            <p style={{ fontSize: "12px", float: "left" }}><span style={{ fontWeight: "bold" }}></span>{draftItem.engine}</p>
          </div>
          <div className='draft-price'>
            <p style={{ fontWeight: "bold", marginTop: "20px" }}>Price : <span style={{ color: "#d1823a" }}>${draftItem.listing_price}</span></p>
            <div style={{ display: "flex", flexDirection: "column", marginTop: "44px" }}>
              <span id='draft-button1'><button type='button' className='btn-change5' onClick={() => handleRestoreData(draftItem.id, draftItem.userId)}>Restore</button></span>
              <span id='draft-button2'><button type='button' className='btn-change5' onClick={() => handleDeleteRecycle(draftItem.id, draftItem.userId)}>Delete</button></span>
            </div>
          </div>
        </div>
      ))}
      {/* <div className="pagination-controls-draft">
          <button onClick={handlePrevious} disabled={currentPage === 1} className='btn-change5'>
            Previous
          </button>
          <button
            onClick={handleNext}
            disabled={indexOfLastItem >= recycleData.length}
            className='btn-change5'
          >
            Next
          </button>
        </div>  */}
    </div>
  )
}

export default DealerRecycleBin;
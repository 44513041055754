import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const DealerProtectedRoutes = ({ children }) => {
  const userData = useSelector((state) => state.user);
  return userData.email && userData.id && userData.roles === "Dealer" ? (
    children
  ) : (
    // : children;
    <Navigate to="/" />
  );
};

export default DealerProtectedRoutes;

import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button, Dropdown } from 'react-bootstrap';
import { AiOutlineMail, AiFillPrinter } from "react-icons/ai";
import Image from '../../imgData';
import './forms.css'

function Forms() {
    // State to manage Select All
    const [selectAll, setSelectAll] = useState(false);
    const [selectedState, setSelectedState] = useState('TX');

    // State to manage checkboxes
    const [checkboxes, setCheckboxes] = useState({
        billOfSale: false,
        buyerGuide: false,
        odometerDisclosure: false,
        titleApp: false,
        // AgreementInsurance: false,
        // creditBureauDisclosure: false,
        // rebuildTitleDisclosure: false,
        // powerOfAttorney: false,
        // retailContractInstallment: false,
        // salesTaxExemptionCertificate: false,
    });

    // Handle individual checkbox change
    const handleCheckboxChange = (e) => {
        const { id, checked } = e.target;
        setCheckboxes((prev) => ({
            ...prev,
            [id]: checked,
        }));

        // Update Select All state based on individual checkboxes
        setSelectAll(Object.values({ ...checkboxes, [id]: checked }).every((val) => val));
    };
    // Handle state selection
    const handleSelectState = (state) => {
        setSelectedState(state);
    };

    // Handle Select All change
    const handleSelectAll = () => {
        const newSelectAll = !selectAll;
        setSelectAll(newSelectAll);
        setCheckboxes({
            billOfSale: newSelectAll,
            buyerGuide: newSelectAll,
            odometerDisclosure: newSelectAll,
            titleApp: newSelectAll,
            // AgreementInsurance: newSelectAll,
            // creditBureauDisclosure: newSelectAll,
            // rebuildTitleDisclosure: newSelectAll,
            // powerOfAttorney: newSelectAll,
            // retailContractInstallment: newSelectAll,
            // salesTaxExemptionCertificate: newSelectAll,
        });
    };

    return (
        <>
            <Container className="forms-container">
                <Row className="forms-header px-1 text-center">
                    <Col sm={2} className="p-1">
                        <h3>Forms</h3>
                    </Col>
                    <Col sm={1} className="text-right p-1">
                        <Dropdown onSelect={handleSelectState} className="m-0 ">
                            <Dropdown.Toggle size="sm" className="px-0 bg-orange border-0 fw-bold text-light">
                                {selectedState}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item eventKey="TX">TX</Dropdown.Item>
                                <Dropdown.Item eventKey="CA">CA</Dropdown.Item>
                                <Dropdown.Item eventKey="NY">NY</Dropdown.Item>
                                {/* Add more states as needed */}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
                <Row className="m-5">
                    <Col sm={12} className="mb-2">
                        <span onClick={handleSelectAll} style={{ cursor: 'pointer', fontWeight: 'bold', textDecoration: "underline" }}>
                            {selectAll ? 'Unselect All ' : 'Select All'}
                        </span>
                    </Col>

                    <Col className="d-flex flex-column justify-content-center align-items-center fs-5">
                        <Form className="">
                            {/* <Form.Group className="form-option m-2 border-end" controlId="AgreementInsurance">
                                <Form.Check
                                    type="checkbox"
                                    label="Agreement To Provide Insurance"
                                    checked={checkboxes.AgreementInsurance}
                                    onChange={handleCheckboxChange}
                                />
                            </Form.Group> */}

                            <Form.Group className="form-option m-2 border-end" controlId="billOfSale">
                                <Form.Check
                                    type="checkbox"
                                    label="Bill of Sale / Buyer Order"
                                    checked={checkboxes.billOfSale}
                                    onChange={handleCheckboxChange}
                                />
                            </Form.Group>

                            {/* <Form.Group className="form-option m-2 border-end" controlId="creditBureauDisclosure">
                                <Form.Check
                                    type="checkbox"
                                    label="Credit bureau Disclosure"
                                    checked={checkboxes.creditBureauDisclosure}
                                    onChange={handleCheckboxChange}
                                />
                            </Form.Group> */}
                            <Form.Group className="form-option m-2 border-end" controlId="buyerGuide">
                                <Form.Check
                                    type="checkbox"
                                    label="Buyer Guide / AS IS"
                                    checked={checkboxes.buyerGuide}
                                    onChange={handleCheckboxChange}
                                />
                            </Form.Group>
                            <Form.Group className="form-option m-2 border-end" controlId="odometerDisclosure">
                                <Form.Check
                                    type="checkbox"
                                    label="Odometer Disclosure"
                                    checked={checkboxes.odometerDisclosure}
                                    onChange={handleCheckboxChange}
                                />
                            </Form.Group>
                            {/* <Form.Group className="form-option m-2 border-end" controlId="rebuildTitleDisclosure">
                                <Form.Check
                                    type="checkbox"
                                    label="Rebuild Title Disclosure"
                                    checked={checkboxes.rebuildTitleDisclosure}
                                    onChange={handleCheckboxChange}
                                />
                            </Form.Group> */}
                            <Form.Group className="form-option m-2 border-end" controlId="titleApp">
                                <Form.Check
                                    type="checkbox"
                                    label={`${selectedState} - Title App I130u`}
                                    checked={checkboxes.titleApp}
                                    onChange={handleCheckboxChange}
                                />
                            </Form.Group>
                            {/* <Form.Group className="form-option m-2 border-end" controlId="powerOfAttorney">
                                <Form.Check
                                    type="checkbox"
                                    label={`${selectedState} - Power Of Attorney`}
                                    checked={checkboxes.powerOfAttorney}
                                    onChange={handleCheckboxChange}
                                />
                            </Form.Group>
                            <Form.Group className="form-option m-2 border-end" controlId="retailContractInstallment">
                                <Form.Check
                                    type="checkbox"
                                    label={`${selectedState} - Retail Contract Installment`}
                                    checked={checkboxes.retailContractInstallment}
                                    onChange={handleCheckboxChange}
                                />
                            </Form.Group>
                            <Form.Group className="form-option m-2 border-end" controlId="salesTaxExemptionCertificate">
                                <Form.Check
                                    type="checkbox"
                                    label={`${selectedState} - Sales Tax Exemption Certificate`}
                                    checked={checkboxes.salesTaxExemptionCertificate}
                                    onChange={handleCheckboxChange}
                                />
                            </Form.Group> */}
                        </Form>
                    </Col>
                </Row>
                <Row className="form-actions">
                    <Col className="text-right d-flex justify-content-center">
                        <Button variant="secondary" className="mx-2 btn-change5">eContract</Button>
                        <Button variant="secondary" className="mx-2 btn-change5"><AiFillPrinter /> Print</Button>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Forms
import React, { useEffect, useRef } from "react";
import "../Styles/Dealer_left_Section.css";

const Dealer_left_Section = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const boxes = container.querySelectorAll('.box_container');
    const boxCount = boxes.length;
    const radius = 300; // Set consistent radius
    let currentBox = 0;

    function positionBoxes() {
      let topRightBox = null;
      let maxScore = -Infinity;

      boxes.forEach((box, index) => {
        const angle = ((index + currentBox) / boxCount) * 2 * Math.PI - Math.PI / 2;
        const x = radius * Math.cos(angle) + radius;
        const y = radius * Math.sin(angle) + radius;
        box.style.left = `${x}px`;
        box.style.top = `${y}px`;
        box.style.transform = 'translate(-50%, -50%) scale(1)';
        box.classList.remove('scale-box');
        box.style.zIndex = '1';

        // Calculate a score based on how "top-right" the box is
        const score = x - y; // Higher x and lower y means more top-right
        if (score > maxScore) {
          maxScore = score;
          topRightBox = box;
        }
      });

      if (topRightBox) {
        topRightBox.classList.add('scale-box');
        topRightBox.style.zIndex = '10';
      }
    }

    function moveBoxes() {
      currentBox = (currentBox + 1) % boxCount;
      positionBoxes();
    }

    positionBoxes();
    const intervalId = setInterval(moveBoxes, 1618);

    return () => clearInterval(intervalId);
  }, []);

  //   function positionBox(box, index) {
  //     const angle = (index / boxCount) * 2 * Math.PI - Math.PI / 2; // Start from top
  //     const x = radius * Math.cos(angle) + radius;
  //     const y = radius * Math.sin(angle) + radius;
  //     box.style.left = `${x}px`;
  //     box.style.top = `${y}px`;
  //     box.style.transform = 'translate(-50%, -50%) scale(1)';

  //     if (Math.abs(x - 2 * radius) < 0.1) { // Approximately at the bottom
  //       box.classList.add('scale-box');
  //       box.style.zIndex = '10'; // Set maximum z-index
  //     } else {
  //       box.classList.remove('scale-box');
  //       box.style.zIndex = '1'; // Reset z-index
  //     }
  //   }

  //   function moveBoxes() {
  //     currentBox = (currentBox + 1) % boxCount;
  //     boxes.forEach((box, index) => {
  //       const newIndex = (index + currentBox) % boxCount;
  //       positionBox(box, newIndex);
  //     });
  //   }

  //   boxes.forEach((box, index) => positionBox(box, index));
  //   setInterval(moveBoxes, 1618);
  // }, []);

  return (
    <div className="dealer_login_left_wrapper">
      <div className="dealer_login_left_container" ref={containerRef}>
        {/* <div className="box_container bg-gray">
          <p>DAC</p>
          <p>AUCTION LINE</p>
        </div> */}
        <div className="box_container bg-gray">
          <p>DIGITAL CONTRACTING</p>
        </div>
        <div className="box_container bg-orange">
          <p>SMART CRM</p>
        </div>
        <div className="box_container bg-orange">
          <p>Personal Manager</p>
        </div>
        <div className="box_container bg-gray">
          <p>Domain and Hosting</p>
        </div>
        <div className="box_container bg-gray">
          <p>Dealer Management Software</p>
          <p>(DMS)</p>
        </div>
        <div className="box_container bg-orange">
          <p>DIGITAL FAX</p>
        </div>
        <div className="box_container bg-orange">
          <p>DAC GOOGLE POWER</p>
        </div>
        {/* <div className="box_container bg-gray">
          <p>QUALIFIED</p>
          <p>LEEDS</p>
        </div> */}
        <div className="box_container bg-gray">
          <p>LEADS</p>
        </div>
        <div className="box_container bg-orange">
          <p>FACEBOOK MARKET</p>
          <p>SERVICE</p>
        </div>
        <div className="box_container bg-orange">
          <p>LOGICSTIC SERVICE</p>
        </div>
        <div className="box_container bg-gray">
          <p>INVENTORY MANAGEMENT</p>
        </div>
        <div className="box_container bg-gray">
          <p>WEBSITE FOR DEALER</p>
        </div>
        <div className="dealer_login_left_center">
          <span>ALL</span>
          <span>IN</span>
          <span>ONE</span>
        </div>
      </div>
    </div>
  );
};

export default Dealer_left_Section;

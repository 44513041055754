import React, { useEffect, useState } from "react";
import Styles from "../DealerSetupUser/setup.module.css";
import { Button } from "react-bootstrap";
const LeadNotification = ({ formData, setFormData ,userEditData}) => {
 
  const [isToggledSms, setIsToggledSms] = useState(false);
  const [isToggledMail, setIsToggledMail] = useState(false);

  const handleToggalSms = () => {
    setIsToggledSms(!isToggledSms);
  };

  const handleToggalMail = () => {
    setIsToggledMail(!isToggledMail);
  };
  console.log("isToggledSms", isToggledSms);
  console.log("isToggledMail", isToggledMail);
  // -----------------------------------------------------------------------------
  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      sms_notify: isToggledSms,
      email_notify: isToggledMail,
    }));
  }, [isToggledSms, isToggledMail]);
  useEffect(() => {
    if (userEditData) {
      setIsToggledSms(userEditData.sms_notify);
      setIsToggledMail(userEditData.email_notify);
    }
  }, [userEditData]);
  // console.log("userEditData",userEditData);
  // console.log("userEditData.sms_notify",userEditData?.sms_notify);
  // console.log("userEditData.email_notify",userEditData?.email_notify);
 
  // -------------------------------------------------------------------------------
  return (
    <div className={Styles.NotificationWrapper}>
      <p className={` mb-0 ${Styles.NotificationHeader}`}>Send Leads Notification to:</p>
      <div className={Styles.NotificationContainer}>
        <div className={Styles.NotificationCard}>
          <p>
            <span>Yes notify me by SMS</span>
          </p>
          <p className={`px-2 ${Styles.toggleContainer}`}>
            <Button
              className={`p-0 border-0 ${Styles.toggleButton} ${isToggledSms ? Styles.toggled : ''}`}
              onClick={handleToggalSms} >
              {isToggledSms ? 'Yes' : 'No'}
            </Button>
          </p>
        </div>
        <div className={Styles.NotificationCard}>
          <p>
            <span>Yes notify me by email</span>
          </p>
          <p className={`px-2 ${Styles.toggleContainer}`}>
            <Button
              className={`p-0 border-0 ${Styles.toggleButton} ${isToggledMail ? Styles.toggled : ''}`}
              onClick={handleToggalMail} >
              {isToggledMail ? 'Yes' : 'No'}
            </Button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LeadNotification;

// MainMarquee.js (second component)

import Image from "../../../imgData";


export const filteredImage = {
    honda: Image.honda,
    landrover: Image.landrover,
    mazda: Image.mazda,
    mercedesbenz: Image.mercedesbenz,
    mini: Image.mini,
    toyota: Image.toyota,
    alfaromeo: Image.alfaromeo,
    bentley: Image.bentley,
    mitsubishi: Image.mitsubishi,
    maserati: Image.maserati,
    volvo: Image.volvo,
    porsche: Image.porsche,
    volkswagen: Image.volkswagen,
    lamborghini: Image.lamborghini,
    audi: Image.audi,
    bmw: Image.bmw,
    buick: Image.buick,
    chevrolet: Image.chevrolet,
    chrysler: Image.chrysler,
    dodge: Image.dodge,
    ferrari: Image.ferrari,
    fiat: Image.fiat,
    ford: Image.ford,
    gmc: Image.gmc,
    ram: Image.ram,
    acura: Image.acura,
    astonmartin: Image.astonmartin,
    cadillac: Image.cadillac,
    genesis: Image.genesis,
    hyundai: Image.hyundai,
    infiniti: Image.infiniti,
    jaguar: Image.jaguar,
    jeep: Image.jeep,
    kia: Image.kia,
    lexus: Image.lexus,
    mclaren: Image.mclaren,
    nissan: Image.nissan,
    // peugeot: Image.peugeot,
    // renault: Image.renault,
    rollsroyce: Image.rollsroyce,
    subaru: Image.subaru,
    tesla: Image.tesla,
  };
  
import React, { useState } from "react";
import Image from "../../imgData";
import { Link } from "react-router-dom";

const DealerfacilitiesHeader = () => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div className="dealer_facilities_header_wrapper">
      <div className="dealer_facilities_header_container">
        <div className="facilties_logo_container_dealer">
          <Link to="/">
            <span>DEALERS</span>
            <span>AUTO</span>
            <span>CENTER</span>
          </Link>
        </div>
        {/* <img src={Image.logoDac} alt="dac logo" /> */}
        <p>
          {" "}
          <Link to="/dealer/login" style={{ color: "white" }}>
            <img
              src={isHovered ? Image.logina : Image.loginb}
              style={{ height: "35px", width: "35px" }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              alt="Login"
            />
            {isHovered && (
              <div
                style={{
                  position: "absolute",
                  // bottom: "30px",
                  // left: "50%",
                  transform: "translateX(-50%)",
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                  color: "white",
                  padding: "5px",
                  borderRadius: "4px",
                  whiteSpace: "nowrap",
                  zIndex: 1,
                }}
              >
                Sign In
              </div>
            )}
          </Link>
        </p>
      </div>
    </div>
  );
};

export default DealerfacilitiesHeader;

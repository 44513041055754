import React, { useCallback, useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../home.css";
import Image from "../../../imgData";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Arrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

const VehicleSlider1 = ({ route }) => {
  const [filters, setFilters] = useState({
    make: [],
    models: [],
    year: [],
    engineType: [],
    fuelType: [],
    vehicleType: [],
    owner: [],
    state: [],
    interiorColor: [],
    exteriorColor: [],
    interiorType: [],
    doorCounts: [],
    driveTrain: [],
    odometer: [],
    price: [],
    exterior_damage: [],
    photos: [],
    seller: [],
    zip_code: [],
  });

  // Initialize vehicle types with default counts of "0"
  const [vehicleTypes, setVehicleTypes] = useState([
    { image: Image.slider_icon1, type: "TRUCK", count: "0" },
    { image: Image.slider_icon2, type: "Coupe", count: "0" },
    { image: Image.slider_icon3, type: "Sedan", count: "0" },
    { image: Image.crossover, type: "CROSSOVER", count: "0" },
    { image: Image.suv, type: "SUV", count: "0" },
    { image: Image.hatchback, type: "Hatchback", count: "0" },
    { image: Image.convertible, type: "Convertible", count: "0" },
    { image: Image.incomplete, type: "INCOMPLETE VEHICLE", count: "0" },
    { image: Image.passanger, type: "PASSENGER CAR", count: "0" },
    { image: Image.mpv, type: "MPV", count: "0" },
  ]);

  const [responseFilter, setResponseFilter] = useState({});

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/listing/filters`,
          filters
        );
        setResponseFilter(response.data);
        updateVehicleCounts(response.data.vehicle_type); // Update counts after fetching data
      } catch (error) {
        console.log("Server side while fetching data during getting filters");
      }
    };
    fetchProducts();
  }, [filters]);

  // Function to update vehicle counts based on API response
  const updateVehicleCounts = (vehicleTypeData) => {
    const updatedVehicleTypes = vehicleTypes.map((vehicle) => {
      const matchingType = vehicleTypeData.find(
        (item) => item.vehicleType === vehicle.type
      );
      return {
        ...vehicle,
        count: matchingType ? matchingType.count : "0", // Update count or set to "0"
      };
    });
    setVehicleTypes(updatedVehicleTypes);
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    nextArrow: <Arrow />,
    prevArrow: <Arrow />,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/dealer/product1", { state: route });
  };

  return (
    <section className="p-4" style={{ cursor: "pointer" }}>
      <div className="container-fluid">
        <div className="col-lg-12">
          <h3 style={{ textAlign: "center" }}>
            <span>Shop By Vehicle Type</span>
          </h3>
          <div className="row">
            <div className="col-md-12">
              <Slider {...settings}>
                {vehicleTypes.map((vehicle) => (
                  <div
                    className="owl-item"
                    key={vehicle.type}
                    onClick={handleNavigate}
                  >
                    <img
                      src={vehicle.image}
                      alt={vehicle.type}
                      style={{ width: "230px", height: "200px" }}
                      className="vehicleSliderImage"
                    />
                    <div
                      className="shadow text-center p_U"
                      style={{ width: "30%" }}
                    >
                      <p>
                        <b>{vehicle.type}</b>
                        <p>{vehicle.count}</p>
                      </p>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VehicleSlider1;

import React, { useEffect, useState } from "react";
import Image from "../../imgData";
import "../Styles/Dealer_SetPass_Signup.css";
import Socialchild from "../../UserAuthDialogue/components/Socialchild";
import { BiHide } from "react-icons/bi";
import { GoEye } from "react-icons/go";
import Fingerprint2 from "fingerprintjs2";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { saveUser } from "../../../Redux/slice/userSlice";
import { UserCity } from "../../UserAuthDialogue/Pages/UserCity";
import toast from "react-hot-toast";

const Dealer_signup_setPass_feature = () => {
  const [showPass, setShowPass] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [InputPass, setInputPass] = useState("");
  const [InputPassError, setInputPassError] = useState(null);

  const [InputConfirmPass, setInputConfirmPass] = useState("");
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [InputConfirmPassError, setInputConfirmPassError] = useState("");

  const [passwordMatch, setPasswordMatch] = useState(true);
  const [fingerprint, setFingerprint] = useState("");
  const [ip, setIP] = useState("");
  console.log(fingerprint);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  console.log(
    "longitude in  Dealer Signup=> ",
    longitude,
    "latitude in Dealer Sign up=>",
    latitude
  );

  useEffect(() => {}, [InputPass, InputConfirmPass]);
  useEffect(() => {
    const getDeviceFingerprint = () => {
      return new Promise((resolve, reject) => {
        Fingerprint2.get((components) => {
          const values = components.map((component) => component.value);
          const fingerprintId = Fingerprint2.x64hash128(values.join(""), 31);
          resolve(fingerprintId);
        });
      });
    };

    const fetchFingerprint = async () => {
      try {
        const fingerprintId = await getDeviceFingerprint();
        setFingerprint(fingerprintId);
      } catch (error) {
        console.error("Error getting fingerprint:", error);
      }
    };

    // Wait for fingerprint to be generated before rendering the component
    const timeout = setTimeout(() => {
      fetchFingerprint();
    }, 1000); // Adjust timeout value as needed

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}$/;

  const handleChangeView = () => {
    setShowPass(!showPass);
  };
  const handleChangeView2 = () => {
    setShowConfirmPass(!showConfirmPass);
  };

  const handleInputChange = (e) => {
    setInputPass(e.target.value);

    if (!passwordRegex.test(e.target.value)) {
      setInputPassError(
        "Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, and one special character."
      );
    } else {
      setInputPassError(null);
    }

    if (InputConfirmPass && e.target.value !== InputConfirmPass) {
      setPasswordMatch(false);
    } else {
      setPasswordMatch(true);
    }
  };

  const handleInputChange2 = (e) => {
    setInputConfirmPass(e.target.value);
    if (!passwordRegex.test(e.target.value)) {
      setInputConfirmPassError(
        "Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, and one special character."
      );
    } else {
      setInputConfirmPassError(null);
    }

    if (InputPass && e.target.value !== InputPass) {
      setPasswordMatch(false);
    } else {
      setPasswordMatch(true);
    }
  };

  useEffect(() => {
    if (
      passwordMatch &&
      !InputPassError &&
      !InputConfirmPassError &&
      InputPass &&
      InputConfirmPass
    ) {
      PassMatchAPI();
    }
  }, [
    passwordMatch,
    InputPass,
    InputConfirmPass,
    InputConfirmPassError,
    InputPassError,
  ]);

  const getUserIPaddress = async () => {
    try {
      const res = await axios.get("https://api.ipify.org/?format=json");
      console.log("User IP Address", res);
      setIP(res.data.ip);
    } catch (error) {
      console.log("Error while getting ip", error);
    }
  };
  useEffect(() => {
    getUserIPaddress();
  }, [ip]);

  const [countryCode, setCountryCode] = useState("");
  console.log(countryCode);
  useEffect(() => {
    const getUserCountryCode = async () => {
      try {
        const res = await axios.get("https://ipapi.co/json");
        console.log("User all info", res.data);
        setCountryCode(res.data.country_calling_code);
      } catch (error) {
        console.log(error);
      }

      // setLongitude(res.data.latitude);
      // setLatitude(res.data.longitude);
      // setIP(res.data.ip)
    };
    getUserCountryCode();
  }, [countryCode]);

  const location = useLocation();
  console.log("location.state in setPass in dealer section", location.state);

  const PassMatchAPI = async () => {
    console.log("Calling the API...");
    const formData = new FormData();
    try {
      formData.append("password", InputPass);
      formData.append("email", location.state);
      formData.append("confirmpassword", InputConfirmPass);
      formData.append("fingerprint", fingerprint);
      formData.append("ip", ip);
      formData.append("country_code", countryCode);
      formData.append("user_type", "Dealer");
      formData.append("account_status", 0);

      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/signup`,
        formData
      );
      console.log("Response after saving new user", res);
      if (res.status === 200) {
        dispatch(
          saveUser({
            email: res.data.email,
            id: res.data.id,
            dealerId: res.data.dealerId,
            phoneNumber: res.data.phoneNumber,
            // firstName: res.data.firstName,
            // lastName: res.data.lastName,
            office_number: res.data.office_number,
            lattitude: res.data.lattitude,
            longitude: res.data.longitude,
            zip_code: res.data.zip_code,
            address: res.data.address,
            // profile_picture: res.data.profile_picture,
            website: res.data.website,
            roles: res.data.roles,
            loginAccess: res.data.loginAccess,
            dealerApproval: res.data.dealerApproval,
            dealerData: res.data.dealerData,
          })
        );
        navigate("/dealer/dashboard");
        toast.success("Dealer registration successful");
        // navigate("/myprofile/dashboard")
      }

      console.log(res);
    } catch (error) {
      console.log("Error in catch part", error);
      toast.error("Server side error during dealer registration");
    }
  };

  return (
    <div className="login_feature_wrapper">
      <div className="login_feature_container">
        <p
          className="welcome_message"
          style={{ fontWeight: "700", fontSize: "xx-large" }}
        >
          Dealer Log-in
        </p>
        {/* <p className="sign_in_message">
          Please enter and confirm your new password
        </p> */}
        {/* <label htmlFor="new_password" className="email_username_label">
          New Password *
        </label> */}
        <div className="input_and_icon">
          <input
            type={!showPass ? "password" : "text"}
            onChange={handleInputChange}
            value={InputPass}
            id="new_password"
            placeholder="Enter Password"
            style={{ fontWeight: "700", fontSize: "xx-large" }}
          />
          <img
            src={!showPass ? Image.eye_icon : Image.hide_icon}
            alt="hide/unhide_icon"
            height={20}
            onClick={handleChangeView}
          />
        </div>
        <p className="error_pass_set">{InputPassError}</p>

        <div className="input_and_icon" style={{ marginTop: "4rem" }}>
          <input
            type={!showConfirmPass ? "password" : "text"}
            onChange={handleInputChange2}
            value={InputConfirmPass}
            placeholder="Confirm Password"
            id="confirm_password"
            style={{ fontWeight: "700", fontSize: "xx-large" }}
          />
          <img
            src={!showConfirmPass ? Image.eye_icon : Image.hide_icon}
            alt="hide/unhide_icon"
            height={20}
            onClick={handleChangeView2}
          />
        </div>
        <p className="error_pass_set">{InputConfirmPassError}</p>
        {!passwordMatch && (
          <p style={{ color: "red" }}>Passwords do not match.</p>
        )}
      </div>
    </div>
  );
};

export default Dealer_signup_setPass_feature;

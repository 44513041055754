import React, { useState } from "react";
import Image from "../../imgData";
import { useDispatch, useSelector } from "react-redux";
import "../Styles/CustomerUserDetailModal.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { saveUser } from "../../../Redux/slice/userSlice";
import { MdMyLocation } from "react-icons/md";
import { DealerGeoAPI } from "../../DealerSection/DealerBusinessInformation/DealerGeoAPI";

const CompleteUserDetailModal = ({setShowDialogueBox}) => {
  const { id } = useSelector((state) => state.user);
  console.log("id of the user from the store in Complete Profile Modal", id);
  const dispatch = useDispatch();

  const [file, setFile] = useState(null);
  const [filePreview, setfilePreview] = useState(null);
  const [isImageSelected, setIsImageSelected] = useState(false);
  const [formData, setformData] = useState({
    firstName: "",
    lastName: "",
    id: id,
    two_factor_authentication: false,
    zip_code: "",
  });
  console.log(formData);

  const handleFileChange = (e) => {
    const selectedfile = e.target.files[0];
    if (selectedfile) {
      setFile(selectedfile);
      setIsImageSelected(true);

      // Validate file size (not more than 3 MB)
      if (selectedfile.size > 2 * 1024 * 1024) {
        console.log("File size should not exceed 3 MB");
        return;
      }

      // Validate file type (JPG or PNG)
      if (!["image/jpeg", "image/png"].includes(selectedfile.type)) {
        console.log("File format should be JPG or PNG");
        return;
      }

      const previewurl = URL.createObjectURL(selectedfile);
      setfilePreview(previewurl);
    } else {
      setIsImageSelected(false);
    }
  };

  const handleNameChange = (e) => {
    const { name, value } = e.target;
    setformData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmitProfileDetails = async () => {
    const fd = new FormData();
    try {
      fd.append("profile_picture", file);
      fd.append("data", JSON.stringify(formData));
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/update_profile`,
        fd
      );

      console.log("response from modal after saving the user details", res);
      if (res.status === 200) {
        // navigate("/myprofile/dashboard")
        dispatch(
          saveUser({
            id: res.data.id,
            firstName: res.data.firstName,
            lastName: res.data.lastName,
            profile_picture: res.data.profile_picture,
            showUserCompletePofileDialogueBox: false,
          })
        );
        // navigate("/myprofile/dashboard")
        setShowDialogueBox(false)
      }
    } catch (error) {
      console.log("Server Side error while submitting User Details", error);
    }
  };

  const isSaveButtonDisabled = () => {
    return (
      formData.firstName.length < 3 ||
      formData.lastName.length < 3 ||
      formData.zip_code.length < 5
    );
  };
  const handleLocation = async () => {
    try {
      const res = await DealerGeoAPI();
      console.log(res);
      setformData((prevData) => ({
        ...prevData,
        zip_code:res.zipCode, // Updating zip_code in state
      }));
    } catch (error) {
      console.log("API Side Error");
    }
  };
  return (
    <div className="modal_wrapper">
      <div className="modal_form_container">
        <h4>Please Complete Your Profile</h4>
        <div className="profile_image_container">
          {filePreview ? (
            <img
              src={filePreview && filePreview}
              height={200}
              width={200}
              alt="profile picture"
              className="profile_image_modal"
              onClick={() =>
                document.getElementById("imagechoosebutton").click()
              }
            />
          ) : (
            <img
              src={Image.upload_icon}
              height={70}
              width={70}
              alt="profile picture"
              className="profile_image_modal"
              style={{ backgroundColor: "white" }}
              onClick={() =>
                document.getElementById("imagechoosebutton").click()
              }
            />
          )}

          <input
            type="file"
            name="profilePicture"
            onChange={handleFileChange}
            id="imagechoosebutton"
          />
        </div>
        <div
          className="first_last_name_container"
          style={{ position: "relative" }}
        >
          <input
            type="text"
            placeholder="First Name"
            name="firstName"
            value={formData.firstName}
            onChange={handleNameChange}
          />
          <input
            type="text"
            placeholder="Last Name"
            name="lastName"
            value={formData.lastName}
            onChange={handleNameChange}
          />

          <input
            type="text"
            placeholder="Zip Code"
            name="zip_code"
            value={formData.zip_code}
            onChange={handleNameChange}
          />
          <span
            style={{
              position: "absolute",
              color: "orange",
              fontSize: "x-large",
              right: "1rem",
              top: "0.7rem",
            
            }}
            onClick={handleLocation}
          >
          
            <MdMyLocation />
          </span>
        </div>
        <div className="save_button_container">
          <button
            onClick={handleSubmitProfileDetails}
            disabled={isSaveButtonDisabled()}
            className={`button ${isSaveButtonDisabled() ? "disabled" : ""}`}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default CompleteUserDetailModal;

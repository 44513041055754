import React from 'react'
import StarCard from '../components/StarCard'
import { Col, Container, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'

import { MdDelete, } from "react-icons/md";
import { IoMdMailUnread } from "react-icons/io";
import { GrRefresh } from "react-icons/gr";

import styles from '../mail.module.css'

const DealerStars = ({ handleActivePage }) => {
  return (
    <Container className='px-4'>
      <Row className={` d-flex justify-content-between py-2 ${styles.subNav}`}>
        <Col sm={1}>
          <OverlayTrigger
            placement="bottom-start"
            overlay={
              <Tooltip id={`tooltip-refresh`} className="custom-tooltip">
                Refresh
              </Tooltip>
            }>
            <span> <GrRefresh className={` ${styles.refreshIcon}`} /></span>
          </OverlayTrigger>
        </Col>
        <Col md={8} className={` ${styles.subNavRight}`}>
          <div className={` ${styles.count}`}>&lt;<span>100 </span> of <span> 758</span>&gt;</div>
          <div className={` ${styles.select}`}><input type='checkbox' name='' id='' /> Select All</div>
          <OverlayTrigger
            placement="bottom-start"
            overlay={
              <Tooltip id={`tooltip-Unread`} className="custom-tooltip">
                Unread Mails
              </Tooltip>
            }>
            <span className={` ${styles.deleteIcon}`}><IoMdMailUnread /></span>
          </OverlayTrigger>
          <OverlayTrigger
            placement="bottom-start"
            overlay={
              <Tooltip id={`tooltip-delete`} className="custom-tooltip">
                Delete
              </Tooltip>
            }>
            <div className={` ${styles.deleteIcon}`}><MdDelete /></div>
          </OverlayTrigger>

          <div className={` ${styles.filter}`} >
            <select>
              <option>newest</option>
              <option>oldest</option>
            </select>
          </div>
        </Col>
      </Row>
      <Row>
        <StarCard handleActivePage={handleActivePage} />
        <StarCard handleActivePage={handleActivePage} />
        <StarCard handleActivePage={handleActivePage} />
        <StarCard handleActivePage={handleActivePage} />
      </Row>
    </Container>
  )
}

export default DealerStars
import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styles from "../Styles/mailAddAcc.module.css";

const DealerAutoResponse = () => {

  const [responseMsg, setResponseMsg] = useState({
    msgEnable: false,
    subject: '',
    message: '',

  });

  const [responseSlot, setResponseSlot] = useState({
    slotEnable: false,
    startTimeMonth: '',
    startTimeDate: '',
    startTimeYear: '',
    startTime: '',

    FinishTimeMonth: '',
    FinishTimeDate: '',
    FinishTimeYear: '',
    FinishTime: '',
  });

  // Handle change for text inputs for message contents
  const handleMsgChange = (event) => {
    const { name, type, value, checked } = event.target;
    setResponseMsg({
      ...responseMsg,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  // Handle change for text inputs for Slot of response
  const handleSlotChange = (event) => {
    const { name, type, value, checked } = event.target;

    if (type === 'month') {
      const [year, month] = value.split('-');
      setResponseSlot({
        ...responseSlot,
        [name]: month
      });
    } else {
      setResponseSlot({
        ...responseSlot,
        [name]: type === 'checkbox' ? checked : value
      });
    }


  };

  const handleSubmitResMsg = (event) => {
    event.preventDefault();
    console.log('Form submitted:', responseMsg);
  };

  const handleSubmitResSlot = (event) => {
    event.preventDefault();
    console.log('Form submitted:', responseSlot);
  }

  return (
    <Container className={`p-4`}>
      <Row>
        <Col sm={12}>
          <h5 className={` ${styles.settingHead}`}>Auto Response</h5>
        </Col>
        <Col sm={8}>
          <form onSubmit={handleSubmitResMsg} className={` ${styles.form}`}>
            <div className={` ${styles.pop}`}>
              <p> <input
                type='checkbox'
                name='msgEnable'
                checked={responseMsg.msgEnable}
                onChange={handleMsgChange}
              />
                Enable auto response</p>

              <label>
                Subject:
                <input
                  type="text"
                  name="subject"
                  value={responseMsg.subject}
                  onChange={e => handleMsgChange(e)}
                />
              </label>
              <label>
                Message Contents:
                <textarea
                  name="message"
                  value={responseMsg.message}
                  onChange={e => handleMsgChange(e)}
                  rows="4"  // Adjust rows to control the height of the textarea
                  cols="50" // Adjust cols to control the width of the textarea
                />
              </label>
            </div>
            <button className={` ${styles.saveBtn}`} type="submit">Save</button>
          </form>
        </Col>

        <Col sm={8} className={`mt-5`}>
          <form onSubmit={handleSubmitResSlot} className={` ${styles.form}`}>
            <div className={` ${styles.pop}`}>
              <p>
                <input
                  type='checkbox'
                  name='slotEnable'
                  checked={responseSlot.slotEnable}
                  onChange={handleSlotChange}
                />
                Only send response between these times</p>

              <label className={` `}>
                Start Time:
                <input
                  type="text"
                  name="startTimeYear"
                  value={responseSlot.startTimeYear}
                  onChange={e => handleSlotChange(e)}
                />
                {/* <input
                  type="month"
                  name="startTimeMonth"
                   value={`${new Date().getFullYear()}-${responseSlot.startTimeMonth}`} // Set initial value
                  onChange={e => handleSlotChange(e)}
                />
                <input
                  type="date"
                  name="startTimeDate"
                  value={responseSlot.startTimeDate}
                  onChange={e => handleSlotChange(e)}
                /> 
                <input
                  type="text"
                  name="startTimeYear"
                  value={responseSlot.startTimeYear}
                  onChange={e => handleSlotChange(e)}
                />
                <input
                  type="time"
                  name="startTime"
                  value={responseSlot.startTime}
                  onChange={e => handleSlotChange(e)}
                /> */}
              </label>

              <label className={` `}>
                Finish Time:
                <input
                  type="text"
                  name="FinishTimeYear"
                  value={responseSlot.FinishTimeYear}
                  onChange={e => handleSlotChange(e)}
                />
                {/* <input
                  type="month"
                  name="FinishTimeMonth"
                  value={responseSlot.FinishTimeMonth}
                  onChange={e => handleSlotChange(e)}
                />
                <input
                  type="date"
                  name="FinishTimeDate"
                  value={responseSlot.FinishTimeDate}
                  onChange={e => handleSlotChange(e)}
                /> 
                <input
                  type="text"
                  name="FinishTimeYear"
                  value={responseSlot.FinishTimeYear}
                  onChange={e => handleSlotChange(e)}
                />
               <input
                  type="time"
                  name="FinishTime"
                  value={responseSlot.FinishTime}
                  onChange={e => handleSlotChange(e)}
                /> */}
              </label>
            </div>
            <button className={` ${styles.saveBtn}`} type="submit">Save</button>
          </form>
        </Col>
      </Row>
    </Container>
  )
}

export default DealerAutoResponse
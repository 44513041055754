import React from 'react'
import { Modal } from 'react-bootstrap'
import DealerAddUser from './DealerSetupUser/DealerAddUser'


const AddUser = ({show, handleClose,setDeleteCount}) => {
  return (
    <>
    <Modal
        show={show} size="xl" onHide={handleClose} dialogClassName='modal-90w'
      >
        <Modal.Header closeButton>
          <Modal.Title>Add User Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <DealerAddUser handleClose={handleClose} setDeleteCount={setDeleteCount}/>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal></>
  )
}

export default AddUser
import React from 'react'
import Image from '../../imgData'

const DealerProductsCard = ({text}) => {
  return (
    <div className='dealer_product_card_wrapper'>
        <div  className='dealer_product_card_container'>
            <p>{text}</p>
            <img src={Image.dealer_inventory_tax_maker} alt="dealer products and facilities" />
            <span>Learn More</span>
        </div>
    </div>
  )
}

export default DealerProductsCard
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams, useLocation, } from 'react-router-dom';
import { Col, Container, Nav, Row, Button, Tab, Form, Dropdown } from "react-bootstrap";
import Autocomplete from 'react-google-autocomplete';
import axios from "axios";
import Image from '../../imgData';
import "./vehicle-list.css"
import "./Sell.css";
import "./VehicleSaleDetails.css"
import "./forms.css"
import VehicleList from "./VehicleList";
import Payment from "./Payment";
import Forms from "./Forms";
import TradeIn from "./TradeIn";
import { fetchGeoMapData } from "./geoAPI";
import PricesFees from "./PricesFees";
import { useDispatch, useSelector } from 'react-redux';
import { setVehicleData, setSelectedVehicle, setLoading, setError, setActiveTab, setSellData, setActiveTabIndex, incrementTabIndex, decrementTabIndex, saveSellData } from '../../../Redux/slice/sellSlice';
import BuyerInfo from "./BuyerInfo";

const apiUrl = process.env.REACT_APP_API_URL;

const Sell = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { data: item } = location.state || {};

  const activeTab = useSelector(state => state.sell.activeTab);
  const vehicleData = useSelector(state => state.sell.vehicleData);
  const isLoading = useSelector(state => state.sell.isLoading);
  const error = useSelector(state => state.sell.error);
  const selectedVehicle = useSelector(state => state.sell.selectedVehicle);
  const activeTabIndex = useSelector(state => state.sell.activeTabIndex);
  // console.log("Selected verhcile", selectedVehicle)
  // console.log("Active tab index", activeTabIndex)
  // const { stockNo, id } = useParams();

  const sellData = useSelector(state => state.sellData);
  const { id } = useSelector((state) => state.user);
  // const vehicleData = useSelector(state => state.sell.vehicleData);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setLoading(true));
        const response = await fetch(`${apiUrl}/listing/get_user_listing/${id}`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        dispatch(setVehicleData(data));
        // setVehicleData(data)
      } catch (error) {
        dispatch(setError(error.message));
      } finally {
        dispatch(setLoading(false));
      }
    };

    fetchData();
  }, [dispatch]);
  const handleSort = (order = 'newest') => {
    if (vehicleData.length > 0) {
      console.log("<<<<<<<<<<<<<order>>>>>>>>>>>>", order);
      const sorted = [...vehicleData].sort((a, b) => b.id - a.id); // Sort in descending order by id
      // setSortedData(sorted);

      // dispatch(setVehicleData(sorted));
      console.log('sortedData', vehicleData);
    } else {
      console.log('sortedData not set', vehicleData);
    }
  };

  useEffect(() => {
    if (location.state) {
      dispatch(setActiveTab("buyer"));
      dispatch(setActiveTabIndex(1));
    }
    console.log("Received data:", location.state);
    console.log("item", item);
  }, [location.state]);

  useEffect(() => {
    console.log("Received data:", location.state);
    dispatch(setSelectedVehicle(item));
  }, [location.state]);

  const handleSelectedVehicle = (vehicle) => {
    dispatch(setSelectedVehicle(vehicle));
    handleTabSelect("buyer");
  };

  const handleInputChange = (field, value) => {
    dispatch(setSellData({ [field]: value }));
  };

  const handleTabSelect = (tab) => {
    dispatch(setActiveTab(tab));

    // dispatch(saveSellData()).then(() => {
    //   dispatch(setActiveTab(tab));
    // });

  };


  // For Phone number 

  const [phoneNumbers, setPhoneNumbers] = useState(['']);

  // Function to add a new phone number field
  const addPhoneNumber = () => {
    if (phoneNumbers.length < 2) {
      setPhoneNumbers([...phoneNumbers, '']);
    }
  };

  // Function to handle phone number input change
  const handlePhoneNumberChange = (index, value) => {
    const updatedPhoneNumbers = phoneNumbers.map((phone, i) =>
      i === index ? value : phone
    );
    setPhoneNumbers(updatedPhoneNumbers);
  };

  // Function to delete a phone number field
  const deletePhoneNumber = (index) => {
    if (phoneNumbers.length > 1) {
      setPhoneNumbers(phoneNumbers.filter((_, i) => i !== index));
    }
  };


  // const autocompleteRef = useRef(null);


  // const handlePlaceSelected = (place) => {
  //   const addressComponents = place.address_components;
  //   const formattedAddress = place.formatted_address;
  //   let city = '';
  //   let state = '';
  //   let zipCode = '';
  //   let country = '';

  //   addressComponents.forEach(component => {
  //     const types = component.types;
  //     if (types.includes('locality')) {
  //       city = component.long_name;
  //     }
  //     if (types.includes('administrative_area_level_1')) {
  //       state = component.short_name;
  //     }
  //     if (types.includes('postal_code')) {
  //       zipCode = component.long_name;
  //     }
  //     if (types.includes('country')) {
  //       country = component.long_name;
  //     }
  //   });

  //   setSellData({
  //     address: formattedAddress,
  //     city,
  //     state,
  //     zipCode,
  //     country
  //   });
  // };


  // // For zip code

  // const handleZipCodeChange = async (e) => {
  //   const zip = e.target.value;
  //   setSellData({ ...sellData, zipCode: zip });

  //   if (zip.length === 5) {
  //     try {
  //       const response = await axios.get(`https://api.zippopotam.us/us/${zip}`);
  //       const data = response.data;
  //       // setCity(data.places[0]['place name']);
  //       // setState(data.places[0]['state']);
  //       // setCountry(data['country abbreviation']);

  //       setSellData({ ...sellData, city: data.places[0]['place name'] })
  //       setSellData({ ...sellData, state: data.places[0]['state'] })
  //       setSellData({ ...sellData, country: data['country abbreviation'] })
  //     } catch (error) {
  //       console.error("Error fetching location data:", error);
  //     }
  //   }
  // };


  const tabs = ['Vehicle List', 'Buyer', 'Prices and Fees', 'Forms'];

  const currentTabIndex = activeTabIndex;

  const handleNext = () => {
    // if (!selectedVehicle) {
    //   console.log('Please select a vehicle first');
    //   return;
    // }
    dispatch(incrementTabIndex());
  };

  const handlePrevious = () => {
    dispatch(decrementTabIndex());
  };



  // console.log("selectedVehicle", selectedVehicle);
  // console.log("current", currentTabIndex);
  // console.log("activeTab", activeTab);
  return (
    <div>
      <Container fluid className="main-container px-4">
        <Row>
          <Tab.Container activeKey={activeTab} >
            <span id="btn-buyer-payment" className=" px-0">
              <Nav fill variant="tabs" className="justify-content-center">

                {tabs.map((tab, index) => (
                  <Nav.Item key={index}>
                    <Nav.Link
                      eventKey={tab}
                      className={activeTab === tab ? "active px-0" : "px-0"}
                      onClick={() => handleTabSelect(tab)}
                    // disabled={selectedVehicle === null && tab !== 'Vehicle List'}
                    // style={{ color: selectedVehicle === null && tab !== 'Vehicle List' ? 'gray' : '#e8a262' }}
                    >
                      {tab}
                    </Nav.Link>
                  </Nav.Item>
                ))}

                {/* <Nav.Item>
                  <Nav.Link eventKey="Vehicle List" className={activeTab === "Vehicle List" ? "active px-0" : "px-0"}
                    onClick={() => handleTabSelect("Vehicle List")}>
                    Vehicle List
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link
                    eventKey="Buyer"
                    className={activeTab === "Buyer" ? "active px-0" : "px-0"}
                    onClick={() => handleTabSelect("Buyer")}
                    disabled={selectedVehicle === null}
                    style={{ color: selectedVehicle === null ? 'gray' : '#e8a262' }}>
                    Buyer Info
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey="Co Buyer" className="px-0"
                    disabled={selectedVehicle === null}
                    style={{ color: selectedVehicle === null ? 'gray' : '#e8a262' }}>
                    Co-Buyer Info
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link
                    eventKey="Trade In"
                    className={activeTab === "Trade In" ? "active px-0" : "px-0"}
                    onClick={() => handleTabSelect("Trade In")}
                    disabled={selectedVehicle === null}
                    style={{ color: selectedVehicle === null ? 'gray' : '#e8a262' }}>
                    Trade In
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link
                    eventKey="Prices and Fees"
                    className={activeTab === "Prices and Fees" ? "active px-0" : "px-0"}
                    onClick={() => handleTabSelect("Prices and Fees")}
                    disabled={selectedVehicle === null}
                    style={{ color: selectedVehicle === null ? 'gray' : '#e8a262' }}>
                    Prices & Fees</Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link
                    eventKey="Forms"
                    className={activeTab === "Forms" ? "active px-0" : "px-0"}
                    onClick={() => handleTabSelect("Forms")}
                    disabled={selectedVehicle === null}
                    style={{ color: selectedVehicle === null ? 'gray' : '#e8a262' }}>
                    Forms
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link
                    eventKey="Payment"
                    className={activeTab === "Payment" ? "active px-0" : "px-0"}
                    onClick={() => handleTabSelect("Payment")}
                    disabled={selectedVehicle === null}
                    style={{ color: selectedVehicle === null ? 'gray' : '#e8a262' }}>
                    Payment
                  </Nav.Link>
                </Nav.Item> */}

              </Nav>
            </span>

            <Row>
              <div className="input-contant-container px-0">

                <Tab.Content style={{ minHeight: '110% !importent' }}>

                  <Tab.Pane eventKey="Vehicle List">
                    <VehicleList vehicleData={vehicleData} isLoading={isLoading} handleTabSelect={handleTabSelect} handleSelectedVehicle={handleSelectedVehicle} />
                  </Tab.Pane>

                  <Tab.Pane eventKey="Buyer">
                    <BuyerInfo selectedVehicle={selectedVehicle} data={item} />
                  </Tab.Pane>

                  {/* <Tab.Pane eventKey="co-buyer">
                <div className="buyer-info mb-5">
                      <Container fluid className="input-user-container">
                        <Row className="py-3">
                          <Col sm={1}>
                            <label htmlFor="inputAccount" className="form-label text-nowrap">Ac. No.</label>
                            <input type="text" value={stockNo} className="form-control-plaintext form-border" />
                          </Col>

                          <Col sm={2}>
                            <label htmlFor="firstName" className="form-label">First Name</label>
                            <input type="text" className="form-control-plaintext form-border" />
                          </Col>
                          <Col sm={1}>
                            <label htmlFor="middleName" className="form-label">M</label>
                            <input type="text" className="form-control-plaintext form-border" />
                          </Col>
                          <Col sm={2}>
                            <label htmlFor="lastName" className="form-label">Last Name</label>
                            <input type="text" className="form-control-plaintext form-border" />
                          </Col>
                          <Col sm={3}>
                            <label htmlFor="inputState" className="form-label">ID.Type</label>
                            <select className="form-select">
                              <option selected>U.S Driver License</option>
                              <option>1</option>
                              <option>2</option>
                              <option>3</option>
                            </select>
                          </Col>

                          <Col sm={3}>
                            <div className="num-state">
                              <div style={{ width: "50%" }}>
                                <label htmlFor="idNumber" className="form-label">ID Number</label>
                                <input type="text" className="form-control-plaintext form-border" />
                              </div>

                              <div style={{ width: "50%" }}>
                                <label htmlFor="state" className="form-label">State</label>
                                <input type="text" className="form-control-plaintext form-border px-3" />
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row className="input-user-container py-5">
                          <Col sm={3}>
                            <label htmlFor="Address" className="form-label">Address</label>

                            <Autocomplete
                              onLoad={autocomplete => (autocompleteRef.current = autocomplete)}
                              onPlaceChanged={handlePlaceSelected}
                              options={{
                                types: ['address'],
                                componentRestrictions: { country: 'us' }
                              }}
                              value={sellData.sellCoBuyer.address} // Update value to sellData.address
                              onChange={(e) => setSellData({ ...sellData, address: e.target.value })} // Update sellData.address on change
                              className="form-control-plaintext form-border"
                            />

                          </Col>
                          <Col sm={1}>
                            <label htmlFor="Apt" className="form-label">Apt#</label>
                            <input type="text" className="form-control-plaintext form-border" />
                          </Col>
                          <Col sm={2}>
                            <label htmlFor="City" className="form-label">City</label>
                            <input type="text" className="form-control-plaintext form-border" value={sellData.sellCoBuyer.city} />
                          </Col>
                          <Col sm={2}>
                            <label htmlFor="State" className="form-label">State</label>
                            <input type="text" className="form-control-plaintext form-border" value={sellData.sellCoBuyer.state} />
                          </Col>
                          <Col sm={2}>
                            <label htmlFor="zipCode" className="form-label">Zip Code</label>
                            <input type="text" className="form-control-plaintext form-border" value={sellData.sellCoBuyer.zipCode} onChange={handleZipCodeChange} />
                          </Col>
                          <Col sm={2}>
                            <label htmlFor="inputState" className="form-label">Country/ Region</label>
                            <select className="form-select" value={country} disabled>
                              <option value={country}>{country}</option>
                            </select>
                          </Col>
                        </Row>

                        <Row className="input-user-container py-3">

                          {phoneNumbers.map((phone, index) => (
                            <Col sm={2}>
                              <label htmlFor={`Phone${index}`} className="form-label text-nowrap">
                                <FaPhoneVolume style={{ fill: "#d1823a" }} /> Phone #{index + 1}
                                {index > 0 && (
                                  <IoMdCloseCircle fill="red" onClick={() => deletePhoneNumber(index)} />
                                )}
                              </label>
                              <input
                                type="text"
                                placeholder="(xxx) xxx-xxxx"
                                className="form-control-plaintext form-border"
                                value={phone}
                                onChange={(e) => handlePhoneNumberChange(index, e.target.value)}
                              />

                            </Col>
                          ))}

                          <Col sm={3}>
                            <label htmlFor="Fax" className="form-label">
                              <FaFax style={{ fill: "#d1823a" }} /> Fax
                            </label>
                            <input type="text" className="form-control-plaintext form-border" />
                          </Col>
                          <Col sm={4}>
                            <label htmlFor="Email" className="form-label">
                              <AiOutlineMail style={{ fill: "#d1823a" }} /> Email
                            </label>
                            <input type="text" placeholder="example@example.com" className="form-control-plaintext form-border" />
                          </Col>

                          <Col sm={1} className="px-0" style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                          }}>
                            <button
                              style={{
                                border: "none",
                                backgroundColor: "#e8a262",
                                borderRadius: "10px",
                                color: "white",
                              }}
                              onClick={addPhoneNumber}
                              disabled={phoneNumbers.length >= 2}
                            >
                              + <FaPhoneVolume style={{ fill: "white" }} />
                            </button>
                          </Col>
                        </Row>
                      </Container>
                    </div> 
                  </Tab.Pane>*/}

                  {/*<Tab.Pane eventKey="Trade In">
                    <TradeIn />
                     <Container fluid className="p-3">
                      <Row className="mb-4 d-flex justify-content-evenly">
                        <Col sm={4} className="text-center px-3">
                          <label htmlFor="vinNo" className="form-label">VIN No.</label>
                          <div className="d-flex half-border ">
                            <input type="text" className="form-control me-2 border-0" id="vinNo" value="" />
                            <Button className="border-0 p-1" style={{ backgroundColor: "white", color: "#e8a262" }} >Decode</Button>
                          </div>
                        </Col>
                        <Col sm={3} className="text-center px-3">
                          <label htmlFor="condition" className="form-label text-center">Condition</label>
                          <select className="form-select half-border " id="condition">
                            <option>Used</option>
                            <option>New</option>
                          </select>
                        </Col>
                        <Col sm={3} className="text-center px-3">
                          <label htmlFor="stockNo" className="form-label">Stock No.</label>
                          <input type="text" className="form-control half-border " id="stockNo" value="" />
                        </Col>

                      </Row>

                      <Row className="mb-4 d-flex justify-content-evenly">
                        <Col sm={3} className="text-center px-3">
                          <label htmlFor="year" className="form-label">Year</label>
                          <input type="text" className="form-control half-border " id="year" value="" />
                        </Col>
                        <Col sm={3} className="text-center px-3">
                          <label htmlFor="make" className="form-label">Make</label>
                          <input type="text" className="form-control half-border " id="make" value="" />
                        </Col>
                        <Col sm={3} className="text-center px-3">
                          <label htmlFor="model" className="form-label">Model</label>
                          <input type="text" className="form-control half-border " id="model" value="" />
                        </Col>
                      </Row>

                      <Row className="mb-4 ">
                        <Col sm={3} className="text-center px-4">
                          <div className="d-flex align-items-center justify-content-center">
                            <img src={Image.fuelType} alt="Fuel Icon" className="me-2" style={{ width: "20px", height: "20px" }} />
                            <label htmlFor="fuelType" className="form-label">Fuel Type</label>
                          </div>
                          <input type="text" className="form-control half-border" id="fuelType" value="" />
                        </Col>
                        <Col sm={3} className="text-center px-4">
                          <div className="d-flex align-items-center justify-content-center">
                            <img src={Image.transmission} alt="Fuel Icon" className="me-2" style={{ width: "20px", height: "20px" }} />
                            <label htmlFor="transmission" className="form-label">Transmission</label>
                          </div>
                          <input type="text" className="form-control half-border " id="transmission" value="" />
                        </Col>
                      </Row>

                      <Row className="mb-4 d-flex justify-content-evenly">
                        <Col sm={3} className="text-center px-3">
                          <div className="d-flex align-items-center justify-content-center">
                            <img src={Image.engineCylinder} alt="Fuel Icon" className="me-2" style={{ width: "20px", height: "20px" }} />
                            <label htmlFor="engineCylinder" className="form-label">Engine Cylinder</label>
                          </div>
                          <input type="text" className="form-control half-border " id="engineCylinder" value="" />
                        </Col>
                        <Col sm={3} className="text-center px-3">
                          <div className="d-flex align-items-center justify-content-center">
                            <img src={Image.engineCylinder} alt="Fuel Icon" className="me-2" style={{ width: "20px", height: "20px" }} />
                            <label htmlFor="engineSize" className="form-label">Engine Size (L)</label>
                          </div>
                          <input type="text" className="form-control half-border " id="engineSize" value="" />
                        </Col>
                        <Col sm={3} className="text-center px-3">
                          <div className="d-flex align-items-center justify-content-center">
                            <img src={Image.engineCylinder} alt="Fuel Icon" className="me-2" style={{ width: "20px", height: "20px" }} />
                            <label htmlFor="engineType" className="form-label">Engine Type</label>
                          </div>
                          <input type="text" className="form-control half-border " id="engineType" value="" />
                        </Col>
                      </Row>

                      <Row className="mb-4 d-flex justify-content-evenly">
                        <Col sm={3} className="text-center px-3">
                          <label htmlFor="vehicleType" className="form-label">Vehicle Type</label>
                          <input type="text" className="form-control half-border " id="vehicleType" value="" />
                        </Col>
                        <Col sm={3} className="text-center px-3">
                          <label htmlFor="horsePower" className="form-label">Horse Power</label>
                          <input type="text" className="form-control half-border " id="horsePower" value="" />
                        </Col>
                        <Col sm={3} className="text-center px-3">
                          <div className="d-flex align-items-center justify-content-center">
                            <img src={Image.mileage} alt="Fuel Icon" className="me-2" style={{ width: "20px", height: "20px" }} />
                            <label htmlFor="mileageIn" className="form-label">Mileage In</label>
                          </div>
                          <input type="text" className="form-control half-border " id="mileageIn" value="" />
                        </Col>
                      </Row>

                      <Row className="trade-foot d-flex justify-content-evenly">
                        <Col sm={3} className="text-center px-3">
                          <label htmlFor="tradeInValue" className="form-label fs-5">Trade-In Value*</label>
                          <input type="text" className="form-control half-border " id="tradeInValue" value="" />
                        </Col>
                        <Col sm={3} className="text-center px-3">
                          <label htmlFor="actualValue" className="form-label fs-5">Actual Value</label>
                          <input type="text" className="form-control half-border " id="actualValue" value="" />
                        </Col>
                        <Col sm={3} className="text-center px-3">
                          <label htmlFor="listingValue" className="form-label fs-5">Listing Value</label>
                          <input type="text" className="form-control half-border " id="listingValue" value="" />
                        </Col>
                      </Row>

                    </Container>
                  </Tab.Pane> */}

                  <Tab.Pane eventKey="Prices and Fees" style={{ minWidth: "60vw" }}>
                    <PricesFees selectedVehicle={selectedVehicle} />
                  </Tab.Pane>

                  <Tab.Pane eventKey="Forms">
                    <Forms />
                  </Tab.Pane>

                  <Tab.Pane eventKey="Payment">
                    <Payment />
                  </Tab.Pane>
                </Tab.Content>

              </div>
            </Row>
          </Tab.Container>
        </Row>

        <Row className="mt-3">
          <Col className="d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-secondary p-0 border-0 bg-transparent text-dark"
              onClick={handlePrevious}
              style={{ width: "10%", height: "30%" }}
              disabled={activeTabIndex === 0}
            >
              <img src={Image.back_btn} width='90%' />
            </button>
            <button
              type="button"
              className="btn btn-secondary p-0 border-0 bg-transparent text-dark"
              onClick={handleNext}
              style={{ width: "10%", height: "30%" }}
              disabled={activeTabIndex === tabs.length - 1}
            >
              <img src={Image.next_btn} width='90%' />
            </button>
          </Col>
        </Row>
      </Container>
    </div >
  );
};

export default Sell;






// <Modal
//   show={showModal}
//   onHide={() => setShowModal(false)}
//   dialogClassName="custom-modal"
// >
//   <Modal.Header closeButton>
//     <Modal.Title>Trade-In Information</Modal.Title>
//   </Modal.Header>
//   <Modal.Body>
//     <Container fluid className="px-0">
//       <Row className="mb-4">
//         <Col sm={3} className="text-center">
//           <label htmlFor="condition" className="form-label text-center">Condition</label>
//           <select className="form-select half-border " id="condition">
//             <option>Used</option>
//             <option>New</option>
//           </select>
//         </Col>
//         <Col sm={3} className="text-center">
//           <label htmlFor="stockNo" className="form-label">Stock No.</label>
//           <input type="text" className="form-control half-border " id="stockNo" value="" />
//         </Col>
//         <Col sm={6} className="text-center">
//           <label htmlFor="vinNo" className="form-label">VIN No.</label>
//           <div className="d-flex half-border ">
//             <input type="text" className="form-control me-2 border-0" id="vinNo" value="" />
//             <Button className="border-0 p-1" style={{ backgroundColor: "white", color: "#e8a262" }} >Decode</Button>
//           </div>
//         </Col>
//       </Row>
//       <Row className="mb-4 ">
//         <Col sm={3} className="text-center">
//           <label htmlFor="year" className="form-label">Year</label>
//           <input type="text" className="form-control half-border " id="year" value="" />
//         </Col>
//         <Col sm={4} className="text-center">
//           <label htmlFor="make" className="form-label">Make</label>
//           <input type="text" className="form-control half-border " id="make" value="" />
//         </Col>
//         <Col sm={4} className="text-center">
//           <label htmlFor="model" className="form-label">Model</label>
//           <input type="text" className="form-control half-border " id="model" value="" />
//         </Col>
//       </Row>
//       <Row className="mb-4">
//         <Col sm={4}>
//           <div className="d-flex align-items-center justify-content-center">
//             <img src={Image.fuelType} alt="Fuel Icon" className="me-2" style={{ width: "20px", height: "20px" }} />
//             <label htmlFor="fuelType" className="form-label">Fuel Type</label>
//           </div>
//           <input type="text" className="form-control half-border" id="fuelType" value="" />
//         </Col>
//         <Col sm={4}>
//           <div className="d-flex align-items-center justify-content-center">
//             <img src={Image.transmission} alt="Fuel Icon" className="me-2" style={{ width: "20px", height: "20px" }} />
//             <label htmlFor="transmission" className="form-label">Transmission</label>
//           </div>
//           <input type="text" className="form-control half-border " id="transmission" value="" />
//         </Col>
//       </Row>
//       <Row className="mb-4">
//         <Col sm={4}>
//           <div className="d-flex align-items-center justify-content-center">
//             <img src={Image.engineCylinder} alt="Fuel Icon" className="me-2" style={{ width: "20px", height: "20px" }} />
//             <label htmlFor="engineCylinder" className="form-label">Engine Cylinder</label>
//           </div>
//           <input type="text" className="form-control half-border " id="engineCylinder" value="" />
//         </Col>
//         <Col sm={4}>
//           <div className="d-flex align-items-center justify-content-center">
//             <img src={Image.engineCylinder} alt="Fuel Icon" className="me-2" style={{ width: "20px", height: "20px" }} />
//             <label htmlFor="engineSize" className="form-label">Engine Size (L)</label>
//           </div>
//           <input type="text" className="form-control half-border " id="engineSize" value="" />
//         </Col>
//         <Col sm={4}>
//           <div className="d-flex align-items-center justify-content-center">
//             <img src={Image.engineCylinder} alt="Fuel Icon" className="me-2" style={{ width: "20px", height: "20px" }} />
//             <label htmlFor="engineType" className="form-label">Engine Type</label>
//           </div>
//           <input type="text" className="form-control half-border " id="engineType" value="" />
//         </Col>
//       </Row>
//       <Row className="mb-4">
//         <Col sm={4} className="text-center">
//           <label htmlFor="vehicleType" className="form-label">Vehicle Type</label>
//           <input type="text" className="form-control half-border " id="vehicleType" value="" />
//         </Col>
//         <Col sm={4} className="text-center">
//           <label htmlFor="horsePower" className="form-label">Horse Power</label>
//           <input type="text" className="form-control half-border " id="horsePower" value="" />
//         </Col>
//         <Col sm={4} className="text-center">
//           <div className="d-flex align-items-center justify-content-center">
//             <img src={Image.mileage} alt="Fuel Icon" className="me-2" style={{ width: "20px", height: "20px" }} />
//             <label htmlFor="mileageIn" className="form-label">Mileage In</label>
//           </div>
//           <input type="text" className="form-control half-border " id="mileageIn" value="" />
//         </Col>
//       </Row>
//       <Row >
//         <Col sm={4} className="text-center">
//           <label htmlFor="tradeInValue" className="form-label">Trade-In Value*</label>
//           <input type="text" className="form-control half-border " id="tradeInValue" value="" />
//         </Col>
//         <Col sm={4} className="text-center">
//           <label htmlFor="actualValue" className="form-label">Actual Value</label>
//           <input type="text" className="form-control half-border " id="actualValue" value="" />
//         </Col>
//         <Col sm={4} className="text-center">
//           <label htmlFor="listingValue" className="form-label">Listing Value</label>
//           <input type="text" className="form-control half-border " id="listingValue" value="" />
//         </Col>
//       </Row>
//     </Container>
//   </Modal.Body>
//   <Modal.Footer>
//     <button onClick={() => setShowModal(false)} className="btn btn-primary">Save</button>
//     <button onClick={() => setShowModal(false)} className="btn btn-secondary">Cancel</button>
//   </Modal.Footer>
// </Modal>



{/* <Autocomplete
                        onLoad={autocomplete => (autocompleteRef.current = autocomplete)}
                        onPlaceChanged={handlePlaceSelected}
                        options={{
                            types: ['address'],
                            componentRestrictions: { country: 'us' }
                        }}
                    >
                        {({ getInputProps }) => (
                            <input
                                type="text"
                                className="form-control-plaintext form-border"
                                value={sellData.address}
                                onChange={(e) => setSellData({ ...sellData, address: e.target.value })}
                                {...getInputProps()}
                            />
                        )}
                    </Autocomplete> */}



// const handlePlaceSelected = () => {
//   const autocomplete = autocompleteRef.current;
//   if (autocomplete) {
//     const place = autocomplete.getPlace();
//     const addressComponents = place.address_components;
//     const newAddress = {};

//     for (const component of addressComponents) {
//       const types = component.types;
//       if (types.includes('locality')) {
//         newAddress.city = component.long_name;
//       } else if (types.includes('administrative_area_level_1')) {
//         newAddress.state = component.short_name;
//       } else if (types.includes('postal_code')) {
//         newAddress.zipCode = component.long_name;
//       } else if (types.includes('country')) {
//         newAddress.country = component.long_name;
//       }
//     }

//     newAddress.address = place.formatted_address;
//     setSellData({
//       ...sellData,
//       address: newAddress.address + (newAddress.zipCode ? `, ${newAddress.zipCode}` : ''),
//       city: newAddress.city,
//       state: newAddress.state,
//       zipCode: newAddress.zipCode,
//       country: newAddress.country
//     });
//   }
// };




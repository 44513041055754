import React from "react";
import Styles from "./Web4.module.css";
import Navbar4 from "./Navbar4";
import MainMarquee1 from "../../components/Marquee1";
import HomeFilters4 from "./HomeFilters4";
import ExtraFeatures1 from "../../components/ExtraFeatures1";
import NearByCars44 from "./NearByCars44";
import MonthlyLoanCalculator4 from "./MonthlyLoanCalculator4";
import SellYourCar4 from "./SellYourCar4";
import VehicleReport1 from "../../components/VehicleReport";
import TrustedFinancePartner from "./TrustedFinancePartner";
import NextCarProtoType from "./NextCarProtoType";
import FooterNew from "./FooterNew";


const Web44 = () => {
  const scrollToCalculator = () => {};
  return (
    <div className={Styles.wrapper}>
      <div>
        <Navbar4 />
        <MainMarquee1 />
        <HomeFilters4 roles="Dealer" scrollToCalculator={scrollToCalculator} />
        <ExtraFeatures1 />
        <NearByCars44 />
        <MonthlyLoanCalculator4 />
        <VehicleReport1 />
        <TrustedFinancePartner />
        <NextCarProtoType />
        <FooterNew />
      </div>
    </div>
  );
};

export default Web44;

import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { FaHeart, FaMicrophone } from "react-icons/fa";
import "../../../navbars/navbar.css";
import Image from "../../../imgData";
import NavMail from "./email icon.svg";

const Navbar1 = ({ route }) => {
  return (
    <>
      {/* First Navbar */}
      <header>
        <div className="parent_nav_a">
          {/* Mobile Apps */}
          <div className="mobile_apps">
            <div className="dac_logo_for_tablet">
              <Link to={route}>
                {/* <Link to={route}> */}
                <span>LOGO</span>

                {/* <img src={Image.logoDac} alt="DAC_logo" height={60} width={70} /> */}
              </Link>
            </div>
          </div>
          <p className="tab_nav_items">
            <div className="wrapper_search">
              {/* input field */}
              <p className="input_search_field">
                <input type="text" placeholder="Search by Vehicle" />
                {/* <span className="search_icon_container">
                    <FaSearch />
                  </span> */}
                {/* Microphone icon*/}
                <span className="microphone_container">
                  <FaMicrophone />
                </span>
              </p>
            </div>
          </p>

          {/* Second and third part of Navbar */}
          <div className="ham_user" style={{ justifyContent: "flex-end" }}>
            <div className="user_section_for_tablet" style={{ flex: "1" }}>
              <div className="user_related_action_tablet2">
                <img
                  src={NavMail}
                  alt="Favourite Icon"
                  height={30}
                  width={30}
                  title="My Mail"
                />
                <p>Add Inventory</p>
                <img
                  src={Image.messanger}
                  alt="Messanger Icon"
                  height={30}
                  width={30}
                  title="Messages"
                />
               
                <img
                  src={Image.offers}
                  alt="Offers Icon"
                  height={30}
                  width={30}
                  title="Offers"
                />
                <p>Add Cost</p>
                <p>DMS</p>
                {/* User icon and drop down */}
                <div className="user_icon_name_tablet">
                  <img
                    src={Image.avatar_img}
                    alt="Avatar Image for Tablet"
                    height={55}
                    width={55}
                    className="avatar_image_icon"
                  />
                  <p>User</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* ------------------------------------------------------Second Navbar -----------------------------------------------------------------------------------*/}

      <div className="parent_nav_b">
        {/* Logo Image Div */}

        {/* Saerching input div */}
        <div className="parent__search">
          {/* DropDown of category for Search */}
        </div>
      </div>
    </>
  );
};

export default Navbar1;

import React from 'react'
import { Col, Container, Nav, Row, Button, Tab, Form, Dropdown, Card, Table } from "react-bootstrap";
import Image from "../../imgData";
import styles from './price-fees.module.css';

const PricesFees = ({ selectedVehicle }) => {

  // if (!selectedVehicle) {
  //   return <div>Please select a vehicle</div>; // Fallback UI
  // }

  return (

    <Container fluid className={`${styles.containerFluid} p-0`}>
      <Row className="d-flex justify-content-between">
        <Col md={7} className="d-flex flex-column justify-content-center">
          <Row>
            <Col md={6} className="mb-3">
              <Card className={styles.card}>
                <Card.Body>
                  <Card.Title className={styles.cardTitle}>Vehicle Info</Card.Title>
                  <Card.Text className={styles.cardText}>
                    2013 CHEVROLET Traverse SUV AWD<br />
                    VIN: 1GNKUKJD4DJ219969<br />
                    Sale Type: Private
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col md={6} className="mb-3">
              <Card className={styles.card}>
                <Card.Body>
                  <Card.Title className={styles.cardTitle}>Trade IN</Card.Title>
                  <Card.Text className={styles.cardText}>
                    VIN: 1G4PR5SK1H4104187<br />
                    Year make model Vehicle type: 2023 Nissan<br />
                    Engine cylender type: 5<br />
                    Miladge:  <br />
                    Traid in value: 5475
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Card className={styles.card}>
                <Card.Body>
                  <Card.Title className={styles.cardTitle}>Buyer</Card.Title>
                  <Card.Text className={styles.cardText}>
                    Name: Priyanka<br />
                    Address: H-190 Sector 63 Noida, UP, India<br />
                    Phone no: 9878654796<br />
                    Email: example87@email.com
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} className="mb-3">
              <Card className={styles.card}>
                <Card.Body>
                  <Card.Title className={styles.cardTitle}>Co-Buyer</Card.Title>
                  <Card.Text className={styles.cardText}>
                    <Card.Text className={styles.cardText}>
                      Name: Priyanka<br />
                      Address: H-190 Sector 63 Noida, UP, India<br />
                      Phone no: 9878654796<br />
                      Email: example87@email.com
                    </Card.Text>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            
          </Row>
        </Col>

        <Col md={4}>
          <div className={`${styles.rightSection} `}>
            <img src={Image.carFees} alt="Car" className="img-fluid" />
          </div>
          <Card className={` ${styles.cardWithArrow}`}>
            <div className={styles.arrowUp}></div>
            <Card.Body className='p-0'>
              <Table bordered className={styles.table}>
                <thead >
                  <tr className='border-0'>
                    <th colSpan={3} >Payment Overview</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Customer On Sale</td>
                    <td>$0.00</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Sale Price ($)</td>
                    <td>$0.00</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>Trade-IN Value ($)</td>
                    <td>$0.00</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>Owed On Trade ($)</td>
                    <td>$0.00</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>Cash Difference ($)</td>
                    <td>$0.00</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>Total Due ($)</td>
                    <td>$0.00</td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>Payment Method</td>
                    <td>$0.00</td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td>Paid Amount ($)</td>
                    <td>$0.00</td>
                  </tr>
                  <tr>
                    <td colSpan="2">Total Due ($)</td>
                    <td>$0.00</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>

    // <div className="buyer-info mb-5">
    //   <Container className=''>
    //     <Row className="d-flex justify-content-evenly">
    //       {/* <Col xs={12} md={5} className="p-3 ttl-left mx-auto ">
    //                       {fields.map((field, index) => (
    //                         <Form.Group as={Row} controlId={`formField${index}`} key={index} className="mb-2">
    //                           <Col xs="5">
    //                             <Form.Control
    //                               type="text"
    //                               placeholder="Label"
    //                               value={field.label}
    //                               onChange={(e) =>
    //                                 handleFieldChange(index, { ...field, label: e.target.value })
    //                               }
    //                             />
    //                           </Col>
    //                           <Col xs="6">
    //                             <Form.Control
    //                               type="text"
    //                               placeholder="Value"
    //                               value={field.value}
    //                               onChange={(e) =>
    //                                 handleFieldChange(index, { ...field, value: e.target.value })
    //                               }
    //                             />
    //                           </Col>
    //                           <Col xs="1" className="text-right px-0">
    //                             {index > 0 && (
    //                               <IoMdCloseCircle fill="red" className="" onClick={() => handleDeleteField(index)} />
    //                             )}
    //                           </Col>

    //                         </Form.Group>
    //                       ))}
    //                       <Button variant="link" className="p-0 mt-3 text-dark" onClick={handleAddField}> +Add more </Button>
    //                     </Col> */}

    //       <Col xs={12} md={7} className="p-3 border ttl-left ">


    //       </Col>

    //       <Col md={5} sm={12} className='ttl-right px-0 fw-bold'>

    //         <Container>
    //           <Row>
    //             <Col className='d-flex justify-content-center'>
    //               <img src={Image.carFees} alt='Golden Car'/>
    //             </Col>

    //           </Row>
    //           <Row>
    //             <Col>

    //             </Col>
    //           </Row>
    //         </Container>
    //       </Col>
    //     </Row>
    //   </Container>
    // </div>
  )
}

export default PricesFees




// < Col xs = { 12} md = { 6} className = "p-3 ttl-left " >
//   <Container>
//     <Row>
//       <Col><h5>Vehicle List</h5></Col>
//       <Col sm={12}><h6>{selectedVehicle && selectedVehicle.year_make_model.trim().split(/\s+/).slice(0, 3).join(' ')}</h6></Col>
//       <Col sm={12} className=''><p className='text-nowrap mb-0'><span className='orange-color'>VIN: </span>{selectedVehicle && selectedVehicle.vin}</p></Col>
//       <Col sm={12} className=''><p><span className='orange-color'>Sale Type: </span>Private</p></Col>
//     </Row>

//     <Row>
//       <Container className='px-0'>
//         <Row>
//           <Col md={6}>
//             <Container>
//               <Row>
//                 <Col><h5>Buyer Infomation</h5></Col>
//                 <Col sm={12}><p className='text-nowrap mb-0'><span className='orange-color'>Name: </span>Priyanka</p></Col>
//                 <Col sm={12} className=''><p className='text-nowrap mb-0'><span className='orange-color'>Address: </span>Noida, UP</p></Col>
//                 <Col sm={12} className=''><p className='text-nowrap mb-0'><span className='orange-color'>Phone no: </span>6573467345</p></Col>
//                 <Col sm={12} className=''><p><span className='orange-color'>email: </span>example87@email.com</p></Col>
//               </Row>
//             </Container>
//           </Col>

//           <Col md={6}>
//             <Container>
//               <Row>
//                 <Col><h5>Co-Buyer Infomation</h5></Col>
//                 <Col sm={12}><p className='text-nowrap mb-0'><span className='orange-color'>Name: </span>Priyanka</p></Col>
//                 <Col sm={12} className=''><p className='text-nowrap mb-0'><span className='orange-color'>Address: </span>Noida, UP</p></Col>
//                 <Col sm={12} className=''><p className='text-nowrap mb-0'><span className='orange-color'>Phone no: </span>6573467345</p></Col>
//                 <Col sm={12} className=''><p><span className='orange-color'>email: </span>example87@email.com</p></Col>
//               </Row>
//             </Container>
//           </Col>
//         </Row>
//       </Container>
//     </Row>

//     <Row>
//       <Col><h5>Trade In</h5></Col>
//       <Col sm={12} className=''><p className='text-nowrap mb-0'><span className='orange-color'>VIN: </span>1G4PR5SK1H4104187</p></Col>
//       <Col sm={12} className=''><p className='text-nowrap mb-0'><span className='orange-color'>Year make model Vehicle type: </span> 2023 Nissan </p></Col>
//       <Col sm={12} className=''><p className='text-nowrap mb-0'><span className='orange-color'>Engine cylender type: </span>5</p></Col>
//       <Col sm={12} className=''><p className='text-nowrap mb-0'><span className='orange-color'>Miladge: </span></p></Col>
//       <Col sm={12} className=''><p><span className='orange-color'>Traid in value: </span>5475</p></Col>
//     </Row>
//   </Container>
//         </Col >

// <Col md={5} sm={12} className='ttl-right px-0 fw-bold'>
//   <Container className="bg-light-orange">
//     <Row className=''>
//       <Col className='mt-4'>
//         <Form.Group controlId="formDate " className="d-flex align-items-center justify-content-between">
//           <Form.Label className="fw-bolder">Date :</Form.Label>
//           <Form.Control type="date" value="26/01/24" className='half-border bg-transparent' />
//         </Form.Group>
//       </Col>
//     </Row>
//     <Row className=''>
//       <Col>
//         <Form.Group className=" d-flex align-items-center justify-content-between" controlId="formOdometer">
//           <Form.Label className="fw-bolder ">Odometer On Sale :</Form.Label>
//           <p className='text-center'>125455</p>
//           {/* <Form.Control className='half-border bg-transparent fw-bolder ' type="text" value="125455" /> */}
//         </Form.Group>
//       </Col>
//     </Row>
//     <Row className=''>
//       <Col>
//         <Form.Group className="d-flex align-items-center justify-content-between" controlId="formSalePrice">
//           <Form.Label className="fw-bolder fs-5">Sale Price ($)</Form.Label>
//           <p className='text-center'>15000</p>
//           {/* <Form.Control className='border-0 half-border sale-p fw-bolder fs-5 text-light' type="text" value="15000" /> */}
//         </Form.Group>
//       </Col>
//     </Row>
//   </Container>


//   <Container style={{ borderBottom: "3px solid #e8a262" }}>
//     <Row>
//       <Col sm={12} className=''>
//         <Form.Group className="d-flex align-items-center justify-content-between" controlId="formTradeValue">
//           <Form.Label>Trade - INs Value ($)</Form.Label>
//           <p className='text-center'>3000</p>
//           {/* <Form.Control className='half-border bg-transparent ' type="text" value="2000" /> */}
//         </Form.Group>
//       </Col>

//       <Col sm={12} className=''>
//         <Form.Group className="d-flex align-items-center justify-content-between" controlId="formOwedOnTrade">
//           <Form.Label>Owed On Trade ($)</Form.Label>
//           <p className='text-center'>0</p>
//           {/* <Form.Control className='half-border bg-transparent ' type="text" value="0" /> */}
//         </Form.Group>
//       </Col>

//       <Col sm={12} className=''>
//         <Form.Group className="d-flex align-items-center justify-content-between" controlId="formCashDifference">
//           <Form.Label>Cash Difference ($)</Form.Label>
//           <p className='text-center'>13000</p>
//           {/* <Form.Control className='half-border bg-transparent ' type="text" value="13000" /> */}
//         </Form.Group>
//       </Col>
//     </Row>
//   </Container>

//   <Container style={{ position: "relative", borderBottom: "3px solid #e8a262" }}>
//     <Row className=' bg-light-orange'>
//       <Col >
//         <Form.Group className="d-flex mt-2 align-items-center justify-content-between" controlId="formTotalDue">
//           <Form.Label className="fw-bolder fs-5">Total Due ($)</Form.Label>
//           <p className='text-center'>13150</p>
//           {/* <Form.Control className='border-0 half-border fw-bolder fs-5 total-due text-light' type="text" value="13150" /> */}
//         </Form.Group>
//       </Col>
//     </Row>

//     <Row className=''>
//       <Col>
//         <Form.Group className="d-flex align-items-center justify-content-between" controlId="formPaymentMethod">
//           <Form.Label>Payment Method</Form.Label>
//           <Form.Control className='half-border bg-transparent form-select' as="select" >
//             <option>Cash</option>
//             <option>Credit</option>
//             <option>Loan</option>
//           </Form.Control>
//         </Form.Group>
//       </Col>
//     </Row>

//     {/* <div className="dealer-icon">D</div> */}
//   </Container>

//   <Container style={{ borderBottom: "3px solid #e8a262" }}>
//     <Row className=' bg-light-orange'>
//       <Col>
//         <Form.Group className="d-flex align-items-center justify-content-between" controlId="formPayment">
//           <Form.Label className="fw-bolder fs-5">Payment ($)</Form.Label>
//           <p className='text-center'>0</p>
//           {/* <Form.Control className='half-border fw-bolder fs-5 bg-transparent ' type="text" value="0" /> */}
//         </Form.Group>
//       </Col>
//     </Row>
//   </Container>

//   <Container>
//     <Row className=''>
//       <Col>
//         <Form.Group className="d-flex align-items-center justify-content-between" controlId="formBalanceDue">
//           <Form.Label className="fw-bolder fs-5">Balance Due ($)</Form.Label>
//           <p className='text-center'>13150</p>
//           {/* <Form.Control className='half-border bg-transparent fw-bolder fs-5 balance-due' type="text" value="13150" /> */}
//         </Form.Group>
//       </Col>
//     </Row>
//   </Container>
//   {/* <div className="client-icon">C</div> */}
// </Col>
import React, { useEffect, useMemo, useState } from "react";
import "./FavCartPage.css";
import { FaSquareShareNodes } from "react-icons/fa6";
import { IoHeartCircle } from "react-icons/io5";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import Favcard from "./Favcard";
import axios from "axios";
import { useSelector } from "react-redux";

const FavCartPage = () => {
  // Session Time out for 15 minutes-------------------------------------
  const [favArray, setFavArray] = useState([]);
  //const favArray = Array(100).fill(null);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  console.log("current Page =>", currentPage);
  const [sortBy, setSortBy] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL;
  const { id } = useSelector((state) => state.user);

  useEffect(() => {
    const api = `${apiUrl}/listing/favourites/${id}`;

    // Example of making a POST request using Axios
    axios
      .post(api)
      .then((response) => {
        console.log("API Response:================", response.data); // Log the response data
        // Assuming your API returns an array of items, update the state
        setFavArray(response.data); // Update favArray state with API response
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  console.log(favArray);
  const startIndex = (currentPage - 1) * itemsPerPage;

  const lastIndex = currentPage * itemsPerPage;

  console.log("Start Index => ", startIndex, " lastIndex => ", lastIndex);

  const handleSelectedNoOfItems = (e) => {
    setItemsPerPage(e.target.value);
  };
  const handleSortByItems = (e) => {
    setSortBy(e.target.value);
  };

  const totalPages = Math.ceil(favArray.length / itemsPerPage);

  const pageNumbers = Array.from(
    { length: totalPages },
    (_, pageNumber) => pageNumber + 1
  );

  const handleShowCurrPageItems = (page) => {
    console.log("Selected Page => ", page);
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  useEffect(() => {
    handleShowCurrPageItems(currentPage);
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1); // Reset to the first page whenever itemsPerPage changes
  }, []);
  // Session Time out for 15 minutes End-------------------------------------
  return (
    <div className="card_wrapper">
      <div className="sort_container">
        <div className="per_page_container">
          <span>Per Page</span>
          <select value={itemsPerPage} onChange={handleSelectedNoOfItems}>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
        <div className="sort_page_container">
          <select value={sortBy} onChange={handleSortByItems}>
            <option value="" disabled hidden>
              Sort By
            </option>
            <option value="Price_l_2_h">Price (Low to High)</option>
            <option value="Price_h_2_l">Price (High to Low)</option>
            <option value="Latest">Latest</option>
            <option value="Oldest">Oldest</option>
          </select>
        </div>
      </div>
      <div>
        {favArray &&
          Array.isArray(favArray) &&
          favArray.slice(startIndex, lastIndex).map((likedItem, index) => {
            const adjustedIndex = startIndex + index;
            return (
              <div key={index}>
                <Favcard index={adjustedIndex} likedItem={likedItem} />
              </div>
            );
          })}
      </div>
      <div className="page_num_container">
        {pageNumbers.map((pageNumber) => (
          <p
            key={pageNumber}
            onClick={() => handleShowCurrPageItems(pageNumber)}
            className={pageNumber === currentPage ? "selected_page" : ""}
          >
            {pageNumber}
          </p>
        ))}
      </div>
    </div>
  );
};

export default FavCartPage;

import React from "react";
import Styles from "./AboutUs.module.css";
import Image from "../../../../imgData";

const AboutUs = () => {
  return (
    <div className={Styles.wrapper}>
      <div className={Styles.container}>
        <span className={Styles.imgContainer}>
          <img src={Image.web3_about_image} alt="about us" />
        </span>
        <div className={Styles.context}>
          <p>About Us</p>
          <p>
            Welcome to Deal Cars, your go-to destination for quality cars and
            unbeatable deals ! We're on a mission to make your car-buying
            experience simple, affordable, and enjoyable.
          </p>
          <p>Why Us?</p>
          <p>1. Wide Variety : Discover a diverse range of vehicles that suits your styles and needs.</p>
          <p>2. Great Prices : Our competitive pricing ensures you get the best value of your money.</p>
          <p>3. Quality Assurance : Every car in our inventory undergoes a thorough inspection, so you can trust in the reliability and quality of your purchase.</p>
          <p>4. Customer Focus : Our friendly team is here to assist you at every step.</p>
          <p>5. Easy Financing : Securing the best financing options is easy with our team.</p>
          <p style={{backgroundColor : "black", padding : "0.3rem 0.6rem", color : "white", "fontWeight" : "normal", display : "inline", }}>About Us</p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;

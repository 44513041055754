import React from "react";
import { Link, Route, Routes } from "react-router-dom";
import DealerBalanceInventory from "../DealerBalanceInventory/DealerBalanceInventory";
import DealerBalancePayment from "./DealerBalancePayment";
import BalancePaymentHistory from "./components/BalancePaymentHistory";
import DealDetails from "./components/DealDetails";

const BalanceHome = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<DealerBalanceInventory />} />
        <Route path="payment" element={<DealerBalancePayment />} />
        <Route path="paymentHistory" element={<BalancePaymentHistory />} />
        <Route path="dealDetails" element={<DealDetails />} />
      </Routes>
    </div>
  );
};

export default BalanceHome;

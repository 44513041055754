import React from 'react'
import { Button, Col, OverlayTrigger, Tooltip, } from "react-bootstrap";
import styles from "../mail.module.css";

const OutboxCard = ({ handleActivePage }) => {
    return (
        <Col sm={12} className={`border ${styles.mailCard}`} onClick={(e) => handleActivePage("Mail templete", e)}>
            <div className={` ${styles.nameSec}`}>
                {/* <div className={`me-2 ${styles.pro}`}><img src={Image.user_icon} alt=""  /></div> */}
                <div className={` ${styles.name}`}>Priyanka</div>
            </div>
            <div className={` ${styles.email}`}>priyanka12@gmail.com</div>
            <div className={` ${styles.content}`}>mail content</div>
        </Col>
    )
}

export default OutboxCard
import { MdMoreTime } from "react-icons/md";
import Image from "../../../imgData";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaCheckCircle } from "react-icons/fa";

const AdvertisingProfile = ({ title, logo, statusName, intervalName, status, interval, handleCheckboxChange, handleSelectChange, toggleImage, isAllInterval, setAllIntervals, readonly, isFacebook = false, facebookPagesStatus, facebookMarketplaceStatus, facebookGroupsStatus, handleFacebookCheckboxChange, }) => {

    return (
        <div className={`col-md-12 shadow mb-2 ${status ? "custom-bg" : "bg-custom"} rounded border border-secondary ${isFacebook && status ? 'mb-5' : ''}`} onClick={(e) => {
            if (!readonly && e.target.tagName !== "SELECT" && e.target.tagName !== "INPUT") {
                const checkbox = e.currentTarget.querySelector('input[type="checkbox"]');

                // Check if the checkbox is the Facebook Marketplace checkbox
                if (checkbox && (checkbox.name !== "facebook_marketplace_status" || checkbox.name !== "facebook_groups_status")) {
                    console.log(checkbox.name, " !== facebook_status")
                    handleCheckboxChange({
                        target: {
                            checked: !checkbox.checked,
                            name: statusName
                        }
                    }, intervalName);
                }
            }
        }}
        >
            <div className={` row d-flex align-items-center`} id="adlst-responsive">
                <div className="col-md-1">
                    <div className="form-check">
                        <label className="containers">
                            {console.log(statusName)}
                            <input type="checkbox"
                                name={statusName}
                                checked={status}
                                disabled={readonly}
                                onChange={(e) => handleCheckboxChange(e, intervalName)} />
                            <span className="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div className="col-md-1">
                    <img src={logo} className="Darkslogo p-0" alt={`${title} Logo`} style={{ height: statusName === "dacmp_status" || statusName === "unitedmotors_status" ? "40px" : "", width: statusName === "dacmp_status" || statusName === "unitedmotors_status" ? "40px" : "" }} />
                </div>
                <div className="col-md-5 fw-bold">
                    <div style={{ fontSize: "20px" }}>{title}</div>
                </div>
                <div className="col-md-1 fw-bold" onClick={toggleImage}>
                    <img
                        src={status ? Image.connectedadvmanager : Image.connectadvmanager}
                        style={{ height: "30px", width: "30px" }}
                        alt="Adv Manager" />
                </div>
                <div className="col-md-1 fw-bold" >
                    {isAllInterval && (
                        <OverlayTrigger placement="bottom-end"
                            overlay={
                                <Tooltip id={`set-all-interval`} className="custom-tooltip" >
                                    {/* Set {interval} interval for all */}
                                    Copy to all
                                </Tooltip>
                            } >
                            <span><MdMoreTime onClick={() => setAllIntervals(interval)} style={{ height: "35px", width: "35px", color: '#4778ab', cursor: 'pointer' }} /></span>
                        </OverlayTrigger>
                    )}
                </div>
                <div className="col-md-3">
                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        <div className="input-group mt-2 mb-2">
                            <select
                                className="form-select"
                                name={intervalName}
                                value={interval}
                                disabled={status ? false : true}
                                onChange={(e) => {
                                    e.stopPropagation(); // Prevent the click from reaching the parent
                                    handleSelectChange(e); // Call your select change handler
                                }}>
                                <option value="">Select Interval</option>
                                <option value="Every Week">Every Week</option>
                                <option value="Weekly">Weekly</option>
                                <option value="ByWeekly">Bi-Weekly</option>
                                <option value="Monthly">Monthly</option>
                            </select>
                            <label className="input-group-text text-light fw-bold fs-5"
                                style={{ backgroundColor: "#d1823a" }}>
                                Repost
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            {/* {isFacebook && status && (
                <div className="tooltip-container">
                    <div className="facebook-tooltip marketplace-tooltip">
                        Facebook Marketplace
                        <span className="tooltip-arrow"></span>
                        <span className="col-md-1 fw-bold" onClick={toggleImage}>
                         <img src={status ? Image.connectedadvmanager : Image.connectadvmanager} style={{ height: "15px", width: "15px", marginLeft: '5px' }} alt="Adv Manager" />
                            <FaCheckCircle fill="#5ae229" style={{ marginLeft: '5px' }} />
                        </span>
                    </div>
                    <div className="facebook-tooltip group-tooltip">
                        Facebook Groups
                        <span className="tooltip-arrow"></span>
                        <span className="col-md-1 fw-bold" onClick={toggleImage}>
                        <img src={status ? Image.connectedadvmanager : Image.connectadvmanager} style={{ height: "15px", width: "15px", marginLeft: '5px' }} alt="Adv Manager" /> 
                            <FaCheckCircle fill="#5ae229" style={{ marginLeft: '5px' }} />
                        </span>
                    </div>
                </div>
            )} */}

            {isFacebook && status && (
                <div className="tooltip-container">
                    <div className="facebook-tooltip marketplace-tooltip"
                        // onClick={() => document.getElementById("facebook_marketplace_status").click()}
                        onClick={(e) => {
                            // Check if the click was on the checkbox
                            if (!readonly && !e.target.closest('input[type="checkbox"]')) {
                                e.stopPropagation();
                                console.log("triggered facebook_marketplace_status", facebookMarketplaceStatus);
                                handleFacebookCheckboxChange(!facebookMarketplaceStatus, 'facebook_marketplace_status');
                            }
                        }}
                    >
                        <label className="containers mb-0 text-nowrap" htmlFor="facebook_marketplace_status" style={{ fontSize: '18px' }}>
                            <input
                                type="checkbox"
                                id="facebook_marketplace_status"
                                name="facebook_marketplace_status"
                                checked={facebookMarketplaceStatus}
                            // onChange={(e) => {
                            //     // Prevent the click event from triggering the parent's onClick
                            //     e.stopPropagation();
                            //     console.log("triggered in input facebook_marketplace_status");
                            //     handleFacebookCheckboxChange(e, 'facebook_marketplace_status');
                            // }}
                            />
                            Facebook Marketplace
                            <span className="checkmark" style={{ top: '5px', border: 'none' }}></span>
                        </label>
                        <span className="tooltip-arrow"></span>
                        <span className="col-md-1 fw-bold" onClick={toggleImage}>
                            {facebookMarketplaceStatus && (
                                <FaCheckCircle fill="#5ae229" style={{ marginLeft: '5px' }} />
                            )}
                        </span>
                    </div>

                    <div
                        className="facebook-tooltip group-tooltip"
                        onClick={(e) => {
                            // Check if the click was on the checkbox
                            if (!readonly && !e.target.closest('input[type="checkbox"]')) {
                                e.stopPropagation();
                                console.log("triggered facebook_groups_status");
                                handleFacebookCheckboxChange(!facebookGroupsStatus, 'facebook_groups_status');
                            }
                        }}
                    >
                        <label className="containers mb-0 text-nowrap" htmlFor="facebook_groups_status" style={{ fontSize: '18px' }}>
                            <input
                                type="checkbox"
                                name="facebook_groups_status"
                                checked={facebookGroupsStatus}
                            // onChange={(e) => {
                            //     // Prevent the click event from triggering the parent's onClick
                            //     e.stopPropagation();
                            //     console.log("triggered in input facebook_groups_status");
                            //     // handleFacebookCheckboxChange(e.target.checked, 'facebook_groups_status');
                            // }}
                            />
                            Facebook Groups
                            <span className="checkmark" style={{ top: '5px', border: 'none' }}></span>
                        </label>
                        <span className="tooltip-arrow"></span>
                        <span className="col-md-1 fw-bold" onClick={toggleImage}>
                            {facebookGroupsStatus && (
                                <FaCheckCircle fill="#5ae229" style={{ marginLeft: '5px' }} />
                            )}
                        </span>
                    </div>
                </div>
            )}


        </div>
    );
};

export default AdvertisingProfile;
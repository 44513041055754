const currentItems = [
  {
    id: 1,
    transactionDate: "2023-10-01",
    latePaymentFees: "$5.00",
    payment: "$100.00",
    paymentMethod: "Credit Card",
    nextPaymentDue: "2023-11-01",
    currentBalance: "$95.00",
  },
  {
    id: 2,
    transactionDate: "2023-09-15",
    latePaymentFees: "$0.00",
    payment: "$150.00",
    paymentMethod: "PayPal",
    nextPaymentDue: "2023-10-15",
    currentBalance: "$0.00",
  },
  {
    id: 3,
    transactionDate: "2023-08-20",
    latePaymentFees: "$10.00",
    payment: "$200.00",
    paymentMethod: "Bank Transfer",
    nextPaymentDue: "2023-09-20",
    currentBalance: "$10.00",
  },
  {
    id: 4,
    transactionDate: "2023-07-30",
    latePaymentFees: "$0.00",
    payment: "$250.00",
    paymentMethod: "Debit Card",
    nextPaymentDue: "2023-08-30",
    currentBalance: "$0.00",
  },
  {
    id: 5,
    transactionDate: "2023-06-25",
    latePaymentFees: "$15.00",
    payment: "$300.00",
    paymentMethod: "Credit Card",
    nextPaymentDue: "2023-07-25",
    currentBalance: "$15.00",
  },
];
export default currentItems;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./DealerDashBoardModal.css";
import DealersProfile from "./DealersProfile";
import RequestDemoModal from "./RequestDemoModal";
import { FaCheckCircle, FaRegClock } from "react-icons/fa";
import { Col, Container, Row, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { saveUser } from "../../../Redux/slice/userSlice";
import { setDynamicAPILabels } from "../../../Redux/slice/taxes&FeesSlice";
import { setDynamicAPILabelsFast } from "../../../Redux/slice/fastSlice";
const apiUrl = process.env.REACT_APP_API_URL;
const DealerDashBoardModal = ({ setShowNavigationModal }) => {
  const { id } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const Userdata = useSelector((state) => state.user);
  const loginAccess = useSelector((state) => state.user.loginAccess);
  const dealerApproval = useSelector((state) => state.user.dealerApproval);
  const dealerData = useSelector((state) => state.user.dealerData);
  console.log("loginAccess", loginAccess);
  console.log("dealerApproval", dealerApproval);
  console.log("dealerData", dealerData);

  const [showModal, setShowModal] = useState(false);
  const [showRequestDemoModal, setShowRequestDemoModal] = useState(false);
  const [activeDealer, setActiveDealer] = useState("pending");
  //This is for fetching the labels after signup
  const fetchDynamicLabels = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/sell/get_labels/${Userdata.id}/Retail`
      );
      console.log("Line 31", res.data);
      const mappedResponse =
        res &&
        res.data &&
        res.data.map((item, index) => ({
          id:item.id,
          label: item.itemName,
          InputValue: Number(item.itemValue),
        }));
      console.log("mappedResponse => ", mappedResponse);
      dispatch(setDynamicAPILabels(mappedResponse));
      dispatch(setDynamicAPILabelsFast(mappedResponse));
    } catch (error) {
      console.log("Error while fetching labels => ", error);
    }
  };

  const handleCloseModal = () => {
    setShowNavigationModal(false);
    fetchDynamicLabels();
  };
  const handleLinkClick = () => {
    setShowModal(true);
  };
  // Function to handle closing the modal
  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleRequestDemoClick = () => {
    setShowRequestDemoModal(true);
  };

  const handleRequestDemoModalClose = () => {
    setShowRequestDemoModal(false);
  };
  //"start": "react-scripts --openssl-legacy-provider start node server.js",
  // for sik modal according to status--------------------------------------------------------------
  useEffect(() => {
    if (loginAccess === 1 && dealerApproval === 1 && dealerData === 1) {
      handleCloseModal();
    }
  }, [loginAccess, dealerApproval, dealerData]);
  useEffect(() => {
    if (loginAccess === 1 && dealerApproval === 0 && dealerData === 0) {
      handleCloseModal();
    }
  }, [loginAccess, dealerApproval, dealerData]);
  // ----------------------------------------------------------------------------------------
  // for skip button api-----------------------------------------------------------
  const handleButtonStatus = async () => {
    console.log("id", id);
    try {
      const fd = new FormData();
      fd.append("id", id); // Make sure `nameValue` is defined in the scope or passed as a parameter

      const response = await axios.post(`${apiUrl}/dealer/dealer_status`, fd);
      console.log("response.data", response.data);
      dispatch(
        saveUser({
          dealerData: 1,
          dealerApproval: 1,
          loginAccess: 1,
        })
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // -------------------------------------------------------------------------------
  // for status  api-----------------------------------------------------------
  const statusApiGet = async () => {
    console.log("id", id);
    try {
      // const fd = new FormData();
      // fd.append("id", id); // Make sure `nameValue` is defined in the scope or passed as a parameter

      const response = await axios.get(
        `${apiUrl}/dealer/check_dealer_status/${id}`
        // fd
      );
      console.log("response.data", response.data);
      dispatch(
        saveUser({
          dealerData: response.data.dealerData,
          dealerApproval: response.data.dealerApproval,
          loginAccess: response.data.loginAccess,
        })
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // -------------------------------------------------------------------------------
  return (
    <div
      className={`dealer_welcome_modal_wrapper ${showModal ? "d-none" : ""}`}
    >
      <div className="dealer_welcome_modal_container">
        {loginAccess === 0 && dealerApproval === 0 && dealerData === 0 && (
          <Container>
            <div className="buton_container">
              <button onClick={handleRequestDemoClick}>Request Demo</button>
              <div onClick={handleLinkClick}>
                <button>Activate Dealership</button>
              </div>
            </div>
            {/* <p className="skip_button_container" style={{display:"flex",marginLeft:"5%"}}>
          <p className='skip_button_container_status'>
            <p>Status1</p>
            <p>Date</p>
            <p><input type="checkbox" id="Status1" name="Status1" value="Status1"/></p>
          </p>
          <p className='skip_button_container_status'>
            <p>Status2</p>
            <p>Date</p>
            <p><input type="checkbox" id="Status2" name="Status2" value="Status2"/></p>
          </p>
          <p className='skip_button_container_status'>
            <p>Status3</p>
            <p>Date</p>
            <p><input type="checkbox" id="Status3" name="Status3" value="Status3"/></p>
          </p>
          <p className='skip_button_container_status'>
            <p>Status4</p>
            <p>Date</p>
            <p><input type="checkbox" id="Status4" name="Status4" value="Status4"/></p>
          </p>
          <p className='skip_button_container_status'>
            <p>Status5</p>
            <p>Date</p>
            <p><input type="checkbox" id="Status5" name="Status5" value="Status5"/></p>
          </p>
        </p> */}
            <div className="skip_button_container_skip">
              <p onClick={handleCloseModal}>
                <u>skip</u>
              </p>
            </div>
          </Container>
        )}
        {loginAccess === 0 && dealerApproval === 0 && dealerData === 1 && (
          <Container className=" h-100" onMouseEnter={statusApiGet}>
            <Row className="d-flex flex-column justify-content-around flex-nowrap align-items-center gap-4 h-100">
              <Col sm={9} className="d-flex justify-content-center">
                <Button className="px-4 py-2 orange-btn border-0 fs-5 fw-bolder">
                  Schedule Demo
                </Button>
              </Col>
              <Col sm={12} className="status-con">
                <div className="status-stages">
                  <p className="stage-text">Request Send</p>
                  <div className="stage-icon">
                    <FaCheckCircle fill="green" />
                  </div>
                </div>
                <div className="status-stages">
                  <p className="stage-text">Under Review</p>
                  <div className="stage-icon">
                    <FaRegClock />
                  </div>
                </div>
                <div className="status-stages">
                  <p className="stage-text">Aproved</p>
                  <div className="stage-icon">
                    <FaRegClock />
                  </div>
                </div>
              </Col>
              <Col
                sm={12}
                className="d-flex flex-column align-items-center msg"
              >
                <p>Thank you for choosing us!</p>
                <p>
                  Your request is under process, We will notify you through
                  Email or SMS once it get aproved.
                </p>
              </Col>
              <Col sm={12} className="d-flex justify-content-end">
                {/* <Button className='px-3 py-2 border-0 guide '>Tour Guide</Button> */}
                <Button
                  variant="secondary"
                  className="px-5 py-2 border-0"
                  onClick={handleCloseModal}
                >
                  Skip
                </Button>
              </Col>
            </Row>
          </Container>
        )}

        {loginAccess === 1 && dealerApproval === 0 && dealerData === 1 && (
          <Container className=" h-100">
            <Row className="d-flex flex-column justify-content-around flex-nowrap align-items-center gap-4 h-100">
              <Col sm={9} className="d-flex justify-content-center">
                <Button className="px-4 py-2 orange-btn border-0 fs-5 fw-bolder">
                  Schedule Demo
                </Button>
              </Col>
              <Col sm={12} className="status-con">
                <div className="status-stages">
                  <p className="stage-text">Request Send</p>
                  <div className="stage-icon">
                    <FaCheckCircle fill="green" />
                  </div>
                </div>
                <div className="status-stages">
                  <p className="stage-text">Account Generated</p>
                  <div className="stage-icon">
                    <FaCheckCircle fill="green" />
                  </div>
                </div>
                <div className="status-stages">
                  <p className="stage-text">Aproved</p>
                  <div className="stage-icon">
                    <FaCheckCircle fill="green" />
                  </div>
                </div>
              </Col>
              <Col
                sm={12}
                className="d-flex flex-column align-items-center msg"
              >
                <p>Thank you for choosing us!</p>
                <p>
                  Your request is under process, We will notify you through
                  Email or SMS once it get aproved.
                </p>
              </Col>
              <Col sm={12} className="d-flex justify-content-between">
                <Button
                  className="px-3 py-2 border-0 guide "
                  onClick={handleButtonStatus}
                >
                  Tour Guide
                </Button>
                <Button
                  variant="secondary"
                  className="px-5 py-2 border-0 "
                  onClick={handleButtonStatus}
                >
                  Skip
                </Button>
              </Col>
            </Row>
          </Container>
        )}
      </div>

      <DealersProfile
        show={showModal}
        handleClose={handleModalClose}
        // setActiveDealer={setActiveDealer}
        addCost="addcost"
      />
      <RequestDemoModal
        show={showRequestDemoModal}
        handleClose={handleRequestDemoModalClose}
      />
    </div>
  );
};

export default DealerDashBoardModal;

const retailData = [
  { id: 1, label: "Documentation Fees", InputValue: 150.0 },
  { id: 2, label: "Wind-Shield Sticker", InputValue: 50.75 },
  { id: 3, label: "TERP Title Fee to DMV", InputValue: 20.0 },
  { id: 4, label: "Title Application Fee", InputValue: 13.0 },
  { id: 5, label: "REG. FEE-DPS to Tax Office", InputValue: 1.0 },
  { id: 6, label: "CNTY ROAD Bridge Addon Fee", InputValue: 10.0 },
  { id: 7, label: "Child Safety to Tax Office", InputValue: 1.5 },
  { id: 8, label: "Proc. & Handling Fee to Tax Office", InputValue: 4.75 },
  { id: 9, label: "Paper Tag to DMV", InputValue: 5.0 },
  { id: 10, label: "Metal Tag", InputValue: 5.0 },
  { id: 11, label: "Inspection Fee To The State", InputValue: 8.25 },
  { id: 12, label: "Inspection Fee To Station", InputValue: 25.0 },
  { id: 13, label: "Service Contract Fee", InputValue: 0.0 },
  { id: 14, label: "Card Fee/Technology Fee", InputValue: 0.0 },
  { id: 15, label: "Others", InputValue: 0.0 },
];

export default retailData;

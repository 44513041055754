import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const UserProtectedRoutes = ({ children }) => {
  const userData = useSelector((state) => state.user);
  return userData.id && userData.roles === "User" ? children : <Navigate to="/" />;
};

export default UserProtectedRoutes;

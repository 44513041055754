import React, { useState, useEffect, useRef } from 'react';

const StateSelector = ({ onStateSelect }) => {
  const [selectedState, setSelectedState] = useState('');
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [lastKey, setLastKey] = useState('');
  const [lastKeyTime, setLastKeyTime] = useState(0);
  const listRef = useRef(null);

  const states = [
    "AL-Alabama", "AK-Alaska", "AZ-Arizona", "AR-Arkansas", "CA-California", "CO-Colorado", "CT-Connecticut", "DE-Delaware", "FL-Florida", "GA-Georgia",
    "HI-Hawaii", "ID-Idaho", "IL-Illinois", "IN-Indiana", "IA-Iowa", "KS-Kansas", "KY-Kentucky", "LA-Louisiana", "ME-Maine", "MD-Maryland",
    "MA-Massachusetts", "MI-Michigan", "MN-Minnesota", "MS-Mississippi", "MO-Missouri", "MT-Montana", "NE-Nebraska", "NV-Nevada", "NH-New Hampshire", "NJ-New Jersey",
    "NM-New Mexico", "NY-New York", "NC-North Carolina", "ND-North Dakota", "OH-Ohio", "OK-Oklahoma", "OR-Oregon", "PA-Pennsylvania", "RI-Rhode Island", "SC-South Carolina",
    "SD-South Dakota", "TN-Tennessee", "TX-Texas", "UT-Utah", "VT-Vermont", "VA-Virginia", "WA-Washington", "WV-West Virginia", "WI-Wisconsin", "WY-Wyoming"
  ];

  useEffect(() => {
    if (listRef.current) {
      listRef.current.focus();
    }
  }, []);

  const handleKeyDown = (e) => {
    if (e.key.length === 1 && e.key.match(/[a-z]/i)) {
      const currentTime = new Date().getTime();
      const key = e.key.toLowerCase();

      if (key !== lastKey || currentTime - lastKeyTime > 1000) {
        setCurrentIndex(-1);
      }

      setLastKey(key);
      setLastKeyTime(currentTime);

      const matchingOptions = states.filter(state =>
        state.toLowerCase().startsWith(key)
      );

      if (matchingOptions.length > 0) {
        const newIndex = (currentIndex + 1) % matchingOptions.length;
        setCurrentIndex(newIndex);
        const optionToSelect = matchingOptions[newIndex];
        setSelectedState(optionToSelect);
        const optionElement = listRef.current.querySelector(`[data-value="${optionToSelect}"]`);
        if (optionElement) {
          optionElement.scrollIntoView({ block: 'nearest' });
        }
      }
    } else if (e.key === 'Enter') {
      selectState();
    }
  };

  const handleClick = (state) => {
    setSelectedState(state);
    onStateSelect(state);
  };

  const selectState = () => {
    if (selectedState) {
      onStateSelect(selectedState);
    }
  };

  return (
    <div 
      style={{
        width: '470px',
        height: '338px',
        overflow: 'hidden'
      }}
    >
      <div
        ref={listRef}
        tabIndex="0"
        onKeyDown={handleKeyDown}
        style={{
          width: '100%',
          height: '100%',
          overflowY: 'scroll',
          outline: 'none'
        }}
      >
        {states.map((state) => (
          <div 
            key={state}
            data-value={state}
            onClick={() => handleClick(state)} // Use `state` here
            style={{
              padding: '5px',
              cursor: 'pointer',
              backgroundColor: state === selectedState ? '#e0e0e0' : 'transparent'
            }}
          >
            <label 
              style={{ display: 'flex', alignItems: 'center', width: '100%' }}  
              onClick={() => handleClick(state)} // Use `state` here
            >
              <input
                type="radio"
                readOnly
                checked={state === selectedState}
                style={{ marginRight: "1rem" }}
              />
              <span>{state}</span>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StateSelector;

import React from "react";
import Image from "../../../../imgData";

const TrustedFinancePartner = () => {
  return (
    <section className="p-4">
      <div className="container-fluid text-center">
        <div className="row">
          <div className="col-md-12 p-4 px-0">
            <h2 style={{ fontWeight: "700" }}>Trusted Financing Partners</h2>
            <p style={{ fontWeight: "600" }}>
              Dealers Auto Center is a trusted partner of leading banks across
              the USA
            </p>
            <div className="row pt-4">
              {/* <div className="customer-logos slider p-0"> */}

              <div className="auto_slider_containers">
                <ul className="auto_sliders">
                  <li>
                    <img src={Image.logo1} alt="bank 1" />
                  </li>
                  <li>
                    <img src={Image.logo2} alt="bank 2" />
                  </li>
                  <li>
                    <img src={Image.logo3} alt="bank 3" />
                  </li>
                  <li>
                    <img src={Image.logo4} alt="bank 4" />
                  </li>
                  <li>
                    <img src={Image.logo5} alt="bank 5" />
                  </li>
                  <li>
                    <img src={Image.logo1} alt="bank 1" />
                  </li>
                </ul>
                <ul className="auto_sliders">
                  <li>
                    <img src={Image.logo2} alt="bank 2" />
                  </li>
                  <li>
                    <img src={Image.logo3} alt="bank 3" />
                  </li>
                  <li>
                    <img src={Image.logo4} alt="bank 4" />
                  </li>
                  <li>
                    <img src={Image.logo5} alt="bank 5" />
                  </li>
                  <li>
                    <img src={Image.logo1} alt="bank 1" />
                  </li>
                  <li>
                    <img src={Image.logo2} alt="bank 2" />
                  </li>
                </ul>
                <ul className="auto_sliders">
                  <li>
                    <img src={Image.logo3} alt="bank 3" />
                  </li>
                  <li>
                    <img src={Image.logo4} alt="bank 4" />
                  </li>
                  <li>
                    <img src={Image.logo5} alt="bank 5" />
                  </li>
                  <li>
                    <img src={Image.logo1} alt="bank 1" />
                  </li>
                  <li>
                    <img src={Image.logo2} alt="bank 2" />
                  </li>
                  <li>
                    <img src={Image.logo3} alt="bank 3" />
                  </li>
                </ul>
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TrustedFinancePartner;

import { Button, Col, Container, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'

import { MdReplyAll, MdDelete, } from "react-icons/md";
import { PiArrowFatRightFill } from "react-icons/pi";
import { RiReplyFill } from "react-icons/ri";
import { ImAttachment } from "react-icons/im";
import { FaStar } from "react-icons/fa6";

import styles from '../mail.module.css'
import Image from '../../../imgData';

const MailCard = ({ handleActivePage }) => {
    return (
        <>
            <Col sm={12} className={`border ${styles.mailCard}`} onClick={(e) => handleActivePage("Mail templete", e)}>
                <div className={` ${styles.nameSec}`}>
                    <div className={`m-1 ${styles.select}`}><input type='checkbox' name='' id='' /></div>
                    <div className={`me-2 ${styles.pro}`}><img src={Image.user_icon} alt=""  /></div>
                    <div className={` ${styles.name}`}>Priyanka</div>
                </div>
                <div className={` ${styles.subject}`}>Good Tool!!</div>
                <div className={` ${styles.content}`}>mail content</div>
                <div className={` ${styles.icons}`}>
                    <OverlayTrigger
                        placement="bottom-start"
                        overlay={
                            <Tooltip id={`tooltip-attachment`} className="custom-tooltip">
                                Attachment
                            </Tooltip>
                        }>
                        <span className={` ${styles.icon}`}><ImAttachment /></span>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="bottom-start"
                        overlay={
                            <Tooltip id={`tooltip-star`} className="custom-tooltip">
                                Star
                            </Tooltip>
                        }>
                        <span className={` ${styles.icon}`}><FaStar /></span>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="bottom-start"
                        overlay={
                            <Tooltip id={`tooltip-forword`} className="custom-tooltip">
                                Forword
                            </Tooltip>
                        }>
                        <span className={` ${styles.icon}`}><PiArrowFatRightFill /></span>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="bottom-start"
                        overlay={
                            <Tooltip id={`tooltip-delete`} className="custom-tooltip">
                                Delete
                            </Tooltip>
                        }>
                        <span className={` ${styles.icon}`}><MdDelete /></span>
                    </OverlayTrigger>
                </div>
            </Col>
        </>
    )
}
export default MailCard;
import React from 'react'
import { Button, Col, OverlayTrigger, Tooltip, } from "react-bootstrap";

import { MdDelete } from "react-icons/md";
import { BsFillEnvelopeArrowUpFill } from "react-icons/bs";

import styles from "../mail.module.css";

const JunkCard = ({ handleActivePage }) => {
    return (<>
        <Col sm={12} className={`border ${styles.mailCard}`} onClick={(e) => handleActivePage("Mail templete", e)}>
            <div className={` ${styles.nameSec}`}>
                {/* <div className={`me-2 ${styles.pro}`}><img src={Image.user_icon} alt="" srcset="" /></div> */}
                <div className={` ${styles.name}`}>Priyanka</div>
            </div>
            <div className={` ${styles.email}`}>priyanka12@gmail.com</div>
            <div className={` ${styles.content}`}>mail content mail content mail content mail content mail content</div>
            <div className={` ${styles.iconsJunk}`}>
                {/* <span className={` ${styles.icon}`}><ImAttachment /></span>
                <span className={` ${styles.icon}`}><FaStar /></span>
                <span className={` ${styles.icon}`}><RiReplyFill /></span>
                <span className={` ${styles.icon}`}><MdReplyAll /></span>*/}
                <OverlayTrigger
                    placement="bottom-start"
                    overlay={
                        <Tooltip id={`tooltip-moveInbox`} className="custom-tooltip">
                            Move to Inbox
                        </Tooltip>
                    }>
                    <span className={` ${styles.icon}`}><BsFillEnvelopeArrowUpFill /></span>
                </OverlayTrigger>
                <OverlayTrigger
                    placement="bottom-start"
                    overlay={
                        <Tooltip id={`tooltip-delete`} className="custom-tooltip">
                            Delete
                        </Tooltip>
                    }>
                    <span className={` ${styles.icon}`}><MdDelete /></span>
                </OverlayTrigger>

            </div>
        </Col>
    </>)
}

export default JunkCard
import React from "react";
import Styles from "./MapTouch.module.css";
import Image from "../../../../imgData";

const MapTouch = () => {
  return (
    <div className={Styles.wrapper}>
      <div className={Styles.container}>
        <img src={Image.web3_gmap_image} alt="google map" />
        <div className={Styles.textContainer}>
          <p className={Styles.header}>Get in Touch</p>
          <p className={Styles.contactNumber}>(123) 456-7890</p>
          <div className={Styles.days}>
            <p>
              <span >Monday</span> 9:00-13:00
            </p>
            <p>
              <span>Tuesday</span> 9:00-13:00
            </p>
            <p>
              <span>Wednesday</span> 9:00-13:00
            </p>
            <p>
              <span>Thursday</span> 9:00-13:00
            </p>
            <p>
              <span>Friday</span> 9:00-13:00
            </p>
            <p>
              <span>Saturday</span> 9:00-13:00
            </p>
            <p>
              <span>Sunday</span> CLOSED
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapTouch;

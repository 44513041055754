import React, { useState, useEffect, useRef } from 'react';

const PopupColorData = ({ radioOptions, onStateSelect, boxColor }) => {
    const [selectedState, setSelectedState] = useState('');
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [lastKey, setLastKey] = useState('');
    const [lastKeyTime, setLastKeyTime] = useState(0);
    const listRef = useRef(null);

    useEffect(() => {
        if (listRef.current) {
            listRef.current.focus();
        }
    }, []);

    const handleKeyDown = (e) => {
        const currentTime = new Date().getTime();
        const key = e.key.toLowerCase();

        if (e.key.length === 1 && e.key.match(/[a-z]/i)) {
            if (key !== lastKey || currentTime - lastKeyTime > 1000) {
                setCurrentIndex(-1);
            }

            setLastKey(key);
            setLastKeyTime(currentTime);

            const matchingOptions = radioOptions.filter(option =>
                option.label.toLowerCase().startsWith(key)
            );

            if (matchingOptions.length > 0) {
                const newIndex = (currentIndex + 1) % matchingOptions.length;
                setCurrentIndex(newIndex);
                const optionToSelect = matchingOptions[newIndex].label;
                setSelectedState(optionToSelect);
                const optionElement = listRef.current.querySelector(`[data-value="${optionToSelect}"]`);
                if (optionElement) {
                    optionElement.scrollIntoView({ block: 'nearest' });
                }
            }
        } else if (e.key === 'Enter') {
            selectState();
        }
    };

    const handleClick = (label, color) => {
        setSelectedState(label);
        onStateSelect(label, color);
    };

    const selectState = () => {
        if (selectedState) {
            const selectedOption = radioOptions.find(option => option.label === selectedState);
            if (selectedOption) {
                onStateSelect(selectedState, selectedOption.boxColor);
            }
        }
    };

    return (
        <div
            style={{
                width: '470px',
                height: '88%',
                overflow: 'hidden'
            }}
        >
            <div
                ref={listRef}
                tabIndex="0"
                onKeyDown={handleKeyDown}
                style={{
                    width: '100%',
                    height: '100%',
                    // overflowY: 'scroll', // Ensures scroll functionality
                    outline: 'none'
                }}
            >
                {radioOptions.map((option) => (
                    <div
                        key={option.value}
                        data-value={option.label}
                        onClick={() => handleClick(option.label, option.boxColor)}
                        style={{
                            padding: '5px',
                            cursor: 'pointer',
                            backgroundColor: option.label === selectedState ? '#e0e0e0' : 'transparent'
                        }}
                    >
                        <label 
                            style={{ display: 'flex', alignItems: 'center', width: '100%' }}
                            onClick={() => handleClick(option.label, option.boxColor)}
                        >
                            <span
                                style={{
                                    display: "inline-block",
                                    height: "15px",
                                    width: "15px",
                                    borderRadius: "3px",
                                    marginRight: "15px",
                                    marginTop: "5px",
                                    border: "0.3px solid grey",
                                    backgroundColor: option.boxColor, // Adjusted property name
                                }}
                            ></span>
                            <input
                                type="radio"
                                readOnly
                                checked={option.label === selectedState} // Reflects selection
                                style={{ marginRight: "1rem" }}
                            />
                            <span>{option.label}</span>
                        </label>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PopupColorData;

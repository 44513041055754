import React from "react";
import Navbar from "./navbars/Navbar";

const PrivacyPolicy = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "85%",
          paddingTop: "1rem",
        }} 
      >
        <p>Privacy Policy</p>
        <p>Last updated: August 19, 2024</p>
        <p>
          This Privacy Policy describes our policies and procedures regarding
          the collection, use, and disclosure of your information when you use
          the Service. It also informs you about your privacy rights and how the
          law protects you.
        </p>
        <p>
          We use your personal data to provide and improve the Service. By using
          the Service, you agree to the collection and use of information in
          accordance with this Privacy Policy. This Privacy Policy has been
          created with the assistance of the Privacy Policy Generator.
        </p>
        <p style={{ color: "blue", fontWeight: "500", fontSize: "large" }}>
          Interpretation and Definitions
        </p>
        <p style={{ color: "blue", fontWeight: "500", marginBottom: "0" }}>
          Interpretation
        </p>
        <p>
          Words with initial capital letters have meanings defined under the
          following conditions. These definitions shall have the same meaning
          whether they appear in the singular or plural form.
        </p>
        <p style={{ color: "blue", fontWeight: "500", marginBottom: "0" }}>
          Definitions
        </p>
        <p>For the purposes of this Privacy Policy:</p>
        <div style={{ paddingLeft: "2rem", marginBottom: "1rem" }}>
          <p style={{ marginBottom: "3px" }}>
            <span style={{ fontWeight: "500" }}>Account</span> means a unique
            account created for You to access our Service or parts of our
            Service.
          </p>
          <p style={{ marginBottom: "3px" }}>
            <span style={{ fontWeight: "500" }}>Affiliate</span> means an entity
            that controls, is controlled by or is under common control with a
            party, where "control" means ownership of 50% or more of the shares,
            equity interest or other securities entitled to vote for election of
            directors or other managing authority.
          </p>
          <p style={{ marginBottom: "3px" }}>
            <span style={{ fontWeight: "500" }}>Company</span> (referred to as
            either "the Company", "We", "Us" or "Our" in this Agreement) refers
            to Dealers Auto Center Inc., 1503 S McDonald St.
          </p>
          <p style={{ marginBottom: "3px" }}>
            <span style={{ fontWeight: "500" }}>Cookies</span> are small files
            that are placed on Your computer, mobile device or any other device
            by a website, containing the details of Your browsing history on
            that website among its many uses.
          </p>
          <p style={{ marginBottom: "3px" }}>
            <span style={{ fontWeight: "500" }}>Country</span> refers to: Texas,
            United States
          </p>
          <p style={{ marginBottom: "3px" }}>
            <span style={{ fontWeight: "500" }}>Device</span> means any device
            that can access the Service such as a computer, a cellphone or a
            digital tablet.
          </p>
          <p style={{ marginBottom: "3px" }}>
            <span style={{ fontWeight: "500" }}>Personal</span> Data is any
            information that relates to an identified or identifiable
            individual.
          </p>
          <p style={{ marginBottom: "3px" }}>
            {" "}
            <span style={{ fontWeight: "500" }}>Service</span> refers to the
            Website.
          </p>
          <p style={{ marginBottom: "3px" }}>
            <span style={{ fontWeight: "500" }}>Service</span> Provider means
            any natural or legal person who processes the data on behalf of the
            Company. It refers to third-party companies or individuals employed
            by the Company to facilitate the Service, to provide the Service on
            behalf of the Company, to perform services related to the Service or
            to assist the Company in analyzing how the Service is used.
          </p>
          <p style={{ marginBottom: "3px" }}>
            <span style={{ fontWeight: "500" }}>Third</span>-party Social Media
            Service refers to any website or any social network website through
            which a User can log in or create an account to use the Service.
          </p>
          <p style={{ marginBottom: "3px" }}>
            <span style={{ fontWeight: "500" }}>Usage</span> Data refers to data
            collected automatically, either generated by the use of the Service
            or from the Service infrastructure itself (for example, the duration
            of a page visit).
          </p>
          <p style={{ marginBottom: "3px" }}>
            <span style={{ fontWeight: "500" }}>Website</span> refers to Dealers
            Auto Center Inc., accessible from https://dealersautocenter.com/
          </p>
          <p style={{ marginBottom: "3px" }}>
            <span style={{ fontWeight: "500" }}>You</span> means the individual
            accessing or using the Service, or the company, or other legal
            entity on behalf of which such individual is accessing or using the
            Service, as applicable.
          </p>
        </div>
        <p style={{ color: "blue", fontWeight: "500", fontSize: "large" }}>
          Collecting and Using Your Personal Data
        </p>
        <p style={{ color: "blue", fontWeight: "500" }}>
          Types of Data Collected
        </p>
        <p style={{ color: "blue", fontWeight: "500", marginBottom: "3px" }}>
          Personal Data
        </p>
        <p>
          While using Our Service, We may ask You to provide Us with certain
          personally identifiable information that can be used to contact or
          identify You. Personally identifiable information may include, but is
          not limited to:
        </p>
        <p
          style={{
            display: "flex",
            flexDirection: "column",
            paddingLeft: "2rem",
          }}
        >
          <span>Email address</span>
          <span>First name and last name</span>
          <span>Phone number</span>
          <span>Usage Data</span>
        </p>
        <p style={{ color: "blue", fontWeight: "500", marginBottom: "3px" }}>
          Using Data
        </p>
        <p>Usage Data is collected automatically when using the Service.</p>
        <p>
          Usage Data may include information such as Your Device's Internet
          Protocol address (e.g. IP address), browser type, browser version, the
          pages of our Service that You visit, the time and date of Your visit,
          the time spent on those pages, unique device identifiers and other
          diagnostic data.
        </p>
        <p>
          When You access the Service by or through a mobile device, We may
          collect certain information automatically, including, but not limited
          to, the type of mobile device You use, Your mobile device unique ID,
          the IP address of Your mobile device, Your mobile operating system,
          the type of mobile Internet browser You use, unique device identifiers
          and other diagnostic data.
        </p>
        <p>
          We may also collect information that Your browser sends whenever You
          visit our Service or when You access the Service by or through a
          mobile device.
        </p>
        <p style={{ color: "blue", fontWeight: "500", marginBottom: "3px" }}>
          Information from Third-Party Social Media Services
        </p>
        <p>
          The Company allows You to create an account and log in to use the
          Service through the following Third-party Social Media Services:
        </p>
        <ul>
          <li>Google</li>
          <li>Facebook</li>
          <li>Instagram</li>
          <li>Twitter</li>
          <li>Linkedin</li>
        </ul>
        <p>
          If You decide to register through or otherwise grant us access to a
          Third-Party Social Media Service, We may collect Personal data that is
          already associated with Your Third-Party Social Media Service's
          account, such as Your name, Your email address, Your activities or
          Your contact list associated with that account.
        </p>
        <p>
          You may also have the option of sharing additional information with
          the Company through Your Third-Party Social Media Service's account.
          If You choose to provide such information and Personal Data, during
          registration or otherwise, You are giving the Company permission to
          use, share, and store it in a manner consistent with this Privacy
          Policy.
        </p>
        <p style={{ color: "blue", fontWeight: "500", marginBottom: "3px" }}>
          Tracking Technologies and Cookies
        </p>
        <p>
          We use Cookies and similar tracking technologies to track the activity
          on Our Service and store certain information. Tracking technologies
          used are beacons, tags, and scripts to collect and track information
          and to improve and analyze Our Service. The technologies We use may
          include:
        </p>
        <ul>
          <li>
            <span style={{ fontWeight: "500" }}>
              Cookies or Browser Cookies.
            </span>{" "}
            A cookie is a small file placed on Your Device. You can instruct
            Your browser to refuse all Cookies or to indicate when a Cookie is
            being sent. However, if You do not accept Cookies, You may not be
            able to use some parts of our Service. Unless you have adjusted Your
            browser setting so that it will refuse Cookies, our Service may use
            Cookies.
          </li>
          <li>
            <span>Web Beacons.</span> Certain sections of our Service and our
            emails may contain small electronic files known as web beacons (also
            referred to as clear gifs, pixel tags, and single-pixel gifs) that
            permit the Company, for example, to count users who have visited
            those pages or opened an email and for other related website
            statistics (for example, recording the popularity of a certain
            section and verifying system and server integrity).
          </li>
        </ul>
        <p>
          Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
          remain on Your personal computer or mobile device when You go offline,
          while Session Cookies are deleted as soon as You close Your web
          browser. You can learn more about cookies on TermsFeed website
          article.
        </p>
        <p>
          We use both Session and Persistent Cookies for the purposes set out
          below:
        </p>
        <div style={{ paddingLeft: "2rem", marginBottom: "1rem" }}>
          <p style={{ marginBottom: "3px", fontWeight: "500" }}>
            Necessary / Essential Cookies
          </p>
          <p style={{ marginBottom: "3px" }}>Type: Session Cookies</p>
          <p style={{ marginBottom: "3px" }}>Administered by: Us</p>
          <p style={{ marginBottom: "3px" }}>
            Purpose: These Cookies are essential to provide You with services
            available through the Website and to enable You to use some of its
            features. They help to authenticate users and prevent fraudulent use
            of user accounts. Without these Cookies, the services that You have
            asked for cannot be provided, and We only use these Cookies to
            provide You with those services.
          </p>
          <p style={{ marginBottom: "3px", fontWeight: "500" }}>
            Cookies Policy / Notice Acceptance Cookies
          </p>
          <p style={{ marginBottom: "3px" }}>Type: Persistent Cookies</p>
          <p style={{ marginBottom: "3px" }}>Administered by: Us</p>
          <p style={{ marginBottom: "3px" }}>
            Purpose: These Cookies identify if users have accepted the use of
            cookies on the Website.
          </p>
          <p style={{ marginBottom: "3px", fontWeight: "500" }}>
            Functionality Cookies
          </p>
          <p style={{ marginBottom: "3px" }}>Type: Persistent Cookies</p>
          <p style={{ marginBottom: "3px" }}>Administered by: Us</p>
          <p>
            Purpose: These Cookies allow us to remember choices You make when
            You use the Website, such as remembering your login details or
            language preference. The purpose of these Cookies is to provide You
            with a more personal experience and to avoid You having to re-enter
            your preferences every time You use the Website.
          </p>
        </div>
        <p>
          For more information about the cookies we use and your choices
          regarding cookies, please visit our Cookies Policy or the Cookies
          section of our Privacy Policy.
        </p>

        <p style={{ color: "blue", fontWeight: "500" }}>
          Use of Your Personal Data
        </p>
        <p>The Company may use Personal Data for the following purposes:</p>
        <div>
          <p>
            <span>To provide and maintain our Service</span> including to
            monitor the usage of our Service.
          </p>
          <p>
            <span>To manage Your Account:</span> to manage Your registration as
            a user of the Service. The Personal Data You provide can give You
            access to different functionalities of the Service that are
            available to You as a registered user.
          </p>
          <p>
            <span>For the performance of a contract: the development,</span>{" "}
            compliance and undertaking of the purchase contract for the
            products, items or services You have purchased or of any other
            contract with Us through the Service.
          </p>
          <p>
            To contact You: To contact You by email, telephone calls, SMS, or
            other equivalent forms of electronic communication, such as a mobile
            application's push notifications regarding updates or informative
            communications related to the functionalities, products or
            contracted services, including the security updates, when necessary
            or reasonable for their implementation.
          </p>
          <p>
            To provide You with news, special offers and general information
            about other goods, services and events which we offer that are
            similar to those that you have already purchased or enquired about
            unless You have opted not to receive such information.
          </p>
          <p>
            To manage Your requests: To attend and manage Your requests to Us.
          </p>
          <p>
            For business transfers: We may use Your information to evaluate or
            conduct a merger, divestiture, restructuring, reorganization,
            dissolution, or other sale or transfer of some or all of Our assets,
            whether as a going concern or as part of bankruptcy, liquidation, or
            similar proceeding, in which Personal Data held by Us about our
            Service users is among the assets transferred.
          </p>
          <p style={{ marginBottom: "3px" }}>
            <span style={{ fontWeight: "500" }}>For other purposes:</span> We
            may use Your information for other purposes, such as data analysis,
            identifying usage trends, determining the effectiveness of our
            promotional campaigns and to evaluate and improve our Service,
            products, services, marketing and your experience.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;

import React, { useEffect, useState } from "react";

import "./GridCard.css";
import { Link, useNavigate } from "react-router-dom";
import Image from "../imgData";
import { MdInfoOutline, MdPhoneInTalk } from "react-icons/md";
import { FaLocationDot, FaRegMessage } from "react-icons/fa6";
import { IoShareSocial } from "react-icons/io5";
import { SlSpeedometer } from "react-icons/sl";
import { FaHeart } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { IoLocationSharp } from "react-icons/io5";
import { VscTag } from "react-icons/vsc";
import {
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookShareButton,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { useSelector } from "react-redux";
import axios from "axios";
import { IoIosPerson } from "react-icons/io";
import { haversineDistanceMiles } from "./calculateDistance";

import { MdWrongLocation } from "react-icons/md";
import toast from "react-hot-toast";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { saveUser } from "../../Redux/slice/userSlice";
const imgUrl = process.env.REACT_APP_IMG_URL;

const GridCard = ({
  handleShowDialogueBox,
  product,
  ProductId,
  locationAccess,
}) => {
  // console.log("locationAccess Card=> ", locationAccess);
  const navigate = useNavigate();
  const [showSocialMedia, setShowSocialMedia] = useState(false);
  const [isFav, setIsFav] = useState(false);
  // console.log(product);
  const location = useLocation();
  const { id } = useSelector((state) => state.user);
  // console.log("User ID ", id);
  const handleShareLink = () => {
    console.log("Sharing link with the id");
    setShowSocialMedia(!showSocialMedia);
  };
  const generateShareUrl = (itemId) => {
    return `${window.location.origin}${location.pathname}/${itemId}`;
  };
  const userData = useSelector((state) => state.user);
  const estimatedPrice = product.estimated_cost;
  // Helper function to get the border color
  const getBorderColor = (condition) => {
    switch (condition) {
      case "New 8/32>":
        return Image.greenTyre;
      case "Good 6/32-7/32>":
        return Image.yellowTyre;
      case "Fair <5/32":
        return Image.blueTyre;
      case "Fully-Used NA":
        return Image.redTyre;
      default:
        return Image.tireCondition; // Default color if condition is unknown
    }
  };

  const handleLikeTheProduct = async () => {
    if (id === 0) {
      handleShowDialogueBox(true);
    } else {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/listing/add_favourite/${id}/${ProductId}`
        );
        console.log(res);
        setIsFav(true);
      } catch (error) {
        console.log("Server side error while liking the product");
      }
    }
  };
  // for single product details---------------
  const handleClick = () => {
    navigate(
      `/cardetail/${product.id}_${product.modelYear}_${product.modelMake}`,
      {
        state: { product },
      }
    );
  };
  // for mask vin----------
  const maskVin = () => {
    const vin = product.vin;
    return vin?.slice(0, 9) + "*".repeat(vin.length - 9);
  };

  const maskMileageIn = () => {
    const mileage_in = product.mileage_in.toString();
    return "*".repeat(mileage_in.length);
  };

  // for popup seller-description----
  const words = product?.seller_description?.split(" ");
  const {
    all_images,
    exterior_images,
    misc_images,
    interior_images,
    three_sixty_images,
  } = product && product.multimedia;
  const noImage = Image.image_not_available;
  const getFirstValidImage = (images) => {
    if (images) {
      const imageList = images.split(",").map((img) => img.trim());
      for (let image of imageList) {
        if (image) {
          return `${imgUrl}/${product.userId}/${image.trim()}`;
        }
      }
    }
    return null;
  };
  const imageUrl =
    (all_images && getFirstValidImage(all_images)) ||
    (exterior_images && getFirstValidImage(exterior_images)) ||
    (misc_images && getFirstValidImage(misc_images)) ||
    (interior_images && getFirstValidImage(interior_images)) ||
    (three_sixty_images && getFirstValidImage(three_sixty_images)) ||
    noImage;
  // console.log("product7888*****",product);

  //getting the location and distance between coordinates
  const [totalDistance, setTotalDistance] = useState(0);
  useEffect(() => {
    if (navigator.geolocation) {
      const { latitude, longitude } = locationAccess && locationAccess;

      const coords1 = {
        latitude: latitude && latitude,
        longitude: longitude && longitude,
      };
      const coords2 = {
        latitude: product?.coordinates && product?.coordinates?.latitude,
        longitude: product?.coordinates && product?.coordinates?.longitude,
      };

      if (latitude && longitude && product?.coordinates) {
        const distance = haversineDistanceMiles(coords1, coords2);
        setTotalDistance(distance.toFixed(1));
        // console.log(
        //   "distance => ",
        //   distance.toFixed(2),
        //   " latitude => ",
        //   latitude,
        //   " longitude => ",
        //   longitude,
        //   "coordinates => ",
        //   product?.coordinates
        // );
      } else {
        setTotalDistance(0);
      }
    }
  }, [locationAccess]);

  //location sending handle
  const [showlocationSend, setShowlocationSend] = useState(false);

  useEffect(() => {
    if (showlocationSend) {
      // toast.error("The dealer has not provided the location of this inventory");
      if (product && !product?.coordinates) {
        toast.error(
          "The dealer has not provided the location of this inventory"
        );
      }
    }
  }, [showlocationSend]);
  const [receiveData, setReceiveData] = useState({
    email: "",
    phoneNumber: "",
  });
  const handleReceiveDataChange = (e) => {
    const { name, value } = e.target;
    setReceiveData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const [error, setError] = useState(null);
  const handleReceiveDLink = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Success callback
          const { latitude, longitude } = position.coords;
          handleSendCoordinates(latitude, longitude);
          setError(null);
        },
        (err) => {
          // Error callback
          setError(err.message);
          toast.error(
            "Geolocation access was denied by the user. To get a directional link, please enable geolocation access in your browser settings."
          );
        }
      );
      // ;
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  };
  const [showLoginModal, setShowLoginModal] = useState(false);

  const dispatch = useDispatch();

  const handleCustomerLogin = (userType) => {
    setShowLoginModal(false);
    if (userType === "Customer") {
      dispatch(saveUser({ showLoginDialogue: true }));

      navigate("/");
    } else {
      navigate("/dealer/login");
    }
  };
  //Handle the data to send and receive of coordinates
  const handleSendCoordinates = (latitude, longitude) => {
    //If we have the coordinates of the user
    if (latitude && longitude) {
      //If the user is not logged in
      if (!id) {
        setShowLoginModal(true);
      } else {
        //If the user is looged in

        const directinalURL = `https://www.google.com/maps/dir/?api=1&origin=${latitude},${longitude}&destination=${product?.coordinates?.latitude},${product?.coordinates?.longitude}
`;
        console.log("directinalURL => ", directinalURL);
        toast.success("Directional link sent to your Email and Phone number");
      }
    } else {
      toast(
        "Please enable location access in your browser to get directional link"
      );
    }
  };

  //modal for availability
  const [showAvailibility, setShowAvailibility] = useState(false);
  return (
    <div className="card  " style={{ borderRadius: "25px", width: "100%" }}>
      <div className="row pb-0 rounded card_container-b ">
        <div className="col-lg-12 col-md-5 card_left-c">
          <div className="product-image-box-c">
            <span style={{ position: "relative" }}>
              <img
                src={imageUrl}
                alt="product_car_image"
                className="product_car_image"
              />
              <span className="grid-int-color">
                Int. Color{" "}
                <span
                  style={{
                    display: "inline-block", // Make the span an inline-block element
                    width: "20px", // Set the width for the circle
                    height: "20px", // Set the height for the circle
                    backgroundColor: product.interiorColor,
                    borderRadius: "50%", // Make it circular
                    // marginLeft: "1.3rem",
                    border: "1px solid white",
                  }}
                  title={product.interiorColor ? product.interiorColor : ""}
                />
              </span>
            </span>
            <div
              className="overlay-text top-text"
              onClick={handleClick}
              style={{ paddingLeft: "5px" }}
            >
              <div style={{ padding: "1.2rem", cursor: "pointer" }}>
                {product?.modelYear} {product?.modelMake} {product?.makeModel}
              </div>
              <span className="grid-card-mi">
                <SlSpeedometer style={{ fill: "#d1823a" }} />{" "}
                <span>
                  {new Intl.NumberFormat().format(product.mileage_in)} mi
                </span>{" "}
              </span>
            </div>

            <div
              className="overlay-text bottom-text"
              style={{ position: "relative" }}
            >
              <div className="gird-card-bottom-txt">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    padding: "1rem",
                    fontSize: "medium",
                  }}
                >
                  <p
                    style={{
                      display: "flex",
                      gap: "5px",
                      display: "none",
                    }}
                  >
                    <span> {product?.driveType || 0} </span>
                    <span> {product?.engine.split(" ")[1]} </span>
                    <span> {product?.engine.split(" ")[0]} </span>
                    <span> {product?.engine.split(" ")[3]} </span>
                    <span style={{ marginBottom: "0.5rem" }}>
                      {" "}
                      {product.transmission || "Transmission NA"}{" "}
                    </span>{" "}
                  </p>
                  <p style={{ marginBottom: "0.5rem" }}>
                    Seller : {product.user_name || "Seller Name NA"}
                  </p>
                  <p>
                    {/* <span style={{ fontSize: "large" }}>
                      <IoLocationSharp style={{ fill: "#d1823a" }} />{" "}
                    </span> */}
                    <span
                      className="theme-color"
                      style={{
                        color: "#D1823A",
                        fontWeight: "bold",
                        marginRight: "0.5rem",
                        marginTop: "-0.3rem",
                        fontSize: "larger",
                        cursor: "pointer",
                      }}
                      onClick={
                        product?.coordinates
                          ? () => setShowlocationSend(true)
                          : null
                      }
                    >
                      {product?.coordinates ? (
                        <FaLocationDot />
                      ) : (
                        <MdWrongLocation />
                      )}
                    </span>
                    {product?.location || "Seller Location NA"}{" "}
                    {totalDistance
                      ? `( ${totalDistance && totalDistance} mi away )`
                      : ""}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    // gap: "0.2rem",
                    alignItems: "center",
                    paddingRight: "0.2rem",
                  }}
                >
                  <p id="grid-card-price">
                    ${new Intl.NumberFormat().format(product.listing_price)}{" "}
                  </p>
                  <p style={{ marginBottom: "1.3rem" }}>
                    <span>
                      Est $
                      {new Intl.NumberFormat().format(
                        estimatedPrice ? Math.ceil(estimatedPrice) : 0
                      )}{" "}
                      mo
                    </span>
                    <span style={{ fontSize: "medium", marginLeft: "0.3rem" }}>
                      <MdInfoOutline />
                    </span>
                  </p>
                  <p
                    id="grid-card-check-avlt"
                    onClick={() => setShowAvailibility(true)}
                  >
                    Check Availability
                  </p>
                </div>
              </div>
            </div>

            <div className="product_share_like-c">
              {id !== 0 ? (
                <span onClick={handleLikeTheProduct}>
                  <FaHeart fill={`${isFav ? "red" : "white"}`} />
                </span>
              ) : null}
            </div>

            {showSocialMedia ? (
              <div className="social_media_container">
                <FacebookShareButton url={generateShareUrl(id)}>
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <TwitterShareButton url={generateShareUrl(id)}>
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
                <WhatsappShareButton url={generateShareUrl(id)}>
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
                <TelegramShareButton url={generateShareUrl(id)}>
                  <FacebookMessengerIcon size={32} round />
                </TelegramShareButton>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <Modal
        show={showlocationSend && product && product?.coordinates}
        onHide={() => setShowlocationSend(false)}
        size="lg"
      >
        <Modal.Body>
          <div>
            <p
              style={{
                fontWeight: "600",
                fontSize: "large",
                textAlign: "center",
                margin: "1rem 0",
              }}
            >
              To receive the directional link, please provide your email address
              and phone number.
            </p>
            <p
              style={{ display: "flex", justifyContent: "center", gap: "10%" }}
            >
              <input
                type="text"
                placeholder="Phone Number"
                style={{ width: "30%", padding: "0.3rem" }}
                value={receiveData.phoneNumber}
                name="phoneNumber"
                onChange={handleReceiveDataChange}
              />
              <input
                type="text"
                placeholder="Email"
                style={{ width: "30%", padding: "0.3rem" }}
                value={receiveData.email}
                name="email"
                onChange={handleReceiveDataChange}
              />
            </p>
            <p style={{ display: "flex", justifyContent: "center" }}>
              {" "}
              <button
                onClick={handleReceiveDLink}
                style={{
                  border: "none",
                  backgroundColor: "rgb(209, 130, 58)",
                  color: "white",
                  padding: "0.4rem 0.9rem",
                  borderRadius: "10px",
                  fontWeight: "bold",
                }}
              >
                Receive
              </button>
            </p>
            <p
              style={{
                fontSize: "medium",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              <span style={{ fontSize: "smaller", marginLeft: "0.3rem" }}>
                <MdInfoOutline />
              </span>{" "}
              <span>Make sure to enable location access in your browser.</span>
            </p>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showAvailibility}
        onHide={() => setShowAvailibility(false)}
        size="lg"
      >
        <Modal.Body>
          <div style={{ fontSize: "x-large", backgroundColor: "lightgrey" }}>
            <p
              style={{
                textAlign: "center",
                color: "#D1823A",
                fontWeight: "700",
              }}
            >
              <span>Check Availability</span>
            </p>
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "1.5rem",
                fontWeight: "600",
                marginBottom: "1.5em",
              }}
            >
              <span>
                <span style={{ borderBottom: "2px solid black" }}>
                  Call Now
                </span>{" "}
              </span>
              <span
                style={{
                  color: "white",
                  backgroundColor: "black",
                  borderRadius: "20px",
                  padding: "0.1rem 2.2rem",
                }}
              >
                (469) 469-4699
              </span>
            </p>
            <p style={{ borderBottom: "1px solid grey", position: "relative" }}>
              <span
                style={{
                  // border: "1px solid black",
                  position: "absolute",
                  right: "50%",
                  top: "-1.8rem",
                  padding: "0.4rem",
                  color: "#D1823A",

                  fontWeight: "600",
                  backgroundColor: "lightgrey",
                }}
              >
                Or
              </span>
            </p>
            <p
              style={{
                fontSize: "large",
                marginTop: "1.5rem",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              <span style={{ marginRight: "1.3rem" }}>My name is </span>
              <input
                type="text"
                placeholder="First Name"
                style={{
                  width: "25%",
                  border: "none",
                  marginRight: "1.3rem",
                  borderRadius: "10px",
                  padding: "0.4rem",
                }}
              />
              <input
                type="text"
                placeholder="Last Name"
                style={{
                  width: "25%",
                  border: "none",
                  marginRight: "1.3rem",
                  borderRadius: "10px",
                  padding: "0.4rem",
                }}
              />
              interested
            </p>
            <p
              style={{
                textAlign: "center",
                fontSize: "large",
                marginTop: "1.5rem",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              in {product.year_Make_Model} {product.trim}
            </p>
            <p
              style={{
                fontSize: "large",
                marginTop: "1.5rem",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              contact me at my
            </p>
            <p
              style={{
                fontSize: "large",
                marginTop: "1.5rem",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              <input
                type="text"
                placeholder="(XXX) XXX-XXXX"
                style={{
                  width: "25%",
                  border: "none",
                  marginRight: "1.3rem",
                  borderRadius: "10px",
                  padding: "0.4rem",
                }}
              />
              <span style={{ marginRight: "1.3rem" }}>&</span>
              <input
                type="text"
                placeholder="Email"
                style={{
                  width: "45%",
                  border: "none",

                  borderRadius: "10px",
                  padding: "0.4rem",
                }}
              />
            </p>
            <div
              style={{
                marginTop: "1rem",

                borderRadius: "8px",
                display: "flex",
                justifyContent: "center",
                marginBottom: "0.4rem",
              }}
            >
              <div
                style={{
                  width: "95%",
                  height: "80%",
                  backgroundColor: "white",
                  borderRadius: "15px",
                  padding: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <p
                  style={{
                    fontSize: "medium",
                    marginTop: "1.5rem",
                    fontWeight: "600",
                  }}
                >
                  <span>Need more info about</span>
                </p>
                <div
                  style={{
                    display: "flex",
                    //  border: "1px solid red"
                    fontSize: "large",
                    fontWeight: "600",
                  }}
                >
                  <p
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: "0.5",
                    }}
                  >
                    <label htmlFor="VehHisRep">
                      <input
                        type="checkbox"
                        id="VehHisRep"
                        style={{ marginRight: "1rem" }}
                      />
                      Vehicle history report
                    </label>
                    <label htmlFor="FinOptions">
                      <input
                        type="checkbox"
                        id="FinOptions"
                        style={{ marginRight: "1rem" }}
                      />
                      Finance Options
                    </label>
                    <label htmlFor="haveTradeIn">
                      <input
                        type="checkbox"
                        id="haveTradeIn"
                        style={{ marginRight: "1rem" }}
                      />
                      I have a trade in
                    </label>
                    <label htmlFor="other">
                      <input
                        type="checkbox"
                        id="other"
                        style={{ marginRight: "1rem" }}
                      />
                      Other
                    </label>
                  </p>
                  <p
                    style={{
                      flex: "0.5",
                      // border: "1px solid blue",
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                      paddingRight: "2rem",
                    }}
                  >
                    {" "}
                    <button
                      style={{
                        backgroundColor: "#D1823A",
                        border: "none",
                        padding: "1rem",
                        borderRadius: "10px",
                      }}
                    >
                      Send
                    </button>
                  </p>
                </div>
                <label
                  htmlFor="agree"
                  style={{ fontSize: "medium", fontWeight: "600" }}
                >
                  <input
                    type="checkbox"
                    id="agree"
                    style={{ marginRight: "1rem" }}
                  />
                  By clicking send , I accept to contacted by
                  dealersautocenter.com in the future.
                </label>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default GridCard;

import React, { useState } from 'react'
import {
  Tabs,
  Tab,
  Container,
  Row,
  Col,
  Button,
  Form,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";

const DealerRetail = () => {
  // for tabs-----------------------------
  const [activeTab, setActiveTab] = useState("tab1");
  const handleNextButtonClick = () => {
    const tabKeys = [
      "tab1",
      "tab2",
      "tab3",
      "tab4",
    ];
    const currentIndex = tabKeys.indexOf(activeTab);

    if (currentIndex < tabKeys.length - 1) {
      const nextTab = tabKeys[currentIndex + 1];
      setActiveTab(nextTab);
    }
  };

  const handleBackButtonClick = () => {
    const tabKeys = ["tab1", "tab2", "tab3", "tab4"];
    const currentIndex = tabKeys.indexOf(activeTab);

    if (currentIndex > 0) {
      const prevTab = tabKeys[currentIndex - 1];
      setActiveTab(prevTab);
    }
  };
  return (
    <div><Tabs
      activeKey={activeTab}
      onSelect={(k) => setActiveTab(k)}
      id="my-tabs"
    >
      <Tab eventKey="tab1" title="Card">
      First Section
      </Tab>
      <Tab eventKey="tab2" title="Transaction">
      Second Section
      </Tab>
      <Tab eventKey="tab3" title="Payment">
      Third Section
      </Tab>
      <Tab eventKey="tab4" title="Services">
      Four Section
      </Tab>
    </Tabs>
    </div>
  )
}

export default DealerRetail;
import "../Styles/Dealer_Input_Login_feature.css";
import React, { useEffect, useState } from "react";
import "../Styles/Dealer_Input_Login_feature.css";
import axios from "axios";
import Image from "../../imgData";
import Fingerprint2 from "fingerprintjs2";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { sideBarArray } from "../../dashboard/SideBarData";
import toast from "react-hot-toast";
import {
  saveUser,
  setShowUserCompleteProfileDialogueBox,
  updateInitialDataFromAPI,
} from "../../../Redux/slice/userSlice";
import rollerLoader from "../Styles/loadingRoller.gif";
import { setDynamicAPILabels } from "../../../Redux/slice/taxes&FeesSlice";
const Dealer_Input_Login_feature = () => {
  const { id } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [InputPass, setInputPass] = useState("");
  const [showPass, setShowPass] = useState(false);
  const [fingerprint, setFingerprint] = useState("");
  const [error, setError] = useState("");
  console.log("Error", error);
  const [ip, setIP] = useState("");
  // const [city, setCity] = useState("");
  console.log(fingerprint);
  const [loading, setLoading] = useState(false);
  console.log("loading =>", loading);

  // UseEffect for Fingerprint
  useEffect(() => {
    const getDeviceFingerprint = () => {
      return new Promise((resolve, reject) => {
        Fingerprint2.get((components) => {
          const values = components.map((component) => component.value);
          const fingerprintId = Fingerprint2.x64hash128(values.join(""), 31);
          resolve(fingerprintId);
        });
      });
    };

    const fetchFingerprint = async () => {
      try {
        const fingerprintId = await getDeviceFingerprint();

        setFingerprint(fingerprintId);
      } catch (error) {
        console.error("Error getting fingerprint:", error);
      }
    };

    // Wait for fingerprint to be generated before rendering the component
    const timeout = setTimeout(() => {
      fetchFingerprint();
    }, 1000); // Adjust timeout value as needed

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  // useEffect for IP Address of user while
  const getUserIPaddress = async () => {
    try {
      const res = await axios.get("https://api.ipify.org/?format=json");
      console.log("User IP Address", res);
      setIP(res.data.ip);
    } catch (error) {
      console.log("error while getting ip", error);
    }
  };
  useEffect(() => {
    getUserIPaddress();
  }, [ip]);

  // user city current addres
  // const [city, setCity] = useState("");
  // console.log("User current city", city);

  // useEffect(() => {
  //   async function fetchCity() {
  //     const userCity = await UserCity();
  //     console.log(userCity);
  //     if (userCity) {
  //       setCity(userCity);
  //     }
  //   }
  //   fetchCity();
  // }, []);

  // function for verifying the password

  // //fetch the dynamic label of the user
  // const fetchDynamicLabels = async () => {
  //   try {
  //     const res = await axios.get(
  //       `${process.env.REACT_APP_API_URL}/sell/get_labels/${Userdata.id}`
  //     );
  //     console.log("Line 31", res.data);
  //     const mappedResponse =
  //       res &&
  //       res.data &&
  //       res.data.map((item) => ({
  //         id: item.id,
  //         label: item.itemName,
  //         InputValue: item.itemValue,
  //       }));
  //     console.log("mappedResponse => ", mappedResponse);
  //     dispatch(setDynamicAPILabels(mappedResponse))
  //   } catch (error) {
  //     console.log("Error while fetching labels => ", error);
  //   }
  // };

  const Userdata = useSelector((state) => state.user);
  const handleContinueResult = async () => {
    console.log("Handling API continue Result");
    setLoading(true);
    const formData = new FormData();
    try {
      formData.append("email", location.state);
      formData.append("password", InputPass);
      formData.append("fingerprint", fingerprint);
      formData.append("ip", ip);
      formData.append("user_type", "Dealer");

      console.log("formData", formData);
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/verify_password`,
        formData
      );
      console.log("response while loggin in", res);
      if (res.status === 200) {
        if (res.data.device_verify) {
          // navigate("/myprofile/dashboard");
          dispatch(
            saveUser({
              email: res.data.email,
              id: res.data.id,
              dealerId: res.data.dealerId,
              phoneNumber: res.data.phoneNumber,
              firstName: res.data.firstName,
              lastName: res.data.lastName,
              office_number: res.data.office_number,
              lattitude: res.data.lattitude,
              longitude: res.data.longitude,
              zip_code: res.data.zip_code,
              address: res.data.address,
              profile_picture: res.data.profile_picture,
              website: res.data.website,
              roles: res.data.roles,
              loginAccess: res.data.loginAccess,
              dealerApproval: res.data.dealerApproval,
              dealerData: res.data.dealerData,
            })
          );
          navigate("/dealer/dashboard");
          fetchSideBarData(res.data.id);
          toast.success("Dealer login successful");
          setLoading(false);
          console.log("Device verify is true");
          console.log(
            "Device verify in Dealer_Input_Login_Feature",
            res.data.device_verify
          );
        } else {
          const dataToVerify = {
            email: res.data.email,
            password: InputPass,
            fingerprint: fingerprint,
          };
          navigate("/dealer/verifyDeviceOTP", { state: dataToVerify });
          console.log(
            "Device verify in Dealer_Input_Login_Feature",
            res.data.device_verify
          );
          toast("Please verify your device", {
            duration: 2000,
          });
          console.log("Device verify in Dealer_Input_Login_Feature is false");
          setLoading(false);
        }
        // fetchDynamicLabels()
      } else if (res.status === 400) {
        console.log("Bad request");
        toast.error("Bad Request");
        setError("Error");
        setLoading(false);
      }
    } catch (error) {
      console.log(
        "error in catch part while logging in using password",
        error.response.data
      );
      toast.error(error.response.data);
      setLoading(false);
    }
  };
  const handleChangeView = () => {
    setShowPass(!showPass);
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "Enter") {
        handleContinueResult();
      }
    };
    document.addEventListener("keypress", handleKeyPress);
    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, [InputPass]);

  const handleInputChange = async (e) => {
    setInputPass(e.target.value);
  };
  const [forgetPasswordLoader, setForgetPasswordLoader] = useState(false);
  const handleForgetPassword = async () => {
    setForgetPasswordLoader(true);
    if (location.state !== "") {
      try {
        const formData = new FormData();
        formData.append("email", location.state);
        formData.append("user_type", "Dealer");
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/user/forgetotp`,
          formData
        );
        if (res.status === 200) {
          console.log("I forgot password ");
          console.log("res.data", res.data);
          navigate("/dealer/forgetPassOTP", { state: location.state });
          setForgetPasswordLoader(false);
          toast.success("OTP sent to your device");
        }
      } catch (error) {
        setForgetPasswordLoader(false);
        toast.error(error.response.data);
        console.log("Error 500 while handling forgot password", error);
      }
    }
  };

  // Update side bar user activity after login
  const fetchSideBarData = async (user_id) => {
    const fd = new FormData();
    fd.append("user_id", user_id);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/tab/get`,
        fd
      );
      console.log(res);
      if (res.status === 200) {
        const transformedData = sideBarArray.map((item) => {
          const apiItem = res.data.find((apiItem) => apiItem.tabId === item.id);
          return {
            ...item,
            count: apiItem ? apiItem.tabCount : item.count,
          };
        });

        // Dispatch action to update the initial state with transformed data
        dispatch(updateInitialDataFromAPI(transformedData));
        localStorage.setItem(
          "user_sidebar_activity",
          JSON.stringify(transformedData)
        );
      }
    } catch (error) {
      console.log(
        "Server side error during getting user activity in my profile",
        error
      );
    }
  };

  return (
    <div className="login_feature_wrapper">
      <div className="login_feature_container">
        <p
          className="welcome_message"
          style={{ fontWeight: "700", fontSize: "xx-large" }}
        >
          Dealer Log-in
        </p>
        {/* <p className="sign_in_message">Please enter your password</p> */}
        {/* <label htmlFor="input_password" className="email_username_label">
          Password *
        </label> */}
        <div className="input_and_icon">
          <input
            type={!showPass ? "password" : "text"}
            onChange={handleInputChange}
            value={InputPass}
            id="input_password"
            placeholder="Enter Password"
            className="input_field_dealer_login"
            style={{ fontWeight: "700", fontSize: "xx-large" }}
          />
          <img
            src={!showPass ? Image.eye_icon : Image.hide_icon}
            alt="hide/unhide_icon"
            height={20}
            onClick={handleChangeView}
          />
        </div>
        <p className="forget_password">
          {forgetPasswordLoader && (
            <img src={rollerLoader} alt="loader" height={20} />
          )}{" "}
          <p
            onClick={handleForgetPassword}
            disabled={forgetPasswordLoader ? true : false}
            style={{
              cursor: forgetPasswordLoader ? "not-allowed" : "pointer",
              marginLeft: "0.3rem",
              color: forgetPasswordLoader ? "grey" : "black",
              marginBottom: "0",
            }}
            className="dealer_forget_pass_btn"
          >
            Forget Password
          </p>
        </p>
        <button
          onClick={handleContinueResult}
          disabled={
            forgetPasswordLoader ? true : false || InputPass.trim() === ""
          }
          className={loading ? "blink_now" : ""}
          style={{
            fontWeight: "700",
            fontSize: "xx-large",
            cursor:
              forgetPasswordLoader || InputPass.trim() === ""
                ? "not-allowed"
                : "pointer",
          }}
        >
          <span>Continue</span>
          {loading ? (
            <img
              src="https://dacbuckets3.s3.amazonaws.com/sport-car.png"
              alt="animated car icon"
              className="car_image_move"
            />
          ) : null}
        </button>
      </div>
      {error ? (
        <p
          className="error_message"
          style={{ fontWeight: "500", fontSize: "large" }}
        >
          {error
            .split(" ")
            .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
            .join(" ")}
        </p>
      ) : null}
    </div>
  );
};

export default Dealer_Input_Login_feature;

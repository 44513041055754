import React, { useEffect, useRef, useState } from "react";
import Styles from "./Styles/DealerMessages.module.css";
import { useOutletContext } from "react-router-dom";
import MessageProfileContainer from "./components/MessageProfileContainer";
import InputMessageContainer from "./components/InputMessageContainer";
import ChatContainer from "./components/ChatContainer";
import MiniChatBox from "./components/MiniChatBox";
import Image from "../../imgData";
import { useDispatch, useSelector } from "react-redux";

import WebSocketService from "./WebSocketService";

const DealerMessages = () => {
  const { id } = useSelector((state) => state.user);
  // console.log("id", id);
  const isExpanded = useOutletContext();
  //const [messageInput, setMessageInput] = useState([]);
  // const handleMessageInput = (e) => {
  //   setMessageInput(e.target.value);
  // };

  //console.log("message:",messageInput);

  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [username, setUsername] = useState(id); // Sender's username
  const [recipient, setRecipient] = useState(""); // Recipient's username
  const [isTyping, setIsTyping] = useState(false);
  const typingTimeoutRef = useRef(null);

  useEffect(() => {
    if (username) {
      console.log(`Connecting as ${username}`);
      WebSocketService.connect(onMessageReceived, username); // Connect WebSocket
    }

    // Cleanup on component unmount
    return () => {
      console.log("Disconnecting WebSocket");
      WebSocketService.disconnect();
    };
  }, [username]);

  const onMessageReceived = (message) => {
    const messageBody = JSON.parse(message.body);
    console.log("Received message:", messageBody);
    if (messageBody.type === "CHAT") {
      setMessages((prevMessages) => [...prevMessages, messageBody]);
    } else if (messageBody.type === "TYPING") {
      setIsTyping(messageBody.content !== "");
    }
  };

  const sendMessage = () => {
    if (!username || !recipient || !input) {
      alert(
        "Please make sure you have set a username, recipient, and message content."
      );
      return;
    }

    const message = {
      sender: username,
      recipient: recipient,
      content: input,
      type: "CHAT",
    };

    // Add the sender's message to the state immediately
    // setMessages((prevMessages) => [...prevMessages, message]);

    // Send the message to the backend via WebSocket
    WebSocketService.sendMessage(message);

    // Clear the input field
    setInput("");
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleInputFocus = () => {
    // Send typing status when the input field is focused
    WebSocketService.sendTypingStatus(username, recipient, true);
  };

  const handleInputBlur = () => {
    // Clear typing status when the input field loses focus
    WebSocketService.sendTypingStatus(username, recipient, false);
  };

  console.log("username", username);
  console.log("messages", messages);
  console.log("input", input);
  console.log("recipient", recipient);
  console.log("isTyping", isTyping);

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div className={`d-flex justify-content-center flex-nowrap `}>
        <div className={` `}>
          <div className={Styles.container}>
            <MessageProfileContainer isExpanded={isExpanded} />
            <ChatContainer
              input={input}
              handleInputChange={handleInputChange}
              messages={messages}
              sendMessage={sendMessage}
              handleInputFocus={handleInputFocus}
              handleInputBlur={handleInputBlur}
            />
          </div>
          {/* Mini Chats */}
          <div className={Styles.MiniChatBoxContainer}>
            <MiniChatBox index={0} />
            <MiniChatBox index={1} />
            <MiniChatBox index={2} />
          </div>
        </div>
        <div className={`p-3 ${Styles.activeProCon}`}>
          <div className={` ${Styles.activePros}`}>
            <img src={Image.user_icon} alt="" srcset="" />
          </div>
          <div className={` ${Styles.activePros}`}>
            <img src={Image.user_icon} alt="" srcset="" />
          </div>
          <div className={` ${Styles.activePros}`}>
            <img src={Image.user_icon} alt="" srcset="" />
          </div>
          <div className={` ${Styles.activePros}`}>
            <img src={Image.user_icon} alt="" srcset="" />
          </div>
          <div className={` ${Styles.activePros}`}>
            <img src={Image.user_icon} alt="" srcset="" />
          </div>
          <div className={` ${Styles.activePros}`}>
            <img src={Image.user_icon} alt="" srcset="" />
          </div>
          <div className={` ${Styles.activePros}`}>
            <img src={Image.user_icon} alt="" srcset="" />
          </div>
          <div className={` ${Styles.activePros}`}>
            <img src={Image.user_icon} alt="" srcset="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealerMessages;

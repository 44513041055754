import React from 'react'
import Navbar from './navbars/Navbar'

const TermsCondition = () => {
  return (
    
    <div>
<Navbar />
<div style={{margin:"20px"}}>
      <h4>Terms & Conditions</h4>
      <p>
        These Terms and Conditions ("Terms") govern your use of Dealers Auto Center and any services provided therein. By accessing or using the Website, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use the Website.
      </p>
      <h5>1. Use of the Website</h5>
      <p>
        a. You must be at least 18 years old to use the Website. By using the Website, you represent and warrant that you are at least 18 years old.
      </p>
      <p>
        b. You agree to use the Website only for lawful purposes and in accordance with these Terms and any applicable laws and regulations.
      </p>
      <p>
        c. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer or device to prevent unauthorized access to your account. You agree to accept responsibility for all activities that occur under your account or password.
      </p>
      <h5>2. Intellectual Property Rights</h5>
      <p>
        a. The Website and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio) are owned by [Your Company Name] and its licensors and are protected by copyright, trademark, and other intellectual property laws.
      </p>
      <p>
        b. You may not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information, software, products, or services obtained from the Website.
      </p>
      <h5>3. User Content</h5>
      <p>
        a. You retain ownership of any content you submit or upload to the Website ("User Content"). However, by submitting or uploading User Content, you grant [Your Company Name] a worldwide, non-exclusive, royalty-free, perpetual, irrevocable, and fully sublicensable license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, perform, and display such User Content in any media.
      </p>
      <p>
        b. You represent and warrant that you own or have the necessary rights, licenses, consents, and permissions to submit or upload User Content and to grant the license described above.
      </p>
      <h5>4. Privacy</h5>
      <p>
        a. Our collection and use of your personal information on the Website are governed by our Privacy Policy, which is incorporated into these Terms by reference.
      </p>
      <h5>5. Limitation of Liability</h5>
      <p>
        a. In no event shall [Your Company Name], its affiliates, or their respective officers, directors, employees, or agents be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Website; (ii) any conduct or content of any third party on the Website; (iii) any content obtained from the Website; and (iv) unauthorized access, use, or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence), or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.
      </p>
      <h5>6. Governing Law</h5>
      <p>
        a. These Terms shall be governed by and construed in accordance with the laws of [Your Country], without regard to its conflict of law provisions.
      </p>
      <h5>7. Changes to Terms</h5>
      <p>
        a. We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
      </p>
      <h5>8. Contact Us</h5>
      <p>
        a. If you have any questions about these Terms, please contact us at <a href="mailto:Support@dealersautocenter.com">Support@dealersautocenter.com</a>.
      </p>
    </div>
    </div>
  )
}

export default TermsCondition
import React, { useState, useEffect } from "react";
import SponsoredCard from "./SponsoredCard";
import GridCard from "./GridCard";

const GridLayout = ({
  items,
  sponsoredItems,
  handleShowDialogueBox,
  locationAccess,
}) => {
  const [renderedItems, setRenderedItems] = useState([]);

  console.log("locationAccess Card=> ", locationAccess);
  useEffect(() => {
    let newRenderedItems = [];
    let sponsoredIndex = 0;
    let nextSponsoredPosition = 6;

    items.forEach((item, index) => {
      // Add sponsored card if it's the right index
      if (
        index + 1 === nextSponsoredPosition &&
        sponsoredIndex < sponsoredItems.length
      ) {
        newRenderedItems.push(
          <SponsoredCard
            key={`sponsored-${sponsoredIndex}`}
            item={sponsoredItems[sponsoredIndex]}
          />
        );
        sponsoredIndex++;
        // Set next sponsored position
        nextSponsoredPosition =
          index + 1 + (nextSponsoredPosition === 6 ? 5 : 6);
      }

      // Add regular grid card
      newRenderedItems.push(
        <GridCard
          key={`regular-${index}`}
          product={item}
          handleShowDialogueBox={handleShowDialogueBox}
          ProductId={item?.id}
          locationAccess={locationAccess && locationAccess}
        />
      );
    });

    setRenderedItems(newRenderedItems);
  }, [items, sponsoredItems, handleShowDialogueBox]);

  return (
    <div
      // className="grid-container"
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gap: "1rem",
        rowGap: "6rem",
        marginBottom: "6rem",
      }}
    >
      {renderedItems}
    </div>
  );
};

export default GridLayout;

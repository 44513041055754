const DraftData = [
    { id: 1, inputLabel: "First Name", key: "firstName", inputValue: "" },
    { id: 2, inputLabel: "Middle Name", key: "middleName", inputValue: "" },
    { id: 3, inputLabel: "Last Name", key: "lastName", inputValue: "" },
    { id: 4, inputLabel: "Phone Number", key: "phoneNumber", inputValue: "" },
    { id: 5, inputLabel: "Email", key: "email", inputValue: "" },
    { id: 6, inputLabel: "Sales Price", key: "salesPrice", inputValue: "" },
    { id: 7, inputLabel: "Trade in", key: "tradeIn", inputValue: "" },
    {
      id: 8,
      inputLabel: "Cash Difference",
      key: "cashDifference",
      inputValue: "",
    },
    {
      id: 9,
      inputLabel: "Documentation Fees",
      key: "documentationFees",
      inputValue: "",
    },
    {
      id: 10,
      inputLabel: "Wind-Shield Sticker",
      key: "windShieldSticker",
      inputValue: "",
    },
    {
      id: 11,
      inputLabel: "TERP Title Fee to DMV",
      key: "terpTitleFeetoDMV",
      inputValue: "",
    },
    {
      id: 12,
      inputLabel: "Title Application Fee",
      key: "titleAppFee",
      inputValue: "",
    },
    {
      id: 13,
      inputLabel: "REG. FEE-DPS to Tax-Office",
      key: "regFeesDPStoTaxOffice",
      inputValue: "",
    },
    {
      id: 14,
      inputLabel: "CNTY ROAD Bridge Addon Fee",
      key: "cntyRoadBridgeAddonFee",
      inputValue: "",
    },
    {
      id: 15,
      inputLabel: "Child Safety to Tax Office",
      key: "childSafetyToTaxOffice",
      inputValue: "",
    },
    {
      id: 16,
      inputLabel: "Proc. & handling fee to tax office",
      key: "procHandlingFee",
      inputValue: "",
    },
    { id: 17, inputLabel: "Metal Tag", key: "metalTag", inputValue: "25.00" },
    {
      id: 18,
      inputLabel: "Inspection fee to the State",
      key: "inspectionFeeFeeToState",
      inputValue: "",
    },
    {
      id: 19,
      inputLabel: "Inspection fee to Station",
      key: "inspectionFeeFeeToStation",
      inputValue: "",
    },
  ];
  export default DraftData;
  
import React from "react";
import Navbar3 from "./Navbar3";
import Hero3 from "./Hero3";
import Features from "./Features";
import AboutUs from "./AboutUs";
import FeaturedLisiting from "./FeaturedLisiting";
import Feedbacks from "./Feedbacks";
import Financing from "./Financing";
import MapTouch from "./MapTouch";
import Footer from "./Footer";

const Web3 = () => {
  return (
    <div>
      <Navbar3 />
      <p
        style={{
          backgroundColor: "red",
          padding: "0.5rem",
          fontSize: "larger",
          color: "white",
          fontWeight: "500",
          textAlign: "center",
        }}
      >
        <span>
          This is a preview only. The site is not functional; please activate
          this design to access the full features.
        </span>
      </p>
      <Hero3 />
      <Features />
      <AboutUs />
      <FeaturedLisiting />
      <Feedbacks />
      <Financing />
      <MapTouch />
      <Footer />
    </div>
  );
};

export default Web3;

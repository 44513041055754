import React, { useState } from "react";
import Styles from "../Styles/MiniChatBox.module.css";
import { LuPhoneCall } from "react-icons/lu";
import { FaWindowMinimize } from "react-icons/fa";
import { IoClose, IoLocationSharp, IoSend } from "react-icons/io5";
import { PiDotsThreeVerticalBold } from "react-icons/pi";
import { GrEmoji } from "react-icons/gr";
import { FaBullseye, FaRegWindowMaximize } from "react-icons/fa6";
import { CgAttachment } from "react-icons/cg";
import { AiOutlineDollar } from "react-icons/ai";
import EmojiPicker from 'emoji-picker-react';

const MiniChatBox = ({ index }) => {
  const [showBody, setShowBody] = useState(false);
  const [showAttachments, setShowAttachments] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [inputVal, setInputVal] = useState("");
  const handleInputMessage = (e) => {
    setInputVal(e.target.value);
  };
  const handleEmojiClick = (emojiObject) => {
    console.log(emojiObject);
    // Append the selected emoji to the input field
    setInputVal(prevInputVal => prevInputVal + emojiObject.emoji);
    setShowEmojiPicker(false); // Optionally hide the picker after selection
  };
  const handleShowBody = () => {
    console.log(showBody ? "View Body" : "Hide Body");
    setShowBody(!showBody);
  };
  return (
    <div
      className={`${Styles.wrapper} ${!showBody ? Styles.minimized : Styles.maximised
        }`}
      style={{ right: `${index * 310}px` }}
    >
      <div className={Styles.container}>
        <div className={` py-3 ${Styles.header}`}>
          <img src="https://picsum.photos/id/237/200/300" alt="random" />
          <span className={Styles.name}>Braim Anna</span>
          <span className={Styles.callIcon} style={{ cursor: "pointer" }}>
            <LuPhoneCall />
          </span>
          <span className={Styles.MinimiseBtn} onClick={handleShowBody} style={{ cursor: "pointer" }}>
            {showBody ? <FaWindowMinimize /> : <FaRegWindowMaximize />}
          </span>
          <span className={Styles.closeBtn} style={{ cursor: "pointer" }}>
            <IoClose />
          </span>
        </div>
        {showBody && (
          <>
            <div className={Styles.body}>
              <p>I am interested in this vehicle</p>
              <p>Thank you for showing interest</p>
              <p>I am interested in this vehicle</p>
              <p>Thank you for showing interest</p>
              <p>Should I share some paperwork</p>
              <p>Yeah Sure</p>
            </div>
            {showEmojiPicker && <div className={Styles.EmojiContainer}><EmojiPicker onEmojiClick={handleEmojiClick} /> </div>}
            <div className={` mt-3 ${Styles.inputBox}`}>
              <span
                onClick={() => setShowAttachments(!showAttachments)}
                style={{ cursor: "pointer" }}
              >
                {" "}
                <PiDotsThreeVerticalBold />
              </span>
              <input type="text" value={inputVal} onChange={handleInputMessage} placeholder="Enter your message..." />
              <span
                className={Styles.showEmojiButton2}
                onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                style={{ cursor: "pointer" }}
              >
                <GrEmoji />
              </span>
              <span style={{ margin: "0 5px" }}>
                <IoSend />
              </span>
              {showAttachments && (
                <div className={Styles.attachmentContainer}>
                  <p>
                    <span style={{ color: "#4184cb" }}>
                      <IoLocationSharp />
                    </span>
                    <span>Location</span>
                  </p>
                  <p>
                    <span>
                      <CgAttachment />
                    </span>
                    <span>Attachment</span>
                  </p>
                  <p>
                    <span>
                      <AiOutlineDollar />
                    </span>
                    <span>Loan App</span>
                  </p>
                </div>
              )}
            </div>{" "}
          </>
        )}
      </div>
    </div>
  );
};

export default MiniChatBox;

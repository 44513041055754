import React, { useEffect, useState } from "react";
import Styles from "../Styles/Box_A.module.css";
import { useSelector, useDispatch } from "react-redux";
import { setRetailData } from "../../../../Redux/slice/taxes&FeesSlice";

const Box_A = ({ retailFromRedux, apiData }) => {
  const dispatch = useDispatch();
  const selectedCar = useSelector((state) => state?.sell?.selectedVehicle);
  const userListingPrice = parseFloat(retailFromRedux.userListingPrice);
  const purchase_price = useSelector(
    (state) => parseFloat(state.taxesAndFees.dealerRetail.purchase_price) || 0
  );
  // Initialize state with Redux data
  const [salesTaxData, setSalesTaxData] = useState(retailFromRedux.salesTaxes);
  const [mInventoryTaxData, setMInventoryTaxData] = useState(
    retailFromRedux.mInventoryTax
  );

  const salesTaxDollar =
    (salesTaxData * (userListingPrice - purchase_price)) / 100 || 0;
  const mInventoryDollar =
    mInventoryTaxData * (userListingPrice - purchase_price) || 0;

  // Update Redux store when API data changes
  useEffect(() => {
    if (apiData) {
      const { salesTaxes, mInventoryTax } = apiData;
      setSalesTaxData(salesTaxes);
      setMInventoryTaxData(mInventoryTax);
      dispatch(setRetailData({ salesTaxes, mInventoryTax }));
    }
  }, [apiData, dispatch]);

  // Update Redux store when tax values or selectedCar change
  useEffect(() => {
    const totalTax = salesTaxDollar + mInventoryDollar;
    const totalFessTax = totalTax.toFixed(2);
    dispatch(setRetailData({ totalFees: totalFessTax }));
  }, [salesTaxDollar, mInventoryDollar, dispatch, selectedCar]);

  // Update Redux store when tax values or selectedCar change
  useEffect(() => {
    dispatch(
      setRetailData({
        saleTaxValue: salesTaxDollar.toFixed(2),
        mInventoryValue: mInventoryDollar.toFixed(2),
      })
    );
  }, [salesTaxDollar, mInventoryDollar]);

  // Handle input changes and update Redux store
  const handleSalesTaxChange = (e) => {
    const value = e.target.value;
    setSalesTaxData(value);
    dispatch(setRetailData({ salesTaxes: value }));
  };

  const handleMInventoryTaxChange = (e) => {
    const value = e.target.value;
    setMInventoryTaxData(value);
    dispatch(setRetailData({ mInventoryTax: value }));
  };

  return (
    <div className={Styles.wrapper}>
      <div className={Styles.container}>
        <div className={Styles.detailValueContainer}>
          <span className={Styles.detailValueHeader}>Sales Taxes</span>
          <p>
            <div className="d-flex align-items-center">
              <span>%</span>
              <input
                type="text"
                value={salesTaxData}
                onChange={handleSalesTaxChange}
                step="0.1"
                min="0"
                style={{
                  // width: '120px',
                   backgroundColor: 'transparent',
                  border: "none",
                }}
              />
            </div>
            <span style={{ paddingTop: "5px", paddingRight: "5px" }}>
              ${salesTaxDollar.toFixed(2)}
            </span>
          </p>
        </div>
        <div className={Styles.detailValueContainerMinventory}>
          <span className={Styles.detailValueHeader}>Inventory Tax</span>
          <p>
            <div className="d-flex align-items-center">
              <span>%</span>
              <input
                type="text"
                value={mInventoryTaxData}
                onChange={handleMInventoryTaxChange}
                step="0.1"
                min="0"
                style={{
                  // width: '120px',
                   backgroundColor: 'transparent;',
                  border: "none",
                }}
              />
            </div>
            <span style={{ paddingTop: "5px" }}>
              ${mInventoryDollar.toFixed(2)}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Box_A;

import React, { useState } from "react";
import Styles from "./DNSManagementSystem.module.css";
import PlugConnectIcon from "./PlugConnectIcon.png";

import { PiPlugs } from "react-icons/pi";
import SearchIcon from "./EarthSearch.png";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import DNSsteps from "./DNSsteps";
const apiUrl = process.env.REACT_APP_API_URL;
const DNSManagementSystem = ({ show, handleClose }) => {
  const { id } = useSelector((state) => state.user);
  const [domainInfo, setDomainInfo] = useState(null); // State to hold the domain info
  const [domainChoice, setDomainChoice] = useState("existingDomain");
  // -------------------------------------------------------------------
  const [showModalSteps, setShowModalSteps] = useState(false);
  const handleLinkClickSteps = () => {
    setShowModalSteps(true);
  };
  const handleModalCloseSteps = () => {
    setShowModalSteps(false);
  };
  // -----------------------------------------------------
  const handleDoaminChange = (e) => {
    setDomainChoice(e.target.value);
    console.log(e.target.value);
  };

  const [domainInput, setdomainInput] = useState("");
  console.log("domainInput => ", domainInput);
  const handleDomainInput = (e) => {
    setdomainInput(e.target.value);
  };

  const [selectedDomainValue, setSelectedDomainValue] = useState("com");
  console.log("selectedDomainValue => ", selectedDomainValue);
  const handleSelectedDomainValue = (e) => {
    setSelectedDomainValue(e.target.value);
  };

  const handleSearch = async () => {
    const fullDomain = `${domainInput}.${selectedDomainValue}`;

    try {
      const response = await fetch(
        `${apiUrl}/domain/info?domainName=${encodeURIComponent(fullDomain)}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Success:", data);
      setDomainInfo(data); // Update state with fetched data
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const domainName = `${domainInput}.${selectedDomainValue}`;
//   const handleConnect = async () => {
//     const fullDomain = `${domainInput}.${selectedDomainValue}`;

//     const payload = {
//         domainName: fullDomain,
//         userId: id,
//         templateId: 1,
//     };

//     try {
//         const response = await fetch(`${apiUrl}/template/save`, {
//             method: "POST",
//             headers: {
//                 "Content-Type": "application/json",
//             },
//             body: JSON.stringify(payload),
//         });
// console.log("success ", response)
//         if (response.ok) {
//             const data = await response;
//             console.log("Success:", data);
//             handleSearch(); // Call handleSearch() when response is successful
//         } else {
//             throw new Error("Network response was not ok");
//         }

//     } catch (error) {
//         console.error("Error:", error);
//     }
// };

  const wrapText = (text, limit) => {
    const words = text.split(" ");
    const wrapped = [];
    for (let i = 0; i < words.length; i += limit) {
      wrapped.push(words.slice(i, i + limit).join(" "));
    }
    return wrapped.join("\n"); // Join with line breaks
  };

  const wrappedMessage = wrapText(
    "You will need to update your domain name server from your domain control panel. Please click here to find the steps to updating your domain DNS.",
    10
  );
  // for set the domain information --------------------------------
  console.log("domainInfo",domainInfo?.registrar);
  const DomainName = domainInfo?.registrar;
  const isDomainValid = DomainName === "GoDaddy.com, LLC" || DomainName === "NameCheap, Inc.";
  // ----------------------------------------------------------------
  return (
    <>
    <Modal
      show={show}
      size="xl"
      onHide={handleClose}
      dialogClassName="modal-90w"
    >
      <Modal.Header closeButton>
        <div>
          <Modal.Title>DNS Section</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className={Styles.wrapper}>
          <div className={Styles.container}>
            <p className={Styles.header}>
              <span>WWW</span>
              <span>Please enter your domain name or register new domain</span>
            </p>
            <p className={Styles.radioContainers}>
              <label htmlFor="existingDomain" className={Styles.radioLabel}>
                <input
                  type="radio"
                  id="existingDomain"
                  name="domain"
                  value="existingDomain"
                  onChange={handleDoaminChange}
                  checked={domainChoice === "existingDomain"}
                />
                <span>I want to connect my existing domain.</span>
              </label>
              <label htmlFor="currentDomain" className={Styles.radioLabel}>
                <input
                  type="radio"
                  id="currentDomain"
                  name="domain"
                  value="currentDomain"
                  checked={domainChoice === "currentDomain"}
                  onChange={handleDoaminChange}
                />
                <span>I want to register new domain.</span>
              </label>
            </p>

            <p className={Styles.inputContainers}>
              <input
                type="text"
                placeholder="Domain name"
                value={domainInput}
                onChange={handleDomainInput}
              />
              <span>.</span>

              <select
                value={selectedDomainValue}
                onChange={handleSelectedDomainValue}
              >
                <option value="com">com</option>
                <option value="us">us</option>
                <option value="uk">uk</option>
                <option value="org">org</option>
                <option value="cn">cn</option>
                <option value="net">net</option>
                <option value="au">au</option>
              </select>
              <button>
                <span className={Styles.link_icon}>
                  {domainChoice === "existingDomain" ? (
                    <img
                      src={PlugConnectIcon}
                      alt="earth search icon"
                      height={60}
                      style={{ marginBottom: "-10px" }}
                      onClick={handleSearch}
                      title="Connect"
                    />
                  ) : (
                    <img
                      src={SearchIcon}
                      alt="earth search icon"
                      height={40}
                      // onClick={handleSearch}
                      // style={{ border: "1px solid black" }}
                      title="Search"
                    />
                  )}
                </span>
              </button>
            </p>
            <p className={Styles.inputContainers}>
              {domainInfo && ( // Conditionally render the info if available
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <label>Domain Name: {domainName}</label>
                  <label>Domain Provider: {domainInfo.registrar}</label>
                  <label>Domain Reg.Date: {domainInfo.registrationDate}</label>
                  <label>Domain Exp.Date: {domainInfo.expiryDate}</label>
                  <div style={{ display: "flex", alignItems: "flex-start",flexDirection:"column" }}>
                    <pre style={{ margin: 0 }}>{wrappedMessage}</pre>
                    <div style={{ display: "flex", alignItems: "flex-start",flexDirection:"row" }}>
                    <button type="button" style={{ marginLeft: "10px",marginTop:"5%",textAlign:"center",alignItems:"center",backgroundColor:"grey",color:"white",width:"70px",height:"30px",borderRadius:"5px" }} onClick={() => handleLinkClickSteps()} disabled={!isDomainValid}>
                      Yes
                    </button>
                    <button type="button" style={{ marginLeft: "10px",marginTop:"5%",textAlign:"center",alignItems:"center",backgroundColor:"grey",color:"white",width:"70px",height:"30px",borderRadius:"5px" }} onClick={() => handleLinkClickSteps()} disabled={isDomainValid}>
                      Others
                    </button>
                    </div>
                  </div>
                </div>
              )}
            </p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
    <DNSsteps
        show={showModalSteps}
        handleClose={handleModalCloseSteps}
        DomainName= {DomainName}
        domainName= {domainName}
      />
    </>
  );
};

export default DNSManagementSystem;

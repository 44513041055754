import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Row, FormControl } from 'react-bootstrap';
import Image from "../../imgData";
import "./equipments.css";

const Equipments = ({ formData, setFormData, apiDataEdit, setApiDataEdit }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredEquipments, setFilteredEquipments] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('safetyFeatures');
  const [selectedEquipments, setSelectedEquipments] = useState([]);
  const [selectedEquipmentsByGroup, setSelectedEquipmentsByGroup] = useState({});
  const [allSelectedEquipments, setAllSelectedEquipments] = useState([]);
  const [showInput, setShowInput] = useState(false);
  const [extraEquipmentName, setExtraEquipmentName] = useState('');
  const [extraVehicleOptions, setExtraVehicleOptions] = useState(formData.extraVehicleOptions || []);
  const [isDropdownVisible, setIsDropdownVisible] = useState(true);
  const groupsAndEquipments = {
    'Safety Features': [
      { name: 'ABS (Anti-lock Braking System)', key: 'anti_lock_brake_system' },
      { name: 'Back Up Camera', key: 'back_up_camera' },
      { name: 'Blind spot Monitoring', key: 'blind_spot_monitoring' },
      { name: 'Break Assist', key: 'brake_assist' },
      { name: 'Collision Mitigation system', key: 'collision_mitigation_system' },
      { name: 'Cross Traffic Alert', key: 'cross_traffic_alert' },
      { name: 'Curtain Airbag', key: 'curtain_airbag' },
      { name: 'daytime Running Lights', key: 'daytime_running_lights' },
      { name: 'Electronic Stability Control', key: 'electronic_stability_control' },
      { name: 'Forward Collision warning', key: 'forward_collision_warning' },
      { name: 'Front Airbag', key: 'front_airbag' },
      { name: 'Hill Descent Control', key: 'hill_descent_control' },
      { name: 'Hill Start assist', key: 'hill_start_assist' },
      { name: 'Lane departure warning', key: 'lane_departure_warning' },
      { name: 'Lane Keeping assist', key: 'lane_keeping_assist' },
      { name: 'Parking Sensors', key: 'parking_sensors' },
      { name: 'Repare cross traffic alert', key: 'rear_cross_traffic_alert' },
      { name: 'Side Airbags', key: 'side_airbags' },
      { name: 'Trafic Control', key: 'traction_control' },
      { name: 'Tyre Pressure monitoring', key: 'tyre_pressure_monitoring' }
    ],
    'Comfort And Convenience': [
      { name: 'Adaptive Cruise Control', key: 'adaptive_cruise_control' },
      { name: 'Auto Climate Control', key: 'auto_climate_control' },
      { name: 'Auto Dimming Rearview Mirror', key: 'auto_dimming_rearview_mirror' },
      { name: 'Heated Ventilated Seats', key: 'heated_ventilated_seats' },
      { name: 'Key Less Entry', key: 'key_less_entry' },
      { name: 'Key Less go', key: 'key_less_go' },
      { name: 'Leather seats', key: 'leather_seats' },
      { name: 'Memory Seats', key: 'memory_seats' },
      { name: 'Multizone climate control', key: 'multizone_climate_control' },
      { name: 'panormic sunroof', key: 'panormic_sunroof' },
      { name: 'power adjustable seats', key: 'power_adjustable_seats' },
      { name: 'Power listgate', key: 'power_liftgate' },
      { name: 'Power window mirrors', key: 'power_window_mirrors' },
      { name: 'Remote starter', key: 'remote_starter' },
      { name: 'Smart trunk opener', key: 'smart_trunk_opener' },
      { name: 'Sunshades', key: 'sunshades' },
      { name: 'Title telescopic steering wheel', key: 'tilt_telescopic_steering_wheel' }
    ],
    'Infotainment': [
      { name: 'Apple Carplay Android Auto', key: 'apple_carplay_android_auto' },
      { name: 'Blutooth Connectivity', key: 'bluetooth_connectivity' },
      { name: 'Incar wifi', key: 'incar_wifi' },
      { name: 'Navigation System', key: 'navigation_system' },
      { name: 'Premium Sound System', key: 'premium_sound_system' }
    ],
    'Exterior Features': [
      { name: 'Alloy wheels', key: 'alloy_wheels' },
      { name: 'Automatic Headlight', key: 'automatic_headlights' },
      { name: 'Fog Lights', key: 'fog_lights' },
      { name: 'Led Headlight', key: 'led_headlights' },
      { name: 'Power Folding Mirrors', key: 'power_folding_mirrors' }
    ],
    'Performance And Handling': [
      { name: 'Adaptive suspension', key: 'adaptive_suspension' },
      { name: 'All Wheel Drive', key: 'all_wheels_drive' },
      { name: 'Drive Mode Selector', key: 'drive_mode_selector' },
      { name: 'Limited Slip Differential', key: 'limited_slip_differential' },
      { name: 'Sports Tuned Suspension', key: 'sport_tuned_suspension' }
    ],
    'Interior Features': [
      { name: 'Ambient Listing', key: 'ambient_lighting' },
      { name: 'Cargo Cover', key: 'cargo_cover' },
      { name: 'cargo Net', key: 'cargo_net' },
      { name: 'Center Console Armrest', key: 'center_console_armrest' },
      { name: 'Heated steering Wheels', key: 'heated_steering_wheels' }
    ],
    'Drive Assistance': [
      { name: 'Adaptive Headlights', key: 'adaptive_headlights' },
      { name: 'Automatic High Beams', key: 'automatic_high_beams' },
      { name: 'Driver Drowsiness Monitoring', key: 'driver_drowsiness_monitoring' },
      { name: 'Head Up Display', key: 'head_up_display' },
      { name: 'Night Version', key: 'night_vision' },
    ],
    'Energy And Equipment': [
      { name: 'Engine Start Stop System', key: 'engine_start_stop_system' },
      { name: 'Hybrid Powertrain', key: 'hybrid_powertrain' },
      { name: 'Regenerative Breaking', key: 'regenerative_breaking' },
      { name: 'Solar Roof', key: 'solar_roof' },
    ],
    'Extra Vehicle Options': extraVehicleOptions.map(option => ({
      name: option.option_name,
      key: option.option_name.toLowerCase().replace(/\s+/g, '_'),
    })),
  };

  // Debounce hook to prevent rapid input changes
  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);

    return debouncedValue;
  }

  const debouncedSearchQuery = useDebounce(searchQuery, 500);


  useEffect(() => {
    if (debouncedSearchQuery) {
      const filtered = [];
      for (const group in groupsAndEquipments) {
        if (groupsAndEquipments.hasOwnProperty(group)) {
          filtered.push(
            ...groupsAndEquipments[group]
              .filter(equipment =>
                equipment.name.toLowerCase().includes(debouncedSearchQuery.toLowerCase())
              )
              .map(equipment => ({ group, equipment }))
          );
        }
      }
      setFilteredEquipments(filtered);
    } else {
      setFilteredEquipments([]);
    }
  }, [debouncedSearchQuery, extraVehicleOptions]); // Included extraVehicleOptions as a dependency

  useEffect(() => {
    if (apiDataEdit) {
      const { vehicleOptions } = apiDataEdit;
      if (vehicleOptions) {
        const updatedExtraVehicleOptions = vehicleOptions.extraVehicleOptions.map(option => ({
          ...option,
          id: option.id,
        }));

        setExtraVehicleOptions(updatedExtraVehicleOptions);

        setFormData(prevFormData => ({
          ...prevFormData,
          vehicleOptions: {
            ...prevFormData.vehicleOptions,
            ...vehicleOptions,
            extraVehicleOptions: updatedExtraVehicleOptions,
          },
        }));

        const selected = [];
        for (const group in vehicleOptions) {
          if (group === 'extraVehicleOptions') {
            const extraOptions = vehicleOptions[group];
            extraOptions.forEach(option => {
              if (option.option_value) {
                selected.push({
                  name: option.option_name,
                  key: option.option_name.toLowerCase().replace(/\s+/g, '_'),
                  option_value: option.option_value,
                });
              }
            });
          } else {
            const options = vehicleOptions[group];
            for (const key in options) {
              if (options[key]) {
                const equipment = groupsAndEquipments[group]?.find(eq => eq.key === key);
                if (equipment) selected.push({ ...equipment, option_value: true });
              }
            }
          }
        }
        setAllSelectedEquipments(selected);
      }
    }
  }, [apiDataEdit]);


  const toggleOptionValue = (name) => {
    setAllSelectedEquipments(prevSelected =>
      prevSelected.filter(equipment => equipment.name !== name)
    );

    // Update formData
    setFormData(prevFormData => {
      const updatedVehicleOptions = { ...prevFormData.vehicleOptions };
      let updated = false;

      // First, check in extraVehicleOptions
      if (updatedVehicleOptions.extraVehicleOptions) {
        updatedVehicleOptions.extraVehicleOptions = updatedVehicleOptions.extraVehicleOptions.map(option => {
          if (option.option_name === name) {
            updated = true;
            return { ...option, option_value: false };
          }
          return option;
        });
      }

      // If not found in extraVehicleOptions, check in other groups
      if (!updated) {
        for (const group in updatedVehicleOptions) {
          if (typeof updatedVehicleOptions[group] === 'object' && updatedVehicleOptions[group] !== null) {
            const key = name.toLowerCase().replace(/\s+/g, '_');
            if (key in updatedVehicleOptions[group]) {
              updatedVehicleOptions[group][key] = false;
              updated = true;
              break;
            }
          }
        }
      }

      return { ...prevFormData, vehicleOptions: updatedVehicleOptions };
    });
  };

  // ------------------------------------------------------------
  const handleGroupSelect = (group) => {
    setSelectedGroup(group);
    setSearchQuery("");
    setFilteredEquipments([]);
  };

  // Update selected equipments for the group when it changes
  useEffect(() => {
    setSelectedEquipmentsByGroup((prevSelectedEquipmentsByGroup) => ({
      ...prevSelectedEquipmentsByGroup,
      [selectedGroup]: selectedEquipments,
    }));
  }, [selectedGroup, selectedEquipments]);

  const handleEquipmentSelect = (equipment) => {
    setAllSelectedEquipments(prevSelected => {
      const index = prevSelected.findIndex(item => item.name === equipment.name);
      if (index === -1) {
        updateVehicleOptionState(equipment.key, true);
        return [...prevSelected, { ...equipment, option_value: true }];
      } else {
        toggleOptionValue(equipment.name);
        return prevSelected;
      }
    });
    setIsDropdownVisible(false);
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setIsDropdownVisible(true); // Add this line
  };
  useEffect(() => {
    if (!searchQuery) {
      setIsDropdownVisible(true);
    }
  }, [searchQuery]);

  const updateVehicleOptionState = (key, value) => {
    if (selectedGroup === 'extraVehicleOptions') {
      setExtraVehicleOptions(prevOptions =>
        prevOptions.map(option =>
          option.option_name.toLowerCase().replace(/\s+/g, '_') === key
            ? { ...option, option_value: value }
            : option
        )
      );

      setFormData(prevFormData => ({
        ...prevFormData,
        vehicleOptions: {
          ...prevFormData.vehicleOptions,
          extraVehicleOptions: prevFormData.vehicleOptions.extraVehicleOptions.map(option =>
            option.option_name.toLowerCase().replace(/\s+/g, '_') === key
              ? { ...option, option_value: value }
              : option
          )
        }
      }));
    } else {
      setFormData(prevFormData => ({
        ...prevFormData,
        vehicleOptions: {
          ...prevFormData.vehicleOptions,
          [selectedGroup]: {
            ...prevFormData.vehicleOptions[selectedGroup],
            [key]: value,
          },
        },
      }));
    }
  };

  // const handleSearchChange = (e) => {
  //   setSearchQuery(e.target.value);
  // };

  const handleAddEquipmentClick = () => {
    if (showInput && extraEquipmentName.trim() !== '') {
      const newOption = {
        id: 0, // Always set id to 0 for new extra vehicle options
        option_name: extraEquipmentName.trim(),
        option_value: false,
      };

      setExtraVehicleOptions(prevOptions => {
        const updatedOptions = [...prevOptions, newOption];

        // Update formData vehicleOptions
        setFormData(prevFormData => ({
          ...prevFormData,
          vehicleOptions: {
            ...prevFormData.vehicleOptions,
            extraVehicleOptions: updatedOptions,
          },
        }));
        return updatedOptions;
      });

      setExtraEquipmentName('');
    }
    setShowInput(!showInput);
  };

  const handleInputChange = (e) => {
    setExtraEquipmentName(e.target.value);
  };

  return (
    <Container className="px-0">
      <Row className="searchParent">
        <Col className="searchOptionParent">
          <div className="searchFormContainer text-center">
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              className="border-0 text-center"
              placeholder="Search the Equipments here"
            />
            <img
              src={Image.search_orange_icon}
              alt="Search Icon"
              style={{ height: "30px", width: "30px" }}
            />
          </div>
          {filteredEquipments.length > 0 && isDropdownVisible && (
            <div className="dropdown-menu show position-absolute w-100">
              {filteredEquipments.map(({ group, equipment }) => (
                <div
                  key={equipment.name}
                  className="dropdown-item"
                  onClick={() => handleEquipmentSelect(equipment)}
                >
                  {`${equipment.name} (${group})`}
                </div>
              ))}
            </div>
          )}
        </Col>
      </Row>

      <Row className="groups-container">
        <Col sm={7} className="px-0">
          <Container>
            <Row>
              <Col className="groups">
                <div className='groups-head'>Please Select The Group</div>
                {Object.keys(groupsAndEquipments).map(group => (
                  <div
                    key={group}
                    className={`group ${selectedGroup === group ? 'selected' : ''}`}
                    onClick={() => handleGroupSelect(group)}
                  >
                    {group}
                    {selectedGroup === group && <span className="arrow"></span>}
                  </div>
                ))}
              </Col>
              <Col className="equipments">
                <div className='equipments-head'>Please Select The Equipments</div>
                <div className="equipments-scroll">
                  {selectedGroup && groupsAndEquipments[selectedGroup] ? (
                    groupsAndEquipments[selectedGroup].map(equipment => (
                      <div
                        key={equipment.name}
                        className={`equipment ${selectedEquipments.find(item => item.name === equipment.name) ? 'selected' : ''}`}
                        onClick={() => handleEquipmentSelect(equipment)}>
                        {equipment.name}
                      </div>
                    ))
                  ) : (
                    <p>No equipments available for this group.</p>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
        <Col className="selected-equipments px-0">
          <Container>
            <Row>
              {allSelectedEquipments.map(equipment => (
                <Col sm={6} key={equipment.name} className="px-2">
                  <div
                    className="selected-equipment"
                    onClick={() => toggleOptionValue(equipment.name)}
                  >
                    {equipment.name}
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </Col>
      </Row>
      <Row className='justify-content-end'>
        {showInput && (
          <Col sm={4} className='d-flex justify-content-end'>
            <FormControl
              type="text"
              value={extraEquipmentName}
              onChange={handleInputChange}
              placeholder="Enter new equipment"
              className="me-2"
            />
          </Col>
        )}
        <Col sm={2} className='d-flex justify-content-end'>
          <Button className='border-0 btns-orange p-1 btn-change4' onClick={handleAddEquipmentClick}>
            {showInput ? 'Add Equipment' : '+ Add Equipment'}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

// Debounce hook to prevent rapid input changes
function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export default Equipments;
import React, { useEffect, useState } from "react";
import styles from "./DealerCRMLeeds.module.css";
import CRMsheet from "./CRMsheet/CRMsheet";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

const DealerCRMLeeds = () => {
  const tabContentArray = [
    { tabName: "All", tabId: "all" },
    { tabName: "Search", tabId: "search" },
    { tabName: "Loan App", tabId: "loanApp" },
    { tabName: "Email Leeds", tabId: "emailLeeds" },
    { tabName: "Messages", tabId: "messages" },
    { tabName: "Calls", tabId: "calls" },
    { tabName: "Offers", tabId: "offers" },
    { tabName: "Users Report", tabId: "usersReport" },
    { tabName: "Services", tabId: "services" },
    { tabName: "Contact Info", tabId: "contactInfo" },
    { tabName: "Quotation", tabId: "quotation" },
  ];
  const [tabSelected, settabSelected] = useState("loanApp");
  const viewContent = (tabSelected) => {
    switch (tabSelected) {
      case "all":
        return "";

      case "loanApp":
        return <CRMsheet />;
    }
  };
  const location = useLocation();
  useEffect(() => {
    const path = location.pathname.replace("/dealer/crmLeeds/", "");
    const route = tabContentArray.find((tab) => tab.tabId === path);
    if (route) {
      settabSelected(path);
    } else {
      settabSelected("loanApp"); // Default tab if path doesn't match
    }
  }, [location.pathname]);
  const navigate = useNavigate();
  const handleTabChange = (tab) => {
    console.log("Navigating to => ", tab);
    settabSelected(tab.tabId);
    navigate(`/dealer/crmLeeds/${tab.tabId}`);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.tabPanelContainer}>
          {tabContentArray.map((tab, index) => (
            <div
              key={tab.tabId}
              className={styles.tabPanel}
              onClick={() => handleTabChange(tab)}
              style={{
                backgroundColor: tabSelected === tab.tabId ? "#f0f0f0" : "",
                color: tabSelected === tab.tabId ? "#000" : "",
                border: tabSelected === tab.tabId ? "none" : "",
              }}
            >
              {index === 1 ? (
                <span>
                  <input type="text" />
                </span>
              ) : (
                <span> {tab.tabName}</span>
              )}
            </div>
          ))}
        </div>
        <div className={styles.tabPanelContent}>{viewContent(tabSelected)}</div>
      </div>
    </div>
  );
};

export default DealerCRMLeeds;

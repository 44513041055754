import React, { useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import styles from "../Styles/mailAddAcc.module.css";

const DealerChangePassword = () => {

  const [formData, setFormData] = useState({
    oldPassword: '',
    password: '',
    confirmPassword: '',
  });

  const handlePasswordChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Form submitted:', formData);
  };

  return (
    <Container className={`p-4`}>
      <Row>
        <Col sm={12}>
          <h5 className={` ${styles.settingHead}`}>Change Password</h5>
          <p className={` ${styles.subHead}`}>Please change your mail box passwod to secure your mail account.</p>
        </Col>
        <Col sm={8}>
          <form onSubmit={handleSubmit} className={` ${styles.form}`}>
            <div className={` ${styles.password} ${styles.smtp}`}>
              {/* <p>Password</p> */}
              <label>
                Old Password:
                <input
                  type="password"
                  name="oldPassword"
                  placeholder='Old Password'
                  value={formData.oldPassword}
                  onChange={handlePasswordChange}
                />
              </label>

              <label>
                Password:
                <input
                  type="password"
                  name="password"
                  placeholder='Password'
                  value={formData.password}
                  onChange={handlePasswordChange}

                />
              </label>

              <label>
                Confirm Password:
                <input
                  type="password"
                  name="confirmPassword"
                  placeholder='Confirm Password'
                  value={formData.confirmPassword}
                  onChange={handlePasswordChange}

                />
              </label>
            </div>
            <button type="submit" className={` ${styles.saveBtn}`}>Save</button>
          </form>
        </Col>
      </Row>
    </Container>
  )
}

export default DealerChangePassword
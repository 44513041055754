import React from "react";
import Image from "../../imgData";
import "../Styles/Dealer_header.css";
import { Link } from "react-router-dom";

const Dealer_header = ({ header }) => {
  return (
    <div>
      <div className="header_container">
        <div className="logo_container_dealer">
          <Link to="/">
            <span>DEALERS</span>
            <span>AUTO</span>
            <span>CENTER</span>
          </Link>
        </div>
        {/* <div className="header_category">
          <p className="sub_category">{header}</p>
          <p>Dealers</p>
        </div> */}
        <div className="dealer_header_menu">
          <p>
            <span>{/* <IoMdHome /> */}</span>
            <span>Home</span>
          </p>
          <p>
            <span>{/* <MdPhoneInTalk /> */}</span>
            <span>Contact</span>
          </p>
          {/* <p> */}
            {/* <span><BsInfoCircle /></span> */}
            {/* <span>About Us</span> */}
          {/* </p> */}
          <p>
            <span>
              {/* <MdStars /> */}
              </span>
            <span><Link to="/dealer_facilities_home">Solutions</Link></span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Dealer_header;

import React, { useEffect, useRef, useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useLocation,
  useOutletContext,
} from "react-router-dom";
import {
  Col,
  Container,
  Nav,
  Row,
  Button,
  Tab,
  Form,
  Dropdown,
  Modal,
} from "react-bootstrap";
import Autocomplete from "react-google-autocomplete";
import axios from "axios";
import "./vehicle-list.css";
import "./DealerSell.css";
import "./VehicleSaleDetails.css";
import "./forms.css";
import "./Sell.css";
import VehicleList from "./VehicleList";
import Payment from "./Payment";
import Forms from "./Forms";
import TradeIn from "./TradeIn";
import { fetchGeoMapData } from "./geoAPI";
import PricesFees from "./PricesFees";
import { useDispatch, useSelector } from "react-redux";
import {
  setVehicleData,
  setSelectedVehicle,
  setLoading,
  setError,
  setActiveTab,
  setSellData,
  setActiveTabIndex,
  incrementTabIndex,
  decrementTabIndex,
  saveSellData,
  resetCoBuyerSellData,
  resetBuyerSellData,
} from "../../../../Redux/slice/sellSlice";
import BuyerInfo from "./BuyerInfo";
import SaleType from "./SaleType";
import {
  resetTexasDynamicPriceFees,
  setRetailData,
} from "../../../../Redux/slice/taxes&FeesSlice";
import { incrementCounter } from "../../../../Redux/slice/activeSlice";
import { MdDelete } from "react-icons/md";

const apiUrl = process.env.REACT_APP_API_URL;

const SellData = ({
  show,
  handleClose,
  VehicleName,
  carName,
  status = false,
  setShowDeleteModal,
  statusDelete,
  handleModalOpenAdd,
  IsThisAddCost,
  
}) => {
  // console.log("VehicleName", carName);
  const dispatch = useDispatch();
  const location = useLocation();
  const { data: item } = location.state || {};

  const activeTab = useSelector((state) => state.sell.activeTab);
  const vehicleData = useSelector((state) => state.sell.vehicleData);
  const isLoading = useSelector((state) => state.sell.isLoading);
  const selectedVehicle = useSelector((state) => state.sell.selectedVehicle);
  const activeTabIndex = useSelector((state) => state.sell.activeTabIndex);
  const counter = useSelector((state) => state.active.counter);
  const selectedPopOutVehicle = useSelector(
    (state) => state.sell.selectedVehiclePopOut
  );
  const { id } = useSelector((state) => state.user);
  const fetchData = async () => {
    try {
      dispatch(setLoading(true));
      const response = await fetch(`${apiUrl}/listing/get_user_listing/${id}`);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();

      if (response.status === 200) {
        // dispatch(setVehicleData(data));
        // const sortedData = [...data].sort((a, b) => b.id - a.id); // Sort in descending order by id
        dispatch(setVehicleData(data));
      }
      // setVehicleData(data)
    } catch (error) {
      dispatch(setError(error.message));
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    fetchData();
    handleSort();
    console.log("vehicle data setting");
  }, [show]);

  const handleSort = (order = "newest") => {
    if (vehicleData.length > 0) {
      const sorted = [...vehicleData].sort((a, b) => b.id - a.id); // Sort in descending order by id
      // setSortedData(sorted);

      dispatch(setVehicleData(sorted));
      // console.log("sortedData", vehicleData);
    } else {
      console.log("sortedData not set", vehicleData);
    }
  };

  useEffect(() => {
    if (location.state) {
      // dispatch(setActiveTab("Sale Type"));
      // dispatch(setActiveTabIndex(1));
    }
    console.log("Received data:", location.state);
    console.log("item", item);
  }, [location.state]);

  // useEffect(() => {
  //   console.log("Received data:", location.state);
  //   dispatch(setSelectedVehicle(item));
  // }, [location.state]);

  const handleSelectedVehicle = (vehicle) => {
    dispatch(setSelectedVehicle(vehicle));
    handleTabSelect("Sale Type");
  };

  const handleTabSelect = (tab) => {
    dispatch(setActiveTab(tab));
  };

  const tabs = IsThisAddCost
    ? ["Vehicle List"]
    : ["Sale Type", "Buyer", "Prices and Fees", "Forms"];

  useEffect(() => {
    if (activeTab === "Vehicle List") {
      console.log("Calling all the Vehicle present in Vehicle List");
      fetchData();
    }
  }, [activeTab]);

  const handleNext = () => {
    dispatch(incrementTabIndex());
  };

  const handlePrevious = () => {
    dispatch(decrementTabIndex());
  };
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const isExpanded = useOutletContext();
  // console.log("isExpanded => ", isExpanded);
  const closeModel = () => {
    // Compare with the previous listingId
    dispatch(resetTexasDynamicPriceFees());
    dispatch(resetCoBuyerSellData());
    dispatch(resetBuyerSellData());
    dispatch(
      setRetailData({
        salesTaxes: "6.25",
        mInventoryTax: "0.001488",
        titleApplicationFee: 13.0,
        terpTitlefee: 20,
        drivesOutTag: 5.0,
        windshieldTrackerSticker: 50.75,
        regFeeDps: 1,
        cntyRoadBridgeFee: 10,
        childSafetyFund: 1.5,
        InspectionFeeOBDNL: 25,
        StateInspectionFees: 8.25,
        processingHandlingFee: 4.75,
        documentationFee: 150.0,
        technologyFees: 0.0,
        buyerTagFees: 5,
        other1: 0.0,
        other2: 0.0,
        cardFees: 0,
        warranty: 0,
        gapInsurance: 0,
        mailDeliveryFees: 0,
        transportationFees: 0,
        totalFees: 0,
        totalFeesAndothers: 0,
        txtState: "",
        listingPrice: "",
        userListingPrice: 0,
        txtTitleType: null,
        ownedTrade: "",
        cashDifference: "",
        paymentPriceFees: "",
        date: getCurrentDate(),
        saleTaxValue: "",
        mInventoryValue: "",
        balanceDue: "",
        totalDue: 0,
        // -----------------------------------
        lienHolderName: "",
        leanHolderId: "",
        lienHolderID: "",
        lienHolderAddress: "",
        lienHolderAddress2: "",
        serviceContractId: "",
        serviceContractName: "",
        serviceContractLicense: "",
        serviceContractAddress: "",
        serviceProviderActivationStatus: "",
        serviceProviderType: "",
        providerPhoneNumber: "",
        providerCounty: "",
        providerListingExpiry: "",
        priceFeesPaymentMethod: "",
        //Gap Insurance Provider Data
        gapInsuranceId: "",
        gapName: "",
        gapLicense: "",
        gapAddress: "",
        gapActivationStatus: "",
        gapType: "",
        gapPhoneNumber: "",
        gapCounty: "",
        gapListingExpiry: "",
        // Sell Form ---------------------------
        sellForm: "",
        // tax maker forms-----------------------
        taxMakerForms: "",
        // trade in key--------
        dealerId: 0,
        vin: "",
        year_make_model: "",
        // make year modal only check purpose for backend------
        make: "",
        year: "",
        model: "",
        bodyClass: "",
        wheelbase: "",
        driveType: "",
        doorCount: "",
        // -----------------------
        trim: "",
        engine: "",
        transmission: "",
        vehicleType: "",
        seat_count: "",
        city_mpg: "",
        highway_mpg: "",
        inspection: "",
        exteriorColor: "",
        interiorColor: "",
        interiorType: "",
        mileage_in: "",
        purchase_price: "",
        //tradeInValue: formData.tradeInValue,
        listing_price: "",
        mileageIn: "",
        // trade in key end--------
        // for images of vehicle card--------
        imageUrl: "",
      })
    );

    handleClose();
  };

  //fetch labels
  const fetchPriceLabels = async () => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}/sell/get_labels/${id}`
      );
      if (result.status === 200) {
        const data = result.data;
        console.log("All the labels in SellData=> ", data);
        dispatch(
          setRetailData({
            documentFees: data.documentFees || "Line 1",
            driveOutTags: data.driveOutTags || "Line 2",
            titleAppFees: data.titleAppFees || "Line 3",
            terpTitleFees: data.terpTitleFees || "Line 4",
            buyerTag: data.buyerTag || "Line 5",
            windShieldTracker: data.windShieldTracker || "Line 6",
            regFeeDpsl: data.regFeeDpsl || "Line 7", //Special case
            cntyRoadBridge: data.cntyRoadBridge || "Line 8",
            childSafetyFee: data.childSafetyFee || "Line 9",
            inspectionFee: data.inspectionFee || "Line 10",
            stateInspectionFee: data.stateInspectionFee || "Line 11",
            procHandlingFee: data.procHandlingFee || "Line 12",
            cardFeesl: data.cardFeesl || "Line 13",
            serviceContractl: data.serviceContractl || "Line 14",
            gapInsurancel: data.gapInsurancel || "Line 15",
            mailDeliveryFeesl: data.mailDeliveryFeesl || "Line 16",
            transportationFeesl: data.transportationFeesl || "Line 17",
          })
        );
      }
    } catch (error) {
      console.log("error ", error);
    }
  };

  useEffect(() => {
    if (show) {
      fetchPriceLabels();
    }
  }, [dispatch, show]);

  //minimisation of the app starts here
  const [showPopoutCloseConfirm, setShowPopoutCloseConfirm] = useState(false);
  console.log("Are we clikcing outside the popout => ", showPopoutCloseConfirm);

  return (
    <>
      <Modal
        show={showPopoutCloseConfirm}
        onHide={() => {
          handleClose();
          setShowPopoutCloseConfirm(false);
        }}    
        closeButton
        
        
      >
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you want to save the data?</Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Modal
        show={show}
        size="xl"
        onHide={handleClose}  
        // onHide={() => setShowPopoutCloseConfirm(true)}
        closeButton
        dialogClassName="modal-90w"
        backdropClassName="sellBackDrop"
        style={{ visibility: show ? "visible" : "hidden" }}
        onBackdropClick={() => console.log("Clicking outside the popout")}
        backdrop="static" 
      >
        <Modal.Header closeButton>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              paddingRight: "2%",
              paddingTop: "5px",
            }}
          >
            <div>
              {status ? (
                <Modal.Title>
                  {VehicleName?.addListing2?.year_Make_Model}{" "}
                  {VehicleName?.addListing2?.trim} {carName}
                </Modal.Title>
              ) : (
                <Modal.Title>
                  {VehicleName?.year_Make_Model} {VehicleName?.trim} {carName}
                </Modal.Title>
              )}
            </div>
            <div>
              {statusDelete === 1 && (
                <div
                  className={` edit ml-3 rounded-circle bg-light`}
                  onClick={() => setShowDeleteModal(true)}
                  style={{ cursor: "pointer", Title: "Delete" }}
                >
                  <MdDelete />
                </div>
              )}
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div style={{ width: "100%" }}>
            <Container
              fluid
              style={{
                width: "100%",
                paddingRight: "0rem",
                paddingLeft: "3rem",
              }}
            >
              <Row style={{ width: "100%" }}>
                <Tab.Container activeKey={activeTab}>
                  <span
                    id="btn-buyer-payment"
                    className=" px-0"
                    style={{ width: "98%" }}
                  >
                    <Nav fill variant="tabs" className="justify-content-center">
                      {tabs.map((tab, index) => (
                        <Nav.Item key={index}>
                          <Nav.Link
                            eventKey={tab}
                            className={
                              activeTab === tab ? "active px-0" : "px-0"
                            }
                            onClick={() => handleTabSelect(tab)}
                          >
                            {tab}
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                    </Nav>
                  </span>

                  <Row style={{ width: "100%" }}>
                    <div className="px-0" style={{ width: "100%" }}>
                      <Tab.Content
                        style={{ minHeight: "110% !importent", padding: "0px" }}
                      >
                        {IsThisAddCost ? (
                          <Tab.Pane eventKey="Vehicle List">
                            <VehicleList
                              vehicleData={vehicleData}
                              isLoading={isLoading}
                              handleTabSelect={handleTabSelect}
                              handleSelectedVehicle={handleSelectedVehicle}
                              handleClose={handleClose}
                              handleModalOpenAdd={handleModalOpenAdd}
                            />
                          </Tab.Pane>
                        ) : (
                          <>
                            <Tab.Pane eventKey="Sale Type">
                              <SaleType handleNext={handleNext} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="Buyer">
                              <BuyerInfo
                                selectedVehicle={selectedVehicle}
                                data={item}
                                status={status}
                              />
                            </Tab.Pane>
                            <Tab.Pane eventKey="Prices and Fees">
                              <PricesFees
                                selectedVehicle={
                                  status
                                    ? selectedPopOutVehicle
                                    : selectedVehicle
                                }
                                status={status}
                              />
                            </Tab.Pane>
                            <Tab.Pane eventKey="Forms">
                              <Forms status={status} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="Payment">
                              <Payment />
                            </Tab.Pane>
                          </>
                        )}
                      </Tab.Content>
                    </div>
                  </Row>
                </Tab.Container>
              </Row>

              {/* <Row className="mt-3">
          <Col className="d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-secondary p-0 border-0 bg-transparent text-dark"
              onClick={handlePrevious}
              style={{ width: "10%", height: "30%" }}
              disabled={activeTabIndex === 0}
            >
              <img src={Image.back_btn} width="90%" />
            </button>
            <button
              type="button"
              className="btn btn-secondary p-0 border-0 bg-transparent text-dark"
              onClick={handleNext}
              style={{ width: "10%", height: "30%" }}
              disabled={activeTabIndex === tabs.length - 1}
            >
              <img src={Image.next_btn} width="90%" />
            </button>
          </Col>
        </Row> */}
            </Container>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default SellData;

import React, { useState } from 'react'
import styles from "./sold.module.css";
import PDF from "../../DealerSell/components/PDF.jsx";
import { useSelector } from 'react-redux';
import {
  Button,
  Col,
  Container,
  Row,
  Table,
  OverlayTrigger,
  Tooltip,
  Modal,
  Form,
} from "react-bootstrap";

import SellDatab from "../activeInventory/Selldatab.jsx";
import Image from '../../../imgData/index.js';
const imgUrl = process.env.REACT_APP_IMG_URL;
const SoldListPreview = ({show,
    handleClose,
    data,}) => {
        //Preview Modal
  const userData = useSelector((state) => state.user);
  const [buyerCobuyerTab, setBuyerCobuyerTab] = useState("Buyer");
  const { id } = useSelector((state) => state.user);
  const pdfArray = [
    `title_issuance_${data?.addListing2?.vin?.slice(-8)}${userData.id}.pdf`,
    `tax_exemption_${data?.addListing2?.vin?.slice(-8)}${userData.id}.pdf`,
    `limited_attorney_${data?.addListing2?.vin?.slice(-8)}${userData.id}.pdf`,
    `buyers_guide_${data?.addListing2?.vin?.slice(-8)}${userData.id}.pdf`,
    `rebuilt_disclosure_${data?.addListing2?.vin?.slice(-8)}${userData.id}.pdf`,
    `title_registration_${data?.addListing2?.vin?.slice(-8)}${userData.id}.pdf`,
    `billOfSale_${data?.addListing2?.vin?.slice(-8)}${userData.id}.pdf`,
    `odometer_statement_${data?.addListing2?.vin?.slice(-8)}${userData.id}.pdf`,
    `salesContract_${data?.addListing2?.vin?.slice(-8)}${userData.id}.pdf`,
  ];
  console.log("pdfArray =>", data);

  //Checkboxes for preview pdfs
  const [checkboxes, setCheckboxes] = useState({
    billOfSale: false,
    buyerGuide: false,
    odometerDisclosure: false,
    titleApp: false,
    AgreementInsurance: false,
    creditBureauDisclosure: false,
    rebuildTitleDisclosure: false,
    powerOfAttorney: false,
    retailContractInstallment: false,
    salesTaxExemptionCertificate: false,
  });

  const PDFTOSHOWArray = [
    {
      pdf: pdfArray && pdfArray[6],
      isShow: checkboxes && checkboxes.billOfSale,
    },
    {
      pdf: pdfArray && pdfArray[5],
      isShow: checkboxes && checkboxes.titleApp,
    },
    {
      pdf: pdfArray && pdfArray[1],
      isShow: checkboxes && checkboxes.salesTaxExemptionCertificate,
    },
    {
      pdf: pdfArray && pdfArray[2],
      isShow: checkboxes && checkboxes.powerOfAttorney,
    },
    {
      pdf: pdfArray && pdfArray[3],
      isShow: checkboxes && checkboxes.buyerGuide,
    },
    {
      pdf: pdfArray && pdfArray[4],
      isShow: checkboxes && checkboxes.rebuildTitleDisclosure,
    },

    {
      pdf: pdfArray && pdfArray[1],
      isShow: checkboxes && checkboxes.salesTaxExemptionCertificate,
    },
    {
      pdf: pdfArray && pdfArray[2],
      isShow: checkboxes && checkboxes.powerOfAttorney,
    },
    {
      pdf: pdfArray && pdfArray[3],
      isShow: checkboxes && checkboxes.buyerGuide,
    },
    {
      pdf: pdfArray && pdfArray[4],
      isShow: checkboxes && checkboxes.rebuildTitleDisclosure,
    },
    {
      pdf: pdfArray && pdfArray[5],
      isShow: checkboxes && checkboxes.titleApp,
    },
    {
      pdf: pdfArray && pdfArray[0],
      isShow: checkboxes && checkboxes.AgreementInsurance,
    },
    {
      pdf: pdfArray && pdfArray[7],
      isShow: checkboxes && checkboxes.odometerDisclosure,
    },
    {
      pdf: pdfArray && pdfArray[8],
      isShow: checkboxes && checkboxes.retailContractInstallment,
    },
  ];

  const AllCheckBoxesFromAllForms = [
    {
      id: "AgreementInsurance",
      label: "Agreement To Provide Insurance",
    },
    { id: "billOfSale", label: "Bill of Sale /Buyer Order" },
    { id: "buyerGuide", label: "Buyer Guide" },
    {
      id: "creditBureauDisclosure",
      label: "Credit Bureau Disclosure",
    },
    { id: "odometerDisclosure", label: "Odometer Disclosure" },
    {
      id: "rebuildTitleDisclosure",
      label: "Rebuilt Title Disclosure",
    },
    { id: "titleApp", label: `Title App I130u` },
    {
      id: "powerOfAttorney",
      label: ` Power Of Attorney`,
    },
    {
      id: "retailContractInstallment",
      label: `Retail Contract Installment`,
    },
    {
      id: "salesTaxExemptionCertificate",
      label: ` Sales Tax Exemption Certificate`,
    },
  ];
  const [showPdfs, setShowPdfs] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setCheckboxes((prevData) => ({
      ...prevData,
      billOfSale: !selectAll,
      buyerGuide: !selectAll,
      odometerDisclosure: !selectAll,
      titleApp: !selectAll,
      AgreementInsurance: !selectAll,
      creditBureauDisclosure: !selectAll,
      rebuildTitleDisclosure: !selectAll,
      powerOfAttorney: !selectAll,
      retailContractInstallment: !selectAll,
      salesTaxExemptionCertificate: !selectAll,
      saleContract: !selectAll,
    }));
  };
  const [showDeleteOptions, setShowDeleteOptions] = useState(false);
  const [selectedDeleteOption, setSelectedDeleteOption] = useState("");
  const [selectedDeletePurpose, setSelectedDeletePurpose] = useState("");

  // console.log("selectedDeletePurpose  ", selectedDeletePurpose);
  // const {
  //   all_images,
  //   exterior_images,
  //   misc_images,
  //   interior_images,
  //   three_sixty_images,
  // } = data?.addListing2 && data?.addListing2?.multimedia;
   const noImage = Image.noImage;

  // const getFirstValidImage = (images) => {
  //   if (images) {
  //     const imageList = images.split(",").map((img) => img.trim());
  //     for (let image of imageList) {
  //       if (image) {
  //         return `${imgUrl}/${id}/${image.trim()}`;
  //       }
  //     }
  //   }
  //   return null;
  // };

  const imageUrl =noImage;
    // (all_images && getFirstValidImage(all_images)) ||
    // (exterior_images && getFirstValidImage(exterior_images)) ||
    // (misc_images && getFirstValidImage(misc_images)) ||
    // (interior_images && getFirstValidImage(interior_images)) ||
    // (three_sixty_images && getFirstValidImage(three_sixty_images)) ||
    // noImage;


  const handleDeleteCheck = (e) => {
    const { value } = e.target;
    setSelectedDeleteOption(value);
    if (value === "deleteOption1") {
      setSelectedDeletePurpose("delete");
    } else {
      setSelectedDeletePurpose("active");
    }
  };
  //sell/toggle_status/delete/sellid/userid
  //sell/active/sellid/userid
  console.log("data of the current product in sold inventory => ", data);
  const handleCheckboxChange = (e, manualId = null) => {
    const id = manualId || e.target.id;
    const checked = manualId ? !checkboxes[id] : e.target.checked;

    setCheckboxes((prev) => ({
      ...prev,
      [id]: checked,
    }));
  };
  return (
    <Modal
                 show={show} size="xl" onHide={handleClose}  dialogClassName="modal-90w"
                  aria-labelledby="example-custom-modal-styling-title"
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                      Sold car details summary
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                  <Container fluid>
                    <Row>
                      <Col sm={12}>
                        <Row className="">
                          <Col sm={9} className="ps-0">
                            {/* <div className={styles.tabCon}>
                              <Button onClick={() => setBuyerCobuyerTab('Buyer')} className={`py-1 px-4 ${styles.btn} ${buyerCobuyerTab === 'Buyer' ? styles.active : ''}`}>Buyer</Button>
                              <Button onClick={() => setBuyerCobuyerTab('Co-Buyer')} className={`py-1 ${styles.btn} ${buyerCobuyerTab === 'Co-Buyer' ? styles.active : ''}`}>Co-Buyer</Button>
                            </div> */}

                            <div className={styles.tabCon}>
                              <label
                                className={` ${
                                  buyerCobuyerTab === "Buyer"
                                    ? styles.active
                                    : ""
                                }`}
                              >
                                <input
                                  type="radio"
                                  name="buyerCobuyer"
                                  value="Buyer"
                                  checked={buyerCobuyerTab === "Buyer"}
                                  onChange={() => setBuyerCobuyerTab("Buyer")}
                                  className="" // Hide the default radio button
                                />
                                Buyer
                              </label>
                              <label
                                className={` ${
                                  buyerCobuyerTab === "Co-Buyer"
                                    ? styles.active
                                    : ""
                                }`}
                              >
                                <input
                                  type="radio"
                                  name="buyerCobuyer"
                                  value="Co-Buyer"
                                  checked={buyerCobuyerTab === "Co-Buyer"}
                                  onChange={() =>
                                    setBuyerCobuyerTab("Co-Buyer")
                                  }
                                  className="" // Hide the default radio button
                                />
                                Co-Buyer
                              </label>
                            </div>

                            {buyerCobuyerTab === "Buyer" ? (
                              <>
                                <div className={styles.soldSubContainer}>
                                  <p>
                                    Name : {data.firstName || " NA"}{" "}
                                    {data.middleName || ""}{" "}
                                    {data.lastName || ""}
                                  </p>
                                  <p>Phone No. : {data.phoneNumber || " NA"}</p>
                                  <p>Email address : {data.email || " NA"}</p>
                                  <p>ID Type : {data.idType || " NA"}</p>
                                  <p>ID State : {data.idState || " NA"}</p>
                                  <p>ID Number : {data.idNumber || " NA"}</p>
                                  <p>
                                    Address : {data.address || " NA"}{" "}
                                    {data.address2
                                      ? ` Apt# : ${data.address2}`
                                      : ""}
                                  </p>
                                  <p>County : {data.county || " NA"}</p>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className={styles.soldSubContainer}>
                                  <p>
                                    Name : {data.sellCoBuyer.firstName || " NA"}{" "}
                                    {data.sellCoBuyer.middleName || ""}{" "}
                                    {data.sellCoBuyer.lastName || ""}
                                  </p>
                                  <p>
                                    Phone No. :{" "}
                                    {data.sellCoBuyer.phoneNumber || " NA"}
                                  </p>
                                  <p>
                                    Email address :{" "}
                                    {data.sellCoBuyer.email || " NA"}
                                  </p>
                                  <p>
                                    ID Type : {data.sellCoBuyer.idType || " NA"}
                                  </p>
                                  <p>
                                    ID State :{" "}
                                    {data.sellCoBuyer.idState || " NA"}
                                  </p>
                                  <p>
                                    ID Number :{" "}
                                    {data.sellCoBuyer.idNumber || " NA"}
                                  </p>
                                  <p>
                                    Address :{" "}
                                    {data.sellCoBuyer.address || " NA"}{" "}
                                    {data.sellCoBuyer.address2
                                      ? ` Apt# : ${data.sellCoBuyer.address2}`
                                      : ""}
                                  </p>
                                  <p>
                                    County : {data.sellCoBuyer.county || " NA"}
                                  </p>
                                </div>
                              </>
                            )}

                            <Row
                              className={`d-flex ${styles.soldSummaryContainer}`}
                            >
                              <Col sm={8} className={styles.feeses}>
                                <p className={styles.soldSubHeader}>
                                  <span>Price and Fees</span>
                                </p>
                                <div
                                  className={styles.soldSubContainer}
                                  style={{
                                    display: "grid",
                                    gridTemplateColumns: "repeat(2, 1fr)",
                                  }}
                                >
                                  {/* <p>VIN Number : {data.vin || " NA"} </p> */}
                                  {data?.sellPriceFees?.sellPriceFeeItems?.map((item) => (
                          <p key={item.id}>
                            {item.itemName}:{" "}
                            {item.itemValue
                              ? `$${item.itemValue}`
                              : "Not Available"}
                          </p>
                        ))}
                                  {/* <p>
                                    Documentation Fees :
                                    {data?.sellPriceFees?.documentationFee
                                      ? ` $${data?.sellPriceFees?.documentationFee}`
                                      : " NA"}
                                  </p>
                                  <p>
                                    Wind-Shield Sticker :
                                    {data?.sellPriceFees
                                      ?.windshieldTrackerSticker
                                      ? ` $${data?.sellPriceFees?.windshieldTrackerSticker}`
                                      : " NA"}
                                  </p>
                                  <p>
                                    Title App Fee :
                                    {data?.sellPriceFees?.titleApplicationFee
                                      ? ` $${data?.sellPriceFees?.titleApplicationFee}`
                                      : " NA"}
                                  </p>
                                  <p>
                                    Drive Out Tag :
                                    {data?.sellPriceFees?.drivesOutTag
                                      ? ` $${data?.sellPriceFees?.drivesOutTag}`
                                      : " NA"}
                                  </p>
                                  <p>
                                    TERP Title Fee :
                                    {data?.sellPriceFees?.terpTitlefee
                                      ? ` $${data?.sellPriceFees?.terpTitlefee}`
                                      : " NA"}
                                  </p>
                                  <p>
                                    Buyer Tag :
                                    {data?.sellPriceFees?.buyerTagFees
                                      ? ` $${data?.sellPriceFees?.buyerTagFees}`
                                      : " NA"}
                                  </p>
                                  <p>
                                    REG. FEE-DPS :
                                    {data?.sellPriceFees?.regFeeDps
                                      ? ` $${data?.sellPriceFees?.regFeeDps}`
                                      : " NA"}
                                  </p>
                                  <p>
                                    CNTY ROAD Bridge Add on Fee :
                                    {data?.sellPriceFees?.cntyRoadBridgeFee
                                      ? ` $${data?.sellPriceFees?.cntyRoadBridgeFee}`
                                      : " NA"}
                                  </p>
                                  <p>
                                    Child Safety Fee :
                                    {data?.sellPriceFees?.childSafetyFund
                                      ? ` $${data?.sellPriceFees?.childSafetyFund}`
                                      : " NA"}
                                  </p>
                                  <p>
                                    Inspection Fee :
                                    {data?.sellPriceFees?.inspectionFeeOBDNL
                                      ? ` $${data?.sellPriceFees?.inspectionFeeOBDNL}`
                                      : " NA"}
                                  </p>
                                  <p>
                                    Process Handling Fee :{" "}
                                    {data?.sellPriceFees?.processingHandlingFee
                                      ? ` $${data?.sellPriceFees?.processingHandlingFee}`
                                      : " NA"}
                                  </p>
                                  <p>
                                    Card Fees :{" "}
                                    {data?.sellPriceFees?.cardFees
                                      ? ` $${data?.sellPriceFees?.cardFees}`
                                      : " NA"}
                                  </p>
                                  <p>
                                    Service Contract :{" "}
                                    {data?.sellPriceFees?.warranty
                                      ? ` $${data?.sellPriceFees?.warranty}`
                                      : " NA"}
                                  </p>
                                  <p>
                                    Gap Insurance :
                                    {data?.sellPriceFees?.gapInsurance
                                      ? ` $${data?.sellPriceFees?.gapInsurance}`
                                      : " NA"}
                                  </p>
                                  <p>
                                    Mail Delivery Fees :{" "}
                                    {data?.sellPriceFees?.mailDeliveryFees
                                      ? ` $${data?.sellPriceFees?.mailDeliveryFees}`
                                      : " NA"}
                                  </p>
                                  <p>
                                    Transportation Fees :{" "}
                                    {data?.sellPriceFees?.transportationFees
                                      ? ` $${data?.sellPriceFees?.transportationFees}`
                                      : " NA"}
                                  </p> */}
                                </div>
                              </Col>
                              <Col className={styles.taxes}>
                                <p className={styles.soldSubHeader}>
                                  <span>All Tax & Calculation</span>
                                </p>
                                <div
                                  className={styles.soldSubContainer}
                                  style={{
                                    display: "grid",
                                    gridTemplateColumns: "repeat(1, 1fr)",
                                  }}
                                >
                                  <p>
                                    Mileage On Sale :
                                    {data?.sellPriceFees?.mileageIn
                                      ? ` $${data?.sellPriceFees?.mileageIn}`
                                      : " NA"}
                                  </p>

                                  <p>
                                    Sale Price :{" "}
                                    {data?.sellPriceFees?.listingPrice
                                      ? ` $${data?.sellPriceFees?.listingPrice}`
                                      : " NA"}
                                  </p>
                                  <p>
                                    Trade In :{" "}
                                    {data?.addListing?.purchase_price
                                      ? ` $${data?.addListing?.purchase_price}`
                                      : " NA"}
                                  </p>
                                  <p>
                                    Cash Difference :{" "}
                                    {data?.sellPriceFees?.cashDifference
                                      ? ` $${data?.sellPriceFees?.cashDifference}`
                                      : " NA"}
                                  </p>
                                  <p>
                                    Sales Taxes Fees :
                                    {data?.sellPriceFees?.salesTaxes
                                      ? ` ${data?.sellPriceFees?.salesTaxes} %`
                                      : " NA"}
                                  </p>
                                  <p>
                                    Inventory Tax :
                                    {data?.sellPriceFees?.mInventoryTax
                                      ? ` ${data?.sellPriceFees?.mInventoryTax} %`
                                      : " NA"}
                                  </p>
                                  <p>
                                    Total Taxes :
                                    {data?.sellPriceFees?.listingPrice &&
                                    data?.sellPriceFees?.mInventoryTax
                                      ? ` $${(
                                          parseFloat(
                                            data.sellPriceFees.listingPrice
                                          ) +
                                          parseFloat(
                                            data.sellPriceFees.mInventoryTax
                                          )
                                        ).toFixed(2)}`
                                      : " NA"}
                                  </p>
                                  <p>
                                    Total Fees And Others :
                                    {data?.sellPriceFees?.totalFeesAndother
                                      ? ` $${data?.sellPriceFees?.totalFeesAndother}`
                                      : " NA"}
                                  </p>
                                  <p>
                                    TOTAL DUE :
                                    {data?.sellPriceFees?.totalDue
                                      ? ` $${data?.sellPriceFees?.totalDue}`
                                      : " NA"}
                                  </p>
                                  <p>
                                    Payment Method :
                                    {data?.sellPriceFees?.priceFeesPaymentMethod
                                      ? ` $${data?.sellPriceFees?.priceFeesPaymentMethod}`
                                      : " NA"}
                                  </p>
                                  <p>
                                    Sales Taxes Fees :
                                    {data?.sellPriceFees?.totalFees
                                      ? ` $${data?.sellPriceFees?.totalFees}`
                                      : " NA"}
                                  </p>
                                  <p>
                                    Payment :
                                    {data?.sellPriceFees?.paymentPriceFees
                                      ? ` $${data?.sellPriceFees?.paymentPriceFees}`
                                      : " NA"}
                                  </p>
                                  <p>
                                    Balance Due :
                                    {data?.sellPriceFees?.balanceDue
                                      ? ` $${data?.sellPriceFees?.balanceDue}`
                                      : " NA"}
                                  </p>
                                </div>
                              </Col>
                            </Row>
                          </Col>

                          <Col
                            sm={3}
                            className="pe-0"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "",
                              gap: ".5rem",
                            }}
                          >
                            <div className={`${styles.imageCon} `}>
                              <img src={imageUrl} alt="" />
                            </div>

                            <div className={`${styles.formCon} `}>
                              <p
                                className={styles.soldSubHeader}
                                style={{
                                  textAlign: "center",
                                  padding: ".5rem .5rem 0 ",
                                }}
                              >
                                <span>Forms Printed</span>
                                <br />
                                <label
                                  htmlFor="selectAll"
                                  style={{ marginTop: "1rem" }}
                                >
                                  <input
                                    type="checkbox"
                                    onChange={handleSelectAll}
                                    id="selectAll"
                                    value={selectAll}
                                  />{" "}
                                  {selectAll ? "DeSelect All" : "Select All"}
                                </label>
                              </p>
                              {/* checkboxes here */}
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  padding: ".5rem",
                                }}
                              >
                                <div>
                                  {" "}
                                  {AllCheckBoxesFromAllForms.map(
                                    ({ id, label }) => (
                                      <Form.Group
                                        key={id}
                                        className="mb-2 flex align-items-center justify-content-center"
                                      >
                                        <Form.Check
                                          type="checkbox"
                                          id={id}
                                          checked={checkboxes[id]}
                                          onChange={handleCheckboxChange}
                                          label={
                                            <span
                                              onClick={(e) => {
                                                e.preventDefault();
                                                handleCheckboxChange(null, id);
                                              }}
                                              style={{ cursor: "pointer" }}
                                            >
                                              {label}
                                            </span>
                                          }
                                          className="form-option border-bottom py-0"
                                          style={{
                                            display: "flex",
                                            justifyContent: "start",
                                            alignItems: "center",
                                            gap: "1rem",
                                            paddingLeft: "30px",
                                          }}
                                        />
                                      </Form.Group>
                                    )
                                  )}
                                </div>
                                <div
                                  style={{
                                    // border: "1px solid black",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <button
                                    onClick={() => setShowPdfs(true)}
                                    className="bg-orange border-none color-white "
                                    style={{
                                      border: "none",
                                      color: "white",
                                      fontWeight: "500",
                                      padding: "0.5rem 1rem",
                                      borderRadius: "3px",
                                      cursor: Object.values(checkboxes).every(
                                        (item) => item === false
                                      )
                                        ? "not-allowed"
                                        : "pointer",
                                      color: Object.values(checkboxes).every(
                                        (item) => item === false
                                      )
                                        ? "grey"
                                        : "white",
                                      backgroundColor: Object.values(
                                        checkboxes
                                      ).every((item) => item === false)
                                        ? "#F2D2BD"
                                        : "orange",
                                    }}
                                    disabled={Object.values(checkboxes).every(
                                      (item) => item === false
                                    )}
                                  >
                                    Preview
                                  </button>
                                </div>

                                {/* Modal for pdf here */}
                                <Modal
                                  show={showPdfs}
                                  onHide={() => setShowPdfs(false)}
                                  size="xl"
                                  dialogClassName="modal-90w"
                                >
                                  <Modal.Header closeButton>
                                    <Modal.Title>
                                      Documents Printed while finalising the
                                      deal
                                    </Modal.Title>
                                  </Modal.Header>
                                  {PDFTOSHOWArray.some(
                                    (item) => item.isShow
                                  ) && (
                                    <Modal.Body className="h-100 ">
                                      <PDF
                                        pdfArray={PDFTOSHOWArray.filter(
                                          (item) => item.isShow
                                        )}
                                      />
                                    </Modal.Body>
                                  )}
                                </Modal>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Container>
                  </Modal.Body>
                </Modal>
  )
}

export default SoldListPreview
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { PDFDocument } from "pdf-lib";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const PDFViewer = ({ pdfArray, isCallFunc, setisCallFunc }) => {
  const [mergedPdfUrl, setMergedPdfUrl] = useState(null);

  console.log("Merged PDF URL", mergedPdfUrl);

  const userID = useSelector((state) => state.user);
  const selectedCar = useSelector((state) => state.sell.selectedVehicle);

  console.log("selectedCar => ", selectedCar);
  //method to merge pdfs
  const mergePDFs = async (pdfArray) => {
    try {
      const mergedPdf = await PDFDocument.create();
      for (const item of pdfArray) {
        const existingPdfBytes = await fetch(
          `${process.env.REACT_APP_DOCS_URL}/${userID.dealerId}/${item.pdf}`
        ).then((res) => res.arrayBuffer());
        const pdf = await PDFDocument.load(existingPdfBytes);
        const copiedPages = await mergedPdf.copyPages(
          pdf,
          pdf.getPageIndices()
        );
        copiedPages.forEach((page) => mergedPdf.addPage(page));
      }

      const mergedPdfBytes = await mergedPdf.save();
      console.log(
        ` mergedPDF ${selectedCar.year_Make_Model} ${
          selectedCar.trim
        } ${selectedCar?.vin.slice(-8)}${userID.id}`
      );
      return URL.createObjectURL(
        new Blob([mergedPdfBytes], { type: "application/pdf" })
      );
    } catch (error) {
      toast.error(error.message);
    }
  };

  // const mergePDFs = async (pdfArray) => {
  //   try {
  //     const mergedPdf = await PDFDocument.create();  // Create a new merged PDF document

  //     for (const item of pdfArray) {
  //       const existingPdfBytes = await fetch(
  //         `${process.env.REACT_APP_DOCS_URL}/${userID.dealerId}/${item.pdf}`
  //       ).then((res) => {
  //         if (!res.ok) throw new Error(`HTTP error! status: ${res.status}`);
  //         return res.arrayBuffer();
  //       });

  //       const pdf = await PDFDocument.load(existingPdfBytes);  // Load the source PDF

  //       // Copy all pages from the current PDF into the merged PDF
  //       const pages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());

  //       // Add the copied pages to the merged PDF
  //       pages.forEach(page => mergedPdf.addPage(page));
  //     }

  //     // Save the merged PDF
  //     const mergedPdfBytes = await mergedPdf.save();

  //     console.log(
  //       `Merged PDF: ${selectedCar.year_Make_Model} ${selectedCar.trim} ${selectedCar?.vin.slice(-8)}${userID.id}`
  //     );

  //     // Return the merged PDF as a Blob URL
  //     return URL.createObjectURL(
  //       new Blob([mergedPdfBytes], { type: "application/pdf" })
  //     );
  //   } catch (error) {
  //     console.error('Merge PDFs Error:', error);
  //     toast.error(error.message);
  //     return null;
  //   }
  // };

  //     const mergedPdfBytes = await mergedPdf.save();
  //     return URL.createObjectURL(
  //       new Blob([mergedPdfBytes], { type: "application/pdf" })
  //     );
  //   } catch (error) {
  //     console.error("PDF Merge Error:", error);
  //     toast.error(error.message);
  //     return null;
  //   }
  // };
  useEffect(() => {
    const mergeAndSetPdf = async () => {
      const url = await mergePDFs(pdfArray);
      setMergedPdfUrl(url);
    };

    mergeAndSetPdf();
  }, []);
  const downloadMergedPDF = () => {
    if (mergedPdfUrl) {
      const link = document.createElement("a");
      console.log("selectedCar => ", selectedCar);
      link.href = mergedPdfUrl;
      link.download = `${selectedCar?.year_Make_Model} ${
        selectedCar?.trim
      } ${selectedCar?.vin.slice(-8)}${userID.id}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      toast.error("PDF is not ready for download yet.");
    }
    setTimeout(() => {
      navigate("/dealer/inventory/sold", { state: true });
    }, 1500); // Delay of 2000 milliseconds (2 seconds)
    console.log("Download Successfully.........");
  };
  // useEffect(() => {
  //   if (isCallFunc) {
  //     downloadMergedPDF();
  //     setisCallFunc(false);
  //   }
  // }, [isCallFunc]);
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (isCallFunc) {
  //     downloadMergedPDF();
  //   }
  // }, [isCallFunc]);
  useEffect(() => {
    if (isCallFunc && mergedPdfUrl) {
      downloadMergedPDF(() => {
        navigate("/dealer/inventory/sold", { state: true });
      });
      setisCallFunc(false);
      // Clean up the timer if the component unmounts
      // return () => {
      //      navigate("/dealer/inventory/sold", { state: true });
      // };
    }
  }, [isCallFunc, navigate, mergedPdfUrl]);

  if (!mergedPdfUrl) {
    return <div>Loading...</div>;
  }
  // for pdf zoom size----------------------------------------------
  return (
    <div style={{ height: "85vh", marginBottom: "1.5rem" }}>
      {/* <button onClick={downloadMergedPDF}>Download All PDFS</button> */}
      <iframe
        src={mergedPdfUrl + "#zoom=110"}
        // src={`${mergedPdfUrl}#view=fit&pagemode=none&zoom=150`}
        width="100%"
        height="100%"
        type="application/pdf"
        title="Merged PDF File"
      />
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          // Define default options
          className: "",
          duration: 1000,
          style: {
            background: "#363636",
            color: "#fff",
            fontSize: "larger",
          },
        }}
      />
    </div>
  );
};

export default PDFViewer;

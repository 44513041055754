import React, { useEffect, useState } from "react";
import Image from "../../imgData";
import "../Styles/Dealer_SetPass_Signup.css";
import Socialchild from "../../UserAuthDialogue/components/Socialchild";
import { BiHide } from "react-icons/bi";
import { GoEye } from "react-icons/go";
import Fingerprint2 from "fingerprintjs2";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { saveUser } from "../../../Redux/slice/userSlice";
import { UserCity } from "../../UserAuthDialogue/Pages/UserCity";
import toast from "react-hot-toast";

const Dealer_User_SetPass = () => {
  const [showPass, setShowPass] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [InputPass, setInputPass] = useState("");
  const [InputPassError, setInputPassError] = useState(null);

  const [InputConfirmPass, setInputConfirmPass] = useState("");
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [InputConfirmPassError, setInputConfirmPassError] = useState("");

  const [passwordMatch, setPasswordMatch] = useState(true);
  const [fingerprint, setFingerprint] = useState("");
  const [ip, setIP] = useState("");

  useEffect(() => {}, [InputPass, InputConfirmPass]);
  useEffect(() => {
    const getDeviceFingerprint = () => {
      return new Promise((resolve, reject) => {
        Fingerprint2.get((components) => {
          const values = components.map((component) => component.value);
          const fingerprintId = Fingerprint2.x64hash128(values.join(""), 31);
          resolve(fingerprintId);
        });
      });
    };

    const fetchFingerprint = async () => {
      try {
        const fingerprintId = await getDeviceFingerprint();
        setFingerprint(fingerprintId);
      } catch (error) {
        console.error("Error getting fingerprint:", error);
      }
    };

    // Wait for fingerprint to be generated before rendering the component
    const timeout = setTimeout(() => {
      fetchFingerprint();
    }, 1000); // Adjust timeout value as needed

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}$/;

  const handleChangeView = () => {
    setShowPass(!showPass);
  };
  const handleChangeView2 = () => {
    setShowConfirmPass(!showConfirmPass);
  };

  const handleInputChange = (e) => {
    setInputPass(e.target.value);

    if (!passwordRegex.test(e.target.value)) {
      setInputPassError(
        "Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, and one special character."
      );
    } else {
      setInputPassError(null);
    }

    if (InputConfirmPass && e.target.value !== InputConfirmPass) {
      setPasswordMatch(false);
    } else {
      setPasswordMatch(true);
    }
  };

  const handleInputChange2 = (e) => {
    setInputConfirmPass(e.target.value);
    if (!passwordRegex.test(e.target.value)) {
      setInputConfirmPassError(
        "Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, and one special character."
      );
    } else {
      setInputConfirmPassError(null);
    }

    if (InputPass && e.target.value !== InputPass) {
      setPasswordMatch(false);
    } else {
      setPasswordMatch(true);
    }
  };

  useEffect(() => {
    if (
      passwordMatch &&
      !InputPassError &&
      !InputConfirmPassError &&
      InputPass &&
      InputConfirmPass
    ) {
      PassMatchAPI();
    }
  }, [
    passwordMatch,
    InputPass,
    InputConfirmPass,
    InputConfirmPassError,
    InputPassError,
  ]);

  const getUserIPaddress = async () => {
    try {
      const res = await axios.get("https://api.ipify.org/?format=json");
      console.log("User IP Address", res);
      setIP(res.data.ip);
    } catch (error) {
      console.log("Error while getting ip", error);
    }
  };
  useEffect(() => {
    getUserIPaddress();
  }, [ip]);

  const [countryCode, setCountryCode] = useState("");
  console.log(countryCode);
  useEffect(() => {
    const getUserCountryCode = async () => {
      const res = await axios.get("https://ipapi.co/json");
      console.log("User all info", res.data);
      setCountryCode(res.data.country_calling_code);
    };
    getUserCountryCode();
  }, [countryCode]);

  const location = useLocation();

  //get the token from string url
  const [token, setToken] = useState("");
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const paramToken = queryParams.get("token");

    if (paramToken) {
      console.log("Yes the token is present ", paramToken);
      setToken(paramToken);
    } else {
      console.log("NO the token is NOT present");
    }
  }, []);
  const PassMatchAPI = async () => {
    console.log("Calling the API...");
    const formData = new FormData();
    try {
      formData.append("password", InputPass);
      formData.append("confirm_password", InputConfirmPass);
      formData.append("fingerprint", fingerprint);
      formData.append("token", token);

      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/set-password`,
        formData
      );
      console.log("Response after saving new user", res);
      if (res.status === 200) {
        navigate("/dealer/login");
        toast.success("Dealer registration successful");
      }

      console.log(res);
    } catch (error) {
      console.log("Error in catch part", error);
      toast.error(error.response.data);
    }
  };

  return (
    <div className="login_feature_wrapper">
      <div className="login_feature_container">
        <p
          className="welcome_message"
          style={{ fontWeight: "700", fontSize: "xx-large" }}
        >
          Dealer Log-in
        </p>

        <div className="input_and_icon">
          <input
            type={!showPass ? "password" : "text"}
            onChange={handleInputChange}
            value={InputPass}
            id="new_password"
            placeholder="Enter Password"
            style={{ fontWeight: "700", fontSize: "xx-large" }}
          />
          <img
            src={!showPass ? Image.eye_icon : Image.hide_icon}
            alt="hide/unhide_icon"
            height={20}
            onClick={handleChangeView}
          />
        </div>
        <p className="error_pass_set">{InputPassError}</p>

        <div className="input_and_icon" style={{ marginTop: "4rem" }}>
          <input
            type={!showConfirmPass ? "password" : "text"}
            onChange={handleInputChange2}
            value={InputConfirmPass}
            placeholder="Confirm Password"
            id="confirm_password"
            style={{ fontWeight: "700", fontSize: "xx-large" }}
          />
          <img
            src={!showConfirmPass ? Image.eye_icon : Image.hide_icon}
            alt="hide/unhide_icon"
            height={20}
            onClick={handleChangeView2}
          />
        </div>
        <p className="error_pass_set">{InputConfirmPassError}</p>
        {!passwordMatch && (
          <p style={{ color: "red" }}>Passwords do not match.</p>
        )}
      </div>
    </div>
  );
};

export default Dealer_User_SetPass;

import React from "react";
import { Modal } from "react-bootstrap";
import styles from "./CrmEmailPopout.module.css";
import Image from "../../../imgData";
import { FaInfo } from "react-icons/fa6";
import { IoMdArrowRoundForward } from "react-icons/io";

const CrmEmailPopOut = ({ showEmailPopOut, setShowEmailPopOut, rowData }) => {
  console.log("rowData => ", rowData);
  return (
    <Modal
      show={showEmailPopOut}
      onHide={() => setShowEmailPopOut(false)}
      dialogClassName="modal-90w"
    >
      <Modal.Header closeButton>
        <div className={styles.popOutHeader}>
          Date Submitted : {rowData?.date} @ 14:52:25
        </div>
      </Modal.Header>

      <Modal.Body>
        <div className={styles.popOutBody}>
          <div className={styles.popOutBodyLeft}>
            <div className={styles.personalDetails}>
              <div className={styles.details}>
                <span>First Name</span>
                <input type="text" />
              </div>

              <div className={styles.details}>
                <span>M. Name</span>
                <input type="text" />
              </div>
              <div className={styles.details}>
                <span>Last Name</span>
                <input type="text" />
              </div>
              <div className={styles.details}>
                <span>Phone Number</span>
                <input type="text" />
              </div>
              <div className={styles.details}>
                <span>Email</span>
                <input type="text" />
              </div>
            </div>
            <div className={styles.assetInfoDetailsWrapper}>
              <span>Asset Info</span>
              <div className={styles.assetInfoDetailsContainer}>
                <p className={styles.assetInfo1}>
                  <img src={Image.car_image} alt="car image" />
                </p>
                <p className={styles.assetInfo2}>
                  <span>Year Make Model</span>
                  <span>2020 Toyota Camry Fortuner Kaali Raakhi</span>
                </p>
                <p className={styles.assetInfo3}>
                  <span>VIN</span>
                  <span>789456123012345678</span>
                </p>
                <p className={styles.assetInfo4}>
                  <span>Price</span>
                  <span>78909</span>
                </p>
              </div>
            </div>
            <p className={styles.downloadAppBtnContainer}>
              <button className={styles.downloadAppBtn}>
                Download Loan App
              </button>
            </p>
          </div>
          <div className={styles.popOutBodyRight}>
            <div className={styles.messageContainer}>
              <div className={styles.messageheader}>
                <span>Messages</span>
                <span>
                  <FaInfo />
                </span>
              </div>
              <div className={styles.messageBody}>
                <div className={styles.leftContainer}>
                  <div className={styles.message}>
                    <span>Micheal</span>
                    <span>I am interested to test drive this car.</span>
                  </div>
                </div>
                <div className={styles.rightContainer}>
                  <div className={styles.message}>
                    <span>Jackson</span>
                    <span>No,I am interested to test drive this car.</span>
                  </div>
                </div>
              </div>
              <div className={styles.messageInputContainer}>
                <input type="text" placeholder="Enter your Message" />
                <span>
                  <IoMdArrowRoundForward />
                </span>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default CrmEmailPopOut;

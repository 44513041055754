import React, { useEffect, useState } from "react";
import "./Styles/DealerDashBoard.css";
import DealerDashBoardModal from "../DealerDashBoardModal/DealerDashBoardModal";
import "./DealerDashBoard.css";
import BarChart from "./DealerHorizontalBarChart1";
import DealerVerticalChart2 from "./DealerVerticalChart2";
import DealerDoughNutChart3 from "./DealerDoughNutChart3";
import NavigationCircles4 from "./NavigationCircles4";
import QuarterRevenueChart5 from "./QuarterRevenueChart5";
import DiscountSection from "./DiscountSection";
import ProgressGraphs from "./Components/ProgressGraphs";
import LastWeekSalesComparison from "./Components/LastWeekSalesComparison";
import NavigationCircle from "./Components/NavigationCircle";
import NavigationCircles42 from "./NavigationCircles42";
import WarningCenter from "./WarningCenter";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import DealerMarquee from "./DealerMarquee";
import { saveUser } from "../../../Redux/slice/userSlice";
import { useDispatch } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";

const DealerDashBoard = () => {
  const [showNavigationModal, setShowNavigationModal] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    if (showNavigationModal) {
      dispatch(saveUser({ isSideBarOpen: false }));
    }
  }, [showNavigationModal]);
  //   for drop down------------------------------------
  const [openDropdowns, setOpenDropdowns] = useState({});

  // Toggle function for individual dropdown buttons
  const handleToggle = (index) => {
    setOpenDropdowns(prevState => ({
      ...prevState,
      [index]: !prevState[index], // Toggle the specific dropdown by index
    }));
  };
  const dropdownData = [
    {
      buttonTitle: "Sales Reports",
      menuItems: [
        "All Sold Today",
        "All Sold This Week",
        "All Sold This Months",
        "All Sold year to Date",
        "Sales By Users",
        "Sales By Make",
        "Sales Summery By Type (Retail, Finance)",
      ],
    },
    {
      buttonTitle: "Purchase Reports",
      menuItems: [
        "All Purchase Today",
        "All Purchase This Week",
        "All Purchase This Months",
        "All Purchase year to Date",
        "Sales By Users",
        "Sales By Make",
        "Sales Summery By Type (Retail, Finance)",
      ],
    },
    {
      buttonTitle: "Collected Taxes And Fees",
      menuItems: [
        "All Sold Today",
        "All Sold This Week",
        "All Sold This Months",
        "All Sold year to Date",
        "Sales By Users",
        "Sales By Make",
        "Sales Summery By Type (Retail, Finance)",
      ],
    },
    {
      buttonTitle: "Vehicles Inspection Report",
      menuItems: [
        "All Sold Today",
        "All Sold This Week",
        "All Sold This Months",
        "All Sold year to Date",
        "Sales By Users",
        "Sales By Make",
        "Sales Summery By Type (Retail, Finance)",
      ],
    },
    {
      buttonTitle: "User Report",
      menuItems: [
        "All Sold Today",
        "All Sold This Week",
        "All Sold This Months",
        "All Sold year to Date",
        "Sales By Users",
        "Sales By Make",
        "Sales Summery By Type (Retail, Finance)",
      ],
    },
    {
      buttonTitle: "All Inventory",
      menuItems: [
        "All Sold Today",
        "All Sold This Week",
        "All Sold This Months",
        "All Sold year to Date",
        "Sales By Users",
        "Sales By Make",
        "Sales Summery By Type (Retail, Finance)",
      ],
    },
    {
      buttonTitle: "All Leads Reports",
      menuItems: [
        "All Sold Today",
        "All Sold This Week",
        "All Sold This Months",
        "All Sold year to Date",
        "Sales By Users",
        "Sales By Make",
        "Sales Summery By Type (Retail, Finance)",
      ],
    },
    {
      buttonTitle:
        "Titles Status (Available Out Of Total) Received Not Received",
      menuItems: [
        "All Sold Today",
        "All Sold This Week",
        "All Sold This Months",
        "All Sold year to Date",
        "Sales By Users",
        "Sales By Make",
        "Sales Summery By Type (Retail, Finance)",
      ],
    },
    {
      buttonTitle: "All Active Balance",
      menuItems: [
        "All Sold Today",
        "All Sold This Week",
        "All Sold This Months",
        "All Sold year to Date",
        "Sales By Users",
        "Sales By Make",
        "Sales Summery By Type (Retail, Finance)",
      ],
    },
    {
      buttonTitle: "Advertised Reports",
      menuItems: [
        "All Sold Today",
        "All Sold This Week",
        "All Sold This Months",
        "All Sold year to Date",
        "Sales By Users",
        "Sales By Make",
        "Sales Summery By Type (Retail, Finance)",
      ],
    },
    {
      buttonTitle: "Add Cost Reports",
      menuItems: [
        "All Sold Today",
        "All Sold This Week",
        "All Sold This Months",
        "All Sold year to Date",
        "Sales By Users",
        "Sales By Make",
        "Sales Summery By Type (Retail, Finance)",
      ],
    },
    {
      buttonTitle: "Recall Reports",
      menuItems: [
        "All Sold Today",
        "All Sold This Week",
        "All Sold This Months",
        "All Sold year to Date",
        "Sales By Users",
        "Sales By Make",
        "Sales Summery By Type (Retail, Finance)",
      ],
    },
    {
      buttonTitle: "Customer Reports",
      menuItems: [
        "All Sold Today",
        "All Sold This Week",
        "All Sold This Months",
        "All Sold year to Date",
        "Sales By Users",
        "Sales By Make",
        "Sales Summery By Type (Retail, Finance)",
      ],
    },
  ];

  return (
    <div className="dealerDashBoard_Wrapper">
      <div className="dealerDashBoard_Container" style={{ width: "100%" }}>
        {showNavigationModal ? (
          <DealerDashBoardModal
            setShowNavigationModal={setShowNavigationModal}
          />
        ) : null}

        {/* <div className="chart_first_row">
          <BarChart />
          <DealerVerticalChart2 />
          <DealerDoughNutChart3 />
        </div> */}
        {/* <div className="chart_second_row">
          <div className="navigation_circles_wrapper">
            <NavigationCircles4 />
          </div>
          <div className="quartely_revenue_wrapper">
            <QuarterRevenueChart5 />
          </div>
        </div> */}
        {/* <div className="chart_third_row">
          <div className="chart_third_row_a">
            <div className="discount_wrapper">
              <DiscountSection />
            </div>
            <div className="progress_wrapper">
              <ProgressGraphs text="Reviews" progress="250" color="#3d74d7" />
              <ProgressGraphs text="Ratings" progress="180" color="#3d74d7" />
              <ProgressGraphs text="Signups" progress="80" color="lightgreen" />
            </div>
          </div>
          <div className="chart_third_row_b">
            <LastWeekSalesComparison
              numbers="70"
              category="Products"
              categoryProgressStatus={true}
              progressNumbers="7.2"
            />
            <LastWeekSalesComparison
              numbers="650"
              category="Orders"
              categoryProgressStatus={true}
              progressNumbers="7.5"
            />
            <LastWeekSalesComparison
              numbers="995"
              category="Revenue"
              categoryProgressStatus={false}
              progressNumbers="5.7"
            />
          </div>
        </div> */}
        {/* <div className="chart_fourth_row">
          <NavigationCircles42 />
        </div>
        <div className="chart_fourth_row">
          <NavigationCircles42 />
        </div> */}
        {/* <div>
          <DealerMarquee />
        </div> */}
        <div>
          <div>
            {/* Create 13 Dropdowns */}
            <div>
              {dropdownData.map((dropdown, index) => (
                <div
                  className="dropdown"
                  key={index}
                  style={{
                    position: "relative",
                    marginBottom: "10px",
                    width: "90%",
                  }}
                >
                  {/* Circle Number - Positioned Outside the Button */}
                  <span
                    className="circle-number"
                    style={{
                      position: "absolute",
                      left: "-50px", // Move the circle further left, outside the dropdown button
                      top: "50%",
                      transform: "translateY(-50%)",
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                      backgroundColor: "#E3E3E3",
                      color: "black",
                      boxShadow: "3px 2px 6px 0px rgba(0, 0, 0, 0.6)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    {index + 1}
                  </span>

                  {/* Button with Dynamic Title from Array */}
                  <button
                    className="btn btn-secondary dropdown-toggle-dash"
                    type="button"
                    id={`dropdownMenuButton${index}`}
                    onClick={() => handleToggle(index)}
                    aria-expanded={openDropdowns[index] ? 'true' : 'false'}
                    data-bs-toggle="dropdown"
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "10px",
                      color: "black",
                      alignItems: "center",
                      height: "4rem",
                      fontFamily: "inherit",
                      fontSize: "25px",
                      paddingLeft: "40px", // Add padding to the left of the button to make space for the circle
                    }}
                  >
                    <span style={{ display: "flex", gap: "10px" }}>
                      <span
                        style={{
                          display: "inline-block",
                          width: "20px",
                          height: "20px", // Square shape
                          top: "20px",
                          backgroundColor: "#d1823a", // Background color
                          borderRadius: "5px", // Optional rounded corners
                        }}
                      />
                      {/* Custom Dropdown Title from Array */}
                      {dropdown.buttonTitle}
                      {/* Arrow Icon */}
                    </span>
                    <span>
              {openDropdowns[index] ? (
                <IoIosArrowUp style={{ fill: "#d1823a" }} />
              ) : (
                <IoIosArrowDown style={{ fill: "#d1823a" }} />
              )}
            </span>
                  </button>

                  {/* Dropdown Menu with Dynamic Items from Array */}
                  <ul
                    className="dropdown-menu drpdwn-menu"
                    aria-labelledby={`dropdownMenuButton${index}`}
                    style={{ width: "100%", padding: "40px" }}
                  >
                    {dropdown.menuItems.map((item, itemIndex) => (
                      <li
                        key={itemIndex}
                        style={{
                          borderBottom: "1px solid grey",
                          display: "flex",
                          alignItems: "center",
                          padding: "5px 0",
                        }}
                      >
                        <span
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                            backgroundColor: "#EFD4BB",
                            color: "black",
                            textAlign: "center",
                            lineHeight: "30px",
                            marginRight: "10px",
                            fontSize: "16px",
                          }}
                        >
                          {itemIndex + 1}-{itemIndex + 1}
                        </span>
                        <a
                          className="dropdown-item"
                          href="#"
                          style={{ fontFamily: "inherit", fontSize: "25px" }}
                        >
                          {item}
                        </a>
                        <span style={{ paddingRight: "2%" }}>0.00</span>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* <div className="chart_fifth_row">
          <WarningCenter />
        </div> */}
      </div>
    </div>
  );
};

export default DealerDashBoard;

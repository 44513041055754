import React from "react"
import Styles from "../Styles/MailNotesSummaryCard.module.css"
import { FaRegEdit } from "react-icons/fa"
import { GiTargetPoster } from "react-icons/gi"
import { Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap"
import { MdDelete } from "react-icons/md"

const MailNotesSummaryCard = ({ note }) => {
  return (
    <Container fluid className={Styles.cardWrapper}>
      <Row className={Styles.cardContainer}>
        <Col className={`px-1 ${Styles.dateContainer}`}>
          <span>{note && note.date}</span>
          <span>{note && note.year}</span>
        </Col>
        <Col className={` px-1 ${Styles.subjectNote}`}>{note && note.subject}</Col>
        <Col className={`px-1 ${Styles.NoteReaders}`}>
          {note && note.readers.map((reader) => (
            <span>{reader}</span>
          ))}
        </Col>
        <Col className={`px-1 ${Styles.NoteFeatures}`}>
          <p style={{ backgroundColor: note && note.bgColor }}></p>
          <OverlayTrigger
            placement="bottom-start"
            overlay={
              <Tooltip id={`tooltip-edit`} className="custom-tooltip">
                Edit
              </Tooltip>
            }>
            <span><FaRegEdit /></span>
          </OverlayTrigger>
          <OverlayTrigger
            placement="bottom-start"
            overlay={
              <Tooltip id={`tooltip-delete`} className="custom-tooltip">
                Delete
              </Tooltip>
            }>
            <span><MdDelete /></span>
          </OverlayTrigger>

        </Col>
      </Row>
    </Container>
  )
}

export default MailNotesSummaryCard

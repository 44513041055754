import React from "react";
import Styles from "./Hero3.module.css";
import Image from "../../../../imgData";

const Hero3 = () => {
  return (
    <div className={Styles.wrapper}>
      <div
        className={Styles.container}
        style={{ backgroundImage: `url(${Image.web3_car_home_image}` }}
      >
        <div className={Styles.textContainer}>
          <p>Welcome to United Motors</p>
          <p>Let us help you find your dream Vehicle</p>
          <p>
            <span>Contact Us</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Hero3;

import React, { useState } from "react";
import Styles from "../Styles/MessageProfielContainer.module.css";
import Image from "../../../imgData";
import { IoMdEye } from "react-icons/io";
import { MdOutlineReport } from "react-icons/md";
const MessageProfileContainer = ({ isExpanded }) => {
  const [showProfileDialog, setshowProfileDialog] = useState(false);
  return (
    <div className={`border-end ${Styles.profileWrapper}`}>
      <div className={Styles.profileContainer}>
        <div className={Styles.imageNameContainer}>
          <img
            src={Image.avatar_img}
            alt="avatar"
            className={`border ${isExpanded ? "" : Styles.imageExpanded}`}
          />
          <p
            onClick={() => setshowProfileDialog(!showProfileDialog)}
            className={Styles.profileName}
          >
            Tim Johnson
          </p>
          {showProfileDialog && (
            <div className={Styles.profileDialogContainer}>
              <p>
                <span>
                  <IoMdEye />
                </span>
                <span>View Profile</span>
              </p>
              <p>
                <span>
                  <MdOutlineReport />
                </span>
                <span>Report</span>
              </p>
            </div>
          )}
        </div>
        <div className={Styles.aboutCarContainer}>
          <div className={Styles.carProfile}>
            <img src={Image.car_image} alt="car" />
            <div className={Styles.carDetails}>
              <div className={Styles.carName}>2019 NISSAN SEDAN</div>
              <div className={Styles.carPerformance}>
                <p>14 Interactions</p>
                <p>102 Views</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageProfileContainer;

import "../Styles/Dealer_login_feature.css";
import React, { useEffect, useState } from "react";
import "../Styles/Dealer_Input_Login_feature.css";
import axios from "axios";
import Image from "../../imgData";
import Fingerprint2 from "fingerprintjs2";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { sideBarArray } from "../../dashboard/SideBarData";
import {
  saveUser,
  setShowUserCompleteProfileDialogueBox,
  updateInitialDataFromAPI,
} from "../../../Redux/slice/userSlice";
import { UserCity } from "../../UserAuthDialogue/Pages/UserCity";
import toast from "react-hot-toast";

const Dealer_Device_Verify_feature = () => {
  const location = useLocation();
  console.log("location.state in Dealer_Device_Verify", location.state);

  const dispatch = useDispatch();
  // console.log("Extra Information in Device Verify OTP", extraInformation);
  // console.log("isLoginEmail in Device Verify OTP", isLoginEmail);
  const navigate = useNavigate();
  const [InputOtp, setInputOtp] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [ip, setIP] = useState("");

  const [disableResendOtp, setdisableResendOtp] = useState(false);
  console.log(errorMessage);
  console.log(InputOtp);
  // useEffect for IP Address of user while
  const getUserIPaddress = async () => {
    try {
      const res = await axios.get("https://api.ipify.org/?format=json");
      console.log("User IP Address", res);
      setIP(res.data.ip);
    } catch (error) {
      console.log("Error while getting ip ", error);
    }
  };
  useEffect(() => {
    getUserIPaddress();
  }, []);

  const handleContinueResult = async (value) => {
    try {
      console.log(
        "Handling API continue Result while sending the otp to verify device"
      );
      const formData = new FormData();
      console.log("formData", formData);
      formData.append("email", location.state.email);
      formData.append("password", location.state.password);
      formData.append("fingerprint", location.state.fingerprint);
      formData.append("otp", value);
      formData.append("ip", ip);
      formData.append("user_type", "Dealer");

      console.log("OTP while verifying the device", value);
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/verify_otp`,
        formData
      );

      if (res.status !== 200) {
        console.log("User OTP Failure");
      }
      dispatch(
        saveUser({
          email: res.data.email,
          id: res.data.id,
          dealerId: res.data.dealerId,
          phoneNumber: res.data.phoneNumber,
          firstName: res.data.firstName,
          lastName: res.data.lastName,
          office_number: res.data.office_number,
          lattitude: res.data.lattitude,
          longitude: res.data.longitude,
          zip_code: res.data.zip_code,
          address: res.data.address,
          profile_picture: res.data.profile_picture,
          website: res.data.website,
          roles: res.data.roles,
          loginAccess: res.data.loginAccess,
          dealerApproval: res.data.dealerApproval,
          dealerData: res.data.dealerData,
        })
      );
      navigate("/dealer/dashboard");
      fetchSideBarData(res.data.id);
      // setShowLoginDialogueBox(false);
      console.log("response when verifying the device", res);
      // dispatch(saveUser({ email: res.data.email, id: res.data.id }));
      // navigate("/myprofile/dashboard");
      toast.success("Device verification successful");
    } catch (error) {
      console.log("error in  catch part while using otp");
      toast.error(error.response.data);
    }
  };

  // Update side bar user activity after login
  const fetchSideBarData = async (user_id) => {
    const fd = new FormData();
    fd.append("user_id", user_id);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/tab/get`,
        fd
      );
      console.log(res);
      if (res.status === 200) {
        const transformedData = sideBarArray.map((item) => {
          const apiItem = res.data.find((apiItem) => apiItem.tabId === item.id);
          return {
            ...item,
            count: apiItem ? apiItem.tabCount : item.count,
          };
        });

        // Dispatch action to update the initial state with transformed data
        dispatch(updateInitialDataFromAPI(transformedData));
        localStorage.setItem(
          "user_sidebar_activity",
          JSON.stringify(transformedData)
        );
      }
    } catch (error) {
      console.log(
        "Server side error during getting user activity in my profile",
        error
      );
    }
  };

  const handleInputChange = async (e) => {
    const { value } = e.target;
    if (/^\d{0,7}$/.test(value)) {
      setInputOtp(value);

      if (value.length === 6) {
        await handleContinueResult(value);
      }
    }
  };
  const [time, setTime] = useState(() => {
    const savedTime = localStorage.getItem("otpTimer");
    return savedTime ? parseInt(savedTime, 10) : 300;
  });

  const [isDisabled, setIsDisabled] = useState(() => {
    return localStorage.getItem("otpButtonDisabled") === "true";
  });

  useEffect(() => {
    let timer;

    if (time > 0 && isDisabled) {
      timer = setInterval(() => {
        setTime((prevTime) => {
          const newTime = prevTime - 1;
          localStorage.setItem("otpTimer", newTime.toString());
          return newTime;
        });
      }, 1000);
    } else if (time <= 0) {
      setIsDisabled(false);
      localStorage.removeItem("otpTimer");
      localStorage.setItem("otpButtonDisabled", "false");
      clearInterval(timer);
    }

    return () => clearInterval(timer); // Cleanup on component unmount
  }, [time, isDisabled]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(
      2,
      "0"
    )}`;
  };
  const handleResendOtp = async () => {
    if (!isDisabled) {
      setIsDisabled(true);
      setTime(300); // Reset to 5 minutes
    }
    console.log("Please resend OTP");
    const formData = new FormData();
    formData.append("email", location.state.email);
    formData.append("password", location.state.password);
    formData.append("fingerprint", location.state.fingerprint);
    formData.append("user_type", "Dealer");
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/verify_password`,
        formData
      );

      if (res.status === 200) {
        console.log(res.data);
        console.log("I got new OTP");
        setTimeout(() => {
          setdisableResendOtp(true);
        }, 5000);
      }
    } catch (error) {
      console.log("Error in catch part", error);
    }
  };

  useEffect(() => {
    // Reset timer and enable button when route changes
    return () => {
      setTime(300);
      setIsDisabled(false);
      localStorage.removeItem("otpTimer");
      localStorage.setItem("otpButtonDisabled", "false");
    };
  }, [location.pathname]);
  return (
    <div className="login_feature_wrapper">
      <div className="login_feature_container">
        <p className="welcome_message">Dealer Log-in</p>
        {/* <p className="sign_in_message">
          Please enter OTP to verify your identity
        </p> */}

        <div className="input_and_icon">
          <input
            type="text"
            onChange={handleInputChange}
            value={InputOtp}
            placeholder="Enter 6 Digit OTP"
          />
        </div>
        {errorMessage ? <p className="error_message">{errorMessage}</p> : null}

        <p className="forget_password">
          <span
            style={{
              color: "white",
              borderBottom: "0",
              fontSize: "large",

              paddingBottom: "0",
            }}
          >
            {isDisabled ? formatTime(time) : ""}
          </span>
          <button
            className={`dealer_forget_pass_btn ${
              isDisabled ? "greyColor" : "whiteColor"
            }`}
            onClick={handleResendOtp}
            style={{
              cursor: "pointer",
              cursor: isDisabled ? "not-allowed" : "pointer",
              marginLeft: "0.5em",
              color: isDisabled ? "#B2BEB5" : "white",
              backgroundColor: "#515558",
              fontSize: "smaller",

              paddingBottom: "0px",
              width: "20%",
            }}
            disabled={isDisabled}
          >
            Resend OTP
          </button>
        </p>
      </div>
    </div>
  );
};

export default Dealer_Device_Verify_feature;

import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import Quote from "../Quote/Quote";
import styles from "./DealerFast.module.css";
import TaxCertificate from "../TaxCertificate/TaxCertificate";
import Drafting from "../Drafting/Drafting";
import { Link, useLocation, useNavigate } from "react-router-dom";

const DealerFastQuote = () => {
  // for tabs-----------------------------
  const [activeTab, setActiveTab] = useState("");
  const navigate = useNavigate();
  const handleNextButtonClick = () => {
    const tabKeys = ["tab1", "tab2", "tab3", "tab4", "tab5", "tab6", "tab7"];
    const currentIndex = tabKeys.indexOf(activeTab);

    if (currentIndex < tabKeys.length - 1) {
      const nextTab = tabKeys[currentIndex + 1];
      setActiveTab(nextTab);
    }
  };

  const handleBackButtonClick = () => {
    const tabKeys = ["tab1", "tab2", "tab3", "tab4", "tab5", "tab6", "tab7"];
    const currentIndex = tabKeys.indexOf(activeTab);

    if (currentIndex > 0) {
      const prevTab = tabKeys[currentIndex - 1];
      setActiveTab(prevTab);
    }
  };
  const location = useLocation();
  const currentTab = location.pathname.replace("/", "");
  console.log("currentTab =>  ", currentTab);
  useEffect(() => {
    switch (currentTab) {
      case "dealer/fast/quote/fast-quote":
        setActiveTab("tab1");
        break;
      case "dealer/fast/quote/tax-certificate":
        setActiveTab("tab2");
        break;
      case "dealer/fast/quote/draft":
        setActiveTab("tab3");
        break;
      case "dealer/fast/quote/forms":
        setActiveTab("tab4");
        break;
      default:
        setActiveTab("tab1");
    }
  }, [currentTab]);

  const switchRoute = (tab) => {
    switch (tab) {
      case "tab1":
        navigate("/dealer/fast/quote/fast-quote");
        break;
      case "tab2":
        navigate("/dealer/fast/quote/tax-certificate");
        break;
      case "tab3":
        navigate("/dealer/fast/quote/draft");
        break;
      case "tab4":
        navigate("/dealer/fast/quote/forms");
        break;
      default:
        navigate("/dealer/fast/quote/fast-quote");
    }
  };

  return (
    <div className={styles.fastParent}>
      <Tabs
        activeKey={activeTab}
        onSelect={(k) => {
          switchRoute(k);
          setActiveTab(k);
        }}
        id="my-tabs"
      >
        <Tab eventKey="tab1" title="Fast Quote">
          <Quote />
        </Tab>
        <Tab
          eventKey="tab2"
          title="Tax Certificate 01-339"
          onSelect={() => navigate("/dealer/fast/quote/tax-certificate")}
        >
          <TaxCertificate />
        </Tab>
        <Tab eventKey="tab3" title="Drafting">
          <Drafting />
        </Tab>
        <Tab eventKey="tab4" title="Forms">
          Lien Holders Section
        </Tab>
      </Tabs>
    </div>
  );
};

export default DealerFastQuote;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux"; // Added useDispatch
import Modal from "react-bootstrap/Modal";
import "./Preview.css";

const apiUrl = process.env.REACT_APP_API_URL;

const Preview = ({ show, handleClose, formData }) => {
    const [showModalpopup, setShowModalpopup] = useState(false);
    const [selectedVehicle, setSelectedVehicle] = useState(null); // State for selected vehicle
    const { id } = useSelector((state) => state.user);
    const [vehicles, setVehicles] = useState([]); // State to store fetched data
    const [currentPage, setCurrentPage] = useState(1); // State for current page
    const [itemsPerPage] = useState(5); // Number of items per page, adjust as needed
    const dispatch = useDispatch(); // Using useDispatch to get the dispatch function

    // popup for inventoryDropdown.jsx------
    // Function to handle opening the InventoryDropdown modal for a specific vehicle
    const handleLinkClickpopup = (vehicle) => {
        setSelectedVehicle(vehicle);
    };

    // Function to handle closing the InventoryDropdown modal
    const handleModalClosepopup = () => {
        setSelectedVehicle(null);
    };


    // Calculate the index range of items for the current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = vehicles.slice(indexOfFirstItem, indexOfLastItem); // Slice data for current page

    const handleNext = () => {
        setCurrentPage((prevPage) => prevPage + 1); // Increment page number
    };

    const handlePrevious = () => {
        setCurrentPage((prevPage) => prevPage - 1); // Decrement page number
    };
    // for equipment-----------------------*********************************************----------------------------------------
    const [itemsToShow, setItemsToShow] = useState(5);

    const renderOptions = (options) => {
        const result = [];
        for (const category in options) {
            if (category !== 'extraVehicleOptions' && typeof options[category] === 'object') {
                for (const feature in options[category]) {
                    if (options[category][feature] === true) {
                        result.push(formatFeatureName(feature));
                    }
                }
            }
        }
        return result;
    };

    const renderExtraOptions = (extraOptions) => {
        return extraOptions.map(option => {
            if (option.option_value === true) {
                return formatFeatureName(option.option_name);
            }
            return null;
        }).filter(option => option !== null);
    };

    const formatFeatureName = (name) => {
        return name.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
    };

    const handleShowMore = () => {
        setItemsToShow(itemsToShow + 5);
    };
    const vehicleOptions = formData.vehicleOptions || {};
    const extraVehicleOptions = vehicleOptions.extraVehicleOptions || [];
    // Combine options and extra options into a single array
    const allOptions = [
        ...renderOptions(vehicleOptions),
        ...renderExtraOptions(extraVehicleOptions)
    ];

    // Function to chunk array into columns
    const chunkArray = (array, chunkSize) => {
        const chunkedArray = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            chunkedArray.push(array.slice(i, i + chunkSize));
        }
        return chunkedArray;
    };

    // Chunk allOptions into two columns
    const chunkedOptions = chunkArray(allOptions.slice(0, itemsToShow), 2);

    // equipmwnt ends-----------------------********************************************----------------------------------------
    // for  repair-cost/maintanance---------------
    const repairCostData = formData.repair_cost || [];

    const renderRepairCostTable = () => {
        return (
            <table className="table">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Category</th>
                        <th>Payee / Vendor</th>
                        <th>Amount</th>
                        <th>Payment Method</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    {repairCostData.map((item, index) => (
                        <tr key={index}>
                            <td>{item.date}</td>
                            <td>{item.category}</td>
                            <td>{item.payee_vendor}</td>
                            <td>{item.amount}</td>
                            <td>{item.payment_method}</td>
                            <td>{item.description}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };
    // end repair cost/maintanance-------------------------
    // for advertising manager-------------------------------------------
    // Mapping of platform keys to display names
    const platformDisplayNames = {
        dacmp: 'Dealers Auto Center Market Price',
        insta: 'Instagram'
    };
    const renderAdvertising = (advertising) => {
        const result = [];
        for (const key in advertising) {
            if (key.endsWith('_status') && advertising[key] === true) {
                const intervalKey = key.replace('_status', '_interval');
                const platformKey = key.replace('_status', '');
                const platformDisplayName = platformDisplayNames[platformKey] || formatFeatureName(platformKey);
                result.push({
                    platform: platformDisplayName,
                    interval: advertising[intervalKey] || 'Not specified'
                });
            }
        }
        return result;
    };

    const advertisingData = renderAdvertising(formData.addvertising || {});
    // advertising manager end------------------------------------
    return (
        <Modal show={show} size="xl" onHide={handleClose}>
            <Modal.Header closeButton>
                <div>
                    <Modal.Title>Preview Data</Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="card-preview-container">
                    <h5 style={{ color: "#d1823a" }}>Vehicle Specification:-</h5>
                    <div className="vehicle-specs">
                        <div className="spec-item">
                            <p><span style={{ fontSize: "15px", fontWeight: "bold" }}>VIN:</span></p>
                            <p>{formData.vin}</p>
                        </div>
                        <div className="spec-item">
                            <p><span style={{ fontSize: "15px", fontWeight: "bold" }}>Trim:</span></p>
                            <p>{formData.trim}</p>
                        </div>
                        <div className="spec-item">
                            <p><span style={{ fontSize: "15px", fontWeight: "bold" }}>Engine:</span></p>
                            <p>{formData.engine}</p>
                        </div>
                    </div>
                    <div className="vehicle-specs">
                        <div className="spec-item">
                            <p><span style={{ fontSize: "15px", fontWeight: "bold" }}>Year:</span></p>
                            <p>{formData.year_make_model}</p>
                        </div>
                        <div className="spec-item">
                            <p><span style={{ fontSize: "15px", fontWeight: "bold" }}>Vehicle Type:</span></p>
                            <p>{formData.vehicle_type}</p>
                        </div>
                        <div className="spec-item">
                            <p><span style={{ fontSize: "15px", fontWeight: "bold" }}>Seats:</span></p>
                            <p>{formData.seat_count}</p>
                        </div>
                    </div>
                    <div className="vehicle-specs">
                        <div className="spec-item">
                            <p><span style={{ fontSize: "15px", fontWeight: "bold" }}>City MPG:</span></p>
                            <p>{formData.city_mpg}</p>
                        </div>
                        <div className="spec-item">
                            <p><span style={{ fontSize: "15px", fontWeight: "bold" }}>Highway MPG:</span></p>
                            <p>{formData.highway_mpg}</p>
                        </div>
                        <div className="spec-item">
                            <p><span style={{ fontSize: "15px", fontWeight: "bold" }}>Mileage In:</span></p>
                            <p>{formData.mileage_in}</p>
                        </div>
                    </div>
                    <div className="vehicle-specs">
                        <div className="spec-item">
                            <p><span style={{ fontSize: "15px", fontWeight: "bold" }}>Purchased Price:</span></p>
                            <p>{formData.purchase_price}</p>
                        </div>
                        <div className="spec-item">
                            <p><span style={{ fontSize: "15px", fontWeight: "bold" }}>Listing Price:</span></p>
                            <p>{formData.listing_price}</p>
                        </div>
                        <div className="spec-item">
                            <p><span style={{ fontSize: "15px", fontWeight: "bold" }}>Purchased Date:</span></p>
                            <p>{formData.purchasedDate}</p>
                        </div>
                    </div>
                    <div className="vehicle-specs">
                        <div className="spec-item">
                            <p><span style={{ fontSize: "15px", fontWeight: "bold" }}>Exterior Color:</span></p>
                            <p>{formData.exterior_color}</p>
                        </div>
                        <div className="spec-item">
                            <p><span style={{ fontSize: "15px", fontWeight: "bold" }}>Interior Color:</span></p>
                            <p>{formData.interior_color}</p>
                        </div>
                        <div className="spec-item">
                            <p><span style={{ fontSize: "15px", fontWeight: "bold" }}>Interior Type:</span></p>
                            <p>{formData.interior_type}</p>
                        </div>
                    </div>
                    <div><p><span style={{ fontSize: "15px", fontWeight: "bold" }}>Other Restrain:</span></p>
                        <p>{formData.other_restraint}</p>
                    </div>
                    {/*---------------- equipments------------ */}
                    <div className="row">
                        <div className="col-md-12">
                            <h5><span style={{ color: "#d1823a" }}>Equipment Features:-</span></h5>
                        </div>
                        {chunkedOptions.map((chunk, index) => (
                            <div key={index} className="row">
                                {chunk.map((option, idx) => (
                                    <div key={idx} className="col-md-6">
                                        <ul>
                                            <li>{option}</li>
                                        </ul>
                                    </div>
                                ))}
                            </div>
                        ))}
                        {itemsToShow < allOptions.length && (
                            <div className="col-md-12">
                                <button onClick={handleShowMore}>More...</button>
                            </div>
                        )}
                    </div>
                    {/*------------------------------- Seller Discription--------------------------- */}
                    <h5 style={{ color: "#d1823a" }}>Seller Discription:-</h5>
                    <div className="vehicle-specs">
                        <div className="spec-item">
                            <p><span style={{ fontSize: "15px", fontWeight: "bold" }}>Title state:</span></p>
                            <p>{formData.state}</p>
                        </div>
                        <div className="spec-item">
                            <p><span style={{ fontSize: "15px", fontWeight: "bold" }}>Title Status:</span></p>
                            <p>{formData.title_status}</p>
                        </div>
                        <div className="spec-item">
                            <p><span style={{ fontSize: "15px", fontWeight: "bold" }}>Odo:</span></p>
                            <p>{formData.odo}</p>
                        </div>
                    </div>
                    <div className="vehicle-specs">
                        <div className="spec-item">
                            <p><span style={{ fontSize: "15px", fontWeight: "bold" }}>Owner:</span></p>
                            <p>{formData.owner}</p>
                        </div>
                        <div className="spec-item">
                            <p><span style={{ fontSize: "15px", fontWeight: "bold" }}>Exterior Damage:</span></p>
                            <p>{formData.exterior_damage}</p>
                        </div>
                        <div className="spec-item">
                            <p><span style={{ fontSize: "15px", fontWeight: "bold" }}>Vehicle Status:</span></p>
                            <p>{formData.status}</p>
                        </div>
                    </div>
                    <div className="vehicle-specs">
                        <div className="spec-item">
                            <p><span style={{ fontSize: "15px", fontWeight: "bold" }}>Keys:</span></p>
                            <p>{formData.keys_count}</p>
                        </div>
                        <div className="spec-item">
                            <p><span style={{ fontSize: "15px", fontWeight: "bold" }}>Smoked In:</span></p>
                            <p>{formData.smoked_in}</p>
                        </div>
                        <div className="spec-item">
                            <p><span style={{ fontSize: "15px", fontWeight: "bold" }}>Tyre Condition-FL:</span></p>
                            <p>{formData.tyrecondition_fl}</p>
                        </div>
                    </div>
                    <div className="vehicle-specs">
                        <div className="spec-item">
                            <p><span style={{ fontSize: "15px", fontWeight: "bold" }}>Tyre Condition-FR:</span></p>
                            <p>{formData.tyrecondition_fr}</p>
                        </div>
                        <div className="spec-item">
                            <p><span style={{ fontSize: "15px", fontWeight: "bold" }}>Tyre Condition-BL:</span></p>
                            <p>{formData.tyrecondition_bl}</p>
                        </div>
                        <div className="spec-item">
                            <p><span style={{ fontSize: "15px", fontWeight: "bold" }}>Tyre Condition-BR:</span></p>
                            <p>{formData.tyrecondition_br}</p>
                        </div>
                    </div>
                    <div><p><span style={{ fontSize: "15px", fontWeight: "bold" }}>Seller Discription:-</span></p>
                        <p>{formData.seller_description}</p>
                    </div>
                    {/* --------Maintananace------------------------------ */}
                    <div className="row">
                        <div className="col-md-12">
                            <h5><span style={{ fontSize: "15px", fontWeight: "bold", color: "#d1823a" }}>Maintanance:-</span></h5>
                            {renderRepairCostTable()}
                        </div>
                    </div>
                    {/*-------------------------------------- Title info------------------------------- */}
                    <h5><span style={{ fontSize: "15px", fontWeight: "bold", color: "#d1823a" }}>Title Info:-</span></h5>
                    <div className="vehicle-specs">
                        <div className="spec-item">
                            <p><span style={{ fontSize: "15px", fontWeight: "bold" }}>Title state:</span></p>
                            <p>{formData.state}</p>
                        </div>
                        <div className="spec-item">
                            <p><span style={{ fontSize: "15px", fontWeight: "bold" }}>Title Status:</span></p>
                            <p>{formData.title_status}</p>
                        </div>
                        <div className="spec-item">
                            <p><span style={{ fontSize: "15px", fontWeight: "bold" }}>Title Number:</span></p>
                            <p>{formData.title_number}</p>
                        </div>
                    </div>
                    <div className="vehicle-specs">
                        <div className="spec-item">
                            <p><span style={{ fontSize: "15px", fontWeight: "bold" }}>Title Received:</span></p>
                            <p>{formData.title_recieved}</p>
                        </div>
                        <div className="spec-item">
                            <p><span style={{ fontSize: "15px", fontWeight: "bold" }}>Title Location:</span></p>
                            <p>{formData.title_location}</p>
                        </div>
                        <div className="spec-item">
                            <p><span style={{ fontSize: "15px", fontWeight: "bold" }}></span></p>
                            <p></p>
                        </div>
                    </div>
                    {/* -------------finanace------------------------- */}
                    <h5><span style={{ fontSize: "15px", fontWeight: "bold", color: "#d1823a" }}>Finanace:-</span></h5>
                    <div className="vehicle-specs">
                        <div className="spec-item">
                            <p><span style={{ fontSize: "15px", fontWeight: "bold" }}>Payment Date:</span></p>
                            <p>{formData.price_fee_date}</p>
                        </div>
                        <div className="spec-item">
                            <p><span style={{ fontSize: "15px", fontWeight: "bold" }}>Market Value Price:</span></p>
                            <p>{formData.market_value_price}</p>
                        </div>
                        <div className="spec-item">
                            <p><span style={{ fontSize: "15px", fontWeight: "bold" }}>Payment Method:</span></p>
                            <p>{formData.payment_method}</p>
                        </div>
                    </div>
                    <div className="vehicle-specs">
                        <div className="spec-item">
                            <p><span style={{ fontSize: "15px", fontWeight: "bold" }}>Listing Price:</span></p>
                            <p>{formData.listing_price}</p>
                        </div>
                        <div className="spec-item">
                            <p><span style={{ fontSize: "15px", fontWeight: "bold" }}>Purchase Price:</span></p>
                            <p>{formData.purchase_price}</p>
                        </div>
                        <div className="spec-item">
                            <p><span style={{ fontSize: "15px", fontWeight: "bold" }}>Est. Profit:</span></p>
                            <p>{formData.profit_price}</p>
                        </div>
                    </div>
                    <div className="vehicle-specs">
                        <div className="spec-item">
                            <p><span style={{ fontSize: "15px", fontWeight: "bold" }}>Total Repair:</span></p>
                            <p>{formData.total_repair_cost}</p>
                        </div>
                        <div className="spec-item">
                            <p><span style={{ fontSize: "15px", fontWeight: "bold" }}>Others:</span></p>
                            <p>{formData.others}</p>
                        </div>
                        <div className="spec-item">
                            <p><span style={{ fontSize: "15px", fontWeight: "bold" }}>Total Cost:</span></p>
                            <p>{formData.total_cost}</p>
                        </div>
                    </div>
                    {/*------------------------------------------- for advertising manager----------------------------------- */}
                    <div className="row">
                        <div className="col-md-12">
                            <h5><span style={{ color:"#d1823a" }}>Advertising:-</span></h5>
                            <ul>
                                {advertisingData.map((ad, index) => (
                                    <li key={index}>{ad.platform}</li>
                                ))}
                            </ul>
                        </div>
                      </div>
                    </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-secondary" onClick={handleClose}>
                    Close
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default Preview;

 import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { fetchGeoMapData } from "../dashboard/geoAPI";
import Image from "../imgData";
import "./NearByCars.css";
import { useSelector } from "react-redux";
import { SlSpeedometer } from "react-icons/sl";
import { IoLocationSharp } from "react-icons/io5";
import { FaHeart } from "react-icons/fa";
const imgUrl = process.env.REACT_APP_IMG_URL;
 
const NearByCars = () => {
  const { id } = useSelector((state) => state.user);
  const [formData, setFormData] = useState({
    latitude: 0,
    longitude: 0,
    ip: "",
  });

  const [carsListing, setCarListing] = useState([]);
  // console.log("Near by cars Listing =>", carsListing);

  // useEffect(() => {
  //   // Fetch Latitude and longitude
  //   const handleGMapAPI = async () => {
  //     try {
  //       const res = await fetchGeoMapData();
  //       console.log("response of 2 latitude", res.coordinates.lat);
  //       console.log("response of 2 longitude", res.coordinates.lng);
  //       setFormData((prevData) => ({
  //         ...prevData,
  //         latitude: res.coordinates.lat || 0,
  //         longitude: res.coordinates.lng || 0,
  //       }));
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };
  //   handleGMapAPI();
  // }, []);

  const axiosInstance = axios.create({
    // Add crossorigin attribute to enable CORS
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  });

  useEffect(() => {
    // Fetch Latitude and longitude
    const handleGMapAPI = async () => {
      try {
        const res = await fetchGeoMapData();
        // console.log("Response of latitude:", res.coordinates.lat);
        // console.log("Response of longitude:", res.coordinates.lng);
        setFormData((prevData) => ({
          ...prevData,
          latitude: res.coordinates.lat || 0,
          longitude: res.coordinates.lng || 0,
        }));

        // After fetching coordinates, fetch nearby cars
        await fetchData(res.coordinates.lat, res.coordinates.lng);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    handleGMapAPI();
  }, []);

  const fetchData = async (lat, lng) => {
    try {
      const ipResponse = await axios.get("https://api.ipify.org/?format=json");
      setFormData((prevData) => ({
        ...prevData,
        ip: ipResponse.data.ip || "",
      }));

      if (ipResponse.data.ip) {
        const fd = new FormData();
        fd.append("latitude", lat);
        fd.append("longitude", lng);
        fd.append("ip", ipResponse.data.ip);

        const carsResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}/listing/getnearby_listing`,
          fd
        );
        setCarListing(carsResponse.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // useEffect(() => {
  //   // Fetch IP
  //   const getUserIPaddress = async () => {
  //     try {
  //       const res = await axiosInstance.get(
  //         "https://api.ipify.org/?format=json"
  //       );
  //       console.log("response of ip", res.data);
  //       setFormData((prevData) => ({
  //         ...prevData,
  //         ip: res.data.ip || "",
  //       }));
  //     } catch (error) {
  //       console.log("error ", error);
  //     }
  //   };
  //   getUserIPaddress();
  // }, []);

  // useEffect(() => {
  //   // Making a post request
  //   const fetchNearByCars = async () => {
  //     try {
  //       const fd = new FormData();
  //       fd.append("latitude", formData.latitude);
  //       fd.append("longitude", formData.longitude);
  //       fd.append("ip", formData.ip);
  //       if (formData.ip) {
  //         const res = await axios.post(
  //           `${process.env.REACT_APP_API_URL}/listing/getnearby_listing`,
  //           fd
  //         );
  //         setCarListing(res.data); // Assuming res.data contains the array of car listings
  //       }
  //     } catch (error) {
  //       console.log("Server side error ", error);
  //     }
  //   };
  //   fetchNearByCars();

  // }, [formData]);

  const navigate = useNavigate();
  const handleClick = (product) => {
    navigate(`/cardetail/${product.id}_${product.year}_${product.make}`, {
      state: { product },
    });
  };

  const getImageSrc = (multimedia, id) => {
    // console.log("multimedia", multimedia);

    if (multimedia.all_images) {
      const allImages = multimedia.all_images.split(",");
      if (allImages.length > 0 && allImages[0]) {
        return `${imgUrl}/${id}/${allImages[0]}`;
      }
    }
    if (multimedia.exterior_images) {
      const exteriorImages = multimedia.exterior_images.split(",");
      if (exteriorImages.length > 0 && exteriorImages[0]) {
        return `${imgUrl}/${id}/${exteriorImages[0]}`;
      }
    }
    if (multimedia.interior_images) {
      const interiorImages = multimedia.interior_images.split(",");
      if (interiorImages.length > 0 && interiorImages[0]) {
        return `${imgUrl}/${id}/${interiorImages[0]}`;
      }
    }
    if (multimedia.misc_images) {
      return `${imgUrl}/${id}/${multimedia.misc_images.split(",")[0]}`;
    }
    return Image.image_not_available;
  };
  console.log("carsListing", carsListing);
  return (
    <section style={{ cursor: "pointer" }}>
      <div className="container-fluid p-4">
        <div className="row">
          <h3 className="text-center p-4">Featured Dealers Near Me</h3>
          {carsListing.length > 0 && (
            <>
              <div className="col-12">
                <div className="col-lg-12">
                  <div className="row">
                    {carsListing.slice(0, 4).map((car, index) => (
                      <div
                        key={index}
                        className="col-lg-4 nearByCarcardWrapper"
                        onClick={() => handleClick(car)}
                        style={{ borderRadius: "25px" }}
                      >
                        <div
                          className="card mt-3 card-product"
                          style={{ borderRadius: "25px" }}
                        >
                          <div
                            className="product-image-box-c"
                            style={{ position: "relative" }}
                          >
                            <img
                              src={getImageSrc(car.multimedia, car.userId)}
                              className="card-img-top card-product-img"
                              alt="..."
                              style={{
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                width: "100%",
                                height: "19.6rem",
                                borderRadius: "25px 25px 0 0",
                              }}
                            />
                            <span className="grid-int-color-b">
                              Int. Color{" "}
                              <span
                                style={{
                                  display: "inline-block", // Make the span an inline-block element
                                  width: "20px", // Set the width for the circle
                                  height: "20px", // Set the height for the circle
                                  backgroundColor: car.interiorColor,
                                  borderRadius: "50%", // Make it circular
                                  // marginLeft: "1.3rem",
                                  border: "1px solid white",
                                }}
                                title={
                                  car.interiorColor
                                    ? car.interiorColor
                                    : ""
                                }
                              />
                            </span>
                            <div
                              className="overlay-text top-text"
                              onClick={() => handleClick(car)}
                              style={{ cursor: "pointer" }}
                            >
                              <div
                                style={{ padding: "1rem", fontSize: "medium" }}
                              >
                                {car.year_Make_Model || "Default Title"}
                              </div>
                              <span className="grid-card-mi">
                                <SlSpeedometer style={{ fill: "#d1823a" }} />{" "}
                                <span>
                                  {new Intl.NumberFormat().format(
                                    car.mileage_in
                                  )}{" "}
                                  mi
                                </span>
                              </span>
                            </div>

                            <div
                              className="overlay-text bottom-text"
                              style={{ position: "relative" }}
                            >
                              <div className="gird-card-bottom-txt">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "1rem",
                                    width: "100%",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      marginTop:"1.5rem"
                                    }}
                                  >
                                    <p
                                      style={{
                                        display: "flex",
                                        gap: "5px",
                                        display: "none",
                                      }}
                                    >
                                      <span> {car?.driveType || 0}</span>
                                      <span> {car?.engine.split(" ")[1]} </span>
                                      <span> {car?.engine.split(" ")[0]} </span>
                                      <span> {car?.engine.split(" ")[3]} </span>
                                      <span style={{ marginBottom: "0.5rem" }}>
                                        {" "}
                                        {car.transmission ||
                                          "Transmission NA"}{" "}
                                      </span>{" "}
                                    </p>
                                    <p style={{ marginBottom: "0.5rem" }}>
                                      Seller :{" "}
                                      {car.user_name || "Seller Name NA"}
                                    </p>
                                    <p>
                                      <span style={{ fontSize: "large" }}>
                                        <IoLocationSharp
                                          style={{ fill: "#d1823a" }}
                                        />{" "}
                                      </span>
                                      {car?.location || "Seller Location NA"}{" "}
                                      (45 mi away)
                                    </p>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <p
                                      id="grid-card-price"
                                      style={{ textAlign: "center" }}
                                    >
                                      $
                                      {new Intl.NumberFormat().format(
                                        car.listing_price
                                      )}{" "}
                                    </p>
                                    <p style={{ textAlign: "center" }}>
                                      <span>
                                        Est $
                                        {new Intl.NumberFormat().format(
                                          car?.estimated_cost
                                            ? Math.ceil(car?.estimated_cost)
                                            : 0
                                        )}{" "}
                                        mo
                                      </span>
                                    </p>
                                    <p id="grid-card-check-avlt">
                                      Check Availability
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="product_share_like-c">
                              {car.id !== 0 ? (
                                <span
                                //   onClick={(e) => {
                                //     e.stopPropagation();
                                //     handleLikeTheProduct(car.id);
                                //   }}
                                >
                                  {/* <FaHeart
                                  // fill={`${isFav ? "red" : "white"}`}
                                  /> */}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default NearByCars;

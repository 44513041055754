import React from 'react'
import { Modal } from 'react-bootstrap';

const LoanApplication = ({ show, handleClose}) => {
  return (
    <Modal show={show} size="xl" onHide={handleClose}>
      <Modal.Header closeButton>
        <div>
         Header
        </div>
      </Modal.Header>
      <Modal.Body>
        loan Application Section
      </Modal.Body>
      <Modal.Footer>
       Footer
      </Modal.Footer>
    </Modal>
  )
}

export default LoanApplication;
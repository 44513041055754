import React, { useState, useEffect, useRef } from 'react';

const StateSelector = ({ onStateSelect }) => {
  const [selectedState, setSelectedState] = useState('');
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [lastKey, setLastKey] = useState('');
  const [lastKeyTime, setLastKeyTime] = useState(0);
  const listRef = useRef(null);

  const states = [
    "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "Florida", "Georgia",
    "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland",
    "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey",
    "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina",
    "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"
  ];

  useEffect(() => {
    if (listRef.current) {
      listRef.current.focus();
    }
  }, []);

  const handleKeyDown = (e) => {
    if (e.key.length === 1 && e.key.match(/[a-z]/i)) {
      const currentTime = new Date().getTime();
      const key = e.key.toLowerCase();

      if (key !== lastKey || currentTime - lastKeyTime > 1000) {
        setCurrentIndex(-1);
      }

      setLastKey(key);
      setLastKeyTime(currentTime);

      const matchingOptions = states.filter(state =>
        state.toLowerCase().startsWith(key)
      );

      if (matchingOptions.length > 0) {
        const newIndex = (currentIndex + 1) % matchingOptions.length;
        setCurrentIndex(newIndex);
        const optionToSelect = matchingOptions[newIndex];
        setSelectedState(optionToSelect);
        const optionElement = listRef.current.querySelector(`[data-value="${optionToSelect}"]`);
        if (optionElement) {
          optionElement.scrollIntoView({ block: 'nearest' });
        }
      }
    } else if (e.key === 'Enter') {
      selectState();
    }
  };

  const selectState = () => {
    if (selectedState) {
      onStateSelect(selectedState);
    }
  };

  return (
    <div 
      style={{
        width: '470px',
        height: '338px',
        overflow: 'hidden'
      }}
    >
      <div
        ref={listRef}
        tabIndex="0"
        onKeyDown={handleKeyDown}
        style={{
          width: '100%',
          height: '100%',
          overflowY: 'scroll',
          outline: 'none'
        }}
      >
        {states.map((state) => (
          <div 
            key={state}
            data-value={state}
            onClick={() => setSelectedState(state)}
            style={{
              padding: '5px',
              cursor: 'pointer',
              backgroundColor: state === selectedState ? '#e0e0e0' : 'transparent'
            }}
          >
            <label style={{ display: 'flex', alignItems: 'center', width: '100%' }} onClick={selectState}>
              <input
                type="radio"
                readOnly
                // checked={state === selectedState}
                style={{ marginRight: "1rem" }}
              />
              <span>{state}</span>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StateSelector;
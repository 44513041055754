import React from 'react'
import DealerProductsCard from './DealerProductsCard';
import '../Styles/DealerFaciltiesProducts.css'

const DealerFacilitiesProducts = () => {
  return (
    <div className='dealer_facilities_wrapper'>
        <div className='dealer_facilities_container'>
            <p className='dealer_products_header'>OUR PRODUCTS</p>
            <div className='dealer_card_container'>
            <DealerProductsCard text="DMS"/>
            <DealerProductsCard text="WEBSITE FOR DEALER"/>
            <DealerProductsCard text="DIGITAL CONTRACTING"/>
            <DealerProductsCard text="ADVERTISING MANAGER"/>
            <DealerProductsCard text="FACEBOOK MARKET SERVICE"/>
            <DealerProductsCard text="DAC GOOGLE POWER"/>
            <DealerProductsCard text="SMART CRM"/>
            <DealerProductsCard text="HR SYSTEM"/>
            <DealerProductsCard text="QUALIFIED LEADS"/>
            <DealerProductsCard text="FAX SERVICES"/>
            <DealerProductsCard text="DOMAIN AND HOSTING"/>
            <DealerProductsCard text="LOGISTICS SERVICE"/>
            <DealerProductsCard text="DAC UNIVERSITY"/>
            <DealerProductsCard text="DAC EMAIL SERVICES"/>
            {/* <DealerProductsCard text="DAC AUCTION LINE"/> */}
            </div>
        </div>
    </div>
  )
}

export default DealerFacilitiesProducts
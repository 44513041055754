import React from "react";

const FooterNew = () => {
  return (
    <div>
      <div
        style={{
        //   border: "1px solid black",
          display: "flex",
          padding: " 1rem 4rem",
          backgroundColor: "#D9D9D9",
        }}
      >
        <p
          style={{
            color: "#d66b00",
            fontSize: "50px",
            fontWeight: "600",
            flex: "0.6",
          }}
        >
          <span>Dealer Auto Center</span>
        </p>
        <p
          style={{
            display: "flex",
            flexDirection: "column",
            flex: "0.2",
            fontSize: "large",
            fontWeight: "600",
            marginTop: "2rem",
          }}
        >
          <span style={{ textDecoration: "underline", marginBottom: "0.5rem" }}>
            Home
          </span>
          <span style={{ textDecoration: "underline", marginBottom: "0.5rem" }}>
            Inventory
          </span>
          <span style={{ textDecoration: "underline", marginBottom: "0.5rem" }}>
            Finance
          </span>
        </p>
        <p
          style={{
            display: "flex",
            flexDirection: "column",
            flex: "0.2",
            fontSize: "large",
            fontWeight: "600",
            marginTop: "2rem",
          }}
        >
          <span style={{ textDecoration: "underline", marginBottom: "0.5rem" }}>
            About Us
          </span>
          <span style={{ textDecoration: "underline", marginBottom: "0.5rem" }}>
            Privacy Policy
          </span>
          <span style={{ textDecoration: "underline", marginBottom: "0.5rem" }}>
            Terms and Conditions
          </span>
        </p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0.5rem 4rem",
          fontSize: "large",
          fontWeight: "600",
          backgroundColor: "#CBCBCB",
        }}
      >
        <p>
          By calling the dealership you agree and accept to Terms and Conditions
          to use.
        </p>
        <p>
          Powered By Dealers Auto Center &reg; 2024 | &copy; All right reserved
        </p>
      </div>
    </div>
  );
};

export default FooterNew;

import React from "react";
import styles from "../PaymentStage2.module.css";
import { IoMdArrowRoundBack } from "react-icons/io";

const PaymentStage2 = ({ setPaymentStages }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.header}>
          <span onClick={() => setPaymentStages("stage1")}>
            <IoMdArrowRoundBack />
          </span>
          <span>Payment</span>
        </div>
        <div className={styles.containerBody}>
          <p className={styles.recepientName}>Emran Rabbah</p>
          <div className={styles.recepietAmt}>
            <p>$2500.22</p>
          </div>
          <p className={styles.recepietSendBtn}>
            <button onClick={() => setPaymentStages("stage3")}>Send Web Receipt</button>

            <button>Print</button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PaymentStage2;

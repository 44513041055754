
import React, { useEffect, useState } from 'react';
import Navbar from '../navbars/Navbar';
import { Link, useNavigate } from 'react-router-dom';
import './AccountVerification.css';
import axios from 'axios';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const apiUrl = process.env.REACT_APP_API_URL;


const AccountVerification = () => {

  const navigate = useNavigate();
  const [token, setToken] = useState("");
  const handleVerifyOTP = async (e) => {
    e.preventDefault();
    try {
      
      const response = await axios.post(`${apiUrl}/user/verify/token=${token}`);
      //console.log(response.data); 
      alert("Email verification successful");
      navigate("/login");
    } catch (error) {
      console.error('API Error:', error);
    }
   
  };

  const [minutes, setMinutes] = useState(4);
  const [seconds, setSeconds] = useState(59);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
           navigate('/singup');
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  return (
    <div>
      <Navbar />
      <div className='sing_up template d-flex justify-content-center align-items-center vh-50 varification-main-container' style={{height:"715px"}}>
        <div className='form_container p-5 bg-white shadow-lg mb-5 mt-5 bg-body' style={{height:"531px"}}>
          <form style={{marginTop:"49px"}}>
            <h3 className='text-center mb-5 fw-bold'>Account Verification</h3>
            <div className="row g-3">
              <div className="col-12">
                <input type="text" className="form-control" placeholder="Please Enter OTP" value={token} onChange={e => setToken(e.target.value)} />
              </div>
              {/* Timer */}
              <p>
                Time Remaining:{" "}
                <span style={{ fontWeight: 600 }}>
                  {minutes < 10 ? `0${minutes}` : minutes}:
                  {seconds < 10 ? `0${seconds}` : seconds}
                </span>
              </p>
              {/* Timer End */}
              <div className='d-grid'>
                <button type="submit" className='btn btn-dark' onClick={handleVerifyOTP}> Sign up</button>
              </div>
              {/* <p className='p_texts text-center form-label'>Already have an account?
                <small><Link to="/login" className="ms-2">login</Link></small>
              </p> */}
              {/* <p className='p_or text-center'>or</p> */}
              <div className="singupicons px-lg-2 text-center">
                <h5 style={{color:"red",cursor:"pointer"}}><u>Resend OTP</u></h5>
              </div>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AccountVerification;


// import { createSlice } from "@reduxjs/toolkit";

// const initialState = {
//     view: 'c',
//     counter:0,
// }

// const activeSlice = createSlice({
//     name: "activeSlice",
//     initialState,
//     reducers: {
//         saveView: (state, action) => {
//             state.view = action.payload;
//         }
//     },
// })

// export const { saveView } = activeSlice.actions;
// export default activeSlice.reducer;
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    view: 'c',
    counter: 0,
}

const activeSlice = createSlice({
    name: "activeSlice",
    initialState,
    reducers: {
        saveView: (state, action) => {
            state.view = action.payload;
        },
        incrementCounter: (state) => {
            console.log('Before incrementCounter:', state.counter);
            state.counter += 1;
            console.log('After incrementCounter:', state.counter);
        }
    },
})

export const { saveView, incrementCounter } = activeSlice.actions;
export default activeSlice.reducer;
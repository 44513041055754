import React, { useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import styles from "../Styles/mailAddAcc.module.css";
import comStyles from "../Styles/common.module.css";
import { FaPlus } from "react-icons/fa";

const DealerMailBlacklist = () => {
    return (
        <Container className={`p-4`}>
            <Row>
                <Col sm={12}>
                    <h5 className={` ${styles.settingHead}`}>Signatures</h5>
                    <p className={`m-0 ${styles.subHead}`}>
                        The following table lists sender addresses that will always be rejected. Incoming messages from senders whose complete email address (e.g. john@example.com) is on this list will be rejected. You can also enter a domain (e.g. example.com) to reject all mail from that domain.</p>
                </Col>
                <Col sm={10}>
                    <form className={` ${styles.form}`}>

                        <label className={`my-3 d-flex justify-content-between`}>
                            Choose Provider:
                            <select
                                style={{
                                    width: '60%',
                                    background: "white",
                                    padding: '2px 10px',
                                    border: '1px solid '
                                }}
                            >
                                <option value="">Select a Provider</option>
                                <option value="Gmail">By individual sender</option>
                                <option value="Yahoo">By originating domain</option>
                            </select>
                        </label>

                        <div className={`m-0 ${styles.pop}`}>

                            <div className={` ${comStyles.tableCon}`} style={{ minHeight: '25vh' }}>
                                <div className={` ${comStyles.tableHead}`}>Sender/Domain</div>
                                <table >
                                    <tbody>
                                        <tr>
                                            <td className='w-75'>db.com</td>
                                            <td>Remove</td>
                                            <td>Edit</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <Button className={`p-1 ${comStyles.saveBtn}`}> <FaPlus />New Sender/Domain</Button>
                    </form>

                    <div className={`p-1 ${comStyles.popUps}`}>
                        <p className={`my-1 ${styles.subHead}`}>Sender/Domain</p>
                        <div>
                            <input
                                type="email"
                                name="email"
                            />
                            <div className={`my-1 ${comStyles.fsSmall}`}>Enter an address (e.g. john@example.com) or domain (e.g. example.com) to whitelist</div>
                        </div>
                        <div className={` d-flex justify-content-end`}>
                            <button className={`me-2 px-4 ${comStyles.saveBtn}`} type="submit">OK</button>
                            <button className={`px-2 ${comStyles.saveBtn}`} type="button">Cancel</button>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>

    )
}

export default DealerMailBlacklist
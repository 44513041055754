import { Stomp } from '@stomp/stompjs';
import SockJS from 'sockjs-client';
const apiUrl = process.env.REACT_APP_API_URL;
class WebSocketService {
    constructor() {
        this.stompClient = null;
    }

    connect(onMessageReceived, username) {
        const socket = new SockJS(`${apiUrl}/ws`);
        this.stompClient = Stomp.over(socket); // Use Stomp.over to initialize over SockJS

        this.stompClient.connect({}, () => {
            console.log(`Connected as ${username}`);
            this.stompClient.subscribe(`/user/${username}/queue/messages`, (message) => {
                console.log('Message received:', message.body); // Debug log
                onMessageReceived(message);
            });
        }, (error) => {
            console.error('Connection error:', error);
            this.stompClient = null; // Reset the client on error
        });
    }

    sendMessage(message) {
        if (this.stompClient && this.stompClient.connected) {
            this.stompClient.send("/app/send", {}, JSON.stringify(message));
            console.log("Message sent:", message);
        } else {
            console.error('Unable to send message. WebSocket is not connected.');
        }
    }

    sendTypingStatus(username, recipient, isTyping) {
        const message = {
            sender: username,
            recipient: recipient,
            content: isTyping ? "is typing..." : "",
            type: "TYPING"
        };
        this.sendMessage(message);
    }

    disconnect() {
        if (this.stompClient && this.stompClient.connected) {
            this.stompClient.disconnect(() => {
                console.log('Disconnected');
            }, (error) => {
                console.error('Disconnection error:', error);
            });
        } else {
            console.warn('Attempted to disconnect, but stompClient was not initialized or already disconnected.');
        }
        this.stompClient = null; // Reset the client after disconnection
    }
}

const webSocketService = new WebSocketService();
export default webSocketService;

import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import BasicInfo from "../components/BasicInfo";
import Permissions from "../components/Permissions";
import LeadNotification from "../components/LeadNotification";
import { useSelector } from "react-redux";
import axios from "axios";
const DealerdataEdit = ({ show, handleClose ,userId,userEditData,setDeleteCount}) => {
  //Preview Modal
  const { id } = useSelector((state) => state.user);
  const [imageData, setImageData] = useState(null);
  const UserIdEdit = userEditData?.id;
  const [formData, setFormData] = useState({
    dealerId : id,
    id:0,
    firstName: "",
    lastName: "",
    email: "",
    jobTitle: "",
    phoneNumber: "",
    landlineNumber: "",
    password: "",
    confirmpassword: "",
    roles: "Dealer",
    sms_notify: false,
    email_notify: false,
  });
  console.log("formData57", formData);
  console.log("userEditData*********", userEditData);
  const profilePicture = userEditData?.profile_picture;
 
  console.log("profile_picture",profilePicture);
  useEffect(() => {
    if (userEditData) {
      setFormData((prevState) => ({
        ...prevState,
        firstName: userEditData?.firstName || "",
        lastName: userEditData?.lastName || "",
        email:  userEditData?.email || "",
        jobTitle: userEditData?.jobTitle || "",
        phoneNumber:  userEditData?.phoneNumber || "",
        landlineNumber: userEditData?.landlineNumber || "",
        password:  userEditData?.password || "",
        confirmpassword: userEditData?.password || "",
        roles: "Dealer",
        sms_notify:  userEditData?.sms_notify || false,
        email_notify:userEditData?.email_notify || false,
        id:userEditData?.id || 0,
    }))
    }
  }, [userEditData]);
  // for images -----------------------------------
  const handleImageUpload = (binaryData) => {
    setImageData(binaryData); // Update state with the binary image data
    console.log('Uploaded Image Data:', binaryData);
  };
  // -----------------------------------------------Dealer Profile Update*************---------------------
  // -----------------------------------------------
  console.log("formData***********",formData);
  const handleUpdateuserInfo = async () => {
    const fd = new FormData();

    fd.append("profile_picture", imageData);
    fd.append("data", JSON.stringify(formData));
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/add_user`,
        fd
      );
      if(res.status=== 200){
        handleClose();
        setDeleteCount(prevCount => prevCount + 1);
      }
    } catch (error) {
      console.log("Error 500 while updating the user information");
    }
  };
  // ------------------------------------------------------------------------------------------------------
  

  return (
    <Modal
      show={show}
      size="xl"
      onHide={handleClose}
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
        User Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <BasicInfo formData={formData} setFormData={setFormData} onImageUpload={handleImageUpload} profilePictureUrl={profilePicture} UserIdEdit={UserIdEdit}/>
        <Permissions formData={formData} setFormData={setFormData} />
        <LeadNotification formData={formData} setFormData={setFormData} userEditData={userEditData}/>
      </Modal.Body>
      <Modal.Footer
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <button
          className="btn btn-secondary"
          style={{ width: "150px" }}
          onClick={handleUpdateuserInfo}
        //   disabled={loading}
        >
         {/* {loading ? <div className="spinner"></div> : 'Submit'} */}
         Update
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default DealerdataEdit;

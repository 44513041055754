export const carPreviews = [
  {
    carName: "2012 FORD F-241 Pickup 4WD",
    MileageIn: 3256,
    WheelDrive: 3,
    engineSize: 4,
    numOfEngines: 3,
    enginType: "Gasoline",
    price: 25000,
    exteriorColor: "#3498db", // blue
    interiorColor: "#f7dc6f", // beige
    location: "123 Main St, Anytown, CA 12345",
    image: 'https://edgecast-img.yahoo.net/mysterio/api/BC51E229135BBDC7BAAC034D62506FD1E1E7357024DD471DFA9CA86D8238A19B/autoblog/resizefill_w768_h432;quality_85;format_webp;cc_31536000;/https://s.aolcdn.com/commerce/autodata/images/CAC20FOT111A0101.jpg',
    sellerName: "John Doe",
  },
  {
    carName: "2015 Chevrolet Silverado 1500",
    MileageIn: 4500,
    WheelDrive: 2,
    engineSize: 5.3,
    numOfEngines: 1,
    enginType: "Gasoline",
    price: 32000,
    exteriorColor: "#2ecc71", // green
    interiorColor: "#95a5a6", // gray
    location: "456 Elm St, Othertown, TX 67890",
    image: 'https://img2.carmax.com/assets/mmy-chevrolet-cruze-2015/image/1.jpg?width=800&height=450',
    sellerName: "Jane Smith",
  },
  {
    carName: "2018 Ram 1500",
    MileageIn: 2100,
    WheelDrive: 4,
    engineSize: 3.6,
    numOfEngines: 1,
    enginType: "Gasoline",
    price: 28000,
    exteriorColor: "#9b59b6", // purple
    interiorColor: "#f1c40f", // orange
    location: "789 Oak St, Thistown, IL 34567",
    image: 'https://p.turbosquid.com/ts-thumb/id/mopRYA/ZA/00/jpg/1621853059/300x300/sharp_fit_q85/f19cbd18dd66f07f30b56b2e6790654bdf9dcfa1/00.jpg',
    sellerName: "Bob Johnson",
  },
  {
    carName: "2020 Toyota Tacoma",
    MileageIn: 1800,
    WheelDrive: 2,
    engineSize: 2.7,
    numOfEngines: 1,
    enginType: "Gasoline",
    price: 22000,
    exteriorColor: "#e74c3c", // red
    interiorColor: "#8e44ad", // pink
    location: "321 Pine St, That-town, FL 12345",
    image: 'https://di-uploads-pod7.dealerinspire.com/toyotachulavista/uploads/2023/08/GR-1024x573.png',
    sellerName: "Alice Brown",
  },
  {
    carName: "2019 GMC Sierra 1500",
    MileageIn: 3200,
    WheelDrive: 4,
    engineSize: 5.3,
    numOfEngines: 1,
    enginType: "Gasoline",
    price: 30000,
    exteriorColor: "#1abc9c", // teal
    interiorColor: "#2980b9", // blue-green
    location: "901 Maple St, Anothertown, NY 67890",
    image: 'https://cgi.gmc.com/mmgprod-us/dynres/prove/image.gen?i=2024/TK10543/TK10543__3SB/GBA_09Y_0ST_1SZ_2ST_3SB_3ST_4AA_4ST_5FC_5ST_6RM_7RM_8HB_9HB_A2X_A50_A7E_AED_AEQ_AKO_AL0_ASV_AU3_AVI_AVJ_AXG_AXK_AY0_B1J_B30_B32_B33_BTM_BTV_BWN_C49_C59_C5Y_CJ2_CTT_D07_D31_D75_DLF_E35_E63_EF7_ENL_EPH_F48_FE9_FJW_G80_GFJ_GU5_H0U_IOK_IVN_J24_J61_JBP_JHD_K34_K47_K4C_KA1_KC4_KC9_KI3_KI4_KL9_KNP_KW7_L84_MAH_MHS_MSL_N06_N10_N37_N57_NQH_NTB_NZZ_PDI_PPW_PRF_QAE_QAQ_QK2_QT5_R5C_R7O_RD3_RFQ_RM7_RSR_SAF_SLM_SU4_SU7_T3U_T8Z_TDM_TQ5_U2K_U73_UBC_UBI_UBJ_UDV_UE1_UE4_UEU_UF2_UH5_UHX_UHY_UJN_UK3_UKJ_UMN_UQF_URC_URD_UTJ_UVB_V76_V8D_VB5_VRF_VRG_VRH_VRK_VRL_VRM_VRN_VRR_VSX_VT5_VT7_VTI_VV4_WMY_X31_YD9_Z71_Z82_Z88gmds2.jpg&v=deg01&std=true&country=US',
    sellerName: "Mike Davis",
  },
  {
    carName: "2016 Ford F-150",
    MileageIn: 4200,
    WheelDrive: 2,
    engineSize: 3.5,
    numOfEngines: 1,
    enginType: "Gasoline",
    price: 24000,
    exteriorColor: "#f39c12", // orange
    interiorColor: "#7f8c8d", // gray-brown
    location: "234 Cedar St, This-town, MI 34567",
    image: 'https://cars.usnews.com/static/images/Auto/izmo/i159615589/2023_ford_edge_angularfront.jpg',
    sellerName: "Emily Taylor",
  },
  {
    carName: "2017 Nissan Titan",
    MileageIn: 2800,
    WheelDrive: 4,
    engineSize: 5.6,
    numOfEngines: 1,
    enginType: "Gasoline",
    price: 26000,
    exteriorColor: "#8bc34a", // yellow-green
    interiorColor: "#4caf50", // green
    location: "567 Spruce St, That-town, CO 12345",
    image: 'https://vehicle-images.dealerinspire.com/cd06-110007321/JN1BZ4BH0RM363187/b9d7dae887a1293508a4b4f41ce5ad85.jpg',
    sellerName: "David Lee",
  },
  {
    carName: "2014 Dodge Ram 1500",
    MileageIn: 3800,
    WheelDrive: 2,
    engineSize: 3.6,
    numOfEngines: 1,
    enginType: "Gasoline",
    price: 20000,
    exteriorColor: "#2196f3", // blue
    interiorColor: "#9e9e9e", // gray
    location: "890 Oak St, Othertown, TX 67890",
    image: 'https://www.velocitytruckcenters.com/themes/vtc/assets/images/new-freightliner-cascadia-2017.jpg',
    sellerName: "Sarah Johnson",
  },
];

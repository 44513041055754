import React, { useEffect, useRef, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'

import { GiSave } from "react-icons/gi";
import styles from '../Styles/dealerContect.module.css';
import Image from '../../../imgData';

const DealerContactsForm = () => {

  const [profileImage, setProfileImage] = useState(null);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file instanceof File) {
      setProfileImage(URL.createObjectURL(file));
      console.log('file', profileImage);
    }
  };

  const [value, setValue] = useState('');
  const textareaRef = useRef(null);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    if (textareaRef.current) {
      const lines = value.split('\n');
      let formattedValue = '';

      if (lines.length > 0) {
        formattedValue += '    ' + lines[0] + '\n'; // Add indent to the first line
      }

      for (let i = 1; i < lines.length; i++) {
        formattedValue += lines[i] + '\n'; // No indent for the following lines
      }

      textareaRef.current.value = formattedValue;
      setValue(formattedValue);
    }
  }, [value]);

  return (
    <>
      <Col sm={12}>
        <Container className={`p-3`}>
          <Row>
            <Col sm={8}>
              <Row className={`mb-2 `}>
                <Col md={4}> <label htmlFor="fullName" className={` px-2 ${styles.contactLable}`}>Full Name: </label> </Col>
                <Col md={8}> <input type="text" name='fullName' className={` ${styles.contactInput}`} /> </Col>
              </Row>
              <Row className={`mb-2 `}>
                <Col md={4}> <label htmlFor="company" className={` px-2 ${styles.contactLable}`}>Company: </label> </Col>
                <Col md={8}> <input type="text" name='company' className={` ${styles.contactInput}`} /> </Col>
              </Row>
              <Row className={`mb-2 `}>
                <Col md={4}> <label htmlFor="jobTitle" className={` px-2 ${styles.contactLable}`}>Job Title: </label> </Col>
                <Col md={8}> <input type="text" name='jobTitle' className={` ${styles.contactInput}`} /> </Col>
              </Row>
            </Col>
            <Col sm={4} className={`  ${styles.uploadPro}`}>
              <label className={`${styles.profileLable}`} onClick={() => document.getElementById('uploadInput').click()}>
                <img
                  src={profileImage || Image.noImage}
                  alt="Profile"
                  width='100%'
                  height='100%'
                />
              </label>
              <input
                type="file"
                id="uploadInput"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={(e) => handleImageUpload(e)}
              />
            </Col>
          </Row>

          <Row>
            <Col sm={12} className={`d-flex flex-nowrap align-items-center justify-content-between my-2 gap-3`}>
              <span style={{ color: 'blue', textWrap: 'nowrap', fontSize: '1.1rem' }}>Contact Infomation</span> <span className={`border-2 border-bottom `} style={{ width: '83%' }}></span></Col>
            <Col sm={12}>
              <Row className={`mb-2 `}>
                <Col md={3}> <label htmlFor="fullName" className={` px-2 ${styles.contactLable}`}>Website: </label> </Col>
                <Col md={9}> <input type="text" name='fullName' className={` ${styles.contactInput}`} /> </Col>
              </Row>
              <Row className={`mb-2 `}>
                <Col md={3}> <label htmlFor="company" className={` px-2 ${styles.contactLable}`}>Business Ph: </label> </Col>
                <Col md={9}> <input type="text" name='company' className={` ${styles.contactInput}`} /> </Col>
              </Row>
              <Row className={`mb-2 `}>
                <Col md={3}> <label htmlFor="jobTitle" className={` px-2 ${styles.contactLable}`}>Mobile: </label> </Col>
                <Col md={9}> <input type="text" name='jobTitle' className={` ${styles.contactInput}`} /> </Col>
              </Row>
              <Row className={`mb-2 `}>
                <Col md={3}> <label htmlFor="company" className={` px-2 ${styles.contactLable}`}>Email: </label> </Col>
                <Col md={9}> <input type="text" name='company' className={` ${styles.contactInput}`} /> </Col>
              </Row>
              <Row className={`mb-2 `}>
                <Col md={3}> <label htmlFor="jobTitle" className={` px-2 ${styles.contactLable}`}>Fax: </label> </Col>
                <Col md={9}> <input type="text" name='jobTitle' className={` ${styles.contactInput}`} /> </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col sm={12} className={`d-flex flex-nowrap justify-content-between align-items-center my-2 gap-3`}>
              <span style={{ color: 'blue', fontSize: '1.1rem' }}>Address</span> <span className={`border-2 border-bottom `} style={{ width: '90%' }}></span></Col>
            <Col md={12}> <textarea rows='2' className={` px-2  ${styles.customTextarea}`} style={{ color: 'black' }} /> </Col>

            <Col sm={12} className={`d-flex flex-nowrap justify-content-between align-items-center my-2 gap-3`}>
              <span style={{ color: 'blue', fontSize: '1.1rem' }}>Notes</span> <span className={`border-2 border-bottom `} style={{ width: '90%' }}></span></Col>
            <Col md={12}> <textarea rows='2' className={` px-2  ${styles.customTextarea}`} style={{ color: 'black' }} /> </Col>
            <Col className={`d-flex justify-content-end align-items-center m-2`}><div className={`  ${styles.saveContect}`}><GiSave /></div></Col>
          </Row>

        </Container>
      </Col>
    </>
  )
}

export default DealerContactsForm
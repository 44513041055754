import React, { useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { FaArrowRightLong } from "react-icons/fa6";
import { useDispatch } from 'react-redux';
import { setSelectedVehicle, incrementTabIndex, setActiveTab } from '../../../Redux/slice/sellSlice';
import "./vehicle-list.css";
import VehicleCard from './VehicleCard';

const VehicleList = ({ vehicleData, isLoading, handleTabSelect, handleSelectedVehicle }) => {

    const [selectedCard, setSelectedCard] = useState(null);
    const dispatch = useDispatch();

    const handleSelected = (index, vehicle) => {
        setSelectedCard(index === selectedCard ? null : index);
        dispatch(setSelectedVehicle(vehicle));
        handleSelectedVehicle(vehicle);
        dispatch(incrementTabIndex());
        dispatch(setActiveTab('Buyer'));
        // console.log("vehicle", typeof vehicle);
    };

    return (

        <Container>
            {vehicleData && vehicleData.length > 0 ? (
                <Row>
                    <Col sm={11} className="mx-auto px-0 py-1">
                        <div className="input-group mb-4" id="act-search">
                            <Button className="btn border-0 dropdown-toggle search-all" type="button" data-bs-toggle="dropdown" aria-expanded="false">All</Button>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="#">Action</a></li>
                                <li><a className="dropdown-item" href="#">Another action</a></li>
                                <li><a className="dropdown-item" href="#">Something else here</a></li>
                                <li><hr className="dropdown-divider" /></li>
                                <li><a className="dropdown-item" href="#">Separated link</a></li>
                            </ul>
                            <input type="text" className="form-control" placeholder="Search Name" />
                            <Button className="btn border-0 search-btn" type="button" id="button-addon2" style={{ backgroundColor: "#e8a262" }}>
                                <i className="fas fa-search"></i>
                            </Button>
                        </div>
                    </Col>
                </Row>
            ) : null}


            {isLoading ? (
                <h2>Loading...</h2>
            )
                : vehicleData && vehicleData.length > 0 ? (
                    <Row>
                        <Col sm={11} className="mx-auto px-0">
                            <Container className="d-flex justify-content-center px-0">
                                <Row className="card-parent w-100">
                                    {vehicleData.map((vehicle, index) => (
                                        <VehicleCard
                                            key={index}
                                            index={vehicle.id}
                                            selectedCard={selectedCard}
                                            handleSelected={() => handleSelected(index, vehicle)}
                                            data={vehicle}
                                        />
                                    ))}
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                ) : (
                    <Row>
                        <Col sm={12} className="outer-box px-0">
                            <div className="no-list">
                                <h2>No active Inventory</h2>
                                <h2><Link to="/myprofile/addlist">Click here to add inventory</Link></h2>
                            </div>
                        </Col>
                    </Row>
                )}

        </Container>

        // <Container fluid className='my-3 mx-4'>
        //     <Row >
        //         {/* <Col sm={6} className="px-0">
        //             <div className="v-list-head"><h3>Vehicle list</h3></div>
        //         </Col> */}
        //         {vehicleData.length > 0
        //             ? <Col sm={10} className='mx-auto px-0 py-1'>
        //                 <div className="input-group mt-3" id='act-search'>
        //                     <Button className="btn border-0 dropdown-toggle search-all" type="button" data-bs-toggle="dropdown" aria-expanded="false" >All</Button>
        //                     <ul className="dropdown-menu">
        //                         <li><Link className="dropdown-item" to="#">Action</Link></li>
        //                         <li><Link className="dropdown-item" to="#">Another action</Link></li>
        //                         <li><Link className="dropdown-item" to="#">Something else here</Link></li>
        //                         <li><hr className="dropdown-divider" /></li>
        //                         <li><Link className="dropdown-item" to="#">Separated link</Link></li>
        //                     </ul>
        //                     <input type="text" className="form-control" placeholder='Search Name' />
        //                     <Button className="btn border-0 search-btn" type="button" id="button-addon2" style={{ backgroundColor: "#e8a262" }}><i className="fas fa-search"></i></Button>
        //                 </div>
        //             </Col>
        //             : null
        //         }
        //     </Row>

        //     {/* For no inventory available page start*/}

        //     {vehicleData && vehicleData.length > 0
        //         ? <Row>
        //             <Col sm={10}  className='mx-auto px-0'>
        //                 <Container className='d-flex justify-content-center'>
        //                     {/* <Row sm={12} className='d-flex justify-content-end mb-3'>
        //                         <Col sm={1} className='orange-btn'>
        //                             <FaArrowRightLong />
        //                         </Col>
        //                     </Row> */}

        //                     <Row className='card-parent '>
        //                         {vehicleData.map((vehicle) => (
        //                             <VehicleCard
        //                                 key={vehicle.id}
        //                                 index={vehicle.id}
        //                                 selectedCard={selectedCard}
        //                                 handleSelected={handleSelected}
        //                                 data={vehicle}
        //                             />
        //                         ))}
        //                     </Row>
        //                 </Container>
        //             </Col>
        //         </Row>
        //         : <Row >
        //             <Col sm={12} className="outer-box px-0">
        //                 <div className="no-list">
        //                     <h2>No active Inventory</h2>
        //                     <h2><Link to="/myprofile/active">Click here to add inventory</Link></h2>
        //                 </div>
        //             </Col>
        //         </Row>
        //     }

        // </Container>
    )
}


export default VehicleList
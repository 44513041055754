// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth, FacebookAuthProvider, OAuthProvider } from "firebase/auth";

// Your Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "dealers-auto-cednter.firebaseapp.com",
  projectId: "dealers-auto-cednter",
  storageBucket: "dealers-auto-cednter.appspot.com",
  messagingSenderId: "584197680128",
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: "G-MLX7V0BNV2",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// Define OAuth providers
const FbOAuthProvider = new OAuthProvider("facebook.com");
const AppleOAuthProvider = new OAuthProvider("apple.com"); // For Apple authentication
const YahooOAuthProvider = new OAuthProvider("yahoo.com"); // For Yahoo authentication

export { auth, FbOAuthProvider, AppleOAuthProvider, YahooOAuthProvider };

import Image from "../imgData";
export const sideBarArray = [
  
  // {
  //   id: 1,
  //   count: 0,
  //   name: "Sell Inventory",
  //   route: "/myprofile/sell",
  //   image: Image.sidebarSell,
  // },
  {
    id: 2,
    count: 0,
    name: "Sale My Car",
    route: "/myprofile/addlist",
    image: Image.sidebarAddListing,
  },
  {
    id: 3,
    count: 0,
    name: "My Active Listing",
    route: "/myprofile/active",
    image: Image.activeListing,
  },
  // {
  //   id: 4,
  //   count: 0,
  //   name: "Bought & Sold",
  //   route: "/myprofile/bought",
  //   image: Image.boughtSold,
  // },
  {
    id: 5,
    count: 0,
    name: "Draft",
    route: "/myprofile/draft",
    image: Image.sidebarDraft,
  },
  {
    id: 6,
    count: 0,
    name: "My Favorites",
    route: "/myprofile/fav",
    image: Image.sidebarMyFavrites,
  },
  // {
  //   id: 7,
  //   count: 0,
  //   name: "e-Contracting",
  //   route: "#",
  //   image: Image.eContract,
  // },
  {
    id: 8,
    count: 0,
    name: "Saved Search",
    route: "/myprofile/save",
    image: Image.sidebarSavedSearch,
  },

  // {
  //   id:9,
  //   count: 0,
  //   name: "Add.Manager",
  //   route: "#",
  //   image: Image.sidebarAddManager,
  // },
  {
    id: 10,
    count: 0,
    name: "Messages",
    route: "/myprofile/message",
    image: Image.sidebarMessages,
  },
  {
    id: 11,
    count: 0,
    name: "Offers",
    route: "/myprofile/offers",
    image: Image.sidebarOffers,
  },
  {
    id: 12,
    count: 0,
    name: "View History",
    route: "#",
    image: Image.sidebarViewhistory,
  },
  {
    id: 13,
    count: 0,
    name: "Compare Vehicles",
    route: "/myprofile/comparivehicle",
    image: Image.sidebarCompareVehicles,
  },
  {
    id: 14,
    count: 0,
    name: "My Profile",
    route: "/myprofile/customerdetails",
    image: Image.sidebarMyProfile,
  },
  {
    id: 15,
    count: 0,
    name: "Recycle Bin",
    route: "/myprofile/recyclebin",
    image: Image.sidebarRecycleBin,
  },
  {
    id: 16,
    count: 0,
    name: "Logout",
    route: "/",
    image: Image.sidebarLogout,
  },
];

import React, { useEffect, useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const SimpleChart = ({color}) => {
  const chartRef = useRef(null);

  const data = {
    labels: ["January", "February", "March", "April", "May", "June"],
    datasets: [
      {
        label: "Sales",
        data: [12, 19, 3, 5, 2, 3],
        // backgroundColor: "rgba(75, 192, 192, 0.6)",
        backgroundColor: color,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        display : false
      },
      title: {
        display: false,
        text: "Monthly Sales",
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
            display: false,
        
        },
      },
      y: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
            display: false,
          beginAtZero: true,
        },
      },
    },
  };

  return (
    <div className="simple_chart_container">
      <Bar ref={chartRef} data={data} options={options} style={{height : "100%"}}/>
    </div>
  );
};

export default SimpleChart;

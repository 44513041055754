import React from "react";
import Dealer_header from "./Dealer_header";
import Dealer_left_Section from "./Dealer_left_Section";

const Dealer_Auth_Layout = ({children, header}) => {
  console.log(header)
  return (
    <div>
      <Dealer_header  header={header}/>
      <div className="dealer_auth_hero" style={{ display: "flex" }}>
        <div style={{flex : "0.7"}}>
          <Dealer_left_Section />
        </div>
        <div style={{flex : "0.3", height : "87vh"}} > 
          {children}
        </div>
      </div>
    </div>
  );
};

export default Dealer_Auth_Layout;

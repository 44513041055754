import React, { useEffect, useRef, useState } from "react";
import "../../../cardetails/cardetail.css";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import Navbar1 from "./Navbar1";
import { FaPhoneVolume } from "react-icons/fa6";
import Image360Viewer from "../../../cardetails/Image360Viewer.jsx";
import {
  IoMdSpeedometer,
  IoLogoModelS,
  IoMdColorFill,
  IoMdInformationCircleOutline,
} from "react-icons/io";
import { CgChevronLeft, CgChevronRight, CgColorBucket } from "react-icons/cg";
import { IoCheckmarkCircle, IoShareSocial } from "react-icons/io5";
import { TbEngine, TbBrandBootstrap } from "react-icons/tb";
import { LuFuel } from "react-icons/lu";
import { GiCarDoor, GiRoad, GiGearStickPattern } from "react-icons/gi";
import { MdOutlineDateRange } from "react-icons/md";
import { FaCarSide, FaRoad } from "react-icons/fa";
import Image from "../../../imgData/index.js";

import { useSelector } from "react-redux";
import ProductData1 from "./ProductData1.js";

import {
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookShareButton,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

import axios from "axios";
import Navbar from "../../../navbars/Navbar.jsx";
// import SimilarCarCard from "./SimilarCarCard";
const apiUrl = process.env.REACT_APP_API_URL;
const imgUrl = process.env.REACT_APP_IMG_URL;

const CarDetails1 = () => {
  const { id } = useSelector((state) => state.user);
  const { roles } = useSelector((state) => state.user);
  const product = ProductData1;
  const productId = 1;
  const Navigate = useNavigate();
  const navigate = useNavigate();

  useEffect(() => {
    console.log(window.location.search || "");
    const id = productId;

    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/listing/get_lisitng/${id}`);
        console.log("Response of the specific car details => ", response);
        const data = response.data;
        // setProduct(data);
        console.log("response", response.data);
      } catch (error) {
        // setError(error); // Optional, to set error state
        console.error("Error fetching data:", error);
        // console.log("similarCars", similarCars);
      }
    };

    setImages([]);
    setCurrentImageIndex(0);
    setVideos([]);

    fetchData();
  }, []);

  // const product = state?.product;
  // console.log("single product details:-", product);
  // const carUserId = product.userId;
  //    for calculator-------------
  const [vehiclePrice, setVehiclePrice] = useState(product?.listing_price || 0);
  const [tradeInValue, setTradeInValue] = useState("0");
  const [interestRate, setInterestRate] = useState("6.85");
  const [downPayment, setDownPayment] = useState("0");
  const [loanTermInMonth, setLoanTermInMonth] = useState("48");
  const [titleRegistrationFees, setTitleRegistrationFees] = useState(0);
  const [activeButton, setActiveButton] = useState("48");
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const [loanAountData, setLoanAmountData] = useState(0);
  const [activeRate, setActiveRate] = useState(6.85); //for button bg color only----------
  const [showInput, setShowInput] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const engine = product?.engine;
  // const match = product.engine.match(/(\d+\.\d+L)/);

  // const engineSize = match ? match[1] : "N/A"; // Extract the first match or 'N/A' if no match is found
  // console.log(engineSize); // Output: "3.0L"
  // ------------------------------
  // for equipment-----------------------*********************************************----------------------------------------
  const [itemsToShow, setItemsToShow] = useState(6);
  const [startIndex, setStartIndex] = useState(0);
  // --------------------------------------------------

  useEffect(() => {
    // Scroll to top of the page when component is mounted
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures this runs once on mount

  const renderOptions = (options) => {
    const result = [];
    for (const category in options) {
      if (
        category !== "extraVehicleOptions" &&
        typeof options[category] === "object"
      ) {
        for (const feature in options[category]) {
          if (options[category][feature] === true) {
            result.push(formatFeatureName(feature));
          }
        }
      }
    }
    return result;
  };

  const renderExtraOptions = (extraOptions) => {
    return extraOptions
      .map((option) => {
        if (option.option_value === true) {
          return formatFeatureName(option.option_name);
        }
        return null;
      })
      .filter((option) => option !== null);
  };

  const formatFeatureName = (name) => {
    return name
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const handleShowMore = () => {
    setItemsToShow(itemsToShow + 6);
  };
  const vehicleOptions = product?.vehicleOptions || {};
  const extraVehicleOptions = vehicleOptions?.extraVehicleOptions || [];
  // Combine options and extra options into a single array
  const allOptions = [
    ...renderOptions(vehicleOptions),
    ...renderExtraOptions(extraVehicleOptions),
  ];

  // Function to chunk array into columns
  const chunkArray = (array, chunkSize) => {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunkedArray.push(array.slice(i, i + chunkSize));
    }
    return chunkedArray;
  };

  // Chunk allOptions into two columns
  const chunkedOptions = chunkArray(allOptions.slice(0, itemsToShow), 3);

  // equipmwnt ends-----------------------********************************************----------------------------------------
  const maskVin = (vin) => {
    return vin?.slice(0, 4) + "*".repeat(vin?.length - 4);
  };

  const maskMileageIn = () => {
    const mileage_in = product.mileage_in.toString();
    return "*".repeat(mileage_in.length);
  };

  // Helper function to get the border color
  const getBorderColor = (condition) => {
    switch (condition) {
      case "New 8/32>":
        return Image.greenTyre;
      case "Good 6/32-7/32>":
        return Image.yellowTyre;
      case "Fair <5/32":
        return Image.blueTyre;
      case "Fully-Used NA":
        return Image.redTyre;
      default:
        return Image.tireCondition; // Default color if condition is unknown
    }
  };

  const [isSticky, setIsSticky] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const debounce = (func, wait) => {
      let timeout;
      return function (...args) {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait);
      };
    };

    const debouncedStickNavbar = debounce(stickNavbar, 100);

    window.addEventListener("scroll", debouncedStickNavbar);
    return () => window.removeEventListener("scroll", debouncedStickNavbar);
  }, []);

  const stickNavbar = () => {
    if (typeof window !== "undefined") {
      let scrollPosition = window.scrollY;
      if (scrollPosition > 0) {
        setIsSticky(true);
        setIsVisible(true);
      } else {
        setIsVisible(false);
        setIsSticky(false);
      }
    }
  };


  // for calculator---------------------------
  useEffect(() => {
    calculateMonthlyPayment();
  }, [
    vehiclePrice,
    tradeInValue,
    interestRate,
    downPayment,
    loanTermInMonth,
    titleRegistrationFees,
  ]);

  const calculateMonthlyPayment = () => {
    const P = parseFloat(vehiclePrice) + parseFloat(titleRegistrationFees);
    const TIV = parseFloat(tradeInValue);
    const r = parseFloat(interestRate) / 100 / 12;
    const DP = parseFloat(downPayment);
    const n = parseInt(loanTermInMonth, 10);

    if (isNaN(P) || isNaN(TIV) || isNaN(r) || isNaN(DP) || isNaN(n)) {
      return;
    }

    const loanAmount = P - DP - TIV;
    setLoanAmountData(loanAmount);
    const M =
      (loanAmount * (r * Math.pow(1 + r, n))) / (Math.pow(1 + r, n) - 1);
    //const roundedValue = M > 0 ? Math.round(M) : Math.ceil(M);
    setMonthlyPayment(Math.ceil(M));
  };
  // bg color-----------------------------------
  const handleButtonClickb = (rate) => {
    setInterestRate(rate);
    setActiveRate(rate); // Set the active rate to the selected rate
  };

  // Function to get button styles based on whether it's active
  const getButtonStyles = (rate) => ({
    backgroundColor: activeRate === rate ? "#d1823a" : "transparent",
    color: activeRate === rate ? "white" : "black",
  });

  const handleButtonClick = (value) => {
    if (activeButton === value) {
      setActiveButton(null);
      setLoanTermInMonth("");
    } else {
      setActiveButton(value);
      setLoanTermInMonth(value);
    }
  };

  const getButtonStyle = (value) => {
    return activeButton === value ? { backgroundColor: "#d1823a" } : {};
  };
  const handleMoreButtonClick = () => {
    setShowInput(true);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyDown = (event) => {
    if (event.key === "Enter") {
      handleButtonClick(inputValue);
      setShowInput(false); // Hide input field after pressing Enter
    }
  };
  const handleInputBlur = () => {
    // Hide input field if it loses focus
    setShowInput(false);
  };

  useEffect(() => {
    setVehiclePrice(product.listing_price || "");
    const id = 33;
    console.log(id);
  }, [product]);
  // -----------------------------------------------------------------------------------
  // for images-----------------------------------------------------------------------
  // Initialize state with all images as the default
  // State variables
  //const [images, setImages] = useState([...]);
  const [showVideos, setShowVideos] = useState(false);
  const [show360, setShow360] = useState(false);
  const [videos, setVideos] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const [categoryCounts, setCategoryCounts] = useState({
    All: 0,
    Exterior: 0,
    Interior: 0,
    Others: 0,
    360: 0,
    Videos: 0,
  });

  const calculateCounts = (product) => {
    const counts = {};

    const countItems = (items) =>
      Array.isArray(items) ? items.length : items.split(",").length;

    if (product && product?.multimedia.exterior_images) {
      counts["Exterior"] = countItems(product.multimedia.exterior_images);
    }
    if (product && product?.multimedia.interior_images) {
      counts["Interior"] = countItems(product.multimedia.interior_images);
    }
    if (product && product?.multimedia.misc_images) {
      counts["Others"] = countItems(product.multimedia.misc_images);
    }
    if (product && product?.multimedia.three_sixty_images) {
      counts["360"] = countItems(product.multimedia.three_sixty_images);
    }

    if (product && product?.multimedia.all_videos) {
      counts["Videos"] = countItems(product.multimedia.all_videos);
    }

    const allImages = [
      ...(Array.isArray(product.multimedia.all_images)
        ? product.multimedia.all_images
        : product.multimedia.all_images.split(",")),
      ...(Array.isArray(product.multimedia.exterior_images)
        ? product.multimedia.exterior_images
        : product.multimedia.exterior_images.split(",")),
      ...(Array.isArray(product.multimedia.interior_images)
        ? product.multimedia.interior_images
        : product.multimedia.interior_images.split(",")),
      ...(Array.isArray(product.multimedia.misc_images)
        ? product.multimedia.misc_images
        : product.multimedia.misc_images.split(",")),
      ...(Array.isArray(product.multimedia.three_sixty_images)
        ? product.multimedia.three_sixty_images
        : product.multimedia.three_sixty_images.split(",")),
    ]
      .filter((item, index, self) => self.indexOf(item) === index)
      .filter((item) => item !== "");

    counts["All"] = allImages.length;

    return counts;
  };

  // Effect to calculate and set initial counts
  useEffect(() => {
    const counts = calculateCounts(product);
    setCategoryCounts(counts);
  }, [product]); // Depend on 'product' if it can change

  // console.log("categoryCounts", categoryCounts);
  const [images, setImages] = useState([]);
  // console.log("images", images);
  // ----for bg cat images---------------------------------------------------------------------------------
  const [imageUrls, setImageUrls] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const videoRef = useRef(null);
  console.log("Product detail => ", product);
  useEffect(() => {
    const populateImageUrls = () => {
      const categories = ["Exterior", "Interior", "Others", "360", "All"];
      const imageUrlsObj = {};
      categories.forEach((category) => {
        let images;

        switch (category) {
          case "Exterior":
            images = product?.multimedia.exterior_images;
            break;
          case "Interior":
            images = product?.multimedia.interior_images;
            break;
          case "Others":
            images = product?.multimedia.misc_images;
            break;
          case "360":
            images = product?.multimedia.three_sixty_images;
            break;
          case "All":
            images = [
              ...(Array.isArray(product?.multimedia.all_images)
                ? product.multimedia.all_images
                : product.multimedia.all_images.split(",")),
              ...(Array.isArray(product?.multimedia.exterior_images)
                ? product.multimedia.exterior_images
                : product.multimedia.exterior_images.split(",")),
              ...(Array.isArray(product?.multimedia.interior_images)
                ? product.multimedia.interior_images
                : product.multimedia.interior_images.split(",")),
              ...(Array.isArray(product?.multimedia.misc_images)
                ? product.multimedia.misc_images
                : product.multimedia.misc_images.split(",")),
              ...(Array.isArray(product?.multimedia.three_sixty_images)
                ? product.multimedia.three_sixty_images
                : product.multimedia.three_sixty_images.split(",")),
            ]
              .filter((item, index, self) => self.indexOf(item) === index)
              .filter((item) => item !== "");
            break;
          default:
            images = [];
            break;
        }

        const imagesArray = Array.isArray(images) ? images : images.split(",");
        let imageUrl;

        for (let i = 0; i < imagesArray.length; i++) {
          const image = imagesArray[i];
          if (image) {
            // imageUrl = `${imgUrl}/${
            //   product.userId
            // }/${encodeURIComponent(image)}`;
            imageUrl = image;
            console.log("437", imageUrl);
            break;
          }
        }

        imageUrlsObj[category] = imageUrl;
      });

      setImageUrls(imageUrlsObj);
    };

    populateImageUrls();
  }, [product]);

  // console.log("450 imagecat", imageUrls);
  // console.log("450 imagecat", imageUrls.All);

  // ----------------------------------------------------------------------------------------------------------------------
  const [selectedCategory, setSelectedCategory] = useState("All");
  const handleCategoryClick = (category = "ALL") => {
    let categoryImages;
    let categoryVideos;
    let count = 0;
    // console.log("category", category);
    switch (category) {
      case "Exterior":
        categoryImages = product?.multimedia.exterior_images;
        count = Array.isArray(categoryImages)
          ? categoryImages.length
          : categoryImages.split(",").length;
        break;
      case "Interior":
        categoryImages = product?.multimedia.interior_images;
        count = Array.isArray(categoryImages)
          ? categoryImages.length
          : categoryImages.split(",").length;
        break;
      case "Others":
        categoryImages = product?.multimedia.misc_images;
        count = Array.isArray(categoryImages)
          ? categoryImages.length
          : categoryImages.split(",").length;
        break;
      case "360":
        categoryImages = product?.multimedia.three_sixty_images;
        count = Array.isArray(categoryImages)
          ? categoryImages.length
          : categoryImages.split(",").length;
        break;
      case "Videos":
        categoryVideos = product?.multimedia.all_videos;
        count = Array.isArray(categoryVideos)
          ? categoryVideos.length
          : categoryVideos.split(",").length;
        break;
      case "All":
        const allImages = [
          ...(Array.isArray(product?.multimedia.all_images)
            ? product.multimedia.all_images
            : product.multimedia.all_images.split(",")),
          ...(Array.isArray(product?.multimedia.exterior_images)
            ? product.multimedia.exterior_images
            : product.multimedia.exterior_images.split(",")),
          ...(Array.isArray(product?.multimedia.interior_images)
            ? product.multimedia.interior_images
            : product.multimedia.interior_images.split(",")),
          ...(Array.isArray(product?.multimedia.misc_images)
            ? product.multimedia.misc_images
            : product.multimedia.misc_images.split(",")),
          ...(Array.isArray(product?.multimedia.three_sixty_images)
            ? product.multimedia.three_sixty_images
            : product.multimedia.three_sixty_images.split(",")),
        ]
          .filter((item, index, self) => self.indexOf(item) === index)
          .filter((item) => item !== "");
        categoryImages = allImages;
        count = allImages.length;
        break;
      default:
        categoryImages = [];
        categoryVideos = [];
        count = 0;
        break;
    }
    setSelectedCategory(category);
    setCategoryCounts((prevCounts) => ({
      ...prevCounts,
      [category]: count,
    }));

    if (category === "Videos") {
      setShowVideos(true);
      const videoUrls = Array.isArray(categoryVideos)
        ? categoryVideos.map(
          (video) =>
            `${imgUrl}/${product.userId}/${video}`
        )
        : categoryVideos
          .split(",")
          .map(
            (video) =>
              `${imgUrl}/${product.userId}/${video}`
          );
      setVideos(videoUrls);
      if (videoUrls.length > 0) {
        setSelectedVideo(videoUrls[0]);
      } else {
        setSelectedVideo(null);
      }
    } else {
      setShowVideos(false);
      const imageUrls = Array.isArray(categoryImages)
        ? categoryImages.map(
          (image) => image
          // `${imgUrl}/${product.userId}/${image}`
        )
        : categoryImages.split(",").map(
          (image) => image
          // `${imgUrl}/${product.userId}/${image}`
        );
      setImages(imageUrls);
      setCurrentImageIndex(0);
    }
  };

  const initializeVideos = () => {
    const videoUrls = product.multimedia.all_videos
      .split(",")
      .map(
        (video) => `${imgUrl}/${product.userId}/${video}`
      );
    setVideos(videoUrls);
    if (videoUrls.length > 0) {
      setSelectedVideo(videoUrls[0]);
    } else {
      setSelectedVideo(null);
    }
  };
  useEffect(() => {
    initializeVideos();
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.autoplay = true;
      videoRef.current.loop = false; // set loop to false to play each video in sequence
      videoRef.current.onended = () => {
        setCurrentIndex((currentIndex + 1) % videos.length);
      };
    }
  }, [videos, currentIndex]);
  // console.log("578videos", videos);
  useEffect(() => {
    // handleCategoryClick("All");
    // handleCategoryClick("Exterior");
    // handleCategoryClick("Interior");
    // handleCategoryClick("Others");
    // handleCategoryClick("360");
    // handleCategoryClick("Videos");
    // initializeVideos();

    // setCategoryCounts((prevCounts) => ({
    //   ...prevCounts,
    //   [category]: count,
    // }));

    if (product.multimedia) {
      const {
        all_images,
        three_sixty_images,
        exterior_images,
        misc_images,
        interior_images,
        all_videos,
      } = product.multimedia;
      if (all_images) {
        handleCategoryClick("All");
      }
      if (three_sixty_images) {
        handleCategoryClick("360");
        handleCategoryClick("All");
      }
      if (exterior_images) {
        handleCategoryClick("Exterior");
        handleCategoryClick("All");
      }
      if (interior_images) {
        handleCategoryClick("Interior");
        handleCategoryClick("All");
      }
      if (misc_images) {
        handleCategoryClick("Others");
        handleCategoryClick("All");
      }
      if (all_videos) {
        // handleCategoryClick("Videos");
      }
    }
  }, [product]);

  const handleVideoClick = (videoUrl) => {
    setSelectedVideo(videoUrl);
    setShowVideos(true);
  };

  const [selectedImage, setSelectedImage] = useState(null);

  const slideLeft = () => {
    setStartIndex((prevIndex) =>
      prevIndex < 0 ? images.length - 5 : prevIndex
    );
    handlePrevImage();
  };
  // console.log("startIndex", startIndex);

  const slideRight = () => {
    setStartIndex((prevIndex) => (prevIndex >= images.length ? 0 : prevIndex));
    handleNextImage();
  };
  // console.log("startIndex", startIndex);
  // Render videos
  const renderVideos = () => {
    const visibleVideos = videos.slice(startIndex, startIndex + 6);

    return (
      <div className="thumbnails">
        {[...Array(6)].map((_, index) => (
          <div className="thumbnail" key={index}>
            {visibleVideos[index] ? (
              <div
                className="video-thumbnail"
                onClick={() => handleVideoClick(visibleVideos[index])}
              >
                <video
                  src={visibleVideos[index]}
                  // poster="https://via.placeholder.com/125x100.png"
                  width="125"
                  height="100"
                />
              </div>
            ) : (
              <div className="empty-thumbnail"></div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const visibleImages = images.slice(startIndex, startIndex + 6);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  // console.log(visibleImages);

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrevImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const handleThumbnailClick = (index) => {
    setCurrentImageIndex(index);
  };

  // Function to display thumbnails and handle image selection
  // const renderThumbnails = () => {
  //   return (
  //     <div className="thumbnails">
  //       {[...Array(6)].map((_, index) => (
  //         <div
  //           className={`thumbnail ${currentImageIndex === index ? "active" : ""
  //             }`}
  //           key={index}
  //         >
  //           {visibleImages[index] ? (
  //             <img
  //               src={visibleImages[index]}
  //               alt={`Thumbnail ${startIndex + index + 1}`}
  //               onClick={() => handleThumbnailClick(index)}
  //             />
  //           ) : (
  //             <div className="empty-thumbnail"></div>
  //           )}
  //         </div>
  //       ))}
  //     </div>
  //   );
  // };
  const renderThumbnails = () => {
    const middleIndex = Math.floor(visibleImages.length / 2);
    const startIndex = Math.max(0, currentImageIndex - middleIndex);
    const endIndex = Math.min(visibleImages.length, startIndex + 3);

    return (
      <div className="thumbnails">
        {[...Array(6)].map((_, index) => (
          <div
            className={`thumbnail ${currentImageIndex === startIndex + index ? "active" : ""
              }`}
            key={startIndex + index}
          >
            {visibleImages[startIndex + index] ? (
              <img
                src={visibleImages[startIndex + index]}
                alt={`Thumbnail ${startIndex + index + 1}`}
                onClick={() => handleThumbnailClick(startIndex + index)}
              />
            ) : (
              <div className="empty-thumbnail"></div>
            )}
          </div>
        ))}
      </div>
    );
  };
  // ---------------------------------------------------------------------------------------------------------------------------------------------------
  const [similarCars, setSimilarCars] = useState([]);
  const sliderRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/listing/get_dealer_inventory/${product.userId}/${product.id}`
        );

        const data = response.data;
        // Ensure data is an array
        if (Array.isArray(data)) {
          setSimilarCars(data);
        } else {
          console.error("Expected an array but got:", data);
        }

        console.log("response", response.data);
      } catch (error) {
        // setError(error); // Optional, to set error state
        console.error("Error fetching data:", error);
        console.log("similarCars", similarCars);
      }
    };

    fetchData();
  }, [product]);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - sliderRef.current.offsetLeft);
    setScrollLeft(sliderRef.current.scrollLeft);
    sliderRef.current.style.cursor = "grabbing"; // Change cursor to grabbing
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const x = e.pageX - sliderRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Scroll speed multiplier
    sliderRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    sliderRef.current.style.cursor = "grab"; // Change cursor back to grab
  };

  // for single product details---------------
  const handleClick = async (car, e) => {
    // console.log("car", car);

    // e.stopPropogation();
    e.preventDefault();

    // history.push(`/cardetail/${parseInt(product.id)}`);

    Navigate(
      `/cardetail/${parseInt(car.id)}_${car.modelYear}_${car.modelMake}`,
      {
        state: { car },
      }
    );
    window.scrollTo(0, 0);
  };

  const [showSocialMedia, setShowSocialMedia] = useState(false);
  const handleShareLink = () => {
    console.log("Sharing link with the id");
    setShowSocialMedia(!showSocialMedia);
  };

  const generateShareUrl = (itemId) => {
    return `${window.location.origin}${window.location.pathname}/${itemId}`;
  };

  const scroll = (direction) => {
    const { current } = sliderRef;
    if (current) {
      const scrollAmount = direction === "left" ? -200 : 200;
      current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };
  console.log("prduct********************************", product);
  return (
    <>
      {!isSticky && <Navbar />}
      <div
        className={`px-3 header-style ${isSticky ? "sticky-nav" : "hidden-nav"} ${isVisible ? "visible" : ""
          }`}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>
            <h4 className="title text-light fw-bold title-style">
              {product?.modelYear} {product?.modelMake} {product?.makeModel}{" "}
              {product?.trim}
            </h4>
          </div>
          <div style={{ marginLeft: "11%" }}>
            <h4 style={{ color: "white" }}>
              {/* {product.mileage_in} */}
              {new Intl.NumberFormat().format(product?.mileage_in)}
              MI
            </h4>
          </div>
        </div>
        {/* <div style={{ display: "flex", flexDirection: "row"}}><span style={{ marginRight: "7px", fontWeight: "bold", fontSize: "18px",color:"white" }}><h4><sup>$</sup>{product.listing_price}</h4></span><span style={{color:"white"}}><h4> | Est.: </h4></span><span style={{color:"white"}}><h4>{monthlyPayment || 0}/m <IoMdInformationCircleOutline style={{title:"This is an Estmated Monthly payment ONLY. It is NOT a loan."}}/></h4></span></div> */}
        <div style={{ display: "flex", flexDirection: "row" }}>
          <span
            style={{
              marginRight: "7px",
              fontWeight: "bold",
              fontSize: "18px",
              color: "white",
            }}
          >
            <h4>
              <sup>$</sup>
              {/* {product.listing_price} */}
              {new Intl.NumberFormat().format(product?.listing_price)}
            </h4>
          </span>
          <span style={{ color: "white" }}>
            <h4> | Est.: </h4>
          </span>
          <span style={{ color: "white", position: "relative" }}>
            <h4>
              {new Intl.NumberFormat().format(monthlyPayment)}/m
              {/* {monthlyPayment || 0} */}
              <span className="tooltip-container">
                <IoMdInformationCircleOutline className="info-icon" />
                <div className="tooltip-text">
                  This is an Estimated Monthly payment ONLY. It is NOT a loan.
                </div>
              </span>
            </h4>
          </span>
        </div>
        {/* <div style={{ display: "flex", flexDirection: "row", gap: "10px", paddingTop: "18px" }}>
                    <span style={{color:"white"}}>Dallas</span>
                    <span className='phone-number-card-nav'>Call</span>
                </div> */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginRight: "25px",
          }}
        >
          <div style={{ paddingLeft: "32px" }}>
            <h6 className="mx-2" style={{ color: "white" }}>
              <img
                src={Image.messanger}
                id="data-car-details-img"
                alt="messenger icon"
                style={{ marginRight: "5px" }}
              />
              Check Availability
            </h6>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
            <span id="nav-text-input">
              <input placeholder="send" />
            </span>
            <span id="nav-btn-send">
              <button type="button">Send</button>
            </span>
          </div>
        </div>
      </div>
      <div>
        {/* Conditionally render the button */}
        {id === product?.userId && (
          <div className="edit-btn-car-details">
            <button type="button">
              Edit
            </button>
          </div>
        )}
      </div>
      <section className="py-5">
        <div style={{ marginLeft: "30px", marginRight: "30px" }}>
          <div className="row">
            <div
              className="col-12"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div className="col-8">
                <h2 className="title text-dark mb-4 fw-bold title-style">
                  {product?.modelYear} {product?.modelMake} {product?.makeModel}{" "}
                  {product?.trim}
                </h2>
              </div>
              <div
                className="col-4 price-container-style"
                style={{ width: "31.9%" }}
              >
                <h5 className="theme-colors price-style">
                  ${new Intl.NumberFormat().format(product?.listing_price)}
                  {/* ${product.listing_price} */}
                </h5>
                <h5 className="text-dark monthly-payment-style">
                  <span>
                    Est. ${new Intl.NumberFormat().format(monthlyPayment)} / Mo
                    {/* {monthlyPayment || 0}/m{" "} */}
                    <span className="tooltip-container">
                      <IoMdInformationCircleOutline className="info-icon" />
                      <div className="tooltip-text">
                        This is an Estimated Monthly payment ONLY. It is NOT a
                        loan.
                      </div>
                    </span>
                  </span>
                </h5>
              </div>
            </div>

            <aside className="col-lg-12" style={{ display: "flex" }}>
              <div
                className="col-lg-8 px-2"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div
                  className="col-lg-12 px-2"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <div className="category-image-car-details">
                    <span
                      onClick={
                        categoryCounts.All > 0
                          ? () => handleCategoryClick("All")
                          : undefined
                      }
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        position: "relative",
                      }}
                      className="btn-change5"
                    >
                      <img
                        src={imageUrls.All}
                        className="img-sidebar"
                        style={{
                          height: "100%",
                          width: "100%",
                          backgroundSize: "cover", // or "contain" depending on your desired behavior
                          backgroundPosition: "center",
                        }}
                      />
                      <p
                        style={{
                          display: "flex",
                          gap: "4px",
                          position: "absolute",
                        }}
                        className="cat-text-blur"
                      >
                        <p>All</p> (<p>{categoryCounts.All}</p>)
                      </p>
                    </span>

                    <span
                      onClick={
                        categoryCounts.Exterior > 0
                          ? () => handleCategoryClick("Exterior")
                          : undefined
                      }
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        cursor: !categoryCounts.Exterior && "not-allowed",
                        backgroundColor: !categoryCounts.Exterior && "#A9A9A9",
                        position: "relative",
                      }}
                      className="btn-change5"
                    >
                      <img
                        src={imageUrls.Exterior}
                        className="img-sidebar"
                        style={{
                          height: "100%",
                          width: "100%",
                          backgroundSize: "cover", // or "contain" depending on your desired behavior
                          backgroundPosition: "center",
                        }}
                      />
                      <p
                        style={{
                          display: "flex",
                          gap: "4px",
                          position: "absolute",
                        }}
                        className="cat-text-blur"
                      >
                        <p>Exterior</p> (<p>{categoryCounts.Exterior}</p>)
                      </p>
                    </span>
                    <span
                      onClick={
                        categoryCounts.Interior
                          ? () => handleCategoryClick("Interior")
                          : undefined
                      }
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        cursor: !categoryCounts.Interior && "not-allowed",
                        backgroundColor: !categoryCounts.Interior && "#A9A9A9",
                        position: "relative",
                      }}
                      className="btn-change5"
                    >
                      <img
                        src={imageUrls.Interior}
                        className="img-sidebar"
                        style={{
                          height: "100%",
                          width: "100%",
                          backgroundSize: "cover", // or "contain" depending on your desired behavior
                          backgroundPosition: "center",
                        }}
                      />
                      <p
                        style={{
                          display: "flex",
                          gap: "4px",
                          position: "absolute",
                        }}
                        className="cat-text-blur"
                      >
                        <p>Interior</p> (<p>{categoryCounts.Interior}</p>)
                      </p>
                    </span>
                    <span
                      onClick={
                        categoryCounts.Others
                          ? () => handleCategoryClick("Others")
                          : undefined
                      }
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        cursor: !categoryCounts.Others && "not-allowed",
                        backgroundColor: !categoryCounts.Others && "#A9A9A9",
                        position: "relative",
                      }}
                      className="btn-change5"
                    >
                      <img
                        src={imageUrls.Others}
                        className="img-sidebar"
                        style={{
                          height: "100%",
                          width: "100%",
                          backgroundSize: "cover", // or "contain" depending on your desired behavior
                          backgroundPosition: "center",
                        }}
                      />
                      <p
                        style={{
                          display: "flex",
                          gap: "4px",
                          position: "absolute",
                        }}
                        className="cat-text-blur"
                      >
                        <p>Others</p> (<p>{categoryCounts.Others}</p>)
                      </p>
                    </span>
                    <span
                      onClick={
                        categoryCounts["360"]
                          ? () => handleCategoryClick("360")
                          : undefined
                      }
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        cursor: !categoryCounts["360"] && "not-allowed",
                        backgroundColor: !categoryCounts["360"] && "#A9A9A9",
                        position: "relative",
                      }}
                      className="btn-change5"
                    >
                      <img
                        src={imageUrls["360"]}
                        className="img-sidebar"
                        style={{
                          height: "100%",
                          width: "100%",
                          backgroundSize: "cover", // or "contain" depending on your desired behavior
                          backgroundPosition: "center",
                        }}
                      />
                      <p
                        style={{
                          display: "flex",
                          gap: "4px",
                          position: "absolute",
                        }}
                        className="cat-text-blur"
                      >
                        <p>
                          <img src={Image.three_sixty_icon} alt="360 icon" />
                        </p>{" "}
                        (<p>{categoryCounts["360"]}</p>)
                      </p>
                    </span>
                    <span
                      onClick={
                        categoryCounts.Videos > 0
                          ? () => handleCategoryClick("Videos")
                          : undefined
                      }
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        cursor: !categoryCounts.Videos && "not-allowed",
                        backgroundColor: !categoryCounts.Videos && "#A9A9A9",
                        position: "relative",
                      }}
                      className="btn-change5"
                    >
                      <video
                        src={videos[currentIndex]}
                        width="100%"
                        height="100%"
                        autoPlay
                        muted
                      />
                      {/* <video ref={videoRef} width="100%" height="100%" style={{position:"absolute"}}>
                   <source src={videos[currentIndex]} type="video/mp4" />
                  </video> */}
                      <p
                        style={{
                          display: "flex",
                          gap: "4px",
                          position: "absolute",
                        }}
                        className="cat-text-blur"
                      >
                        <p>Videos</p> (<p>{categoryCounts.Videos}</p>)
                      </p>
                    </span>
                  </div>
                  <div className="gallery">
                    {!showVideos ? (
                      <div className="large-image-container">
                        <button
                          className="left-arrow-large"
                          onClick={handlePrevImage}
                        >
                          &#8249;
                        </button>
                        {categoryCounts.All > 0 ? (
                          selectedCategory === "360" ? (
                            <Image360Viewer images={images} />
                          ) : (
                            <img
                              id="large-image"
                              src={images[currentImageIndex]}
                              alt="Large"
                            />
                          )
                        ) : (
                          <img
                            id="large-image"
                            src={Image.defaultImage}
                            alt="Large"
                          />
                        )}
                        <button
                          className="right-arrow-large"
                          onClick={handleNextImage}
                        >
                          &#8250;
                        </button>

                        {/* <i className = "fa-regular fa-heart"></i> */}
                        <div className="product_share_like">
                          <span onClick={() => handleShareLink()}>
                            <IoShareSocial />
                          </span>
                        </div>
                        {/* Share buttons */}
                        {showSocialMedia ? (
                          <div className="social_media_container">
                            <FacebookShareButton url={generateShareUrl(id)}>
                              <FacebookIcon size={32} round />
                            </FacebookShareButton>
                            <TwitterShareButton url={generateShareUrl(id)}>
                              <TwitterIcon size={32} round />
                            </TwitterShareButton>
                            <WhatsappShareButton url={generateShareUrl(id)}>
                              <WhatsappIcon size={32} round />
                            </WhatsappShareButton>
                            <TelegramShareButton url={generateShareUrl(id)}>
                              <FacebookMessengerIcon size={32} round />
                            </TelegramShareButton>
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      <div className="large-video-container">
                        {console.log("showVideos", showVideos)}
                        {selectedVideo ? (
                          <video
                            src={selectedVideo}
                            controls
                            width="100%"
                            height="100%"
                            autoPlay
                            onEnded={() => {
                              const currentIndex =
                                videos.indexOf(selectedVideo);
                              const nextIndex =
                                (currentIndex + 1) % videos.length;
                              setSelectedVideo(videos[nextIndex]);
                            }}
                          />
                        ) : (
                          <div>No video selected</div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-12 px-2 cat-small-image-container">
                  <div
                    className="thumbnail-slider"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <button
                        className="arrow left-arrow btn-change5"
                        onClick={slideLeft}
                      >
                        {"<"}
                      </button>
                    </div>
                    {showVideos
                      ? videos.length > 0 && renderVideos()
                      : images.length > 0 && renderThumbnails()}
                    <div>
                      <button
                        className="arrow right-arrow btn-change5"
                        onClick={slideRight}
                      >
                        {">"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <section className="col-lg-4">
                <div
                  className="product-detail-sideBox"
                  style={{ height: "40%", marginLeft: "5%" }}
                >
                  <div className="row" style={{ display: "flex" }}>
                    <div
                      className="col-md-6"
                      style={{ fontSize: "17px", fontWeight: "bold" }}
                    >
                      <span>
                        {/* {product.mileage_in} */}
                        {/* {product.listing_price} */}
                        {new Intl.NumberFormat().format(product?.mileage_in)}
                        MI
                      </span>
                    </div>
                    {/* <div className='col-md-6' style={{ fontSize: "17px", paddingLeft: "43%" }}>{maskMileageIn()}MI</div> */}
                  </div>
                  <div className="row mt-4" style={{ display: "flex" }}>
                    <div className="col-md-12" style={{ fontSize: "17px" }}>
                      VIN: <span>{maskVin(product?.vin)}</span>
                    </div>
                    {/* <div className='col-md-6' style={{ fontSize: "17px", paddingLeft: "43%" }}>{maskMileageIn()}MI</div> */}
                    {/* <div className='col-md-6' style={{paddingLeft:"46px"}}><button className='text-center product-detail-boxes-btn'>VIN Request</button></div> */}
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-4 p-0 px-2 mt-md-0 my-2">
                      <div className="text-center product-detail-boxes">
                        <span>{product?.engineType}</span>
                      </div>
                    </div>
                    <div className="col-md-4 p-0 mt-md-0 my-2">
                      <div className="text-center product-detail-boxes">
                        <span>{product?.transmission}</span>
                      </div>
                    </div>
                    <div className="col-md-4 p-0 px-2 mt-md-0 my-2">
                      <div className="text-center product-detail-boxes">
                        <span>{product?.fuelType}</span>
                      </div>
                    </div>
                    <div className="col-md-4 p-0 px-2 mt-md-0 my-2">
                      <div className="text-center product-detail-boxes">
                        <span>{product?.driveType}</span>
                      </div>
                    </div>
                    <div className="col-md-4 p-0 mt-md-0 my-2">
                      <div className="text-center product-detail-boxes">
                        <span>15HP</span>
                      </div>
                    </div>
                    <div className="col-md-4 p-0 px-2 mt-md-0 my-2">
                      <div className="text-center product-detail-boxes">
                        <span>{product?.vehicleType}</span>
                      </div>
                    </div>
                  </div>

                  <div
                    className="row my-2 mt-2"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div className="col-md-5 px-0 ps-2">
                      <button className="product-detail-boxes-btn2">
                        <i className="fa-solid fa-landmark me-2"></i>Loan
                      </button>
                    </div>
                    <div className="col-md-5 px-0 pe-2">
                      <button className="product-detail-boxes-btn2">
                        <i className="fas fa-paper-plane me-2"></i>Send Offer
                      </button>
                    </div>
                  </div>

                  <div className="row"></div>
                </div>
                <div
                  className="product-detail-sideBox-b"
                  style={{ height: "31%", marginTop: "20px", marginLeft: "5%" }}
                >
                  <h2 className="title text-dark fw-bold h4 text-center">
                    <h4 className="mx-2">
                      {" "}
                      <img src={Image.messanger} id="data-car-details-img" />
                      Check Availability
                    </h4>
                  </h2>

                  <div className="row mt-3">
                    <div
                      className="col-12 my-2 mt-2"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="col-md-6">
                        <span id="nav-text-input-b">
                          <input placeholder="Hi kumar,is this still available?.." />
                        </span>
                      </div>
                      <div className="col-md-5">
                        <div className="product-detail-boxes-btn4  d-block m-auto">
                          <Link to="#" className="text-light">
                            Send message
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mt-3">
                      <div className="col-12 phone-number-card-b">
                        <span>
                          <FaPhoneVolume
                            style={{ fill: "yellow", marginBottom: "4px" }}
                          />{" "}
                          +(123)********
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </aside>

            {/* <div className='product-detail-sideBoxs mt-4' style={{ width: "90%" }}> */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="mt-5"
            >
              <div className="product-detail-boxes-userImg">
                <img
                  src="https://images.unsplash.com/photo-1585846416120-3a7354ed7d39?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHByb2Zlc3Npb25hbCUyMG1hbnxlbnwwfHwwfHx8MA%3D%3D"
                  height="100px"
                  width="100px"
                  alt=".."
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "20px",
                }}
              >
                <div>
                  <span className="fw-bold fs-4">
                    <span className="fw-bold fs-4" style={{ color: "#d1823a" }}>
                      Location:{" "}
                    </span>
                    Mckinney, Dollas TX
                  </span>
                  <span>
                    <i className="fa fa-star  text-warning me-2 fs-6"></i>
                    <i className="fa fa-star  text-warning me-2 fs-6"></i>
                    <i className="fa fa-star  text-warning me-2 fs-6"></i>
                    <i className="fa fa-star  text-warning me-2 fs-6"></i>
                    <i className="fa fa-star  text-danger me-2 fs-6"></i>
                  </span>
                  <span className="ms-1 fw-bold fs-6"> 4.3 </span>
                </div>
                <div>
                  {" "}
                  <h5 className="">
                    Get in Touch With{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {product?.user_name}
                    </span>
                  </h5>{" "}
                </div>
              </div>
            </div>
            {/* </div> */}
            <div className="row mt-5">
              {/* --------------Vehicle Specification start--------------- */}
              <div className="col-md-8">
                <div className="col-12">
                  <h2>Vehicle Specification</h2>
                </div>
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne"></h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body p-md-3 p-0">
                        <table className="table table-borderless">
                          <tbody>
                            <tr>
                              <td scope="row">
                                <MdOutlineDateRange className="svg-text-icon" />{" "}
                                Year
                              </td>
                              <th>{product?.modelYear}</th>
                              <td>
                                <TbEngine className="svg-text-icon" /> Engine
                              </td>
                              <th>{product?.engine}</th>
                            </tr>
                            <tr>
                              <td scope="row">
                                <TbBrandBootstrap className="svg-text-icon" />{" "}
                                Make
                              </td>
                              <th>{product?.modelMake}</th>
                              <td>
                                <LuFuel className="svg-text-icon" /> Fuel type
                              </td>
                              <th>{product?.fuelType}</th>
                            </tr>
                            <tr>
                              <td scope="row">
                                <IoLogoModelS className="svg-text-icon" /> Model
                              </td>
                              <th>{product?.makeModel}</th>
                              <td scope="row">
                                <GiGearStickPattern className="svg-text-icon" />{" "}
                                Transmission
                              </td>
                              <th>{product?.transmission}</th>
                            </tr>
                            <tr>
                              <td scope="row">
                                <FaCarSide className="svg-text-icon" /> Trim
                              </td>
                              <th>{product?.trim}</th>
                              <td scope="row">
                                <img
                                  src={Image.driveTrain}
                                  style={{ width: "15px", height: "15px" }}
                                />{" "}
                                Drivetrain
                              </td>
                              <th>{product?.driveTrain}</th>
                            </tr>
                            <tr>
                              <td>
                                <img
                                  src={Image.bodyType}
                                  style={{ width: "15px", height: "15px" }}
                                />{" "}
                                Body Type
                              </td>
                              <th>{product?.bodyClass}</th>
                              <td>
                                <GiCarDoor className="svg-text-icon" /> Doors
                              </td>
                              <th>{product?.doorCounts}</th>
                            </tr>
                            <tr>
                              <td scope="row">
                                <CgColorBucket className="svg-text-icon" />{" "}
                                Exterior Color
                              </td>
                              <th>{product?.exteriorColor}</th>
                              <td>
                                <IoMdSpeedometer className="svg-text-icon" />{" "}
                                Mileage
                              </td>
                              <th>
                                {/* {product?.mileage_in} */}

                                {new Intl.NumberFormat().format(
                                  product?.mileage_in
                                )}
                              </th>
                            </tr>
                            <tr>
                              <td>
                                {" "}
                                <IoMdColorFill className="svg-text-icon" />{" "}
                                Interior Color
                              </td>
                              <th>{product?.interiorColor}</th>
                              <td scope="row">
                                <FaRoad className="svg-text-icon" /> City MPG
                              </td>
                              <th>{product?.city_mpg}</th>
                            </tr>
                            <tr>
                              <td>
                                <IoCheckmarkCircle className="svg-text-icon" />{" "}
                                Condition
                              </td>
                              <th>Used</th>
                              <td>
                                <GiRoad className="svg-text-icon" /> Highway MPG
                              </td>
                              <th>{product?.highway_mpg}</th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                {/* --------------Vehicle Specification End--------------- */}

                {/* --------------Features Start--------------- */}
                <div className="row">
                  <div className="col-12 mt-3">
                    <hr style={{ color: "black", border: "2px solid black" }} />
                    <h2 className="fw-bold">Seller Discription</h2>
                    <hr style={{ color: "black", border: "2px solid black" }} />
                  </div>
                  <div className="row">
                    <div>{product.seller_description}</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mt-3">
                    <hr style={{ color: "black", border: "2px solid black" }} />
                    <h2 className="fw-bold">Highlights</h2>
                    <hr style={{ color: "black", border: "2px solid black" }} />
                  </div>
                  <div className="row">
                    <div className="col-12 highlight-section-second">
                      <div className="highlight-section-contant">
                        <div
                          className="highlight-second-text"
                          style={{ paddingLeft: "2%" }}
                        >
                          <span>
                            <img
                              src={Image.title}
                              style={{ width: "30px", height: "25px" }}
                            />
                          </span>
                          <span>Title Status</span>
                          <span style={{ fontWeight: "bold" }}>
                            {product.title_status}
                          </span>
                        </div>
                        <div
                          className="highlight-second-text"
                          style={{ paddingRight: "5%" }}
                        >
                          <span>
                            <img
                              src={Image.mileage}
                              style={{ width: "30px", height: "25px" }}
                            />
                          </span>
                          <span>Miles</span>
                          <span style={{ fontWeight: "bold" }}>
                            {/* {product.mileage_in} */}
                            {new Intl.NumberFormat().format(
                              product.mileage_in
                            )}{" "}
                            MI
                          </span>
                        </div>
                        <div
                          className="highlight-second-text"
                          style={{ paddingRight: "3%" }}
                        >
                          <span>
                            <img
                              src={Image.owner}
                              style={{ width: "30px", height: "25px" }}
                            />
                          </span>
                          <span>Owners</span>
                          <span style={{ fontWeight: "bold" }}>
                            {product.owner}
                          </span>
                        </div>
                      </div>
                      <div className="highlight-section-contant">
                        <div className="highlight-second-text">
                          <span>
                            <img
                              src={Image.damage}
                              style={{ width: "30px", height: "25px" }}
                            />
                          </span>
                          <span>Exterior Damage</span>
                          <span style={{ fontWeight: "bold" }}>
                            {product.exterior_damage}
                          </span>
                        </div>
                        <div
                          className="highlight-second-text"
                          style={{ marginRight: "45px" }}
                        >
                          <span>
                            <img
                              src={Image.suitKeys}
                              style={{ width: "30px", height: "25px" }}
                            />
                          </span>
                          <span>Keys</span>
                          <span style={{ fontWeight: "bold" }}>
                            {product.keys_count}
                          </span>
                        </div>
                        <div className="highlight-second-text">
                          <span>
                            <img
                              src={Image.green_smoke_icon}
                              style={{ width: "30px", height: "25px" }}
                            />
                          </span>
                          <span>Odor/Smoked In</span>
                          <span style={{ fontWeight: "bold" }}>
                            {product.smoked_in}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mt-3">
                    <hr style={{ color: "black", border: "2px solid black" }} />
                    <h2 className="fw-bold">Tyre Condition</h2>
                    <hr style={{ color: "black", border: "2px solid black" }} />
                  </div>
                  <div className="highlight-section-first">
                    <div className="highlighta-a">
                      <div className="highlighta-img">
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <img
                            src={Image.vectorImg}
                            style={{ transform: "scaleX(-1)" }}
                            id="bi-img-vector"
                          />{" "}
                          <img
                            src={getBorderColor(product.tyrecondition_bl)}
                            id="bi-img"
                          />
                        </div>
                        <p id="bi-text">{product.tyrecondition_bl}</p>
                      </div>
                      <div className="highlighta-img">
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <img
                            src={Image.vectorImg}
                            style={{ transform: "scaleX(-1)" }}
                            id="br-img-vector"
                          />{" "}
                          <img
                            src={getBorderColor(product.tyrecondition_br)}
                            id="br-img"
                          />{" "}
                        </div>
                        <p id="br-text">{product.tyrecondition_br}</p>
                      </div>
                    </div>
                    <div className="highlight-img">
                      <img src={Image.carImageDetails} />
                    </div>
                    <div className="highlighta-a" style={{ position: 'relative', }}>
                      <div className="highlighta-img">
                        <div
                          style={{ display: "flex", flexDirection: "column", alignItems: 'flex-end' }}
                        >
                          <img src={Image.vectorImg} id="fl-img-vector" />{" "}
                          <img
                            src={getBorderColor(product.tyrecondition_fl)}
                            id="fl-img"
                          />
                        </div>
                        <p id="fl-text">{product.tyrecondition_fl}</p>
                      </div>
                      <div className="highlighta-img">
                        <div
                          style={{ display: "flex", flexDirection: "column", alignItems: 'flex-end' }}
                        >
                          <img src={Image.vectorImg} id="fr-img-vector" />{" "}
                          <img
                            src={getBorderColor(product.tyrecondition_fr)}
                            id="fr-img"
                          />
                        </div>
                        <p id="fr-text">{product.tyrecondition_fr}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* --------------Features End--------------- */}
              {/* --------------Monthly Loan Calculator start--------------- */}
              <main className="col-md-4">
                {/* -----------------Monthly loan calculator--------*/}
                <div className="product-detail-sideBox mt-5">
                  <h5 className="text-center text-dark fw-bold text-white">
                    Est. Loan Calculator
                  </h5>
                  <div className="row">
                    <div className="col-12 est-monthly-heading mb-2 animated-box">
                      <div>
                        <h4
                          style={{
                            marginBottom: "0px",
                            marginTop: "9px",
                            fontWeight: "900",
                            color: "#006099",
                          }}
                        >
                          {/* ${monthlyPayment}{" "} */}

                          {new Intl.NumberFormat().format(monthlyPayment)}
                          <span className="tooltip-container">
                            <IoMdInformationCircleOutline className="info-icon" />
                            <div className="tooltip-text">
                              This is an Estimated Monthly payment ONLY. It is
                              NOT a loan.
                            </div>
                          </span>
                        </h4>
                      </div>
                      <div>
                        <p style={{ marginBottom: "0px" }}>
                          Est. Monthly Payment
                        </p>
                        <p>
                          Total loan amount{" "}
                          <span style={{ fontWeight: "bold" }}>
                            ${new Intl.NumberFormat().format(loanAountData)}
                            {/* ${loanAountData} */}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="view">
                        <div className="details">
                          <div
                            className="mt-2"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              color: "black",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <span>
                              <p style={{ marginBottom: "0px" }}>
                                Vehicle Price
                              </p>
                            </span>
                            <span>
                              <p style={{ marginBottom: "0px" }}></p>
                            </span>
                          </div>
                          <div>
                            <input
                              type="text"
                              value={vehiclePrice}
                              name="listing_price"
                              className="form-control"
                              placeholder="$23,434"
                              onChange={(e) => setVehiclePrice(e.target.value)}
                            />
                          </div>
                          <div
                            className="mt-2"
                            style={{
                              display: "flex",
                              color: "black",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>
                              <p style={{ marginBottom: "0px" }}>
                                Terms(Month)
                              </p>
                            </span>
                            <span>
                              <p style={{ marginBottom: "0px" }}></p>
                            </span>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <span className="btn-car-details">
                              <button
                                type="button"
                                onClick={() => handleButtonClick("36")}
                                style={getButtonStyle("36")}
                              >
                                36
                              </button>
                            </span>
                            <span className="btn-car-details">
                              <button
                                type="button"
                                onClick={() => handleButtonClick("48")}
                                style={getButtonStyle("48")}
                              >
                                48
                              </button>
                            </span>
                            <span className="btn-car-details">
                              <button
                                type="button"
                                onClick={() => handleButtonClick("60")}
                                style={getButtonStyle("60")}
                              >
                                60
                              </button>
                            </span>
                            <span className="btn-car-details">
                              <button
                                type="button"
                                onClick={() => handleButtonClick("72")}
                                style={getButtonStyle("72")}
                              >
                                72
                              </button>
                            </span>
                            <span className="btn-car-details">
                              {/* <button
                                type="button"
                                onClick={() => handleButtonClick("84")}
                                style={getButtonStyle("84")}
                              >
                                84
                              </button> */}
                            </span>
                            <span className="btn-car-details">
                              {showInput ? (
                                <input
                                  type="text"
                                  value={inputValue}
                                  onChange={handleInputChange}
                                  onKeyDown={handleInputKeyDown}
                                  onBlur={handleInputBlur}
                                  placeholder="Enter"
                                  style={{
                                    marginLeft: "10px",
                                    width: "65px",
                                    height: "40px",
                                    borderRadius: "5px",
                                  }}
                                />
                              ) : (
                                <button
                                  type="button"
                                  onClick={handleMoreButtonClick}
                                  style={getButtonStyle("more")}
                                >
                                  more
                                </button>
                              )}
                            </span>
                          </div>
                          <div
                            className="mt-2"
                            style={{
                              display: "flex",
                              color: "black",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>
                              <p style={{ marginBottom: "0px" }}>
                                Traded-in-value
                              </p>
                            </span>
                            <span>
                              <p style={{ marginBottom: "0px" }}></p>
                            </span>
                          </div>
                          <div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="$0"
                              onChange={(e) => setTradeInValue(e.target.value)}
                            />
                          </div>
                          <div
                            className="mt-2"
                            style={{
                              display: "flex",
                              color: "black",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>
                              <p style={{ marginBottom: "0px" }}>
                                Down-payment
                              </p>
                            </span>
                            <span>
                              <p style={{ marginBottom: "0px" }}></p>
                            </span>
                          </div>
                          <div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="$0"
                              onChange={(e) => setDownPayment(e.target.value)}
                            />
                          </div>
                          <div
                            className="mt-2"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              color: "black",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>
                              <p style={{ marginBottom: "0px" }}>
                                Approx. Credit Score
                              </p>
                            </span>
                            <span>
                              <p style={{ marginBottom: "0px" }}></p>
                            </span>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <span className="txt-btn-car-details">
                              <button
                                type="button"
                                style={getButtonStyles(11)}
                                onClick={() => handleButtonClickb(11)}
                              >
                                <p style={{ fontWeight: "bold" }}>Poor</p>
                                <p>630 or less</p>
                              </button>
                            </span>
                            <span className="txt-btn-car-details">
                              <button
                                type="button"
                                style={getButtonStyles(6.85)}
                                onClick={() => handleButtonClickb(6.85)}
                              >
                                <p style={{ fontWeight: "bold" }}>Fair</p>
                                <p>640 or 699</p>
                              </button>
                            </span>
                            <span className="txt-btn-car-details">
                              <button
                                type="button"
                                style={getButtonStyles(5.85)}
                                onClick={() => handleButtonClickb(5.85)}
                              >
                                <p style={{ fontWeight: "bold" }}>Good</p>
                                <p>700 or 749</p>
                              </button>
                            </span>
                            <span className="txt-btn-car-details">
                              <button
                                type="button"
                                style={getButtonStyles(4)}
                                onClick={() => handleButtonClickb(4)}
                              >
                                <p style={{ fontWeight: "bold" }}>Excellent</p>
                                <p>750 or 850</p>
                              </button>
                            </span>
                          </div>
                          <div
                            className="mt-2"
                            style={{
                              display: "flex",
                              color: "black",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>
                              <p style={{ marginBottom: "0px" }}>Est. APR</p>
                            </span>
                            <span>
                              <p style={{ marginBottom: "0px" }}></p>
                            </span>
                          </div>
                          <div className="input-container-percentage">
                            <input
                              type="text"
                              value={interestRate}
                              name="percentageData"
                              className="form-control"
                              placeholder="12"
                              onChange={(e) => setInterestRate(e.target.value)}
                            />
                            <span className="percentage-symbol">%</span>
                          </div>
                          <div
                            className="mt-2"
                            style={{
                              display: "flex",
                              color: "black",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>
                              <p style={{ marginBottom: "0px" }}>
                                Title Registration Fess & Others
                              </p>
                            </span>
                            <span>
                              <p style={{ marginBottom: "0px" }}></p>
                            </span>
                          </div>
                          <div className="input-container-percentage">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="$0"
                              onChange={(e) =>
                                setTitleRegistrationFees(e.target.value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* -----------------Value My Trade In End--------*/}
                <div
                  className="product-detail-sideBox-b"
                  style={{ height: "17%", marginTop: "20px" }}
                >
                  <h2 className="title text-dark fw-bold h4 text-center">
                    Value My Trade In
                  </h2>

                  <div className="row mt-3">
                    <div className="col-12">
                      <form>
                        <input type="text" className="form-control py-2 my-2" />
                      </form>
                    </div>
                    <div
                      className="col-12 my-2 mt-2"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="col-md-5">
                        <input
                          type="text"
                          className="form-control py-2 my-2"
                          placeholder="Enter Mileage"
                        />
                      </div>
                      <div className="col-md-5">
                        <div className="product-detail-boxes-btn4  d-block m-auto mt-3">
                          <Link to="#" className="text-light">
                            Submit
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* --------------Monthly Loan Calculator End--------------- */}
              </main>
              {/* -----------------------Standard Features End---------------------------------- */}

              {/* ------------------------------Seller Description start--------------------*/}

              {/* ------------------------------Seller Description End--------------------*/}
            </div>
            {/*---------------- equipments------------ */}
            <div className="row" style={{ marginTop: "10%" }}>
              <div className="col-md-12">
                <hr style={{ color: "black", border: "2px solid black" }} />
                <h5>
                  <h1>Equipments Features</h1>
                </h5>
              </div>
              {chunkedOptions.map((chunk, index) => (
                <div key={index} className="row">
                  {chunk.map((option, idx) => (
                    <div key={idx} className="col-md-4">
                      <ul>
                        <li>{option}</li>
                      </ul>
                    </div>
                  ))}
                </div>
              ))}
              {itemsToShow < allOptions.length && (
                <div id="add-more-show-vehicleOptions">
                  <button onClick={handleShowMore}>More...</button>
                </div>
              )}
            </div>
            {/* </section> */}
            {/*----------------------Transportion Start-------------------------*/}
            {/*----------------------Transportion End-------------------------*/}

            {/*-----------------------Ship From and image start--------------------------*/}

            {/*----------------Ship From and image End--------------------*/}

            {/*----------Vechile Market Value Start-----------*/}
            <div className="row mt-4">
              {/* <div className='col-12 text-center mt-4'><h1>VEHICLE MARKET VALUE</h1></div> */}
              <div className="col-6">
                {/* <div className="input-group mb-3 w-50 dd">
                                                <button className="btn btn-outline-secondary" type="button" id="button-addon2">VIN</button>
                                                <input type="text" className="form-control iped" placeholder="1GKEC13Z14R183668" aria-label="Recipient's username" aria-describedby="button-addon2"/>
                                                <button className="btn btn-outline-secondary" type="button" id="button-addon2">Load</button>
                                                </div> */}
                {/* <img src="image/graph-1.jpg" width="100%" alt=".." className='bacd' /> */}
              </div>

              {/* <div className="col-6 costs">
                                <h1 className='mt-3 cc'>Ownership Cost</h1>
                                <h2 className='text-center rr'>Estimate Market Value*</h2>
                                <h2 className='text-center tt'>$15,345</h2>
                            </div> */}
            </div>
            {/*-------------------Vechile Market Value End---------------------*/}

            {/*----------------Recent Viewed start----------------------*/}
            <div className="row mt-3">
              <div className="col-12">
                <hr style={{ color: "black", border: "2px solid black" }} />
                <h2>Similar Car</h2>
              </div>

              {similarCars.length > 0 ? (
                <div className="container position-relative d-flex justify-content-evenly">
                  {similarCars.length > 5 && (
                    <button
                      className="btn similar-arrow p-0 position-relative top-50 start-0 translate-middle-y z-10"
                      style={{ marginLeft: "-25px" }}
                      onClick={() => scroll("left")}
                    >
                      <CgChevronLeft size={50} />
                    </button>
                  )}

                  <div
                    ref={sliderRef}
                    className="similar-slider w-100 pb-3"
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                    onMouseLeave={handleMouseUp}
                  >
                    {Array.isArray(similarCars) ? (
                      similarCars.map((car, index) => {
                        const imageArray = car.multimedia.all_images
                          ? car.multimedia.all_images.split(",")
                          : car.multimedia.exterior_images
                            ? car.multimedia.exterior_images.split(",")
                            : car.multimedia.misc_images
                              ? car.multimedia.misc_images.split(",")
                              : car.multimedia.interior_images
                                ? car.multimedia.interior_images.split(",")
                                : car.multimedia.three_sixty_images
                                  ? car.multimedia.three_sixty_images.split(",")
                                  : [];

                        const firstImage =
                          imageArray.length > 0 ? imageArray[0] : null;

                        return (
                          <div
                            key={index}
                            className="similar-car"
                            onClick={(e) => handleClick(car, e)}
                          >
                            <div className="col-item">
                              <h5 className="col-item-name px-1">
                                {car.modelYear} {car.modelMake} {car.makeModel}
                              </h5>
                              <img
                                // src={
                                // //   firstImage
                                //     ? `${imgUrl}/${product.userId}/${firstImage}`
                                //     : Image.noImage
                                // }
                                src="https://www.hyundai.com/content/dam/hyundai/in/en/data/find-a-car/i20/Highlights/pc/i20_Modelpc.png"
                                alt={`Similar Cars`}
                                className="img-fluid"
                              />
                              <div className="w-100 car-info px-1">
                                <span className="miles">{car.mileage_in}</span>
                                <span className="price">
                                  {car.listing_price}
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <p className="text-center">No similar cars found.</p>
                    )}
                  </div>

                  {similarCars.length > 5 && (
                    <button
                      className="btn similar-arrow p-0 position-relative top-50 end-0 translate-middle-y z-10"
                      style={{ marginRight: "-50px" }}
                      onClick={() => scroll("right")}
                    >
                      <CgChevronRight size={50} />
                    </button>
                  )}
                </div>
              ) : (
                <>
                  <h5>
                    {product.user_name ? product.user_name : "This dealer"} have
                    not other listing
                  </h5>
                </>
              )}
            </div>
            {/*------------------------------Recent Viewed End------------------------------*/}
          </div>
        </div>
      </section>
    </>
  );
};

export default CarDetails1;

// SignInWithApple.js
import { auth, AppleOAuthProvider } from "./firebaseConfig";
import { signInWithPopup } from "firebase/auth";
import axios from "axios";
import { saveUser } from "../../../Redux/slice/userSlice";
import { toast } from "react-toastify";

export const getIPAddress = async () => {
  try {
    const res = await axios.get("https://api.ipify.org/?format=json");
    return res.data.ip;
  } catch (error) {
    console.error("Error fetching IP information:", error);
    return "";
  }
};

const SignInWithApple = async (dispatch, navigate, userType, isScreenSmaller = false) => {
  console.log("Is screen smaller: ", isScreenSmaller);
  try {
    const ip = await getIPAddress();
    let result;

    // Use signInWithPopup for both small and large screens
    result = await signInWithPopup(auth, AppleOAuthProvider);
    console.log("Result from popup => ", result); // Log result for big and small screens
    await handleUserResult(result.user, ip, userType, dispatch, navigate);

  } catch (error) {
    console.error("Sign in with Apple failed:", error);
    toast.error("Sign in failed. Please try again.");
  }
};

const handleUserResult = async (user, ip, userType, dispatch, navigate) => {
  const formData = new FormData();
  formData.append("email", user.email || "");
  formData.append("name", user.displayName ? user.displayName.split(" ")[0] : "");
  formData.append("ip", ip);
  formData.append("phoneNumber", user.phoneNumber || "");
  formData.append("user_type", userType);

  try {
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/user/save_user`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (res.status === 200) {
      dispatch(saveUser(res.data));
      console.log("Response666", res.data); // Log response for both screens
      if (res.data.roles === "Dealer") {
        navigate("/dealer/dashboard");
      }
    }
  } catch (error) {
    console.error("Error saving user data:", error);
    toast.error("User registration failed. Please try again.");
  }
};

export default SignInWithApple;

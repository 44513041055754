import React from "react";
import { useSelector } from "react-redux";

const DropDownListTwo = ({ responseFilter, updateFilters, filters }) => {
  const {id} = useSelector((state) => state.user);
  return (
    <>
      {/*------------------------------------------------------------------------drop down start 2----------------------------------------------------------------*/}
      <div className="accordion-item mt-5">
        <h2 className="accordion-header" id="headingNine">
          <button
            className="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseNine"
            aria-expanded="false"
            aria-controls="collapseNine"
          >
            Owners
          </button>
        </h2>
        <div
          id="collapseNine"
          className="accordion-collapse collapse"
          aria-labelledby="headingNine"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body">
            <div>
              {/* <!-- Default checkbox --> */}

              {responseFilter &&
                responseFilter?.owner?.map((person, i) => (
                  <div className="form-check" key={i}>
                    <input
                      className="form-check-input filtercar"
                      type="checkbox"
                      value={person}
                      name={person}
                      id={person}
                      onChange={(e) =>
                        updateFilters("owner", person, e.target.checked)
                      }
                    />
                    <label className="form-check-label" htmlFor={person}>
                      {person}
                    </label>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>

      <div className="accordion-item">
        <h2 className="accordion-header" id="headingEleven">
          <button
            className="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseEleven"
            aria-expanded="false"
            aria-controls="collapseEleven"
          >
            Interior Color
          </button>
        </h2>
        <div
          id="collapseEleven"
          className="accordion-collapse collapse"
          aria-labelledby="headingEleven"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body">
            <div>
              {responseFilter?.interiorColor?.map((color, i) => {
                const backgroundColor =
                  color.toLowerCase() === "Off White" ? "#FFFEFA" : color;
                return (
                  <div className="form-check d-flex align-items-center" key={i}>
                    <input
                      className="form-check-input filtercar me-2"
                      type="checkbox"
                      value={color}
                      id={`interior_color_${color}`}
                      onChange={(e) =>
                        updateFilters("interiorColor", color, e.target.checked)
                      }
                      checked={filters.interiorColor?.includes(color)}
                    />
                    <div
                      className="color-box me-2"
                      style={{
                        width: "20px",
                        height: "20px",
                        backgroundColor: backgroundColor,
                        border: "1px solid #ccc",
                        display: "inline-block",
                      }}
                    ></div>
                    <label
                      className="form-check-label"
                      htmlFor={`interior_color_${color}`}
                    >
                      {color}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingTwelve">
          <button
            className="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseTwelve"
            aria-expanded="false"
            aria-controls="collapseTwelve"
          >
            Exterior Color
          </button>
        </h2>
        <div
          id="collapseTwelve"
          className="accordion-collapse collapse"
          aria-labelledby="headingTwelve"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body">
            <div>
              {responseFilter?.exteriorColor?.map((color, i) => {
                const backgroundColor =
                  color.toLowerCase() === "Off White" ? "#FFFEFA" : color;
                return (
                  <div className="form-check d-flex align-items-center" key={i}>
                    <input
                      className="form-check-input filtercar me-2"
                      type="checkbox"
                      value={color}
                      id={`exterior_color_${color}`}
                      onChange={(e) =>
                        updateFilters("exteriorColor", color, e.target.checked)
                      }
                      checked={filters.exteriorColor?.includes(color)}
                    />
                    <div
                      className="color-box me-2"
                      style={{
                        width: "20px",
                        height: "20px",
                        backgroundColor: backgroundColor,
                        border: "1px solid #ccc",
                        display: "inline-block",
                      }}
                    ></div>
                    <label
                      className="form-check-label"
                      htmlFor={`exterior_color_${color}`}
                    >
                      {color}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingThirteen">
          <button
            className="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseThirteen"
            aria-expanded="false"
            aria-controls="collapseThirteen"
          >
            Frame Damage
          </button>
        </h2>
        <div
          id="collapseThirteen"
          className="accordion-collapse collapse"
          aria-labelledby="headingThirteen"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body">
            <div>
              {responseFilter?.exterior_damage?.map((option, i) => (
                <div className="form-check" key={i}>
                  <input
                    className="form-check-input filtercar"
                    type="radio"
                    value={option}
                    name="exterior_damage"
                    id={`exterior_damage_${option}`}
                    onChange={(e) =>
                      updateFilters("exterior_damage", option, true, true)
                    }
                    checked={
                      filters.exterior_damage &&
                      filters.exterior_damage[0] === option
                    }
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`exterior_damage_${option}`}
                  >
                    {option}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingFourteen">
          <button
            className="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseFourteen"
            aria-expanded="false"
            aria-controls="collapseFourteen"
          >
            Fuel Type
          </button>
        </h2>
        <div
          id="collapseFourteen"
          className="accordion-collapse collapse"
          aria-labelledby="headingFourteen"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body">
            <div>
              {/* <!-- Default checkbox --> */}

              {responseFilter &&
                responseFilter?.fuelType?.map((fuel, i) => (
                  <div className="form-check" key={i}>
                    <input
                      className="form-check-input filtercar"
                      type="checkbox"
                      value={fuel}
                      name={fuel}
                      id={fuel}
                      onChange={(e) =>
                        updateFilters("fuelType", fuel, e.target.checked)
                      }
                    />
                    <label className="form-check-label" htmlFor={fuel}>
                      {fuel}
                    </label>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingFifteen">
          <button
            className="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseFifteen"
            aria-expanded="false"
            aria-controls="collapseFifteen"
          >
            Interior Type
          </button>
        </h2>
        <div
          id="collapseFifteen"
          className="accordion-collapse collapse"
          aria-labelledby="headingFifteen"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body">
            <div>
              {responseFilter?.interiorType?.map((type, i) => (
                <div className="form-check" key={i}>
                  <input
                    className="form-check-input filtercar"
                    type="checkbox"
                    value={type}
                    id={`interior_type_${type}`}
                    onChange={(e) =>
                      updateFilters("interiorType", type, e.target.checked)
                    }
                    checked={filters.interiorType?.includes(type)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`interior_type_${type}`}
                  >
                    {type}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingSixteen">
          <button
            className="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseSixteen"
            aria-expanded="false"
            aria-controls="collapseSixteen"
          >
            DAC Value
          </button>
        </h2>
        <div
          id="collapseSixteen"
          className="accordion-collapse collapse"
          aria-labelledby="headingSixteen"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body">
            <div>
              {/* <!-- Default checkbox --> */}
              <div className="form-check">
                <input
                  className="form-check-input filtercar"
                  type="checkbox"
                  value=""
                  id="flexCheckChecked14"
                />
                <label className="form-check-label" htmlFor="flexCheckChecked1">
                  1
                </label>
                {/* <span className="badge badge-secondary float-end">(265)</span> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingSeventeen">
          <button
            className="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseSeventeen"
            aria-expanded="false"
            aria-controls="collapseSeventeen"
          >
            Photos
          </button>
        </h2>
        <div
          id="collapseSeventeen"
          className="accordion-collapse collapse"
          aria-labelledby="headingSeventeen"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body">
            <div>
              {responseFilter?.photos?.map((option, i) => (
                <div className="form-check" key={i}>
                  <input
                    className="form-check-input filtercar"
                    type="radio"
                    value={option}
                    name="photos"
                    id={`photos_${option}`}
                    onChange={(e) =>
                      updateFilters("photos", option, true, true)
                    }
                    checked={filters.photos && filters.photos[0] === option}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`photos_${option}`}
                  >
                    {option}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="accordion-item">
        <h2 className="accordion-header" id="headingEighteen">
          <button
            className="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseEighteen"
            aria-expanded="false"
            aria-controls="collapseEighteen"
          >
            State
          </button>
        </h2>
        <div
          id="collapseEighteen"
          className="accordion-collapse collapse"
          aria-labelledby="headingEighteen"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body">
            <div>
              {/* <!-- Default checkbox --> */}
              {responseFilter &&
                responseFilter?.state?.map((state, i) => (
                  <div className="form-check" key={i}>
                    <input
                      className="form-check-input filtercar"
                      type="checkbox"
                      value={state}
                      name={state}
                      id={state}
                      onChange={(e) =>
                        updateFilters("state", state, e.target.checked)
                      }
                    />
                    <label className="form-check-label" htmlFor={state}>
                      {state}
                    </label>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className="accordion-item"></div>
      <div className="accordion-item">
        {!id && (
          <h2 className="accordion-header" id="headingTwenty">
          <button
            className="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseTwenty"
            aria-expanded="false"
            aria-controls="collapseTwenty"
          >
            Seller
          </button>
        </h2>
        )}
        {/* <h2 className="accordion-header" id="headingTwenty">
          <button
            className="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseTwenty"
            aria-expanded="false"
            aria-controls="collapseTwenty"
          >
            Seller
          </button>
        </h2> */}
        <div
          id="collapseTwenty"
          className="accordion-collapse collapse"
          aria-labelledby="headingTwenty"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body">
            <div>
              {responseFilter?.user?.map((user, i) => (
                <div className="form-check" key={i}>
                  <input
                    className="form-check-input filtercar"
                    type="checkbox"
                    value={user.user}
                    id={`seller_${user.user}`}
                    onChange={(e) =>
                      updateFilters("seller", user.user, e.target.checked)
                    }
                    checked={filters.seller?.includes(user.user)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`seller_${user.user}`}
                  >
                    {user.name}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/*------------------------------------------------------------------------drop down End 2----------------------------------------------------------------*/}
    </>
  );
};

export default DropDownListTwo;

import React, { useState, useEffect, useRef } from "react";

const PopupTyresData = ({ radioOptions, onStateSelect }) => {
  const [selectedState, setSelectedState] = useState("");
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [lastKey, setLastKey] = useState("");
  const [lastKeyTime, setLastKeyTime] = useState(0);
  const listRef = useRef(null);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.focus();
    }
  }, []);

  const handleKeyDown = (e) => {
    if (e.key.length === 1 && e.key.match(/[a-z]/i)) {
      const currentTime = new Date().getTime();
      const key = e.key.toLowerCase();

      if (key !== lastKey || currentTime - lastKeyTime > 1000) {
        setCurrentIndex(-1);
      }

      setLastKey(key);
      setLastKeyTime(currentTime);

      const matchingOptions = radioOptions.filter((option) =>
        option.label.toLowerCase().startsWith(key)
      );

      if (matchingOptions.length > 0) {
        const newIndex = (currentIndex + 1) % matchingOptions.length;
        setCurrentIndex(newIndex);
        const optionToSelect = matchingOptions[newIndex];
        setSelectedState(optionToSelect.label);
        const optionElement = listRef.current.querySelector(
          `[data-value="${optionToSelect.label}"]`
        );
        if (optionElement) {
          optionElement.scrollIntoView({ block: "nearest" });
        }
      }
    } else if (e.key === "Enter") {
      selectState();
    }
  };

  const selectState = () => {
    if (selectedState) {
      const selectedOption = radioOptions.find(
        (option) => option.label === selectedState
      );
      onStateSelect(selectedOption.value, selectedOption.image);
    }
  };

  return (
    <div
      style={{
        width: "470px",
        height: "88%",
        overflow: "hidden",
      }}
    >
      <div
        ref={listRef}
        tabIndex="0"
        onKeyDown={handleKeyDown}
        style={{
          width: "100%",
          height: "100%",
          outline: "none",
        }}
      >
        {radioOptions.map((option) => (
          <div
            key={option.value}
            data-value={option.label}
            onClick={() => setSelectedState(option.label)}
            style={{
              padding: "5px",
              cursor: "pointer",
              backgroundColor:
                option.label === selectedState ? "#e0e0e0" : "transparent",
            }}
          >
            <label onClick={selectState}>
              <input
                type="radio"
                name="tyre_fl_option"
                value={option.value}
                style={{ marginRight: "1rem", visibility: "hidden" }}
                onChange={() => setSelectedState(option.label)}
              />
              <p
                style={{
                  display: "inline-flex",
                  width: "200px",
                  justifyContent: "start",
                  gap: "1rem",
                }}
              >
                <img
                  src={option.image}
                  alt="tyre_icon"
                  height={option.value === "Fully-Used NA" ? 34 : 40}
                  width={option.value === "Fully-Used NA" ? 34 : 40}
                />
                <span>{option.label}</span>
              </p>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PopupTyresData;

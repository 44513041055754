import React, { useEffect, useState } from "react";
import "./SellFormData.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setRetailData } from "../../../../Redux/slice/taxes&FeesSlice";
import Image from "../../../imgData";
import toast from "react-hot-toast";
import { setActiveTab } from "../../../../Redux/slice/sellSlice";
import { Modal } from "react-bootstrap";
import { saveUser } from "../../../../Redux/slice/userSlice";
const apiUrl = process.env.REACT_APP_API_URL;
const SellFromData = () => {
  const { id } = useSelector((state) => state.user);
  const { dealerId } = useSelector((state) => state.user);
  const listingId = useSelector((state) =>
    parseFloat(state.taxesAndFees.dealerRetail.listingId)
  );
  const retailFromRedux = useSelector(
    (state) => state.taxesAndFees.dealerRetail
  );
  console.log("line 12", retailFromRedux);
  const buyer = useSelector((state) => state.sell.buyer);
  const sellCoBuyer = useSelector((state) => state.sell.sellCoBuyer);
  //console.log("listingId",userId);
  const [formData, setFormData] = useState({
    userId: dealerId,
    listingId: listingId,
    id: 0,
    firstName: "",
    saleType: "Retail",
    mileageIn: "",
    lastName: "",
    middleName: "",
    city: "",
    zipCode: "",
    state: "",
    idType: "",
    idNumber: "",
    idState: "",
    address: "",
    address2: "",
    county: "",
    phoneNumber: "",
    businessName: "",
    email: "",
    created_at: "",
    isBuyerEntity: false,

    // sellPriceFeesLabels: {
    //   documentFees: retailFromRedux.documentFees,
    //   driveOutTags: retailFromRedux.driveOutTags,
    //   titleAppFees: retailFromRedux.titleAppFees,
    //   terpTitleFees: retailFromRedux.terpTitleFees,
    //   buyerTag: retailFromRedux.buyerTag,
    //   windShieldTracker: retailFromRedux.windShieldTracker,
    //   regFeeDpsl: retailFromRedux.regFeeDpsl, //Special case
    //   cntyRoadBridge: retailFromRedux.cntyRoadBridge,
    //   childSafetyFee: retailFromRedux.childSafetyFee,
    //   inspectionFee: retailFromRedux.inspectionFee,
    //   stateInspectionFee: retailFromRedux.stateInspectionFee,
    //   procHandlingFee: retailFromRedux.procHandlingFee,
    //   cardFeesl: retailFromRedux.cardFeesl,
    //   serviceContractl: retailFromRedux.serviceContractl,
    //   gapInsurancel: retailFromRedux.gapInsurancel,
    //   mailDeliveryFeesl: retailFromRedux.mailDeliveryFeesl,
    //   transportationFeesl: retailFromRedux.transportationFeesl,
    // },
    lienHolder: {
      leanHolderId: 0,
      lienHolderName: "",
      leinHolderID: "",
      leinHolderAddress: "",
      leinHolderAddress2: "",
    },
    serviceContract: {
      serviceContractId: 0,
      serviceContractName: "",
      serviceContractLicense: "",
      serviceProviderMonths: "",
      serviceContractMiles: "",
      serviceContractAddress: "",
      serviceProviderActivationStatus: "",
      serviceProviderType: "",
      providerPhoneNumber: "",
      providerCounty: "",
      providerListingExpiry: "",
      serviceProviderMonths: "",
      serviceContractMiles: "",
    },
    gapInsurance: {
      gapInsuranceId: 0,
      gapName: "",
      gapMonths: "",
      gapMiles: "",
      gapLicense: "",
      gapAddress: "",
      gapActivationStatus: "",
      gapType: "",
      gapPhoneNumber: "",
      gapCounty: "",
      gapListingExpiry: "",
      gapMonths: "",
      gapMiles: "",
    },
    sellPriceFees: {
      salesTaxes: 0, //
      mInventoryTax: 0, //

      totalFees: 0, //
      totalFeesAndothers: 0, //

      listingPrice: 0, //
      // txtTitleType: 0,

      cashDifference: 0, //
      paymentPriceFees: 0, //
      priceFeeDate: "", //
      priceFeesPaymentMethod: "", //
      saleTaxValue: "", //
      mInventoryValue: "", //
      balanceDue: 0, //
      totalDue: 0,
      sellPriceFeeItems: [],
    },
    sellCoBuyer: {
      firstName: "",
      lastName: "",
      middleName: "",
      businessName: "",
      idType: "",
      idNumber: "",
      idState: "",
      address: "",
      address2: "",
      county: "",
      phoneNumber: "",
      email: "",
      isCoBuyerEntity: false,
      city: "",
      zipCode: "",
      state: "",
    },
    addListing: {
      id: 0,
      userId: dealerId,
      listingStatus: "Available",
      vin: "",
      lattitude: 0,
      longitude: 0,
      ip: "",
      purchasedDate: "",
      inspection: "",
      purchased_from: "",
      buyer_name: "",
      // stock_no: "",
      condition: "Used",
      year_make_model: "",
      // make: "",
      // make year model only use for backend-------------
      make: "",
      year: "",
      model: "",
      bodyClass: "",
      wheelbase: "",
      driveType: "",
      doorCount: "",
      // -----------------------------
      testp: "",
      // model: "",
      vehicleType: "",
      engine: "",
      transmission: "",
      mileage_in: "",
      // mileage_read: "",
      // engine_type: "",
      horse_power: "",
      trim: "",
      // transmission_speed: "",
      exteriorColor: "",
      interiorColor: "",
      seat_count: "",
      door_count: "",
      drive_train: "",
      interiorType: "",
      frame_damage: "",
      keys_count: "",
      accident: "",
      battery_kwh: "",
      battery_type: "",
      city_mpg: "",
      highway_mpg: "",
      price_fee_date: "",
      // payment_method: "",
      purchase_price: "",
      auction_fees: "",
      market_value_price: "",
      listing_price: "",
      total_repair_cost: "",
      flooring_fee: "",
      commission: "",
      others: "",
      total_cost: "",
      profit_price: "",
      title: "Clean",
      odo: "",
      owner: "",
      exterior_damage: "",
      state: "",
      status: "",
      smoked_in: "",
      tyrecondition_fl: "",
      tyrecondition_fr: "",
      tyrecondition_bl: "",
      tyrecondition_br: "",
      seller_description: "",
      // Title details section-------------
      title_status: "",
      title_type: "",
      title_number: "",
      title_recieved: "",
      title_location: "",
      other_restraint: "",

      vehicleOptions: {
        safetyFeatures: {
          anti_lock_brake_system: false,
          front_airbag: false,
          side_airbags: false,
          curtain_airbag: false,
          back_up_camera: false,
          blind_spot_monitoring: false,
          parking_sensors: false,
          brake_assist: false,
          collision_mitigation_system: false,
          cross_traffic_alert: false,
          daytime_running_lights: false,
          electronic_stability_control: false,
          forward_collision_warning: false,
          hill_descent_control: false,
          hill_start_assist: false,
          lane_departure_warning: false,
          lane_keeping_assist: false,
          rear_cross_traffic_alert: false,
          tyre_pressure_monitoring: false,
          traction_control: false,
        },
        infotainment: {
          apple_carplay_android_auto: false,
          bluetooth_connectivity: false,
          incar_wifi: false,
          navigation_system: false,
          premium_sound_system: false,
        },
        comfortAndConvenience: {
          adaptive_cruise_control: false,
          auto_dimming_rearview_mirror: false,
          auto_climate_control: false,
          heated_ventilated_seats: false,
          key_less_entry: false,
          key_less_go: false,
          memory_seats: false,
          leather_seats: false,
          multizone_climate_control: false,
          panormic_sunroof: false,
          power_adjustable_seats: false,
          power_liftgate: false,
          power_window_mirrors: false,
          remote_starter: false,
          smart_trunk_opener: false,
          sunshades: false,
          tilt_telescopic_steering_wheel: false,
        },

        exteriorFeatures: {
          alloy_wheels: false,
          automatic_headlights: false,
          fog_lights: false,
          led_headlights: false,
          power_folding_mirrors: false,
        },
        performanceAndHandling: {
          adaptive_suspension: false,
          all_wheels_drive: false,
          drive_mode_selector: false,
          sport_tuned_suspension: false,
          limited_slip_differential: false,
        },
        interiorFeatures: {
          ambient_lighting: false,
          cargo_cover: false,
          cargo_net: false,
          center_console_armrest: false,
          heated_steering_wheels: false,
        },
        driveAssistance: {
          adaptive_headlights: false,
          automatic_high_beams: false,
          driver_drowsiness_monitoring: false,
          head_up_display: false,
          night_vision: false,
        },
        energyAndEquipment: {
          engine_start_stop_system: false,
          hybrid_powertrain: false,
          regenerative_breaking: false,
          solar_roof: false,
        },
        extraVehicleOptions: [],
      },
      // -----------------------------------
      floor_fee: "",
      repair_cost: "",
      addvertising: {
        dacmp_status: true,
        dacmp_interval: "",
        unitedmotors_status: true,
        unitedmotors_interval: "",
        google_status: false,
        google_interval: "",
        insta_status: false,
        insta_interval: "",
        facebook_status: false,
        facebook_interval: "",
        craigslist_status: false,
        craigslist_interval: "",
        twitter_status: false,
        twitter_interval: "",
      },
    },
  });
  useEffect(() => {
    console.log("listingId", listingId);
    setFormData((prevState) => ({
      ...prevState,
      listingId: listingId,
    }));
  }, [listingId]);
  // ------------------------------------------send data of redux to api-------------------------
  useEffect(() => {
    if (retailFromRedux) {
      setFormData((prevState) => ({
        ...prevState,
        addListing: {
          id: 0,
          userId: dealerId,
          vin: retailFromRedux.vin,
          listingStatus: "Available",
          year_make_model: retailFromRedux.year_make_model,
          // make year model check purpose-------
          make: retailFromRedux.make,
          year: retailFromRedux.year,
          model: retailFromRedux.model,
          bodyClass: retailFromRedux.bodyClass,
          wheelbase: retailFromRedux.wheelbase,
          driveType: retailFromRedux.driveType,
          doorCount: retailFromRedux.doorCount,
          // -----------------------------
          trim: retailFromRedux.trim,
          engine: retailFromRedux.engine,
          transmission: retailFromRedux.transmission,
          vehicleType: retailFromRedux.vehicleType,
          seat_count: retailFromRedux.seat_count,
          city_mpg: retailFromRedux.city_mpg,
          highway_mpg: retailFromRedux.highway_mpg,
          inspection: retailFromRedux.inspection,
          exteriorColor: retailFromRedux.exteriorColor,
          interiorColor: retailFromRedux.interiorColor,
          interiorType: retailFromRedux.interiorType,
          mileage_in: retailFromRedux.mileage_in,
          purchase_price: retailFromRedux.purchase_price,
          //tradeInValue: formData.tradeInValue,
          listing_price: retailFromRedux.listing_price,
        },
      }));
    }
  }, [retailFromRedux]);
  // -------------------------------------------------liean holder-------
  useEffect(() => {
    if (retailFromRedux) {
      setFormData((prevState) => ({
        ...prevState,
        lienHolder: {
          leanHolderId: retailFromRedux.leanHolderId || 0,
          lienHolderName: retailFromRedux.lienHolderName,
          leinHolderID: retailFromRedux.lienHolderID,
          leinHolderAddress: retailFromRedux.lienHolderAddress,
          leinHolderAddress2: retailFromRedux.lienHolderAddress2,
        },
      }));
    }
  }, [retailFromRedux]);

  // -------------------------------service contract------------------------
  useEffect(() => {
    if (retailFromRedux) {
      setFormData((prevState) => ({
        ...prevState,
        serviceContract: {
          serviceContractId: retailFromRedux.serviceContractId || 0,
          serviceContractName: retailFromRedux.serviceContractName,
          serviceContractLicense: retailFromRedux.serviceContractLicense,
          serviceContractAddress: retailFromRedux.serviceContractAddress,
          serviceProviderActivationStatus:
            retailFromRedux.serviceProviderActivationStatus,
          serviceProviderType: retailFromRedux.serviceProviderType,
          providerPhoneNumber: retailFromRedux.providerPhoneNumber,
          providerCounty: retailFromRedux.providerCounty,
          providerListingExpiry: retailFromRedux.providerListingExpiry,
          serviceProviderMonths: retailFromRedux.serviceProviderMonths,
          serviceContractMiles: retailFromRedux.serviceContractMiles,
        },
      }));
    }
  }, [retailFromRedux]);
  // -----------------------------------------------------------gap insurance----------------------------
  useEffect(() => {
    if (retailFromRedux) {
      setFormData((prevState) => ({
        ...prevState,
        gapInsurance: {
          gapInsuranceId: retailFromRedux.gapInsuranceId || 0,
          gapName: retailFromRedux.gapName,
          gapLicense: retailFromRedux.gapLicense,
          gapAddress: retailFromRedux.gapAddress,
          gapActivationStatus: retailFromRedux.gapActivationStatus,
          gapType: retailFromRedux.gapType,
          gapPhoneNumber: retailFromRedux.gapPhoneNumber,
          gapCounty: retailFromRedux.gapCounty,
          gapListingExpiry: retailFromRedux.gapListingExpiry,
          gapMonths: retailFromRedux.gapMonths,
          gapMiles: retailFromRedux.gapMiles,
        },
      }));
    }
  }, [retailFromRedux]);

  // -------------------------------------------------------
  useEffect(() => {
    if (retailFromRedux) {
      setFormData((prevState) => ({
        ...prevState,
        id: 0,
        saleType: retailFromRedux.txtTitleType || "",
        mileageIn: retailFromRedux.mileageIn || "",
        sellPriceFees: {
          salesTaxes: Number(retailFromRedux.salesTaxes) || 0,
          mInventoryTax: Number(retailFromRedux.mInventoryTax) || 0,
          totalFees: Number(retailFromRedux.totalFees) || 0,
          totalFeesAndothers: Number(retailFromRedux.totalFeesAndothers) || 0,
          listingPrice: Number(retailFromRedux.userListingPrice) || 0,
          // txtTitleType: retailFromRedux.txtTitleType || 0,
          ownedTrade: Number(retailFromRedux.ownedTrade) || 0,
          cashDifference: Number(retailFromRedux.cashDifference) || 0,
          paymentPriceFees: Number(retailFromRedux.paymentPriceFees) || 0,
          priceFeeDate: retailFromRedux.date || "",
          priceFeesPaymentMethod: retailFromRedux.priceFeesPaymentMethod || "",
          saleTaxValue: Number(retailFromRedux.saleTaxValue) || 0,
          mInventoryValue: Number(retailFromRedux.mInventoryValue) || 0,
          balanceDue: Number(retailFromRedux.balanceDue) || 0,
          totalDue: Number(retailFromRedux.totalDue) || 0,
        },
      }));
    }
  }, [retailFromRedux]);
  const [isBuyerAvailable, setIsBuyerAvailable] = useState(false);
  console.log("isBuyerAvailable ", isBuyerAvailable);
  useEffect(() => {
    if (buyer) {
      setFormData((prevState) => ({
        ...prevState,
        firstName: buyer.firstName,
        lastName: buyer.lastName,
        middleName: buyer.middleName,
        businessName: buyer.businessName,
        idType: buyer.idType,
        idNumber: buyer.idNumber,
        idState: buyer.idState,
        address: buyer.address,
        address2: buyer.address2,
        county: buyer.county,
        phoneNumber: buyer.phoneNumber,
        email: buyer.email,
        isBuyerEntity: buyer.isBuyerEntity,
        city: buyer.city,
        zipCode: buyer.zipCode,
        state: buyer.state,
      }));
      if (
        buyer.idType &&
        buyer.idNumber &&
        buyer.idState &&
        buyer.address &&
        buyer.county &&
        buyer.phoneNumber &&
        buyer.email &&
        buyer.city &&
        buyer.zipCode &&
        buyer.state
      ) {
        if (buyer.isBuyerEntity) {
          if (buyer.businessName) {
            setIsBuyerAvailable(true);
          } else {
            setIsBuyerAvailable(false);
          }
        } else {
          if (buyer.firstName && buyer.lastName) {
            setIsBuyerAvailable(true);
          } else {
            setIsBuyerAvailable(false);
          }
        }
      } else {
        setIsBuyerAvailable(false);
      }
    }
  }, [buyer]);
  useEffect(() => {
    if (sellCoBuyer) {
      setFormData((prevState) => ({
        ...prevState,
        sellCoBuyer: {
          firstName: sellCoBuyer.firstName,
          lastName: sellCoBuyer.lastName,
          middleName: sellCoBuyer.middleName,
          idType: sellCoBuyer.idType,
          idNumber: sellCoBuyer.idNumber,
          businessName: sellCoBuyer.businessName,
          idState: sellCoBuyer.idState,
          address: sellCoBuyer.address,
          address2: sellCoBuyer.address2,
          county: sellCoBuyer.county,
          phoneNumber: sellCoBuyer.phoneNumber,
          email: sellCoBuyer.email,
          isCoBuyerEntity: sellCoBuyer.isCoBuyerEntity,
          city: sellCoBuyer.city,
          zipCode: sellCoBuyer.zipCode,
          state: sellCoBuyer.state,
        },
      }));

      if (buyer.isCoBuyerActive) {
        if (sellCoBuyer.isCoBuyerEntity) {
          if (sellCoBuyer.businessName) {
            setIsBuyerAvailable(true);
          } else {
            setIsBuyerAvailable(false);
          }
        } else {
          if (sellCoBuyer.firstName && sellCoBuyer.lastName) {
            setIsBuyerAvailable(true);
          } else {
            setIsBuyerAvailable(false);
          }
        }
      }
    }
  }, [sellCoBuyer]);

  //
  const SellPriceInputs = useSelector(
    (state) => state.taxesAndFees.dealerRetail.SellPriceInputs
  );
  useEffect(() => {
    if (retailFromRedux) {
      setFormData((prevState) => ({
        ...prevState,
        sellPriceFees: {
          ...prevState.sellPriceFees,
          sellPriceFeeItems:
            SellPriceInputs &&
            SellPriceInputs?.map((item) => ({
              itemName: item.label,
              itemValue: Number(item.InputValue),
            })),
        },
      }));
    }
  }, [retailFromRedux]);
  //for labels of the price and fees
  // useEffect(() => {
  //   if (retailFromRedux) {
  //     setFormData((prevState) => ({
  //       ...prevState,
  //       sellPriceFeesLabels: {
  //         documentFees: retailFromRedux.documentFees,
  //         driveOutTags: retailFromRedux.driveOutTags,
  //         titleAppFees: retailFromRedux.titleAppFees,
  //         terpTitleFees: retailFromRedux.terpTitleFees,
  //         buyerTag: retailFromRedux.buyerTag,
  //         windShieldTracker: retailFromRedux.windShieldTracker,
  //         regFeeDpsl: retailFromRedux.regFeeDpsl, //Special case
  //         cntyRoadBridge: retailFromRedux.cntyRoadBridge,
  //         childSafetyFee: retailFromRedux.childSafetyFee,
  //         inspectionFee: retailFromRedux.inspectionFee,
  //         stateInspectionFee: retailFromRedux.stateInspectionFee,
  //         procHandlingFee: retailFromRedux.procHandlingFee,
  //         cardFeesl: retailFromRedux.cardFeesl,
  //         serviceContractl: retailFromRedux.serviceContractl,
  //         gapInsurancel: retailFromRedux.gapInsurancel,
  //         mailDeliveryFeesl: retailFromRedux.mailDeliveryFeesl,
  //         transportationFeesl: retailFromRedux.transportationFeesl,
  //       },
  //     }));
  //   }
  // }, [retailFromRedux]);
  // for buyer and cobyer-----------------------------------
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [sellForm, setSellForm] = useState([]);
  console.log("sellForm", sellForm);
  useEffect(() => {
    dispatch(
      setRetailData({
        sellForm: sellForm,
      })
    );
  }, [sellForm]);
  // for form to save data to craete sell form---------------------------
  const [showBalance, setShowBalance] = useState(false);
  useEffect(() => {
    if (showBalance) {
      // dispatch(saveUser({ isSideBarOpen: false }));
    }
  }, [showBalance]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!retailFromRedux.userListingPrice) {
      setShowBalance(true); // Show the balance or whatever indication you want
      return; // Stop further execution of the function
    }
    setLoading(true); // Start loading

    const fnd = new FormData();
    fnd.append("sell", JSON.stringify(formData));

    try {
      const response = await axios.post(`${apiUrl}/sell/generate_form`, fnd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("Data saved successfully:", response.data);
      setSellForm(response.data);
      if (response.status === 200) {
        dispatch(setActiveTab("Forms"));
      }
      toast.success("Form generated successfully");
    } catch (error) {
      console.error("Error saving form data:", error);
      // toast.error(error.response.data);
      toast.error(
        "Please ensure all data is entered accurately and in the correct format."
      );
    } finally {
      setLoading(false); // End loading regardless of success or failure
      setShowBalance(false);
    }
  };

  const handleBalanceDuePopout = () => {
    setShowBalance(true);
  };
  return (
    <div
      className="sell_data_form"
      style={{ display: "flex", flexDirection: "column" }}
    >
      {!isBuyerAvailable && (
        <p
          onClick={() => dispatch(setActiveTab("Buyer"))}
          className="emptyDataMessage"
        >
          Please fill in all the buyer details to enable this button.
        </p>
      )}
      <button
        type="button"
        onClick={
          retailFromRedux.balanceDue !== 0
            ? handleBalanceDuePopout
            : handleSubmit
        }
        disabled={loading || !isBuyerAvailable}
        style={{
          cursor: !isBuyerAvailable && "not-allowed",
          backgroundColor: !isBuyerAvailable && "lightgray",
        }}
      >
        {loading ? "Processing.." : "Generate Form"}
      </button>
      <Modal show={showBalance} onHide={() => setShowBalance(false)} size="lg">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {retailFromRedux?.userListingPrice ? (
            <>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "larger",
                  fontWeight: "500",
                }}
              >
                There is a balance due on this inventory. Do you want to
                continue?
              </p>
              <p style={{ display: "flex", justifyContent: "space-evenly" }}>
                <button
                  style={{
                    color: "black",
                    border: "none",
                    fontSize: "larger",
                    fontWeight: "500",
                    width: "20%",
                    padding: "0.3rem 0",
                  }}
                  onClick={() => setShowBalance(false)}
                >
                  No
                </button>
                <button
                  style={{
                    color: "white",
                    backgroundColor: "#d1823a",
                    border: "none",
                    fontSize: "larger",
                    fontWeight: "500",
                    width: "20%",
                    padding: "0.3rem 0",
                  }}
                  onClick={handleSubmit}
                >
                  {loading ? "Processing.." : "Yes"}
                </button>
              </p>
            </>
          ) : (
            <h1 style={{ color: "red" }}>Please Fill The Sale Price......</h1>
          )}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
};

export default SellFromData;

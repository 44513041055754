import React from "react";
import { FaCheck } from "react-icons/fa";

const DealerCredibility = () => {
  return (
    <div className="dealer_credibility_wrapper">
      <div className="dealer_credibility_container">
        <p className="credibility_header">WHO WE ARE</p>
        <div className="credibility_areas">
          <p>
            <span className="check_icon">
              <FaCheck />
            </span>
            <span className="credibility_areas_text">
              <span>Dealer</span> <span>Designed</span>
            </span>
          </p>
          <p>
            <span className="check_icon">
              <FaCheck />
            </span>
            <span className="credibility_areas_text">
              <span>Dealer</span> <span>Choice</span>
            </span>
          </p>
          <p>
            <span className="check_icon">
              <FaCheck />
            </span>
            <span className="credibility_areas_text">
              <span>Dealer</span> <span>Approved</span>
            </span>
          </p>
          <p>
            <span className="check_icon">
              <FaCheck />
            </span>
            <span className="credibility_areas_text">
              <span>Dealer</span> <span>Built</span>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default DealerCredibility;

import axios from "axios";
import { gapi } from "gapi-script";
import { saveUser } from "../../../Redux/slice/userSlice";

const REACT_PUBLIC_GOOGLE_CLIENT_ID =
  "934964841699-dv076mu15tjh9tvgcrg53c9sqjrgab4r.apps.googleusercontent.com";
const GOOGLE_CLIENT_PLUGIN_NAME = "DAC OAUTH";

let isInitialized = false;

export const initializeGoogleSignIn = () => {
  return new Promise((resolve, reject) => {
    if (isInitialized) {
      resolve();
      return;
    }

    function start() {
      gapi.client
        .init({
          clientId: REACT_PUBLIC_GOOGLE_CLIENT_ID,
          scope: "email",
          plugin_name: GOOGLE_CLIENT_PLUGIN_NAME,
        })
        .then(() => {
          if (gapi.auth2.getAuthInstance()) {
            console.log("Google Sign-In initialized successfully");
            isInitialized = true;
            resolve();
          } else {
            console.error(
              "Google Sign-In initialization failed: Auth instance not available"
            );
            reject(new Error("Auth instance not available"));
          }
        })
        .catch((error) => {
          console.error("Failed to initialize Google Sign-In:", error);
          reject(error);
        });
    }

    if (typeof gapi !== "undefined" && gapi.load) {
      gapi.load("client:auth2", start);
    } else {
      reject(new Error("Google API not loaded"));
    }
  });
};

const getIPAddress = async () => {
  try {
    const res = await axios.get("https://api.ipify.org/?format=json");
    return res.data.ip;
  } catch (error) {
    console.error("Error fetching IP information:", error);
    return "";
  }
};

export const handleGoogleSignIn = async (dispatch, navigate, userType) => {
  try {
    await initializeGoogleSignIn();

    const ip = await getIPAddress();

    if (!gapi.auth2) {
      throw new Error("Google Sign-In not initialized");
    }

    const googleUser = await gapi.auth2.getAuthInstance().signIn();
    const profile = googleUser.getBasicProfile();

    console.log("User signed in:", profile.getName());
    console.log("User email:", profile.getEmail());

    const formData = new FormData();
    formData.append("email", profile.getEmail() || "");
    formData.append(
      "name",
      profile.getName() ? profile.getName().split(" ")[0] : ""
    );
    formData.append("ip", ip);
    formData.append("user_type", userType);

    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/user/save_user`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (res.status === 200) {
      dispatch(
        saveUser({
          email: res.data.email,
          id: res.data.id,
          dealerId: res.data.dealerId,
          firstName: res.data.firstName,
          lastName: res.data.lastName,
          roles: res.data.roles,
          loginAccess: res.data.loginAccess,
          dealerApproval: res.data.dealerApproval,
          dealerData:res.data.dealerData,
        })
      );

      if (res.data.roles === "Dealer") {
        navigate("/dealer/dashboard");
      }

      return {
        name: profile.getName(),
        email: profile.getEmail(),
      };
    } else {
      throw new Error("User save failed");
    }
  } catch (error) {
    console.error("Detailed sign-in error:", JSON.stringify(error, null, 2));
    throw error;
  }
};

export default function GoogleSignIn() {
  return null;
}

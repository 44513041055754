import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Row,
  Table,
  OverlayTrigger,
  Tooltip,
  Modal,
} from "react-bootstrap";
import { MdModeEdit, MdDelete, MdOutlineShare } from "react-icons/md";
// import AdvertisingProfile from "./AdvertisingProfile";
import Image from "../../../imgData";
import styles from "../../DealerInventory/DealerSoldInventory/sold.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveTab,
  setCoBuyerSellData,
  setSelectedVehicle,
  setSelectedVehicleOrigin,
  setSelectedVehiclePopOut,
  setSellData,
} from "../../../../Redux/slice/sellSlice";
import axios from "axios";
import { setRetailData } from "../../../../Redux/slice/taxes&FeesSlice";
import SellData from "../activeInventory/SellData";
const apiUrl = process.env.REACT_APP_API_URL;
const imgUrl = process.env.REACT_APP_IMG_URL;

const SellDraftCard = ({
  data,
  index,
  selectedCards,
  handleSelected,
  fetchData,
  onUpdate,
  setIsDeleted,
}) => {
  const { id } = useSelector((state) => state.user);
  const { dealerId } = useSelector((state) => state.user);
  const [show, setShow] = useState(false);
  const [socialIcons, setSocialIcons] = useState([]);
  const [isProChecked, setIsProChecked] = useState(true);
  const [advertisingPro, setAdvertisingPro] = useState({
    // listing_id: data.id,
    dacmp_status: true,
    dacmp_interval: "",
    unitedmotors_status: true,
    unitedmotors_interval: "",
    google_status: false,
    google_interval: "",
    insta_status: false,
    insta_interval: "",
    facebook_status: false,
    facebook_interval: "",
    craigslist_status: false,
    craigslist_interval: "",
    twitter_status: false,
    twitter_interval: "",
  });
  console.log("data of the current product draftaction=> ", data);
  // const handleAddsPro = (e) => {
  //   e.stopPropagation();
  //   setShow(true);
  //   console.log("seting Profils");
  //   if (data && data.addvertising) {
  //     setAdvertisingPro((prev) => ({
  //       ...prev,
  //       dacmp_interval: data.addvertising.dacmp_interval ?? prev.dacmp_interval,
  //       unitedmotors_interval:
  //         data.addvertising.unitedmotors_interval ?? prev.unitedmotors_interval,
  //       google_status: data.addvertising.google_status,
  //       google_interval:
  //         data.addvertising.google_interval ?? prev.google_interval,
  //       insta_status: data.addvertising.insta_status,
  //       insta_interval: data.addvertising.insta_interval ?? prev.insta_interval,
  //       facebook_status: data.addvertising.facebook_status,
  //       facebook_interval:
  //         data.addvertising.facebook_interval ?? prev.facebook_interval,
  //       craigslist_status: data.addvertising.craigslist_status,
  //       craigslist_interval:
  //         data.addvertising.craigslist_interval ?? prev.craigslist_interval,
  //       twitter_status: data.addvertising.twitter_status,
  //       twitter_interval:
  //         data.addvertising.twitter_interval ?? prev.twitter_interval,
  //     }));
  //   }
  // };

  const handleCheckboxChange = (e, intervalName) => {
    const { name, checked } = e.target;
    // setAdvertisingPro((prev) => ({ ...prev, [name]: checked }));

    // Reset the interval if the checkbox is unchecked
    const resetInterval = !checked ? "" : advertisingPro[intervalName];

    setAdvertisingPro((prev) => ({
      ...prev,
      [name]: checked,
      [intervalName]: resetInterval,
    }));
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setAdvertisingPro((prev) => ({ ...prev, [name]: value }));
  };

  const resetCheckboxes = () => {
    setAdvertisingPro({
      listing_id: data.id,
      dacmp_status: true,
      // dacmp_interval: "",
      unitedmotors_status: true,
      // unitedmotors_interval: "",
      google_status: false,
      google_interval: "",
      insta_status: false,
      insta_interval: "",
      facebook_status: false,
      facebook_interval: "",
      craigslist_status: false,
      craigslist_interval: "",
      twitter_status: false,
      twitter_interval: "",
    });
  };

  // Handle image toggle state for all profiles
  const [connectionStatus, setConnectionStatus] = useState({
    dacmp_connected: false,
    unitedmotors_connected: false,
    google_connected: false,
    insta_connected: false,
    facebook_connected: false,
    craigslist_connected: false,
    twitter_connected: false,
  });

  const toggleConnectionStatus = (profileName) => {
    setConnectionStatus((prevStatus) => ({
      ...prevStatus,
      [profileName]: !prevStatus[profileName],
    }));
  };

  // Function to post advertisingPro data
  const updateAdvertisement = async () => {
    try {
      console.log(advertisingPro);
      // console.log('Payload being sent:', JSON.stringify(advertisingPro, null, 2));

      const response = await fetch(`${apiUrl}/listing/update_advertisement`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(advertisingPro),
      });

      if (response.ok) {
        const updatedData = await response.json();
        console.log("Advertisement updated successfully:", updatedData);

        // Notify parent component of the update
        if (onUpdate) onUpdate(data.id, advertisingPro);

        // Update local state with the new data
        // setAdvertisingPro((prev) => ({
        //     ...prev,
        //     ...updatedData, // Assume the API response contains the updated fields
        // }));
      } else {
        console.error("Failed to update advertisement:", response.statusText);
        // Handle the error response as needed
      }
    } catch (error) {
      console.error("Error updating advertisement:", error);
      // Handle any errors that occur during the fetch
    } finally {
      setShow(false);
    }
  };

  const navigate = useNavigate();
  const location = useLocation();
  // Extract data and originalData from location state
  const originalData = location.state?.originalData || {};
  const updatedData = location.state?.updatedData || {};

  console.log("location", location);
  console.log("updatedData", updatedData);
  console.log("originalData", originalData);

  // Function to check if a field has been updated
  const isFieldUpdated = (fieldName) => {
    if (data.vin === updatedData.vin) {
      return originalData[fieldName] !== updatedData[fieldName];
    }
  };
  // navigate("/myprofile/addlist", { state: { vin, active: true } });
  // Navigate(`/dealer/sell#${data.stock_no}#${data.id}`, { state: { data } });
  const dispatch = useDispatch();

  const Navigate = useNavigate();

  const handleGoToSell = () => {
    // Make sure that 'data' is correctly defined and holds the necessary information
    Navigate(`/dealer/sell#${data.stock_no}#${data.id}`, { state: { data } });
  };

  useEffect(() => {
    if (data && data.addvertising) {
      const newSocialIcons = [];
      const advertisingData = data.addvertising;

      // Iterate over the keys of the advertisingData object
      for (const key in advertisingData) {
        // Check if the key ends with '_status' and its value is true
        if (key.endsWith("_status") && advertisingData[key]) {
          // Extract the social profile name from the key
          const profile = key.split("_status")[0];
          // Push the profile name to the newSocialIcons array
          newSocialIcons.push(profile);
        }
      }
      // Set the socialIcons state with the newSocialIcons array
      setSocialIcons(newSocialIcons);
    }
  }, [data]);

  const colorMapping = {
    Beige: "#F5F5DC",
    Black: "#151517",
    Blue: "#2c447b",
    Bronze: "#655e5f",
    Brown: "brown",
    "Dark Gray": "#a6b0b6",
    "Dark Red": "#802630",
    Grey: "#808080",
    "Light Gray": "#c6ccd4",
    Magenta: "#916075",
    Maroon: "#8e373e",
    "Off White": "#f4ebdd",
    Orange: "#f79057",
    Pink: "#d60f6f",
    Purple: "#6316bc",
    Red: "#de0a06",
    Silver: "#d4d4d4",
    Tan: "#c3793f",
    White: "#ffffff",
    Yellow: "#f5d512",
    Burgundy: "#800020",
    Charcoal: "#36454F",
    Gold: "#FFD700",
    Green: "#008000",
  };

  // Set the background color dynamically based on the received color names
  const exteriorBackgroundColor =
    colorMapping[data && data?.addListing2?.exteriorColor];
  const interiorBackgroundColor =
    colorMapping[data && data?.addListing2?.interiorColor];

  const {
    all_images,
    exterior_images,
    misc_images,
    interior_images,
    three_sixty_images,
  } = data && data?.addListing2.multimedia;
  const noImage = Image.noImage;

  const getFirstValidImage = (images) => {
    if (images) {
      const imageList = images.split(",").map((img) => img.trim());
      for (let image of imageList) {
        if (image) {
          return `${imgUrl}/${dealerId}/${image}`;
        }
      }
    }
    return null;
  };

  const imageUrl =
    (all_images && getFirstValidImage(all_images)) ||
    (exterior_images && getFirstValidImage(exterior_images)) ||
    (misc_images && getFirstValidImage(misc_images)) ||
    (interior_images && getFirstValidImage(interior_images)) ||
    (three_sixty_images && getFirstValidImage(three_sixty_images)) ||
    noImage;

  console.log("socialIcons", socialIcons);
  console.log("data", data);
  console.log("advertisingPro", advertisingPro);
  const [deleteModal, setShowDeleteModal] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const draftCardAction = async (action) => {
    try {
      console.log("Doing the action in the draftCardAction", action);
      const url = `${apiUrl}/sell/${action}/${id}/${data?.id}`;
      console.log("Request URL in draftCardAction:", url);
      const response = await fetch(url, {
        method: action === "delete" ? "DELETE" : "GET",
      });
      const res = await response.json();
      console.log("response of draftCardAction", res);

      if (action === "get") {
        console.log(" draftCardAction is get");
        dataPopulateInSell(res);
      } else {
        //reload when deleted
        setIsDeleted(true);
      }
      if (res.status === 200) {
        fetchData();
      }
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      console.log(response);
    } catch (error) {
      console.log(error);
      console.log("Caught an error here in draftCardAction");
    }
  };
  const [showModalSell, setShowModalSell] = useState(false);
  const handleModalCloseSell = () => {
    setShowModalSell(false);
  };
  const SoldPopData = useSelector(
    (state) => state?.sell?.selectedVehiclePopOut
  );
  console.log("SoldPopData", SoldPopData);
  const dataPopulateInSell = (res) => {
    console.log("Populdated Data ", res);
    dispatch(setSelectedVehiclePopOut(data));
    dispatch(setActiveTab("Prices and Fees"));
    // Navigate(`/dealer/sell`, { state: { data } });
    dispatch(
      setSellData({
        buyer: {
          firstName: res.firstName,
          middleName: res.middleName,
          lastName: res.lastName,
          idType: res.idType,
          idNumber: res.idNumber,
          idState: res.idState,
          address: res.address,
          county: res.county,
          address2: res.address2,
          phoneNumber: res.phoneNumber,
          email: res.email,
          city: res.city,
          state: res.state,
          zipCode: res.zipCode,
        },
      })
    );
    dispatch(
      setCoBuyerSellData({
        sellCoBuyer: {
          firstName: res.sellCoBuyer.firstName,
          middleName: res.sellCoBuyer.middleName,
          lastName: res.sellCoBuyer.lastName,
          idType: res.sellCoBuyer.idType,
          idNumber: res.sellCoBuyer.idNumber,
          idState: res.sellCoBuyer.idState,
          address: res.sellCoBuyer.address,
          county: res.sellCoBuyer.county,
          address2: res.sellCoBuyer.address2,
          phoneNumber: res.sellCoBuyer.phoneNumber,
          email: res.sellCoBuyer.email,

          city: res.sellCoBuyer.city,
          state: res.sellCoBuyer.state,
          zipCode: res.sellCoBuyer.zipCode,
        },
      })
    );

    dispatch(
      setRetailData({
        // buyerTagFees: res.sellPriceFees.buyerTagFees || 0,
        // cardFees: res.sellPriceFees.cardFees || 0,
        mileageIn: res.mileageIn || 0,
        userListingPrice: res.sellPriceFees.listingPrice || 0,
        cashDifference: res.sellPriceFees.cashDifference || 0,
        // ----------------ServiceContarct----------------------------------------------
        serviceContractName: data.serviceContract.serviceContractName || "",
        providerPhoneNumber: data.serviceContract.providerPhoneNumber || "",
        serviceContractAddress:
          data.serviceContract.serviceContractAddress || "",
        serviceContractId: data.serviceContract.serviceContractId || "",
        serviceProviderMonths: data.serviceContract.serviceProviderMonths || "",
        serviceContractMiles: data.serviceContract.serviceContractMiles || "",
        // Gap Insurance Data------------------------------------------------------------------------------
        gapName: data.gapInsurance.gapName || "",
        gapPhoneNumber: data.gapInsurance.gapPhoneNumber || "",
        gapAddress: data.gapInsurance.gapAddress || "",
        gapInsuranceId: data.gapInsurance.gapInsuranceId || "",
        gapMonths: data.gapInsurance.gapMonths || "",
        gapMiles: data.gapInsurance.gapMiles || "",
        // Lien Holder Data-----------------------------------------------------------------------------
        lienHolderName: data.lienHolder.lienHolderName || "",
        lienHolderAddress: data.lienHolder.leinHolderAddress || "",
        leinHolderAddress2: data.lienHolder.leinHolderAddress2 || "",
        leanHolderId: data.lienHolder.leanHolderId || "",
        //  For TradeIn Data-------------------------------------------------------*************------------------------------
        tradeInId: data?.tradeInId,
        vin: data?.addListing?.vin,
        year_make_model: data?.addListing?.year_make_model,
        trim: data?.addListing?.trim,
        // maek year modal for check purpose only------
        make: data?.addListing?.modelMake,
        year: data?.addListing?.modelYear,
        model: data?.addListing?.makeModel,
        bodyClass: data?.addListing?.bodyClass,
        wheelbase: data?.addListing?.wheelbase,
        driveType: data?.addListing?.driveType,
        doorCount: data?.addListing?.doorCount,
        purchase_price: data?.addListing?.purchase_price,
        // ----------------------------------------
        engine: data?.addListing?.engine,
        transmission: data?.addListing?.transmission,
        vehicleType: data?.addListing?.vehicleType,
        seat_count: data?.addListing?.seat_count,
        city_mpg: data?.addListing?.city_mpg,
        highway_mpg: data?.addListing?.highway_mpg,
        inspection: data?.addListing?.inspection,
        exteriorColor: data?.addListing?.exteriorColor,
        interiorColor: data?.addListing?.interiorColor,
        interiorType: data?.addListing?.interiorType,
        mileage_in: data?.addListing?.mileage_in,
        //tradeInValue: formData.tradeInValue,
        listing_price: data?.addListing?.listing_price,
        // childSafetyFund: res.sellPriceFees.childSafetyFund || 0,
        // cntyRoadBridgeFee: res.sellPriceFees.cntyRoadBridgeFee || 0,
        // documentationFee: res.sellPriceFees.documentationFee || 0,
        // documentationFee: res.sellPriceFees.documentationFee || 0,
        // drivesOutTag: res.sellPriceFees.drivesOutTag || 0,
        // inspectionFeeOBDNL: res.sellPriceFees.inspectionFeeOBDNL || 0,
        listingPrice: res.sellPriceFees.listingPrice || 0,
        mInventoryTax: res.sellPriceFees.mInventoryTax || 0,
        // mailDeliveryFees: res.sellPriceFees.mailDeliveryFees || 0,
        ownedTrade: res.sellPriceFees.ownedTrade || 0,
        paymentPriceFees: res.sellPriceFees.paymentPriceFees || 0,
        priceFeesPaymentMethod: res.sellPriceFees.priceFeesPaymentMethod || "",
        processingHandlingFee: res.sellPriceFees.processingHandlingFee || 0,
        // regFeeDps: res.sellPriceFees.regFeeDps || 0,
        salesTaxes: res.sellPriceFees.salesTaxes || 0,
        // technologyFees: res.sellPriceFees.technologyFees || 0,
        // terpTitlefee: res.sellPriceFees.terpTitlefee || 0,
        // titleApplicationFee: res.sellPriceFees.titleApplicationFee || 0,
        totalFees: res.sellPriceFees.totalFees || 0,
        totalFeesAndothers: res.sellPriceFees.totalFeesAndothers || 0,
        transportationFees: res.sellPriceFees.txtTitleType || 0,

        // warranty: res.sellPriceFees.warranty || 0,
        // windshieldTrackerSticker:
        //   res.sellPriceFees.windshieldTrackerSticker || 0,
        other1: res.sellPriceFees.other1 || 0,
        other2: res.sellPriceFees.other2 || 0,
        date: res.sellPriceFees.priceFeeDate || 0,
        txtTitleType: res.saleType,
      })
    );
    setShowModalSell(true);
  };

  return (
    <>
      <Container className={`px-0 bg-tr1`}>
        <Row
          className="mx-0 rounded bg-body border-2 bg-tr1"
          style={{ position: "relative", zIndex: "5 " }}
        >
          {/* <div className="" style={{position:"relative"}}> */}
          <Col sm={1} className={`px-0 ${styles.selectionBox}`} key={index}>
            <div className={`${styles.cardCount} p-auto`}>{index + 1}</div>
            <div className={`${styles.selectionBtn}`}>
              <img
                src={
                  selectedCards && selectedCards.includes(index + 1)
                    ? Image.selected
                    : Image.select
                }
                alt={
                  selectedCards && selectedCards.includes(index + 1)
                    ? "selected"
                    : "select"
                }
                className="select-img"
              />
              {/* {selectedCards.includes(index) ? "S" : "N"} */}
            </div>
          </Col>

          <Col sm={11} className="px-2 cards bg-tr1">
            <Container
              fluid
              className="d-flex flex-nowrap  mb-4"
              onClick={() => {
                handleSelected(index + 1);
              }}
            >
              <Row
                className={`rounded border border-2 w-100 p-2 ${
                  selectedCards && selectedCards.includes(index + 1)
                    ? "bg-card"
                    : ""
                }`}
              >
                <Col md={12} className="" style={{ paddingRight: "2%" }}>
                  <Container fluid className="px-0">
                    <Row>
                      <Col
                        lg={3}
                        md={5}
                        className="shadow px-2 image-con"
                        style={{ position: "relative" }}
                      >
                        <MdOutlineShare
                          fill="black"
                          className={` ${styles.share}`}
                        />
                        <div className={`${styles.productActives}`}>
                          <span
                            className="add-date"
                            style={{ color: "#D1823A", paddingLeft: "2rem" }}
                          >
                            Saved on : {data.created_at}
                          </span>
                          <img
                            //src="http://localhost:9999/images/24/download%20(1).jpg"
                            src={imageUrl}
                            className=""
                            alt="Inventory Car Image"
                          />
                          <div className="d-flex flex-row justify-content-between  align-items-center ps-3">
                            {/* <span
                              className="add-date"
                              style={{ color: "#D1823A" }}
                            >
                              Added: {data.created_at}
                            </span> */}
                            {/* <span
                              className="Exteriors rounded-pill"
                              style={{
                                backgroundColor: exteriorBackgroundColor,
                              }}
                            >
                              Exterior
                            </span>
                            <span
                              className="Interiors rounded-pill"
                              style={{
                                backgroundColor: interiorBackgroundColor,
                              }}
                            >
                              Interior
                            </span> */}
                            <div className="interior_exterior_color_container">
                              <span>Exterior</span>
                              <span
                                style={{
                                  backgroundColor: exteriorBackgroundColor,
                                  color: exteriorBackgroundColor ? "white" : "",
                                }}
                                title={
                                  exteriorBackgroundColor
                                    ? exteriorBackgroundColor
                                    : ""
                                }
                              ></span>
                              <span
                                style={{
                                  marginLeft: "0.3rem",
                                }}
                              >
                                Interior
                              </span>
                              <span
                                style={{
                                  backgroundColor: interiorBackgroundColor,
                                }}
                                title={
                                  interiorBackgroundColor
                                    ? interiorBackgroundColor
                                    : ""
                                }
                              ></span>
                            </div>
                          </div>
                          {/* <div className="d-flex flex-row justify-content-between mt-2 align-items-center">
                            <span
                              className={`${styles.addDate}`}
                              style={{ color: "#D1823A" }}
                            >
                              Sold On: {data.created_at}
                            </span>
                            <span
                              className={` rounded-pill ${styles.Exteriors}`}
                              style={{
                                backgroundColor: exteriorBackgroundColor,
                              }}
                            >
                              Exterior
                            </span>
                            <span
                              className={` rounded-pill ${styles.Interiors}`}
                              style={{
                                backgroundColor: interiorBackgroundColor,
                              }}
                            >
                              Interior
                            </span>
                          </div> */}
                        </div>
                      </Col>

                      <Col lg={7} md={7}>
                        <Container fluid className="px-0 h-100">
                          <Row className="h-100 d-flex align-items-between">
                            <Col sm={12} className="px-0">
                              <Container>
                                <Row className="d-flex justify-content-between">
                                  <Col
                                    sm={10}
                                    className={`${styles.cardHead}`}
                                    style={{
                                      textShadow: isFieldUpdated("trim")
                                        ? "#ff8c21 1px 0 10px"
                                        : "black",
                                    }}
                                  >
                                    {data.addListing2.year_Make_Model}{" "}
                                    {data.trim}
                                  </Col>
                                </Row>
                              </Container>
                            </Col>
                            <Col sm={12} className="px-0">
                              <Container>
                                <Row
                                  className={`${styles.subHead} ${styles.dataFont} d-flex justify-content-between`}
                                >
                                  <Col
                                    md={4}
                                    className={`text-nowrap mx-2 px-1 fw-bold ${styles.vin}`}
                                  >
                                    <span className={`${styles.orangeColor}`}>
                                      VIN:
                                    </span>
                                    {data.addListing2.vin}{" "}
                                  </Col>
                                  <Col className="px-0 " md={4}>
                                    <Container>
                                      <Row className=" ">
                                        {data.addListing2.mileage_in ? (
                                          <Col
                                            sm={3}
                                            className={` text-nowrap fw-bold  border-2 px-2 ${styles.grayColor}`}
                                            style={{
                                              textShadow: isFieldUpdated(
                                                "mileage_in"
                                              )
                                                ? "#ff8c21 1px 0 10px"
                                                : "black",
                                              color: isFieldUpdated(
                                                "mileage_in"
                                              )
                                                ? "orange"
                                                : "black",
                                            }}
                                          >
                                            {new Intl.NumberFormat().format(
                                              data.addListing2.mileage_in
                                            )}
                                            {/* {data.mileage_in} */}
                                          </Col>
                                        ) : null}

                                        {data.addListing2.engine ? (
                                          <Col
                                            className=" border-2 px-0"
                                            style={{
                                              textShadow: isFieldUpdated(
                                                "engine"
                                              )
                                                ? "#ff8c21 1px 0 10px"
                                                : "black",
                                            }}
                                          >
                                            {data.addListing2.engine}
                                          </Col>
                                        ) : null}
                                      </Row>
                                    </Container>
                                  </Col>
                                  <Col
                                    md={2}
                                    className={`text-nowrap mx-2 px-1 fw-bold ${styles.vin}`}
                                  >
                                    {data.firstName} {data.lastName}
                                  </Col>
                                </Row>
                              </Container>
                            </Col>
                            <Col sm={12} className="px-0">
                              <Container fluid>
                                <Row>
                                  <Col>
                                    <Container fluid>
                                      <Row
                                        className={` ${styles.dataFont} text-center`}
                                      >
                                        <Col
                                          className={`px-0 ${styles.box} me-2 `}
                                          style={{
                                            textShadow: isFieldUpdated(
                                              "purchasedDate"
                                            )
                                              ? "#ff8c21 1px 0 10px"
                                              : "black",
                                            // color: isFieldUpdated("trim") ? "orange" : "black",
                                          }}
                                        >
                                          Vehicle Type{" "}
                                          <p
                                            className={`py-0 m-0 ${styles.orangeColor}`}
                                          >
                                            {data.addListing2.vehicleType ||
                                              "NA"}
                                          </p>
                                        </Col>
                                        <Col
                                          className={`px-0 ${styles.box} me-2 `}
                                        >
                                          All leads{" "}
                                          <p
                                            className={`py-0 m-0 ${styles.orangeColor}`}
                                          >
                                            23
                                          </p>
                                        </Col>
                                        <Col
                                          className={`px-0 ${styles.box} me-2 `}
                                          style={{
                                            textShadow: isFieldUpdated(
                                              "inspection"
                                            )
                                              ? "#ff8c21 1px 0 10px"
                                              : "black",
                                            // color: isFieldUpdated("trim") ? "orange" : "black",
                                          }}
                                        >
                                          Sale Type{" "}
                                          <p
                                            className={`py-0 m-0 ${styles.orangeColor}`}
                                          >
                                            {data.saleType}
                                          </p>
                                        </Col>
                                        <Col
                                          className={`px-0 ${styles.box} me-2 `}
                                          style={{
                                            textShadow: isFieldUpdated(
                                              "inspection"
                                            )
                                              ? "#ff8c21 1px 0 10px"
                                              : "black",
                                            // color: isFieldUpdated("trim") ? "orange" : "black",
                                          }}
                                        >
                                          Trade In{" "}
                                          <p
                                            className={`py-0 m-0 ${styles.orangeColor}`}
                                          >
                                            {new Intl.NumberFormat().format(
                                              data.addListing2.purchase_price
                                            )}
                                          </p>
                                        </Col>

                                        {/* <Col sm={2} className="px-0 me-3 d-flex align-items-end">
                                                                                    <h6 className="my-0">Acc. No. <span className={`${styles.blueColor}`}>7646</span></h6>
                                                                                </Col> */}
                                        {/* <Col
                                          sm={3}
                                          className="px-0 d-flex align-items-end"
                                        >
                                          <h6 className="my-0">
                                            Jessica Hernandez
                                          </h6>
                                        </Col> */}
                                      </Row>
                                    </Container>
                                  </Col>
                                </Row>
                              </Container>
                            </Col>
                          </Row>
                        </Container>
                      </Col>

                      <Col className=" px-1">
                        <h4
                          className={` ${styles.dataFont} ${styles.stockNo} text-nowrap mb-4 cursor-pointer`}
                          style={{ cursor: "pointer" }}
                        >
                          ${" "}
                          {new Intl.NumberFormat().format(
                            data.addListing2.listing_price
                          )}{" "}
                        </h4>

                        <div className="d-flex flex-column justify-content-around">
                          {/* <Button className="product-g-btns border-0 fw-bold p-1">
                           Docs 
                          </Button> */}
                          <Button
                            className="product-g-btns border-0 fw-bold p-1"
                            onClick={() => draftCardAction("get")}
                          >
                            Resume
                          </Button>
                          {/* <Button className="product-g-btns border-0 fw-bold p-1">
                            Cost Info
                          </Button> */}
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
              <div
                className="row mb-3 h-100"
                style={{
                  display: "inline-block",
                  width: "5%",
                  position: "absolute",
                  right: "0%",
                }}
              >
                <Container fluid className="px-0 h-100">
                  <div className="row d-flex flex-nowrap h-100">
                    <div className="col-3 h-100 px-0 d-flex flex-column justify-content-between">
                      <div
                        className="d-flex align-items-center justify-content-around"
                        style={{ height: "100%" }}
                      >
                        <div className="vr pr-2 mx-1"></div>
                        <div className="d-flex flex-column align-items-center justify-content-around h-100">
                          {/* <div
                            className={` ${styles.edit} ml-3 rounded-circle px-2 py-1 bg-light`}
                            onClick={() => draftCardAction("get")}
                            onClick={() => draftCardAction("get")}
                          >
                            <MdModeEdit />
                          </div> */}
                          <div
                            className={` ${styles.edit} ml-3 rounded-circle px-2 py-1 bg-light`}
                            onClick={() => draftCardAction("delete")}
                          >
                            <MdDelete />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Container>
                <Modal
                  show={deleteModal}
                  size="xl"
                  onHide={() => setShowDeleteModal(false)}
                  dialogClassName="modal-90w"
                  aria-labelledby="example-custom-modal-styling-title"
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                      Delete this Inventory ?
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>Are you sure you want to delete this inventory?</p>
                    <Button style={{ marginRight: "2rem" }}>
                      Temporarily Delete
                    </Button>
                    <Button
                      style={{
                        marginRight: "2rem",
                        backgroundColor: "red",
                        border: "none",
                      }}
                    >
                      Permanently Delete
                    </Button>
                    <Button
                      style={{
                        marginRight: "2rem",
                        backgroundColor: "green",
                        border: "none",
                      }}
                      onClick={() => setShowDeleteModal(false)}
                    >
                      No, keep it here
                    </Button>
                  </Modal.Body>
                </Modal>
              </div>
            </Container>
          </Col>
        </Row>
      </Container>
      <SellData
        show={showModalSell}
        handleClose={handleModalCloseSell}
        VehicleName={data}
        status={true}
      />
    </>
  );
};

// Define the calculateDaysDifference function outside of ListView
const calculateDaysDifference = (inspectionDate) => {
  if (!inspectionDate) return 0;
  const currentDate = new Date();
  const inspectionDateObj = new Date(inspectionDate);
  const timeDifference = inspectionDateObj - currentDate;
  return Math.ceil(Math.abs(timeDifference) / (1000 * 60 * 60 * 24));
};

export default SellDraftCard;

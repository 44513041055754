import React, { useEffect, useState } from "react";
import Styles from "./DNSsteps.module.css";
import { MdOutlineAddLink } from "react-icons/md";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Image from "../../imgData";
import { useSelector } from "react-redux";
const DNSsteps = ({ show, handleClose, DomainName, domainName }) => {
  const [currentImage, setCurrentImage] = useState("");
  const [activeIndex, setActiveIndex] = useState(null);
  const webCardId = useSelector((state) =>state.taxesAndFees.dealerRetail.webCardId);
  console.log("webCardId",webCardId);
  const { id } = useSelector((state) => state.user);
  // State to control the visibility of the special div
  const [showSpecialDiv, setShowSpecialDiv] = useState(false);
  let accountType; // Declare the variable outside

  if (DomainName === "GoDaddy.com, LLC") {
    accountType = "GoDaddy";
  } else if (DomainName === "NameCheap, Inc.") {
    accountType = "Namecheap";
  } else {
    accountType = "Unknown"; // Optional: handle other cases
  }

  // Define instructions for each account type
  const getDefaultImage = (type) => {
    if (type === "GoDaddy") {
      return Image.godaddy.login; // Replace with the appropriate first step image
    } else if (type === "Namecheap") {
      return Image.namecheap.login; // Replace with the appropriate first step image
    }
    return ""; // Fallback if no account type matches
  };
  // for hyper link-----------
  const handleClickLink = () => {
    let url;

    if (accountType === "GoDaddy") {
      url =
        "https://sso.godaddy.com/?realm=idp&app=cart&path=%2Fcheckoutapi%2Fv1%2Fredirects%2Flogin";
    } else if (accountType === "Namecheap") {
      url = "https://www.namecheap.com/myaccount/login/";
    }

    if (url) {
      window.location.href = url;
    }
  };
  // ------------------------
  const instructions = {
    GoDaddy: [
      "1. Log in to your GoDaddy account.",
      "2. Click On My Product.",
      "3. Click Manage Domains.",
      "4. Click on the domain you want to update.",
      "5. Click Manage DNS.",
      "6. Update the DNS records.",
      "7. Click Save.",
      "8. Verify Domain",
    ],
    Namecheap: [
      "1. Log in to your Namecheap account.",
      "2. Click On Domain List.",
      "3. Click On Manage.",
      "4. Choose Custom DNS In Dropdown.",
      "5. Enter New DNS.",
      "6. Click On Save.",
      "7. Verify Domain",
    ],
    Unknown: ["1. Verify Domain"],
    // Add more companies as needed
  };
  const handleStepClick = (step) => {
    setActiveIndex(step);
    let images;
    // Determine which image set to use
    if (accountType === "GoDaddy") {
      images = Image.godaddy;
    } else if (accountType === "Namecheap") {
      images = Image.namecheap;
    }

    // Set the current image based on the step
    if (
      (accountType === "GoDaddy" && step === 8) ||
      (accountType === "Namecheap" && step === 7) ||
      (accountType === "Unknown" && step === 1)
    ) {
      setShowSpecialDiv(true); // Show the special div
      setCurrentImage(null); // Clear the current image if necessary
    } else {
      setShowSpecialDiv(false); // Reset the flag for other steps
      switch (step) {
        case 1:
          setCurrentImage(images.login);
          break;
        case 2:
          setCurrentImage(images.product);
          break;
        case 3:
          setCurrentImage(images.manageDomains);
          break;
        case 4:
          setCurrentImage(images.updateDomain);
          break;
        case 5:
          setCurrentImage(images.manageDNS);
          break;
        case 6:
          setCurrentImage(images.updateDNS);
          break;
        case 7:
          setCurrentImage(images.save);
          break;
        default:
          setCurrentImage(getDefaultImage(accountType));
      }
    }
  };

  // Effect to set the default image on accountType change

  useEffect(() => {
    setCurrentImage(getDefaultImage(accountType));
  }, [accountType]);
  // --------------------------------------------------------------check verify check--------------------------------
  const [statusVerify, setStatusVerify] = useState(null);
  const [loading, setLoading] = useState(false);
  const verifyDomain = () => {
    // Construct the URL with query parameters
    const queryParams = new URLSearchParams({
      domain: domainName,
    });

    const url = `${
      process.env.REACT_APP_API_URL
    }/domain/check-domain?${queryParams.toString()}`;

    fetch(url, {
      method: "GET",
    })
      .then((response) => {
        if (response.ok) {
          setStatusVerify(1); // Set status to 1 if response is 200
          return response.json();
        } else {
          setStatusVerify(0); // Set status to 0 for any other response
          throw new Error("Network response was not ok");
        }
      })
      .then((data) => {
        console.log("Success:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  // ----------------------------------------------------------------for livwe the website-----------------------
  const handleConnect = async (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      domainName: domainName,
      userId: id,
      templateId: webCardId,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/template/save`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      console.log("success ", response);
      if (response.ok) {
        const data = await response;
        console.log("Success:", data);
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false); // Set loading to false when the request finishes
    }
  };
  // ------------------------------------------------------------------*************************----------------------

  return (
    <Modal
      show={show}
      size="xl"
      onHide={handleClose}
      dialogClassName="modal-90w"
    >
      <Modal.Header closeButton>
        <div>
          <Modal.Title>DNS Steps</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className={Styles.wrapper}>
          <div className={Styles.container}>
            <p className={Styles.header}>
              Here are some steps to update DNS in "{accountType}"
            </p>
            <div className={Styles.stepsHero}>
              <p className={Styles.sideBarItems}>
                {instructions[accountType]?.map((instruction, index) => (
                  <span
                    key={index}
                    onClick={() => handleStepClick(index + 1)}
                    className={
                      activeIndex === index + 1 ? Styles.active_steps : ""
                    }
                  >
                    {instruction}
                  </span>
                ))}
              </p>
              {showSpecialDiv ? (
                <div style={{ width: "25rem" }}>
                  <p className={Styles.verify_domain_status_btn}>
                    <button type="button" onClick={verifyDomain}>
                      Verify Domain
                    </button>
                  </p>
                  {statusVerify === 1 ? (
                    <>
                      <div className={Styles.verify_domain_status}>
                        <h4>
                          Verified{" "}
                          <span class={Styles.verify_domain_circle}></span>
                        </h4>
                        <p>Your domain is verified, Please Select Your</p>
                        <p>website theme</p>
                        <p className={Styles.preview_live_btn}>
                          <button
                            type="button"
                            onClick={handleConnect}
                            disabled={loading}
                          >
                            {loading ? <div className="spinner" style={{paddingLeft:"10px"}}></div> : "Live"}
                          </button>
                        </p>
                      </div>
                    </>
                  ) : statusVerify === 0 ? (
                    <>
                      <div className={Styles.verify_domain_status}>
                        <h4>
                          Verified{" "}
                          <span class={Styles.verify_domain_circle_b}></span>
                        </h4>
                        <p>Please wait sometime to propagate</p>
                        <p>your domain and try again.</p>
                      </div>
                    </>
                  ) : null}
                </div>
              ) : (
                currentImage && (
                  <img src={currentImage} alt="Instructional step" />
                )
              )}
            </div>
            <div className={Styles.stepsLinkWrapper}>
              <div className={Styles.stepsLinkContainer}>
                <p>
                  <span>Domain Name Server</span>
                </p>
                <p>
                  <span>NS1.Dealersautocenter.com</span>
                </p>
                <p>
                  <span>NS2.Dealersautocenter.com</span>
                </p>
              </div>
              <div className={Styles.stepsLinkNavigaion}>
                {/* <p
                  onClick={handleClickLink}
                  style={{ cursor: "pointer" }}
                  className={Styles.link_icon}
                >
                  <span>
                    <MdOutlineAddLink />
                  </span>
                </p> */}
                {!(
                  (accountType === "GoDaddy" && activeIndex === 8) ||
                  (accountType === "Namecheap" && activeIndex === 7) ||
                  (accountType === "Unknown" && activeIndex === 1)
                ) && (
                  <p
                    onClick={handleClickLink}
                    style={{ cursor: "pointer" }}
                    className={Styles.link_icon}
                  >
                    <span>
                      <MdOutlineAddLink />
                    </span>
                  </p>
                )}

                <p>
                  <span>
                    {/* <Link to="/dealer/website/dns" style={{ color: "black" }}>
                  Back
                </Link>{" "} */}
                    {/* | Next */}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default DNSsteps;

import React, { useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import stylesHead from "../Styles/mailAddAcc.module.css";
import styles from "../Styles/common.module.css";

import { FaPlus } from "react-icons/fa";

const DealerMailboxFil = () => {
  return (
    <Container className={`p-4`}>
      <form className={` ${stylesHead.form}`}>
        <Row>
          <Col sm={12}>
            <h5 className={` ${stylesHead.settingHead}`}>Mailbox Filtering</h5>
          </Col>

          <Col sm={10}>

            <div className={` ${stylesHead.pop}`}>
              <p className={` ${styles.worn}`}>Not enabled for Demo account</p>
              <p>
                <input
                  type='checkbox'
                />
                Enable Custom Mailbox Filtering</p>
              <p className={` ${styles.fsSmall}`}>Message filtering allows you to define rules and actions that are triggered when messages are delivered to this mailbox.</p>
            </div>

            <div className={` ${styles.tableCon}`} style={{ height: '25vh' }}>
              <div className={` ${styles.tableHead}`}>Filter Discription</div>
              <table>
                <thead>
                  <tr>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <Button className={`p-1 ${styles.saveBtn}`}> <FaPlus /> New Filter</Button>
            <p className='my-2 fw-bold'>
              <input
                type='checkbox'
                className='me-2'
              />
              Deliver Bulk and List messages to Bulk E-Mail Folder</p>

          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            <h5 className={` ${stylesHead.settingHead}`}>Mailbox Filtering - Properties</h5>
            <p className={` ${stylesHead.subHead}`}>Message filtering allows you to define rules and actions that are triggered when messages are delivered to this mailbox.</p>
          </Col>

          <Col sm={10}>
            <div className={` ${stylesHead.pop}`}>
              <label>
                Filter Discription:
                <input
                  type="text"
                  name="FilterDiscription"
                />
              </label>
            </div>

            <div className={` ${styles.tableCon}`}>
              <div className={`d-flex justify-content-between ${styles.tableHead}`}>
                <span>Filtering Criteria</span>
                <span style={{ display: 'inline-block', width: '50%' }}>Criteria Data</span>
              </div>

              <div className='p-2 d-flex flex-column justify-content-evenly' style={{ height: '80%' }}>
                <div className={` ${styles.formRow}`}>
                  <label>
                    <input
                      type='checkbox'
                    />
                    Message From:
                  </label>
                  <input
                    type="text"
                    name="MessageFrom"
                  />
                </div>

                <div className={` ${styles.formRow}`}>
                  <label>
                    <input
                      type='checkbox'
                    />
                    Message To:
                  </label>
                  <input
                    type="text"
                    name="MessageTo"
                  />
                </div>

                <div className={` ${styles.formRow}`}>
                  <label>
                    <input
                      type='checkbox'
                    />
                    Message Cc:
                  </label>
                  <input
                    type="text"
                    name=" MessageCc"
                  />
                </div>

                <div className={` ${styles.formRow}`}>
                  <label>
                    <input
                      type='checkbox'
                    />
                    Message To or Cc:
                  </label>
                  <input
                    type="text"
                    name=" MessageToOrCc"
                  />
                </div>

                <div className={` ${styles.formRow}`}>
                  <label>
                    <input
                      type='checkbox'
                    />
                    Attachments:
                  </label>
                  <input
                    type="text"
                    name="attachments"
                  />
                </div>

                <div className={` ${styles.formRow}`}>
                  <label>
                    <input
                      type='checkbox'
                    />
                    Message Subject Contains:
                  </label>
                  <input
                    type="text"
                    name="MessageSubjectContains"
                  />
                </div>

                <div className={` ${styles.formRow}`}>
                  <label>
                    <input
                      type='checkbox'
                    />
                    Message Contains:
                  </label>
                  <input
                    type="text"
                    name="MessageContains"
                  />
                </div>

                <div >
                  <span className={`mx-auto ${styles.fsSmall}`} style={{ display: 'inline-block', width: '50%', float: 'right' }}>You can use asterisks as wild cards and commas to delimit multiple string values.</span>
                </div>

              </div>
            </div>

            <p className={`mt-2 ${styles.fsSmall}`}>When the above conditions are met, I would like the following action to occur:</p>

            <div className={` ${stylesHead.pop}`}>
              <label>
                Filter Action:
                <input
                  type="text"
                  name="FilterAction"
                />
              </label>
            </div>

          </Col>
        </Row>
      </form>
    </Container >
  )
}

export default DealerMailboxFil
import { Stomp } from "@stomp/stompjs";
import SockJS from "sockjs-client";

class WebSocketService {
  constructor() {
    this.stompClient = null;
  }

  connect(onMessageReceived, username, onConnectionEstablished) {
    const socket = new SockJS(`${process.env.REACT_APP_API_URL}/ws`);
    this.stompClient = Stomp.over(socket); // Use Stomp.over to initialize over SockJS
    this.stompClient.debug = (str) => {
      console.log(str);
    };

    this.stompClient.connect(
      {},
      () => {
        // const sessionId = frame.headers['session-id'] || frame.headers['simpSessionId'];
        console.log(`Connected as ${username}`);

        if (onConnectionEstablished) {
          onConnectionEstablished();
        }
        console.log(`Subscribing to: /user/${username}/queue/history`);
        this.stompClient.subscribe(
          `/user/${username}/queue/messages`,
          (message) => {
            console.log("Message received:", message.body); // Debug log
            onMessageReceived(message);
          }
        );
        // this.stompClient.subscribe(
        //   `/user/${username}/queue/history`,
        //   (message) => {
        //     console.log("Chat history subscription triggered");
        //     console.log("Raw message:", message);
        //     try {
        //       const chatHistory = JSON.parse(message.body);
        //       console.log("Chat history received:", chatHistory);
        //       onMessageReceived({
        //         body: JSON.stringify(chatHistory),
        //         type: "HISTORY",
        //       });
        //     } catch (e) {
        //       console.error("Error parsing chat history:", e);
        //       console.error("Raw message body:", message.body);
        //     }
        //   }
        // );
        this.stompClient.subscribe(
          `/user/${username}/queue/history`,
          (message) => {
            console.log("Chat history subscription triggered");
            console.log("Raw message:", message);
            try {
              const chatHistory = JSON.parse(message.body);
              console.log("Chat history received:", chatHistory);
              onMessageReceived({
                body: JSON.stringify(chatHistory),
                type: "HISTORY",
              });
            } catch (e) {
              console.error("Error parsing chat history:", e);
              console.error("Raw message body:", message.body);
            }
          }
        );
      },
      (error) => {
        console.error("Connection error:", error);
        this.stompClient = null; // Reset the client on error
      }
    );
  }

  sendMessage(message) {
    if (this.stompClient && this.stompClient.connected) {
      this.stompClient.send("/app/send", {}, JSON.stringify(message));
      console.log("Message sent:", message);
    } else {
      console.error("Unable to send message. WebSocket is not connected.");
    }
  }

  sendChatHistoryRequest(username, recipient) {
    const request = { sender: username, recipient: recipient };
    if (this.stompClient && this.stompClient.connected) {
      this.stompClient.send("/app/history", {}, JSON.stringify(request));
      console.log(
        `Requested chat history between ${username} and ${recipient}`
      );
    } else {
      console.log(
        "Unable to send history request. WebSocket is not connected."
      );
    }
  }

  // Other methods remain unchanged...

  sendTypingStatus(username, recipient, isTyping) {
    const message = {
      sender: username,
      recipient: recipient,
      content: isTyping ? "is typing..." : "",
      type: "TYPING",
    };
    this.sendMessage(message);
  }

  disconnect() {
    if (this.stompClient && this.stompClient.connected) {
      this.stompClient.disconnect(
        () => {
          console.log("Disconnected");
        },
        (error) => {
          console.error("Disconnection error:", error);
        }
      );
    } else {
      console.warn(
        "Attempted to disconnect, but stompClient was not initialized or already disconnected."
      );
    }
    this.stompClient = null; // Reset the client after disconnection
  }
}

const webSocketService = new WebSocketService();
export default webSocketService;

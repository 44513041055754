import React, { useState } from "react";

import "../../../productsListing/GridCard.css";
import { Link, useNavigate } from "react-router-dom";
import Image from "../../../imgData";
import { IoShareSocial } from "react-icons/io5";
import { FaHeart } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import {
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookShareButton,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { useSelector } from "react-redux";
import axios from "axios";

const GridCard1 = ({ handleShowDialogueBox, product, ProductId }) => {
  const navigate = useNavigate();
  const [showSocialMedia, setShowSocialMedia] = useState(false);
  const [isFav, setIsFav] = useState(false);
  // console.log(product);
  const location = useLocation();
  const { id } = useSelector((state) => state.user);
  // console.log("User ID ", id);
  const handleShareLink = () => {
    console.log("Sharing link with the id");
    setShowSocialMedia(!showSocialMedia);
  };
  const generateShareUrl = (itemId) => {
    return `${window.location.origin}${location.pathname}/${itemId}`;
  };

  const handleLikeTheProduct = async () => {
    if (id === 0) {
      handleShowDialogueBox(true);
    } else {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/listing/add_favourite/${id}/${ProductId}`
        );
        console.log(res);
        setIsFav(true);
      } catch (error) {
        console.log("Server side error while liking the product");
      }
    }
  };
  // for single product details---------------
  const handleClick = () => {
    navigate(`/dealer/carDetails1`);
  };

  return (
    <div className="card grid-container p-2">
      <div className="row  p-3 pb-0 rounded card_container">
        <div className="col-lg-12 col-md-5 card_left">
          <div className="product-image-box" onClick={handleClick}>
            <img
              src={product.image}
              alt="product_car_image"
              className="product_car_image"
            />

            <div className="product_share_like">
              <span onClick={handleShareLink}>
                <IoShareSocial />
              </span>
              {id !== 0 ? (
                <span onClick={handleLikeTheProduct}>
                  <FaHeart fill={`${isFav ? "red" : "white"}`} />
                </span>
              ) : null}
            </div>

            {showSocialMedia ? (
              <div className="social_media_container">
                <FacebookShareButton url={generateShareUrl(id)}>
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <TwitterShareButton url={generateShareUrl(id)}>
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
                <WhatsappShareButton url={generateShareUrl(id)}>
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
                <TelegramShareButton url={generateShareUrl(id)}>
                  <FacebookMessengerIcon size={32} round />
                </TelegramShareButton>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GridCard1;

import React, { useState, useEffect, useRef } from 'react';
import SockJS from 'sockjs-client';
import { Client } from '@stomp/stompjs';
import Styles from '../Styles/ChatContainer.module.css';
import './ChatContainer.css';
import { IoLocationSharp, IoSend } from 'react-icons/io5';
import { CgAttachment } from 'react-icons/cg';
import { AiOutlineDollar } from 'react-icons/ai';
import { GrEmoji } from 'react-icons/gr';

import { PiDotsThreeVerticalBold } from 'react-icons/pi';
import EmojiPicker from 'emoji-picker-react';
import { BsCurrencyDollar } from "react-icons/bs";
import { FaRegFaceSmile } from "react-icons/fa6";

import { useDispatch, useSelector } from "react-redux";
import { first } from 'lodash';
const ChatContainer = ({ input, handleInputChange, messages, sendMessage, handleInputFocus, handleInputBlur }) => {
    const { firstName } = useSelector((state) => state.user);
    const [stompClient, setStompClient] = useState(null);
    // const [messages, setMessages] = useState([]);
    const [messageInput, setMessageInput] = useState('');
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const chatBoxRef = useRef(null);

    // useEffect(() => {
    //     // Initialize WebSocket connection
    //     const socket = new SockJS('http://localhost:9999/chat-websocket');
    //     const client = new Client({
    //         webSocketFactory: () => socket,
    //         connectHeaders: {},
    //         debug: (str) => {
    //             console.log(str);
    //         },
    //         onConnect: (frame) => {
    //             console.log('Connected: ' + frame);
    //             client.subscribe('/topic/public', (messageOutput) => {
    //                 const message = JSON.parse(messageOutput.body);
    //                 setMessages((prevMessages) => [...prevMessages, message]);
    //             });
    //         },
    //         onStompError: (frame) => {
    //             console.error('Broker reported error: ' + frame.headers['message']);
    //             console.error('Additional details: ' + frame.body);
    //         },
    //         onWebSocketClose: () => {
    //             console.log('WebSocket closed');
    //         }
    //     });

    //     client.activate();
    //     setStompClient(client);

    //     // Cleanup function to disconnect when component unmounts
    //     return () => {
    //         if (stompClient) {
    //             stompClient.deactivate();
    //         }
    //     };
    // }, []);

    useEffect(() => {
        // Scroll to bottom of chat box
        if (chatBoxRef.current) {
            chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
        }
    }, [messages]);

    const handleMessageSubmit = (e) => {
        e.preventDefault();
        if (messageInput && stompClient) {
            const chatMessage = {
                sender: 'neeraj', // Replace with actual sender if needed
                content: messageInput,
                receiver: 'Sumit' // Replace with actual receiver if needed
            };

            // stompClient.publish({
            //     destination: '/app/chat.sendMessage',
            //     body: JSON.stringify(chatMessage)
            // });
            setMessageInput('');
        }
    };

    const handleEmojiClick = (emojiObject) => {
        console.log('Emoji clicked:', emojiObject); // Debugging line
        setMessageInput((prevInput) => prevInput + emojiObject.emoji);
        setShowEmojiPicker(false);
    };
    // const handleLocationClick = () => {
    //     if (navigator.geolocation) {
    //         navigator.geolocation.getCurrentPosition(async (position) => {
    //             const { latitude, longitude } = position.coords;
    //             const apiKey = process.env.REACT_APP_GOOGLE_API_KEY; // Replace with your actual API key
    //             const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`);
    //             const data = await response.json();

    //             if (data.results && data.results.length > 0) {
    //                 const location = data.results[0].formatted_address;
    //                 setMessageInput(location);
    //             } else {
    //                 setMessageInput('Location not found');
    //             }
    //         }, (error) => {
    //             console.error('Error getting location:', error);
    //             setMessageInput('Error getting location');
    //         });
    //     } else {
    //         setMessageInput('Geolocation is not supported by this browser.');
    //     }
    // };

    return (
        <div className={` border`}>
            <h1>User Chat</h1>
            <div className={Styles.chatBox} ref={chatBoxRef}>
                <div className={Styles.receivedMessage}>
                    <div>user name</div>
                    <div className={Styles.msgContent}>kityb uykdyi fgriuetg 458tg 4r85gc 3583gc r8gc48f c486rtgfc 48g eruxfg78e rg78 g8guki,kmghvtm tvgbftdx  ucvnbnhg jt7u65u7fd rhd7uhjur</div>
                </div>

                <div className={Styles.sentMessage}>
                    <div>{firstName}</div>
                    <div className={Styles.msgContent}>jyge5tk rtguy n458t4 5t84 t76c5gt 7g jg4kdyi fgriuetg 458tg 4r85gc 3583gc r8gc48f c486rtgfc 48g eruxfg78e rg78 g8 nn5t8 irdi8 gn5i4u yg4576  tg5u775 t4578t5h8  giu8t4e 8947t3 8r497 t609d 64876tyf</div>
                </div>

                {messages.map((msg, index) => (
                    <div key={index} className={Styles.message}>
                        {msg.sender}: {msg.content}
                    </div>
                ))}

                {messages.map((msg, index) => (
                    <div key={index} className={msg.sender === firstName ? Styles.sentMessage : Styles.receivedMessage}>
                        <div>{msg.sender === firstName ? firstName : 'Customer'}</div>
                        <div className={Styles.msgContent}>{msg.content}</div>
                    </div>
                ))}

            </div>
            <form onSubmit={handleMessageSubmit} className={Styles.messageForm}>
                <div className='input-field-message mx-auto my-3'>
                    <div className={`me-2 ${Styles.inputContainer}`}>
                        <button type="button" className={Styles.iconButton}>
                            <IoLocationSharp style={{ fill: "#4184cb" }} />
                            <span style={{ fontSize: "12px", fontWeight: "unset" }}>Location</span>
                        </button>
                        <button type="button" className={Styles.iconButton}>
                            <CgAttachment />
                            <span style={{ fontSize: "12px", fontWeight: "unset" }}>Attachment</span>
                        </button>
                        <button type="button" className={Styles.iconButton}>
                            <BsCurrencyDollar />
                            <span style={{ fontSize: "12px", fontWeight: "unset" }}>Loan App</span>
                        </button>
                        <input
                            type="text"
                            value={input}
                            onChange={handleInputChange}
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                            placeholder="Type a message..."
                            className={Styles.messageInput}
                            style={{ letterSpacing: "2px", fontWeight: "bold" }}
                        />
                        <button type="button" onClick={() => setShowEmojiPicker(!showEmojiPicker)} className={Styles.emojiButton}>
                            <FaRegFaceSmile style={{ backgroundColor: "#ffc800" }} />
                        </button>
                    </div>
                    <button type="submit" className={` border-0 ${Styles.sendMessageButton}`} onClick={sendMessage}>
                        <IoSend style={{ border: "none" }} id='send' />
                    </button>
                </div>
                {showEmojiPicker && (
                    <div className={Styles.emojiPickerContainer}>
                        <EmojiPicker onEmojiClick={handleEmojiClick} />
                    </div>
                )}
            </form>
        </div>
    );
};

export default ChatContainer;

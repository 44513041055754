import React, { useEffect, useRef, useState } from "react";
import Styles from "./Styles/DealerMessages.module.css";
import { useLocation, useOutletContext } from "react-router-dom";
import MessageProfileContainer from "./components/MessageProfileContainer";
import InputMessageContainer from "./components/InputMessageContainer";
import ChatContainer from "./components/ChatContainer";
import MiniChatBox from "./components/MiniChatBox";
import Image from "../../imgData";
import { useDispatch, useSelector } from "react-redux";

import WebSocketService from "./WebSocketService";

const UserMessages = () => {
  const { id: username } = useSelector((state) => state.user);
  // console.log("id", id);
  const location = useLocation();
  const isExpanded = useOutletContext();

  const [messages, setMessages] = useState([]);
  console.log("All the messages received => ", messages);
  const [input, setInput] = useState("");
  const [recipient, setRecipient] = useState(10); // Recipient's username
  const [isTyping, setIsTyping] = useState(false);

  //Setting the Sender
  useEffect(() => {
    if (username) {
      WebSocketService.connect(onMessageReceived, username, () => {
        if (recipient) {
          WebSocketService.sendChatHistoryRequest(username, recipient);
        }
      }); // Connect WebSocket
    }

    return () => {
      WebSocketService.disconnect();
    };
  }, [username, recipient]);

  const onMessageReceived = (message) => {
    const messageBody = JSON.parse(message.body);
    console.log("Message Body in User Messages => ", messageBody);
    console.log(
      Array.isArray(messageBody) &&
        messageBody.forEach((element) => {
          console.log("Object of this messageBody is => ", element);
        })
    );
    if (messageBody.type === "CHAT") {
      setMessages((prevMessages) => [...prevMessages, messageBody]);
    } else if (
      messageBody.filter(
        (obj) => obj.type === "HISTORY" && obj.content.length > 0
      )
    ) {
      console.log("Message Body in User Messages => ", messageBody.type);
      try {
        if (Array.isArray(messageBody)) {
          // Prepend the history messages to keep them in order
          setMessages((prevMessages) => [...messageBody, ...prevMessages]);
        }
      } catch (e) {
        console.error("Error parsing history messages: ", e);
      }
    } else if (messageBody.type === "TYPING") {
      setIsTyping(messageBody.content !== "");
    }
  };

  const sendMessage = () => {
    if (!username || !recipient || !input) {
      alert(
        "Please make sure you have set a username, recipient, and message content."
      );
      return;
    }

    const message = {
      sender: username,
      // recipient: recipient,
      recipient: recipient,
      content: input,
      type: "CHAT",
    };

    // Add the sender's message to the state immediately
    // setMessages((prevMessages) => [...prevMessages, message]);

    // Send the message to the backend via WebSocket
    WebSocketService.sendMessage(message);

    // Clear the input field
    setInput("");
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleInputFocus = () => {
    // Send typing status when the input field is focused
    WebSocketService.sendTypingStatus(username, recipient, true);
  };

  const handleInputBlur = () => {
    // Clear typing status when the input field loses focus
    WebSocketService.sendTypingStatus(username, recipient, false);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        paddingLeft: "3rem",
        width: "100%",
      }}
    >
      <div
        className={`d-flex justify-content-center flex-nowrap `}
        style={{ minWidth: "100%" }}
      >
        <div className={` `}>
          <div className={Styles.container}>
            <MessageProfileContainer isExpanded={isExpanded} />
            <ChatContainer
              input={input}
              handleInputChange={handleInputChange}
              messages={messages && messages}
              sendMessage={sendMessage}
              handleInputFocus={handleInputFocus}
              handleInputBlur={handleInputBlur}
            />
          </div>
          {/* Mini Chats */}
          <div className={Styles.MiniChatBoxContainer}>
            <MiniChatBox index={0} />
            <MiniChatBox index={1} />
            <MiniChatBox index={2} />
          </div>
        </div>
        <div className={`p-3 ${Styles.activeProCon}`}>
          <div className={` ${Styles.activePros}`}>
            <img src={Image.user_icon} alt="" srcset="" />
          </div>
          <div className={` ${Styles.activePros}`}>
            <img src={Image.user_icon} alt="" srcset="" />
          </div>
          <div className={` ${Styles.activePros}`}>
            <img src={Image.user_icon} alt="" srcset="" />
          </div>
          <div className={` ${Styles.activePros}`}>
            <img src={Image.user_icon} alt="" srcset="" />
          </div>
          <div className={` ${Styles.activePros}`}>
            <img src={Image.user_icon} alt="" srcset="" />
          </div>
          <div className={` ${Styles.activePros}`}>
            <img src={Image.user_icon} alt="" srcset="" />
          </div>
          <div className={` ${Styles.activePros}`}>
            <img src={Image.user_icon} alt="" srcset="" />
          </div>
          <div className={` ${Styles.activePros}`}>
            <img src={Image.user_icon} alt="" srcset="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserMessages;

import React, { useEffect, useRef, useState } from "react";
import Image from "../../imgData/index.js";
import Socialchild from "./Socialchild.jsx";
import "../Styles/SocialBox.css";
import { Link, useNavigate } from "react-router-dom";
import SignInWithApple from "./SignInwithApple.js";
import YahooSignIn from "./SignInWithYahoo.js";
import FacebookSignIn from "./fblogin.js";
import { useDispatch } from "react-redux";
import  toast from "react-hot-toast";
import GoogleConsoleLogin from "./GoogleConsoleLogin.js";     
import {
  initializeGoogleSignIn,
  handleGoogleSignIn,
} from "./SignInWithGoogle.js";
import { useSelector } from "react-redux";
import { saveUser } from "../../../Redux/slice/userSlice.js";
import { GoogleOAuthProvider } from "@react-oauth/google";

const SocialBox = ({ setShowLoginDialogueBox }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //Tracking the scren size for the tablet and mobile
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [isScreenSmaller, setIsScreenSmaller] = useState(false);

  console.log("Is this screen size smaller than 1024 => ", isScreenSmaller);
  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if (screenSize >= 1024) {
      setIsScreenSmaller(false);
    } else {
      setIsScreenSmaller(true);
    }
  }, [screenSize]);
  const handleAppleSign = async () => {
    try {
      await SignInWithApple(dispatch, navigate, "User", isScreenSmaller);
      setShowLoginDialogueBox(false);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };

  const handleYahooSignIn = async () => {
    try {
      const result = await YahooSignIn(dispatch, navigate, "User");
      console.log("Result after YAHOO sign in", result);
      setShowLoginDialogueBox(false);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };

  const handleGoogleSignInClick = async () => {
    try {
      // Initialize Google Sign-In when the button is clicked
      await initializeGoogleSignIn();

      // If initialization is successful, proceed with sign-in
      await handleGoogleSignIn(dispatch, navigate, "User");
      toast.success("Sign-in successful!");
      setShowLoginDialogueBox(false);
    } catch (error) {
      console.error("Google Sign-In failed:", error);
      toast.error(error.message || "Google Sign-In failed");
    }
  };

  const handleFacebookSignIn = async () => {
    try {
      await FacebookSignIn(dispatch, navigate, "User");
      setShowLoginDialogueBox(false);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };
  const { showLoginDialogue } = useSelector((state) => state.user);
  const handleClicked = () => {
    console.log("button is clicked");
    setShowLoginDialogueBox(false);
    dispatch(saveUser({ showLoginDialogue: false }));
  };

  return (
    <div className="grand_social_parent">
      
      <div className="social_parent">
        <div onClick={handleAppleSign}>
          <Socialchild imgSrc={Image.apple_logo} socialName="Apple" />
        </div>
        <div onClick={handleYahooSignIn}>
          <Socialchild imgSrc={Image.yahooLogo} socialName="Yahoo" />
        </div>
        {/* <div onClick={handleGoogleSignInClick}>
          <Socialchild imgSrc={Image.google_logo} socialName="Google" />
        </div> */}
        <div>
          {/* <GoogleOAuthProvider clientId="854910170922-t0t33tjvq2dubabr5a54j9o89knqvarv.apps.googleusercontent.com"> */}
          <GoogleOAuthProvider clientId="413776152809-kefhp5sb06c160jr9h3ndb9t0svtkpke.apps.googleusercontent.com">
            <GoogleConsoleLogin userType="User" />
          </GoogleOAuthProvider>
        </div>
        <div onClick={handleFacebookSignIn}>
          <Socialchild imgSrc={Image.fb_logo} socialName="Facebook" />
        </div>
        {/* <span className="fb_container">
          <img src={Image.facebookIcon} alt="fb" height={35} />
          <SigninWithFB
            dispatch={dispatch}
            navigate={navigate}
            userType="User"
            callThisFunc={callThisFunc}
          />
        </span> */}
      </div>
      {/* <span style={{ border: "1px solid black" }}>
        {" "}
        <SigninWithFB
          dispatch={dispatch}
          navigate={navigate}
          userType="User"
          callThisFunc={callThisFunc}
        />
      </span> */}

      <div className="parent_dealer_section">
        <div className="dealer_section">
          <Link to="/dealer/login" onClick={handleClicked}>
            Dealers <span>login</span>
          </Link>
        </div>
      </div>

      <p className="toc_and_privacy_policy">
        By creating an account you agree to <br /> Dealer's Auto Center's
      </p>
      <p className="toc_and_privacy_policy2">
        <span>Terms of Use</span> and <span>Privacy Policy</span>
      </p>
    </div>
  );
};

export default SocialBox;

export default async function fetchLanguages() {
    try {
        const response = await fetch('https://www.langlist.com/api/languages');
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching languages:', error);
        // Handle or display the error as needed
    }
}

import React, { useEffect, useRef, useState } from "react";
import styles from "../PaymentHistoryDesktop.module.css";
import { AiFillDelete } from "react-icons/ai";
import { Modal, Table } from "react-bootstrap";
import currentItems from "../../DealerBalanceInventory/sampleTableData";
import axios from "axios";
import { MdEdit, MdEmail } from "react-icons/md";
import ReceiptPDFRender from "./ReceiptPDFRender";
import toast from "react-hot-toast";

const PaymentHistoryDesktop = ({
  PaymentData,
  setPaymentActiveTab,
  setFormData,
  setIsEditing,
}) => {
  console.log("PaymentData => ", PaymentData);
  const [arrayCheckIDToSend, setArrayCheckIDToSend] = useState([]);
  const [showSditEmailContainer, setShowSditEmailContainer] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [itemToEdit, setItemToEdit] = useState(null);

  const itemsPerPage = 20;
  // Add empty rows if necessary
  const emptyRowsCount = Math.max(itemsPerPage - currentItems.length, 0);
  const emptyRows = Array.from({ length: emptyRowsCount }).map((_, index) => (
    <tr key={`empty-${index}`} className={styles.tableRowData2}>
      {[...Array(9)].map((_, i) => (
        <td key={i}>&nbsp;</td>
      ))}
    </tr>
  ));
  const tableRef = useRef(null);

  const [apiData, setApiData] = useState([]);
  console.log("apiData => ", apiData);

  const handleGetDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/sell/get_paymenthistory/${PaymentData.userId}/${PaymentData.id}`
      );
      // console.log("Response from the data => ", response);
      if (response.status === 200) {
        console.log("Response from the data => ", response);
        setApiData(response.data);
      }
    } catch (error) {
      console.log("Error while response from API => ", error);
    }
  };
  useEffect(() => {
    handleGetDetails();
  }, []);

  console.log("arrayCheckIDToSend => ", arrayCheckIDToSend);
  const handleRightClick = (e, item) => {
    e.preventDefault();
    console.log("Item is => ", item);
    setItemToEdit(item);
    setMenuPosition({ x: e.clientX, y: e.clientY });
    setShowSditEmailContainer(!showSditEmailContainer);
  };
  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (!event.target.closest(`.${styles.ContextContainer}`)) {
        setShowSditEmailContainer(false);
      }
    };
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  // handle Edit
  const handleEdit = (item) => {
    console.log("Item to be edited is 74 => ", itemToEdit);
    setShowSditEmailContainer(false);
    let formattedDate;
    if (itemToEdit.nextPaymentDate) {
      const dateParts = itemToEdit.nextPaymentDate?.split("/"); // Assuming the input is in yyyy/mm/dd format
      formattedDate =
        dateParts.length === 3
          ? `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
          : "";
    }

    setFormData((prevData) => ({
      ...prevData,
      dueBalance: itemToEdit.dueBalance,
      latePaymentFees: itemToEdit.latePaymentFees,
      payment: itemToEdit.payment,
      paymentMethod: itemToEdit.paymentMethod,
      nextPaymentDate: itemToEdit.nextPaymentDate ? formattedDate : "",
      balanceDue: itemToEdit.balanceDue,
      notes: itemToEdit.notes,
      mscOthers: itemToEdit.mscOthers,
      reminder: itemToEdit.reminder,
      sendReceiptEmail: itemToEdit.sendReceiptEmail,
      id: itemToEdit.id,
    }));
    setIsEditing(true);
    setPaymentActiveTab("Payment");
  };
  const renderTableRow = (item, index) => {
    console.log("item in the current list => ", item);
    return (
      <>
        <tr
          // className={selectedRows.includes(index) ? "selected-rows" : ""}
          className={`${styles.tableRowData} ${
            index === currentItems.length - 1 ? styles.RowData : ""
          } `}
          key={item.id}
          onContextMenu={(e) =>
            index === 0 ? null : handleRightClick(e, item)
          }
        >
          <td>
            {index !== 0 && (
              <input
                type="checkbox"
                checked={arrayCheckIDToSend.includes(item.id) ? true : false}
                onChange={() => handleCheckDelete(item.id)}
              />
            )}
          </td>

          {item ? (
            <>
              <td>{index}</td>
              <td>{item.paymentDate || "NA"}</td>
              <td> {(index !== 0 && `$ ${item.latePaymentFees}`) || ""}</td>
              <td
                style={{
                  backgroundColor: index === apiData.length - 1 && "#b8d3b3",
                }}
              >
                {`$ ${item.payment}` || ""}
              </td>
              <td>{(index !== 0 && item.paymentMethod) || ""}</td>
              <td>{(index !== 0 && item.nextPaymentDate) || ""}</td>
              <td
                style={{
                  backgroundColor: index === apiData.length - 1 && "#fc9090",
                }}
              >
                {(item.dueBalance && `$ ${item.dueBalance?.toFixed(2)}`) ||
                  "NA"}
              </td>
              <td>{(index !== 0 && `${item.notes}`) || ""}</td>
            </>
          ) : (
            Array.from({ length: 9 }).map((_, i) => <td key={i}>&nbsp;</td>)
          )}
        </tr>
        {showSditEmailContainer && (
          <div
            className={styles.ContextContainer}
            style={{
              position: "absolute",
              top: menuPosition.y - 95,
              left: menuPosition.x - 270,
              // backgroundColor: "white",
              borderRadius: "5px",
              zIndex: 1000,
              width: "110px",
            }}
          >
            <p onClick={() => handleEmailReceipt(item.id)}>
              <span>
                <MdEmail />
              </span>
              <span>Email</span>
            </p>
            <p onClick={() => handleEdit(item)}>
              <span>
                <MdEdit />
              </span>
              <span>Edit</span>
            </p>
          </div>
        )}
      </>
    );
  };

  // delete functionality  starts from here
  const handleCheckDelete = (id) => {
    if (arrayCheckIDToSend.includes(id)) {
      setArrayCheckIDToSend(arrayCheckIDToSend.filter((item) => item !== id));
    } else {
      setArrayCheckIDToSend([...arrayCheckIDToSend, id]);
    }
  };

  const handleDeleteRow = async () => {
    try {
      // delete_paymenthistory/{userId}/{id}
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/sell/delete_paymenthistory/${PaymentData.userId}`,
        arrayCheckIDToSend
      );
      if (response.status === 200) {
        await handleGetDetails();
        setArrayCheckIDToSend([]);
      }
    } catch (error) {
      console.log("Error while response from API => ", error);
    }
  };
  const [pdfArray, setPdfArray] = useState([]);
  const [showPdfArray, setShowPdfArray] = useState(false); 
  const handlePrintfetch = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/sell/view_receipt/${PaymentData.userId}`,
        arrayCheckIDToSend
      );
      if (response.status === 200) {
        console.log("Response from PDF API => ", response);
        setPdfArray(response.data);
        setShowPdfArray(true);
        
      }
    } catch (error) {
      console.log("Error while response from API => ", error);
    }
  };
  
  
  const handleEmailReceipt = async (paymentId) => {
    console.log("Payment iD => ", paymentId);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/sell/send_receipt/${PaymentData.userId}/${paymentId}`
      );
      if (response.status === 200) {
        console.log("Response from PDF API => ", response);
        toast.success("Payment Receipt sent via Email.");
        setShowSditEmailContainer(false)
      }
    } catch (error) {
      console.log("Error while response from API => ", error);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.header}>
          <button onClick={handlePrintfetch}>Print</button>
          <span onClick={handleDeleteRow}>
            <AiFillDelete />
          </span>
        </div>
        <div className={styles.tableWrapper}>
          <Table
            // striped
            bordered
            hover
            ref={tableRef}
            className="shadow mb-0"
            id="table"
          >
            <thead>
              <tr>
                <th></th>
                <th>No. #</th>
                <th>Transaction Date</th>
                <th>Late Payment Fees</th>
                <th>Payment</th>
                <th>Payment Method</th>
                <th>Next Payment Due</th>
                <th>Current Balance</th>
                <th>Notes</th>
              </tr>
            </thead>
            <tbody>
              {apiData && apiData?.map(renderTableRow)}
              {emptyRows}
            </tbody>
          </Table>
          <div className={styles.tableFooter}>
            <p>
              <span>
                <span>Total Transaction : </span>
                <span>{apiData.length}</span>
              </span>
              <span>
                <span>Total Payments : </span>
                <span>
                  ${" "}
                  {apiData &&
                    apiData.reduce((acc, item) => acc + item.payment, 0)}
                </span>
              </span>
            </p>
            <p>
              <span>Current Balance</span>
              <span>
                ${" "}
                {apiData && apiData[apiData && apiData?.length - 1]?.dueBalance}
              </span>
            </p>
          </div>
        </div>
      </div>

      <Modal
        show={showPdfArray}
        onHide={() => setShowPdfArray(false)}
        size="xl"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <ReceiptPDFRender pdfArray={pdfArray && pdfArray} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PaymentHistoryDesktop;

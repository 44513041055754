import React from 'react'
import { Button, Col, Container, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import { MdReplyAll, MdDelete, MdEmail, } from "react-icons/md";
import { PiArrowFatRightFill, PiSuitcaseSimpleFill } from "react-icons/pi";
import { BiSolidBuildings } from "react-icons/bi";
import { FaEdit } from 'react-icons/fa';
import { FaStar, FaPhone, FaArrowUpRightFromSquare } from "react-icons/fa6";

import mailStyle from '../mail.module.css'
import styles from "../Styles/dealerContect.module.css";
import Image from '../../../imgData';


const ContactCard = () => {
    return (
        <Col sm={11} className={`ms-5 border ${mailStyle.mailCard}`}>
            <div className={` ${mailStyle.nameSec}`}>
                <div className={`me-2 ${mailStyle.pro}`}><img src={Image.user_icon} alt="" srcset="" /></div>
                <div className={` ${mailStyle.name}`}>Priyanka</div>
            </div>

            <div className={` ${styles.contactContent}`}>
                <div><div className={` ${mailStyle.icon}`}><MdEmail /></div><p>priyanka12@gmail.com</p></div>
                <div><div className={` ${mailStyle.icon}`}><FaPhone /></div><p>(123) 456-7890</p></div>
                <div><div className={` ${mailStyle.icon}`}><PiSuitcaseSimpleFill /></div><p>Sales Person</p></div>
                <div><div className={` ${mailStyle.icon}`}><BiSolidBuildings /></div><p>Dac</p></div>
            </div>
            <div className={` ${styles.contactIcons}`}>

                <OverlayTrigger
                    placement="bottom-start"
                    overlay={
                        <Tooltip id={`tooltip-edit`} className="custom-tooltip">
                            Edit
                        </Tooltip>
                    }>
                    <span className={`p-1 ${mailStyle.icon}`}>
                        <FaEdit />
                        {/* <FaArrowUpRightFromSquare style={{ padding: '2px' }} /> */}
                    </span>
                </OverlayTrigger>
                <OverlayTrigger
                    placement="bottom-start"
                    overlay={
                        <Tooltip id={`tooltip-delete`} className="custom-tooltip">
                            Delete
                        </Tooltip>
                    }>
                    <span className={` ${mailStyle.icon}`}><MdDelete /></span>
                </OverlayTrigger>
            </div>
        </Col>
    )
}

export default ContactCard
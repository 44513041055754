import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Button, Col, Container, Row, Table, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import { MdModeEdit, MdDelete } from "react-icons/md";
import AdvertisingProfile from "./AdvertisingProfile";
import { useSelector } from 'react-redux';
import Image from "../../imgData";
import "./active.css";
const apiUrl = process.env.REACT_APP_API_URL;
const imgUrl = process.env.REACT_APP_IMG_URL;

const CardView = ({ data, index, selectedCards, handleSelected, handleDelete, onUpdate }) => {
    const { id } = useSelector((state) => state.user);
    const [show, setShow] = useState(false);
    const [socialIcons, setSocialIcons] = useState([]);
    const [isProChecked, setIsProChecked] = useState(true);
    const [advertisingPro, setAdvertisingPro] = useState({
        listing_id: data.id,
        dacmp_status: true,
        dacmp_interval: "",
        unitedmotors_status: true,
        unitedmotors_interval: "",
        google_status: false,
        google_interval: "",
        insta_status: false,
        insta_interval: "",
        facebook_status: false,
        facebook_interval: "",
        craigslist_status: false,
        craigslist_interval: "",
        twitter_status: false,
        twitter_interval: "",
    })

    const handleAddsPro = (e) => {
        e.stopPropagation();
        setShow(true)
        console.log("seting Profils");
        if (data && data.addvertising) {
            setAdvertisingPro((prev) => ({
                ...prev,
                dacmp_interval: data.addvertising.dacmp_interval ?? prev.dacmp_interval,
                unitedmotors_interval: data.addvertising.unitedmotors_interval ?? prev.unitedmotors_interval,
                google_status: data.addvertising.google_status,
                google_interval: data.addvertising.google_interval ?? prev.google_interval,
                insta_status: data.addvertising.insta_status,
                insta_interval: data.addvertising.insta_interval ?? prev.insta_interval,
                facebook_status: data.addvertising.facebook_status,
                facebook_interval: data.addvertising.facebook_interval ?? prev.facebook_interval,
                craigslist_status: data.addvertising.craigslist_status,
                craigslist_interval: data.addvertising.craigslist_interval ?? prev.craigslist_interval,
                twitter_status: data.addvertising.twitter_status,
                twitter_interval: data.addvertising.twitter_interval ?? prev.twitter_interval,
            }));
        }
    }

    const handleCheckboxChange = (e, intervalName) => {
        const { name, checked } = e.target;
        // setAdvertisingPro((prev) => ({ ...prev, [name]: checked }));

        // Reset the interval if the checkbox is unchecked
        const resetInterval = !checked ? "" : advertisingPro[intervalName];

        setAdvertisingPro((prev) => ({
            ...prev,
            [name]: checked,
            [intervalName]: resetInterval,
        }));
    };

    const handleSelectChange = (e) => {
        const { name, value } = e.target;
        setAdvertisingPro((prev) => ({ ...prev, [name]: value }));
    };

    const resetCheckboxes = () => {
        setAdvertisingPro({
            listing_id: data.id,
            dacmp_status: true,
            // dacmp_interval: "",
            unitedmotors_status: true,
            // unitedmotors_interval: "",
            google_status: false,
            google_interval: "",
            insta_status: false,
            insta_interval: "",
            facebook_status: false,
            facebook_interval: "",
            craigslist_status: false,
            craigslist_interval: "",
            twitter_status: false,
            twitter_interval: "",
        })
    }

    // Handle image toggle state for all profiles
    const [connectionStatus, setConnectionStatus] = useState({
        dacmp_connected: false,
        unitedmotors_connected: false,
        google_connected: false,
        insta_connected: false,
        facebook_connected: false,
        craigslist_connected: false,
        twitter_connected: false,
    });

    const toggleConnectionStatus = (profileName) => {
        setConnectionStatus((prevStatus) => ({
            ...prevStatus,
            [profileName]: !prevStatus[profileName],
        }));
    };

    // Function to post advertisingPro data
    const updateAdvertisement = async () => {
        try {
            console.log(advertisingPro);
            // console.log('Payload being sent:', JSON.stringify(advertisingPro, null, 2));

            const response = await fetch(`${apiUrl}/listing/update_advertisement`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(advertisingPro),
            });

            if (response.ok) {
                const updatedData = await response.json();
                console.log('Advertisement updated successfully:', updatedData);

                // Notify parent component of the update
                if (onUpdate) onUpdate(data.id, advertisingPro);

                // Update local state with the new data
                // setAdvertisingPro((prev) => ({
                //     ...prev,
                //     ...updatedData, // Assume the API response contains the updated fields
                // }));

            } else {
                console.error('Failed to update advertisement:', response.statusText);
                // Handle the error response as needed
            }
        } catch (error) {
            console.error('Error updating advertisement:', error);
            // Handle any errors that occur during the fetch
        }
        finally {
            setShow(false)
        }
    };

    const navigate = useNavigate();
    const location = useLocation();
    // Extract data and originalData from location state
    const originalData = location.state?.originalData || {};
    const updatedData = location.state?.updatedData || {};

    console.log("location", location);
    console.log("updatedData", updatedData);
    console.log("originalData", originalData);

    // Function to check if a field has been updated
    const isFieldUpdated = (fieldName) => {
        if (data.vin === updatedData.vin) {
            return originalData[fieldName] !== updatedData[fieldName];
        }
    };

    const handleLinkClick = (vin) => {
        // navigate("/myprofile/addlist", { state: { vin, active: true } });
        navigate("/myprofile/addlist", { state: { vin, data, active: true } });
    };

    const Navigate = useNavigate();

    const handleGoToSell = () => {
        // Make sure that 'data' is correctly defined and holds the necessary information
        Navigate(`/myprofile/sell#${data.stock_no}#${data.id}`, { state: { data } });
    };

    useEffect(() => {
        if (data && data.addvertising) {
            const newSocialIcons = [];
            const advertisingData = data.addvertising;

            // Iterate over the keys of the advertisingData object
            for (const key in advertisingData) {
                // Check if the key ends with '_status' and its value is true
                if (key.endsWith("_status") && advertisingData[key]) {
                    // Extract the social profile name from the key
                    const profile = key.split("_status")[0];
                    // Push the profile name to the newSocialIcons array
                    newSocialIcons.push(profile);
                }
            }
            // Set the socialIcons state with the newSocialIcons array
            setSocialIcons(newSocialIcons);
        }
    }, [data]);

    const colorMapping = {
        Beige: "#F5F5DC",
        Black: "#151517",
        Blue: "#2c447b",
        Bronze: "#655e5f",
        Brown: "brown",
        "Dark Gray": "#a6b0b6",
        "Dark Red": "#802630",
        Grey: "#808080",
        "Light Gray": "#c6ccd4",
        Magenta: "#916075",
        Maroon: "#8e373e",
        "Off White": "#f4ebdd",
        Orange: "#f79057",
        Pink: "#d60f6f",
        Purple: "#6316bc",
        Red: "#de0a06",
        Silver: "#d4d4d4",
        Tan: "#c3793f",
        White: "#ffffff",
        Yellow: "#f5d512",
        Burgundy: "#800020",
        Charcoal: "#36454F",
        Gold: "#FFD700",
        Green: "#008000",
    };

    // Set the background color dynamically based on the received color names
    const exteriorBackgroundColor = colorMapping[data.exteriorColor];
    const interiorBackgroundColor = colorMapping[data.interiorColor];

    const calculateDaysDifferencepurchase = (purchasedDate) => {
        if (!purchasedDate) return 0; // Ensure formData.inspection is used for calculation
        const currentDate = new Date();
        const selectedDate = new Date(purchasedDate);
        const timeDifference = selectedDate.getTime() - currentDate.getTime();
        const daysDifference = Math.ceil(
            Math.abs(timeDifference) / (1000 * 60 * 60 * 24)
        );
        console.log(daysDifference);
        return daysDifference;
    };

    const { all_images, exterior_images, misc_images, interior_images, three_sixty_images } = data.multimedia;
    const noImage = Image.noImage;

    const getFirstValidImage = (images) => {
        if (images) {
            const imageList = images.split(",").map((img) => img.trim());
            for (let image of imageList) {
                if (image) {
                    return `${imgUrl}/${id}/${image}`;
                }
            }
        }
        return null;
    };

    const imageUrl =
        (all_images && getFirstValidImage(all_images)) ||
        (exterior_images && getFirstValidImage(exterior_images)) ||
        (misc_images && getFirstValidImage(misc_images)) ||
        (interior_images && getFirstValidImage(interior_images)) ||
        (three_sixty_images && getFirstValidImage(three_sixty_images)) ||
        noImage;

    console.log("socialIcons", socialIcons);
    console.log("data", data);
    console.log("advertisingPro", advertisingPro);

    return (
        <>
            <Container className="px-0 bg-tr1">
                <Row className="mx-0 rounded bg-body border-2 bg-tr1"
                    style={{ position: "relative", zIndex: "5 " }}>
                    {/* <div className="" style={{position:"relative"}}> */}
                    <Col sm={1} className="selection-box px-0" key={index}>
                        <div className="card-count p-auto">{index + 1}</div>
                        <div className="selection-btn">
                            <img src={selectedCards && selectedCards.includes(index + 1) ? Image.selected : Image.select} alt={selectedCards && selectedCards.includes(index + 1) ? "selected" : "select"} className="select-img"
                            />
                            {/* {selectedCards.includes(index) ? "S" : "N"} */}
                        </div>
                    </Col>

                    <Col sm={11} className="px-2 cards bg-tr1">
                        <Container fluid className="d-flex flex-nowrap  mb-4" onClick={() => { handleSelected(index + 1) }}>
                            <Row className={`rounded border border-2 p-2 ${selectedCards && selectedCards.includes(index + 1) ? "bg-card" : ""}`}>
                                <Col md={12} className="" style={{ paddingRight: '2%' }}>
                                    <Container fluid className="px-0">
                                        <Row>
                                            <Col lg={3} md={5} className="shadow px-2 image-con">
                                                <div className="product-actives">
                                                    <img src={imageUrl} className="" alt="Inventory Car Image" />
                                                    <div className="d-flex flex-row justify-content-between mt-2 align-items-center">
                                                        <span className="add-date" style={{ color: "#D1823A" }}>
                                                            Added: {data.created_at}
                                                        </span>
                                                        <span className="Exteriors rounded-pill"
                                                            style={{ backgroundColor: exteriorBackgroundColor }}>
                                                            Exterior
                                                        </span>
                                                        <span className="Interiors rounded-pill" style={{ backgroundColor: interiorBackgroundColor }}>
                                                            Interior
                                                        </span>
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col lg={7} md={7}>
                                                <Container fluid className="px-0">
                                                    <Row>
                                                        <Col sm={12} className="px-0">
                                                            <Container>
                                                                <Row className="d-flex justify-content-between">
                                                                    <Col sm={10} className="card-head" onClick={() => handleLinkClick(data.vin)} style={{ textShadow: isFieldUpdated("trim") ? '#ff8c21 1px 0 10px' : 'black', }}>
                                                                        {data.year_Make_Model} {data.trim}
                                                                    </Col>
                                                                </Row>
                                                            </Container>
                                                        </Col>
                                                        <Col sm={12} className="px-0">
                                                            <Container>
                                                                <Row className="sub-head data-font d-flex justify-content-between">
                                                                    <Col md={4} className="text-nowrap mx-2 px-1 vin fw-bold" onClick={() => handleLinkClick(data.vin)} >
                                                                        <span className="orange-color ">VIN:</span>
                                                                        {data.vin}{" "}
                                                                    </Col>
                                                                    <Col className="px-0 ">
                                                                        <Container>
                                                                            <Row className=" ">
                                                                                {data.mileage_in ?
                                                                                    <Col sm={3} className=" text-nowrap fw-bold gray-color border-2 px-2"
                                                                                        style={{
                                                                                            textShadow: isFieldUpdated("mileage_in") ? '#ff8c21 1px 0 10px' : 'black', color: isFieldUpdated("mileage_in")
                                                                                                ? "orange"
                                                                                                : "black",
                                                                                        }}>
                                                                                        {data.mileage_in}
                                                                                    </Col>
                                                                                    : null
                                                                                }

                                                                                {data.engine
                                                                                    ? <Col className=" border-2 px-0"
                                                                                        style={{ textShadow: isFieldUpdated("engine") ? '#ff8c21 1px 0 10px' : 'black', }}>
                                                                                        {data.engine}
                                                                                    </Col>
                                                                                    : null
                                                                                }
                                                                            </Row>
                                                                        </Container>
                                                                    </Col>
                                                                </Row>
                                                            </Container>
                                                        </Col>
                                                        <Col sm={12} className="px-0">
                                                            <Container fluid>
                                                                <Row>
                                                                    <Col>
                                                                        <Container fluid>
                                                                            <Row className="text-center data-font">
                                                                                <Col className="border-end border-2 px-0 "
                                                                                    style={{
                                                                                        textShadow: isFieldUpdated("purchasedDate") ? '#ff8c21 1px 0 10px' : 'black',
                                                                                        // color: isFieldUpdated("trim") ? "orange" : "black",
                                                                                    }}>
                                                                                    <OverlayTrigger
                                                                                        placement="bottom-end"
                                                                                        overlay={
                                                                                            <Tooltip id={`tooltip-${index}`} className="custom-tooltip">
                                                                                                Days In: {calculateDaysDifferencepurchase(data.purchasedDate)}
                                                                                            </Tooltip>
                                                                                        }>
                                                                                        <div>
                                                                                            P.Date
                                                                                            <p className="orange-color">
                                                                                                {data.purchasedDate}
                                                                                            </p>
                                                                                        </div>
                                                                                    </OverlayTrigger>
                                                                                </Col>
                                                                                {/* <Col className="border-end border-2 px-0"
                                                                                    style={{
                                                                                        textShadow: isFieldUpdated("inspection") ? '#ff8c21 1px 0 10px' : 'black',
                                                                                        // color: isFieldUpdated("trim") ? "orange" : "black",
                                                                                    }}>
                                                                                    Days in{" "}
                                                                                    <p className="orange-color">
                                                                                        {data.daysDifference}
                                                                                    </p>
                                                                                </Col> */}
                                                                                <Col className="border-end border-2 px-0"
                                                                                    style={{
                                                                                        textShadow: isFieldUpdated("inspection") ? '#ff8c21 1px 0 10px' : 'black',
                                                                                        // color: isFieldUpdated("trim") ? "orange" : "black",
                                                                                    }}>
                                                                                    <OverlayTrigger
                                                                                        placement="bottom-end"
                                                                                        overlay={
                                                                                            <Tooltip id={`tooltip-${index}`} className="custom-tooltip">
                                                                                                Days In: {data.daysDifference}
                                                                                            </Tooltip>
                                                                                        }>
                                                                                        <div>
                                                                                            Inspection
                                                                                            <p className="orange-color">
                                                                                                {data.inspection}
                                                                                            </p>
                                                                                        </div>
                                                                                    </OverlayTrigger>
                                                                                </Col>
                                                                                <Col className="border-end border-2 px-0">
                                                                                    All leaads{" "}
                                                                                    <p className="orange-color">23</p>
                                                                                </Col>
                                                                                <Col className="border-end border-2 px-0"
                                                                                    style={{
                                                                                        textShadow: isFieldUpdated("listing_price") ? '#ff8c21 1px 0 10px' : 'black',
                                                                                        // color: isFieldUpdated("trim") ? "orange" : "black",
                                                                                    }}>
                                                                                    Listing price
                                                                                    <p className="orange-color">
                                                                                        ${data.listing_price}
                                                                                    </p>
                                                                                </Col>
                                                                                <Col className=" px-0"
                                                                                    style={{
                                                                                        textShadow: isFieldUpdated("market_value_price") ? '#ff8c21 1px 0 10px' : 'black',
                                                                                        // color: isFieldUpdated("trim") ? "orange" : "black",
                                                                                    }}>
                                                                                    Market value{" "}
                                                                                    <p className="orange-color">
                                                                                        ${data.market_value_price}
                                                                                    </p>
                                                                                </Col>
                                                                            </Row>
                                                                        </Container>
                                                                    </Col>
                                                                </Row>
                                                            </Container>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mt-1" style={{ marginRight: '1%' }}>
                                                        <Col sm={12} className=" d-flex align-items-end">
                                                            <Button className=" border-0 p-1 bg-orange fw-bold" onClick={(e) => handleAddsPro(e)} >Advertised on</Button>
                                                            <div className="d-flex justify-content-evenly flex-wrap">
                                                                {socialIcons.map((iconName, index) => (
                                                                    <img key={index}
                                                                        className="m-2 mx-3 social-icon"
                                                                        src={Image[iconName]} // Corrected
                                                                        alt={iconName}
                                                                        width="25px"
                                                                    />
                                                                ))}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </Col>

                                            <Col className=" px-1">
                                                <h6 className="data-font text-nowrap stock-no mb-1" onClick={() => handleLinkClick(data.vin)}>
                                                    <span className="orange-color"> Stock. no # </span> {data.stock_no}
                                                </h6>
                                                <div className="d-flex flex-column justify-content-around">
                                                    <Button className="product-g-btns fw-bold p-1" onClick={handleGoToSell}> Make A Sale </Button>
                                                    <Button className="btn-sec border-0 fw-bold p-1" variant="secondary">Docs</Button>
                                                    <Button className="btn-sec border-0 fw-bold p-1" variant="secondary">Cost Info</Button>
                                                    <Button className="btn-sec border-0 fw-bold p-1" variant="secondary">VHR</Button>
                                                </div>
                                            </Col>
                                        </Row>

                                    </Container>
                                </Col>
                            </Row>
                            <div className="row mb-3 h-100" style={{ display: 'inline-block', width: '5%', position: 'absolute', right: '0%' }}>
                                <Container fluid className="px-0 h-100">
                                    <div className="row d-flex flex-nowrap h-100">

                                        <div className="col-3 h-100 px-0 d-flex flex-column justify-content-between">
                                            <div className="d-flex align-items-center justify-content-around" style={{ height: "100%" }}>
                                                <div className="vr pr-2 mx-1"></div>
                                                <div className="d-flex flex-column align-items-center justify-content-around h-100">
                                                    <div className=" ml-3 rounded-circle px-2 py-1 bg-light edit" onClick={() => handleLinkClick(data.vin)} >
                                                        <MdModeEdit />
                                                    </div>
                                                    <div className=" ml-3 rounded-circle px-2 py-1 bg-light edit"
                                                        onClick={(event) =>
                                                            handleDelete(event, data.id, index)
                                                        }>
                                                        <MdDelete />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Container>
                            </div>
                        </Container>
                    </Col>
                </Row>
            </Container>

            <Modal
                show={show}
                size='xl'
                onHide={() => setShow(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title">
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        Advertising Manager
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div id="refresh-btn-seller">
                            <button type="button" id="adlst-reset-btn-seller" onClick={resetCheckboxes} title="Reset">
                                <img src={Image.reset_button} alt="Reset Button" />
                            </button>
                        </div>
                        <div className="tab-buttons">
                            <section className="container">
                                <div className="row">
                                    {/* Card for Dealers Auto Center Market Place */}
                                    <AdvertisingProfile
                                        title="Dealers Auto Center Market Place"
                                        logo={Image.darkLogo}
                                        statusName="dacmp_status"
                                        intervalName="dacmp_interval"
                                        status={advertisingPro.dacmp_status}
                                        interval={advertisingPro.dacmp_interval}
                                        handleCheckboxChange={handleCheckboxChange}
                                        handleSelectChange={handleSelectChange}
                                        toggleImage={() => toggleConnectionStatus("dacmp_connected")}
                                        isConnected={connectionStatus.dacmp_connected}
                                        readonly={true}
                                    />

                                    {/* Card for United Motors */}
                                    <AdvertisingProfile
                                        title="Website (United Motors)"
                                        logo={Image.darkLogo}
                                        statusName="unitedmotors_status"
                                        intervalName="unitedmotors_interval"
                                        status={advertisingPro.unitedmotors_status}
                                        interval={advertisingPro.unitedmotors_interval}
                                        handleCheckboxChange={handleCheckboxChange}
                                        handleSelectChange={handleSelectChange}
                                        toggleImage={() => toggleConnectionStatus("unitedmotors_connected")}
                                        isConnected={connectionStatus.unitedmotors_connected}
                                        readonly={true}
                                    />

                                    {/* Card for Google Market Place */}
                                    <AdvertisingProfile
                                        title="Google Market Place"
                                        logo={Image.google}
                                        statusName="google_status"
                                        intervalName="google_interval"
                                        status={advertisingPro.google_status}
                                        interval={advertisingPro.google_interval}
                                        handleCheckboxChange={handleCheckboxChange}
                                        handleSelectChange={handleSelectChange}
                                        toggleImage={() => toggleConnectionStatus("google_connected")}
                                        isConnected={connectionStatus.google_connected}
                                    />

                                    {/* Card for Instagram */}
                                    <AdvertisingProfile
                                        title="Instagram"
                                        logo="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/2048px-Instagram_logo_2016.svg.png"
                                        statusName="insta_status"
                                        intervalName="insta_interval"
                                        status={advertisingPro.insta_status}
                                        interval={advertisingPro.insta_interval}
                                        handleCheckboxChange={handleCheckboxChange}
                                        handleSelectChange={handleSelectChange}
                                        toggleImage={() => toggleConnectionStatus("insta_connected")}
                                        isConnected={connectionStatus.insta_connected}
                                    />

                                    {/* Card for Facebook */}
                                    <AdvertisingProfile
                                        title="Facebook"
                                        logo={Image.facebook}
                                        statusName="facebook_status"
                                        intervalName="facebook_interval"
                                        status={advertisingPro.facebook_status}
                                        interval={advertisingPro.facebook_interval}
                                        handleCheckboxChange={handleCheckboxChange}
                                        handleSelectChange={handleSelectChange}
                                        toggleImage={() => toggleConnectionStatus("facebook_connected")}
                                        isConnected={connectionStatus.facebook_connected}
                                    />

                                    {/* Card for Craigslist */}
                                    <AdvertisingProfile
                                        title="Craigslist"
                                        logo={Image.craigslist}
                                        statusName="craigslist_status"
                                        intervalName="craigslist_interval"
                                        status={advertisingPro.craigslist_status}
                                        interval={advertisingPro.craigslist_interval}
                                        handleCheckboxChange={handleCheckboxChange}
                                        handleSelectChange={handleSelectChange}
                                        toggleImage={() => toggleConnectionStatus("craigslist_connected")}
                                        isConnected={connectionStatus.craigslist_connected}
                                    />

                                    {/* Card for Twitter */}
                                    <AdvertisingProfile
                                        title="Twitter"
                                        logo={Image.twitter_x_logo}
                                        statusName="twitter_status"
                                        intervalName="twitter_interval"
                                        status={advertisingPro.twitter_status}
                                        interval={advertisingPro.twitter_interval}
                                        handleCheckboxChange={handleCheckboxChange}
                                        handleSelectChange={handleSelectChange}
                                        toggleImage={() => toggleConnectionStatus("twitter_connected")}
                                        isConnected={connectionStatus.twitter_connected}
                                    />
                                </div>
                            </section>
                        </div>
                        <div className="d-flex justify-content-center ">
                            <Button className="btn-orange border-0 py-2 px-3" onClick={updateAdvertisement}>Publish</Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

// Define the calculateDaysDifference function outside of ListView
const calculateDaysDifference = (inspectionDate) => {
    if (!inspectionDate) return 0;
    const currentDate = new Date();
    const inspectionDateObj = new Date(inspectionDate);
    const timeDifference = inspectionDateObj - currentDate;
    return Math.ceil(Math.abs(timeDifference) / (1000 * 60 * 60 * 24));
};

export default CardView;
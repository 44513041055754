import React from 'react'
import { Col, Container, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import { IoPersonAddOutline } from "react-icons/io5";
import styles from '../Styles/dealerContect.module.css';
import MailCard from '../components/MailCard';
import ContactCard from '../components/ContactCard';

const DealerContacts = ({ handleActivePage }) => {
    return (
        <>
            <Col sm={12}>
            <OverlayTrigger
                placement="bottom-start"
                overlay={
                    <Tooltip id={`tooltip-addContact`} className="custom-tooltip">
                        Add Contact
                    </Tooltip>
                }>
                <div className={`  ${styles.addContact}`} onClick={(e) => handleActivePage("Contact Form", e)}><IoPersonAddOutline fill='black' /></div>
            </OverlayTrigger>

            <Container className={`my-5 `}>
                <Row>
                    <ContactCard />
                    <ContactCard />
                    <ContactCard />
                    <ContactCard />
                </Row>
                </Container>
            </Col>
        </>

    )
}

export default DealerContacts
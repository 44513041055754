import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { MdArrowLeft, MdArrowRight } from "react-icons/md";
import { FaSearch } from "react-icons/fa";

import styles from "../Styles/DalerCalendar.module.css";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

const localizer = momentLocalizer(moment);

// const CustomDayView = ({ date, onDateClick }) => {
//   const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
//   const startOfWeek = moment(date).startOf('week');

//   return (
//     <div className="custom-day-view">
//       <div className="days-header">
//         {daysOfWeek.map((day, index) => (
//           <div key={index} className="day-header">{day}</div>
//         ))}
//       </div>
//       <div className="days-content">
//         {Array.from({ length: 7 }).map((_, index) => {
//           const day = startOfWeek.clone().add(index, 'days').format('D');
//           const fullDate = startOfWeek.clone().add(index, 'days').format('YYYY-MM-DD');

//           return (
//             <div
//               key={index}
//               className="day-content"
//               onClick={() => onDateClick(fullDate)}
//             >
//               <span className="day-date">{day}</span>
//             </div>
//           );
//         })}
//       </div>
//     </div>
//   );
// };

// const CustomDayView = ({ date }) => {
//   const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
//   const startOfWeek = moment(date).startOf('week');

//   return (
//     <div className="custom-day-view">
//       <div className="days-header">
//         {daysOfWeek.map((day, index) => (
//           <div key={index} className="day-header">{day}</div>
//         ))}
//       </div>
//       <div className="days-content">
//         {Array.from({ length: 7 }).map((_, index) => {
//           const day = startOfWeek.clone().add(index, 'days').format('D');
//           const fullDate = startOfWeek.clone().add(index, 'days').format('YYYY-MM-DD');

//           return (
//             <div
//               key={index}
//               className="day-content"
//               onClick={() => alert(`Selected Date: ${fullDate}`)} // Example action for date selection
//             >
//               <span className="day-date">{day}</span>
//             </div>
//           );
//         })}
//       </div>
//     </div>
//   );
// };

// const CustomDayView = ({ date, events }) => {
//   const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
//   const startOfWeek = moment(date).startOf('week');

//   return (
//     <div className="custom-day-view">
//       <div className="days-header">
//         {daysOfWeek.map((day, index) => (
//           <div key={index} className="day-header">{day}</div>
//         ))}
//       </div>
//       <div className="days-content">
//         {Array.from({ length: 7 }).map((_, index) => {
//           const day = startOfWeek.clone().add(index, 'days').format('D');
//           return (
//             <div key={index} className="day-content">
//               <span className="day-date">{day}</span>
//               {/* Display events for the day if needed */}
//             </div>
//           );
//         })}
//       </div>
//     </div>
//   );
// };

const DealerCalendar = () => {

  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateSelection = (date) => {
    console.log("date", date);
    setSelectedDate(date);
  };

  const events = [
    {
      title: 'Meeting',
      start: new Date(),
      end: new Date(moment().add(1, 'hour').toDate()),
    },
  ];

  const CustomToolbar = (toolbar) => {
    const goToBack = () => {
      toolbar.onNavigate('PREV');
    };
    const goToNext = () => {
      toolbar.onNavigate('NEXT');
    };
    const goToCurrent = () => {
      toolbar.onNavigate('TODAY');
    };

    return (
      <div className="custom-toolbar">
        <button onClick={goToBack} className="toolbar-btn"><MdArrowLeft /></button>
        <span className="toolbar-label">
          {toolbar.label}
        </span>
        <button onClick={goToNext} className="toolbar-btn"><MdArrowRight /></button>
        {/* <button onClick={goToCurrent} className="toolbar-btn">Today</button> */}
      </div>
    );
  };

  const CustomDayView = ({ date, onDateClick, selectedDate }) => {
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const startOfWeek = moment(date).startOf('week');
    const today = moment().startOf('day');

    const isCurrentDate = (date) => moment(date).isSame(today, 'day');
    const isSelectedDate = (date) => moment(date).isSame(selectedDate, 'day');

    return (
      <div className="custom-day-view">
        <div className="days-header">
          {daysOfWeek.map((day, index) => (
            <div key={index} className="day-header">{day}</div>
          ))}
        </div>
        <div className="days-content">
          {Array.from({ length: 7 }).map((_, index) => {
            const dayDate = startOfWeek.clone().add(index, 'days').toDate();
            const isCurrent = isCurrentDate(dayDate);
            const isSelected = isSelectedDate(dayDate);

            return (
              <div
                key={index}
                className={`day-content ${isCurrent ? 'current-date' : ''} ${isSelected ? 'selected-date' : ''}`}
                onClick={() => onDateClick(dayDate)}
              >
                <span className="day-date">{startOfWeek.clone().add(index, 'days').format('D')}</span>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <Col sm={12}>
      <Container fluid>
        <Row>
          <Col sm={3} className={`border-end ${styles.sidebar}`}>

            <div className={` ${styles.emailPro}`}>
              <select>
                <option>priyanka12@gmail.com</option>
              </select>
            </div>

            <div className="custom-calendar">
              <Calendar
                localizer={localizer}
                // events={events}
                defaultView="month"
                // defaultView={Views.MONTH}
                views={['month']}
                components={{
                  toolbar: CustomToolbar,
                  // day: (props) => <CustomDayView {...props} onDateClick={handleDateSelection} selectedDate={selectedDate} />,
                  day: (props) => {
                    console.log('Rendering CustomDayView', props); // Debug log
                    return <CustomDayView {...props} onDateClick={handleDateSelection} selectedDate={selectedDate} />;
                  },
                }}
                style={{ height: 600 }}

              // components={{
              //   toolbar: CustomToolbar,
              //   day: CustomDayView,
              // }}

              />
              {/* {selectedDate && <div>Selected Date: {selectedDate}</div>} */}
            </div>

            <div className={` ${styles.eventCon}`}>
              <div className={` ${styles.eventHead}`}><FaSearch /> <span>All Calendar Items</span></div>
              <ul className={` ${styles.eventList}`}>
                <li className={` ${styles.event}`}>Meeting with Mr. Emran</li>
                <li className={` ${styles.event}`}>Appointment Remainder</li>
                <li className={` ${styles.event}`}>Meeting with Mr. Emran</li>
              </ul>
            </div>
          </Col>
          <Col sm={9}>
            <Container fluid className="px-0 h-100">
              <Row className="px-0 h-100">
                <Col sm={5} className="px-0 border-end h-100">
                  <Container className="">
                    <Row className={` ${styles.dayWeekCon}`}>
                      <Col sm={4} className={` ${styles.day}`}>Day</Col>
                      <Col sm={4} className={` ${styles.week}`}>Week</Col>
                      <Col sm={4} className={` ${styles.month}`}>Month</Col>
                      <Col sm={12} className="d-flex justify-content-between my-1" style={{ backgroundColor: '#e7c19c' }}>
                        <div className="day">Friday</div>
                        <div className="date">{selectedDate}</div>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={12} className="d-flex justify-content-center ">
                        <div className={` ${styles.am}`}>AM</div>
                        <div className={` ${styles.pm}`}>PM</div>
                      </Col>

                      <Col sm={12} className={`mt-2 ${styles.slot}`}>
                        <div className="">12:00</div>
                        <div className="">01:00</div>
                        <div className="">02:00</div>
                        <div className="">03:00</div>
                        <div className="">04:00</div>
                        <div className="">05:00</div>
                        <div className={` ${styles.selected}`}>06:00</div>
                        <div className="">07:00</div>
                        <div className="">08:00</div>
                        <div className="">09:00</div>
                        <div className="">10:00</div>
                        <div className="">11:00</div>
                      </Col>
                    </Row>
                  </Container>
                </Col>
                <Col sm={7} className={`my-4 ${styles.selectedSlot}`}>
                  <div className={` ${styles.selectedDay}`}>Fri</div>
                  <div className={`my-auto ${styles.selectedDate}`}>{moment(selectedDate).format('YYYY-MM-DD')}</div>
                  <div className={` ${styles.selectedTime}`}><p className={` m-0 `}>06</p><span> pm</span></div>
                </Col>
              </Row>
            </Container></Col>
        </Row>
      </Container>
    </Col>
  )
}

export default DealerCalendar
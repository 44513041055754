import React from "react";
import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend
);

const DealerDoughNutChart3 = () => {
  const data = {
    labels: ["Desktop", "Tablet", "Mobile"],
    datasets: [
      {
        label: "Marketing Channels",
        data: [2000, 3000, 4000],
        backgroundColor: ["#FF6384", "#36a2eb", "#FFCE56", "#4BC0C0", "#9966FF"],
        hoverBackgroundColor: ["#FF6384", "#36a2eb", "#FFCE56", "#4BC0C0", "#9966FF"],
        borderWidth: 0, // Set the border width to 0 to remove the border
        borderColor: "transparent" // Set the border color to transparent
      },
    ],
  };

  const options = {
    responsive: true,
     layout: {
    padding: {
      top: 20,
      right: 20,
      bottom: 20,
      left: 20
    }
  },
    plugins: {
      legend: {
        position: 'bottom',
        padding: 50,
        labels: {
          boxWidth: 20, // Decrease the box width to reduce the gap between the doughnut and the text
          padding: 20 ,// Increase the padding to add more space between the doughnut and the text
          color: "white" // White with transparency
        }
      },
      title: {
        display: true,
        text: 'Marketing Channels Performance',
        color: "white"
        
      },
    },
    cutout: '70%' // Decrease the cutout percentage to make the doughnut thinner
  };

  return (
    <div style={{
      backgroundColor: '#2a2b4a',
      padding: '10px',
      borderRadius: '10px',
      color: "white",
      width: '500px',
      height: '350px',
      display : "flex",
      justifyContent : "center",
        margin: 'auto',
        //  border : "2px solid red"
    }}>
      <Doughnut data={data} options={options} />
    </div>
  );
};

export default DealerDoughNutChart3;

import React, { useState } from "react";
import Image from "../../../imgData";
import "../../home.css";

const VehicleReport1 = () => {
  const [isLicenseActive, setIsLicenseActive] = useState(false);
  return (
    <section
      style={{
        //   backgroundImage: `url(${Image.car_bg3})`,
        //   backgroundPosition: "center",
        // border: " 1px solid black",
        marginTop: "1rem",
        backgroundColor: "#dbd9d7",
      }}
      className="p-lg-5 pb-5"
    >
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className=" row d-flex justify-content-center">
            {/* <div className="col-lg-3"></div> */}
            <div className="col-lg-5">
              <div className="p-4  text-center">
                <h1 style={{ fontWeight: "700" }}>We buy cars</h1>
                <h4>Get Started now by entering the VIN below</h4>
              </div>
              <div
                className="card  shadow"
                style={{
                  backgroundColor: "black",
                  borderRadius: "10px",
                  padding: "2rem",
                }}
              >
                {/* <!-- <h2 className="text-center p-3">Add Listing</h2> --> */}
                <nav>
                  <div
                    className="nav nav-tabs  nav-justified"
                    id="nav-tab"
                    role="tablist"
                  >
                    <button
                      className="nav-link "
                      id="nav-home-tab1"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-home1"
                      type="button"
                      role="tab"
                      aria-controls="nav-home"
                      aria-selected="true"
                      style={{
                        color: !isLicenseActive ? "#d39c68" : "white",
                        fontWeight: "600",
                        fontSize: "large",
                        backgroundColor: !isLicenseActive ? "#eaeaea" : "black",
                      }}
                      onClick={() => setIsLicenseActive(false)}
                    >
                      VIN
                    </button>
                    <button
                      className="nav-link"
                      id="nav-profile-tab2"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-profile1"
                      type="button"
                      role="tab"
                      aria-controls="nav-profile"
                      aria-selected="false"
                      style={{
                        color: isLicenseActive ? "#d39c68" : "white",
                        fontWeight: "600",
                        fontSize: "large",
                        backgroundColor: isLicenseActive ? "#eaeaea" : "black",
                      }}
                      onClick={() => setIsLicenseActive(true)}
                    >
                      Licenses
                    </button>
                  </div>
                </nav>
                <div
                  className="tab-content p-3 border "
                  id="nav-tabContent "
                  style={{
                    minWidth: "auto",
                    backgroundColor: "#eaeaea",
                  }}
                >
                  <div
                    className="tab-pane fade active show"
                    id="nav-home1"
                    role="tabpanel"
                    aria-labelledby="nav-home-tab1"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div className="input-group">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter VIN Number"
                      />{" "}
                      <span className="input-group-btn">
                        <button className="btntttt btn-theme" type="submit">
                          {" "}
                          Search{" "}
                        </button>
                      </span>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade d-flex"
                    id="nav-profile1"
                    role="tabpanel"
                    aria-labelledby="nav-profile-tab2"
                  >
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter state"
                      />{" "}
                      <span className="input-group-btn">
                        <button className="btntttt btn-theme" type="submit">
                          {" "}
                          Search{" "}
                        </button>
                      </span>
                    </div>
                    <div className="input-group mt-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter License Number"
                      />{" "}
                      <span className="input-group-btn">
                        <button className="btntttt btn-theme" type="submit">
                          {" "}
                          Search{" "}
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VehicleReport1;

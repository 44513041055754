import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { PDFDocument } from "pdf-lib";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const PDFViewer2 = ({ pdfArray, isCallFunc, setisCallFunc }) => {
  const [mergedPdfUrl, setMergedPdfUrl] = useState(null);
  console.log("Merged PDF URL in pdf 2", mergedPdfUrl);
  const userID = useSelector((state) => state.user);
  const selectedCar = useSelector((state) => state.sell.selectedVehicle);

  // Method to merge PDFs
  const mergePDFs = async (pdfArray) => {
    try {
      const mergedPdf = await PDFDocument.create();
      for (const item of pdfArray) {
        const existingPdfBytes = await fetch(
          `https://5starsmotors.com/documents/${userID.id}/${item.pdf}`
        ).then((res) => res.arrayBuffer());
        const pdf = await PDFDocument.load(existingPdfBytes);
        const copiedPages = await mergedPdf.copyPages(
          pdf,
          pdf.getPageIndices()
        );
        copiedPages.forEach((page) => mergedPdf.addPage(page));
      }

      const mergedPdfBytes = await mergedPdf.save();
      const mergedPdfBlob = new Blob([mergedPdfBytes], {
        type: "application/pdf",
      });
      const url = URL.createObjectURL(mergedPdfBlob);
      return url;
    } catch (error) {
      toast.error(
        "Sorry the PDFs could not be merged at the moment. Try again later",
        error.message
      );
    }
  };

  useEffect(() => {
    const mergeAndSetPdf = async () => {
      const url = await mergePDFs(pdfArray);
      setMergedPdfUrl(url);
    };

    mergeAndSetPdf();
  }, []);

  const downloadMergedPDF = () => {
    if (mergedPdfUrl) {
      const link = document.createElement("a");
      link.href = mergedPdfUrl;
      link.download = `${selectedCar?.year_Make_Model} ${
        selectedCar?.trim
      } ${selectedCar?.vin.slice(-8)}${userID.id}`;
      document.body.appendChild(link);
      link.click();
      console.log("Downloading the PDFs");
      document.body.removeChild(link);
    } else {
      toast.error("PDF is not ready for download yet.");
    }
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (isCallFunc && mergedPdfUrl) {
      console.log("Downloading the PDFs in useEffect");
      downloadMergedPDF();
      // downloadMergedPDF(() => {
      //   navigate("/dealer/inventory/sold", { state: true });
      // });
      setisCallFunc(false);
    }
  }, [isCallFunc, mergedPdfUrl]);

  // useEffect(() => {
  //   if (mergedPdfUrl) {
  //     // Initialize the Adobe viewer once the mergedPdfUrl is ready
  //     const adobeDCView = new window.AdobeDC.View({
  //       // clientId: "5d6c7dca7b44405c8c25b6675b35682e",
  //       clientId: "48b02e42652d479d9d3c9960030793f8",
  //     });
  //     console.log("mergedPdfUrl => ", mergedPdfUrl);
  //     adobeDCView.previewFile(
  //       {
  //         content: { location: { url: mergedPdfUrl } },
  //         metaData: {
  //           fileName: `${selectedCar?.year_Make_Model} ${
  //             selectedCar?.trim
  //           } ${selectedCar?.vin.slice(-8)}${userID.id}`,
  //         },
  //       },
  //       { embedMode: "SIZED_CONTAINER" }
  //     );
  //   }
  // }, [mergedPdfUrl]);

  if (!mergedPdfUrl) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ height: "85vh", marginBottom: "1.5rem" }}>
      <div id="adobe-dc-view" style={{ overflowY: "scroll" }}></div>
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          className: "",
          duration: 1000,
          style: {
            background: "#363636",
            color: "#fff",
            fontSize: "larger",
          },
        }}
      />
    </div>
  );
};

export default PDFViewer2;

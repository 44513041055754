// ScrollToTop.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const scrollToTop = (duration) => {
    const start = window.scrollY;
    const startTime = performance.now();

    const animateScroll = (currentTime) => {
        const elapsedTime = currentTime - startTime;
        const progress = Math.min(elapsedTime / duration, 1); // Ensure it doesn't exceed 1
        const ease = progress < 0.5 ? 4 * progress * progress * progress : (progress - 1) * (2 * progress - 2) * (2 * progress - 2) + 1; // Ease-in-out

        window.scrollTo(0, start + (0 - start) * ease);

        if (elapsedTime < duration) {
            requestAnimationFrame(animateScroll);
        }
    };

    requestAnimationFrame(animateScroll);
};

const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        scrollToTop(500); // Duration in milliseconds (3000ms = 3 seconds)
    }, [pathname]);

    return null;
};

export default ScrollToTop;

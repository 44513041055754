import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaMicrophone } from "react-icons/fa";
import { MdMyLocation } from "react-icons/md";
import { DealerGeoAPI } from "../DealerSection/DealerBusinessInformation/DealerGeoAPI";
import { IoMic } from "react-icons/io5";
import { AiOutlineClose } from "react-icons/ai";
import "./home.css";
import { useSelector } from "react-redux";

const HomeFilters = ({ scrollToCalculator, roles }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const { dealerId, id } = useSelector((state) => state.user);
  const inputRef = useRef(null);
  const [isListening, setIsListening] = useState(false);
  useEffect(() => {
    const accordionState = localStorage.getItem("accordionState");
    if (accordionState) {
      setIsCollapsed(accordionState === "collapsed");
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      "accordionState",
      isCollapsed ? "collapsed" : "expanded"
    );
  }, [isCollapsed]);
  const navigate = useNavigate();

  //   First fetch the filters
  const [filters, setFilters] = useState({
    make: [],
    models: [],
    year: [],
    engineType: [],
    fuelType: [],
    vehicleType: [],
    owner: [],
    state: [],
    interiorColor: [],
    exteriorColor: [],
    interiorType: [],
    doorCounts: [],
    driveTrain: [],
    odometer: [],
    price: [],
    exterior_damage: [],
    photos: [],
    seller: roles === "Dealer" ? [dealerId] : [],

    zip_code: [],
  });
  console.log("filters =>", filters);
  // const [input]

  const [responseFilter, setResponseFilter] = useState({});
  console.log("Response filters from the API", responseFilter);
  const handleChangeZipCode = (e) => {
    setFilters((prevData) => ({
      ...prevData,
      zip_code: [e.target.value],
    }));
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/listing/filters`,
          filters
        );
        // console.log("response filters from the API", response.data.data);
        setResponseFilter(response.data);
      } catch (error) {
        // console.log("Server side while fetching data during getting filters");
      }
    };
    fetchProducts();
  }, []);

  const handleFiltersChange = (e) => {
    const { name, value } = e.target;

    setFilters((prevFilters) => {
      if (name === "yearFrom" || name === "yearTo") {
        const yearIndex = name === "yearFrom" ? 0 : 1;
        const newYear = [...prevFilters.year];
        newYear[yearIndex] = value;

        // Remove empty values and sort
        const sortedYear = newYear.filter(Boolean).sort((a, b) => a - b);

        return {
          ...prevFilters,
          year: sortedYear,
        };
      } else if (name === "odometer") {
        return {
          ...prevFilters,
          odometer: ["0", value], // Set 0 as lower bound and selected value as upper bound
        };
      } else {
        return {
          ...prevFilters,
          [name]: [value],
        };
      }
    });
  };
  // const currentDate = new Date();
  // console.log(currentDate.getFullYear());
  // const userData = useSelector((state) => state.user);
  // const handleNavigate = () => {
  //   if (
  //     userData &&
  //     userData.roles === "Dealer" &&
  //     userData.firstName &&
  //     userData.lastName
  //   ) {
  //     setFilters((prevData) => ({
  //       ...prevData,
  //       seller: [userData.firstName + " " + userData.lastName],
  //     }));
  //   }
  //   console.log("Navigating using " + userData.firstName);
  //   navigate("/product", { state: filters });
  // };

  const userData = useSelector((state) => state.user);

  const handleNavigate = () => {
    if (
      userData &&
      userData.roles === "Dealer" &&
      userData.firstName &&
      userData.lastName
    ) {
      // Update filters state with seller info
      setFilters((prevData) => {
        const updatedFilters = {
          ...prevData,
          seller: [userData.id],
        };

        // Navigate after the state update
        navigate("/product", { state: updatedFilters });
        return updatedFilters;
      });
    } else {
      // Navigate with current filters if user is not a dealer or other conditions are not met
      navigate("/product", { state: filters });
    }
  };

  const handleLocation = async () => {
    try {
      const res = await DealerGeoAPI();
      console.log(res);
      setFilters((prevData) => ({
        ...prevData,
        zip_code: [res.zipCode], // Updating zip_code in state
      }));
    } catch (error) {
      console.log("API Side Error");
    }
  };
  // for micro phone---------------------------
  const SpeechRecognitionPopup = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
      <div className="speech-recognition-popup">
        <div className="popup-content">
          <div className="close-icon" onClick={onClose}>
            <AiOutlineClose />
          </div>
          <div className="img-mick">
            <IoMic className="mic-icon" />
          </div>
          {/* <div className="btn-close-mick">
            <button onClick={onClose}>Close</button>
          </div> */}
        </div>
      </div>
    );
  };
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleMicrophoneClick = () => {
    if ("webkitSpeechRecognition" in window) {
      setIsPopupOpen(true);
      const recognition = new window.webkitSpeechRecognition();
      recognition.lang = "en-US";
      recognition.continuous = false;
      recognition.interimResults = false;

      recognition.onstart = () => {
        setIsListening(true);
        console.log(
          "Voice recognition started. Try speaking into the microphone."
        );
      };

      recognition.onresult = (event) => {
        const speechToText = event.results[0][0].transcript;
        setSearchQuery(speechToText);
        inputRef.current.value = speechToText;
        setIsPopupOpen(false);
      };

      recognition.onerror = (event) => {
        console.error("Error occurred in recognition: " + event.error);
        setIsPopupOpen(false);
      };

      recognition.onend = () => {
        setIsListening(false);
        console.log("Voice recognition ended.");
        setIsPopupOpen(false);
      };

      recognition.start();
    } else {
      console.log("Speech Recognition not supported in this browser.");
    }
  };
  // ----------for search bar filter -----------------------------------------------------------
  // const [inputValue, setInputValue] = useState("");

  const handleSelect = async (selectedStatus) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/listing/filters/${id}?searchTerm=${selectedStatus}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      navigate("/product", { state: data });
      // setTableData(data);
      console.log(data);
      // Handle the response data as needed
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSelect(searchQuery); // Call the function with the input value
      setSearchQuery(""); // Clear the input field if desired
    }
  };
  // const handleButtonClick = () => {
  //   handleSelect(inputValue); // Call the function with the input value
  //   setInputValue(""); // Clear the input field if desired
  // };
  // -------------------------------------------------------------------------------------------
  return (
    <section className="sectionMain ">
      <div className="container-fluid">
        <div className="row justify-content-center">
          {/* <div className="col-md-10 form-col"> */}
          <div className=" form-col">
            {" "}
            {/* col-md-8  col-12*/}
            <div className="form_5">
              <form className="row login-form">
                <div
                  className="row text-light d-flex d-flex justify-content-between align-items-center text-center "
                  style={{ paddingRight: "0px" }}
                >
                  {/* <div className="col-lg-2">
                    <h3>I WANT</h3>
                  </div> */}
                  {/* <div className="col-lg-3">
                    <Link to="#" className="button-with-bg-color">
                      All Cars
                    </Link>
                  </div> */}
                  <div className="searchLoanParent">
                    <div
                      className=" d-flex justify-content-end pe-10 pe-0 searchParent"
                      style={{ paddingRight: "10rem" }}
                    >
                      <div className="col-lg-12 m-0 searchFilterInputParent">
                        <p
                          className="input_search_field box_shadow_filter_btn"
                          style={{
                            marginBottom: "0",
                            borderRadius: "15px",
                            backgroundColor: "#dda36d",
                            // width: "92%",
                          }}
                        >
                          <input
                            type="text"
                            placeholder="Search by Vehicle"
                            style={{ padding: "10px 5px" }}
                            ref={inputRef}
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            onKeyPress={handleKeyPress}
                          />
                          <span
                            className={`microphone_container ${
                              isListening ? "listening" : ""
                            }`}
                            onClick={handleMicrophoneClick}
                          >
                            <FaMicrophone className="mick-homefilter" />
                          </span>
                        </p>
                      </div>
                      <SpeechRecognitionPopup
                        isOpen={isPopupOpen}
                        onClose={() => setIsPopupOpen(false)}
                      />
                    </div>
                    <div className=" pe-0 d-flex justify-content-end loanParent">
                      <button
                        type="button"
                        className="buttonSell2  btn-change1 box_shadow_filter_btn"
                        onClick={scrollToCalculator}
                      >
                        Auto Loan Calculator
                      </button>
                    </div>
                  </div>
                  {/* <div className="col-lg-2">
                    <Link className="buttonSell2">Sell My Car</Link>
                  </div> */}
                  {/* <div className="col-lg-2">
                    <h3>(14) Vehicle</h3>
                  </div> */}
                </div>

                {/* <div
                  className="accordion col-lg-2 col-md-4 col-sm-12"
                  id="accordionExample"
                >
                  <div className="accordion-item" style={{ borderRadius: '10px' }}>
                    <div
                      id="collapseOne"
                      className={`accordion-collapse `}
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    
                    >
                      <div className="accordion-body p-0 d-flex w-80 form-select select-filter">
                        <select
                          className="p-0 py- px-1 form-select select-filter"
                          value={filters.year[0] || ""}
                          name="yearFrom"
                          onChange={handleFiltersChange}
                        >
                          <option value="" hidden>
                            Year
                          </option>
                          {responseFilter &&
                            responseFilter?.year?.map((year, i) => (
                              <option value={year} key={i}>
                                {year}
                              </option>
                            ))}
                        </select>
                        <select
                          className="p-0 py-2 px-1 form-select select-filter"
                          value={filters.year[1] || ""}
                          name="yearTo"
                          onChange={handleFiltersChange}
                        >
                          <option value="" hidden>
                            Year
                          </option>
                          {responseFilter &&
                            responseFilter?.year?.map((year, i) => (
                              <option value={year} key={i}>
                                {year}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="dropDownFilterParent">
                  <div className="">
                    <select
                      className="form-select select-filter box_shadow_filter_btn"
                      name="make"
                      value={filters.make}
                      onChange={handleFiltersChange}
                      style={{ paddingTop: "9px", paddingBottom: "9px" }}
                    >
                      <option value="" hidden>
                        Make
                      </option>
                      {responseFilter &&
                        responseFilter?.makes?.map((makeItem, i) => (
                          <option value={makeItem.make} key={i}>
                            {makeItem.make}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="">
                    <select
                      className="form-select select-filter box_shadow_filter_btn"
                      name="models"
                      value={filters.models}
                      onChange={handleFiltersChange}
                      style={{ paddingTop: "9px", paddingBottom: "9px" }}
                    >
                      <option value="" hidden>
                        Model
                      </option>
                      {responseFilter &&
                        responseFilter?.models?.map((model, i) => (
                          <option value={model} key={i}>
                            {model}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="">
                    <select
                      className="form-select select-filter box_shadow_filter_btn"
                      name="odometer"
                      value={filters.odometer[1] || ""}
                      onChange={handleFiltersChange}
                      style={{ paddingTop: "9px", paddingBottom: "9px" }}
                    >
                      <option value="" hidden>
                        Odometer
                      </option>
                      <option value="500">500 miles</option>
                      <option value="10000">10k miles</option>
                      <option value="50000">50k miles</option>
                      <option value="100000">100k miles</option>
                      <option value="150000">150k miles</option>
                      <option value="200000">200k miles</option>
                      <option value="1000000">200k + miles</option>
                    </select>
                  </div>
                  <div className=" zipCode_container">
                    <input
                      type="text"
                      placeholder="Enter Zip Code"
                      style={{ padding: "9px" }}
                      value={filters.zip_code}
                      onChange={handleChangeZipCode}
                      className="form-select select-filter box_shadow_filter_btn"
                    />
                    <span onClick={handleLocation}>
                      <MdMyLocation />
                    </span>
                  </div>
                  <div className=" ps-0 searchInventoryButtonContainr">
                    <button
                      type="button"
                      className=" search_btn btn-change2 box_shadow_filter_btn"
                      onClick={handleNavigate}
                      style={{ width: "100%" }}
                    >
                      Search Inventory
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeFilters;

import React, { useState } from "react";
import Image from "../../../../imgData";
import { TiThMenu } from "react-icons/ti";
import DealerSideBar from "../../../../DealerSection/DealerSideBar/DealerSideBar";

const Navbar4 = () => {
  const [showSideBar, setShowDealerSideBar] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const handleSidebarHover = (hovered) => {
    setIsHovered(hovered);
  };
  const isPermanentlyExpanded = true;
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "1rem 2rem",
        backgroundColor: "#dbd9d7",
        boxShadow: "lightgrey 4px -11px 13px 14px",
        marginBottom: "2rem",
        alignItems: "center",
        position: "relative",
      }}
    >
      <p
        style={{
          marginBottom: "0",
          fontSize: "xx-large",
          fontWeight: "600",

          display: "flex",
        }}
      >
        <span
          style={{
            display: "grid",
            placeContent: "center",
            padding: "0.3rem",
            cursor: "pointer",
            backgroundColor: showSideBar ? "#f0f0f0" : "#dbd9d7",
            marginRight: "1rem",
            borderRadius: "50%",
          }}
          onClick={() => setShowDealerSideBar(!showSideBar)}
        >
          <TiThMenu />
        </span>
        <span style={{ color: "#d66b00" }}>United Motors LLC</span>
      </p>
      <div
        style={{
          // border: "1px solid black",
          flex: "0.4",
          display: "flex",
          justifyContent: "center",
          fontSize: "x-large",
          fontWeight: "700",
          gap: "1.5rem",
        }}
      >
        <button
          style={{
            border: "none",
            backgroundColor: "#d66b00",
            color: "white",
            width: "30%",
            padding: "0.25rem",
            borderRadius: "10px",
          }}
        >
          Add Inventory
        </button>

        <button
          style={{
            border: "none",
            backgroundColor: "#d66b00",
            color: "white",
            width: "30%",
            padding: "0.25rem",
            borderRadius: "10px",
          }}
        >
          Add Cost
        </button>
      </div>
      <p style={{ marginBottom: "0" }}>
        <img src={Image.loginb} alt="login image" height={45} width={45} />
      </p>
      <div
        className={`dealer_sidebar_home_Parent ${
          showSideBar ? "ShowSidebar" : "HideSidebar"
        }`}
        style={{
          position: "absolute",
          top: "80px",
          zIndex: "99",
          flex: showSideBar ? "0.15" : "0.75",
          backgroundColor: "#dbd9d7",
        }}
      >
        <DealerSideBar
          isPermanentlyExpanded={isPermanentlyExpanded}
          onHoverChange={handleSidebarHover}
        />
      </div>
    </div>
  );
};

export default Navbar4;

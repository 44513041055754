import React, { useState, useEffect, useMemo } from "react";
import { debounce } from "lodash";

const PriceFilter = ({ responseFilter, setFilters }) => {
  // Price state
  const [priceLower, setPriceLower] = useState("1");
  const [priceUpper, setPriceUpper] = useState("1000");
  // Year state
  const [yearLower, setYearLower] = useState("");
  const [yearUpper, setYearUpper] = useState("");

  useEffect(() => {
    if (
      responseFilter &&
      responseFilter.year &&
      responseFilter.year.length > 0
    ) {
      setYearLower(responseFilter.year[0]);
      setYearUpper(responseFilter.year[responseFilter.year.length - 1]);
    }
  }, [responseFilter]);

  // Odometer state
  const [odometerLower, setOdometerLower] = useState("0");
  const [odometerUpper, setOdometerUpper] = useState("100000");


  const debouncedUpdateYearRange = useMemo(
    () =>
      debounce((lower, upper) => {
        const lowerNum = Number(lower);
        const upperNum = Number(upper);

        setFilters((prevFilters) => ({
          ...prevFilters,
          year: [Math.min(lowerNum, upperNum), Math.max(lowerNum, upperNum)],
          odo: [Math.min(lowerNum, upperNum), Math.max(lowerNum, upperNum)],
        }));
      }, 300),
    []
  );
  const debouncedUpdateOdoRange = useMemo(
    () =>
      debounce((lower, upper) => {
        const lowerNum = Number(lower);
        const upperNum = Number(upper);

        setFilters((prevFilters) => ({
          ...prevFilters,
          odo: [Math.min(lowerNum, upperNum), Math.max(lowerNum, upperNum)],
        }));
      }, 300),
    []
  );

  // Cleanup function to cancel any pending debounced calls when the component unmounts
  useEffect(() => {
    return () => {
      debouncedUpdateYearRange.cancel();

    };
  }, [debouncedUpdateYearRange]);
  useEffect(() => {
    return () => {
      debouncedUpdateOdoRange.cancel();

    };
  }, [debouncedUpdateOdoRange]);

  const debouncedUpdatePriceRange = useMemo(
    () =>
      debounce((lower, upper) => {
        const lowerNum = Number(lower) * 1000;
        const upperNum = Number(upper) * 1000;
        setFilters(prevFilters => ({
          ...prevFilters,
          price: [Math.min(lowerNum, upperNum), Math.max(lowerNum, upperNum)]
        }));
      }, 300),
    [setFilters]
  );

  useEffect(() => {
    return () => {
      debouncedUpdatePriceRange.cancel();
    };
  }, [debouncedUpdatePriceRange]);

  const formatPrice = (price) => `${price}k`;
  return (
    <div>
      {/* Price */}
      <div className="price-bars text-center mt-3">
        <h4>Price</h4>
        <div className="wrapper">
          <fieldset className="filter-price">
            <div className="price-wrap mt-2 d-flex">
              <input
                value={formatPrice(priceLower)}
                className="odo-wrap2"
                style={{ marginLeft: "0px", marginRight: "10px" }}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, '');
                  setPriceLower(value);
                  debouncedUpdatePriceRange(value, priceUpper);
                }}
              />
              <input
                value={formatPrice(priceUpper)}
                className="odo-1"
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, '');
                  setPriceUpper(value);
                  debouncedUpdatePriceRange(priceLower, value);
                }}
              />
            </div>
            <div className="price-field1">
              <input
                type="range"
                min="1"
                max="1000"
                step="1"
                className="filtercar"
                value={priceLower}
                id="pricelower"
                onChange={(e) => {
                  setPriceLower(e.target.value);
                  debouncedUpdatePriceRange(e.target.value, priceUpper);
                }}
              />
              <input
                type="range"
                min="1"
                max="1000"
                step="1"
                className="filtercar"
                value={priceUpper}
                id="priceupper"
                onChange={(e) => {
                  setPriceUpper(e.target.value);
                  debouncedUpdatePriceRange(priceLower, e.target.value);
                }}
              />
            </div>
          </fieldset>
        </div>
      </div>

      {/* Year */}
      <div className="price-bars text-center mt-3">
        <h4>Year</h4>
        <div className="wrapper">
          <fieldset className="filter-price">
            <div className="price-wrap mt-2 d-flex">
              <input
                value={yearLower}
                className="prices-wrap2"
                style={{ marginLeft: "0px", marginRight: "0px" }}
                onChange={(e) => {
                  setYearLower(e.target.value);
                  debouncedUpdateYearRange(e.target.value, yearUpper);
                }}
              />
              <input
                value={yearUpper}
                className="price-wrap1"
                onChange={(e) => {
                  setYearUpper(e.target.value);
                  debouncedUpdateYearRange(yearLower, e.target.value);
                }}
              />
            </div>
            <div className="price-field1">
              <input
                type="range"
                min="1900"
                className="filtercar"
                max="2023"
                value={yearLower}
                id="yearlower"
                onChange={(e) => {
                  setYearLower(e.target.value);
                  debouncedUpdateYearRange(e.target.value, yearUpper);
                }}
              />
              <input
                type="range"
                min="1900"
                className="filtercar"
                max="2023"
                value={yearUpper}
                id="yearupper"
                onChange={(e) => {
                  setYearUpper(e.target.value);
                  debouncedUpdateYearRange(yearLower, e.target.value);
                }}
              />
            </div>
          </fieldset>
        </div>
      </div>

      {/* Odometer */}
      <div className="price-bars text-center mt-3">
        <h4>Odometer</h4>
        <div className="wrapper">
          <fieldset className="filter-price">
            <div className="price-wrap mt-2 d-flex">
              <input
                value={odometerLower}
                className="odo-wrap2"
                style={{ marginLeft: "0px", marginRight: "0px" }}
                onChange={(e) => {
                  setOdometerLower(e.target.value);
                  debouncedUpdateOdoRange(e.target.value, odometerUpper);
                }}
              />
              <input
                value={odometerUpper}
                className="odo-wrap1"
                style={{ marginLeft: "0px", marginRight: "0px" }}
                onChange={(e) => {
                  setOdometerUpper(e.target.value);
                  debouncedUpdateOdoRange(odometerLower, e.target.value);
                }}
              />
            </div>
            <div className="price-field1">
              <input
                type="range"
                min="0"
                className="filtercar"
                max="100000"
                value={odometerLower}
                id="yearlower"
                onChange={(e) => {
                  setOdometerLower(e.target.value);
                  debouncedUpdateOdoRange(e.target.value, odometerUpper);
                }}
              />
              <input
                type="range"
                min="0"
                className="filtercar"
                max="100000"
                value={odometerUpper}
                id="yearupper"
                onChange={(e) => {
                  setOdometerUpper(e.target.value);
                  debouncedUpdateOdoRange(odometerLower, e.target.value);
                }}
              />
            </div>
          </fieldset>
        </div>
      </div>
    </div>
  );
};

export default PriceFilter;

import React, { useState } from "react";
import Styles from "./DealerWebsiteCard.module.css";
import Image from "../../imgData";
import { Link, useNavigate } from "react-router-dom";
import DNSManagementSystem from "./DNSManagementSystem";
import { setRetailData } from "../../../Redux/slice/taxes&FeesSlice";
import { useDispatch } from "react-redux";

const WebsiteCard = ({
  route = "/dealer/webPreview1",
  imgScreenShot = Image.web_screenshot_1,
  handleShowDNS,
  id,
}) => {
  const [showModalDns, setShowModalDns] = useState(false);
  const dispatch = useDispatch();
  const handleLinkClickDns = (carId) => {
    console.log("carId",carId);
    dispatch(
      setRetailData({
       webCardId:carId,
      })
    );
    setShowModalDns(true);
  };
  const handleModalCloseDns = () => {
    setShowModalDns(false);
  };

  const baseUrl = window.location.origin; // or a specific base URL
  const imgUrl = new URL(imgScreenShot, baseUrl).href;


  console.log("Hello This is website card",id);

  return (
    <div className={Styles.CardWrapper}>
      <div className={Styles.CardContainer}
        style={{
          background: `url(${imgUrl}) `,
          backgroundSize: 'cover',
          overflow: 'hidden',
        }}
      >
        {/* <img src={imgScreenShot} alt="car" /> */}
        <div className={Styles.btnContainer}>
          <button onClick={() => handleLinkClickDns(id)}>
            Activate
          </button>
          <button>
            <Link to={route} target="_blank" rel="noopener noreferrer">
              Preview
            </Link>
          </button>
        </div>
      </div>
      <div style={{display:"flex",justifyContent:"center"}}>Card No: {id}</div>
      <DNSManagementSystem
        show={showModalDns}
        handleClose={handleModalCloseDns}
      />
    </div>
  );
};

export default WebsiteCard;

import React from "react";
import Styles from "./Footer.module.css";
import { SiFacebook } from "react-icons/si";
import Image from "../../../../imgData";
import { FaSquareXTwitter } from "react-icons/fa6";

const Footer = () => {
  return (
    <div className={Styles.wrapper}>
      <div className={Styles.container}>
        <div className={Styles.footer1}>
          {" "}
          <p className={Styles.logo}>Website Logo</p>
          <div className={Styles.miniContainer}>
            <ul className={Styles.unorderedList}>
              <li>Listing</li>
              <li>Blog</li>
              <li>Our Team</li>
              <li>FAQ</li>
              <li>About us</li>
              <li>Contact</li>
            </ul>
            <p className={Styles.address}>
              <span>(123) 456-7890</span>
              <span>support@info.com</span>
              <span>Example street, Texas, Usa</span>
            </p>
          </div>
        </div>
        <div className={Styles.footer2}>
          <p className={Styles.copyRight}>
            Copyright &copy; 2023. All rights reserved.{" "}
            <span style={{ textDecoration: "underline" }}>Privacy policy</span>{" "}
          </p>
          <p className={Styles.socials}>
            <span>
              <SiFacebook />
            </span>
            <span>
              <img src={Image.insta} alt="insta icon" />
            </span>
            <span>
              <FaSquareXTwitter />
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;

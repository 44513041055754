import React from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";

function Arrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}
const OurProducts = () => {
  const userData = useSelector((state) => state.user);
  const { roles } = userData;
  var settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    initialSlide: 0,
    className: "slider-with-gap",
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      {roles !== "Dealer" ? (
        <>
          <section className="our-solutions">
            <div className="container-fluid">
              <div className="text-center ourProductsHeader">
                <h2>Our Products</h2>
                <p>
                  Explore our Services to make your car buying selling
                  experience seamless.
                </p>
              </div>
              {/* <div className="col-lg-12 pt-3"> */}
              <div className="row justify-content-center">
                {/* <div className="col-md-2"></div> */}
                <div className="col-md-12 text-center">
                  <div className="OurProductsMobileSliderParent">
                    <Slider {...settings}>
                      <div className="col-md-3 mb-3">
                        <div className="box-cont">
                          <img
                            src="image/solutions-icon1.png"
                            alt="1"
                            width="100%"
                          />
                          <h5 className="h_5 fw-bold">DMS</h5>
                        </div>
                      </div>
                      <div className="col-md-3 mb-3">
                        <div className="box-cont img_e">
                          <img
                            src="image/solutions-icon2.png"
                            alt="2"
                            width="100%"
                          />
                          <h5 className="h_5 fw-bold">Fax Service</h5>
                        </div>
                      </div>
                      <div className="col-md-3 mb-3">
                        <div className="box-cont img_e">
                          <img
                            src="image/solutions-icon9.png"
                            alt="3"
                            width="100%"
                          />
                          <h5 className="h_5 fw-bold">Logistics Service</h5>
                        </div>
                      </div>
                      <div className="col-md-3 mb-3">
                        <div className="box-cont img_e">
                          <img
                            src="image/solutions-icon3.png"
                            alt="4"
                            width="100%"
                          />
                          <h5 className="h_5 fw-bold">
                            Facebook Market Service
                          </h5>
                        </div>
                      </div>
                      <div className="col-md-3 mb-3">
                        <div className="box-cont">
                          <img
                            src="image/solutions-icon4.png"
                            alt="5"
                            width="100%"
                          />
                          <h5 className="h_5 fw-bold">Qualified Leads</h5>
                        </div>
                      </div>
                      <div className="col-md-3 mb-3">
                        <div className="box-cont img_e">
                          <img
                            src="image/solutions-icon5.png"
                            alt="6"
                            width="100%"
                          />
                          <h5 className="h_5 fw-bold">Smart CRM</h5>
                        </div>
                      </div>
                      <div className="col-md-3 mb-3">
                        <div className="box-cont img_e">
                          <img
                            src="image/solutions-icon6.png"
                            alt="7"
                            width="100%"
                          />
                          <h5 className="h_5 fw-bold">Social Media Ads</h5>
                        </div>
                      </div>
                      <div className="col-md-3 mb-3">
                        <div className="box-cont img_D">
                          <img
                            src="image/solutions-icon8.png"
                            alt="8"
                            width="100%"
                          />
                          <h5 className="h_5 fw-bold">Website For Dealers</h5>
                        </div>
                      </div>
                      <div className="col-md-3 mb-3">
                        <div className="box-cont img_e">
                          <img
                            src="image/solutions-icon7.png"
                            alt="9"
                            width="100%"
                          />
                          <h5 className="h_5 fw-bold">HR System</h5>
                        </div>
                      </div>
                    </Slider>
                  </div>
                  <div className="OurProductsLaptopSliderParent">
                    <div className="">
                      <div className="box-cont">
                        <img
                          src="image/solutions-icon1.png"
                          alt="1"
                          width="100%"
                        />
                        <h5 className="h_5 fw-bold">DMS</h5>
                      </div>
                    </div>
                    <div className=" ">
                      <div className="box-cont img_e">
                        <img
                          src="image/solutions-icon2.png"
                          alt="2"
                          width="100%"
                        />
                        <h5 className="h_5 fw-bold">Fax Service</h5>
                      </div>
                    </div>
                    <div className=" ">
                      <div className="box-cont img_e">
                        <img
                          src="image/solutions-icon9.png"
                          alt="3"
                          width="100%"
                        />
                        <h5 className="h_5 fw-bold">Logistics Service</h5>
                      </div>
                    </div>
                    <div className="">
                      <div className="box-cont img_e">
                        <img
                          src="image/solutions-icon3.png"
                          alt="4"
                          width="100%"
                        />
                        <h5 className="h_5 fw-bold">Facebook Market Service</h5>
                      </div>
                    </div>
                    <div className=" ">
                      <div className="box-cont">
                        <img
                          src="image/solutions-icon4.png"
                          alt="5"
                          width="100%"
                        />
                        <h5 className="h_5 fw-bold">Qualified Leads</h5>
                      </div>
                    </div>
                    <div className="">
                      <div className="box-cont img_e">
                        <img
                          src="image/solutions-icon5.png"
                          alt="6"
                          width="100%"
                        />
                        <h5 className="h_5 fw-bold">Smart CRM</h5>
                      </div>
                    </div>
                    <div className="">
                      <div className="box-cont img_e">
                        <img
                          src="image/solutions-icon6.png"
                          alt="7"
                          width="100%"
                        />
                        <h5 className="h_5 fw-bold">Social Media Ads</h5>
                      </div>
                    </div>
                    <div className="">
                      <div className="box-cont img_D">
                        <img
                          src="image/solutions-icon8.png"
                          alt="8"
                          width="100%"
                        />
                        <h5 className="h_5 fw-bold">Website For Dealers</h5>
                      </div>
                    </div>
                    <div className="">
                      <div className="box-cont img_e">
                        <img
                          src="image/solutions-icon7.png"
                          alt="9"
                          width="100%"
                        />
                        <h5 className="h_5 fw-bold">HR System</h5>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-2"></div> */}
              </div>
              {/* </div> */}
            </div>
          </section>
          <section className="p-lg-5 p-md-3 p-2 bg-lightOrange box1">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <h3 className="text-center">CRM Featured</h3>
                  <p className="text-center">
                    Dealers Auto Center is a trusted partner of leading banks
                    across the USA
                  </p>
                </div>

                <div className="crmFeaturedLaptop">
                  <div className="col-lg-4 col-md-12">
                    <div className=" h-100 d-sm-flex text-md-start text-center justify-content-center align-items-center p-1 bg-white border rounded my-2">
                      <img
                        className="w-25 mx-2"
                        src="image/icon-box1.webp"
                        alt="box1"
                      />
                      <div>
                        <h4>Track with Precision</h4>
                        <p>
                          Harness the power of real-time data, ensuring every
                          customer interaction is logged, every opportunity
                          captured, and every sales move is strategic.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12">
                    <div className=" h-100 d-sm-flex text-md-start text-center justify-content-center align-items-center p-1 bg-white border rounded my-2">
                      <img
                        className="w-25 mx-2"
                        src="image/icon-box2.webp"
                        alt="box2"
                      />
                      <div>
                        <h4>Profile with Insight</h4>
                        <p>
                          Dive deep into customer behaviors, preferences, and
                          histories, crafting detailed profiles that empower
                          sales teams to personalize and connect more
                          effectively.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12">
                    <div className="h-100 d-sm-flex text-md-start text-center justify-content-center align-items-center p-1 bg-white border rounded my-2">
                      <img
                        className="w-25 mx-2"
                        src="image/icon-box3.webp"
                        alt="box3"
                      />
                      <div>
                        <h4>Market with Mastery</h4>
                        <p>
                          Seamlessly integrate potent marketing tools,
                          automating campaigns that resonate, engage, and
                          convert, all while boosting sales and fortifying brand
                          relationships.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center mt-4">
                  <button className="btn-grad">More</button>
                </div>
              </div>
            </div>
          </section>{" "}
        </>
      ) : null}
    </div>
  );
};

export default OurProducts;

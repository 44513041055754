
import React from "react";
import { Link } from "react-router-dom";
import Image from "../../../imgData";
import "../../NearByCars.css";

const NearByCars1 = () => {
    const carsListing = [
        {
          year_make_model: "2014 CHEVROLET Cruze Sedan 4D 1LS",
          image: "path/to/chevrolet_cruze.jpg", // Replace with actual image path or URL
          mileage_in: 30000,
          fuel: "(123) 123-1234",
          listing_price: 3234,
        },
        {
          year_make_model: "2016 TOYOTA Corolla Sedan 4D LE",
          image: "path/to/toyota_corolla.jpg", // Replace with actual image path or URL
          mileage_in: 45000,
          fuel: "(123) 123-1234",
          listing_price: 13500,
        },
        {
          year_make_model: "2018 HONDA Civic Sedan 4D LX",
          image: "path/to/honda_civic.jpg", // Replace with actual image path or URL
          mileage_in: 22000,
          fuel: "(123) 123-1234",
          listing_price: 18000,
        },
        {
          year_make_model: "2017 FORD Focus Sedan 4D SE",
          image: "path/to/ford_focus.jpg", // Replace with actual image path or URL
          mileage_in: 35000,
          fuel: "(123) 123-1234",
          listing_price: 16000,
        },
        {
          year_make_model: "2015 NISSAN Sentra Sedan 4D SV",
          image: "path/to/nissan_sentra.jpg", // Replace with actual image path or URL
          mileage_in: 50000,
          fuel: "(123) 123-1234",
          listing_price: 14000,
        },
        {
          year_make_model: "2019 KIA Forte Sedan 4D EX",
          image: "path/to/kia_forte.jpg", // Replace with actual image path or URL
          mileage_in: 15000,
          fuel: "(123) 123-1234",
          listing_price: 19000,
        },
      ];
      

  return (
    <section style={{ cursor: "pointer" }}>
      <div className="container-fluid p-4">
        <div className="row">
          <h3 className="text-center p-4">Featured Dealers Near Me</h3>
          {carsListing.length > 0 && (
            <>
              <div className="col-12">
                <div className="col-lg-12">
                  <div className="row">
                    {carsListing.map((car, index) => (
                      <div
                        key={index}
                        className="col-lg-4 nearByCarcardWrapper"
                      >
                        <div className="card mt-3 card-product">
                          <div className="img-over-color">
                            <img
                              src={Image.car_image} // Replace with actual image field
                              className="card-img-top card-product-img"
                              alt="..."
                            />
                          </div>
                          <div
                            className="card-body card-home-body2"
                            style={{ paddingBottom: "5px" }}
                          >
                            <div className="card-space">
                              <h5
                                className="card-title"
                                style={{ fontSize: "15px" }}
                              >
                                {car.year_make_model || "Default Title"}
                              </h5>
                            </div>
                            <div className="card-space d-flex">
                              <div className="chip col-lg-6">
                                <Link to="#" className="color-blk">
                                  <i className="fa-solid fa-gauge-high"></i>{" "}
                                  {car.mileage_in || "0"} Miles
                                </Link>
                              </div>

                              <div className="chip col-lg-6">
                                <Link to="#" className="color-blk">
                                  <i class="fa-solid fa-phone"></i>{" "}
                                  {car.fuel || "Fuel Type"}
                                </Link>
                              </div>
                            </div>
                            <div
                              className="card-space"
                              style={{
                                padding: "0px",
                                paddingBottom: "0.4rem",
                              }}
                            >
                              <div className="d-flex total font-weight-bold justify-content-between">
                                <span className="doller-price">
                                  ${car.listing_price || "0"}
                                </span>
                                <span className="doller-price">
                                  <img
                                    src={Image.messanger}
                                    alt="messanger"
                                    height={25}
                                  />{" "}
                                  Check Availability
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default NearByCars1;

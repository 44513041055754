// googleMapsUtils.js

const loadGoogleMapsApi = (apiKey) => {
    return new Promise((resolve, reject) => {
      if (window.google && window.google.maps) {
        resolve(window.google.maps);
        return;
      }
      
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
      script.async = true;
      script.defer = true;
      
      script.onload = () => {
        if (window.google && window.google.maps) {
          resolve(window.google.maps);
        } else {
          reject(new Error('Google Maps API failed to load'));
        }
      };
      
      script.onerror = () => {
        reject(new Error('Google Maps API script error'));
      };
      
      document.head.appendChild(script);
    });
  };
  
  const getCountyFromLocation = async (apiKey, location) => {
    try {
      const maps = await loadGoogleMapsApi(apiKey);
      const geocoder = new maps.Geocoder();
  
      return new Promise((resolve, reject) => {
        geocoder.geocode({ address: location }, (results, status) => {
          if (status === 'OK') {
            const addressComponents = results[0].address_components;
            const countyComponent = addressComponents.find(component =>
              component.types.includes('administrative_area_level_2')
            );
            if (countyComponent) {
              resolve(countyComponent.long_name);
            } else {
              resolve('County not found');
            }
          } else {
            reject(new Error('Geocode was not successful for the following reason: ' + status));
          }
        });
      });
    } catch (error) {
      throw new Error(error.message);
    }
  };
  
  export { getCountyFromLocation };
  
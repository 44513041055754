import { createSlice } from '@reduxjs/toolkit';

const dataSlice = createSlice({
  name: 'data',
  initialState: {
    x: null, // Initial value of x
  },
  reducers: {
    setData(state, action) {
      state.x = action.payload;
    },
  },
});

export const { setData } = dataSlice.actions;
export default dataSlice.reducer;
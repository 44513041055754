import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
// import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from '../src/Redux/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div>
 
    {/* <BrowserRouter> */}
    <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
    <App />
    {/* </BrowserRouter> */}
    </PersistGate>
    </Provider>
  </div>
);

reportWebVitals();

import React, { useEffect, useRef } from 'react';
import * as PANOLENS from 'panolens';
import { useSelector } from 'react-redux';

const imgUrl = process.env.REACT_APP_IMG_URL;

const PanolensViewer = ({ image }) => {
  const { id } = useSelector((state) => state.user);
  const viewerRef = useRef(null);
  const viewerInstance = useRef(null);

  useEffect(() => {
    if (!viewerRef.current) return;

    // Dispose of the previous viewer instance if it exists
    if (viewerInstance.current) {
      viewerInstance.current.dispose();
    }

    const viewer = new PANOLENS.Viewer({
      container: viewerRef.current,
      autoRotate: true,
      autoRotateSpeed: 0.5,
    });

    viewerInstance.current = viewer;

    let panorama;
    let url = '';

    console.log('Image:', image);

    if (image instanceof File || image instanceof Blob) {
      // Create a URL for the File or Blob object
      url = URL.createObjectURL(image);
      panorama = new PANOLENS.ImagePanorama(url);
    } else if (typeof image === 'string') {
      if (image.startsWith('data:image')) {
        // If image is a base64 string
        panorama = new PANOLENS.ImagePanorama(image);
      } else if (image.startsWith(`${imgUrl}/`)) {
        // Correctly construct the URL
        const constructedUrl = `${imgUrl}/${id}/${image.split('/').pop()}`;
        console.log('Constructed URL:', constructedUrl);
        panorama = new PANOLENS.ImagePanorama(constructedUrl);
      } else {
        // If image is a URL
        console.log('URL Image:', image);
        panorama = new PANOLENS.ImagePanorama(image);
      }
    }

    if (panorama) {
      viewer.add(panorama);
    }

    // Cleanup the URL object if it was created
    return () => {
      if (url) {
        URL.revokeObjectURL(url);
      }

      // Dispose of the viewer instance
      if (viewerInstance.current) {
        viewerInstance.current.dispose();
        viewerInstance.current = null;
      }
    };

  }, [image, id]); // Ensure id is included if it affects URL construction

  return <div ref={viewerRef} style={{ width: '100%', height: '100%', boxSizing: 'border-box' }} />;
};

export default PanolensViewer;

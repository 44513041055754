import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Row,
  Table,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Image from "../../../imgData";
import styles from "../DealerSoldInventory/sold.module.css";

import { saveView } from "../../../../Redux/slice/activeSlice";
import SellDraftCard from "./SellDraftCard.jsx";
import { BiReset } from "react-icons/bi";

const apiUrl = process.env.REACT_APP_API_URL;

const DealerDraft = () => {
  const { id } = useSelector((state) => state.user);
  const view = useSelector((state) => state.active.view);

  const dispatch = useDispatch();

  const location = useLocation();
  const data = location.state || {};

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [openDropdown, setOpenDropdown] = useState(null);
  const dropdownRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);

  const [tableData, setTableData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  // const [apiRespnse]

  //fetch all the drafts
  const activeTab = useSelector((state) => state.sell.activeTab);
  const [counter, setCounter] = useState(0);
  const [initialFilters, setInitialFilters] = useState([]);
  const fetchData = async () => {
    try {
      const response = await fetch(`${apiUrl}/sell/get_draft/${id}`);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      setTableData(data);
      setInitialFilters(data.map(item => item.addListing2));
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [activeTab]);
  // for fetch data----------------------------
  useEffect(() => {
    fetchData();
  }, [counter]);

  const handleIncrement = () => {
    setCounter(prevCounter => prevCounter + 1);
    setSelectedFilter({
      make: "Make",
      models: "Model",
      year: "Year",
      bodyClass: "Body Type",
    });
    setSelectedSort(null);
  };
  // --------------------------------------
  const toggleDropdown = (dropdownName) => {
    setOpenDropdown((prevDropdown) => {
      // If the clicked dropdown is already open, close it
      if (prevDropdown === dropdownName) {
        return null;
      } else {
        // Open the clicked dropdown and close any other open dropdown
        return dropdownName;
      }
    });
  };

  // For Pagination
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleViewToggle = (newView) => {
    dispatch(saveView(newView));
  };

  // Calculate the number of days difference
  const calculateDaysDifference = (inspectionDate) => {
    if (!inspectionDate) return 0;
    const currentDate = new Date();
    const inspectionDateObj = new Date(inspectionDate);
    const timeDifference = inspectionDateObj - currentDate;
    return Math.ceil(Math.abs(timeDifference) / (1000 * 60 * 60 * 24));
  };

  // For selection of cards
  const [selectedCards, setselectedCards] = useState([]);

  const handleSelected = (index) => {
    setselectedCards((prevselectedCards) => {
      if (prevselectedCards.includes(index)) {
        // If the card is already selected, remove it
        return prevselectedCards.filter((i) => i !== index);
      } else {
        // If the card is not selected, add it
        return [...prevselectedCards, index];
      }
    });
  };

  const handleRowClick = (index) => {
    // If the clicked row is already selected, deselect it
    if (selectedRows.includes(index)) {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((selectedIndex) => selectedIndex !== index)
      );
    } else {
      // If the clicked row is not selected, select it
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, index]);
    }
  };

  // Function to handle export button click
  const tableRef = useRef(null);

  const handleSort = (order) => {
    // const sorted = [...tableData].sort((a, b) => {
    //   const dateA = new Date(a.created_at);
    //   const dateB = new Date(b.created_at);
    //   return order === "oldest" ? dateA - dateB : dateB - dateA;
    // });
    console.log("__________order", order);
    if (order) {
      const sorted = [...tableData].sort((a, b) => a.id - b.id);
      console.log("__________sorted newest", sorted);
      setTableData(sorted);
    } else {
      const sorted = [...tableData].sort((a, b) => b.id - a.id);
      console.log("__________sorted oldest", sorted);
      setTableData(sorted);
    }
  };

  const handleWebsite = (event) => {
    event.stopPropagation();
    alert("Added");
  };

  const handleEdit = (event) => {
    event.stopPropagation();
    alert("Edited");
  };

  const handleDelete = async (event, cardId, index) => {
    event.stopPropagation();

    // Display a confirmation dialog
    const userConfirmed = window.confirm(
      "Are you sure you want to delete this inventory item?"
    );

    if (!userConfirmed) {
      // If the user clicks "Cancel", exit the function
      return;
    }

    try {
      const listing_id = "listing";
      const response = await fetch(
        `${apiUrl}/listing/delete/${listing_id}/${id}/${cardId}`,
        {
          method: "DELETE",
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete the card");
      }

      // Update the state to reflect the deletion
      const newTableData = tableData.filter((_, i) => i !== index);
      setTableData(newTableData);
      // setSortedData(newTableData);
    } catch (error) {
      console.error("Error deleting card:", error);
    }
  };

  const handleUpdate = (id, updatedData) => {
    console.log("updatedData>>>>>>>>>>>>>>>>>>>>>>>", updatedData);
    setTableData((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, addvertising: updatedData } : item
      )
    );
    // setTableData((prevData) =>
    //   prevData.map((item) => (item.id === id ? { ...item, addvertising: updatedData } : item))
    // );
  };
  // for filter sold data---------------------------------------------------------------*********************-----------------------------


  const [selectedFilter, setSelectedFilter] = useState({
    make: "Make",
    models: "Model",
    year: "Year",
    bodyClass: "Body Type",
  });

  // ----------for search bar filter -----------------------------------------------------------
  const [inputValue, setInputValue] = useState("");
  const [selectedSort, setSelectedSort] = useState(null);

  const handleSelect = async (selectedStatus, type = "") => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/sell/get_draft/${id}?searchTerm=${selectedStatus}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();

      if (type) {
        setSelectedFilter((prev) => ({
          ...prev,
          [type]: selectedStatus, // Use computed property name here
        }));
      }

      setTableData(data);
      setSelectedSort(selectedStatus);
      console.log(data);
      // Handle the response data as needed
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSelect(inputValue); // Call the function with the input value
      setInputValue(""); // Clear the input field if desired
    }
  };
  const handleButtonClick = () => {
    handleSelect(inputValue); // Call the function with the input value
    setInputValue(""); // Clear the input field if desired
  };
  // -------------------------------------------------------------------------------------------
  console.log("initialFilters32455", initialFilters);
  // const filterSoldData = initialFilters.addListing2;
  // console.log("filterSoldData32455******",filterSoldData);
  // const filterSoldData = 

  // console.log(newData);
  // ------------------------------------------------------------------------------------
  return (
    <Container fluid className="px-4">
      <Row className="px-3">
        <Col sm={2} className="px-0">
          {/* Icon in the first column */}
          {/* <div className={styles.toggleView}>
            <img
              src={view === "c" ? Image.cardViewS : Image.cardView}
              alt="card-view"
              width="50%"
              height="100%"
              className={styles.cardView}
              onClick={() => handleViewToggle("c")}
            />
            <img
              src={view === "l" ? Image.listViewS : Image.listView}
              alt="list-view"
              width="50%"
              height="100%"
              className={styles.listView}
              onClick={() => handleViewToggle("l")}
            />
          </div> */}
        </Col>

        <Col className="d-flex justify-content-end align-items-center px-0">
          {/* First dropdown */}
          {/* <div>
            <button
              className={`btn dropdown-toggle me-2 ${styles.drpBtn} ${styles.pageCount}`}
              type="button"
              onClick={() => toggleDropdown("page-count")}
            >
              10
            </button>
            {openDropdown === "page-count" && (
              <ul
                className={`${styles.pageCountList} p-0`}
                ref={dropdownRef}
                style={{ display: openDropdown ? "block" : "none" }}
              >
                <li>
                  <Button className="dropdown-item">1</Button>
                </li>
                <li>
                  <Button className="dropdown-item">2</Button>
                </li>
              </ul>
            )}
          </div> */}

          {/* Second dropdown */}
          {/* {view === "c" && (
            <div>
              <button
                className={`btn dropdown-toggle ${styles.drpBtn} ${styles.sortBy}`}
                type="button"
                onClick={() => toggleDropdown("sort-by")}
              >
                Sort by
              </button>
              {openDropdown === "sort-by" && (
                <ul
                  className={`${styles.sortByList} p-0`}
                  ref={dropdownRef}
                  aria-labelledby="dropdownMenuButton2"
                  style={{ display: openDropdown ? "block" : "none" }}
                >
                  <li
                    className={`list-style-none dropdown-item ${styles.sortByItem}`}
                    onClick={() => handleSort("oldest")}
                  >
                    Oldest
                  </li>
                  <li
                    className={`list-style-none dropdown-item ${styles.sortByItem}`}
                    onClick={() => handleSort("")}
                  >
                    Newest
                  </li>
                </ul>
              )}
            </div>
          )} */}
        </Col>
      </Row>

      <Row>
        <Col sm={1} className="h-100">
          {/* <div className="select-btn"></div> */}
        </Col>
        <Col sm={11} className="px-0" style={{ position: "relative" }}>
          <Container fluid className="px-3">
            <Row>
              {/* input field start */}
              <Col
                sm={11}
                className="input-group mt-3 mb-4"
                id="act-search"
              >
                <Button
                  className="btn border-0 dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ backgroundColor: "#e8a262" }}
                >
                  All
                </Button>
                <ul className="dropdown-menu">
                  <li onClick={() => handleSelect("Price10k")}>
                    <Link className="dropdown-item" to="#">
                      Price &lt; 10k
                    </Link>
                  </li>
                  <li onClick={() => handleSelect("Mileage50k")}>
                    <Link className="dropdown-item" to="#">
                      Miles &lt; 50k
                    </Link>
                  </li>
                  <li onClick={() => handleSelect("Mileage100k")}>
                    <Link className="dropdown-item" to="#">
                      Miles &lt; 100k
                    </Link>
                  </li>
                </ul>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Inventory"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)} // Update state on input change
                  onKeyPress={handleKeyPress} // Call handleKeyPress on key press
                />
                <Button
                  className="btn border-0"
                  type="button"
                  id="button-addon1"
                  style={{ backgroundColor: "#e8a262" }}
                  onClick={handleButtonClick}
                >
                  <i className="fas fa-search"></i>
                </Button>
                <Button
                  className={`btn border-0 dropdown-toggle btn-eq-wd ${selectedFilter.year === "Year" || selectedFilter.year === null ? "" : 'bg-secondary'}`}
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  id="dropdownYear" // Added ID for accessibility
                  style={{
                    backgroundColor: "#e8a262",
                    margin: "0 0.3rem",
                  }}
                >
                  {selectedFilter.year}
                </Button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownYear"
                  style={{ maxHeight: "300px", overflowY: "scroll" }}
                >
                  <li onClick={handleIncrement}>
                    <Link className={`dropdown-item ${selectedFilter.year === ''
                      ? "selected-item"
                      : ""
                      }`} to="#">
                      Select None
                    </Link>
                  </li>
                  {initialFilters &&
                    [
                      ...new Set(initialFilters.map((car) => car.year)),
                    ].sort((a, b) => a - b).map((year, index) => (
                      <li
                        key={index}
                        onClick={() => handleSelect(year, "year")}
                      >
                        <Link className={`dropdown-item ${selectedFilter.year === year
                          ? "selected-item"
                          : ""
                          }`} to="#">
                          {year}
                        </Link>
                      </li>
                    ))}
                </ul>

                <Button
                  className={`btn border-0 dropdown-toggle btn-eq-wd ${selectedFilter.make === "Make" || selectedFilter.make === null ? "" : 'bg-secondary'}`}
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  id="dropdownMake" // Added ID for accessibility
                  style={{ backgroundColor: "#e8a262" }}
                >
                  {selectedFilter.make}
                </Button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMake"
                  style={{ maxHeight: "300px", overflowY: "scroll" }}
                >
                  <li onClick={handleIncrement}>
                    <Link className={`dropdown-item ${selectedFilter.make === ''
                      ? "selected-item"
                      : ""
                      }`} to="#">
                      Select None
                    </Link>
                  </li>
                  {initialFilters &&
                    [
                      ...new Set(initialFilters.map((car) => car.make)),
                    ].sort().map((make, index) => (
                      <li
                        key={index}
                        onClick={() => handleSelect(make, 'make')}
                      >
                        <Link className={`dropdown-item ${selectedFilter.make === make
                          ? "selected-item"
                          : ""
                          }`} to="#">
                          {make}
                        </Link>
                      </li>
                    ))}
                </ul>

                <Button
                  className={`btn border-0 dropdown-toggle btn-eq-wd ${selectedFilter.models === "Model" || selectedFilter.models === null ? "" : 'bg-secondary'}`}
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  id="dropdownModel" // Added ID for accessibility
                  style={{
                    backgroundColor: "#e8a262",
                    margin: "0 0.3rem",
                  }}
                >
                  {selectedFilter.models}
                </Button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownModel"
                  style={{ maxHeight: "300px", overflowY: "scroll" }}
                >
                  <li onClick={handleIncrement}>
                    <Link className={`dropdown-item ${selectedFilter.models === ''
                      ? "selected-item"
                      : ""
                      }`} to="#">
                      Select None
                    </Link>
                  </li>
                  {initialFilters &&
                    [
                      ...new Set(initialFilters.map((car) => car.model)),
                    ].sort().map((model, index) => (
                      <li
                        key={index}
                        onClick={() =>
                          handleSelect(model, 'models')
                        }
                      >
                        <Link className={`dropdown-item ${selectedFilter.models === model
                          ? "selected-item"
                          : ""
                          }`} to="#">
                          {model}
                        </Link>
                      </li>
                    ))}
                </ul>

                <Button
                  className={`btn border-0 dropdown-toggle btn-eq-wd ${selectedFilter.bodyClass === "Body Type" || selectedFilter.bodyClass === null ? "" : 'bg-secondary'}`}
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  id="bodyClass" // Added ID for accessibility
                  style={{
                    backgroundColor: "#e8a262",
                    margin: "0 0.3rem",
                  }}
                >
                  {selectedFilter.bodyClass}
                </Button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="bodyClass"
                  style={{ maxHeight: "300px", overflowY: "scroll" }}
                >
                  <li
                    onClick={handleIncrement}
                  >
                    <Link className={`dropdown-item ${selectedFilter.bodyClass === ''
                      ? "selected-item"
                      : ""
                      }`} to="#">
                      Select None
                    </Link>
                  </li>
                  {initialFilters &&
                    [
                      ...new Set(
                        initialFilters.map((car) => car.bodyClass)
                      ),
                    ].sort().map((bodyClass, index) => (
                      <li
                        key={index}
                        onClick={() =>
                          handleSelect(bodyClass, 'bodyClass')
                        }
                      >
                        <Link className={`dropdown-item ${selectedFilter.bodyClass === bodyClass
                          ? "selected-item"
                          : ""
                          }`} to="#">
                          {bodyClass}
                        </Link>
                      </li>
                    ))}
                </ul>

                <Button
                  className={`btn border-0 dropdown-toggle btn-eq-wd ${selectedSort === "Desc" || selectedSort === "Asc" ? 'bg-secondary' : ""}`}
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  id="sortBy" // Added ID for accessibility
                  style={{
                    backgroundColor: "#e8a262",
                    margin: "0 0.3rem",
                  }}
                >
                  Sort BY
                </Button>
                <ul className="dropdown-menu" aria-labelledby="sortBy">
                  <li onClick={() => handleSelect("Desc")}>
                    <Link className={`dropdown-item ${selectedSort === "Desc" ? 'selected-item' : ''}`} to="#">
                      Newest
                    </Link>
                  </li>
                  <li onClick={() => handleSelect("Asc")}>
                    <Link className={`dropdown-item ${selectedSort === "Asc" ? 'selected-item' : ''}`} to="#">
                      Oldest
                    </Link>
                  </li>
                </ul>

                <Button
                  className="btn border-0 btn-eq-wd"
                  type="button"
                  id="button-addon1"
                  style={{ backgroundColor: "#e8a262" }}
                  onClick={handleIncrement}
                >
                  <BiReset className="icons-r-flt" />
                </Button>
              </Col>
              {/* input field end */}
            </Row>
          </Container>
        </Col>
      </Row>

      <Row className={`mx-0 rounded bg-body border-2 ${styles.bgTr}`} style={{ width: "100%" }}>
        {tableData &&
          tableData?.map((data, index) => {
            return (
              <SellDraftCard
                data={{
                  ...data,
                  daysDifference: calculateDaysDifference(data.inspection),
                }}
                index={index}
                key={index}
                selectedCards={selectedCards}
                handleSelected={handleSelected}
                handleWebsite={handleWebsite}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                onUpdate={handleUpdate}
                fetchData={fetchData}
              />
            );
          })}
      </Row>
    </Container>
  );
};

export default DealerDraft;

import React from "react";
import Styles from "./Web1.module.css";
import Navbar1 from "../../components/Navbar1";
import MainMarquee1 from "../../components/Marquee1";
import HomeFilters1 from "../../components/HomeFilter1";
import ExtraFeatures1 from "../../components/ExtraFeatures1";
import VehicleSlider1 from "../../components/VehicleSlider1";
import NearByCars1 from "../../components/NearBycars1";
import VehicleReport1 from "../../components/VehicleReport";
import Footer from "../../../Footer";

const Web1 = () => {
  return (
    <div className={Styles.wrapper}>
      <div className={Styles.container}>
        {/* <noscript> */}
        <p
          style={{
            backgroundColor: "black",
            padding: "0.5rem",
            fontSize: "larger",
            color: "white",
            fontWeight: "500",
            textAlign: "center",
            justifyContent: "space-evenly",
            display: "flex",
            border: "1px solid white",
          }}
        >
          <span>Home</span>
          <span>Inventory</span>
          <span>Finance</span>
          <span>About Us</span>
        </p>
        <Navbar1 />
        <MainMarquee1 />
        <HomeFilters1 route="/dealer/webPreview1" />
        <ExtraFeatures1 />
        <VehicleSlider1 route="/dealer/webPreview1" />
        <NearByCars1 />
        <VehicleReport1 />
        <Footer />
      </div>
    </div>
  );
};

export default Web1;

import React, { useEffect, useRef, useState } from "react";
import "./cardetail.css";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import Navbar from "../navbars/Navbar";
import { FaPhoneVolume } from "react-icons/fa6";
import { BsFillFuelPumpFill } from "react-icons/bs";
import Image360Viewer from "./Image360Viewer";
import {
  IoMdSpeedometer,
  IoLogoModelS,
  IoMdColorFill,
  IoMdInformationCircleOutline,
} from "react-icons/io";
import { CgChevronLeft, CgChevronRight, CgColorBucket } from "react-icons/cg";
import { IoCheckmarkCircle, IoShareSocial } from "react-icons/io5";
import { TbEngine, TbBrandBootstrap } from "react-icons/tb";
import { LuFuel } from "react-icons/lu";
import { GrSend } from "react-icons/gr";
import { FaArrowLeft } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import { GiCarDoor, GiRoad, GiGearStickPattern } from "react-icons/gi";
import { MdOutlineDateRange } from "react-icons/md";
import { FaCarSide, FaRoad } from "react-icons/fa";
import Image from "../imgData";
import Pannellum from "pannellum";
import { useDispatch, useSelector } from "react-redux";

import {
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookShareButton,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

import axios from "axios";
import { Button } from "react-bootstrap";
import {
  resetBuyerSellData,
  resetCoBuyerSellData,
  setActiveTab,
  setSelectedVehicle,
} from "../../Redux/slice/sellSlice";
import { setRetailData } from "../../Redux/slice/taxes&FeesSlice";
import SellData from "../DealerSection/DealerInventory/activeInventory/SellData";
import DealerSideBar from "../DealerSection/DealerSideBar/DealerSideBar";
// import SimilarCarCard from "./SimilarCarCard";
const apiUrl = process.env.REACT_APP_API_URL;
const imgUrl = process.env.REACT_APP_IMG_URL;

const CarDetails = () => {
  const userData = useSelector((state) => state.user);
  const { firstName, lastName, id, email, roles } = userData;

  // console.log("userDealer", roles);
  // console.log("userID Car Deatails page", id);
  const { productId } = useParams();
  let [idCar, userCar] = productId.split('_');
  console.log("productId", idCar,userCar);
  const Navigate = useNavigate();
  const navigate = useNavigate();
  // const history = useHistory();
  const { state } = useLocation();
  // const productData = state?.product;
  const [product, setProduct] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const id = parseInt(productId.split("_", 1)); // Parse the product ID
        const userId = parseInt(userCar);  // Parse the user ID
        
        const response = await axios.get(`${apiUrl}/listing/get_product/${userId}/${id}`);
        console.log("response1234******",response.data);
        setProduct(response.data);  // Update the product state with the fetched data
        
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    
    fetchData();
  }, [productId, userCar]); // Fetch when productId or userCar changes
  
  // Use another effect to act after product is updated
  useEffect(() => {
    if (product !== undefined) {
      console.log("Product has been set:", product);  // Only log when product is no longer undefined
    }
  }, [product]);

  // const product = state?.product;
  // console.log("single product details:-", product);
  // const carUserId = product.userId;
  //    for calculator-------------
  const [vehiclePrice, setVehiclePrice] = useState(product?.listing_price || 0);
  const [tradeInValue, setTradeInValue] = useState("0");
  const [interestRate, setInterestRate] = useState("6.85");
  const [downPayment, setDownPayment] = useState("0");
  const [loanTermInMonth, setLoanTermInMonth] = useState("48");
  const [titleRegistrationFees, setTitleRegistrationFees] = useState(0);
  const [activeButton, setActiveButton] = useState("48");
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const [loanAountData, setLoanAmountData] = useState(0);
  const [activeRate, setActiveRate] = useState(6.85); //for button bg color only----------
  const [showInput, setShowInput] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const engine = product?.engine;
  // const match = product.engine.match(/(\d+\.\d+L)/);

  // const engineSize = match ? match[1] : "N/A"; // Extract the first match or 'N/A' if no match is found
  // console.log(engineSize); // Output: "3.0L"
  // ------------------------------
  // for equipment-----------------------*********************************************----------------------------------------
  const [itemsToShow, setItemsToShow] = useState(6);
  const [startIndex, setStartIndex] = useState(0);
  // --------------------------------------------------

  useEffect(() => {
    // Scroll to top of the page when component is mounted
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures this runs once on mount

  const renderOptions = (options) => {
    const result = [];
    for (const category in options) {
      if (
        category !== "extraVehicleOptions" &&
        typeof options[category] === "object"
      ) {
        for (const feature in options[category]) {
          if (options[category][feature] === true) {
            result.push(formatFeatureName(feature));
          }
        }
      }
    }
    return result;
  };

  const renderExtraOptions = (extraOptions) => {
    return extraOptions
      .map((option) => {
        if (option.option_value === true) {
          return formatFeatureName(option.option_name);
        }
        return null;
      })
      .filter((option) => option !== null);
  };

  const formatFeatureName = (name) => {
    return name
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const handleShowMore = () => {
    setItemsToShow(itemsToShow + 6);
  };
  const vehicleOptions = product?.vehicleOptions || {};
  const extraVehicleOptions = vehicleOptions?.extraVehicleOptions || [];
  // Combine options and extra options into a single array
  const allOptions = [
    ...renderOptions(vehicleOptions),
    ...renderExtraOptions(extraVehicleOptions),
  ];

  // Function to chunk array into columns
  const chunkArray = (array, chunkSize) => {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunkedArray.push(array.slice(i, i + chunkSize));
    }
    return chunkedArray;
  };

  // Chunk allOptions into two columns
  const chunkedOptions = chunkArray(allOptions.slice(0, itemsToShow), 3);

  // equipmwnt ends-----------------------********************************************----------------------------------------
  const maskVin = (vin) => {
    return vin?.slice(0, 4) + "*".repeat(vin?.length - 4);
  };

  const maskMileageIn = () => {
    const mileage_in = product.mileage_in.toString();
    return "*".repeat(mileage_in.length);
  };

  // Helper function to get the border color
  const getBorderColor = (condition) => {
    switch (condition) {
      case "New 8/32>":
        return Image.greenTyre;
      case "Good 6/32-7/32>":
        return Image.yellowTyre;
      case "Fair <5/32":
        return Image.blueTyre;
      case "Fully-Used NA":
        return Image.blueTyre;
      default:
        return Image.greenTyre; // Default color if condition is unknown
    }
  };
  // console.log("ImageHi", getBorderColor(product.tyrecondition_st));
  const [isSticky, setIsSticky] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const debounce = (func, wait) => {
      let timeout;
      return function (...args) {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait);
      };
    };

    const debouncedStickNavbar = debounce(stickNavbar, 100);

    window.addEventListener("scroll", debouncedStickNavbar);
    return () => window.removeEventListener("scroll", debouncedStickNavbar);
  }, []);

  const stickNavbar = () => {
    if (typeof window !== "undefined") {
      let scrollPosition = window.scrollY;
      if (scrollPosition > 0) {
        setIsSticky(true);
        setIsVisible(true);
      } else {
        setIsVisible(false);
        setIsSticky(false);
      }
    }
  };

  // for edit the draft----------------
  const handleLinkClick = (vin) => {
    if (roles === "Dealer") {
      navigate("/dealer/inventory/add", { state: { vin, active: true } });
    } else if (roles === "User") {
      navigate("/myprofile/addlist", { state: { vin, active: true } });
    }
  };
  //  for sell--------------------------
  const dispatch = useDispatch();
  const [showModalSell, setShowModalSell] = useState(false);
  const [selldata, setSellData] = useState();
  const handleModalCloseSell = () => {
    setShowModalSell(false);
  };
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const handleGoSell = (dataSell) => {
    // navigate("/dealer/sell", { state: {dataSell} });
    setSellData(dataSell);
    // dispatch(saveUser({ isSideBarOpen: false }));
    // Make sure that 'data' is correctly defined and holds the necessary information
    console.log("data to be sent to sell after cliking on card", dataSell);
    // Navigate(`/dealer/sell#${data.stock_no}#${data.id}`, { state: { data } });
    setShowModalSell(false);
    // dispatch(setActiveTab("Vehicle List"));
    dispatch(setSelectedVehicle(dataSell));
    dispatch(
      setRetailData({
        listingId: dataSell.id,
      })
    );
    // Compare with the previous listingId
    dispatch(resetCoBuyerSellData());
    dispatch(resetBuyerSellData());
    dispatch(
      setRetailData({
        salesTaxes: "6.25",
        mInventoryTax: "0.001488",
        titleApplicationFee: 13.0,
        terpTitlefee: 20,
        drivesOutTag: 5.0,
        windshieldTrackerSticker: 50.75,
        regFeeDps: 1,
        cntyRoadBridgeFee: 10,
        childSafetyFund: 1.5,
        InspectionFeeOBDNL: 25,
        StateInspectionFees: 8.25,
        processingHandlingFee: 4.75,
        documentationFee: 150.0,
        technologyFees: 0.0,
        buyerTagFees: 5,
        other1: 0.0,
        other2: 0.0,
        cardFees: 0,
        warranty: 0,
        gapInsurance: 0,
        mailDeliveryFees: 0,
        transportationFees: 0,
        totalFees: 0,
        totalFeesAndothers: 273,
        txtState: "",
        listingPrice: "",
        userListingPrice: 0,
        txtTitleType: null,
        ownedTrade: "",
        cashDifference: "",
        paymentPriceFees: "",
        date: getCurrentDate(),
        saleTaxValue: "",
        mInventoryValue: "",
        balanceDue: "",
        totalDue: 0,
        // -----------------------------------
        lienHolderName: "",
        leanHolderId: "",
        lienHolderID: "",
        lienHolderAddress: "",
        lienHolderAddress2: "",
        serviceContractId: "",
        serviceContractName: "",
        serviceContractLicense: "",
        serviceContractAddress: "",
        serviceProviderActivationStatus: "",
        serviceProviderType: "",
        providerPhoneNumber: "",
        providerCounty: "",
        providerListingExpiry: "",
        priceFeesPaymentMethod: "",
        //Gap Insurance Provider Data
        gapInsuranceId: "",
        gapName: "",
        gapLicense: "",
        gapAddress: "",
        gapActivationStatus: "",
        gapType: "",
        gapPhoneNumber: "",
        gapCounty: "",
        gapListingExpiry: "",
        // Sell Form ---------------------------
        sellForm: "",
        // tax maker forms-----------------------
        taxMakerForms: "",
        // trade in key--------
        dealerId: 0,
        vin: "",
        year_make_model: "",
        // make year modal only check purpose for backend------
        make: "",
        year: "",
        model: "",
        bodyClass: "",
        wheelbase: "",
        driveType: "",
        doorCount: "",
        // -----------------------
        trim: "",
        engine: "",
        transmission: "",
        vehicleType: "",
        seat_count: "",
        city_mpg: "",
        highway_mpg: "",
        inspection: "",
        exteriorColor: "",
        interiorColor: "",
        interiorType: "",
        mileage_in: "",
        purchase_price: "",
        //tradeInValue: formData.tradeInValue,
        listing_price: "",
        mileageIn: "",
        // trade in key end--------
        // for images of vehicle card--------
        imageUrl: "",
      })
    );
    navigate("/dealer/sell");
    dispatch(setActiveTab("Buyer"));
  };
  // for calculator---------------------------
  useEffect(() => {
    calculateMonthlyPayment();
  }, [
    vehiclePrice,
    tradeInValue,
    interestRate,
    downPayment,
    loanTermInMonth,
    titleRegistrationFees,
  ]);

  const calculateMonthlyPayment = () => {
    const P = parseFloat(vehiclePrice) + parseFloat(titleRegistrationFees);
    const TIV = parseFloat(tradeInValue);
    const r = parseFloat(interestRate) / 100 / 12;
    const DP = parseFloat(downPayment);
    const n = parseInt(loanTermInMonth, 10);

    if (isNaN(P) || isNaN(TIV) || isNaN(r) || isNaN(DP) || isNaN(n)) {
      return;
    }

    const loanAmount = P - DP - TIV;
    setLoanAmountData(loanAmount);
    const M =
      (loanAmount * (r * Math.pow(1 + r, n))) / (Math.pow(1 + r, n) - 1);
    //const roundedValue = M > 0 ? Math.round(M) : Math.ceil(M);
    setMonthlyPayment(Math.ceil(M));
  };
  // bg color-----------------------------------
  const handleButtonClickb = (rate) => {
    setInterestRate(rate);
    setActiveRate(rate); // Set the active rate to the selected rate
  };

  // Function to get button styles based on whether it's active
  const getButtonStyles = (rate) => ({
    backgroundColor: activeRate === rate ? "black" : "white",
    color: activeRate === rate ? "white" : "black",
  });
  // btn bg color---------------------------------------
  // console.log("vehiclePrice:", vehiclePrice);
  // console.log("tradeInValue:", tradeInValue);
  // console.log("interestRate:", interestRate);
  // console.log("downPayment:", downPayment);
  // console.log("loanTermInMonth:", loanTermInMonth);

  const handleButtonClick = (value) => {
    if (activeButton === value) {
      setActiveButton(null);
      setLoanTermInMonth("");
    } else {
      setActiveButton(value);
      setLoanTermInMonth(value);
    }
  };

  const getButtonStyle = (value) => {
    return activeButton === value
      ? { backgroundColor: "black", color: "white" }
      : {};
  };
  const handleMoreButtonClick = () => {
    setShowInput(true);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyDown = (event) => {
    if (event.key === "Enter") {
      handleButtonClick(inputValue);
      setShowInput(false); // Hide input field after pressing Enter
    }
  };
  const handleInputBlur = () => {
    // Hide input field if it loses focus
    setShowInput(false);
  };

  useEffect(() => {
    setVehiclePrice(product?.listing_price || "");
    console.log("productId", productId.split("_", 1));
    const id = parseInt(productId.split("_", 1));
    console.log(id[0], typeof id[0]);
  }, [product]);
  // -----------------------------------------------------------------------------------
  // for images-----------------------------------------------------------------------
  // Initialize state with all images as the default
  // State variables
  //const [images, setImages] = useState([...]);
  const [showVideos, setShowVideos] = useState(false);
  const [show360, setShow360] = useState(false);
  const [selected360Image, setSelected360Image] = useState(null);
  const [videos, setVideos] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const [categoryCounts, setCategoryCounts] = useState({
    All: 0,
    Exterior: 0,
    Interior: 0,
    Others: 0,
    360: 0,
    Videos: 0,
  });

  const calculateCounts = (product) => {
    const counts = {};

    const countItems = (items) =>
      Array.isArray(items) ? items.length : items.split(",").length;

    if (product && product?.multimedia?.exterior_images) {
      counts["Exterior"] = countItems(product.multimedia.exterior_images);
    }
    if (product && product?.multimedia?.interior_images) {
      counts["Interior"] = countItems(product.multimedia.interior_images);
    }
    if (product && product?.multimedia?.misc_images) {
      counts["Others"] = countItems(product.multimedia?.misc_images);
    }
    if (product && product?.multimedia?.three_sixty_images) {
      counts["360"] = countItems(product.multimedia.three_sixty_images);
    }

    if (product && product?.multimedia?.all_videos) {
      counts["Videos"] = countItems(product.multimedia.all_videos);
    }

    // const allImages = [
    //   ...(Array.isArray(product.multimedia.all_images)
    //     ? product.multimedia.all_images
    //     : product.multimedia.all_images.split(",")),
    //   ...(Array.isArray(product.multimedia.exterior_images)
    //     ? product.multimedia.exterior_images
    //     : product.multimedia.exterior_images.split(",")),
    //   ...(Array.isArray(product.multimedia.interior_images)
    //     ? product.multimedia.interior_images
    //     : product.multimedia.interior_images.split(",")),
    //   ...(Array.isArray(product.multimedia.misc_images)
    //     ? product.multimedia.misc_images
    //     : product.multimedia.misc_images.split(",")),
    //   ...(Array.isArray(product.multimedia.three_sixty_images)
    //     ? product.multimedia.three_sixty_images
    //     : product.multimedia.three_sixty_images.split(",")),
    // ]
    //   .filter((item, index, self) => self.indexOf(item) === index)
    //   .filter((item) => item !== "");

    // counts["All"] = allImages.length;

    // return counts;
    const allImages = [
      ...(Array.isArray(product?.multimedia?.all_images)
        ? product.multimedia.all_images
        : typeof product?.multimedia?.all_images === "string"
        ? product.multimedia.all_images.split(",")
        : []),
      ...(Array.isArray(product?.multimedia?.exterior_images)
        ? product.multimedia.exterior_images
        : typeof product?.multimedia?.exterior_images === "string"
        ? product.multimedia.exterior_images.split(",")
        : []),
      ...(Array.isArray(product?.multimedia?.interior_images)
        ? product.multimedia.interior_images
        : typeof product?.multimedia?.interior_images === "string"
        ? product.multimedia.interior_images.split(",")
        : []),
      ...(Array.isArray(product?.multimedia?.misc_images)
        ? product.multimedia.misc_images
        : typeof product?.multimedia?.misc_images === "string"
        ? product.multimedia.misc_images.split(",")
        : []),
      ...(Array.isArray(product?.multimedia?.three_sixty_images)
        ? product.multimedia.three_sixty_images
        : typeof product?.multimedia?.three_sixty_images === "string"
        ? product.multimedia.three_sixty_images.split(",")
        : []),
    ]
      .filter((item, index, self) => self.indexOf(item) === index)
      .filter((item) => item !== "");

    counts["All"] = allImages.length;

    return counts;
  };

  // Effect to calculate and set initial counts
  useEffect(() => {
    const counts = calculateCounts(product);
    setCategoryCounts(counts);
  }, [product]); // Depend on 'product' if it can change

  // console.log("categoryCounts", categoryCounts);
  const [images, setImages] = useState([]);
  // console.log("images", images);
  // ----for bg cat images---------------------------------------------------------------------------------
  const [imageUrls, setImageUrls] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const videoRef = useRef(null);

  useEffect(() => {
    const populateImageUrls = () => {
      const categories = ["Exterior", "Interior", "Others", "360", "All"];
      const imageUrlsObj = {};
      categories.forEach((category) => {
        let images;

        switch (category) {
          case "Exterior":
            images = product?.multimedia?.exterior_images;
            break;
          case "Interior":
            images = product?.multimedia?.interior_images;
            break;
          case "Others":
            images = product?.multimedia?.misc_images;
            break;
          case "360":
            images = product?.multimedia?.three_sixty_images;
            break;
          case "All":
            images = [
              ...(Array.isArray(product?.multimedia?.all_images)
                ? product.multimedia.all_images
                : (product?.multimedia?.all_images || "").split(",")),
              ...(Array.isArray(product?.multimedia?.exterior_images)
                ? product.multimedia.exterior_images
                : (product?.multimedia?.exterior_images || "").split(",")),
              ...(Array.isArray(product?.multimedia?.interior_images)
                ? product.multimedia.interior_images
                : (product?.multimedia?.interior_images || "").split(",")),
              ...(Array.isArray(product?.multimedia?.misc_images)
                ? product.multimedia.misc_images
                : (product?.multimedia?.misc_images || "").split(",")),
              ...(Array.isArray(product?.multimedia?.three_sixty_images)
                ? product.multimedia.three_sixty_images
                : (product?.multimedia?.three_sixty_images || "").split(",")),
            ]
              .filter((item, index, self) => self.indexOf(item) === index)
              .filter((item) => item !== "");
            break;
          default:
            images = [];
            break;
        }

        const imagesArray = Array.isArray(images)
          ? images
          : images
          ? images.split(",")
          : [];
        let imageUrl;

        for (let i = 0; i < imagesArray.length; i++) {
          const image = imagesArray[i];
          if (image) {
            imageUrl = `${imgUrl}/${product.userId}/${encodeURIComponent(
              image
            )}`;
            console.log("437", imageUrl);
            break;
          }
        }

        imageUrlsObj[category] = imageUrl;
      });

      setImageUrls(imageUrlsObj);
    };

    populateImageUrls();
  }, [product]);

  // console.log("450 imagecat", imageUrls);
  // console.log("450 imagecat", imageUrls.All);

  // ----------------------------------------------------------------------------------------------------------------------
  const [selectedCategory, setSelectedCategory] = useState("All");
  const handleCategoryClick = (category = "ALL") => {
    let categoryImages;
    let categoryVideos;
    let count = 0;
    // console.log("category", category);
    switch (category) {
      case "Exterior":
        categoryImages = product?.multimedia.exterior_images;
        count = Array.isArray(categoryImages)
          ? categoryImages.length
          : categoryImages.split(",").length;
        break;
      case "Interior":
        categoryImages = product?.multimedia.interior_images;
        count = Array.isArray(categoryImages)
          ? categoryImages.length
          : categoryImages.split(",").length;
        break;
      case "Others":
        categoryImages = product?.multimedia.misc_images;
        count = Array.isArray(categoryImages)
          ? categoryImages.length
          : categoryImages.split(",").length;
        break;
      case "360":
        categoryImages = product?.multimedia.three_sixty_images;
        count = Array.isArray(categoryImages)
          ? categoryImages.length
          : categoryImages.split(",").length;
        break;
      case "Videos":
        categoryVideos = product?.multimedia?.all_videos;
        count = Array.isArray(categoryVideos)
          ? categoryVideos.length
          : categoryVideos.split(",").length;
        break;
      case "All":
        const allImages = [
          ...(Array.isArray(product?.multimedia.all_images)
            ? product.multimedia.all_images
            : product.multimedia.all_images.split(",")),
          ...(Array.isArray(product?.multimedia.exterior_images)
            ? product.multimedia.exterior_images
            : product.multimedia.exterior_images.split(",")),
          ...(Array.isArray(product?.multimedia.interior_images)
            ? product.multimedia.interior_images
            : product.multimedia.interior_images.split(",")),
          ...(Array.isArray(product?.multimedia.misc_images)
            ? product.multimedia.misc_images
            : product.multimedia.misc_images.split(",")),
          ...(Array.isArray(product?.multimedia.three_sixty_images)
            ? product.multimedia.three_sixty_images
            : product.multimedia.three_sixty_images.split(",")),
        ]
          .filter((item, index, self) => self.indexOf(item) === index)
          .filter((item) => item !== "");
        categoryImages = allImages;
        count = allImages.length;
        break;
      default:
        categoryImages = [];
        categoryVideos = [];
        count = 0;
        break;
    }
    setSelectedCategory(category);
    setCategoryCounts((prevCounts) => ({
      ...prevCounts,
      [category]: count,
    }));

    if (category === "Videos") {
      setShowVideos(true);
      const videoUrls = Array.isArray(categoryVideos)
        ? categoryVideos.map((video) => `${imgUrl}/${product.userId}/${video}`)
        : categoryVideos
            .split(",")
            .map((video) => `${imgUrl}/${product.userId}/${video}`);
      setVideos(videoUrls);
      if (videoUrls.length > 0) {
        setSelectedVideo(videoUrls[0]);
      } else {
        setSelectedVideo(null);
      }
    } else {
      setShowVideos(false);
      const imageUrls = Array.isArray(categoryImages)
        ? categoryImages.map((image) => `${imgUrl}/${product.userId}/${image}`)
        : categoryImages
            .split(",")
            .map((image) => `${imgUrl}/${product.userId}/${image}`);
      setImages(imageUrls);
      setCurrentImageIndex(0);
    }
  };

  const initializeVideos = () => {
    const allVideos = product?.multimedia?.all_videos;
  
    // Ensure that all_videos is a string before calling .split()
    if (allVideos) {
      const videoUrls = allVideos
        .split(",")
        .map((video) => `${imgUrl}/${product.userId}/${video}`);
      
      setVideos(videoUrls);
      
      // Set the selected video to the first one in the list or null if no videos are present
      if (videoUrls.length > 0) {
        setSelectedVideo(videoUrls[0]);
      } else {
        setSelectedVideo(null);
      }
    } else {
      // If all_videos is undefined or null, handle accordingly
      setVideos([]);
      setSelectedVideo(null);
    }
  };
  
  useEffect(() => {
    initializeVideos();
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.autoplay = true;
      videoRef.current.loop = false; // set loop to false to play each video in sequence
      videoRef.current.onended = () => {
        setCurrentIndex((currentIndex + 1) % videos.length);
      };
    }
  }, [videos, currentIndex]);
  // console.log("578videos", videos);
  useEffect(() => {
    if (product?.multimedia) {
      const {
        all_images,
        three_sixty_images,
        exterior_images,
        misc_images,
        interior_images,
        all_videos,
      } = product.multimedia;
      if (all_images) {
        handleCategoryClick("All");
      }
      if (three_sixty_images) {
        handleCategoryClick("360");
        handleCategoryClick("All");
      }
      if (exterior_images) {
        handleCategoryClick("Exterior");
        handleCategoryClick("All");
      }
      if (interior_images) {
        handleCategoryClick("Interior");
        handleCategoryClick("All");
      }
      if (misc_images) {
        handleCategoryClick("Others");
        handleCategoryClick("All");
      }
      if (all_videos) {
        // handleCategoryClick("Videos");
      }
    }
  }, [product]);

  const handleVideoClick = (videoUrl, index) => {
    setSelectedVideo(videoUrl);
    setCurrentIndex(index);
    setShowVideos(true);
  };

  const [selectedImage, setSelectedImage] = useState(null);

  const slideLeft = () => {
    setStartIndex((prevIndex) =>
      prevIndex < 0 ? images.length - 5 : prevIndex
    );
    handlePrevImage();
  };
  // console.log("startIndex", startIndex);

  const slideRight = () => {
    setStartIndex((prevIndex) => (prevIndex >= images.length ? 0 : prevIndex));
    handleNextImage();
  };
  // for video------------------------
  const slideLeftVideo = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < 0 ? videos.length - 5 : prevIndex
    );
    handlePrevVideo();
  };
  // console.log("startIndex", startIndex);

  const slideRightVideo = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex >= videos.length ? 0 : prevIndex
    );
    handleNextVideo();
  };

  // console.log("startIndex", startIndex);
  // -----------------------------------------------------
  useEffect(() => {
    if (videos.length > 0) {
      setSelectedVideo(videos[currentIndex]);
    }
  }, [currentIndex, videos]);

  const handleNextVideo = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % videos.length);
  };

  const handlePrevVideo = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + videos.length) % videos.length
    );
  };
  // Render videos
  const visibleVideos = videos.slice(startIndex, startIndex + videos.length);

  const renderVideos = () => {
    const thumbnailCount = 6; // Total number of thumbnails to display
    const middleIndex = Math.floor(thumbnailCount / 2); // Index of the middle thumbnail

    // Calculate the startIndex such that the currentIndex is centered
    let startIndex = currentIndex - middleIndex;
    let endIndex = currentIndex + middleIndex + 1; // endIndex is exclusive

    // Adjust startIndex if it is less than 0
    if (startIndex < 0) {
      endIndex -= startIndex; // Shift endIndex to keep the total count of thumbnails the same
      startIndex = 0;
    }

    // Adjust endIndex if it exceeds the length of videos
    if (endIndex > videos.length) {
      startIndex -= endIndex - videos.length; // Shift startIndex to keep the total count of thumbnails the same
      endIndex = videos.length;
    }

    // Ensure startIndex is not negative
    startIndex = Math.max(startIndex, 0);

    // Get the visible videos slice based on adjusted indices
    const visibleVideos = videos.slice(startIndex, endIndex);

    return (
      <div className="thumbnails">
        {visibleVideos.map((video, index) => (
          <div
            className={`thumbnail ${
              currentIndex === startIndex + index ? "active" : ""
            }`}
            key={startIndex + index}
          >
            {video ? (
              <div
                className="video-thumbnail"
                onClick={() => handleVideoClick(video, startIndex + index)}
              >
                <video
                  src={video}
                  // poster="https://via.placeholder.com/125x100.png"
                  width="110"
                  height="100"
                />
              </div>
            ) : (
              <div className="empty-thumbnail"></div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const visibleImages = images.slice(startIndex, startIndex + images.length);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  // console.log(visibleImages);

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrevImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const handleThumbnailClick = (index) => {
    setCurrentImageIndex(index);
  };

  // Function to display thumbnails and handle image selection

  const renderThumbnails = () => {
    const thumbnailCount = 6; // Total number of thumbnails to display
    const middleIndex = Math.floor(thumbnailCount / 2); // Index of the middle thumbnail

    // Calculate the startIndex such that the currentImageIndex is centered
    let startIndex = currentImageIndex - middleIndex;
    let endIndex = currentImageIndex + middleIndex + 1; // endIndex is exclusive

    // Adjust startIndex if it is less than 0
    if (startIndex < 0) {
      endIndex -= startIndex; // Shift endIndex to keep the total count of thumbnails the same
      startIndex = 0;
    }

    // Adjust endIndex if it exceeds the length of visibleImages
    if (endIndex > visibleImages.length) {
      startIndex -= endIndex - visibleImages.length; // Shift startIndex to keep the total count of thumbnails the same
      endIndex = visibleImages.length;
    }

    // Ensure startIndex is not negative
    startIndex = Math.max(startIndex, 0);

    return (
      <div className="thumbnails">
        {visibleImages.slice(startIndex, endIndex).map((image, index) => (
          <div
            className={`thumbnail ${
              currentImageIndex === startIndex + index ? "active" : ""
            }`}
            key={startIndex + index}
          >
            {image ? (
              <img
                src={image}
                alt={`Thumbnail ${startIndex + index + 1}`}
                onClick={() => handleThumbnailClick(startIndex + index)}
              />
            ) : (
              <div className="empty-thumbnail"></div>
            )}
          </div>
        ))}
      </div>
    );
  };

  // ---------------------------------------------------------------------------------------------------------------------------------------------------
  const [similarCars, setSimilarCars] = useState([]);
  const sliderRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/listing/get_dealer_inventory/${product.userId}/${product.id}`
        );

        const data = response.data;
        // Ensure data is an array
        if (Array.isArray(data)) {
          setSimilarCars(data);
        } else {
          console.error("Expected an array but got:", data);
        }

        console.log("response", response.data);
      } catch (error) {
        // setError(error); // Optional, to set error state
        console.error("Error fetching data:", error);
        console.log("similarCars", similarCars);
      }
    };

    fetchData();
  }, [product]);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - sliderRef.current.offsetLeft);
    setScrollLeft(sliderRef.current.scrollLeft);
    sliderRef.current.style.cursor = "grabbing"; // Change cursor to grabbing
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const x = e.pageX - sliderRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Scroll speed multiplier
    sliderRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    sliderRef.current.style.cursor = "grab"; // Change cursor back to grab
  };

  // for single product details---------------
  const handleClick = async (car, e) => {
    // console.log("car", car);

    // e.stopPropogation();
    e.preventDefault();

    // history.push(`/cardetail/${parseInt(product.id)}`);

    Navigate(
      `/cardetail/${parseInt(car.id)}_${car.modelYear}_${car.modelMake}`,
      {
        state: { car },
      }
    );

    // const urlParams = new URLSearchParams(window.location.search);
    // urlParams.set("paramKey", "paramValue");
    // window.location.search = urlParams.toString();
    // setProduct(car);
    // console.log("car", car);
    window.scrollTo(0, 0);
    // console.log("updated product", product);
    // console.log("productId", productId);
  };

  const [showSocialMedia, setShowSocialMedia] = useState(false);
  const handleShareLink = () => {
    console.log("Sharing link with the id");
    setShowSocialMedia(!showSocialMedia);
  };

  const generateShareUrl = (itemId) => {
    return `${window.location.origin}${window.location.pathname}/${itemId}`;
  };

  const scroll = (direction) => {
    const { current } = sliderRef;
    if (current) {
      const scrollAmount = direction === "left" ? -200 : 200;
      current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };
  console.log("prduct********************************", product);
  // for tire condition------------------------------------------------------------------
  const flTire = product?.tyrecondition_fl;
  const partsFl = flTire ? flTire?.split(" ") : [];
  const statusFl = partsFl[0] || null;
  const measurementFl = partsFl.slice(1).join(" ");
  // console.log(statusFl);       // Output: Fair
  // console.log(measurementFl);  // Output: <5/32
  const frTire = product?.tyrecondition_fr;
  const partsFr = frTire ? frTire?.split(" ") : [];
  const statusFr = partsFr[0] || null;
  const measurementFr = partsFr.slice(1).join(" ");
  // ----------
  const brTire = product?.tyrecondition_br;
  const partsBr = brTire ? brTire?.split(" ") : [];
  const statusBr = partsBr[0] || null;
  const measurementBr = partsBr.slice(1).join(" ");

  const blTire = product?.tyrecondition_bl;
  const partsBl = blTire ? blTire?.split(" ") : [];
  const statusBl = partsBl[0] || null;
  const measurementBl = partsBl.slice(1).join(" ");
  // ------------------------
  const stTire = product?.tyrecondition_st;
  const partsSt = stTire ? stTire.split(" ") : [];
  const statusSt = partsSt[0] || null;
  const measurementSt = partsSt.slice(1).join(" ");
  // ------------------------------------------------------------------------------------
  const [isPermanentlyExpanded, setIsPermanentlyExpanded] = useState(() => {
    // Initialize state from localStorage, default to false if not set
    return localStorage.getItem("sidebarExpanded") === "true";
  });
  
  useEffect(() => {
    // Update localStorage whenever isPermanentlyExpanded changes
    localStorage.setItem("sidebarExpanded", isPermanentlyExpanded);
  }, [isPermanentlyExpanded]);
  const toggleSidebar = () => {
    setIsPermanentlyExpanded(!isPermanentlyExpanded);
  };
  const [isHovered, setIsHovered] = useState(false);
  const handleSidebarHover = (hovered) => {
    setIsHovered(hovered);
  };
  const isSideBarOpen = useSelector((state) => state.user.isSideBarOpen);

  return (
    <>
      {!isSticky && <Navbar />}
      {isSideBarOpen && id !== 0 && email && roles === "Dealer" && (
        <div
          className={`dealer_sidebar_home_Parent ${
            isSideBarOpen ? "ShowSidebar" : "HideSidebar"
          }`}
          style={{
            flex: isSideBarOpen ? "0.15" : "0.75",
          }}
        >
          <DealerSideBar
            isPermanentlyExpanded={isPermanentlyExpanded}
            onHoverChange={handleSidebarHover}
          />
        </div>
      )}
      <div
        className={`px-3 header-style ${
          isSticky ? "sticky-nav" : "hidden-nav"
        } ${isVisible ? "visible" : ""}`}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>
            <h4 className="title text-light fw-bold title-style">
              {product?.modelYear} {product?.modelMake} {product?.makeModel}{" "}
              {product?.trim}
            </h4>
          </div>
          <div style={{ marginLeft: "11%" }}>
            <h4 style={{ color: "white" }}>
              {/* {product.mileage_in} */}
              {new Intl.NumberFormat().format(product?.mileage_in)}
              MI
            </h4>
          </div>
        </div>
        {/* <div style={{ display: "flex", flexDirection: "row"}}><span style={{ marginRight: "7px", fontWeight: "bold", fontSize: "18px",color:"white" }}><h4><sup>$</sup>{product.listing_price}</h4></span><span style={{color:"white"}}><h4> | Est.: </h4></span><span style={{color:"white"}}><h4>{monthlyPayment || 0}/m <IoMdInformationCircleOutline style={{title:"This is an Estmated Monthly payment ONLY. It is NOT a loan."}}/></h4></span></div> */}
        <div style={{ display: "flex", flexDirection: "row" }}>
          <span
            style={{
              marginRight: "7px",
              fontWeight: "bold",
              fontSize: "18px",
              color: "white",
            }}
          >
            <h4>
              <sup>$</sup>
              {/* {product.listing_price} */}
              {new Intl.NumberFormat().format(product?.listing_price)}
            </h4>
          </span>
          <span style={{ color: "white" }}>
            <h4> | Est.: </h4>
          </span>
          <span style={{ color: "white", position: "relative" }}>
            <h4 className="text-nowrap">
              {new Intl.NumberFormat().format(monthlyPayment)}/m
              {/* {monthlyPayment || 0} */}
              <span className="tooltip-container">
                <IoMdInformationCircleOutline className="info-icon" />
                <div className="tooltip-text">
                  This is an Estimated Monthly payment ONLY. It is NOT a loan.
                </div>
              </span>
            </h4>
          </span>
        </div>
        {/* <div style={{ display: "flex", flexDirection: "row", gap: "10px", paddingTop: "18px" }}>
                    <span style={{color:"white"}}>Dallas</span>
                    <span className='phone-number-card-nav'>Call</span>
                </div> */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginRight: "25px",
          }}
        >
          <div style={{ paddingLeft: "32px" }}>
            <h6 className="mx-2" style={{ color: "white" }}>
              {/* <img
                src={Image.messanger}
                id="data-car-details-img"
                alt="messenger icon"
                style={{ marginRight: "5px" }}
              /> */}
              Check Availability
            </h6>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
            <span id="nav-text-input">
              <input placeholder="send" />
            </span>
            <span id="nav-btn-send">
              <button type="button">Send</button>
            </span>
          </div>
        </div>
      </div>

      {/* <div>
        {/* Conditionally render the button 
      {id === product?.userId && (
        <div className="edit-btn-car-details">
          <button type="button" onClick={() => handleLinkClick(product?.vin)}>
            Edit
          </button>
        </div>
      )}
    </div > */}

      <section className="pb-5">
        <div style={{ marginLeft: "30px", marginRight: "30px" }}>
          <div className="row">
            <div
              className="col-12"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div className="">
                <h2 className="title text-dark mb-4 fw-bold title-style">
                  {product?.modelYear} {product?.modelMake} {product?.makeModel}{" "}
                  {product?.trim} fghf
                </h2>
              </div>

              <div
                className=" price-container-style"
                style={{ width: "31.9%" }}
              >
                <h5 className="theme-colors price-style">
                  ${new Intl.NumberFormat().format(product?.listing_price)}
                  {/* ${product.listing_price} */}
                </h5>
                <h5 className="text-dark monthly-payment-style">
                  <span>
                    Est. ${new Intl.NumberFormat().format(monthlyPayment)} / Mo
                    {/* {monthlyPayment || 0}/m{" "} */}
                    <span className="tooltip-container">
                      <IoMdInformationCircleOutline className="info-icon" />
                      <div className="tooltip-text">
                        This is an Estimated Monthly payment ONLY. It is NOT a
                        loan.
                      </div>
                    </span>
                  </span>
                </h5>
              </div>

              {id === product?.userId && (
                <div className="edit-btn-car-details">
                  <button
                    type="button"
                    style={{ marginRight: "5px" }}
                    onClick={() => handleGoSell(product)}
                  >
                    Sell
                  </button>
                  <button
                    type="button"
                    onClick={() => handleLinkClick(product?.vin)}
                  >
                    Edit
                  </button>
                </div>
              )}
            </div>

            <aside className="col-lg-12" style={{ display: "flex" }}>
              <div
                className="col-lg-8 px-2"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div className="container">
                  <div
                    className="col-lg-12 px-2"
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <div className="category-image-car-details">
                      <span
                        onClick={
                          categoryCounts.All > 0
                            ? () => handleCategoryClick("All")
                            : undefined
                        }
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          position: "relative",
                        }}
                        className="btn-change5"
                      >
                        <img
                          src={imageUrls.All}
                          className="img-sidebar"
                          style={{
                            height: "100%",
                            width: "100%",
                            backgroundSize: "cover", // or "contain" depending on your desired behavior
                            backgroundPosition: "center",
                          }}
                        />
                        <p
                          style={{
                            display: "flex",
                            gap: "4px",
                            position: "absolute",
                          }}
                          className="cat-text-blur"
                        >
                          <p>All</p> (<p>{categoryCounts.All}</p>)
                        </p>
                      </span>

                      <span
                        onClick={
                          categoryCounts.Exterior > 0
                            ? () => handleCategoryClick("Exterior")
                            : undefined
                        }
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          cursor: !categoryCounts.Exterior && "not-allowed",
                          backgroundColor:
                            !categoryCounts.Exterior && "#A9A9A9",
                          position: "relative",
                        }}
                        className="btn-change5"
                      >
                        <img
                          src={imageUrls.Exterior}
                          className="img-sidebar"
                          style={{
                            height: "100%",
                            width: "100%",
                            backgroundSize: "cover", // or "contain" depending on your desired behavior
                            backgroundPosition: "center",
                          }}
                        />
                        <p
                          style={{
                            display: "flex",
                            gap: "4px",
                            position: "absolute",
                          }}
                          className="cat-text-blur"
                        >
                          <p>Exterior</p> (<p>{categoryCounts.Exterior}</p>)
                        </p>
                      </span>
                      <span
                        onClick={
                          categoryCounts.Interior
                            ? () => handleCategoryClick("Interior")
                            : undefined
                        }
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          cursor: !categoryCounts.Interior && "not-allowed",
                          backgroundColor:
                            !categoryCounts.Interior && "#A9A9A9",
                          position: "relative",
                        }}
                        className="btn-change5"
                      >
                        <img
                          src={imageUrls.Interior}
                          className="img-sidebar"
                          style={{
                            height: "100%",
                            width: "100%",
                            backgroundSize: "cover", // or "contain" depending on your desired behavior
                            backgroundPosition: "center",
                          }}
                        />
                        <p
                          style={{
                            display: "flex",
                            gap: "4px",
                            position: "absolute",
                          }}
                          className="cat-text-blur"
                        >
                          <p>Interior</p> (<p>{categoryCounts.Interior}</p>)
                        </p>
                      </span>
                      <span
                        onClick={
                          categoryCounts.Others
                            ? () => handleCategoryClick("Others")
                            : undefined
                        }
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          cursor: !categoryCounts.Others && "not-allowed",
                          backgroundColor: !categoryCounts.Others && "#A9A9A9",
                          position: "relative",
                        }}
                        className="btn-change5"
                      >
                        <img
                          src={imageUrls.Others}
                          className="img-sidebar"
                          style={{
                            height: "100%",
                            width: "100%",
                            backgroundSize: "cover", // or "contain" depending on your desired behavior
                            backgroundPosition: "center",
                          }}
                        />
                        <p
                          style={{
                            display: "flex",
                            gap: "4px",
                            position: "absolute",
                          }}
                          className="cat-text-blur"
                        >
                          <p>Others</p> (<p>{categoryCounts.Others}</p>)
                        </p>
                      </span>
                      <span
                        onClick={
                          categoryCounts["360"]
                            ? () => handleCategoryClick("360")
                            : undefined
                        }
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          cursor: !categoryCounts["360"] && "not-allowed",
                          backgroundColor: !categoryCounts["360"] && "#A9A9A9",
                          position: "relative",
                        }}
                        className="btn-change5"
                      >
                        <img
                          src={imageUrls["360"]}
                          className="img-sidebar"
                          style={{
                            height: "100%",
                            width: "100%",
                            backgroundSize: "cover", // or "contain" depending on your desired behavior
                            backgroundPosition: "center",
                          }}
                        />
                        <p
                          style={{
                            display: "flex",
                            gap: "4px",
                            position: "absolute",
                          }}
                          className="cat-text-blur"
                        >
                          <p>
                            <img src={Image.three_sixty_icon} alt="360 icon" />
                          </p>{" "}
                          (<p>{categoryCounts["360"]}</p>)
                        </p>
                      </span>
                      <span
                        onClick={
                          categoryCounts.Videos > 0
                            ? () => handleCategoryClick("Videos")
                            : undefined
                        }
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          cursor: !categoryCounts.Videos && "not-allowed",
                          backgroundColor: !categoryCounts.Videos && "#A9A9A9",
                          position: "relative",
                        }}
                        className="btn-change5"
                      >
                        <video
                          src={videos[currentIndex]}
                          width="100%"
                          height="100%"
                          autoPlay
                          muted
                        />
                        {/* <video ref={videoRef} width="100%" height="100%" style={{position:"absolute"}}>
                   <source src={videos[currentIndex]} type="video/mp4" />
                  </video> */}
                        <p
                          style={{
                            display: "flex",
                            gap: "4px",
                            position: "absolute",
                          }}
                          className="cat-text-blur"
                        >
                          <p>Videos</p> (<p>{categoryCounts.Videos}</p>)
                        </p>
                      </span>
                    </div>
                    <div className="gallery">
                      {!showVideos ? (
                        <div className="large-image-container">
                          <button
                            className="left-arrow-large"
                            onClick={handlePrevImage}
                          >
                            &#8249;
                          </button>
                          {categoryCounts.All > 0 ? (
                            selectedCategory === "360" ? (
                              <Image360Viewer images={images} />
                            ) : (
                              <img
                                id="large-image"
                                src={images[currentImageIndex]}
                                alt="Large"
                              />
                            )
                          ) : (
                            <img
                              id="large-image"
                              src={Image.defaultImage}
                              alt="Large"
                            />
                          )}
                          <button
                            className="right-arrow-large"
                            onClick={handleNextImage}
                          >
                            &#8250;
                          </button>

                          {/* <i className = "fa-regular fa-heart"></i> */}
                          <div className="product_share_like_car_details">
                            <span onClick={() => handleShareLink()}>
                              <IoShareSocial />
                            </span>
                          </div>
                          {/* Share buttons */}
                          {showSocialMedia ? (
                            <div className="social_media_container">
                              <FacebookShareButton url={generateShareUrl(id)}>
                                <FacebookIcon size={32} round />
                              </FacebookShareButton>
                              <TwitterShareButton url={generateShareUrl(id)}>
                                <TwitterIcon size={32} round />
                              </TwitterShareButton>
                              <WhatsappShareButton url={generateShareUrl(id)}>
                                <WhatsappIcon size={32} round />
                              </WhatsappShareButton>
                              <TelegramShareButton url={generateShareUrl(id)}>
                                <FacebookMessengerIcon size={32} round />
                              </TelegramShareButton>
                            </div>
                          ) : null}
                        </div>
                      ) : (
                        <div className="large-video-container">
                          {console.log("showVideos", showVideos)}
                          <button
                            className="left-arrow-large"
                            onClick={handlePrevVideo}
                          >
                            &#8249;
                          </button>
                          {selectedVideo ? (
                            <video
                              src={selectedVideo}
                              controls
                              width="100%"
                              height="100%"
                              autoPlay
                              onEnded={() => {
                                const currentIndex =
                                  videos.indexOf(selectedVideo);
                                const nextIndex =
                                  (currentIndex + 1) % videos.length;
                                setSelectedVideo(videos[nextIndex]);
                              }}
                            />
                          ) : (
                            <div>No video selected</div>
                          )}
                          <button
                            className="right-arrow-large"
                            onClick={handleNextVideo}
                          >
                            &#8250;
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12 px-2 cat-small-image-container">
                    <div
                      className="thumbnail-slider"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <button
                          className="arrow left-arrow btn-change5"
                          onClick={showVideos ? slideLeftVideo : slideLeft}
                        >
                          {"<"}
                        </button>
                      </div>
                      {showVideos
                        ? videos.length > 0 && renderVideos()
                        : images.length > 0 && renderThumbnails()}
                      <div>
                        <button
                          className="arrow right-arrow btn-change5"
                          onClick={showVideos ? slideRightVideo : slideRight}
                        >
                          {">"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <section
                className="col-lg-4"
                style={{
                  // backgroundColor: "#bfbfbf",
                  borderRadius: "5px",
                  // padding: "1% 1%",
                  // border: "1px solid lightgrey",
                }}
              >
                {/* <h5 className=" mb-3">
                  <span className="orange-color" style={{ color: "#e78932" }}>
                    Call Now
                  </span>{" "}
                  (765) 345-269
                </h5> */}
                <div
                  className=" d-flex flex-column justify-content-between product-detail-sideBox"
                  style={{ height: "auto" }}
                >
                  <div className="carDetailInfoList">
                    <span className="orange-color" style={{ color: "#e78932" }}>
                      VIN :{" "}
                    </span>
                    {product?.vin}
                  </div>
                  <ul>
                    {/* {product.mileage_in && ( */}
                    <li className="carDetailInfoList">
                      {new Intl.NumberFormat().format(product?.mileage_in)} MI
                    </li>
                    {/* // )} */}

                    {product?.transmission && (
                   <li className="carDetailInfoList">
                    {product.transmission}
                     </li>
                       )}

                    {product?.fuelType && (
                      <li className="carDetailInfoList">
                        {product?.fuelType}{" "}
                      </li>
                    )}

                    {product?.vehicleType && (
                      <li className="carDetailInfoList">
                        {product?.vehicleType}
                      </li>
                    )}

                    {product?.driveType && (
                      <li className="carDetailInfoList">
                        {product?.driveType}{" "}
                      </li>
                    )}
                  </ul>

                  <div className="btn-section">
                    <Button
                      className="fw-bold px-4 btn-sec"
                      style={{ backgroundColor: "white" }}
                    >
                      Loan
                    </Button>
                    <Button className="fw-bold product-g-btns">
                      Send Offer
                    </Button>
                  </div>
                </div>
                <div
                  className="product-detail-sideBox-b"
                  style={{ height: "auto", marginTop: "10px" }}
                >
                  {/* <h5 className="carDetailInfoList">Check Availability</h5>
                  <form action="" method="post"> */}
                  {/* <textarea name="" className="checkAvailInput" id=""></textarea>

                    <div className="w-100 d-flex justify-content-between">
                      <input type="text" className="checkAvailInput" placeholder="First Name" name="" id="" />
                      <input type="text" className="checkAvailInput" placeholder="Last Name" name="" id="" />
                    </div>

                    <div className="w-100 d-flex flex-column aling-items-center">
                      <input type="text" className="checkAvailInput" placeholder="Email" name="" id="" />
                      <input type="tel" className="checkAvailInput" placeholder="Phone Number" name="" id="" />
                    </div>

                    <div className="w-100 d-flex flex-column aling-items-center">
                      <label htmlFor="checkAvail">
                        <input type="checkbox" className="" name="" id="checkAvail" /> check to hdbf vbe
                      </label>
                      <Button className=" my-2 w-100 fw-bold product-g-btns">Send</Button>
                    </div> */}
                  {/* <input type="text" className="checkAvailInput" placeholder="Email" name="" id="" />
                    <input type="tel" className="checkAvailInput" placeholder="Phone Number" name="" id="" /> */}

                  {/* </form> */}
                  {/* <div
                    style={{
                      fontSize: "x-large",
                      backgroundColor: "lightgrey",
                    }}
                  > */}
                  <p
                    style={{
                      textAlign: "center",
                      color: "#D1823A",
                      fontWeight: "600",
                      fontSize: "20px",
                      marginBottom: "7px",
                    }}
                  >
                    Check Availability
                  </p>

                  <p
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "1.5rem",
                      fontWeight: "600",
                      marginBottom: "7px",
                      fontSize: "large",
                    }}
                  >
                    {/* <span> */}
                    <span
                      style={{
                        borderBottom: "2px solid black",
                        textWrap: "nowrap",
                      }}
                    >
                      Call Now
                    </span>{" "}
                    {/* </span> */}
                    <span
                      style={{
                        color: "white",
                        backgroundColor: "black",
                        borderRadius: "10px",
                        padding: "0.1rem 1rem",
                      }}
                    >
                      (469) 469-4699
                    </span>
                  </p>

                  <p
                    style={{
                      borderBottom: "1px solid grey",
                      position: "relative",
                      marginTop: "1rem",
                      marginBottom: "5px",
                    }}
                  >
                    <span
                      style={{
                        // border: "1px solid black",
                        position: "absolute",
                        right: "50%",
                        top: "-1rem",
                        padding: "0.3rem",
                        color: "#D1823A",
                        fontWeight: "600",
                        backgroundColor: "white",
                      }}
                    >
                      Or
                    </span>
                  </p>

                  <p
                    style={{
                      fontSize: "1rem",
                      marginTop: "1.2rem",
                      fontWeight: "600",
                      textAlign: "center",
                      marginBottom: "0rem",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <span>My name is </span>
                      <span>
                        <input
                          type="text"
                          placeholder="First Name"
                          style={{
                            width: "56%",
                            border: "none",
                            marginRight: ".2rem",
                            borderRadius: "3px",
                            padding: "0px 0.2rem",
                            backgroundColor: "#f7f7f7",
                          }}
                        />
                      </span>
                      <span>
                        <input
                          type="text"
                          placeholder="Last Name"
                          style={{
                            width: "56%",
                            border: "none",
                            marginRight: ".2rem",
                            borderRadius: "3px",
                            padding: "0px 0.2rem",
                            backgroundColor: "#f7f7f7",
                          }}
                        />
                      </span>
                    </div>
                    <div>
                      interested in {product?.modelYear} {product?.modelMake}{" "}
                      {product?.makeModel}, requesting to contact me at my{" "}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>
                        <input
                          type="text"
                          placeholder="(XXX) XXX-XXXX"
                          style={{
                            width: "100%",
                            border: "none",
                            marginRight: ".2rem",
                            borderRadius: "3px",
                            padding: "0px 0.2rem",
                            backgroundColor: "#f7f7f7",
                          }}
                        />
                      </span>
                      <span> & </span>
                      <span>
                        <input
                          type="text"
                          placeholder="Email"
                          style={{
                            width: "100%",
                            border: "none",
                            marginRight: ".2rem",
                            borderRadius: "3px",
                            padding: "0px 0.2rem",
                            backgroundColor: "#f7f7f7",
                          }}
                        />
                      </span>
                    </div>
                  </p>

                  <div
                    style={{
                      borderRadius: "12px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      padding: ".5rem 1rem",
                      // marginBottom: "2rem",
                    }}
                  >
                    {/* <div
                      style={{
                        width: "95%",
                        height: "80%",
                        fontSize: "large",
                        backgroundColor: "white",
                        borderRadius: "15px",
                        padding: "0 1rem",
                        marginBottom: "0.9rem",
                      }}
                    > */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        fontSize: "1rem",
                        fontWeight: "600",
                        borderRadius: "12px",
                        padding: "0 .5rem",
                        backgroundColor: "#f7f7f7",
                      }}
                    >
                      <p
                        style={{
                          margin: "0.3rem 0px",
                        }}
                      >
                        <span
                          style={{
                            margin: "0.3rem 0px",
                            fontWeight: "600",
                            borderBottom: "2px solid black",
                          }}
                        >
                          Need more info about :
                        </span>
                      </p>

                      <p
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flex: "0.5",
                        }}
                      >
                        <label htmlFor="TestDrive">
                          <input
                            type="checkbox"
                            id="TestDrive"
                            style={{ marginRight: "1rem" }}
                          />
                          Test Drive
                        </label>
                        <label htmlFor="VehHisRep">
                          <input
                            type="checkbox"
                            id="VehHisRep"
                            style={{ marginRight: "1rem" }}
                          />
                          Vehicle history report
                        </label>
                        <label htmlFor="FinOptions">
                          <input
                            type="checkbox"
                            id="FinOptions"
                            style={{ marginRight: "1rem" }}
                          />
                          Finance Options
                        </label>
                        <label htmlFor="haveTradeIn">
                          <input
                            type="checkbox"
                            id="haveTradeIn"
                            style={{ marginRight: "1rem" }}
                          />
                          I have a trade in
                        </label>
                        <label htmlFor="other">
                          <input
                            type="checkbox"
                            id="other"
                            style={{ marginRight: "1rem" }}
                          />
                          Other
                        </label>
                      </p>
                    </div>

                    <label
                      htmlFor="agree"
                      style={{
                        fontSize: ".7rem",
                        fontWeight: "600",
                        marginBottom: ".5rem",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="agree"
                        style={{ marginRight: ".5rem" }}
                      />
                      By clicking send , I accept to contacted by
                      dealersautocenter.com in the future.
                    </label>
                    <Button
                      className="mx-auto p-1"
                      style={{
                        width: "50%",
                        backgroundColor: "#D1823A",
                        border: "none",
                        padding: "0.2rem !important",
                        borderRadius: "5px",
                      }}
                    >
                      Send
                    </Button>

                    {/* </div> */}
                  </div>
                  {/* </div> */}
                </div>
              </section>

              {/* <section className="col-lg-4">
                <div className="product-detail-sideBox"
                  style={{ height: "40%", marginLeft: "5%" }}
                >
                  <div className="row" style={{ display: "flex" }}>
                    <div
                      className="col-md-6"
                      style={{ fontSize: "17px", fontWeight: "bold" }}
                    >
                      <span>
                        {new Intl.NumberFormat().format(product?.mileage_in)}
                        MI
                      </span>
                    </div>
                  </div>
                  <div className="row mt-4" style={{ display: "flex" }}>
                    <div className="col-md-12" style={{ fontSize: "17px" }}>
                      VIN: {product?.vin}
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-4 p-0 px-2 mt-md-0 my-2">
                      <div className="text-center product-detail-boxes">
                        <span>{product?.engineType}</span>
                      </div>
                    </div>
                    <div className="col-md-4 p-0 px-2 mt-md-0 my-2">
                      <div className="text-center product-detail-boxes">
                        <span>{product?.transmission}</span>
                      </div>
                    </div>
                    <div className="col-md-4 p-0 px-2 mt-md-0 my-2">
                      <div className="text-center product-detail-boxes">
                        <span>{product?.fuelType}</span>
                      </div>
                    </div>
                    <div className="col-md-4 p-0 px-2 mt-md-0 my-2">
                      <div className="text-center product-detail-boxes">
                        <span>{product?.driveType}</span>
                      </div>
                    </div>
                    <div className="col-md-4 p-0 px-2 mt-md-0 my-2">
                      <div className="text-center product-detail-boxes">
                      </div>
                    </div>
                    <div className="col-md-4 p-0 px-2 mt-md-0 my-2">
                      <div className="text-center product-detail-boxes">
                        <span>{product?.vehicleType}</span>
                      </div>
                    </div>
                  </div>

                  <div className="row my-2 mt-2" style={{ display: "flex", justifyContent: "space-between" }} >
                    <div className="col-md-5 ps-2">
                      <button className="product-detail-boxes-btn2">
                        <i className="fa-solid fa-landmark me-2"></i>Loan
                      </button>
                    </div>
                    <div className="col-md-5 ps-0 pe-2">
                      <button className="product-detail-boxes-btn2">
                        <i className="fas fa-paper-plane me-2"></i>Send Offer
                      </button>
                    </div>
                  </div>
                  <div className="row"></div>
                </div>
                <div className="product-detail-sideBox-b" style={{ height: "31%", marginTop: "20px", marginLeft: "5%" }} >
                  <h2 className="title text-dark fw-bold h4 text-center">
                    <h4 className="mx-2">
                      Check Availability
                    </h4>
                  </h2>
                  <div className="row mt-3">
                    <div
                      className="col-12 my-2 mt-2"
                      style={{  display: "flex",  justifyContent: "space-between",}}
                    >
                      <div className="col-md-6">
                        <span id="nav-text-input-b">
                          <input placeholder="Hi kumar,is this still available?.." />
                        </span>
                      </div>
                      <div className="col-md-5">
                        <div className="product-detail-boxes-btn4  d-block m-auto">
                          <Link to="#" className="text-light">
                            Send message
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mt-3">
                      <div className="col-12 phone-number-card-b">
                        <span>
                          <FaPhoneVolume
                            style={{ fill: "yellow", marginBottom: "4px" }}
                          />{" "}
                          +(123) 678 3458
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </section> */}
            </aside>

            {/* <div className='product-detail-sideBoxs mt-4' style={{ width: "90%" }}> */}
            <div className="col-lg-12">
              <div className="row">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                  className="mt-5 col-lg-8"
                >
                  <div
                    className=" gap-2 product-detail-boxes-userImg"
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <img
                      src={
                        product?.profilePicture
                          ? `${process.env.REACT_APP_IMG_URL}/${
                              product.userId
                            }/${product.profilePicture.trim()}`
                          : "https://images.unsplash.com/photo-1585846416120-3a7354ed7d39?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHByb2Zlc3Npb25hbCUyMG1hbnxlbnwwfHwwfHx8MA%3D%3D"
                      }
                      height="100px"
                      width="100px"
                      alt=".."
                    />
                    <div className="d-flex flex-column justify-content-center fw-bold">
                      <span>
                        {(product?.user_name && product?.user_name) ||
                          "Business Name NA"}{" "}
                      </span>
                      <span>
                        <i className="fa fa-star  text-warning me-2 fs-6"></i>
                        <i className="fa fa-star  text-warning me-2 fs-6"></i>
                        <i className="fa fa-star  text-warning me-2 fs-6"></i>
                        <i className="fa fa-star  text-warning me-2 fs-6"></i>
                        <i className="fa fa-star  text-danger me-2 fs-6"></i>
                      </span>
                    </div>
                  </div>

                  {/* <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "20px",
                    }}
                  >
                    <div>
                      <span className="fw-bold fs-4">
                        <span
                          className="fw-bold fs-4"
                          style={{ color: "#d1823a" }}
                        >
                          Location:{" "}
                        </span>
                        {product.location}
                      </span>

                      <span className="ms-1 fw-bold fs-6"> 4.3 </span>
                    </div>
                    <div>
                      {" "}
                      <h5 className="">
                        Get in Touch With{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {product?.user_name}
                        </span>
                      </h5>{" "}
                    </div>
                  </div> */}

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "20px",
                      fontWeight: "700",
                    }}
                  >
                    <Link style={{ color: "rgb(209, 130, 58)" }}>
                      {product?.location ? product?.location : "Location NA"}
                    </Link>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      gap: "20px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        paddingTop: "1rem",
                        alignItems: "center",
                      }}
                      className="fw-bold"
                    >
                      <span>City</span>
                      <span>{product?.city_mpg}</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                      }}
                    >
                      <span className="fw-bold">Fuel Economy</span>
                      <span
                        className=""
                        style={{
                          width: "50px",
                          height: "50px",
                          paddingLeft: "0.9rem",
                        }}
                      >
                        <BsFillFuelPumpFill />
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        paddingTop: "1rem",
                        alignItems: "center",
                      }}
                      className="fw-bold"
                    >
                      <span>HYW</span>
                      <span>{product?.highway_mpg}</span>
                    </div>
                  </div>
                </div>

                {/* <div className="col-lg-4  d-flex flex-nowrap justify-content-evenly">
                  {product.city_mpg && (
                    <div className="bg-liniar">
                      <div className="">{product.city_mpg}</div>
                      <span>City MPG</span>
                    </div>
                  )}

                  {product.highway_mpg && (
                    <div className="bg-liniar">
                      <div className="">{product.highway_mpg}</div>
                      <span>Highway MPG</span>
                    </div>
                  )}
                </div> */}
              </div>
            </div>

            {/* </div> */}
            <div className="row mt-5">
              {/* --------------Vehicle Specification start--------------- */}
              <div className="col-md-8">
                <div className="col-12">
                  <h2>Vehicle Specification</h2>
                </div>
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    {/* <h2 className="accordion-header" id="headingOne"></h2> */}
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                      style={{
                        boxShadow: "0px 2px 2px 2px #b8b8b8",
                        height: "25rem",
                      }}
                    >
                      <div className="accordion-body p-md-3 p-0">
                        <table className="table table-borderless">
                          <tbody>
                            <tr>
                              <td scope="row">
                                <MdOutlineDateRange className="svg-text-icon" />{" "}
                                Year
                              </td>
                              <th>{product?.modelYear}</th>
                              <td>
                                <TbEngine className="svg-text-icon" /> Engine
                              </td>
                              <th>{product?.engine}</th>
                            </tr>
                            <tr>
                              <td scope="row">
                                <TbBrandBootstrap className="svg-text-icon" />{" "}
                                Make
                              </td>
                              <th>{product?.modelMake}</th>
                              <td>
                                <LuFuel className="svg-text-icon" /> Fuel type
                              </td>
                              <th>{product?.fuelType}</th>
                            </tr>
                            <tr>
                              <td scope="row">
                                <IoLogoModelS className="svg-text-icon" /> Model
                              </td>
                              <th>{product?.makeModel}</th>
                              <td scope="row">
                                <GiGearStickPattern className="svg-text-icon" />{" "}
                                Transmission
                              </td>
                              <th>{product?.transmission}</th>
                            </tr>
                            <tr>
                              <td scope="row">
                                <FaCarSide className="svg-text-icon" /> Trim
                              </td>
                              <th>{product?.trim}</th>
                              <td scope="row">
                                <img
                                  src={Image.driveTrain}
                                  style={{ width: "15px", height: "15px" }}
                                />{" "}
                                Drivetrain
                              </td>
                              <th>{product?.driveTrain}</th>
                            </tr>
                            <tr>
                              <td>
                                <img
                                  src={Image.bodyType}
                                  style={{ width: "15px", height: "15px" }}
                                />{" "}
                                Body Type
                              </td>
                              <th>{product?.bodyClass}</th>
                              <td>
                                <GiCarDoor className="svg-text-icon" /> Doors
                              </td>
                              <th>{product?.doorCounts}</th>
                            </tr>
                            <tr>
                              <td scope="row">
                                <CgColorBucket className="svg-text-icon" />{" "}
                                Exterior Color
                              </td>
                              <th>{product?.exteriorColor}</th>
                              <td>
                                <IoMdSpeedometer className="svg-text-icon" />{" "}
                                Mileage
                              </td>
                              <th>
                                {/* {product?.mileage_in} */}

                                {new Intl.NumberFormat().format(
                                  product?.mileage_in
                                )}
                              </th>
                            </tr>
                            <tr>
                              <td>
                                {" "}
                                <IoMdColorFill className="svg-text-icon" />{" "}
                                Interior Color
                              </td>
                              <th>{product?.interiorColor}</th>
                              <td scope="row">
                                <FaRoad className="svg-text-icon" /> City MPG
                              </td>
                              <th>{product?.city_mpg}</th>
                            </tr>
                            <tr>
                              <td>
                                <IoCheckmarkCircle className="svg-text-icon" />{" "}
                                Condition
                              </td>
                              <th>Used</th>
                              <td>
                                <GiRoad className="svg-text-icon" /> Highway MPG
                              </td>
                              <th>{product?.highway_mpg}</th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                {/* --------------Vehicle Specification End--------------- */}

                {/* --------------Features Start--------------- */}
                <div className="row">
                  <div className="col-12 mt-3">
                    {/* <hr style={{ color: "black", border: "2px solid black" }} /> */}
                    <h2 className="fw-bold">Seller Discription</h2>
                    {/* <hr style={{ color: "black", border: "2px solid black" }} /> */}
                  </div>
                  <div className="row">
                    <div
                      style={{
                        boxShadow: "0px 2px 2px 2px #b8b8b8",
                        marginLeft: "0.5rem",
                        height: "11rem",
                      }}
                    >
                      {product?.seller_description}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mt-3">
                    {/* <hr style={{ color: "black", border: "2px solid black" }} /> */}
                    <h2 className="fw-bold">Highlights</h2>
                    {/* <hr style={{ color: "black", border: "2px solid black" }} /> */}
                  </div>
                  <div className="row">
                    <div
                      className="col-12 highlight-section-second"
                      style={{
                        boxShadow: "0px 2px 2px 2px #b8b8b8",
                        height: "22rem",
                      }}
                    >
                      <div className="highlight-section-contant">
                        <div
                          className="highlight-second-text"
                          style={{ paddingLeft: "2%" }}
                        >
                          <span>
                            <img
                              src={Image.title}
                              style={{ width: "30px", height: "25px" }}
                            />
                          </span>
                          <span>Title Status</span>
                          <span style={{ fontWeight: "bold" }}>
                            {product?.title_status}
                          </span>
                        </div>
                        <div
                          className="highlight-second-text"
                          style={{ paddingRight: "5%" }}
                        >
                          <span>
                            <img
                              src={Image.mileage}
                              style={{ width: "30px", height: "25px" }}
                            />
                          </span>
                          <span>Miles</span>
                          <span style={{ fontWeight: "bold" }}>
                            {/* {product.mileage_in} */}
                            {new Intl.NumberFormat().format(
                              product?.mileage_in
                            )}{" "}
                            MI
                          </span>
                        </div>
                        <div
                          className="highlight-second-text"
                          style={{ paddingRight: "3%" }}
                        >
                          <span>
                            <img
                              src={Image.owner}
                              style={{ width: "30px", height: "25px" }}
                            />
                          </span>
                          <span>Owners</span>
                          <span style={{ fontWeight: "bold" }}>
                            {product?.owner}
                          </span>
                        </div>
                      </div>
                      <div className="highlight-section-contant">
                        <div className="highlight-second-text">
                          <span>
                            <img
                              src={Image.damage}
                              style={{ width: "30px", height: "25px" }}
                            />
                          </span>
                          <span>Exterior Damage</span>
                          <span style={{ fontWeight: "bold" }}>
                            {product?.exterior_damage}
                          </span>
                        </div>
                        <div
                          className="highlight-second-text"
                          style={{ marginRight: "45px" }}
                        >
                          <span>
                            <img
                              src={Image.suitKeys}
                              style={{ width: "30px", height: "25px" }}
                            />
                          </span>
                          <span>Keys</span>
                          <span style={{ fontWeight: "bold" }}>
                            {product?.keys_count}
                          </span>
                        </div>
                        <div className="highlight-second-text">
                          <span>
                            <img
                              src={Image.green_smoke_icon}
                              style={{ width: "30px", height: "25px" }}
                            />
                          </span>
                          <span>Odor/Smoked In</span>
                          <span style={{ fontWeight: "bold" }}>
                            {product?.smoked_in}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* --------------Features End--------------- */}
              {/* --------------Monthly Loan Calculator start--------------- */}
              <main className="col-md-4">
                {/* -----------------Monthly loan calculator--------*/}
                <div className="product-detail-sideBox-cal mt-5">
                  <h5 className="text-center text-dark fw-bold text-white">
                    Est. Loan Calculator
                  </h5>
                  <div className="row">
                    <div className="col-12 est-monthly-heading mb-2 animated-box">
                      <div className="load_valContainer">
                        <img
                          src="https://dealersautocenter.com/image/CalculatorCircle.png"
                          alt=""
                          srcset=""
                          width="100%"
                          height="100%"
                        />
                        <span className="loan_emi">
                          $
                          <span style={{ color: "#d1823a" }}>
                            {new Intl.NumberFormat().format(monthlyPayment)}
                          </span>
                          {/* <span
                    className="tooltip-container"
                    style={{ top: "-4px" }}
                  >
                    <IoMdInformationCircleOutline className="info-icon" />
                    <div className="tooltip-text">
                      This is an Estimated Monthly payment ONLY. It is NOT a
                      loan.
                    </div>
                  </span> */}
                          <p style={{ marginTop: "-1rem" }}>month</p>
                        </span>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="view">
                        <div className="details">
                          <div
                            className="mt-2"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              color: "black",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <span>
                              <p
                                style={{ marginBottom: "0px" }}
                                className="label-head"
                              >
                                Vehicle Price
                              </p>
                            </span>
                            <span>
                              <p style={{ marginBottom: "0px" }}></p>
                            </span>
                          </div>
                          <div className="input-container-percentage cal_input_div">
                            <span>
                              <span className="percentage-symbol-b">$</span>
                            </span>
                            <input
                              type="text"
                              value={vehiclePrice}
                              name="listing_price"
                              className="form-control"
                              onChange={(e) => setVehiclePrice(e.target.value)}
                            />
                          </div>

                          <div
                            className="mt-2"
                            style={{
                              display: "flex",
                              color: "black",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>
                              <p
                                style={{ marginBottom: "0px" }}
                                className="label-head"
                              >
                                Traded-in-value
                              </p>
                            </span>
                            <span>
                              <p style={{ marginBottom: "0px" }}></p>
                            </span>
                          </div>
                          <div className="input-container-percentage cal_input_div">
                            <span>
                              <span className="percentage-symbol-b">$</span>
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              onChange={(e) => setTradeInValue(e.target.value)}
                            />
                          </div>
                          <div
                            className="mt-2"
                            style={{
                              display: "flex",
                              color: "black",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>
                              <p
                                style={{ marginBottom: "0px" }}
                                className="label-head"
                              >
                                Down-payment
                              </p>
                            </span>
                            <span>
                              <p style={{ marginBottom: "0px" }}></p>
                            </span>
                          </div>
                          <div className="input-container-percentage cal_input_div">
                            <span>
                              <span className="percentage-symbol-b">$</span>
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              onChange={(e) => setDownPayment(e.target.value)}
                            />
                          </div>

                          <div
                            className="mt-2"
                            style={{
                              display: "flex",
                              color: "black",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>
                              <p
                                style={{ marginBottom: "0px" }}
                                className="label-head"
                              >
                                Est. APR
                              </p>
                            </span>
                            <span>
                              <p style={{ marginBottom: "0px" }}></p>
                            </span>
                          </div>
                          <div className="input-container-percentage cal_input_div">
                            <input
                              type="text"
                              value={interestRate}
                              name="percentageData"
                              className="form-control"
                              onChange={(e) => setInterestRate(e.target.value)}
                            />
                            <span className="percentage-symbol-b">%</span>
                          </div>
                          <div
                            className="mt-2"
                            style={{
                              display: "flex",
                              color: "black",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>
                              <p
                                style={{ marginBottom: "0px" }}
                                className="label-head"
                              >
                                Title Registration Fess & Others
                              </p>
                            </span>
                          </div>
                          <div className="input-container-percentage cal_input_div">
                            <span>
                              <span className="percentage-symbol-b">$</span>
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              onChange={(e) =>
                                setTitleRegistrationFees(e.target.value)
                              }
                            />
                          </div>
                          <div
                            className="mt-2"
                            style={{
                              display: "flex",
                              color: "black",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>
                              <p
                                style={{ marginBottom: "0px" }}
                                className="label-head"
                              >
                                Terms(Month)
                              </p>
                            </span>
                            <span>
                              <p style={{ marginBottom: "0px" }}></p>
                            </span>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <span className="btn-car-details">
                              <button
                                type="button"
                                onClick={() => handleButtonClick("36")}
                                style={getButtonStyle("36")}
                              >
                                36
                              </button>
                            </span>
                            <span className="btn-car-details">
                              <button
                                type="button"
                                onClick={() => handleButtonClick("48")}
                                style={getButtonStyle("48")}
                              >
                                48
                              </button>
                            </span>
                            <span className="btn-car-details">
                              <button
                                type="button"
                                onClick={() => handleButtonClick("60")}
                                style={getButtonStyle("60")}
                              >
                                60
                              </button>
                            </span>
                            {/* <span className="btn-car-details">
                              <button
                                type="button"
                                onClick={() => handleButtonClick("72")}
                                style={getButtonStyle("72")}
                              >
                                72
                              </button>
                            </span> */}
                            <span className="btn-car-details">
                              {showInput ? (
                                <input
                                  type="text"
                                  value={inputValue}
                                  onChange={handleInputChange}
                                  onKeyDown={handleInputKeyDown}
                                  onBlur={handleInputBlur}
                                  placeholder="Enter"
                                  style={{
                                    marginLeft: "10px",
                                    width: "65px",
                                    height: "40px",
                                    borderRadius: "5px",
                                  }}
                                />
                              ) : (
                                <button
                                  type="button"
                                  onClick={handleMoreButtonClick}
                                  style={getButtonStyle(inputValue)}
                                >
                                  more
                                </button>
                              )}
                            </span>
                          </div>
                          <div
                            className="mt-2"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              color: "black",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>
                              <p
                                style={{ marginBottom: "0px" }}
                                className="label-head"
                              >
                                Approx. Credit Score
                              </p>
                            </span>
                            <span>
                              <p style={{ marginBottom: "0px" }}></p>
                            </span>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <span className="txt-btn-car-details">
                              <button
                                type="button"
                                style={getButtonStyles(11)}
                                onClick={() => handleButtonClickb(11)}
                              >
                                <p style={{ fontWeight: "bold" }}>Poor</p>
                                <p>630 or less</p>
                              </button>
                            </span>
                            <span className="txt-btn-car-details">
                              <button
                                type="button"
                                style={getButtonStyles(6.85)}
                                onClick={() => handleButtonClickb(6.85)}
                              >
                                <p style={{ fontWeight: "bold" }}>Fair</p>
                                <p>640 or 699</p>
                              </button>
                            </span>
                            <span className="txt-btn-car-details">
                              <button
                                type="button"
                                style={getButtonStyles(5.85)}
                                onClick={() => handleButtonClickb(5.85)}
                              >
                                <p style={{ fontWeight: "bold" }}>Good</p>
                                <p>700 or 749</p>
                              </button>
                            </span>
                            <span className="txt-btn-car-details">
                              <button
                                type="button"
                                style={getButtonStyles(4)}
                                onClick={() => handleButtonClickb(4)}
                              >
                                <p style={{ fontWeight: "bold" }}>Excellent</p>
                                <p>750 or 850</p>
                              </button>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* -----------------Value My Trade In End--------*/}
                <div
                  className="product-detail-sideBox-b"
                  style={{ height: "17%", marginTop: "20px" }}
                >
                  <h2 className="title text-dark fw-bold h4 text-center">
                    Value My Trade In
                  </h2>

                  <div className="row mt-3">
                    <div className="col-12">
                      <form>
                        <input type="text" className="form-control py-2 my-2" />
                      </form>
                    </div>
                    <div
                      className="col-12 my-2 mt-2"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="col-md-5">
                        <input
                          type="text"
                          className="form-control py-2 my-2"
                          placeholder="Enter Mileage"
                        />
                      </div>
                      <div className="col-md-5">
                        <div className="product-detail-boxes-btn4  d-block m-auto mt-3">
                          <Link to="#" className="text-light">
                            Submit
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* --------------Monthly Loan Calculator End--------------- */}
              </main>
              {/* -----------------------Standard Features End---------------------------------- */}

              {/* ------------------------------Seller Description start--------------------*/}

              {/* ------------------------------Seller Description End--------------------*/}
            </div>
            {/*----------------------------------- tyre condition--------------------------------------- */}
            <div
              className="row d-flex justify-content-center"
              style={{
                marginTop: "5rem",
                boxShadow: "0px 2px 2px 2px #b8b8b8",
                paddingBottom: "3rem",
                width: "97.5%",
                marginLeft: "0.2rem",
              }}
            >
              <div className="col-12">
                <h2 className="fw-bold">Tire Condition</h2>
              </div>
              <div className="col-12 d-flex">
                <div
                  className="col-4"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div
                    className="col-12"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "1.5rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        // paddingTop:"1rem",
                        marginTop: "2rem",
                        gap: "4rem",
                        alignItems: "center",
                      }}
                    >
                      <span>
                        <img
                          src={getBorderColor(product?.tyrecondition_fl)}
                          style={{ width: "55px", height: "55px" }}
                        />
                        <FaArrowLeft />
                      </span>
                      <span>
                        <img
                          src={getBorderColor(product?.tyrecondition_bl)}
                          style={{ width: "55px", height: "55px" }}
                        />
                        <FaArrowLeft />
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        paddingTop: "1rem",
                        alignItems: "center",
                      }}
                    >
                      <span>
                        <img src={Image.carDetailsCar} />
                      </span>
                      <span style={{ marginTop: "0.5rem" }}>
                        <img
                          src={getBorderColor(product?.tyrecondition_st)}
                          style={{ width: "55px", height: "55px" }}
                        />
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        // paddingTop:"1rem",
                        marginTop: "2rem",
                        gap: "4rem",
                        alignItems: "center",
                      }}
                    >
                      <span>
                        <FaArrowRight />
                        <img
                          src={getBorderColor(product?.tyrecondition_fr)}
                          style={{ width: "55px", height: "55px" }}
                        />
                      </span>
                      <span>
                        <FaArrowRight />
                        <img
                          src={getBorderColor(product?.tyrecondition_br)}
                          style={{ width: "55px", height: "55px" }}
                        />
                      </span>
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        // display: "flex",
                        display: "inline-block",
                        flexDirection: "column",
                        paddingTop: "1rem",
                        position: "relative",
                        // alignItems: "center",
                      }}
                      className="fw-bold"
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          position: "absolute",
                          top: "-56px",
                          width: "120px",
                        }}
                        className="tyre_cond_car"
                      >
                        <span>
                          <span class="tyre_cond_circlea"></span> New
                        </span>
                        <span>
                          <span class="tyre_cond_circleb"></span> Fair
                        </span>
                        <span>
                          <span class="tyre_cond_circlec"></span> Good
                        </span>
                        <span>
                          {" "}
                          <span class="tyre_cond_circled"></span> Fully Used
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-8 d-flex"
                  style={{ justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      paddingTop: "1rem",
                      alignItems: "center",
                    }}
                    className="fw-bold"
                  >
                    <span>1. Drive Font</span>
                    <span>
                      <img src={getBorderColor(product?.tyrecondition_fr)} />
                    </span>
                    <span>{statusFr ? statusFr : "N/A"}</span>
                    <span>{measurementFr ? measurementFr : "N/A"}</span>
                    {/* <span>Wheel Size</span>
                      <span>17"</span> */}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      paddingTop: "1rem",
                      alignItems: "center",
                    }}
                    className="fw-bold"
                  >
                    <span>2. Passanger Font</span>
                    <span>
                      <img src={getBorderColor(product?.tyrecondition_fl)} />
                    </span>
                    <span>{statusFl ? statusFl : "N/A"}</span>
                    <span>{measurementFl ? measurementFl : "N/A"}</span>
                    {/* <span>Wheel Size</span>
                        <span>17"</span> */}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      paddingTop: "1rem",
                      alignItems: "center",
                    }}
                    className="fw-bold"
                  >
                    <span>3. Driver Back-R</span>
                    <span>
                      <img src={getBorderColor(product?.tyrecondition_br)} />
                    </span>
                    <span>{statusBr ? statusBr : "N/A"}</span>
                    <span>{measurementBr ? measurementBr : "N/A"}</span>
                    {/* <span>Wheel Size</span>
                        <span>17"</span> */}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      paddingTop: "1rem",
                      alignItems: "center",
                    }}
                    className="fw-bold"
                  >
                    <span>4. Driver Back-L</span>
                    <span>
                      <img src={getBorderColor(product?.tyrecondition_bl)} />
                    </span>
                    <span>{statusBl ? statusBl : "N/A"}</span>
                    <span>{measurementBl ? measurementBl : "N/A"}</span>
                    {/* <span>Wheel Size</span>
                        <span>17"</span> */}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      paddingTop: "1rem",
                      alignItems: "center",
                    }}
                    className="fw-bold"
                  >
                    <span>5. Spare Tire</span>
                    <span>
                      <img src={getBorderColor(product?.tyrecondition_st)} />
                    </span>
                    <span>{statusSt ? statusSt : "N/A"}</span>
                    <span>{measurementSt ? measurementSt : "N/A"}</span>
                    {/* <span>Wheel Size</span>
                        <span>17"</span> */}
                  </div>
                </div>
              </div>
            </div>
            {/**************************************---------------- equipments------------************************************************* */}
            <div className="row" style={{ marginTop: "1%" }}>
              <div className="col-md-12">
                {/* <hr style={{ color: "black", border: "2px solid black" }} /> */}
                <h5>
                  <h1>Equipments Features</h1>
                </h5>
              </div>
              {chunkedOptions.map((chunk, index) => (
                <div key={index} className="row">
                  {chunk.map((option, idx) => (
                    <div key={idx} className="col-md-4">
                      <ul>
                        <li>{option}</li>
                      </ul>
                    </div>
                  ))}
                </div>
              ))}
              {itemsToShow < allOptions.length && (
                <div id="add-more-show-vehicleOptions">
                  <button onClick={handleShowMore}>More...</button>
                </div>
              )}
            </div>
            {/* </section> */}
            {/*----------------------Transportion Start-------------------------*/}
            {/*----------------------Transportion End-------------------------*/}

            {/*-----------------------Ship From and image start--------------------------*/}

            {/*----------------Ship From and image End--------------------*/}

            {/*----------Vechile Market Value Start-----------*/}
            <div className="row mt-4">
              {/* <div className='col-12 text-center mt-4'><h1>VEHICLE MARKET VALUE</h1></div> */}
              <div className="col-6">
                {/* <div className="input-group mb-3 w-50 dd">
                                                <button className="btn btn-outline-secondary" type="button" id="button-addon2">VIN</button>
                                                <input type="text" className="form-control iped" placeholder="1GKEC13Z14R183668" aria-label="Recipient's username" aria-describedby="button-addon2"/>
                                                <button className="btn btn-outline-secondary" type="button" id="button-addon2">Load</button>
                                                </div> */}
                {/* <img src="image/graph-1.jpg" width="100%" alt=".." className='bacd' /> */}
              </div>

              {/* <div className="col-6 costs">
                                <h1 className='mt-3 cc'>Ownership Cost</h1>
                                <h2 className='text-center rr'>Estimate Market Value*</h2>
                                <h2 className='text-center tt'>$15,345</h2>
                            </div> */}
            </div>
            {/*-------------------Vechile Market Value End---------------------*/}

            {/*----------------Recent Viewed start----------------------*/}
            <div className="row mt-3">
              <div className="col-12">
                <hr style={{ color: "black", border: "2px solid black" }} />
                <h2>Similar Car</h2>
              </div>

              {similarCars.length > 0 ? (
                <div className="container position-relative d-flex justify-content-evenly">
                  {similarCars.length > 5 && (
                    <button
                      className="btn similar-arrow p-0 position-relative top-50 start-0 translate-middle-y z-10"
                      style={{ marginLeft: "-25px" }}
                      onClick={() => scroll("left")}
                    >
                      <CgChevronLeft size={50} />
                    </button>
                  )}

                  <div
                    ref={sliderRef}
                    className="similar-slider w-100 pb-3"
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                    onMouseLeave={handleMouseUp}
                  >
                    {Array.isArray(similarCars) ? (
                      similarCars.map((car, index) => {
                        const imageArray = car.multimedia.all_images
                          ? car.multimedia.all_images.split(",")
                          : car.multimedia.exterior_images
                          ? car.multimedia.exterior_images.split(",")
                          : car.multimedia.misc_images
                          ? car.multimedia.misc_images.split(",")
                          : car.multimedia.interior_images
                          ? car.multimedia.interior_images.split(",")
                          : car.multimedia.three_sixty_images
                          ? car.multimedia.three_sixty_images.split(",")
                          : [];

                        const firstImage =
                          imageArray.length > 0 ? imageArray[0] : null;

                        return (
                          <div
                            key={index}
                            className="similar-car"
                            onClick={(e) => handleClick(car, e)}
                          >
                            <div className="col-item">
                              <h5 className="col-item-name px-1">
                                {car.modelYear} {car.modelMake} {car.makeModel}
                              </h5>
                              <img
                                src={
                                  firstImage
                                    ? `${imgUrl}/${product.userId}/${firstImage}`
                                    : Image.noImage
                                }
                                alt={`Similar Cars`}
                                className="img-fluid"
                              />
                              <div className="w-100 car-info px-1">
                                <span className="miles">{car.mileage_in}</span>
                                <span className="price">
                                  {car.listing_price}
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <p className="text-center">No similar cars found.</p>
                    )}
                  </div>

                  {similarCars.length > 5 && (
                    <button
                      className="btn similar-arrow p-0 position-relative top-50 end-0 translate-middle-y z-10"
                      style={{ marginRight: "-50px" }}
                      onClick={() => scroll("right")}
                    >
                      <CgChevronRight size={50} />
                    </button>
                  )}
                </div>
              ) : (
                <>
                  <h5>
                    {product?.user_name ? product?.user_name : "This dealer"} have
                    not other listing
                  </h5>
                </>
              )}
            </div>
            {/*------------------------------Recent Viewed End------------------------------*/}
          </div>
        </div>
      </section>
      <SellData
        show={showModalSell}
        handleClose={handleModalCloseSell}
        VehicleName={selldata}
        status={false}
      />
    </>
  );
};

export default CarDetails;

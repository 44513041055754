import React, { useState } from "react";
import { RiCloseLargeLine } from "react-icons/ri";
import { GiHamburgerMenu } from "react-icons/gi";
import Styles from "./Navbar3.module.css";
import { IoIosArrowDown } from "react-icons/io";

const YourComponent = () => {
  const [showMenu, setShowMenu] = useState(false);

  const handleShowMobileMenu = () => {
    setTimeout(() => {
      setShowMenu(!showMenu);
    }, 300);
  };

  return (
    <div className={Styles.wrapper}>
      <div className={Styles.container}>
        <p className={Styles.logo}>Website Name</p>
        {/* Mobile Design */}
        <span
          className={`${Styles.mobileAbsoluteLogo} ${
            showMenu ? Styles.active : ""
          }`}
          onClick={handleShowMobileMenu}
        >
          {showMenu ? <RiCloseLargeLine /> : <GiHamburgerMenu />}
        </span>
        {/* Mobile Menu Items */}
        {showMenu && (
          <div className={`${Styles.menuMobileItems} `}>
            <span>Home</span>
            <span>Inventory</span>
            <span>Listing</span>
            <span>Pages</span>
            <span>More</span>
            <span>Dealer Login</span>
            <span>Add Listing</span>
          </div>
        )}
        {/* Laptop View */}
        <div className={`${Styles.menuTabItems} `}>
          <p>Home</p>
          <p>Inventory</p>
          <p>
            Listing{" "}
            <span>
              <IoIosArrowDown />
            </span>
          </p>
          <p>
            Pages{" "}
            <span>
              <IoIosArrowDown />
            </span>
          </p>
          <p>
            More{" "}
            <span>
              <IoIosArrowDown />
            </span>
          </p>
        </div>
        <div className={`${Styles.DealerTabItems} `}>
          <p>Dealer login </p>
          <p>Add listing</p>
        </div>
      </div>
    </div>
  );
};

export default YourComponent;

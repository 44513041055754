import React from "react";
import Image from "../imgData";

const SponsoredCard = () => {
  return (
    <div
      style={{
        height: "400px",
        width: "100%",
        // border: "1px solid black",
        position: "relative",
        color: "red",
      }}
    >
      <img
        src={Image.sponsoredSampleCar}
        alt="sponsored car"
        style={{ maxWidth: "100%", height: "530px" }}
      />
      <div
        style={{
          position: "absolute",

          // border: "1px solid black",
          color: "#FFAA33",
          top: "6rem",
          left: "25%",

          fontWeight: "700",
          fontSize: "20px",
          letterSpacing: "1px",
          textShadow:
            "-1px -1px 0px black,  1px -1px 0px black,-1px 1px 0px black, 1px 1px 0px black",
        }}
      >
        SPONSORED IMAGE HERE
      </div>
    </div>
  );
};

export default SponsoredCard;

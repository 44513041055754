import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { MdNoteAdd } from "react-icons/md";
import { IoPersonAdd } from "react-icons/io5";

import NotesForm from "../components/NotesForm.jsx";
import MailNotesSummaryCard from "../components/MailNotesSummaryCard.jsx";
import Styles from "../Styles/DealerNotes.module.css";



const DealerNotes = () => {
  const notesArray = [
    {
      date: "04",
      year: "JUL 2024",
      subject: "Good Work",
      readers: ["Afjal", "Neeraj", "Sumit", "Tanish"],
      bgColor: "red",
    },
    {
      date: "24",
      year: "MAY 2023",
      subject: "Work done",
      readers: ["Tanish", "Emran", "Sumit", "Afjal"],
      bgColor: "yellow",
    },
    {
      date: "04",
      year: "Sep 2024",
      subject: "Good Work",
      readers: ["Afjal", "Tanish", "Priyanka", "Neeraj"],
      bgColor: "green",
    },
    {
      date: "04",
      year: "Oct 2024",
      subject: "Good Work",
      readers: ["Afjal", "priyanka", "Emran", "Sumit"],
      bgColor: "blue",
    },
    {
      date: "09",
      year: "DEC 2021",
      subject: "Good Morning",
      readers: ["Afjal", "priyanka", "Tanish", "Sumit"],
      bgColor: "grey",
    },
    {
      date: "19",
      year: "Dec 2022",
      subject: "India Wins",
      readers: ["Afjal", "priyanka", "Neeraj", "Sumit"],
      bgColor: "pink",
    },
  ];

  const [showNotesForm, setShowNotesForm] = useState({
    showForm: false,
    formData: null,
  });

  const handleOpenForm = (note) => {
    setShowNotesForm((prevData) => ({
      ...prevData,
      showForm: true,
      formData: note,
    }));
  };

  const handleAddNotes = () => {
    setShowNotesForm((prevData) => ({
      ...prevData,
      showForm: true,
    }));
  }

  return (
    <Col sm={12}>
      <div className={Styles.wrapper}>
        <p className={Styles.addPerson}>
          <OverlayTrigger
            placement="bottom-start"
            overlay={
              <Tooltip id={`tooltip-addNotes`} className="custom-tooltip">
                Add Notes
              </Tooltip>
            }>
            <span onClick={handleAddNotes}><MdNoteAdd /></span>
          </OverlayTrigger>
        </p>

        <Container fluid>
          <Row className={Styles.container}>
            {notesArray &&
              notesArray.map((note, i) => {
                return (
                  <Col xl={6} key={i} onClick={() => handleOpenForm(note)}>
                    <MailNotesSummaryCard note={note} />
                  </Col>
                );
              })}
          </Row>
        </Container>
        {showNotesForm.showForm && (
          <div>
            <NotesForm data={showNotesForm.formData} />
          </div>
        )}
        {/* <NotesForm/> */}
      </div>
    </Col>
  );
};

export default DealerNotes;

import React from 'react';
import '../Styles/SocialChild.css'

const Socialchild = ({imgSrc,socialName}) => {
 
  return (
    <div>
        <div className='social_item_container'  >
            <img src={imgSrc} alt="Social Media icon" height={30}/>
            
             <p>Signin with <span>{socialName}</span></p>
        </div>
      
    </div>
  )
}

export default Socialchild

const QuoteData = [
  { id: 1, inputLabel: "First Name", key: "firstName", inputValue: "" },
  { id: 2, inputLabel: "Last Name", key: "lastName", inputValue: "" },
  { id: 3, inputLabel: "Phone Number", key: "phoneNumber", inputValue: "" },
  { id: 4, inputLabel: "Email", key: "email", inputValue: "" },
  { id: 1, inputLabel: "Sales Price", key: "salesPrice", inputValue: 0 },
  { id: 2, inputLabel: "Trade in", key: "tradeIn", inputValue: "" },
  {
    id: 3,
    inputLabel: "Owned on Trade-In",
    key: "ownedTradeIn",
    inputValue: "",
  },
  {
    id: 4,
    inputLabel: "Cash Difference",
    key: "cashDifference",
    inputValue: "",
  },
  
  {
    id: 3,
    inputLabel: "Wind-Shield Sticker",
    key: "windShieldSticker",
    inputValue: "",
  },
  {
    id: 4,
    inputLabel: "TERP Title Fee to DMV",
    key: "terpTitleFeetoDMV",
    inputValue: "",
  },
  {
    id: 5,
    inputLabel: "Title Application Fee",
    key: "titleAppFee",
    inputValue: "",
  },
  {
    id: 6,
    inputLabel: "REG. FEE-DPS to Tax-Office",
    key: "regFeesDPStoTaxOffice",
    inputValue: "",
  },
  {
    id: 7,
    inputLabel: "CNTY ROAD Bridge Addon Fee",
    key: "cntyRoadBridgeAddonFee",
    inputValue: "",
  },
  {
    id: 8,
    inputLabel: "Child Safety to Tax Office",
    key: "childSafetyToTaxOffice",
    inputValue: "",
  },
  {
    id: 9,
    inputLabel: "Proc. & handling fee to tax office",
    key: "procHandlingFee",
    inputValue: "",
  },
  { id: 10, inputLabel: "Metal Tag", key: "metalTag", inputValue: "25.00" },
  {
    id: 11,
    inputLabel: "Inspection fee to the State",
    key: "inspectionFeeFeeToState",
    inputValue: "",
  },
  {
    id: 12,
    inputLabel: "Inspection fee to Station",
    key: "inspectionFeeFeeToStation",
    inputValue: "",
  },
];
export default QuoteData;

import React from "react";
import styles from "../PaymentStage3.module.css";
import { IoMdArrowRoundBack } from "react-icons/io";
import { PiCheckFatFill } from "react-icons/pi";

const PaymentStage3 = ({ setPaymentStages }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.header}>
          <span onClick={() => setPaymentStages("stage2")}>
            <IoMdArrowRoundBack />
          </span>
          <span>Payment</span>
        </div>
        <div className={styles.dataContainer}>
          <div className={styles.completedSignContainer}>
            <p>
              <PiCheckFatFill />
            </p>
            <p>Completed</p>
          </div>
          <div className={styles.paymentTimeContainer}>
            <p>$ 2500.22</p>
            <p>Dec 3, 2024 at 7:11 PM</p>
          </div>
          <div className={styles.paymentAndSentDetailContainer}>
            <div className={styles.paymentContainer}>
              <p>
                <span>Amount</span>
                <span>$ 2500.22</span>
              </p>
              <p>
                <span>Transaction ID</span>
                <span>#SAM56Q</span>
              </p>
              <p>
                <span>To</span>
                <span>United Motors</span>
              </p>
              
            </div>
            <div className={styles.sentConfirmContainer}>
              <p>Receipt Sent</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentStage3;

import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Image from "../../imgData";
import { useDispatch, useSelector } from "react-redux";
import { setRetailData } from "../../../Redux/slice/taxes&FeesSlice";
import "./VehicleCard.css";
import { IoMdInformationCircleOutline } from "react-icons/io";
const imgUrl = process.env.REACT_APP_IMG_URL;

const VehicleCard = ({ index, selectedCard, handleSelected, data }) => {
  const { id } = useSelector((state) => state.user);
  const { dealerId } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const {
    all_images,
    exterior_images,
    misc_images,
    interior_images,
    three_sixty_images,
  } = data.multimedia;
  const noImage = Image.noImage;

  const getFirstValidImage = (images) => {
    if (images) {
      const imageList = images.split(",").map((img) => img.trim());
      for (let image of imageList) {
        if (image) {
          return `${imgUrl}/${dealerId}/${image}`;
        }
      }
    }
    return null;
  };

  const imageUrl =
    (all_images && getFirstValidImage(all_images)) ||
    (exterior_images && getFirstValidImage(exterior_images)) ||
    (misc_images && getFirstValidImage(misc_images)) ||
    (interior_images && getFirstValidImage(interior_images)) ||
    (three_sixty_images && getFirstValidImage(three_sixty_images)) ||
    noImage;

  // console.log('index', index);
  useEffect(() => {
    dispatch(
      setRetailData({
        imageUrl: imageUrl,
      })
    );
  }, [imageUrl]);

  console.log("selectedCard******************************************", data);
  const selectedVehicle = useSelector((state) => state.sell.selectedVehicle);
  const [isIdMatch, setisIdMatched] = useState(false);
  useEffect(() => {
    if (selectedVehicle) {
      if (selectedVehicle.id === data.id) {
        console.log("Yes both are equal");
        setisIdMatched(true);
      } else {
        setisIdMatched(false);
      }
    }
  }, [selectedVehicle]);

  // useEffect(()=>{
  //   dispatch(setRetailData({
  //     listingId:selectedVehicle.id,
  //   }));
  // },[selectedVehicle]);

  return (
    <>
      <Col
        sm={12}
        className=" mb-4 v-cardWrapper"
        onClick={() => {
          handleSelected(index);
        }}
      >
        <Link to={`#${data.stock_no}#${data.id}`}>
          <div>
            <div
              className="v-card"
              style={{
                backgroundColor: isIdMatch ? "rgb(245 148 57 / 34%)" : "",
                boxShadow: isIdMatch ? "0px 5px 5px #cccccc" : "",
              }}
            >
              <div className="sectionContainer">
                <img src={imageUrl} alt="..." />
              </div>
              <div className="sectionContainer">
                <div className=" vehicleSectionDetails">
                  <span style={{display:"flex",alignItems:"center"}}>
                  <span>Assets Info</span>
                  <span className="tooltip-container-active-inventory">
                  <IoMdInformationCircleOutline className="info-icon-active-inventory" />
                  <div className="tooltip-text-active-inventory" style={{textWrap:"nowrap",top:"86%"}}>
                    Year Make Model
                  </div>
                  </span>
                  </span>
                  <span>
                    {data.year} {data.make} {data.model}
                  </span>
                </div>
              </div>
              <div className="  sectionContainer">
                <div className="divide"></div>
                <div className="vehicleSectionDetails">
                  <span className="">VIN: </span>
                  <span>{data.vin}</span>
                </div>
              </div>
              <div className=" sectionContainer">
                <div className="divide"></div>
                <div className="vehicleSectionDetails">
                  <span>Price:</span>
                  <span>
                    ${new Intl.NumberFormat().format(data.listing_price)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </Col>
    </>
  );
};

export default VehicleCard;
